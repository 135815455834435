<div class="left-blog-section">
    <div class="left-blog-posts">
        <div class="recent-posts">
            <div class="widget-title">
                <h3 class="title">Blogs Articles</h3>
            </div>
            <div *ngFor="let i of allNews | slice:0:4" class="left-blog-post-widget no-border">
                <div class="post-img">
                    <a routerLink="/MGL-corporate/media/news/{{i._id}}"><img *ngIf="i.Img" src="{{base}}{{i.Img}}" alt=""></a>
                </div>
                <div class="post-desc">
                    <a routerLink="/MGL-corporate/media/news/{{i._id}}">{{i.Title}} </a>
                    <!-- <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span> -->
                </div>
            </div>
            
            
            

            <a [routerLink]="['/MGL-corporate/media/news']" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
        </div>
    </div>

</div>