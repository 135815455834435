import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { CarrierItem} from 'src/app/models/carrier';
import { CarrierjobapplyService } from 'src/app/Service/carrierjobapply.service';
import { GenderItem } from 'src/app/models/carrier';
@Component({
  selector: 'app-carrierjobapply',
  templateUrl: './carrierjobapply.component.html',
  styleUrls: ['./carrierjobapply.component.css']
})
export class CarrierjobapplyComponent {
  carrierapplyform!:FormGroup
  submitted =false
  carrierapply:CarrierItem[]=[]
  gender:GenderItem[]=[]
  files!: any ;
  LpgSlip:any;
  captcha:any
 DOBResponse : any = "select valid date"


 alertType: any = ''
 alertMsg: any = ''
 alertColor: string = ''
 alertTextColor: string = ''
 alertBorder: string = ''
 alertTextAlign: string = 'center'
  constructor(private FormBuilder : FormBuilder,private service :CarrierjobapplyService) { }
  ngOnInit(): void {
    this.getGender();
	this.captchaGenerate()
    this.carrierapplyform = this.FormBuilder.group({
      FirstName:['',[Validators.required]],
      LastName:['',[Validators.required]],
      Qualification:['',[Validators.required]],
      ContactNumber:['',[Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
      DOB:['',[Validators.required]],
      Gender:['',[Validators.required]],
      Email:['',[Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Address:['',[Validators.required]],
      Pincode:['',[Validators.required,Validators.pattern(/^[0-9]{6}$/)]],
      ExpYear:['',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{1}$")]],
      ExpMonth:['',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{1}$")]],
      CompanyName:['',[Validators.required]],
      CurrentSalary:['',[Validators.required]],
      ExpectedSalary:['',[Validators.required]],
      CVFileName:['',[Validators.required]],
	  Captcha: ['',[Validators.required]],
    })

  }

  fileCheckForSlip(val:any,name:any){
    //console.log(val.files)
    if ((val.files[0].type=="application/pdf"||val.files[0].type=="application/msword"||val.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.files[0].size < (5*1024*1024)) {
      this.files = val.files
    } else{
      this.LpgSlip=null
	  this.carrierapplyform.get(name)?.setValue('');
      return alert('Please Select pdf,msword,document')
    }
  }


  // filechange(element:any){
  //   this.files = element.target.files
  //   console.log(this.files)
  //   }
    


captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }
  getGender() {
    this.service.getgender().subscribe((res: any) => {
      this.gender = res
      // console.log(res)
      // console.log('this.gender', this.gender);
    })
  }

  
    onSubmit() {
	
      this.submitted = true
	  const d = new Date();
      let year = d.getFullYear();

    var d1 = new Date(this.carrierapplyform.get('DOB')?.value);   
    var d2 = new Date();   
    var diff = d2.getTime() - d1.getTime();   
  
    var daydiff = (diff / 31536000000).toFixed(0);

    if(parseInt(daydiff) < 18){

      this.carrierapplyform.get('DOB')?.setValue('')
      this.DOBResponse = "Should be Above 18"

    }
	  if(this.carrierapplyform.get('Captcha')?.value != this.captcha){
        this.carrierapplyform.get('Captcha')?.setValue('')
      }
	  this.captchaGenerate();
      if(this.carrierapplyform.invalid){
        return;
      }
      //console.log("hello")
      const form = new FormData()
      form.append("FirstName",this.carrierapplyform.get('FirstName')?.value)
      form.append("LastName",this.carrierapplyform.get('LastName')?.value)
      form.append("Qualification",this.carrierapplyform.get('Qualification')?.value)
      form.append("ContactNumber",this.carrierapplyform.get('ContactNumber')?.value)
      form.append("DOB",this.carrierapplyform.get('DOB')?.value)
      form.append("Gender",this.carrierapplyform.get('Gender')?.value)
      form.append("Email",this.carrierapplyform.get('Email')?.value)
      form.append("Address",this.carrierapplyform.get('Address')?.value)
      form.append("Pincode",this.carrierapplyform.get('Pincode')?.value)
      form.append("ExpYear",this.carrierapplyform.get('ExpYear')?.value)
      form.append("ExpMonth",this.carrierapplyform.get('ExpMonth')?.value)
      form.append("CompanyName",this.carrierapplyform.get('CompanyName')?.value)
      form.append("CurrentSalary",this.carrierapplyform.get('CurrentSalary')?.value)
      form.append("ExpectedSalary",this.carrierapplyform.get('CurrentSalary')?.value)
      form.append("CVFileName", this.files[0],this.files[0].name)
      this.service.insertcarrier(form).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response))
        if(data){
          this.alertType='submit'
          this.alertMsg = "Submitted"
          this.alertColor = 'rgba(4, 183, 107, 0.2)'
          this.alertTextColor = '#038b51'
          this.alertBorder = '1px solid #04a862;'
    
          setTimeout(() => {
            this.alertType=''
            this.carrierapplyform.reset()
            this.submitted = false
          }, 1000);
        }
      })
      
      
    }
       
    
    
  reset(){
    this.carrierapplyform.reset()
	this.gender=[]
    this.submitted=false
  }
   
  }
  
