<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CNG <span>Dealer Feedback </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>CNG</a></li>
                <li class=""><a>Dealer Zone</a></li>
                <li class=""><a>Login For Existing Dealers</a></li>
                <li class=""><a>Feedback</a></li>
                <li class="active" aria-current="page">CNG Dealer Feedback </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <div class="customer-login-page log-meter-reading">

                                <form [formGroup]="UserForm">
                                    <div class="row">

                                        <div class="col-sm-6 form-group">
                                            <!-- <label>Matter Related To</label> -->
                                            <div class="form-floating">
                                                <select (change)="getOutletDetails(type.value)"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['RetailOutlet'].errors}"
                                                    #type
                                                    (change)="this.UserForm.get('RetailOutlet')?.setValue(type.value)"
                                                    class="form-select" aria-label="Floating label select example">
                                                    <option value="" selected>Select Option</option>
                                                    <option *ngFor="let i of OutletName" [value]="i._id">
                                                        {{i.OutletName}}</option>

                                                </select>
                                                <label for="floatingSelectGrid">Select Name of Retail Outlet</label>

                                                <div *ngIf="submitted && UserForm.controls['RetailOutlet'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['RetailOutlet'].errors">Required Field
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly type="text" class="form-control" formControlName="OutletNo"
                                                    placeholder="OutletNo"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['OutletNo'].errors }"
                                                    id="OutletNo" />
                                                <div *ngIf="submitted && UserForm.controls['OutletNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['OutletNo'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Outlet No.</label>
                                            </div>
                                        </div>




                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="ConcernPerson"
                                                    placeholder="ConcernPerson"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['ConcernPerson'].errors }"
                                                    id="ConcernPerson" />
                                                <div *ngIf="submitted && UserForm.controls['ConcernPerson'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['ConcernPerson'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Concern Person<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly  type="text"
                                                    class="form-control" formControlName="PhoneNo" placeholder="PhoneNo"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['PhoneNo'].errors }"
                                                    id="PhoneNo" />
                                                <div *ngIf="submitted && UserForm.controls['PhoneNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['PhoneNo'].errors">
                                                        Required 10 digit Number</div>
                                                </div>
                                                <label for="Contact">Phone No.</label>
                                            </div>
                                        </div>


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" formControlName="Email"
                                                    placeholder="Email"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                                                    id="Email" />
                                                <div *ngIf="submitted && UserForm.controls['Email'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Email'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Email<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="Address" placeholder="Address"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Address'].errors }"
                                                    id="Address" />
                                                <div *ngIf="submitted && UserForm.controls['Address'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Address'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Address</label>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Type<span
                                                    class="text-danger">*</span></label>
                                            <div class="mt-10">
                                                <div class="row service-label">

                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="css"
                                                            name="fav_language" value="Complaint" class="form-check-input">
                                                        <label for="css" class="">Complaint</label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="css"
                                                            name="fav_language" value="FeedBack" class="form-check-input">
                                                        <label for="css" class="">FeedBack</label>
                                                        </div>
                                                    </div>


                                                    
                                                    

                                                    <div *ngIf="submitted && UserForm.controls['Rating'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm.controls['Rating'].errors">
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                        <div class="col-sm-6 form-group">
                                            <!-- <label>Matter Related To</label> -->
                                            <div class="mb-15 form-floating">
                                                <select
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['type'].errors}"
                                                    #Mat (change)="this.UserForm.get('type')?.setValue(Mat.value)"
                                                    class="form-select" aria-label="Floating label select example">
                                                    <option value="" selected>Select Option</option>
                                                    <option *ngFor="let i of Matter">{{i}}</option>

                                                </select>
                                                <label for="floatingSelectGrid">Select Matter Related To</label>

                                                <div *ngIf="submitted && UserForm.controls['type'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['type'].errors">Required Field
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6 col-sm-12">

                                            <div class="mb-10 form-floating mt-0">
                                                <!-- <i class="demo-icon uil-lock-alt"></i> -->
                                                <select
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Category'].errors}"
                                                    #Cat (change)="this.UserForm.get('Category')?.setValue(Cat.value)"
                                                    class="form-select">
                                                    <option value="" selected>Select Category </option>
                                                    <option *ngFor="let i of Category">{{i}}</option>

                                                </select>
                                                <label for="floatingSelect">Select Category</label>

                                                <div *ngIf="submitted && UserForm.controls['Category'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Category'].errors">Required Field
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <!-- <div class="form-floating">
                                                <textarea [ngClass]="{'is-invalid':submitted && UserForm.controls['Remark'].errors}" formControlName="Remark" class="from-control" type="text"
                                                    name="name" placeholder="Enter Your Remark " id="floatingTextarea2" required=""></textarea>
                                                <label for="floatingTextarea2">Model</label>

                                                <div *ngIf="submitted && UserForm.controls['Remark'].errors" class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Remark'].errors">Remark required</div>
                                                </div>
                                            </div> -->
                                            <div class="form-floating">
                                                <textarea
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Remark'].errors}"
                                                    formControlName="Remark" class="form-control"
                                                    placeholder="Leave a comment here"
                                                    id="floatingTextarea33"></textarea>
                                                <label for="floatingTextarea33">Remark</label>
                                                <div *ngIf="submitted && UserForm.controls['Remark'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Remark'].errors">Required Field
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-2 col-md-5 col-sm-12">
                                                <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                                <div class="pt-20 md-pt-0">
                                                    <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                   <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                    aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-7 col-sm-12">
                                                
                                                
    
                                                <div class="form-floating">
                                                    <input [ngClass]="{'is-invalid':submitted && UserForm.controls['Captcha'].errors}" type="text" class="form-control" id="Captcha" placeholder="Captcha" formControlName="Captcha">
                                                    <label for="Captcha">Captcha</label>
                                                    <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                          class="invalid-feedback">
                                                          <div
                                                              *ngIf="UserForm.controls['Captcha'].errors">Required Field</div>
                                                      </div>
                                                  </div>
                                            </div>
    
                                            
                                        </div>



                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Year</label>
                                            <div class="mb-30 cu-login-input2 mt-10">
                                                
                                                <input
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Year'].errors}"
                                                    formControlName="Year" class="from-control" type="text" id="name"
                                                    name="name" placeholder=" Enter Captcha" required="">
                                                <div *ngIf="submitted && UserForm.controls['Year'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Year'].errors">Captcha required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->






                                    </div>
                                    <div class="mt-30">
                                        <div class="row">
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                <button (click)="submit()" class="greenbtn2">Submit <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                <div class="text-right md-text-left">
                                                    <button (click)="reset()" class="bluebtn2">Reset <i
                                                            class="demo-icon uil-redo"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div *ngIf="alertType == 'form'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                                <p class="text-right mt-30 mb-0"><i><b>Please note all fields marked <span
                                                class="text-danger">*</span> are compulsory.</b></i></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>