import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { NewslettersService } from 'src/app/Service/newsletters.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-newslettersdetails',
  templateUrl: './newslettersdetails.component.html',
  styleUrls: ['./newslettersdetails.component.css']
})
export class NewslettersdetailsComponent {
  public base = environment.baseUrl
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  walkcenterArray: any[] = [];
 Data:any
  id:any
  constructor(private pageLoaderService: PageLoaderService,private service: NewslettersService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
      const id = this.route.snapshot.paramMap.get('id');
    this.id=id;
    this.getData();
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

    getData() {

   this.service.getNewsletters().subscribe(response => { 
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.Response == true) {
         this.walkcenterArray =res.details; 
          let filtered = this.walkcenterArray.filter((x:any)=>{
        return x._id == this.id
      })
      this.Data=filtered
      //console.log(this.Data)
       

      } else {
        this.walkcenterArray = [];
       
      }



    }, err => {
     // console.log(err)
    })

  }
}

