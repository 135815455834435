<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Payment <span>History</span></h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>Residential PNG</a></li>
        <li class=""><a >Customer Zone</a></li>
        <li class=""><a >Customer Login</a></li>
        <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
        <li class="active" aria-current="page">Payment History</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->

<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <div class="latestnews-section">
          <app-dashboard-info-card></app-dashboard-info-card>
        </div>
      </div>

      <div *ngIf="this.allData.length > 0" class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="innerpage-right-content awards-section">
          <div class="mt-20 payments_table">
            <p>THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 PAYMENTS ARE AS FOLLOWS</p>
            <ul>
              <li class="payments-box">
                <span class="InstrumentNo">Instrument / Transaction ID No.</span>
                <span class="PaymentDate">Payment Date</span>
                <span class="PaymentSource">Payment Source</span>
                <span class="Amount">Amount</span>
              </li>

              <li *ngFor="let i of allData,index as j">
                <!-- <span class="InstrumentNo">{{i.sno}}</span>
                <span class="PaymentDate">{{i.Cheque_Date}}</span>
                <span class="PaymentSource">{{i.source_of_payment}}</span>
                <span class="Amount">{{i.Payment_amount}}</span>-->
				<span class="InstrumentNo">{{i['Instrumrnt No']}}</span>
                <span class="PaymentDate">{{i['Payment date']}}</span>
                <span class="PaymentSource">{{i['Payment Source']}}</span>
                <span class="Amount">{{i['Amount']}}</span>
              </li>

            </ul>
            <!-- <p class="mt-20">THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 DBS BANK UPI PAYMENTS ARE AS FOLLOWS </p> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>