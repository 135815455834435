import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { SayyogiItem } from 'src/app/models/sayyogi';
import { SayyogiService } from 'src/app/Service/sayyogi.service';
import { NatureofJobItem } from 'src/app/models/sayyogi';
import { MunicipalAreaItem } from 'src/app/models/sayyogi';
@Component({
  selector: 'app-mglsahayogi-dialbefore-dig',
  templateUrl: './mglsahayogi-dialbefore-dig.component.html',
  styleUrls: ['./mglsahayogi-dialbefore-dig.component.css']
})
export class MGLSahayogiDialbeforeDigComponent {
  UserForm!:FormGroup
  submitted=false
  Sayyogidata:SayyogiItem[]=[]
  muncipaldata:MunicipalAreaItem[]=[]
  naturedata:NatureofJobItem[]=[]
  cityselected: any;
  captcha:any


  constructor(private pageLoaderService: PageLoaderService,private FormBuilder:FormBuilder,private Service:SayyogiService) { }

  ngOnInit(): void {
    this.getmuncipal();
    this.getnature();
	this.captchaGenerate()

    this.UserForm=this.FormBuilder.group({
      NameofPerson:['',[Validators.required]],
      MobileNo:['',[Validators.pattern(/^[0-9]{10}$/)]],
      MuncipalArea:[''],
      Locality:['',[Validators.required]],
      ExactPlace:['',[Validators.required]],
      LandMark:[''],
      NatureofJob:['',[Validators.required]],
	  Captcha: ['',[Validators.required]],

	  

    })

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }
  
  getmuncipal() {
    this.Service.getMuncipal().subscribe((res: any) => {
      this.muncipaldata = res
      //console.log(res)
      //console.log('this.muncipaldata', this.muncipaldata);
    })
  }

  getnature() {
    this.Service.getNature().subscribe((res: any) => {
      this.naturedata = res
     // console.log(res)
      //console.log('this.naturedata', this.naturedata);
    })
  }
  
   captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

    // submit(){
    //   this.submitted=true
	   // if(this.UserForm.get('Captcha')?.value != this.captcha){
    //     this.UserForm.get('Captcha')?.setValue('')
    //   }
	   // this.captchaGenerate()
    //   if(this.UserForm.invalid){	
    //     return
    //   }
    //   console.log("hello")
    //   const form = new FormData()
    //   form.append("NameofPerson",this.UserForm.get('NameofPerson')?.value)
    //   form.append("MobileNo",this.UserForm.get('MobileNo')?.value)
    //   form.append("MuncipalArea",this.UserForm.get('MuncipalArea')?.value)
    //   form.append("Locality",this.UserForm.get('Locality')?.value)
    //   form.append("ExactPlace",this.UserForm.get('ExactPlace')?.value)
    //   form.append("LandMark",this.UserForm.get('LandMark')?.value)
    //   form.append("NatureofJob",this.UserForm.get('NatureofJob')?.value)
    //   this.Service.insert(form)
	  	//   this.UserForm.reset()
    //   this.submitted =false
       
    // } 


  submit(){

         this.submitted=true
     if(this.UserForm.get('Captcha')?.value != this.captcha){
        this.UserForm.get('Captcha')?.setValue('')
      }
     this.captchaGenerate()
      if(this.UserForm.invalid){  
        return
      }
      this.Service.insert(this.UserForm.value).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response))
       // console.log(data)
          this.UserForm.reset()
          this.submitted =false
        
      })

    

    

    

    

  }
     
  reset(){
   
    this.UserForm.reset()
	this.muncipaldata=[]
    this.naturedata=[]
    setTimeout(() => {

    }, 300);
	 this.submitted=false

  }
}

