<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Blogs</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page"><a href="#">Blogs</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="rs-inner-blog innerpage-content-section">
    <div class="container custom">
        <div class="row ">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="widget-area">
                    <div class="search-widget mb-50">
                        <div class="search-wrap">
                            <input type="search" placeholder="Searching..." name="s" class="search-input" value="">
                            <button type="submit" value="Search"><i class="flaticon-search"></i></button>
                        </div>
                    </div>
                </div>

                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Recent Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2022 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2022 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2022 </span>
                                </div>
                            </div>
                            <div class="latest-post-widget no-border mb-30">
                                <div class="post-img">
                                    <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                </div>
                                <div class="post-desc">
                                    <a href="#"> Price revision October 4, 2022 The price of domestically produced APM
                                        gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2022 </span>
                                </div>
                            </div>

                            <a href="#" class="greenbtn2 d-block">View All <i class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>

                </div>

                <div class="blog-cat widget-area">
                    <div class="categories mt-30">
                        <h3 class="">Categories</h3>
                        <a href="#">CNG</a>
                        <a href="#">PNG</a>
                        <a href="#"> Commercial </a>
                        <a href="#">Environment</a>
                        <a href="#">CNG</a>
                        <a href="#">PNG</a>
                        <a href="#"> Commercial </a>
                        <a href="#">Environment</a>
                        <a href="#">CNG</a>
                        <a href="#">PNG</a>
                        <a href="#"> Commercial </a>
                        <a href="#">Environment</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="blog-details">
                            <div class="bs-img mb-35">
                                <a href="#"><img src="assets/images/gallery/_51_a8ef8d997f.jpg" alt=""></a>
                            </div>
                            <div class="blog-full">
                                <ul class="single-post-meta">
                                    <li>
                                        <span class="p-date"><i class="fa fa-calendar-check-o"></i> January 21,
                                            2020 </span>
                                    </li>
                                    <li>
                                        <span class="p-date"> <i class="fa fa-user-o"></i> admin </span>
                                    </li>
                                    <li class="Post-cate">
                                        <div class="tag-line">
                                            <i class="fa fa-book"></i>
                                            <a href="#">Strategy</a>
                                        </div>
                                    </li>
                                    <li class="post-comment"> <i class="fa fa-comments-o"></i> 1</li>
                                </ul>
                                <p>
                                    We denounce with righteous indige nation and dislike men who are so beguiled
                                    and demo realized by the charms of pleasure of the moment, so blinded by
                                    desire, that they cannot foresee the pain and trouble that are bound to
                                    ensue cannot foresee. These cases are perfectly simple and easy to
                                    distinguish. In a free hour, when our power of choice is untrammelled data
                                    structures manages data in technology.
                                </p>
                                <blockquote>
                                    <p class="margin-0">We can easily manage if we will only take, each day, the
                                        burden appointed to it. But the load will be too heavy for us if we
                                        carry yesterday’s burden over again today, and then add the burden of
                                        the morrow before we are required to bear it.<br>
                                        <cite>Robert Calibo</cite>
                                    </p>
                                </blockquote>
                                <h3>Easy tips to build credit for your business</h3>
                                <ul class="dots">
                                    <li>How will digital activities impact traditional manufacturing.</li>
                                    <li>All these digital elements and projects aim to enhance .</li>
                                    <li>I monitor my staff with software that takes screenshots.</li>
                                    <li>Laoreet dolore magna niacin sodium glutimate aliquam hendrerit.</li>
                                    <li>Minim veniam quis niacin sodium glutimate nostrud exerci dolor.</li>
                                </ul>
                                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit
                                    quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda
                                    est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis
                                    debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae
                                    sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente
                                    delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
                                    perferendis doloribus asperiores repellat.</p>
                                <div class="bs-img mb-30">
                                    <a href="#"><img src="assets/images/gallery/_50_f84e9c2af5.jpg" alt=""></a>
                                </div>
                                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                                    sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                                    nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                                    consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt
                                    ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
                                    veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi
                                    ut aliquid ex ea commodi consequatur.</p>

                                <h3 class="comment-title">Leave a Reply</h3>
                                <p>Your email address will not be published. Required fields are marked *</p>
                                <div class="comment-note">
                                    <div id="form-messages"></div>
                                    <form id="contact-form" method="post"
                                        action="https://reactheme.com/products/html/bizup/mailer.php">
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-lg-6 mb-35 col-md-6 col-sm-6">
                                                    <input class="from-control" type="text" id="name" name="name"
                                                        placeholder="Name*" required="">
                                                </div>
                                                <div class="col-lg-6 mb-35 col-md-6 col-sm-6">
                                                    <input class="from-control" type="text" id="email" name="email"
                                                        placeholder="E-Mail*" required="">
                                                </div>
                                                <div class="col-lg-12 mb-30">
                                                    <textarea class="from-control" id="message" name="message"
                                                        placeholder="Your message Here" required=""></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <input class="readon post" type="submit" value="Post Comment">
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->