<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>MGL Life Saving Rules</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class="active" aria-current="page">MGL Life Saving Rules</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">


                    

                       
                            <h6>At MGL, everyone has a duty and responsibility to ensure that Life Saving Rules are
                                enforced.</h6>
                                <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                         <div class="authorization-required">
                                        <div class="main-head-a mb-20">AUTHORIZATION REQUIRED</div> 
                                        <div class="services-items mb-30 blue-border-btm">
                                            <i><img src="assets/images/life-saving-rules/disabling-safety.png"></i>
                                            <p>Obtain authorisation before overriding or disabling safety critical equipment</p>
                                        </div>

                                        <div class="services-items mb-30 blue-border-btm">
                                            <i><img src="assets/images/life-saving-rules/management-plane.png"></i>
                                            <p>Follow prescribed Journey Management Plan</p>
                                        </div>

                                        <div class="services-items mb-30 blue-border-btm">
                                            <i><img src="assets/images/life-saving-rules/confined-Space.png"></i>
                                            <p>Obtain authorisation before entering a Confined Space</p>
                                        </div>

                                         <div class="services-items mb-30 blue-border-btm">
                                            <i><img src="assets/images/life-saving-rules/conduct-gas-test.png"></i>
                                            <p>Conduct gas test when required</p>
                                        </div>

                                        <div class="services-items mb-30 blue-border-btm">
                                            <i><img src="assets/images/life-saving-rules/work-permit.png"></i>
                                            <p>Work with valid work permit when required</p>
                                        </div>

                                        <div class="services-items mb-30 blue-border-btm">
                                            <i><img src="assets/images/life-saving-rules/ensure-risk-assesments.png"></i>
                                            <p>Ensure Risk Assessment completed & controls are in place before starting any job</p>
                                        </div>
                                        </div>                                         
                                        </div>

                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                         <div class="must-do">
                                        <div class="main-head-a mb-20">Must Do</div> 
                                        <div class="services-items mb-30 green-border-btm">
                                            <i><img src="assets/images/life-saving-rules/protecting-equipment.png"></i>
                                            <p>Verify isolation before work begins and use the specific life protecting equipment</p>
                                        </div>

                                        <div class="services-items mb-30 green-border-btm">
                                            <i><img src="assets/images/life-saving-rules/ppe-compliance.png"></i>
                                            <p>Protect yourself against any untoward incident at all places by ensuring required PPE Compliance</p>
                                        </div>

                                        <div class="services-items mb-30 green-border-btm">
                                            <i><img src="assets/images/life-saving-rules/working-at-height.png"></i>
                                            <p>Protect yourself against a fall when working at height</p>
                                        </div>

                                         <div class="services-items mb-30 green-border-btm">
                                            <i><img src="assets/images/life-saving-rules/sweeping.png"></i>
                                            <p>Ensure all Safety Compliances while doing Excavation</p>
                                        </div>

                                        <div class="services-items mb-30 green-border-btm">
                                            <i><img src="assets/images/life-saving-rules/ensure-hazard.png"></i>
                                            <p>Ensure Hazards & Nearmiss are reported to line manager for corrective actions</p>
                                        </div>

                                        <div class="services-items mb-30 green-border-btm">
                                            <i><img src="assets/images/life-saving-rules/seat-belt.png"></i>
                                            <p>Wear your seat belt</p>
                                        </div>

                                        </div>                                         
                                        </div>

                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                         <div class="must-not-do">
                                        <div class="main-head-a mb-20">Must Not Do</div> 
                                        <div class="services-items mb-30 red-border-btm">
                                            <i><img src="assets/images/life-saving-rules/do-not-walk.png"></i>
                                            <p>Do not walk/stand under a suspended load</p>
                                        </div>

                                        <div class="services-items mb-30 red-border-btm">
                                            <i><img src="assets/images/life-saving-rules/no-alcohol.png"></i>
                                            <p>No alcohol or drugs while working or driving</p>
                                        </div>

                                        <div class="services-items mb-30 red-border-btm">
                                            <i><img src="assets/images/life-saving-rules/do-not-smoke.png"></i>
                                            <p>Do not smoke in designated No Smoking Areas</p>
                                        </div>

                                         <div class="services-items mb-30 red-border-btm">
                                            <i><img src="assets/images/life-saving-rules/speed-limit.png"></i>
                                            <p>While driving, do not use your phone and do not exceed speed limits</p>
                                        </div>

                                       

                                        
                                        </div>                                         
                                        </div>
                                </div>

                            <!-- <div class="life-saversmodi">
                                <div class="block"><img
                                        src="assets/images/_mgl-life-savers-img01_56a9b023a6.jpg"
                                        style="max-width: 282px;"></div>
                                <div class="block"><img
                                        src="assets/images/_mgl-life-savers-img02_3145a2cbf1.jpg"
                                        style="max-width: 288px;"></div>
                                <div class="block"><img
                                        src="assets/images/_mgl-life-savers-img03_94c06bad93.jpg"
                                        style="max-width: 283px;"></div>
                            </div> -->
                            <!--<div class="con"><h2>At MGL everyone has a DUTY and RESPONSIBILITY to ensure that Life Savers are enforced. </h2><div class="life-savers" style="text-align:center;"><div class="hexagon_box"><img src="../Images/DrivingSafety-icon.png" /><span class="space">01</span><p>Driving Safety</p></div><div class="hexagon_box2"><img src="../Images/WorkingHeights-icon.png" /><span class="space">02</span><p>Working at Heights</p></div><div class="hexagon_box3"><img src="../Images/Safesy-stem-of-works-icon.png" /><span class="space">03</span><p>Safe system of works </p></div><div class="hexagon_box4"><img src="../Images/Confined-Space-Entry-icon.png" /><span class="space">04</span><p>Confined Space Entry</p></div><div class="hexagon_box5"><img src="../Images/Ground-Excavation-icon.png" /><span class="space">05</span><p>Ground Excavation</p></div><div class="hexagon_box6"><img src="../Images/Lifting-Operations-icon.png" /><span class="space">06</span><p>Lifting Operations</p></div><div class="hexagon_box7"><img src="../Images/electrical-safety-icon.png" /><span class="space">07</span><p>Electrical Safety</p></div> </div> </div>-->
                       

                   










                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>