import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AreaResp } from '../models/area';
import { OutletResp } from '../models/outlet';
@Injectable({
  providedIn: 'root'
})
export class CnglocatorService {
  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  getoutlet(Location:string,AreaName:string) {
    return this.http.post<OutletResp>(`${this.baseUrl}outlet/cngfilling`, {Location:Location,AreaName:AreaName})
      .pipe(map(user => {
        return user; 
      }))
  } 
  getlocation():Observable<AreaResp[]> {
    return this.http.get<AreaResp[]>(this.baseUrl+'area/get_location');
  }
  getarea(Location:string) {
    return this.http.post<AreaResp[]>(this.baseUrl+'area/get_locationarea', { Location: Location} )
      .pipe(map(user => {
        return user;
      }))
  }

}