import { AfterViewInit, Component, HostListener, Inject, ElementRef } from '@angular/core';
import { GalleryServiceService } from 'src/app/Service/gallery-service.service';
import { DOCUMENT } from '@angular/common';
import getVideoId from 'get-video-id';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-videogallery',
  templateUrl: './videogallery.component.html',
  styleUrls: ['./videogallery.component.css']
})
export class VideogalleryComponent {
  allVideo: any[] = []
  Popup:any=''
  videoID:any=''
  safeSrc: SafeResourceUrl = '';
  display:string='none'
  constructor(private GalleryService: GalleryServiceService, public sanitizer:DomSanitizer, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getAllVideo()
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
  
  getAllVideo() {
    this.GalleryService.getallVideo().subscribe(response => {
      var data = JSON.parse(JSON.stringify(response)).details;
      this.allVideo = data
    })
  }

  getID(url: string){
    const op = getVideoId(url);
    return op.id;
  }

  openPopup(val:any){
   // console.log(val)
    this.Popup='show'
    this.display='block'
    const op = getVideoId(val);
    this.videoID = op.id;
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+this.videoID);
  }

  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }
}


