import { Component } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PayuService } from 'src/app/Service/payu.service';

@Component({
  selector: 'app-pay-upaymentsuccess',
  templateUrl: './pay-upaymentsuccess.component.html',
  styleUrls: ['./pay-upaymentsuccess.component.css']
})
export class PayUpaymentsuccessComponent {
  id: any;
  amt:any;
   data:any;
   walletData:any;
   latestData:any;
   walletPaymentOrderNo:any;
  constructor(public datePipe: DatePipe, public UserService: UserRegistrationService, private route: ActivatedRoute,
    public payuService: PayuService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.amt = this.route.snapshot.paramMap.get('amt');
    this.amt = this.amt*100;
   // console.log( this.id)
    // console.log( this.amt)
     let data = sessionStorage.getItem('UserCredential')
    // console.log(data)
     this.walletPaymentOrderNo=sessionStorage.getItem('walletPaymentOrderNo')
     // console.log(this.walletPaymentOrderNo)
     
    if(data){
       this.data=JSON.parse(data)
     
         this.findpayUcreatewalletdata();
         //this.getLatestPayadddata()
     
    }
  } 




  
   findpayUcreatewalletdata() {
    let obj={"MobileNo":this.data.Mobile}
     this.payuService.checkwallet(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.walletData = res.details.LstWallet_Response;
       // console.log(this.walletData[0].urn)
         this.addBalance()
       } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


  getLatestPayadddata() {
    let obj={"pay_tran_id":this.id}
     this.payuService.getLatestPayadddata(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.latestData = res.details;
       // console.log(this.latestData)
        this.payupaymentcomplete_wallet();
       } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }




  payupaymentcomplete_wallet() {
    let obj={"id":this.latestData[0].mihpayid,"mode":this.latestData[0].mode,"status":this.latestData[0].payment_status,
    "unmappedstatus":this.latestData[0].unmappedstatus,"key":this.latestData[0].key,
    "txnid":this.latestData[0].pay_tran_id,"transaction_fee":this.latestData[0].transaction_fee,
    "amount":this.latestData[0].amount,
    "discount":this.latestData[0].discount,"addedon":this.latestData[0].addedon,
    "productinfo":this.latestData[0].productinfo,"firstname":this.latestData[0].firstname,
    "email":this.latestData[0].email,
    "phone":this.latestData[0].phone, "hash":this.latestData[0].hash,
    "field9":this.latestData[0].message,"payment_source":this.latestData[0].payment_source,
    "PG_TYPE":this.latestData[0].PG_TYPE,"bank_ref_no":this.latestData[0].bank_ref_num,
    "ibibo_code":this.latestData[0].bankcode,
    "error_code":this.latestData[0].error_code,
    "Error_Message":this.latestData[0].error_Message,"OrderNo":this.walletPaymentOrderNo}
     this.payuService.payupaymentcomplete_wallet(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        
       } else {
        
      }
     }, err => {
     // console.log(err)
    })

  }

  //  findpayUcreatewalletdata() {
  //   let obj={"CANo":this.data.CaNo,"MobileNo":this.data.Mobile,
  //   "Email":this.data.Email}
  //    this.payuService.findpayUcreatewalletdata(obj).subscribe(response => {
  //     console.log(response);
  //     let res = JSON.parse(JSON.stringify(response))
  //     if (res.response == true) {
  //       this.walletData = res.details;
  //       console.log(this.walletData[0].urn)
  //        this.addBalance()
      
  //     } else {
        
  //     }
  //    }, err => {
  //     console.log(err)
  //   })

  // }

   addBalance(){

   //  console.log('enter')
      var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
    // console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
 //console.log(text3)
 //console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;

 

    // let obj={"clientTxnId":this.walletData[0].clientTxnId,"requestDateTime":savetime,
    // "urn":this.walletData[0].urn,"transactionAmount":this.addmoneyForm.get('amount')?.value,
    // "subWalletId":this.walletData[0].subwallet_id,"sourceAccount":this.walletData[0].account_number}

      let obj={"clientTxnId":this.id,"requestDateTime":savetime,
    "urn":this.walletData[0].urn,"transactionAmount":this.amt,
    "subWalletId":this.walletData[0].subwallet_id,"sourceAccount":this.walletData[0].account_number}
     this.payuService.load_wallet_money_encrypt(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.wallet_money_loadAccount(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }

    wallet_money_loadAccount(data:any) {
    let obj={"token":data}
     this.payuService.wallet_money_loadAccount(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      this.load_wallet_money__decrypt(res.details.token)
      
      } else {
        alert(res.message)
      }
     }, err => {
     // console.log(err)
    })

  }


   load_wallet_money__decrypt(data:any) {
    let obj={"token":data}
     this.payuService.load_wallet_money__decrypt(obj).subscribe(response => {
   //   console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
     //  console.log(res.details);
        //this.get_wallet_balance_encrypt();
        if(res.details.responseMessage=="CARD TEMPORARY BLOCK"){
          alert(res.details.responseMessage)
        }else if(res.details.responseMessage=="DUPLICATE REQUEST"){
           alert(res.details.responseMessage)
        }
      
      } else {
        
      }
     }, err => {
    //  console.log(err)
    })

  }
} 