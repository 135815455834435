<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Benefits</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a  >Industrial & Commercial   </a></li>
                <li class=""><a  >Commercial PNG</a></li>
                <li class=""><a  >Overview</a></li>
                <li class="active" aria-current="page"><a>Benefits</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-commercial-service-section></app-commercial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <app-facebook-post></app-facebook-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <p>Piped Natural Gas (PNG)  is the preferred fuel these days primarily because of the many benefits of natural gas.</p>

                    <div class="light-blue-bg mt-20 csr-policies">
                        <h5>Some benefits of PNG are</h5>
                        <div id="rs-services" class="rs-services style4">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/convenient.png"
                                                alt="Convenient">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Convenient</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover"
                                                src="assets/images/careers/environment-friendly.png"
                                                alt="Environment Friendly">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Environment Friendly</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover"
                                                src="assets/images/careers/Better-fuel-efficiency.png"
                                                alt="MGL Connect">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Better Fuel Efficiency</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/versatile.png"
                                                alt="Versatile">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Versatile</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/reliable.png"
                                                alt="Reliable">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Reliable</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/safe.png" alt="Safe">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Safe</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>