<!--Full width header End-->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Car Maintenance</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a href="#">CNG</a></li>
                <li class=""><a href="#">Customer Zone </a></li>
                <li class="active" aria-current="page">Car Maintenance</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->
                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <h6>CNG Car Maintenance</h6>
                    <p>The following precautions are to be followed during servicing and repairing of CNG vehicles which
                        is advisable to do after every 6 months for better performance and mileage of the vehicle.</p>
                    <div class="blue-tick-list">
                        <ul>
                            <li>In case of vehicles undergoing repairs involving welding, or heat application to any
                                part
                                (within 1.5 m) of the cylinder, the cylinder should be emptied first.</li>
                            <li>In case of leakage in fuel system, vehicles shall not be parked within 6 m of any source
                                of
                                ignition or fire.</li>
                        </ul>
                    </div>

                    <div class="light-yellow-bg mt-30 customer-care-overview-icon">
                        <div id="rs-services" class="rs-services style4">
                            <h6>Do’s and Don’ts!</h6>
                            <div class="row">
                                <div class="col-lg-3 col-md-6 md-mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/suppliers-kit.png"
                                                alt="Supplier’s">
                                        </div>
                                        <div class="services-content">
                                            <p class="margin-0"> Always refer to the supplier’s kit manual for the
                                                trouble-shooting guide. Do not do it yourself.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 md-mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/lpg-cylinder.png"
                                                alt="install a LPG">
                                        </div>
                                        <div class="services-content">
                                            <p class="margin-0"> Do not install a LPG, Propane or any other cylinder in
                                                place of a CNG cylinder. It is illegal and unsafe.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 sm-mb-30">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/cng-lick.png"
                                                alt="emergency handling">
                                        </div>
                                        <div class="services-content">
                                            <p class="margin-0"> For emergency handling of any CNG leak, users must be
                                                aware of the location and operation of cylinder valve, master shut-off
                                                valve and burst disc in the CNG system. Study the system and ask your
                                                mechanic to identify these parts for you.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/vehicle.png"
                                                alt="Retrofitter">
                                        </div>
                                        <div class="services-content">
                                            <p class="margin-0">Retrofitter doing the kit fitment should be able to
                                                demonstrate these operations to your satisfaction. It is advisable to
                                                operate the vehicle occasionally on petrol to ensure that the petrol
                                                system remains in good working conditions.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="mt-30">
                        <h6>Other Precautions For Your Vehicle:</h6>
                        <div class="blue-tick-list">
                            <ul>
                                <li>The CNG kit installed in the vehicle should be insured along with vehicle
                                    accessories.
                                    The
                                    motorist should notify the insurance company to provide insurance on the CNG system,
                                    for
                                    which additional premium may be charged by the insurance company. Motorists should
                                    take
                                    the
                                    insurance cover for the additional CNG kit system.</li>

                                <li>As per present Government regulation the pollution checks and a pollution
                                    certificate is
                                    mandatory even after converting the vehicle on CNG.</li>

                                <li>A pressurized gas cylinder is probably the strongest component on the vehicle.
                                    Vehicles
                                    that
                                    were totally destroyed in collisions show the only discernible component being the
                                    intact
                                    gas cylinder. It is unlikely that cylinders will rupture due to collision impact.
                                </li>
                                <li>Regarding the danger of fire from leaking cylinders, all we have is the experience
                                    to
                                    date
                                    that indicates that such an event is unlikely to occur. The risk of fire from
                                    leaking
                                    cylinders must be low since there are well over 03 million CNG vehicles worldwide
                                    that
                                    have
                                    not experienced such problems.</li>

                                <li>Natural Gas is lighter than air and in the unlikely event of a leak from piping or
                                    container
                                    the gas will dissipate upwards quite quickly. In case of petrol and LPG, the vapour
                                    given
                                    off is heavier than air and will tend to pool near the ground.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>