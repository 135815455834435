import { Component } from '@angular/core';

@Component({
  selector: 'app-mglcorporatebroucher',
  templateUrl: './mglcorporatebroucher.component.html',
  styleUrls: ['./mglcorporatebroucher.component.css']
})
export class MglcorporatebroucherComponent {

}
