

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Registered & Corporate Office Address</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                 <li class=""><a>Contact Us</a></li>
                <li class="active" aria-current="page">Registered & Corporate Office Address</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>
                <app-news-comp></app-news-comp>

                <div class="mt-30">
                  <!--   <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>

                    </div> -->


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content ">


                    <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-12">

                        <div class="share-location-section">
                            <div class="location-details w-100">
                            <h6>MGL House</h6>
                            <ul>
                                <li><i class="demo-icon uil-location-point"></i> Block G-33,<br>
                                Bandra Kurla Complex,<br>
                                Bandra (East)<br>
                                Mumbai-400051<br></li>
                                <li><i class="demo-icon uil-calling"></i> <strong>Tel. No.</strong> 6678 5000</li>                                 
                                <!--<strong>Fax. No.</strong> 2654 0092<br />--> 
                                <li><i class="demo-icon uil-envelope-alt"></i> <strong>Email id :</strong><a href="mailto:info@mahanagargas.com">info@mahanagargas.com</a></li>
                                <li><i class="demo-icon uil-file-alt"></i> <strong>CIN :</strong> L40200MH1995PLC088133</li>

                            </ul>

                            </div>
                            <!--<iframe scrolling="No" marginheight="0" marginwidth="0" src="/registered-corporate-office.html" width="100%" height="600px" frameborder="0"></iframe>-->
                        </div>
</div>










                    </div>
                </div>
            </div>
        </div>
    </div>







    <!-- Inner Page Content Section End -->






 