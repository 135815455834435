import { Injectable } from '@angular/core';
import { map, Observable} from 'rxjs';
import { SayyogiResp } from '../models/sayyogi';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SayyogiService {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getAll():Observable<SayyogiResp[]> {
    return this.http.get<SayyogiResp[]>(this.baseUrl+'sayyogi/get_sayyogis');
  }
  getMuncipal():Observable<SayyogiResp[]> {
    return this.http.get<SayyogiResp[]>(this.baseUrl+'muncipal/get_MunicipalArea');
  }
  getNature():Observable<SayyogiResp[]> {
    return this.http.get<SayyogiResp[]>(this.baseUrl+'Naturejob/NatureofJob');
  }
//   insert(data:any) {
//     console.log(data)
//     this.http.post<SayyogiResp>(`${this.baseUrl}sayyogi/insert_sayyogis`,data).subscribe(()=>{
//       return alert("inserted  successfully")
//     })
// }


 insert(data:any){
    return this.http.post(this.baseUrl+'sayyogi/insert_sayyogis',data)
  }

}
