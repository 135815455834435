import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-if-you-smell-gas',
  templateUrl: './if-you-smell-gas.component.html',
  styleUrls: ['./if-you-smell-gas.component.css']
})
export class IfYouSmellGasComponent {
  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

}
