import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { IcUsersService } from 'src/app/Service/ic-users/ic-users.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { AreaResp } from 'src/app/models/area';

@Component({
  selector: 'app-ic-bill',
  templateUrl: './ic-bill.component.html',
  styleUrls: ['./ic-bill.component.css']
})
export class IcBillComponent {
  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  data: any = ''

  UserForm: FormGroup
  otpForm: FormGroup
  Minute: any = 2
  Sec: any = 59
  public qrdata: any = null;
  captcha: any
  Otp: any
  OtpSent: boolean = false
  PopUp: boolean = false
  submitted: boolean = false;
  display: string = 'none'
  Popup: any = ''
  stopTimer: boolean = false
  OtpButtonActive: boolean = true
  btnStatus: boolean = false
  submitStatus: boolean = false
  otpSubmitted: boolean = false
  showOtp: boolean = false

  constructor(private FormBuilder: FormBuilder, public icUsersService: IcUsersService, private pageLoaderService: PageLoaderService, public userService: UserRegistrationService) {

  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.UserForm = this.FormBuilder.group({
      CAno: ['', [Validators.required, Validators.pattern(/^\d{12}$/)]],
      MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]],
    })

    this.otpForm = this.FormBuilder.group({
      InputOtp: ['', Validators.required]
    })
  }

  submit() {
    this.submitted = true
   // console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    this.icUsersService.getCANum(this.UserForm.controls['CAno'].value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.response) {
            this.OtpGenerate()
            this.openPopup()
          }
        }
      )
  }

  clearStatus() {
    setTimeout(() => {
      this.alertType = ''
    }, 3000)
  }

  openPopup() {
    this.Popup = 'show'
    this.display = 'block'
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
    this.stopTimer = true
  }

  verifyOtp() {
    this.otpSubmitted = true

    if (this.otpForm.invalid) {
      return
    }
    this.alertType = 'secSubmit'
   // console.log(this.Otp)
    //console.log(this.otpForm.controls['InputOtp'].value)
    if (this.Otp != this.otpForm.controls['InputOtp'].value) {

      this.alertMsg = "Otp Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
    } else {
      this.alertMsg = "Otp Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'

      this.icUsersService.getBill(this.UserForm.controls['CAno'].value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.response) {
            window.open("https://www.mahanagargas.com:3000/"+this.UserForm.controls['CAno'].value+".pdf", '_blank');
          }
        }
      )

      setTimeout(() => {
        this.clearAlert()
        this.showOtp = false
        this.closeModel()
      }, 1000);
    }
  }

  otpTimer() {
    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {
      sec--;
      if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0
        clearInterval(count)
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);

    var test = () => {
      if (this.stopTimer) {
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp = ''
        this.submitStatus = true
        clearInterval(count)
      }
      this.Minute = minute
      this.Sec = sec
    }
  }

  OtpGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
  //  console.log(result)
    this.Otp = result
    this.userService.sendOtp(this.Otp, this.UserForm.get('MobileNo')?.value)
    this.otpTimer()
    this.OtpButtonActive = true
    this.submitStatus = false
  }

  clearAlert() {
    this.alertType = ''
  }
}

