import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { AwardsService } from 'src/app/Service/awards.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-award-details',
  templateUrl: './award-details.component.html',
  styleUrls: ['./award-details.component.css']
})
export class AwardDetailsComponent {
  Data:any
  id:any

  baseUrl = environment.baseUrl

  constructor(private route: ActivatedRoute,private AwardsService : AwardsService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    const id = this.route.snapshot.paramMap.get('id');
    this.id=id
    this.getAllAwards()
  }

  getAllAwards(){

    this.AwardsService.getAll().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      //console.log(response)
      let filtered = data.filter((x:any)=>{
        return x._id == this.id
      })
      this.Data=filtered
      //console.log(this.Data)
    })

  }

}



