import { Component } from '@angular/core';

@Component({
  selector: 'app-apppayupaymentsuccess',
  templateUrl: './apppayupaymentsuccess.component.html',
  styleUrls: ['./apppayupaymentsuccess.component.css']
})
export class ApppayupaymentsuccessComponent {

}
