import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent {

  AddressData :any

  OtpButtonActive: boolean = true
  btnStatus:boolean=false
  display:string='none'
  Popup:any=''

  alertMsg:any=''
  alertColor : string=''
  alertTextColor : string=''
  alertBorder : string=''
  alertTextAlign: string = 'center'

  submitted: any = false
  OtpSubmitted:any = false
  UserForm !: FormGroup
  OtpForm !: FormGroup
  // OtpForm !: FormGroup({
  //   originalOtp: new FormControl(''),
  //   InputOtp: new FormControl('')
  // })

  Minute: any = 5
  Sec: any = 59
  alertType: string = '';

  constructor(private service: UserRegistrationService,private FormBuilder: FormBuilder,private UserService : UserRegistrationService,private router: Router, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    this.getAddress()

    this.UserService.checkLogin()

    this.OtpForm = this.FormBuilder.group({
      originalOtp: ['', Validators.required],
      InputOtp: ['', Validators.required]
    })

    this.UserForm = this.FormBuilder.group({
      Name : ['', Validators.required],
      Email : ['', Validators.required],
      MobileNo : ['',[Validators.required, Validators.pattern(/[0-9]{10}/)]],
      FlatNo: [''],
      Floor: [''],
      PlotNo: [''],
      Wing: [''],
      BuildingName: [''],
      SociatyName: [''],
      Colony: [''],
      RoadName: [''],
      LandMark: [''],
      Location: [''],
      CityDistrict: [''],
      Pincode: [''],
      CaNo : ['']
    })
  }


  otpTimer() {

    var minute = this.Minute;
    var sec = this.Sec;
    const count = setInterval(function () {

      sec--;

      if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0

        clearInterval(count)

      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }

      test()


    }, 1000);

    var test = () => {
      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.btnStatus = true
        
        clearInterval(count)

      }
      this.Minute = minute
      this.Sec = sec

    }

  }

  captchaGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    this.OtpForm.get('originalOtp')?.setValue(result)
    this.UserForm.get('Otp')?.setValue(result)
   // console.log(result)
  }

  resendOtp() {
    this.captchaGenerate()
    this.service.sendOtp(this.OtpForm.get('originalOtp')?.value, this.UserForm.get('MobileNo')?.value)
    this.OtpButtonActive = true
    this.alertType = 'secSubmit'
    this.alertMsg = "OTP Resend Succesful"
    this.alertColor = 'rgba(4, 183, 107, 0.2)'
    this.alertTextColor = '#038b51'
    this.alertBorder = '1px solid #04a862;'
    this.btnStatus = false
  }

  clearStatus(){
    this.alertType=''
  }

  verify() {

    this.OtpSubmitted = true

    if(this.OtpForm.invalid){
      return
    }

    if(this.OtpForm.get('originalOtp')?.value == this.OtpForm.get('InputOtp')?.value ){

      this.UserService.updateUserAddress(this.UserForm.value).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response))
        this.alertMsg = data.message
        if(data.Response){
  
          this.alertType = 'secSubmit'

      //this.alertMsg = "OTP Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
            
  
          setTimeout(()=>{
            this.router.navigate(['/login'])
          },2000)
  
        }else{
  
          this.alertColor="rgba(255, 94, 109, 0.2)"
          this.alertTextColor='#c24753'
          this.alertBorder='1px solid #eb5664;'
  
        }
      })
    

      
        
        setTimeout(() => {
          //this.dialog=false
          this.router.navigate(['/user-dashboard'])
          this.OtpForm.reset()
          this.closeModel()
        }, 500)
      

    }else{
      this.alertMsg = "OTP Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearStatus()
        this.OtpForm.reset()
        
      }, 500)
    }

    
  }


  openPopup(){
    this.Popup='show'
    this.display='block'
  }

  closeModel(){
    this.Popup=''
    this.display='none'
    
  }

  reset(){

    this.UserForm.get('Email')?.setValue('')
    this.UserForm.get('MobileNo')?.setValue('')

  }

  getAddress(){
    let data = (sessionStorage.getItem('UserCredential'))
    if(data){
      
      // console.log(JSON.parse(data).CaNo)
      this.UserService.getCaNo({CAno:JSON.parse(data).CaNo,BPno:''}).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response)).details
        this.AddressData = data
       // console.log(data)
        this.UserForm.get('Name')?.setValue(data.Name)
        this.UserForm.get('Email')?.setValue(data.Email)
        this.UserForm.get('MobileNo')?.setValue(data.Contact)
        this.UserForm.get('FlatNo')?.setValue(data.FlatNo)
        this.UserForm.get('Floor')?.setValue(data.Floor)
        this.UserForm.get('PlotNo')?.setValue(data.PlotNo)
        this.UserForm.get('Wing')?.setValue(data.Wing)
        this.UserForm.get('BuildingName')?.setValue(data.BuildingName)
        this.UserForm.get('SociatyName')?.setValue(data.SociatyName)
        this.UserForm.get('Colony')?.setValue(data.Colony)
        this.UserForm.get('RoadName')?.setValue(data.RoadName)
        this.UserForm.get('LandMark')?.setValue(data.LandMark)
        this.UserForm.get('Location')?.setValue(data.Location)
        this.UserForm.get('CityDistrict')?.setValue(data.CityDistrict)
        this.UserForm.get('Pincode')?.setValue(data.Pincode)
       



      })
    }
   
  }

  submit(){

    this.submitted=true
    if(this.UserForm.invalid){
      return
    }
    let data = (sessionStorage.getItem('UserCredential'))
    if(data){
     // console.log(JSON.parse(data).CaNo)
      this.UserForm.get('CaNo')?.setValue(JSON.parse(data).CaNo)
    }
    this.captchaGenerate()
    this.service.sendOtp(this.OtpForm.get('originalOtp')?.value, this.AddressData.Contact)
    this.OtpButtonActive = true
    this.otpTimer()
    this.openPopup()
    
    //console.log(this.UserForm.value)

  }

}
