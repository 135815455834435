<!-- Inner Page Content Section Start -->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Online <span>Payment</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>MGL Corporate</a></li>
                <li class="active" aria-current="page">Online Payment</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>


            </div>


            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">

                            <div class="customer-login-page">

                                <div class="log-meter-text">
                                    <ul>
                                        <li>* Kindly enter <b>CA</b> number as appearing on your bill.</li>
                                    </ul>
                                </div>
                                <div class="">
                                    <form [formGroup]="UserForm">
                                        <div class="mb-20 cu-login-input">
                                            <i class="demo-icon uil-file-landscape-alt"></i>
                                            <input
                                                [ngClass]="{'is-invalid':submitted && UserForm.controls['CAno'].errors}"
                                                formControlName="CAno" class="from-control" type="text" id="name"
                                                name="name" placeholder="Enter your CA Number" required="">

                                            <div *ngIf="submitted && UserForm.controls['CAno'].errors"
                                                class="invalid-feedback">
                                                <div style="white-space: nowrap;"
                                                    *ngIf="UserForm.controls['CAno'].errors">CA Number required
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-20 cu-login-input">
                                            <i class="demo-icon uil-lock-alt"></i>
                                            <input
                                                [ngClass]="{'is-invalid':submitted && UserForm.controls['Captcha'].errors}"
                                                formControlName="Captcha" class="from-control" type="text" id="name"
                                                name="name" placeholder="Enter Captcha" required="">

                                            <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                class="invalid-feedback">
                                                <div style="white-space: nowrap;"
                                                    *ngIf="UserForm.controls['Captcha'].errors">Captcha required
                                                </div>
                                            </div>
                                        </div>

                                        <div (copy)="false" class="mb-20 ">
                                            {{this.captcha}}

                                            <button style="background: transparent;border: none;"
                                                (click)="captchaGenerate()"><i class="fa fa-repeat"
                                                    aria-hidden="true"></i></button>

                                        </div>




                                        <div class="mb-20">
                                            <button (click)="submit()" style="border: none;" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>

                                    </form>

                                    <div *ngIf="alertType == 'CA'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                    <hr />


                                    <ul>

                                        <div *ngIf="data != ''">
                                            <li>User Information</li>
                                            <div style="display :flex; margin-top: 20px;"
                                                class="col-lg-6 col-md-6 col-sm-12">
                                                <label style="white-space:nowrap">Name :-</label>
                                                <h6 style="margin-left: 100px;">{{data[0].name}}</h6>
                                            </div>
                                            <div style="display :flex; margin-top: 20px;"
                                                class="col-lg-6 col-md-6 col-sm-12">
                                                <label style="white-space:nowrap">BP No :-</label>
                                                <h6 style="margin-left: 100px;">{{data[0].BPno}}</h6>
                                            </div>

                                            <div style="display :flex; margin-top: 20px;"
                                                class="col-lg-6 col-md-6 col-sm-12">
                                                <label style="white-space:nowrap">CA No :-</label>
                                                <h6 style="margin-left: 100px;">{{data[0].CAno}}</h6>
                                            </div>
                                            <hr />
                                            <div *ngIf="!OtpSent">
                                                <div class="mb-20 cu-login-input">
                                                    <i class="demo-icon uil-file-landscape-alt"></i>
                                                    <input onKeyPress="if(this.value.length==10) return false;" #num
                                                        (change)="this.MobileNo=num.value" class="from-control"
                                                        type="number" id="name" name="name" placeholder="Enter Number"
                                                        required="">


                                                </div>
                                                <div class="mb-20">
                                                    <button (click)="sendOtp()" style="border: none;"
                                                        class="greenbtn2">Submit <i
                                                            class="demo-icon uil-arrow-right"></i></button>
                                                </div>

                                                <div *ngIf="alertType == 'sendOtp'"
                                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}"
                                                    class="card card-inverse-info" id="context-menu-simple">
                                                    <div class="card-body">
                                                        <p class="card-text">
                                                            {{alertMsg}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="mb-20 cu-login-input">
                                                    <i class="demo-icon uil-file-landscape-alt"></i>
                                                    <input #num2
                                                        (change)="this.UserForm.get('Otp')?.setValue(num2.value)"
                                                        class="from-control" type="text" id="name" name="name"
                                                        placeholder="Enter Otp" required="">


                                                </div>
                                                <div style="display: flex;">
                                                    <div class="mb-20">
                                                        <button (click)="verifyOtp()" style="border: none;"
                                                            class="greenbtn2">Verify<i
                                                                class="demo-icon uil-arrow-right"></i></button>
                                                    </div>
                                                    <div style="margin-left: 10px;" class="mb-20">
                                                        <button [disabled]="OtpButtonActive" (click)="sendOtp()" style="border: none;"
                                                            class="greenbtn2">{{OtpButtonActive ? this.Minute+":"+this.Sec:
                                                            "Resend"}}<i
                                                                class="demo-icon uil-arrow-right"></i></button>
                                                    </div>
                                                </div>
                                                <div *ngIf="alertType == 'verifyOtp'"
                                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}"
                                                    class="card card-inverse-info" id="context-menu-simple">
                                                    <div class="card-body">
                                                        <p class="card-text">
                                                            {{alertMsg}}
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>

<div *ngIf="PopUp" class="boxx">
    <div class="mb-20">
        <button style="border: none;" class="greenbtn2"><a
                href="https://paytm.com/gas-bill-payment/Mahanagar-Gas--Mumbai" (click)="PopUp=false" target="_blank">Pay Now By
                Paytm</a></button>
    </div>

    <div class="mb-20">
        <button style="border: none;" class="greenbtn2"><a
                href="https://payments.billdesk.com/MercOnline/mglBillPay/mgl_billpayment.html" (click)="PopUp=false" target="_blank">Pay Now
                By Billdesk</a></button>
    </div>


</div>