import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { SliderService } from 'src/app/Service/slider.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-track-name-transfer-req',
  templateUrl: './track-name-transfer-req.component.html',
  styleUrls: ['./track-name-transfer-req.component.css']
})
export class TrackNameTransferReqComponent {

  public base = environment.baseUrl

  allPost: any[] = [];


  rb1Open:boolean=false
  rb2Open:boolean=false
  captcha:any

  status:boolean = false

  submitted=false
  UserForm1!:FormGroup

  Data:any[]=[]

   checkOtpForm!: FormGroup;
    OtpSubmitted = false;
     submitStatus:boolean=false;
  display:string='none';
  Popup:any='';
  stopTimer:boolean=false;
  OtpButtonActive: boolean = false;
  Minute: any = 1;
  Sec: any = 10;
  Otp: any;

    alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';
  
  constructor(public router: Router,private PostService: SliderService,private FormBuilder : FormBuilder,private Service:FormServiceService,
     public UserService: UserRegistrationService,){}

  ngOnInit(): void {
    this.captchaGenerate()
    this.getAllHighlight()
    this.UserForm1 = this.FormBuilder.group({
      ApplicationNo:['',[Validators.required]],
      MobileNo: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      captcha:['',[Validators.required]]
    })

      this.checkOtpForm = this.FormBuilder.group({
      Otp: ['', Validators.required]
    })

    
    
  }

  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    
      this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
      

    })
    
  }


  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }


  

  submit1(){

    this.submitted=true
    this.status=false

    if(this.UserForm1.get('captcha')?.value != this.captcha){
      this.UserForm1.get('captcha')?.setValue('')
    }
  this.captchaGenerate()

    if(this.UserForm1.invalid){
      return
    }

       this.Service.getNameTransferStatus(this.UserForm1.value).subscribe(response=>{
       // this.Service.getNameTransferStatusbyBPno(this.UserForm1.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))


      // this.Data = data
      // this.status=true

        if (data.Response==true) {
         this.Data = data.details;
       
        // this.router.navigate(['/residential-png/customer-zone/name-transfer/name-transfer-details'],
        // {queryParams:{data:JSON.stringify(this.Data)}})
   
          // this.otpTimer()
          // this.OtpButtonActive = true
          // this.submitStatus=false
           this.OtpGenerate()
           this.openPopup();


        // this.status=true
      }else{
        this.Data = data.details;
        this.status=true
      }

    })
  }


  //  resendOtp(){

   
  //      this.Service.getNameTransferStatus(this.UserForm1.value).subscribe(response=>{
  //      // this.Service.getNameTransferStatusbyBPno(this.UserForm1.value).subscribe(response=>{
  //     let data = JSON.parse(JSON.stringify(response))
  //       if (data.Response==true) {
  //          this.otpTimer()
  //         this.OtpButtonActive = true
  //         this.submitStatus=false
  //          // this.openPopup();


  //       // this.status=true
  //     }else{
  //       // this.Data = data.details;
  //       // this.status=true
  //     }

  //   })
  // }


    OtpGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
   // console.log(result)
    this.Otp = result
    this.UserService.sendOtp(this.Otp, this.UserForm1.get('MobileNo')?.value)
    this.otpTimer()
    this.OtpButtonActive = true
    this.submitStatus = false
  }



    otpTimer() {

    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {
     sec--;
   if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0

        clearInterval(count)
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);

    var test = () => {
      if(this.stopTimer){
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp=''
        this.submitStatus=true
        clearInterval(count)
      }
      this.Minute = minute
      this.Sec = sec
    }
  }

  openPopup(){
    this.Popup='show';
    this.display='block';
    }

  closeModel(){
    this.Popup='';
    this.display='none';
    this.stopTimer=true;
    
  }

  clearAlert() {
    this.alertType = ''
  }


   verifyOtp() {

    this.OtpSubmitted = true

    if (this.checkOtpForm.invalid) {
      return
    }
    this.alertType = 'secSubmit'
    if (this.Otp != this.checkOtpForm.get('Otp')?.value) {

      this.alertMsg = "OTP Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
    } else {
      this.alertMsg = "OTP Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
  
      setTimeout(() => {
        this.clearAlert()
        this.closeModel()
        this.router.navigate(['/residential-png/customer-zone/name-transfer/name-transfer-details'],
        {queryParams:{data:JSON.stringify(this.Data)}})
      }, 1000);
    }
  }

  // verifyOtp() {
  //   this.OtpSubmitted = true
  //   if (this.checkOtpForm.invalid) {
  //     return
  //   }
  //   this.alertType = 'secSubmit';
  //    let obj = {'ApplicationNo':this.UserForm1.get('ApplicationNo')?.value,
  //   'MobileNo':this.UserForm1.get('MobileNo')?.value,
  //   'otp':this.checkOtpForm.get('Otp')?.value}

  //    this.Service.getNameTransferStatus(obj).subscribe(response=>{
  //     let data = JSON.parse(JSON.stringify(response))
  //     if (data.Response==true) {
  //      this.Data = data.details;
       
      

  //     this.alertMsg = "Otp Verified"
  //     this.alertColor = 'rgba(4, 183, 107, 0.2)'
  //     this.alertTextColor = '#038b51'
  //     this.alertBorder = '1px solid #04a862;'
  //     // this.ShowGo = true
  //     setTimeout(() => {
  //       this.clearAlert()
  //       // this.ShowOtp = false
  //       this.closeModel()
  //         this.router.navigate(['/residential-png/customer-zone/name-transfer/name-transfer-details'],
  //       {queryParams:{data:JSON.stringify(this.Data)}})
       
        
       
  //     }, 1000);
        

  //     } else {

  //     this.alertMsg = "Otp Did Not Match"
  //     this.alertColor = 'rgba(255, 94, 109, 0.2)'
  //     this.alertTextColor = '#c24753'
  //     this.alertBorder = '1px solid #eb5664;'
  //     setTimeout(() => {
  //       this.clearAlert()
  //     }, 1000);
       
  //     }



  //   }, err => {
  //     console.log(err)
  //   })

  // }



  
}
