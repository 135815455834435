<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Drop Box</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Care</a></li>
                <li class="active" aria-current="page">Drop Box</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                   <!--  <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 d-block">View All <i
                                        class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>

                    </div> -->


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

                            <div class="customer-login-page log-meter-reading">
                                <div class="log-meter-text">
                                    <ul>
                                        <li>Search by -
                                        </li>

                                    </ul>


                                </div>
                                <form [formGroup]="dropboxForm">
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12 col-sm-12">


                                            <div class="form-floating mb-3">
                                                <select class="form-select" aria-label="Default select example"
                                                    [ngClass]="{'is-invalid':submitted && dropboxForm.controls['City'].errors}"
                                                    #citys
                                                    (change)="this.dropboxForm.get('City')?.setValue(citys.value)"
                                                    (change)="getAreaByCity(citys.value)">
                                                    <option selected value="">Select City</option>
                                                    <option *ngFor="let i of AllData" value="{{i}}">{{i}}</option>

                                                </select>
                                                <div *ngIf="submitted && dropboxForm.controls['City'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && dropboxForm.controls['City'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="City">City</label>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <select class="form-select" aria-label="Default select example"
                                                    [ngClass]="{'is-invalid':submitted && dropboxForm.controls['Area'].errors}"
                                                    #Area (change)="this.dropboxForm.get('Area')?.setValue(Area.value)"
                                                    (change)="getBankList(this.dropboxForm.get('Area')?.value)">
                                                    <option selected value="">Select Area</option>
                                                    <option *ngFor="let i of AllCity">{{i}}</option>

                                                </select>
                                                <div *ngIf="submitted && dropboxForm.controls['Area'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && dropboxForm.controls['Area'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Area">Area</label>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <select class="form-select" aria-label="Default select example" #bank
                                                    (change)="this.dropboxForm.get('Bank')?.setValue(bank.value)"
                                                    [ngClass]="{'is-invalid':submitted && dropboxForm.controls['Bank'].errors}">
                                                    <option selected value="">Select Bank</option>
                                                    <option *ngFor="let i of AllBank"> {{i}}</option>

                                                </select>
                                                <div *ngIf="submitted && dropboxForm.controls['Bank'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && dropboxForm.controls['Bank'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Bank">Bank</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div *ngIf="status" class="light-blue-bg mt-30 customer-care-overview-icon">
                                    <div *ngFor="let i of BankList" class="white-box mt-30 mb-30">
                                        <div class="tender-setion">
                                            <div class="tender-head">
                                                <div class="tendername">
                                                    <h6>{{i.bankname}}</h6>
                                                    <p>{{i.Address}}</p>

                                                </div>
                                                <!-- <a href="#" class="bluebtn2 d-block">Apply Tender <i class="demo-icon uil-arrow-right"></i></a> -->
                                            </div>

                                            <div class="tender-body">
                                                <div class="category-sect">
                                                    <p>City</p>
                                                    <h6>{{i.city}}</h6>
                                                </div>
                                                <div class="category-sect">
                                                    <p>Area</p>
                                                    <h6>{{i.area}}</h6>
                                                </div>
                                                <div class="category-sect">
                                                    <p>Pincode</p>
                                                    <h6>{{i.Pincode}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>

                        </div>
                    </div>




                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>