<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Bill-info</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a href="#">Home</a></li>
        <li class=""><a href="#">MGL Corporate</a></li>
        <li class="active" aria-current="page">Bill-info</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<div id="rs-services" class="rs-services style6 bn-service">
  <div class="marketstats">
    <img src="assets/images/market-stat-bg.png" class="mk-bg">
    <div class="marketstats-content">
      <ul>
        <li>
          <p>MGL-NSE</p>
          <h6><i class="demo-icon uil-chart-line"></i> ₹839.25 <span>(+0.19%)</span> </h6>
        </li>
        <li>
          <p>MGL-BSE</p>
          <h6><i class="demo-icon uil-chart-line"></i> ₹839.25 <span>(+0.19%)</span> </h6>
        </li>
        <li>
          <p>Call Us on</p>
          <h6><i class="demo-icon uil-chart-line"></i> 68674500 / 61564500 </h6>
        </li>
      </ul>

    </div>
  </div>
  <div class="looking-for">
    <img src="assets/images/green-bg.png" class="looking-for-bgimg">
    <div class="looking-for-services">
      <h3>I am <span> looking for</span></h3>
      <ul>
        <li><a href="#"><img src="assets/images/lk-domestic-connection.png">
            <h6>Domestic <span>Connection</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-rate-card.png">
            <h6>Rate Card <span>& Plans</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-payment.png">
            <h6>Payment <span>I & C Bill</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-generate-bill.png">
            <h6>Generate <span>Your Bill</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-nach-upi.png">
            <h6>NACH/e-<span>NACH / UPI</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-mgl-fuel-card.png">
            <h6>MGL Fuel <span>Card</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-nearest-cng.png">
            <h6>Nearest CNG <span>Station</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-feedback-enquiry.png">
            <h6>Feedback / <span>Enquiry</span></h6>
          </a></li>
        <li><a href="#"><img src="assets/images/lk-cng-calulator.png">
            <h6>CNG Saving <span>Calculator</span></h6>
          </a></li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="latestnews-section">
          <div class="profile-section1">
            <div class="profile-name mb-20">
              <h4>Radhika P.Malik</h4>
              <p>BP No.: <b>1100274654</b></p>
              <p>CA No.:<b> 210000007924</b></p>
            </div>
            <div class="user-address mb-20">
              <div class="user-add-heading">
                Address
              </div>
              <ul>
                <li><i class="demo-icon uil-home-alt"></i> D-12, Borla CHSL, Wing-D-12, Borla, Dr.CG.Road, Chembur,
                  Mumbai, 400074</li>
              </ul>
            </div>

            <div class="user-address mb-20">
              <div class="user-add-heading">
                Contact Details
              </div>
              <ul>
                <li><i class="demo-icon uil-phone-volume"></i> +91 78945 61230 <br>
                  0253 5614 45662</li>
                <li><i class="demo-icon uil-envelope"></i> <a
                    href="mailto:radhika.malik@hohtechlabs.com">radhika.malik@hohtechlabs.com </a></li>
              </ul>
            </div>

          </div>
        </div>



      </div>

      <div class="col-lg-9 col-md-9 col-sm-12">
        <div class="mb-30">
          <h3> You can log your Meter Reading between following dates only</h3>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="billing-cycle-section">
                <i class="demo-icon uil-calender"></i>
                <div class="billing-cy-content">
                  <b>Normal</b>
                  <div class="mb-10">30 Jan 2023 - 09 Feb 2023 </div>
                  <div class="mb-10">30 May 2023 - 09 Jun 2023 </div>
                  <div class="mb-10">29 Sep 2023 - 09 Oct 2023 </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="billing-cycle-section">
                <i class="demo-icon uil-calender"></i>
                <div class="billing-cy-content">
                  <b>Assessed</b>
                  <div class="mb-10">30 Mar 2023 - 09 Apr 2023 </div>
                  <div class="mb-10">30 Jul 2023 - 09 Aug 2023 </div>
                  <div class="mb-10">29 Nov 2023 - 09 Dec 2023 </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
  <!-- Inner Page Content Section End -->





</div>
<!-- Main content End -->