import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropboxServiceService {

  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }

  getAllDroplist(){
    return this.http.get(this.baseUrl+'droplist/getAlldroplistCity')
  }

  getAllDroplistss(){
    return this.http.get(this.baseUrl+'droplist/get_droplist')
  }


  getAreaByCity(data:any){
    return this.http.post(this.baseUrl+'droplist/getAlldroplistAreaByCity',data)
  }

  getBankByCityArea(data:any){
    return this.http.post(this.baseUrl+'droplist/getAllBankByCityArea',data)
  }

  getBankDetails(data:any){
    return this.http.post(this.baseUrl+'droplist/getBankdetails',data)
  }
}
