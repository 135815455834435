import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthorizedService {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  getAllAuthUser(){
    return this.http.get(this.baseUrl+'getAuthPerson')
  }

  getFilteredData(data:any){
    return this.http.post(this.baseUrl+'getFilterAuthPerson',data)
  }

   getAuthPersonweb(data:any){
    return this.http.post(this.baseUrl+'getAuthPersonweb',data)
  }
}


