<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
     <div class="banner-heading">
        <h1>Retrofitters Level One Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahostav 2.0</a></li>
                <li class="active" aria-current="page"><a>Retrofitters Level One Status</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->

<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">


                       <div class="row">
                        <div class="col-sm-12 mx-auto">
                            <div class="customer-login-page">
                               
                                <div class="row">
                                    <form [formGroup]="searchForm">
                                        <div class="row">
                                     

                                               <div class="col-sm-4 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="vehicleNo"
                                                        placeholder="Vehicle No"
                                                        [ngClass]="{ 'is-invalid': submittedsearch && searchForm.controls['vehicleNo'].errors }"
                                                        id="vehicleNo" />
                                                    <div *ngIf="submittedsearch && searchForm.controls['vehicleNo'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="searchForm.controls['vehicleNo'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                   <!--  <label>Vehicle Number<span style="color: crimson;">*</span></label> -->
                                                    <label>Vehicle Number</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 form-group">
                                                <div class="form-floating">
                                                      <select  type="text" class="form-select" formControlName="status"
                                                [ngClass]="{'is-invalid':submittedsearch && searchForm.controls['status'].errors}" >
                                                <option value="" selected>Select Status</option>
                                                <option value="new">New</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="Rejected">Rejected</option>
                                             

                                            </select>
                                                    <div *ngIf="submittedsearch && searchForm.controls['status'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="searchForm.controls['status'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                   <!--  <label>Status<span style="color: crimson;">*</span></label> -->
                                                    <label>Status</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <button (click)="searchsubmit()" class="greenbtn2">Search <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                            
                                          
                                        </div>

                                    </form>

                                  

                                    <div *ngIf="alertType == 'searchV'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}" class="alert alert-danger mt-2" role="alert">
                                   {{alertMsg}}
                                     </div>



                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <div class="white-box10">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th width="20%">Vehicle no.</th>
                                    <th width="30%">Customer name</th>
                                    <th width="10%">Status</th>
                                     <th  width="30%" align="right" style="textAlign:right">Action</th>
                                     
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of leveloneArray">
                                    <td data-label="Vehicle no." valign="middle">{{item.vehicleNo}}</td>
                                    <td data-label="Customer name" valign="middle">{{item.Name}}</td>
                                    <td data-label="Status" valign="middle">{{item.status}}</td>
                                     <td data-label="Action" align="right"> 
                                      <span *ngIf="item.status=='Rejected'"><button class="blue-border-btn mr-2" (click)="gotoEdit(item)"><i class="demo-icon uil-edit"></i> Edit </button></span>
                                      <span *ngIf="item.status=='Accepted'"> <button class="blue-border-btn" (click)="gotoLeveltwo(item)"><i class="demo-icon uil-document-layout-right"></i> Proceed Level 2</button></span>
                                      </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>

                  <!--   <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                            <div class="customer-login-page">
                               
                                <div class="row">
                                    <form [formGroup]="searchpromotionForm">
                                        <div class="row">
                                     

                                               <div class="col-sm-12 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="vehicleNo"
                                                        placeholder="Vehicle No"
                                                        [ngClass]="{ 'is-invalid': submitted && searchpromotionForm.controls['vehicleNo'].errors }"
                                                        id="vehicleNo" />
                                                    <div *ngIf="submitted && searchpromotionForm.controls['vehicleNo'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="searchpromotionForm.controls['vehicleNo'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label>Vehicle Number<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                            
                                          
                                        </div>

                                        <div class="row y-middle">
                                            <div class="col-lg-6">
                                                <div (copy)="false" class="">
                                                    <div class="captcha-text">
                                                        {{this.captcha}}
                                                        <button style="background: transparent;border: none;"
                                                            (click)="captchaGenerate()"><i class="fa fa-repeat"
                                                                aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && searchpromotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted && searchpromotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="searchpromotionForm.controls['Captcha'].errors.required">
                                                            Required Field</div>

                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="mt-15">
                                        <div class="row">
                                            <div class="col-3 col-lg-3 col-md-3 col-sm-12">
                                                <button (click)="submit()" class="greenbtn2">Search <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                           
                                        </div>
                                        </div>
                                    </form>

                                  

                                    <div *ngIf="alertType == 'searchPro'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}" class="alert alert-danger mt-2" role="alert">
                                   {{alertMsg}}
                                     </div>



                                </div>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>



        </div> <!-- row end -->
    </div>
</div>


<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            * Kindly enter OTP as appearing on your Mobile.
                        </div>
                        <form [formGroup]="checkOtpForm">
                            <div class="row">
                                


                                <div class="col-sm-6 form-group">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Otp" 
                                            placeholder="Otp"
                                            [ngClass]="{ 'is-invalid': OtpSubmitted && checkOtpForm.controls['Otp'].errors }" id="Otp" />
                                        <div *ngIf="OtpSubmitted && checkOtpForm.controls['Otp'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="checkOtpForm.controls['Otp'].errors.required">Required Field</div>
                                            <!-- <div *ngIf="checkOtpForm.controls['Otp'].errors.pattern">MobileNo should be in 12 digits.</div> -->
                                        </div>
                                        <label for="Captcha">Enter OTP<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button [disabled]="submitStatus" (click)="verifyOtp()"  class="{{submitStatus ? 'greybtn' :'greenbtn2' }}">Submit OTP<i
                                            class="demo-icon uil-arrow-right"></i></button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button [disabled]="OtpButtonActive" greybtn
                                        (click)="submit()" class="{{OtpButtonActive ? 'greybtn' :'greenbtn2' }}">{{OtpButtonActive ?
                                        Minute+":"+Sec:
                                        "Resend OTP"}}<i class="demo-icon uil-arrow-right"></i></button>
                                </div>
                            </div>


                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>