<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Caution Notice <span> </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page">Caution Notice</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content xs-mt-30">
                    <h3>PNG</h3>
                    <p>Beware of unauthorised agencies</p>

                    <div class="light-blue-bg mb-30">
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Kind Attention PNG customers
                                </div>
                                <a href="assets/images/caution-notice/png/_kapc_8e8a8a9f5a.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Public Notice - List of unauthorised Service Providers
                                </div>
                                <a href="assets/images/caution-notice/png/_public-notice-12822_38339cbc94.pdf"
                                    target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Public Notice - Mahanigam Gas Safety (English)
                                </div>
                                <a href="assets/images/caution-notice/png/_mahanigam-gas-safety-eng_e7d7575373.pdf"
                                    target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Public Notice - Mahanigam Gas Safety (Marathi)
                                </div>
                                <a href="assets/images/caution-notice/png/_mahanigam-gas-safety-mar_83e1128aba.pdf"
                                    target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Public Notice - Gas Flame Technical Service Co.
                                </div>
                                <a href="assets/images/caution-notice/png/_gas-flame-technical-service-eng_b571605c61.pdf"
                                    target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="light-blue-bg ">

                        <h3>CNG</h3>
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Beware of fake websites for CNG dealership
                                </div>
                                <a href="assets/images/caution-notice/cng/_caution-notice-cng_15de71b818.pdf"
                                    target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>