<!-- Slider Start -->
<div>
	<div id="rs-slider" class="rs-slider slider3 rs-slider-style4 relative">
        <div class="bend niceties">
            <div class="slider-container"></div>
            <div class="slider-container-data"></div>
        </div>
    </div>

<!-- Premium Services Section Start -->
<div id="rs-services" class="rs-services style2 pt-70 pb-70 md-pt-70 md-pb-70 sm-pt-20 sm-pb-20">
    <div class="container custom">
        <div class="mgl-network-section">
            <div class="mgl-network-caption">
                <h2>MGL <span>Network</span></h2><img src="../../assets/images/mgl-network-graphic.png">
            </div>
            <div class="mgl-slider">
                <owl-carousel-o [options]="networkOptions">
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-satisfied-household.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                       
                                        <!--<span>2.10 Milion</span> -->
                                            <span>{{pngRate}}</span>
                                        Lakh Satisfied Households
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap slide">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-small-commercial.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                       
                                        <span>{{commercialestablishments}}</span>
                                        Small
                                        Commercial
                                        Establishments
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap slide">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-small-commercial.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                       
                                        <span>{{Industrialestablishments}}</span>
                                        Industrial 
                                        Establishments
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-vehicle-mumbai.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        MGL supplies CNG
                                        to more than
                                        <!-- <span>0.91 Milion</span> -->
                                        <span>{{Vehicles}}</span>
                                        Lakh vehicles 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-vehicle-mumbai.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        MGL supplies CNG
                                        to more than
                                        <!-- <span>0.37 Milion</span> -->
                                        <span>{{Rickshaws}}</span>
                                        Lakh Rickshaws 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-vehicle-mumbai.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        MGL Supplies CNG
                                       to more than
                                        <!-- <span>0.07 Milion</span> -->
                                        <span>{{Taxis}}</span>
                                       Lakh Taxis 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-vehicle-mumbai.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        MGL supplies CNG
                                        to more than
                                        <!-- <span>0.44 Milion</span> -->
                                        <span>{{Cars}}</span>
                                        Lakh Cars
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-small-commercial.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                       
                                        <span>{{NMMTBuses}}</span>
                                        BEST / TMT / MSRTC / NMMT Buses
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-small-commercial.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        
                                        <span>{{PrivateBuses}}</span>
                                        LCV / Tempos / Trucks / Private Buses
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-cng-stations.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        
                                        <span>{{Steelpipeline}}</span>
                                        kms of Steel pipeline   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-cng-stations.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        <span>{{PEpipeline}}</span>
                                        kms of PE pipeline
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="service-wrap">
                                <div class="icon-service">
                                    <img src="../../assets/images/mn-cng-stations.png" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">
                                        
                                        <span>{{Fillingstation}}</span>
                                       Filling Stations having
                                        <span>{{Dispensingpoints}}</span> 
                                        dispensing points.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
<!-- Premium Services Section End -->

<!-- News Section Start -->
<div class="news-latest">
    <div class="news-section">
        <h2 class="site-heading"><span>News</span> @ MGL</h2>
        <img src="../../assets/images/news-bg.jpg" alt="" class="newsbg">
        <div class="rs-blog style2 pt-50 md-pt-30 pt-50 sm-pt-0 sm-pb-10">
            <div *ngIf="allNews.length !== 0 && allNews.length >= 4" class="container">
                <owl-carousel-o [options]="newsOptions">
                    <ng-template *ngFor="let i of allNews | slice:0:4" carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img *ngIf="i.Img" src="{{base}}{{i.Img}}" alt="Blog"></a>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <!-- <li class="admin">{{i.Title}}</li> -->
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> {{i.PublishDate | date:'MM/dd/yyyy'}}</li>
                                    </ul>
                                    <h3 class="blog-title"><a routerLink="/MGL-corporate/media/news/{{i._id}}">{{i.Title}}</a></h3>
                                    <!-- <h3 class="blog-title"><a [innerHTML]="i.Description"></a></h3> -->
                                    <a routerLink="/MGL-corporate/media/news/{{i._id}}" class="view-details">View Details <i
                                            class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
                <div class="blog-know-btn">
                    <a [routerLink]="['/MGL-corporate/media/news']">Know More <i class="demo-icon uil-arrow-right"></i></a>
                </div>
            </div>

        </div>
    </div>

    <div class="latest-section">
        <h2 class="site-heading"><span>Latest</span> @ MGL</h2>
        <div class="pt-10 md-pt-50 sm-pt-0 sm-pb-0">
            
            <div class="latest-details">
                <ul>
                    <li *ngFor="let i of allHighlight | slice:0:3">
                        <!-- <div class="date-highlights"><span>{{i.PublishDate | date :'d MMM, y'}}</span> | Highlights</div> -->
                        <p [innerHTML]="i.Description"></p>
                    </li>

                </ul>
                <!-- <a [routerLink]="['/MGL-corporate/media/news']" class="greenbtn">View All News & Updates <i class="demo-icon uil-arrow-right"></i></a> -->
            </div>
        </div>
    </div>

</div>
<!-- News Section End -->
<!-- CSR Section Start -->
<!-- <div class="csr-activity pt-80 md-pt-40 sm-pt-0">
    <div class="csr-content">
        <div class="csr-whitebox">
            <h2>CSR
                <span> Initiative</span>
            </h2>
            <h6>Global CSR Excellence Award in Community Development initiatives</h6>
            <p>MGL was awarded the Global CSR Excellence & Leadership Award 2023 on 18th February under the category of CSR Excellence in Community Development initiatives. It is a recognition of the beautiful community engagement projects implemented by MGL through its NGO Partners.
</p>
             <a [routerLink]="['/BlogLists']" class="bluebtn">Know More <i class="demo-icon uil-arrow-right"></i></a>
        </div>
    </div>
    <div class="csr-img">
        <img src="../../assets/images/image_2023_04_26T07_54_34_231Z.png">
    </div>
</div> -->
<div *ngIf="CsrData != ''" class="csr-activity pt-80 md-pt-40 sm-pt-0">
    <div class="csr-content">
        <div  class="csr-whitebox">
            <h2>{{CsrData.Title}}
                <!-- CSR
                <span> Initiative</span> -->
            </h2>
            <span [innerHtml]="CsrData.Description"></span>
             <a href="{{CsrData.Url}}" class="bluebtn">Know More <i class="demo-icon uil-arrow-right"></i></a>
        </div>
    </div>
    <div class="csr-img">
        <img src="{{base}}{{CsrData.ImageName}}">
    </div>
</div>
<!-- CSR Section End -->
<!-- Partner Section Start -->
<div class="rs-patter-section style1 pt-80 pb-80 md-pb-40 md-pt-40 client-logos">
    <div class="pb-30 md-pb-20">
        <h2 class="site-heading text-dark text-center">Our <span> Clients </span></h2>
    </div>
    <div class="container custom">
        <owl-carousel-o  [options]="customOptions">
            <ng-template *ngFor="let i of allClient" carouselSlide>
                <div class="slide">
                    <div class="logo-img">
                        <a href="#">
                            <img style="height: 100px;" class="hovers-logos rs-grid-img" src="{{base}}{{i.Image}}"
                                title="" alt="">
                            <img style="height: 100px;" class="mains-logos rs-grid-img " src="{{base}}{{i.Image}}"
                                title="" alt="">
                        </a>
                    </div>
                </div>
            </ng-template>
            
        </owl-carousel-o>
    </div>
</div>
<!-- Partner Section End -->
<div class="investor-leadership">
    <div class="row">
        <div class="col-lg-6 col-md-6 p-0">
            <div class="investor-zone blue-gradient">
                <h2 class="site-heading text-center mb-50 md-mb-20">Investors <span>Zone</span></h2>
                <div class="row">
                    <div  class="col-lg-6 col-md-6 col-sm-6 col-6 qtr-select">
                        <div class="mb-20 sm-mb-0">
                            <a [routerLink]="['/MGL-corporate/investors/annual-report']">
                          <i class="demo-icon uil-file-check"></i>  Annual Reports
                            </a>
                        </div>
                        
                    </div>
                    
                    <div  class="col-lg-6 col-md-6 col-sm-6 col-6 qtr-select">
                        <div class="mb-20 sm-mb-0">
                            <a [routerLink]="['/MGL-corporate/investors/financial-results/audited-result']">
                          <i class="demo-icon uil-file-bookmark-alt"></i>  Audited Results
                            </a>
                        </div>
                        
                    </div>
                    
                    
                </div>

                <div class="nse-bse-section">
                    <p>{{myDate}}</p>
                    <div class="nse-bse-content">
                        <p>BSE - {{stock.BseCurrentPrice}} <span class="md-mt-0 sm-mt-5 " [ngStyle]="{'color':BseColors}" >({{stock.BseChange}}%)</span></p>
                        <p>NSE - {{stock.CurrentPrice}} <span class="md-mt-0 sm-mt-5 " [ngStyle]="{'color':NseColors}"  >({{stock.Changes}}%)</span></p>
                    </div>
                </div>

                <div class="investors-events">
                    <ul>
                        <li>
                            <div class="in-ev-headings">Upcoming Investor Events</div>
                            <div class="in-ev-date">27 Feb 2022 | Policies</div>
                            <p>Code of Conduct to Regulate, Monitor and Report Trading by Designated Persons and
                                their immediate relatives</p>
                            <a href="#">View Details <i class="demo-icon uil-angle-right"></i></a>
                        </li>
                        <li>
                            <div class="in-ev-headings">Upcoming Investor Events</div>
                            <div class="in-ev-date">27 Feb 2022 | Policies</div>
                            <p>Code of Conduct to Regulate, Monitor and Report Trading by Designated Persons and
                                their immediate relatives</p>
                            <a href="#">View Details <i class="demo-icon uil-angle-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

       <!--  <div class="col-lg-6 col-md-6 p-0">
            <div class="investor-zone green-gradient">
                <div class="rs-videos choose-video">
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/zL5BjQkwjHk" title="Expect Extension Of Exclusivity By PNGRB For MMR Region: Mahanagar Gas | CNBC TV18" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                   
                </div>
                <h2 class="site-heading text-center mb-40 md-mb-20 mt-40 md-mt-20">Leadership
                    <span>Speaks</span>
                </h2>
                <div class="leadership-desc">
                    <em>Mahanagar Gas Limited MD Ashu Shinghal in an exclusive interview with BQ Prime.</em>
                </div>
            </div>
        </div> -->

        <div class="col-lg-6 col-md-6 p-0">
                <div class="investor-zone green-gradient leadership-speaks-video">
                    <h2 class="site-heading text-center mb-40 md-mb-20">Leadership <span>Speaks</span></h2>
                    <div class="container custom">
                        <owl-carousel-o [options]="leaderOptions">
                            <ng-template carouselSlide>
                                <div class="video-sect">
                                    <div class="rs-videos choose-video">
                                        <div class="images-video">
                                            <img src="../../assets/images/leadershipslider/NDTV-Profit-2024.jpg"
                                                alt="images">
                                        </div>
                                        <div class="animate-border">
                                            <a class="popup-border1"
                                                href="https://www.ndtvprofit.com/india-market-open/ashu-shinghal-md-of-mahanagar-gas-ltd-forecast-volume-growth-next-year-admist-price-cuts-in-past-year"
                                                target="_blank">
                                                <i class="fa fa-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <h4>MD’s interaction on NDTV Profit on 22nd March 2024</h4>
                                    <div class="leadership-desc">
                                        <em>Managing Director of Mahanagar Gas Ltd. Forecast Volume Growth Next year
                                            Amidst Price Cuts In Past Year</em>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="video-sect">
                                    <div class="rs-videos choose-video">
                                        <div class="images-video">
                                            <img src="../../assets/images/leadershipslider/Zee-Business-20-03-2024.jpg"
                                                alt="images">
                                        </div>
                                        <div class="animate-border">
                                            <a class="popup-border1" href="https://youtu.be/oXJ1nujyfCM"
                                                target="_blank">
                                                <i class="fa fa-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <h4>MD's interaction on Zee Business on 20 March 2024</h4>
                                    <div class="leadership-desc">
                                        <em>News Par Views | Mahanagar Gas Reduces CNG Prices by ₹2.5/kg! How Will This
                                            Impact Consumers?</em>
                                    </div>
                                </div>
                            </ng-template>
                             <ng-template carouselSlide>
                                <div class="video-sect">
                                    <div class="rs-videos choose-video">
                                        <div class="images-video">
                                            <img src="../../assets/images/leadershipslider/CNBC-TV18.png"
                                                alt="images">
                                        </div>
                                        <div class="animate-border">
                                            <a class="popup-border1"
                                                href="https://www.youtube.com/watch?v=375qkyZc_vo"
                                                target="_blank">
                                                <i class="fa fa-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <h4>MD’s interaction with CNBC TV-18 on 10th July 2024</h4>
                                    <div class="leadership-desc">
                                        <em>Conversion Of CNG Bikes Into Volumes For CGD Companies Will Take Some Time: MGL</em>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="video-sect">
                                    <div class="rs-videos choose-video">
                                        <div class="images-video">
                                            <img src="../../assets/images/leadershipslider/ET-Now.png"
                                                alt="images">
                                        </div>
                                        <div class="animate-border">
                                            <a class="popup-border1" href="https://www.youtube.com/watch?v=F5lMot3ygao"
                                                target="_blank">
                                                <i class="fa fa-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <h4>MD’s interaction with ET Now on 10th July 2024</h4>
                                    <div class="leadership-desc">
                                        <em> Excise Duty Cut To Boost CNG Car Volumes? | MGL A Big Beneficiary Of CNG Excise Duty Cuts?</em>
                                    </div>
                                </div>
                            </ng-template>
                             <ng-template carouselSlide>
                                <div class="video-sect">
                                    <div class="rs-videos choose-video">
                                        <div class="images-video">
                                            <img src="../../assets/images/leadershipslider/October_2024_with_ET .png"
                                                alt="images">
                                        </div>
                                        <div class="animate-border">
                                            <a class="popup-border1" href="https://youtu.be/usbv6CiLrc8"
                                                target="_blank">
                                                <i class="fa fa-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <h4>MD Sir’s interaction on 11th October 2024 with ET Now</h4>
                                    <div class="leadership-desc">
                                        <em>Mahanagar Gas Ventures into Battery Manufacturing with Major Investment for Future Growth</em>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>

    </div>
</div>


<!-- <div class="pt-70 pb-70 md-pt-70 md-pb-70">
    <h2 class="site-heading text-center mb-50 md-mb-20 text-dark">Blogs <span>Articles</span></h2>
    <div class="container custom">
        <div class="blogs-article">
            <div class="rs-blog style2">
                <owl-carousel-o [options]="articalOptions">
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img src="../../assets/images/news/news1.jpg" alt="Blog"></a>
                                   
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin"> Savroli</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a>Mahanagar Gas Limited’s City Gate Station commissioned at Savroli- Augmentation of MGL’s Gas Distribution Network</a></h3>
                                    <a class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img src="../../assets/images/news/news2.jpg" alt="Blog"></a>
                                   
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin">  Press Release</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="#">MGL Creates Dedicated Kiosk at CGD Pavilion in India Energy Week 2023</a></h3>
                                    <a class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="#"><img src="../../assets/images/news/news3.jpg" alt="Blog"></a>
                                   
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin"> Press Release</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="#">MGL Creates Dedicated Kiosk at CGD Pavilion in India Energy Week 2023</a></h3>
                                    <a  class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img src="../../assets/images/news/news4.jpg" alt="Blog"></a>
                                    
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin"> Press Release</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="#">MGL Creates Dedicated Kiosk at CGD Pavilion in India Energy Week 2023</a></h3>
                                    <a href="#" class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img src="../../assets/images/news/news1.jpg" alt="Blog"></a>
                                   
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin"> Savroli</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="blog-details-2.html">Mahanagar Gas Limited’s City Gate Station commissioned at Savroli- Augmentation of MGL’s Gas Distribution Network</a></h3>
                                    <a  class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img src="../../assets/images/news/news2.jpg" alt="Blog"></a>
                                   
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin">  Press Release</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="#">MGL Creates Dedicated Kiosk at CGD Pavilion in India Energy Week 2023</a></h3>
                                    <a  class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a href="#"><img src="../../assets/images/news/news3.jpg" alt="Blog"></a>
                                   
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                        <li class="admin"> Press Release</li>
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> 16 Aug 2021</li>
                                    </ul>
                                    <h3 class="blog-title"><a href="#">MGL Creates Dedicated Kiosk at CGD Pavilion in India Energy Week 2023</a></h3>
                                    <a  class="view-details">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
                <div class="blog-know-btn">
                    <a [routerLink]="['/blog-list']">View All<i class="demo-icon uil-arrow-right"></i></a>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade videogallery {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div (click)="this.closeModel()" class="modal-dialog modal-xl modal-video">

        <div class="modal-content">
            <button (click)="this.closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    
                    <iframe  [src]="safeSrc" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div> -->

<div class="pt-70 pb-70 md-pt-70 md-pb-70 sm-pt-20 sm-pb-20">
    <h2 class="site-heading text-center mb-50 md-mb-20 text-dark">Blog <span>Articles</span></h2>
    <div class="container custom">
        <div class="blogs-article">
            <div class="rs-blog style2">
                <!-- <owl-carousel-o [options]="articalOptions"> -->
                    <!-- <ng-template *ngFor="let i of blogdata" carouselSlide>
                        <div class="slide">
                            <div class="blog-item">
                                <div class="image-wrap">
                                    <a ><img *ngIf="i.Img" src="{{base}}{{i.Img}}" alt="Blog"></a>

                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta mb-10">
                                         <li class="admin">{{i.Title}}</li> 
                                        <li class="date"> <i class="fa fa-calendar-check-o"></i> {{i.PublishDate | date:'MM/dd/yyyy'}}</li>
                                    </ul>
                                    <h3 class="blog-title"><a routerLink="blog/{{i._id}}">{{i.Title}}</a></h3>
                                    <h3 class="blog-title"><a routerLink="blog/{{i._id}}"><span [innerHTML]="i.Description"></span></a></h3> 
                                    <a routerLink="blog/{{i._id}}">View Details <i class="demo-icon uil-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-template> -->

                    <owl-carousel-o [options]="BlogOptions">
                        <ng-template *ngFor="let i of allNews | slice:0:4" carouselSlide>
                            <div class="slide">
                                <div class="blog-item">
                                    <div class="image-wrap">
                                        <a ><img *ngIf="i.Img" src="{{base}}{{i.Img}}" alt="Blog"></a>
                                    </div>
                                    <div class="blog-content">
                                        <ul class="blog-meta mb-10">
                                            <!-- <li class="admin">{{i.Title}}</li> -->
                                            <li class="date"> <i class="fa fa-calendar-check-o"></i> {{i.PublishDate | date:'MM/dd/yyyy'}}</li>
                                        </ul>
                                        <h3 class="blog-title"><a routerLink="/MGL-corporate/media/news/{{i._id}}">{{i.Title}}</a></h3>
                                        <!-- <h3 class="blog-title"><a [innerHTML]="i.Description"></a></h3> -->
                                        <a routerLink="/MGL-corporate/media/news/{{i._id}}" class="view-details">View Details <i
                                                class="demo-icon uil-angle-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                </owl-carousel-o>
                <div class="blog-know-btn">
                <a [routerLink]="['/MGL-corporate/media/news']">View All<i class="demo-icon uil-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>