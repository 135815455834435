import { Component } from '@angular/core';

@Component({
  selector: 'app-newvehicle-faq',
  templateUrl: './newvehicle-faq.component.html',
  styleUrls: ['./newvehicle-faq.component.css']
})
export class NewvehicleFAQComponent {

}
