<!-- Inner Page Content Section Start -->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>View <span>Bill</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page">View Your Bill</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->
 
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                    <app-recent-post></app-recent-post>
               
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                            <div class="customer-login-page">
                                <div class="log-meter-text">
                                    <ul>
                                        <li>* Kindly enter <b>CA</b> number as appearing on your bill.</li>
                                    </ul>
                                </div>
                                <div class="row">
                                    <form [formGroup]="UserForm">
                                        
                                        

                                        <div class="col-sm-12 form-group">
                                            <div class="form-floating">
                                                <input type="number" class="form-control" formControlName="CAno"
                                                    placeholder="CAno" onKeyPress="if(this.value.length==12) return false;"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['CAno'].errors }" id="CAno" />
                                                <div *ngIf="submitted && UserForm.controls['CAno'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['CAno'].errors.required">Required Field</div>
                                                    <div *ngIf="UserForm.controls['CAno'].errors.pattern">CA should be in 10 digits.</div>
                                                </div>
                                                <label for="CAno">CA No.<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="row y-middle">
                                         <div class="col-lg-5 ml-15">
                                        <div  class="">
                                            <div class="captcha-text">
                                            {{this.captcha}}
                                            <button style="background: transparent;border: none;" (click)="captchaGenerate()"><i class="fa fa-repeat" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        </div>   
                                        


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Captcha"
                                                    placeholder="Captcha" 
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Captcha'].errors }" id="Captcha" />
                                                <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Captcha'].errors.required">Required Field</div>
                                                   
                                                </div>
                                                <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        
                                        </div>
                                        <div class="mt-20">
                                            <button (click)="submit()" style="border: none;" class="greenbtn2">Submit <i class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                    </form>

                                    <div *ngIf="alertType == 'CA'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>