<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Privacy Policy</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page">Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="mt-40">
                    <div class="con">
                        <h5>Terms and conditions for website usage</h5>
                        <p>Welcome to our website. If you continue to browse and use this website you are agreeing to
                            comply with and be bound by the following terms and conditions of use, which together with
                            our privacy policy govern Mahanagar Gas Limited's relationship with you in relation to this
                            website.</p>
                        <p>The term Mahanagar Gas Limited or 'MGL' or 'us' or 'we' refers to the owner of the website
                            whose registered office is located at MGL House, Block G-33, Bandra - Kurla Complex Bandra
                            (E), Mumbai-400051. The term 'you' refers to the user or viewer of our website.</p>
                        <h5>The use of this website is subject to the following terms of use:</h5>
                        <div class="blue-tick-list">
                        <ul>
                            <li>The content of the pages of this website is for your general information and use only.
                                It is subject to change without notice.</li>
                            <li>We do not provide any warranty or guarantee as to the accuracy, timeliness, performance,
                                completeness or suitability of the information and materials found or offered on this
                                website for any particular purpose. You acknowledge that such information and materials
                                may contain inaccuracies or errors and we expressly exclude liability for any such
                                inaccuracies or errors to the fullest extent permitted by law.</li>
                            <li>Your use of any information or materials on this website is entirely at your own risk,
                                for which we shall not be liable. It shall be your own responsibility to ensure that any
                                products, services or information available through this website meet your specific
                                requirements.</li>
                            <li>This website contains material which is owned by or licensed to us. This material
                                includes, but is not limited to, the design, layout, look, appearance and graphics.
                                Reproduction is prohibited other than in accordance with the copyright notice, which
                                forms part of these terms and conditions.</li>
                            <li>Unauthorized use of this website may give rise to a claim for damages and/or be a
                                criminal offence.</li>
                            <li>From time to time this website may also include links to other websites. These links are
                                provided for your convenience to provide further information. They do not signify that
                                we endorse the website(s). We have no responsibility for the content of the linked
                                website(s).</li>
                            <li>Your use of this website and any dispute arising out of such use of the website is
                                subject to Jurisdiction of Mumbai Court in accordance with the laws of India.</li>
                        </ul>
                        </div>
                        <h5>Business privacy policy</h5>
                        <p>This privacy policy sets out how Mahanagar Gas Limited uses and protects any information that
                            you give to Mahanagar Gas Limited when you use this website.</p>
                        <p>Mahanagar Gas Limited is committed to ensuring that your privacy is protected. Should we ask
                            you to provide certain information by which you can be identified when using this website,
                            then you can be assured that it will only be used in accordance with this privacy statement.
                        </p>
                        <p>Mahanagar Gas Limited may change this policy from time to time by updating this page. You
                            should check this page from time to time to keep yourself abreast of updations.</p>
                        <h5>What we collect</h5>
                        <p>We may collect the following information:</p>
                        <div class="blue-tick-list">
                        <ul>
                            <li>Name, address and employment details</li>
                            <li>Contact information including email address</li>
                            <li>Registration No./ CRN No / address proof</li>
                            <li>Demographic information such as postcode, preferences and interests</li>
                            <li>Other information relevant to customer surveys and/or offers</li>
                        </ul>
                        </div>
                        <h5>What we do with the information we gather</h5>
                        <p>We require this information to provide you with better service, understand your needs, to
                            process your requests and in particular for the following reasons:</p>
                        <div class="blue-tick-list">
                            <ul>
                                <li>For billing / market operation purposes.</li>
                                <li>Update your address &amp; contact details.</li>
                                <li>For recovery of dues / outstanding.</li>
                                <li>We may use the information to improve our products and services.</li>
                                <li>We may periodically send promotional emails about new products, special offers or
                                    other
                                    information which we think you may find interesting using the email address which
                                    you
                                    have provided.</li>
                                <li>From time to time, we may also use your information to contact you for market
                                    research
                                    purposes. We may contact you by email, phone, fax or mail.</li>
                                <li>We may share the information with the law enforcement agencies / statutory / govt
                                    authorities, only if directed to do so.</li>
                            </ul>
                        </div>
                        <h5>Security</h5>
                        <p>We are committed to ensuring that your information is secure. In order to prevent
                            unauthorised access or disclosure we have put in place suitable physical, electronic and
                            managerial procedures to safeguard and secure the information we collect online.</p>
                        <h5>How we use cookies</h5>
                        <p>A cookie is a small file which asks permission to be placed on your computer's hard drive.
                            Once you agree, the file is added and the cookie helps analyse web traffic or lets you know
                            when you visit a particular site. Cookies allow web applications to respond to you as an
                            individual. The web application can tailor its operations to your needs, likes and dislikes
                            by gathering and remembering information about your preferences.</p>
                        <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data
                            about webpage traffic and improve our website in order to tailor it to customer needs. We
                            only use this information for statistical analysis purposes and then the data is removed
                            from the system.</p>
                        <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which
                            pages you find useful and which you do not. A cookie in no way gives us access to your
                            computer or any information about you, other than the data you choose to share with us.</p>
                        <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies,
                            but you can usually modify your browser setting to decline cookies if you prefer. This may
                            prevent you from taking full advantage of the website.</p>
                        <h5>Links to other websites</h5>
                        <p>Our website may contain links to other websites of interest. However, once you have used
                            these links to leave our site, you should note that we do not have any control over that
                            other website. Therefore, we cannot be responsible for the protection and privacy of any
                            information which you provide whilst visiting such sites and such sites are not governed by
                            this privacy statement. You should exercise caution and look at the privacy statement
                            applicable to the website in question.</p>
                        <h5>Protecting your personal information</h5>
                        <p>We will not sell, distribute or lease your personal information to third parties unless we
                            have your permission or are required by law to do so. We may use your personal information
                            to send you information about our products / services/ payment facilities or promotional
                            information regarding our products/ co - branded products of third parties with whom we have
                            tied up, which we think you may find interesting or useful to you. </p>
                        <h5>Grievance Officer </h5>
                        <p>For any matter related to your concern on IT Security please contact or write to our
                            Grievance Officer:</p>
                        <!--<p>Mr. Abhijit Mukherjee<br /> General Manager (BIS)<br />e-Mail: abhijitm@mahanagargas.com<br />  Phone(D): 022-26594551</p>  -->
                        <p>Mr. Kedar Gadre<br>
                            General Manager (BIS)<br>
                            e-Mail: gadre.kedar@mahanagargas.com<br>
                            Phone(D): 022-26594551</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Main content End -->