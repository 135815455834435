import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as crypto from 'crypto-js';

type NewType = string;

@Component({
  selector: 'app-qr-pay',
  templateUrl: './qr-pay.component.html',
  styleUrls: ['./qr-pay.component.css']
})
export class QrPayComponent {
  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  data: any = ''

  UserForm !: FormGroup
  Minute: any = 2
  Sec: any = 59
  public qrdata: any = null;
  captcha: any
  Otp: any
  OtpSent: boolean = false
  PopUp: boolean = false
  submitted: boolean = false;
  isVisible = false
  netpay = ''

  constructor(private FormBuilder: FormBuilder, public service: DashboardServiceService, private pageLoaderService: PageLoaderService, public sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.UserForm = this.FormBuilder.group({
      CAno: ['', [Validators.required, Validators.pattern(/^\d{12}$/)]],
      Captcha: ['', [Validators.required]],
      Otp: ['']
    })

    this.captchaGenerate()
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result
  }

  submit() {

    this.submitted = true
    //console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    if (this.captcha == this.UserForm.get('Captcha')?.value) {
       var ciphertext = crypto.AES.encrypt(this.UserForm.controls['CAno'].value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
       this.service.getQRPayNew(ciphertext).subscribe(response => {
       // this.service.getQRPay(this.UserForm.controls['CAno'].value).subscribe(response => {
		this.isVisible = true;
        this.data = JSON.parse(JSON.stringify(response))
        this.netpay = this.data.NETPAY.toString();
		if (parseFloat(this.netpay) > 0){
			this.qrdata = "upi://pay?pa=mahagasupi" + this.data.CA + "@hsbc&pn=MahanagarGasLtd&mc=4900&tid=&tr=" + this.data.BILLNO + "&tn=" + this.data.BILLNO + "&am=" + this.netpay + "&mam=null&cu=INR";
			// this.qrdata = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + encodeURIComponent(this.qrdata) + "&choe=UTF-8";
		this.qrdata = "https://image-charts.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl=" + encodeURIComponent(this.qrdata);
//https://image-charts.com/chart?chs=150x150&cht=qr&choe=UTF-8&chl=dynamic_part
    //console.log(this.qrdata)
    } else {
			this.qrdata = '';
		}
      })

      // this.service.getCurrentBill(this.UserForm.value).subscribe(response => {
      //   this.data = JSON.parse(JSON.stringify(response)).details
      //   console.log(this.data)
      //   const temp = this.data[0].due_date.split("/")
      //   //alert(new Date(temp[2]+"-"+temp[1]+"-"+temp[0]).getTime())
      //   const date = new Date();
      //   var amt = parseFloat(this.data[0].net_pay)
      //   if (new Date(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDay()).getTime() > new Date(temp[2] + "-" + temp[1] + "-" + temp[0]).getTime()) {
      //     amt = amt + 100;
      //   }
      //   this.netpay = amt.toString();
      //   this.qrdata = "upi://pay?pa=mahagasupi" + this.data[0].contract_acc + "@hsbc&pn=MahanagarGasLtd&mc=4900&tid=&tr=" + this.data[0].bill_no + "&tn=" + this.data[0].bill_no + "&am=" + amt + "&mam=null&cu=INR";
      //   this.qrdata = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + encodeURIComponent(this.qrdata) + "&choe=UTF-8";
      // })
    } else {
      this.alertMsg = "Captcha Did Not Match";
      this.alertColor = 'rgba(255, 94, 109, 0.2)';
      this.alertTextColor = '#c24753';
      this.alertBorder = '1px solid #eb5664;';
      this.clearStatus();
    }
  }

  clearStatus() {
    setTimeout(() => {
      this.alertType = ''
    }, 3000)
  }
}