<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
       <!--  <h1>Domestic PNG Connection from a Non-Gasified Building Where Network is not Available</h1> -->
       <h1>New PNG Request where Gas Supply is not available</h1>
        
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG </a></li>
                <li class=""><a >Domestic Connection Request</a></li>
                <li class=""><a>Domestic PNG Registration</a></li>
                <li class=""><a>Domestic Connection Request Form</a></li>
                 <li class="active" aria-current="page">New PNG Request where Gas Supply is not available</li>
               <!--  <li class="active" aria-current="page">Domestic PNG Connection from a Non-Gasified Building Where Network is not Available</li> -->
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Title"></a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM dd, yyyy'}}</span>
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a> 
                        </div>
                    </div>
                </div>
                    
                
                <app-tips></app-tips>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="customer-login-page log-meter-reading">
                                
                                <form [formGroup]="UserForm">
                                    <div class="light-blue-bg">
                                    <p><i><b>Note:</b> Please enter your details below and our Marketing Representatives will carry out the feasibilty study of your building and contact you.</i></p>  
                                    <div class="row">
                                        <p><b>Residential Details</b></p>
                                        <div class="col-lg-2 col-md-2 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==4) return false;" type="number" class="form-control"
                                                    formControlName="flatNo" placeholder="flatNo"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['flatNo'].errors }"
                                                    id="flatNo" />
                                                <div *ngIf="submitted && UserForm.controls['flatNo'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['flatNo'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="flatNo">Flat No. <span style="color: crimson;">*</span></label>
                                            </div>


                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==2) return false;" type="number" class="form-control"
                                                    formControlName="floor" placeholder="floor"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['floor'].errors }"
                                                    id="floor" />
                                                <div *ngIf="submitted && UserForm.controls['floor'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['floor'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="floor">Floor</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-12">
            
                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==3) return false;" type="number" class="form-control"
                                                    formControlName="Newplot" placeholder="Newplot"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Newplot'].errors }"
                                                    id="Newplot" />
                                                <div *ngIf="submitted && UserForm.controls['Newplot'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Newplot'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Newplot">Plot No</label>
                                            </div>

                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==2) return false;" type="number" class="form-control"
                                                    formControlName="Newwing" placeholder="Newwing"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Newwing'].errors }"
                                                    id="Newwing" />
                                                <div *ngIf="submitted && UserForm.controls['Newwing'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Newwing'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Newwing">Wing</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                            
                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewBuildingName" placeholder="NewBuildingName"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewBuildingName'].errors }"
                                                    id="NewBuildingName" />
                                                <div *ngIf="submitted && UserForm.controls['NewBuildingName'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewBuildingName'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewBuildingName">Building Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewSocietyName" placeholder="NewSocietyName"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewSocietyName'].errors }"
                                                    id="NewSocietyName" />
                                                <div *ngIf="submitted && UserForm.controls['NewSocietyName'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewSocietyName'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewSocietyName">Society Name</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewColony" placeholder="NewColony"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewColony'].errors }"
                                                    id="NewColony" />
                                                <div *ngIf="submitted && UserForm.controls['NewColony'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewColony'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewColony">Colony</label>
                                            </div>


                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewRoadName" placeholder="NewRoadName"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewRoadName'].errors }"
                                                    id="NewRoadName" />
                                                <div *ngIf="submitted && UserForm.controls['NewRoadName'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewRoadName'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewRoadName">Road Name</label>
                                            </div>


                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewLandkmark" placeholder="NewLandkmark"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewLandkmark'].errors }"
                                                    id="NewLandkmark" />
                                                <div *ngIf="submitted && UserForm.controls['NewLandkmark'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewLandkmark'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewLandkmark">Landmark</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewLocation" placeholder="NewLocation"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewLocation'].errors }"
                                                    id="NewLocation" />
                                                <div *ngIf="submitted && UserForm.controls['NewLocation'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewLocation'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewLocation">Location</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="NewcityDist" placeholder="NewcityDist"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewcityDist'].errors }"
                                                    id="NewcityDist" />
                                                <div *ngIf="submitted && UserForm.controls['NewcityDist'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewcityDist'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewcityDist">City / District<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==6) return false;" type="number" class="form-control"
                                                    formControlName="NewPincode" placeholder="NewPincode"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewPincode'].errors }"
                                                    id="NewPincode" />
                                                <div *ngIf="submitted && UserForm.controls['NewPincode'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewPincode'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewPincode">Pincode<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6 col-sm-12">


                                            <div class="form-floating mb-3">
                                                <select #Restype
                                                            (change)="this.UserForm.get('ResType')?.setValue(Restype.value)"
                                                            [ngClass]="{'is-invalid':submitted && UserForm.controls['ResType'].errors}"
                                                            class="form-select">
                                                            <option value="" selected>Select</option>
                                                            <option value="OWNED">OWNED</option>
                                                            <option value="RENTED / LEASED">RENTED / LEASED</option>
                                                            


                                                        </select>
                                                <div *ngIf="submitted && UserForm.controls['ResType'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['ResType'].errors.required">
                                                        Required Field</div>

                                                </div>
                                                <label for="ResType">Residential Type<span
                                                    class="text-danger">*</span></label>
                                            </div>
                                        </div>
                                         </div>
                                        </div>
                                    
                                        <div class="light-blue-bg mt-10">
                                        <div class="row">
                                        <p><b><i>Personal Details</i></b></p>

                                        <div class="col-lg-2 col-md-3 col-sm-12">


                                            <div class="form-floating mb-3">
                                                <select #INI
                                                    (change)="this.UserForm.get('Initial')?.setValue(INI.value)"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Initial'].errors}"
                                                    class="form-select">
                                                    <option value="" selected>Initial</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Miss.">Miss.</option>
                                                    <option value="Dr.">Dr.</option>


                                                </select>
                                                <div *ngIf="submitted && UserForm.controls['Initial'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Initial'].errors.required">
                                                        Required Field</div>

                                                </div>
                                                <label for="Initial">Initial <span
                                                        class="text-danger">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-9 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="fname" placeholder="fname"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['fname'].errors }"
                                                    id="fname" />
                                                <div *ngIf="submitted && UserForm.controls['fname'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['fname'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="fname">First Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="mname" placeholder="mname"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['mname'].errors }"
                                                    id="mname" />
                                                <div *ngIf="submitted && UserForm.controls['mname'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['mname'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="mname">Middle</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="surname" placeholder="surname"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['surname'].errors }"
                                                    id="surname" />
                                                <div *ngIf="submitted && UserForm.controls['surname'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['surname'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="surname">Surname<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            <label>Gender</label>
                                            <div class="mb-15 ml-15">
                                                <div class="row service-label">
                                                    
                                                    <div [ngClass]="{'is-invalid':submitted && UserForm.controls['gender'].errors}" class="col-md-12 form-check">
                                                       <input formControlName="gender" type="radio" id="html" name="fav_language" value="Male" class="form-check-input">
                                                        <label for="html" class="">Male</label>
                                                    </div>
                                                    
                                                    <div class="col-md-12 form-check">
                                                        <input formControlName="gender" type="radio" id="css" name="fav_language" value="Female" class="form-check-input">
                                                        <label for="css" class="">Female</label>
                                                    </div>
                                                    
                                                    <div *ngIf="submitted && UserForm.controls['gender'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm.controls['gender'].errors">Required Field
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                            
                                            <div class="form-floating mb-3">
                                                <input  type="date" class="form-control"
                                                    formControlName="DOB" placeholder="DOB"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['DOB'].errors }"
                                                    id="DOB" />
                                                <div *ngIf="submitted && UserForm.controls['DOB'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['DOB'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="DOB">DOB</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==10) return false;"  type="number" class="form-control"
                                                    formControlName="Mobile" placeholder="Mobile"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Mobile'].errors }"
                                                    id="Mobile" />
                                                <div *ngIf="submitted && UserForm.controls['Mobile'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Mobile'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Mobile">Mobile No<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">


                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==10) return false;"
                                                    type="number" class="form-control" formControlName="AltMobile"
                                                    placeholder="AltMobile"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['AltMobile'].errors }"
                                                    id="AltMobile" />
                                                <div *ngIf="submitted && UserForm.controls['AltMobile'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['AltMobile'].errors.required">
                                                        Required Field</div>

                                                </div>
                                                <label for="AltMobile">Alternate No</label>
                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==10) return false;"  type="text" class="form-control"
                                                    formControlName="Pan" placeholder="Pan"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Pan'].errors }"
                                                    id="Pan" />
                                                <div *ngIf="submitted && UserForm.controls['Pan'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Pan'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Pan">Pan No<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==12) return false;"  type="number" class="form-control"
                                                    formControlName="Aadhar" placeholder="Aadhar"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Aadhar'].errors }"
                                                    id="Aadhar" />
                                                <div *ngIf="submitted && UserForm.controls['Aadhar'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Aadhar'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Pan">Aadhar No</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==12) return false;"  type="text" class="form-control"
                                                    formControlName="ElectMeter" placeholder="ElectMeter"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['ElectMeter'].errors }"
                                                    id="ElectMeter" />
                                                <div *ngIf="submitted && UserForm.controls['ElectMeter'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['ElectMeter'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="ElectMeter">Electric Meter </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="email" class="form-control"
                                                    formControlName="EmailId" placeholder="EmailId"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['EmailId'].errors }"
                                                    id="EmailId" />
                                                <div *ngIf="submitted && UserForm.controls['EmailId'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['EmailId'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="EmailId">Email <span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                       

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="Occ" placeholder="Occ"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Occ'].errors }"
                                                    id="Occ" />
                                                <div *ngIf="submitted && UserForm.controls['Occ'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Occ'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Occ">Occupation </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control"
                                                    formControlName="TelRes" placeholder="TelRes"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['TelRes'].errors }"
                                                    id="TelRes" />
                                                <div *ngIf="submitted && UserForm.controls['TelRes'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['TelRes'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="TelRes">Residence Telephone</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control"
                                                    formControlName="TelOff" placeholder="TelOff"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['TelOff'].errors }"
                                                    id="TelOff" />
                                                <div *ngIf="submitted && UserForm.controls['TelOff'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['TelOff'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="TelOff">Office Telephone </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                             <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                             <label> Captcha </label>
                                            <div class="mt-15">
                                                <div class="captcha-text">{{captcha}} <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i></div>
                                            </div>
                                        </div> 
                                       <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input  type="text" class="form-control"
                                                    formControlName="Captcha" placeholder="Captcha"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Captcha'].errors }"
                                                    id="Captcha" />
                                                <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Captcha'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Captcha">Enter Captcha <span style="color: crimson;">*</span></label>
                                            </div>


                                            </div>
                                            </div>
                                            </div>

                                        
                                    </div>
                                        <div class="mt-20">
                                        <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                            <p class="mt-30 text-right mb-0"><i><b>Please note all fields marked <span class="text-danger">*</span> are compulsory.</b></i></p>
                                        </div>  

                                </form>
                                

                                <div *ngIf="alertType == 'alert'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                              <!--   <h6 *ngIf="invoiceStatus">This is Your Request Number :
                                    {{applicationnum}}</h6> -->
                            </div>

                        </div>
                    </div>




                
            </div>
        </div>
    </div>