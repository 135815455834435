<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        
        <h1 >Domestic PNG Connection in a Non-Gasified Building Where Network is Being Laid</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a href="#">Businesses</a></li>
                <li *ngIf="!status" class="active" aria-current="page">Domestic Connection Request </li>
                <li *ngIf="status" class="active" aria-current="page">Domestic PNG Connection in a Non-Gasified Building Where Network is Being Laid</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">

                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Title"></a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM dd, yyyy'}}</span>
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a> 
                        </div>
                    </div>
                </div>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="domestic_png_connection">


                        <div class="reg_form_box">

                            <input type="hidden" name="ctl00$ContentPlaceHolder1$hiddma_id"
                                id="ContentPlaceHolder1_hiddma_id">

                            <h6>Applicants may please Note that :</h6>


                            <ul class="applicants">

                                <li><span>1.</span> Only for Non-Gasified building where network is being laid.</li>
                                <li><span>2.</span> Payments can be made through Online transaction OR through cheque.</li>
                                <li><span>3.</span> Applicant should be the owner of the flat for which new connection is requested. Incase of any dispute w.r.t. ownership arising in future, the Security Deposit against the connection stands forfeited.</li>
                                <li><span>4.</span> As per safety standards, in case, the applicant's residence is found to be technically not feasible, MGL will refund the amount paid by the applicant in full.</li>
                                
                                </ul>


                            <h6>Please enter the details below to submit your Domestic Connection Request</h6>

                            <div class="light-blue-bg mt-20">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <p><b><i>Please enter the details below to submit your Domestic Connection Request</i></b></p>
                                    <div class="customer-login-page">
                                        <div class="">
                                            <form [formGroup]="UserForm">
                                                <div class="row g-3 align-items-center">
                                                      <div class="col-auto">
                                                
                                            </div>
                                              <div class="col-auto">
                                                

                                                <div class="form-floating">
                                                    <input type="number" class="form-control" formControlName="pincode" onKeyPress="if(this.value.length==6) return false;"
                                                        
                                                        placeholder="pincode"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['pincode'].errors }"
                                                        id="pincode" />
                                                    <div *ngIf="submitted && UserForm.controls['pincode'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm.controls['pincode'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Captcha">Enter the Pincode<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                             <div class="col-auto">
                                                <div class="">
                                                    <button (click)="submit()" class="greenbtn2">Go <i
                                                            class="demo-icon uil-arrow-right"></i></button>
                                                </div>
                                            </div>
                                                </div>
                                            </form>
                                            
                                            <span *ngIf="status && Address.length == 0"><div class="alert alert-warning mt-10"><i class="bi flex-shrink-0 me-2 demo-icon uil-exclamation-circle" ></i>  Area Not Serviceable</div></span>
                                            
                                            <span *ngIf="status && Address.length > 0">
                                                <form [formGroup]="address" class="mt-30">
                                                     <div class="row g-3 align-items-center">
                                                    <div class="col-auto"> 
                                                        <label><b>Please choose your Building Name and click 'Select'</b></label>
                                                    </div>
                                                    <div class="col-auto"> 
                                                    <div class="cu-login-input2">
                                                        <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                        <select #addr
                                                            (change)="this.address.get('addres')?.setValue(addr.value)"
                                                            name="" id="">
                                                            <option value="" selected>Select Options</option>
                                                            <option *ngFor="let i of Address" [value]="i._id">
                                                                {{i.Bldg_name+','+
                                                                i.Colony_name +','+i.Road_name+','}}{{i.Location != null ? i.Location : ''}}</option>


                                                        </select>
                                                        <div *ngIf="submitted2 && address.controls['addres'].errors"
                                                            class="invalid-feedback">
                                                            <div style="margin-left: 50px;"
                                                                *ngIf="address.controls['addres'].errors">Required Field
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div class="col-auto"> 
                                                        <button (click)="submit2()" class="greenbtn2">Search <i
                                                                class="demo-icon uil-arrow-right"></i></button>
                                                    </div>
                                                    </div>

                                                </form>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>




                            <div class="form_50per choose"></div>
                            <div class="form_50per choose">
                                <div class="Select_box"></div>
                            </div>
                            <div id="ContentPlaceHolder1_chk"></div>
                            
                        </div>


                        <div *ngIf="status2" class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">

                                <div class="customer-login-page log-meter-reading ">
                                    
                                    <form [formGroup]="UserForm2">
                                    <div class="light-blue-bg mt-10">
                                        <p><b>Residential Details</b></p>
                                        <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input type="number" class="form-control" formControlName="flatNo" onKeyPress="if(this.value.length==4) return false;"
                                                        
                                                        placeholder="flatNo"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['flatNo'].errors }"
                                                        id="flatNo" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['flatNo'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['flatNo'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Captcha">Flat No. <span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-lg-2 col-md-2 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" formControlName="floor" onKeyPress="if(this.value.length==2) return false;"
                                                        
                                                        placeholder="floor"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['floor'].errors }"
                                                        id="floor" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['floor'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['floor'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Captcha">Floor </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" formControlName="plot" onKeyPress="if(this.value.length==3) return false;"
                                                        
                                                        placeholder="plot"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['plot'].errors }"
                                                        id="plot" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['plot'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['plot'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Captcha">Plot No. </label>
                                                </div>

                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly type="text" class="form-control" formControlName="wing" 
                                                        
                                                        placeholder="wing"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['wing'].errors }"
                                                        id="wing" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['wing'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['wing'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="wing">Wing</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="text" class="form-control" formControlName="Sector" 
                                                        
                                                        placeholder="Sector"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Sector'].errors }"
                                                        id="Sector" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Sector'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Sector'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Sector">Sector</label>
                                                </div>


                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="BuildingName" 
                                                        
                                                        placeholder="BuildingName"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['BuildingName'].errors }"
                                                        id="BuildingName" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['BuildingName'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['BuildingName'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="BuildingName">Building Name<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="SocietyName" 
                                                        
                                                        placeholder="SocietyName"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['SocietyName'].errors }"
                                                        id="SocietyName" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['SocietyName'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['SocietyName'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="BuildingName">Society Name</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="Colony" 
                                                        
                                                        placeholder="Colony"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Colony'].errors }"
                                                        id="Colony" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Colony'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Colony'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="BuildingName">Colony</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="RoadName" 
                                                        
                                                        placeholder="RoadName"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['RoadName'].errors }"
                                                        id="RoadName" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['RoadName'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['RoadName'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="BuildingName">Road Name</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="Landkmark" 
                                                        
                                                        placeholder="Landkmark"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Landkmark'].errors }"
                                                        id="Landkmark" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Landkmark'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Landkmark'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Landkmark">Landmark</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="Location" 
                                                        
                                                        placeholder="Location"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Location'].errors }"
                                                        id="Location" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Location'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Location'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Location">Location</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="cityDist" 
                                                        
                                                        placeholder="cityDist"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['cityDist'].errors }"
                                                        id="cityDist" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['cityDist'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['cityDist'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="cityDist">City/District</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                               

                                                <div class="form-floating mb-3">
                                                    <input readonly  type="text" class="form-control" formControlName="Pincode" 
                                                        
                                                        placeholder="Pincode"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Pincode'].errors }"
                                                        id="Pincode" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Pincode'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Pincode'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Pincode">Pincode<span
                                                        class="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">


                                                <div class="form-floating mb-3">
                                                    <select #Restype
                                                                (change)="this.UserForm2.get('ResType')?.setValue(Restype.value)"
                                                                [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['ResType'].errors}"
                                                                class="form-select">
                                                                <option value="" selected>Select</option>
                                                                <option value="OWNED">OWNED</option>
                                                                <option value="RENTED / LEASED">RENTED / LEASED</option>
                                                                


                                                            </select>
                                                    <div *ngIf="submitted3 && UserForm2.controls['ResType'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['ResType'].errors.required">
                                                            Required Field</div>

                                                    </div>
                                                    <label for="ResType">Residential Type<span
                                                        class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <!--New Form-->  
                                         </div>
                                         </div>
                                        <div class="light-blue-bg mt-10">
                                        <div class="row">
                                             <p><b>Personal Details</b></p>


                                             <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="row">
                                                  <div class="col-lg-3 col-md-3 col-sm-12">  
                                                

                                                <div class="form-floating mb-3">
                                                    <select #INI (change)="this.UserForm2.get('Initial')?.setValue(INI.value)"
                                                        [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['Initial'].errors}"
                                                        
                                                        class="form-select">
                                                        <option value="" selected>Initial</option>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Ms.">Ms.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                        <option value="Miss.">Miss.</option>
                                                        <option value="Dr.">Dr.</option>


                                                    </select>
                                                    <div *ngIf="submitted3 && UserForm2.controls['Initial'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Initial'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Initial">Initial <span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                                <div class="col-lg-9 col-md-9 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input   type="text" class="form-control" formControlName="fname" 
                                                        
                                                        placeholder="fname"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['fname'].errors }"
                                                        id="fname" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['fname'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['fname'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="fname">First Name<span class="text-danger">*</span></label>
                                                </div>


                                            </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input   type="text" class="form-control" formControlName="mname" 
                                                        
                                                        placeholder="mname"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['mname'].errors }"
                                                        id="mname" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['mname'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['mname'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="mname">Middle Name</label>
                                                </div>


                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input   type="text" class="form-control" formControlName="surname" 
                                                        
                                                        placeholder="surname"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['surname'].errors }"
                                                        id="surname" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['surname'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['surname'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="surname">Surname<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-12">
                                                <label>Male/Female</label>
                                                <div class="mb-15 ml-15">
                                                    <div class="row service-label">
                                                        <div [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['gender'].errors}"
                                                            class="col-md-12 form-check">
                                                            <input formControlName="gender" type="radio" id="html" name="gender" value="Male" class="form-check-input">
                                                            <label for="html" class="form-check-label">Male</label><br>
                                                        </div>
                                                        <div class="col-md-12 form-check">
                                                            <input formControlName="gender" type="radio" id="css" name="gender" value="Female" class="form-check-input">
                                                            <label for="css" class="form-check-label">Female</label><br>
                                                        </div>

                                                        <div *ngIf="submitted3 && UserForm2.controls['gender'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['gender'].errors">Required Field
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input   type="date" class="form-control" formControlName="DOB" 
                                                        
                                                        placeholder="DOB"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['DOB'].errors }"
                                                        id="DOB" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['DOB'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['DOB'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="DOB">Date of Birth</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input onKeyPress="if(this.value.length==10) return false;"  type="number" class="form-control" formControlName="Mobile" 
                                                        
                                                        placeholder="Mobile"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Mobile'].errors }"
                                                        id="Mobile" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Mobile'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Mobile'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Mobile">Mobile No<span class="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">


                                                <div class="form-floating mb-3">
                                                    <input onKeyPress="if(this.value.length==10) return false;"
                                                        type="number" class="form-control" formControlName="AltMobile"
                                                        placeholder="AltMobile"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['AltMobile'].errors }"
                                                        id="AltMobile" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['AltMobile'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['AltMobile'].errors.required">
                                                            Required Field</div>

                                                    </div>
                                                    <label for="AltMobile">Alternate No</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input onKeyPress="if(this.value.length==10) return false;"  type="text" class="form-control" formControlName="Pan" 
                                                        
                                                        placeholder="Pan"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Pan'].errors }"
                                                        id="Pan" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Pan'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Pan'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Pan">PAN No<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input onKeyPress="if(this.value.length==12) return false;"  type="number" class="form-control" formControlName="Aadhar" 
                                                        
                                                        placeholder="Aadhar"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Aadhar'].errors }"
                                                        id="Aadhar" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Aadhar'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Aadhar'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Aadhar">Aadhar No</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-12">
                                                <!-- <label>Is NRI <span class="text-danger">*</span></label> -->
                                                <div class="mt-10 form-check">
                                                    <!-- <i class="demo-icon uil-clipboard-notes"></i> -->
                                                    <input
                                                        [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['IsNri'].errors}"
                                                        formControlName="IsNri" class="form-check-input" type="checkbox"
                                                        id="name" name="name" placeholder="Enter Your IsNri">
                                                    <label>Is NRI </label>
                                                    <div *ngIf="submitted3 && UserForm2.controls['IsNri'].errors" class="invalid-feedback ">
                                                        <div *ngIf="UserForm2.controls['Pan'].errors">Required Field
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="text" class="form-control" formControlName="ElectMeter" 
                                                        
                                                        placeholder="ElectMeter"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['ElectMeter'].errors }"
                                                        id="ElectMeter" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['ElectMeter'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['ElectMeter'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="ElectMeter">Electricity Meter</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="email" class="form-control" formControlName="EmailId" 
                                                        
                                                        placeholder="EmailId"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['EmailId'].errors }"
                                                        id="EmailId" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['EmailId'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['EmailId'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="EmailId">Email ID<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="text" class="form-control" formControlName="Occ" 
                                                        
                                                        placeholder="Occ"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Occ'].errors }"
                                                        id="Occ" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Occ'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Occ'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Occ">Occupation</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="number" class="form-control" formControlName="TelRes" 
                                                        
                                                        placeholder="TelRes"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['TelRes'].errors }"
                                                        id="TelRes" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['TelRes'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['TelRes'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="TelRes">Residence Telephone</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="number" class="form-control" formControlName="TelOff" 
                                                        
                                                        placeholder="TelOff"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['TelOff'].errors }"
                                                        id="TelOff" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['TelOff'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['TelOff'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="TelOff">Office Telephone</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <select #LPG (change)="checkLpg(LPG.value)"
                                                        [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['HaveLpg'].errors}"
                                                        (change)="this.UserForm2.get('HaveLpg')?.setValue(LPG.value)"
                                                        class="form-select">
                                                        <option value="" selected>Select Options</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>


                                                    </select>
                                                    <div *ngIf="submitted3 && UserForm2.controls['HaveLpg'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['HaveLpg'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="HaveLpg">Do you have a LPG connection in your name?<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div *ngIf="LpgStatus" class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <select #ans
                                                        [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['IfYes'].errors}"
                                                        (change)="this.UserForm2.get('IfYes')?.setValue(ans.value)"
                                                        class="form-select">
                                                        <option value="" selected>If Yes</option>
                                                        <option value="Indian Gas">Indian Gas</option>
                                                        <option value="HP Gas">HP Gas</option>
                                                        <option value="Bharat Gas">Bharat Gas</option>
                                                        <option value="None Of the Above">None Of the Above</option>


                                                    </select>
                                                    <div *ngIf="submitted3 && UserForm2.controls['IfYes'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['IfYes'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="IfYes">If Yes<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div  *ngIf="LpgStatus" class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="text" class="form-control" formControlName="Distributor" 
                                                        
                                                        placeholder="Distributor"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Distributor'].errors }"
                                                        id="Distributor" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Distributor'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Distributor'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Distributor">Name of the LPG Distributor<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div *ngIf="LpgStatus" class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="text" class="form-control" formControlName="Consumer" 
                                                        
                                                        placeholder="Consumer"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Consumer'].errors }"
                                                        id="Consumer" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Consumer'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Consumer'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Consumer">LPG Consumer No<span class="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="row"> 
                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label>Captcha </label> 
                                                <div class="captcha-text ">
                                                    <div class="mt-15">{{captcha}} <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                

                                                <div class="form-floating mb-3">
                                                    <input  type="text" class="form-control" formControlName="Captcha" 
                                                        
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted3 && UserForm2.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm2.controls['Captcha'].errors.required">
                                                            Required Field</div>
    
                                                    </div>
                                                    <label for="Captcha">Captcha<span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                                </div>
                                            </div>
                                            
                                             </div>
                                            <!-- <div class="row y-middle">
                                                
                                            </div> -->
                                            <div class="mt-20">
                                            <div class="row">
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                <button (click)="submit3()" class="greenbtn2">Submit <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                <div class="text-right md-text-left">
                                                    <button (click)="reset()" class="bluebtn2">Reset <i
                                                            class="demo-icon uil-redo"></i></button>
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                            <div class="text-right mt-20">
                                            <p class="mb-0"><i><b>Please note all fields marked <span class="text-danger">*</span> are compulsory.</b></i></p>
                                            </div>

                                            <div *ngIf="alertType == 'secSubmit'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                        </div>          
                                    
                                    <!---New Form End-->

                                   
                                    </form>

                                    <!-- <div *ngIf="alertMsg != ''"
                                            [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'margin-top':'10px'}"
                                            class="card card-inverse-info" id="context-menu-simple">
                                            <div class="card-body">
                                                <p class="card-text">
                                                    {{alertMsg}}
                                            </div>
                                        </div> -->
                                </div>

                            </div>
                        </div>
                        <!-- *ngIf="status3" -->
                        <div *ngIf="status3" class="mt-20">
                            <div class="light-blue-bg station-list">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mb-30">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <th>Flat No</th>
                                  <th>Building</th>
                                  <th>Location</th>
                                  <th>Meter No</th>
                                  <th>Mobile</th>
                                  <th>Email</th>
                                </tr>
                                <tr>
                                  <td><span class="InstrumentNo">{{this.UserForm2.get('fname')?.value}}</span></td>
                                  <td><span class="PaymentDate">{{this.UserForm2.get('flatNo')?.value}}</span></td>
                                  <td><span class="PaymentSource">{{this.UserForm2.get('BuildingName')?.value}}</span></td>
                                  <td><span class="Amount">{{this.UserForm2.get('Location')?.value}}</span></td>
                                  <td><span class="Amount">{{this.UserForm2.get('ElectMeter')?.value}}</span></td>
                                  <td><span class="Amount">{{this.UserForm2.get('Mobile')?.value}}</span></td>
                                  <td><span class="Amount">{{this.UserForm2.get('EmailId')?.value}}</span></td>
                                </tr>
                              </tbody>
                            </table>
                            <button (click)="submit4()" class="greenbtn2">Confirm <i class="demo-icon uil-arrow-right"></i></button>

                            <div class="alert alert-info mt-20"><b>Your request for a PNG connection is saved.</b></div>

                            </div>


                        </div>
                        <!-- *ngIf="status4" -->
                        <div  *ngIf="status4" class="light-green-bg mt-10">
                            
                            <p class="Application mb-10"><b><i>Please note your Application No:</i></b></p>
                            <div class="Application">
                                <strong><span  id="ContentPlaceHolder1_lblappln_no">{{this.UserForm2.get('ApplicationNo')?.value}}</span></strong>
                            </div>

                            <p class="mb-10"><b><i>Please choose from the options below:</i></b></p>
                            <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-12">
                                <!-- <label class="mb-15">Please Rate the Services of MGL<span class="text-danger">*</span></label> -->
                                
                                    <form>
                                        <div class="row service-label">
                                            <div class="col-md-6">
                                                <div class="rate-services-box">
                                                <div class="form-check">
                        <input (change)="ActivatePrintForm()" formControlName="Rating" type="radio" id="html" name="fav_language" value="Print Application Form" class="form-check-input">
                                        <label for="html" class="mb-0">Print Application Form</label>
                                        </div>
                                        </div>
                                            </div>
                                    
                                            <div class="col-md-6">
                                                <div class="rate-services-box">
                                                <div class="form-check">
                        <input (change)="ActivatePayRegistrationCharges()" formControlName="Rating" type="radio" id="html" name="fav_language" value="Pay Registration Charges" class="form-check-input">
                                                <label for="html" class="mb-0">Pay Registration Charges</label>
                                                </div>
                                                </div>
                                            </div>
                                             </div>
                                    </form>
                               
                            </div>
                            </div>

                            <!-- *ngIf="PrintStatus" -->
                        <p class="mt-20 mb-15"><b><i> Please take the printout of the Application Form by clicking the button.</i> </b></p>
                           <div  *ngIf="PrintStatus">
                                <button (click)="printAppl()"  class="greenbtn2">Print Application Form <i  class="demo-icon uil-print"></i></button>
                            </div>

                       

                        </div>
                        

                        <div *ngIf="status5" class="light-blue-bg mt-10">
                        <!-- *ngIf="status5" -->
                        <form  [formGroup]="UserForm4">

                                <p class="mb-15"><b><i>Please upload a scanned copy of any of the below mentioned documents (preferably in PDF Format):</i></b></p>
                                <div class="mb-25">
                                <ol class="number-list">
                                 <li> Society Maintenance Receipt of the last month (which displays your name & flat number)</li>
                                 <li> Agreement copy (which displays your name & flat number) </li>
                                 <li> Share Certificate 4. Electricity bill (which displays your name & flat number)</li>
                                </ol>
                                </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">

                                    <div class="customer-login-page">

                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mb-10 cu-login-input2">
                                                <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                <select #types1
                                                    [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1'].errors}"
                                                    (change)="this.UserForm4.get('type1')?.setValue(types1.value)"
                                                    name="" id="">
                                                    <option value="" selected>Select Proof of Ownership</option>
                                                    <option value="House Tax Receipt">House Tax Receipt</option>
                                                    <option value="Sale Deed/ Aggreement to sale">Sale Deed/ Aggreement
                                                        to sale</option>
                                                    <option value="lectricity Bill">Electricity Bill</option>
                                                    <option value="Society Share Certificate / Society Letter">Society
                                                        Share Certificate / Society Letter</option>
                                                    <option value="Society Maintenance Bill / Company Letter">Society
                                                        Maintenance Bill / Company Letter</option>
                                                    <option value="Lease Deed with Owner's NOC">Lease Deed with Owner's
                                                        NOC</option>


                                                </select>
                                                <div *ngIf="submitted4 && UserForm4.controls['type1'].errors" class="invalid-feedback">
                                                    <div style="margin-left: 50px;" *ngIf="UserForm4.controls['type1'].errors">Proof of Ownership required </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mb-10 cu-login-input2">
                                                <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                <input
                                                    (change)="this.UserForm4.get('type1File')?.setValue($event.target)"
                                                    [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1File'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter your BP Number">
                                                <div *ngIf="submitted4 && UserForm4.controls['type1File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="margin-left: 50px;"
                                                        *ngIf="UserForm4.controls['type1File'].errors">Proof of
                                                        Ownership required
                                                    </div>
                                                </div>
                                            </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mb-10 cu-login-input2">
                                                <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                <select #types2
                                                    [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type2'].errors}"
                                                    (change)="this.UserForm4.get('type2')?.setValue(types2.value)"
                                                    name="" id="">
                                                    <option value="" selected>Select Proof of identity</option>
                                                    <option value="PAN Card">PAN Card</option>
                                                    <option value="Voter ID">Voter ID</option>
                                                    <option value="Aadhar Card">Aadhar Card</option>
                                                    <option value="Driving License">Driving License</option>
                                                    <option value="Passport">Passport</option>

                                                </select>
                                                <div *ngIf="submitted4 && UserForm4.controls['type2'].errors"
                                                    class="invalid-feedback">
                                                    <div style="margin-left: 50px;"
                                                        *ngIf="UserForm4.controls['type2'].errors">Proof of identity
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mb-10 cu-login-input2">
                                                <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                <input
                                                    (change)="this.UserForm4.get('type2File')?.setValue($event.target)"
                                                    [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type2File'].errors}"
                                                    formControlName="type2File" class="from-control" type="file"
                                                    onKeyPress="if(this.value.length==10) return false;" id="name"
                                                    name="name" placeholder="Enter your BP Number">
                                                <div *ngIf="submitted4 && UserForm4.controls['type2File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="margin-left: 50px;"
                                                        *ngIf="UserForm4.controls['type2File'].errors">Proof of identity
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="mt-10 ">
                                                <button (click)="submit5()" class="greenbtn2">Upload <i class="demo-icon uil-upload-alt"></i></button>
                                            </div>


                                            <div *ngIf="alertType == 'upload'"
                                            [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                            class="card card-inverse-info" id="context-menu-simple">
                                            <div class="card-body">
                                                <p class="card-text">
                                                    {{alertMsg}}
                                            </div>
                                        </div>






                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                        </div>

                         <div *ngIf="activeTC" class="light-blue-bg mt-10 mb-lg-0 mb-30">
                        <!-- *ngIf="activeTC" -->
                        <div  class="row service-label">
                            <div class="col-md-12">
                                <div class="form-check">
                                <input (click)="ActivatePayment()" formControlName="Rating" type="checkbox" id="html" name="fav_language" value="Print Application Form" class="form-check-input">
                                <label for="html" class="">I have also read and agreed all the terms and conditions &
                                    declare that I am the owner of the premise where connection is requested. Incase of
                                    any dispute w.r.t. ownership arising in future, the Security Deposit against the
                                    connection stands forfeited.</label>
                                    </div>
                            </div>
                        </div>


                        <!-- *ngIf="activepaymentlink" -->
                        <div  *ngIf="activepaymentlink"  class="mt-20">
                            <a (click)="makePayment()"
                                class="bluebtn2">Make Payment <i class="demo-icon uil-files-landscapes-alt"></i></a>
                        </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<!-- Inner Page Content Section End -->






<!-- Main content End -->