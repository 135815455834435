<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/about-banner.jpg">
  <div class="banner-heading">
    <h1>Download App Link</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class="active" aria-current="page">Download App Link</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
      <app-recent-post></app-recent-post>

                <app-tips></app-tips>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
  <div class="">
    <p>"MGL Connect" is the simplest way for MGL customers to get connected with MGL. The application is of great help to customers who are prospective or existing. The application can direct you to the nearest CNG station or a drop box to pay your PNG bill. The CNG savings calculator will aid in your decision making for switching over to CNG. From CNG kit supplier to tips for car care and safety are all available at one-touch. Cool features like meter reading upload, viewing domestic bills, payment history and making payment through secured gateway have been provided for your delight and comfort. Safety and security has been top priority so "Know your Meter Reader" helps in identifying the genuinity of your meter reader. With this "App" you need not dial the call center just lodge your complaint through it and it will be attended with the similar priority. MGL Connect is Mahanagar Gas's endeavor to serve its customers better.</p>

    <div class="mt-40 mb-40">
     <h3 class="text-center">Download MGL Connect Now!</h3>
     <div class="row">
     	<div class="col-lg-6 col-md-6 col-sm-12 mx-auto text-center">
     		<a href="https://apps.apple.com/in/app/mgl-connect/id1294636901" target="_blank"><img src="assets/images/app-store.png" class="mr-2"></a>
     		<a href="https://play.google.com/store/apps/details?id=com.mobicule.mgl" target="_blank"><img src="assets/images/play-store.png"></a>
     	</div>
     	
     </div>
    </div>
    <div class="mt-40 mb-40 text-center">
    	<img src="assets/images/_app-store-img_d64cb0041b.jpg">
    </div>

  	</div>
  	<!-- Inner Page Content Section End -->
	</div>


</div>
</div>

</div>