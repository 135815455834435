import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-applyforname-edit',
  templateUrl: './applyforname-edit.component.html',
  styleUrls: ['./applyforname-edit.component.css']
})
export class ApplyfornameEditComponent {
  public base = environment.baseUrl


  LpgConn : boolean = false
  allPost: any[] = [];

  hideValidation:boolean =false
  finalSubmit : boolean = false

  DOBResponse: any = "Required Field"
  captcha: any
  // NameInitial: any[] = ['Mr.', 'Ms.', 'Mrs.','Miss.','Dr.']
  NameInitial: any[] = ['Mr.', 'Ms.', 'Mrs.','Miss.']
  Occupation: any[] = ['Male', 'Female'];
  HaveConn: any[] = ['Yes', 'No'];

  SaleOfFlat1:any=['Agreement - Sales / Purchase deed with present registerd customer',
'Share Certificate of registered housing society endorsed to the applicant',
'NOC from society','Property tax receipt in the applicants name (in case of bungalows)',
'Court sales Document / Deed']
  SaleOfFlat2:any=['NOC from the registered PNG customer / joint owners in case the property is in the joint name']


  Mortaged1:any = ['Lease agreement','Latest Rent Receipt in applicant Name']
  Mortaged2:any = ['NOC from the landlord']
  Mortaged3:any = ['NOC from Registered Customer','Affidavit cum Indemnity']

  Data11:any = ['Purchase of Property','Inherentance of Property','Succession Agreement to Sale','Gift Deed']
  Data21:any = ['Agreement - Sales / Purchase deed with present registerd customer/ Gift Deed with the present registered customer',
'Share Certificate of registered housing society endorsed to the applicant','Property tax receipt in the applicants name','Court sales Document / Deed','allotmnet letter']
  Data31:any = ['NOC','NOC from the registered PNG customer / joint owners in case the property is in the joint name']

  DeathOfReg1 : any = ['Attested Photo copy of Death certificate of Registered Consumer']
  DeathOfReg2 : any =['NOC from sole /all/joint Natural Legal Heits (if any)','Photo copy of Succession Certificate / Will - If there is Natural legal heir']
  DeathOfReg3 : any =['Share certificate of Society','NOC from Society']

  GiftTransfer1 : any = ['Gift Deed with the present registered customer',]
  GiftTransfer2 : any = ['NOC from registered customer in Original','Affidavit cum Indemnity']

  BuilderTransfer1 : any = ['Copy of Flat Agreement with Builder ','NOC letter from Builder','Allotment letter of Flat']
  BuilderTransfer2 : any = ['Registration Form by new consumer']

  InstituteTransfer1 : any = ['Letter from employer to the applicant']

  Doc1Text:any=''
  Doc1File:any=''

  Doc2Text:any=''
  Doc2File:any=''

  Doc3Text:any=''
  Doc3File:any=''

  Data1:any=[]
  Data2:any=[]
  Data3:any=[]

  ShowDoc1:boolean = false
  ShowDoc2:boolean = false
  ShowDoc3:boolean = false


  stopTimer: boolean = false

  submitStatus: boolean = false
  display: string = 'none'
  Popup: any = ''

  address: any[] = []

  ShowOtp: boolean = false
  OtpButtonActive: boolean = false
  ShowUserForm: boolean = false
  UserVerified: boolean = false
  ShowCustomerForm: boolean = false
  ShowGo: boolean = false
  MeterPhoto: any
  LpgSlip: any

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  UserData: any = []

  submitted = false
  OtpSubmitted = false
  CustomerformSubmited = false
  allFaq: any[] = []
  AllReason: any[] = []

  Otp: any
  Minute: any = 1
  Sec: any = 10

  // ValidationForm!: FormGroup
  // checkOtpForm!: FormGroup
  CustomerInfoForm!: FormGroup

  meter_img:any;
  meter_img_name:any;

  LpgSurrSlip_img:any;
  LpgSurrSlip_img_name:any;
   DocName1File_img:any;
   DocName1File_img_name:any;
   DocName2File_img:any;
   DocName2File_img_name:any;
   DocName3File_img:any;
   DocName3File_img_name:any;

   existingCustName:any;FlatNo:any;Floor:any;Wing:any;PlotNo:any;
   BuildingName:any;Colony:any;RoadName:any;Location:any;
   LandMark:any;City:any;Pin:any;OutstandingAmt:any;
   PaymentStatus:any;
   previouspageData:any;
   previouspageData1:any;
  constructor(private PostService: SliderService,private el: ElementRef,private datePipe: DatePipe, private FormBuilder: FormBuilder, private PngService: FormServiceService,
     public UserService: UserRegistrationService, 
     public router: Router, private pageLoaderService: PageLoaderService,
     private activatedRoute: ActivatedRoute,
   ) { 
    this.activatedRoute.queryParams.subscribe((data: any) => {
      // console.log(data);
       this.previouspageData = JSON.parse(data.data);
      //  console.log(this.previouspageData);
       this.previouspageData1 = this.previouspageData[0];
     // console.log(this.previouspageData1);
     
    });
   }

  ngOnInit(): void {
    //this.UserService.checkLogin()
    this.captchaGenerate()
    this.getAllReason()

    this.getAllHighlight()

    // this.ValidationForm = this.FormBuilder.group({
    //   BPno: [''],
    //   CAno: [''],
    //   BPnoCAno: ['', [Validators.required]],
    //   MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]]
    // })

    // this.checkOtpForm = this.FormBuilder.group({
    //   Otp: ['', Validators.required]
    // })

    this.CustomerInfoForm = this.FormBuilder.group({
      flatNo: [''],
      floor: [''],
      plot: [''],
      wing: [''],
      Sector: [''],
      BuildingName: [''],
      SocietyName: [''],
      Colony: [''],
      RoadName: [''],
      Landkmark: [''],
      Location: [''],
      cityDist: [''],
      Pincode: [''],
      NameInitial: ['', [Validators.required]],
      Fname: ['', [Validators.required]],
      lname: ['', [Validators.required]],
      // Pan: ['', [Validators.required]],
      Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
      MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]],
      AltMobileNo: ['', [Validators.pattern(/[0-9]{10}/)]],
      Email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      LpgGasConn: ['', [Validators.required]],
      ResForTrans: ['', [Validators.required]],
      Declaration: ['', [Validators.required]],
      Noc: ['', [Validators.required]],
      TNC: ['', [Validators.required]],
      Captcha: ['', [Validators.required]],
      Mname: [''],
      
      FHusbandName: [''],
      DOB: [''],
      Occupation: [''],
      
      
      
      TelePhone_Res: [''],
      TelePhone_Off: [''],
      
      MeterNo: [''],
      MeterReading: [''],
      MeterPhoto: [''],
      
      GasSuppli: [''],
      GasDistri: [''],
      LpgConnNo: [''],
      LpgSurrSlip: [''],
      
      BPno: [''],
      CAno: [''],
      DocName1:[''],
      DocName1File:[''],
      DocName2:[''],
      DocName2File:[''],
      DocName3:[''],
      DocName3File:[''],
      
      ApplicationId:[''],
      Reason:['']
    });

   

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    const d = new Date(this.previouspageData1.DOB);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
     var myd = [year, month,day ].join('-');

    this.CustomerInfoForm.get('NameInitial')?.setValue(this.previouspageData1.NameInitial);
    this.CustomerInfoForm.get('Fname')?.setValue(this.previouspageData1.Fname);
    this.CustomerInfoForm.get('Mname')?.setValue(this.previouspageData1.Mname);
    this.CustomerInfoForm.get('lname')?.setValue(this.previouspageData1.lname);
    this.CustomerInfoForm.get('FHusbandName')?.setValue(this.previouspageData1.FHusbandName);
    this.CustomerInfoForm.get('DOB')?.setValue(myd);
    this.CustomerInfoForm.get('Occupation')?.setValue(this.previouspageData1.Occupation);
    this.CustomerInfoForm.get('Pan')?.setValue(this.previouspageData1.Pan);
    this.CustomerInfoForm.get('MobileNo')?.setValue(this.previouspageData1.MobileNo);
    this.CustomerInfoForm.get('TelePhone_Res')?.setValue(this.previouspageData1.TelePhone_Res);
    this.CustomerInfoForm.get('TelePhone_Off')?.setValue(this.previouspageData1.TelePhone_Off);
    this.CustomerInfoForm.get('Email')?.setValue(this.previouspageData1.Email);
    this.CustomerInfoForm.get('AltMobileNo')?.setValue(this.previouspageData1.AltMobileNo);
    this.CustomerInfoForm.get('MeterNo')?.setValue(this.previouspageData1.MeterNo);
    this.CustomerInfoForm.get('MeterReading')?.setValue(this.previouspageData1.MeterReading);
    this.CustomerInfoForm.get('LpgGasConn')?.setValue(this.previouspageData1.LpgGasConn);
    this.CustomerInfoForm.get('GasSuppli')?.setValue(this.previouspageData1.GasSuppli);
    this.CustomerInfoForm.get('GasDistri')?.setValue(this.previouspageData1.GasDistri);
    this.CustomerInfoForm.get('LpgConnNo')?.setValue(this.previouspageData1.LpgConnNo);
    this.CustomerInfoForm.get('ResForTrans')?.setValue(this.previouspageData1.ResForTrans);
    this.CustomerInfoForm.get('DocName1')?.setValue(this.previouspageData1.Doc1Type);
    this.CustomerInfoForm.get('DocName2')?.setValue(this.previouspageData1.Doc2Type);
    this.CustomerInfoForm.get('DocName3')?.setValue(this.previouspageData1.Doc3Type);
    this.CustomerInfoForm.get('flatNo')?.setValue(this.previouspageData1.FlatNo);
    this.CustomerInfoForm.get('floor')?.setValue(this.previouspageData1.Floor);
    this.CustomerInfoForm.get('wing')?.setValue(this.previouspageData1.Wing);
    this.CustomerInfoForm.get('plot')?.setValue(this.previouspageData1.plot);
    this.CustomerInfoForm.get('BuildingName')?.setValue(this.previouspageData1.PlotNo);
    this.CustomerInfoForm.get('Colony')?.setValue(this.previouspageData1.Colony);
    this.CustomerInfoForm.get('RoadName')?.setValue(this.previouspageData1.RoadName);
    this.CustomerInfoForm.get('Location')?.setValue(this.previouspageData1.Location);
    this.CustomerInfoForm.get('Landkmark')?.setValue(this.previouspageData1.LandMark);
    this.CustomerInfoForm.get('cityDist')?.setValue(this.previouspageData1.City);
    this.CustomerInfoForm.get('Pincode')?.setValue(this.previouspageData1.Pin);
    this.CustomerInfoForm.get('DocName1File')?.setValue(this.previouspageData1.Doc1File);
    this.CustomerInfoForm.get('DocName2File')?.setValue(this.previouspageData1.Doc2File);
    this.CustomerInfoForm.get('DocName3File')?.setValue(this.previouspageData1.Doc3File);
    this.CustomerInfoForm.get('LpgSurrSlip')?.setValue(this.previouspageData1.LpgSurrSlip);
    this.CustomerInfoForm.get('MeterPhoto')?.setValue(this.previouspageData1.MeterPhoto);
    this.HaveConnection(this.previouspageData1.LpgGasConn)
    this.ReasonForTransfer(this.previouspageData1.ResForTrans)

    this.existingCustName=this.previouspageData1.existingCustName;
    this.FlatNo=this.previouspageData1.FlatNo;
    this.Floor=this.previouspageData1.Floor;
    this.Wing=this.previouspageData1.Wing;
    this.PlotNo=this.previouspageData1.PlotNo;
    this.BuildingName=this.previouspageData1.BuildingName;
    this.Colony=this.previouspageData1.Colony;
    this.RoadName=this.previouspageData1.RoadName;
    this.Location=this.previouspageData1.Location;
    this.LandMark=this.previouspageData1.LandMark;
    this.City=this.previouspageData1.City;
    this.Pin=this.previouspageData1.Pin;
    this.OutstandingAmt=this.previouspageData1.OutstandingAmt;
    this.PaymentStatus=this.previouspageData1.PaymentStatus;
   


  }


  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    
      this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
      

    })
    
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }


  ReasonForTransfer(val:any){
    this.ShowDoc1 = false
    this.ShowDoc2 = false
    this.ShowDoc3 = false

    //console.log(val)

    this.CustomerInfoForm.get('DocName1')?.clearValidators()
    this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
    this.CustomerInfoForm.get('DocName1File')?.clearValidators()
    this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
    
    this.CustomerInfoForm.get('DocName2')?.clearValidators()
    this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
    this.CustomerInfoForm.get('DocName2File')?.clearValidators()
    this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()

    this.CustomerInfoForm.get('DocName3')?.clearValidators()
    this.CustomerInfoForm.get('DocName3')?.updateValueAndValidity()
    this.CustomerInfoForm.get('DocName3File')?.clearValidators()
    this.CustomerInfoForm.get('DocName3File')?.updateValueAndValidity()
    if(val == "Purchase of Property/ Inherentance of Property/ Succession Agreement to Sale/ Gift Deed"){

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName3')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName3')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName3File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName3File')?.updateValueAndValidity()

      this.Doc1Text = ''
      this.Doc2Text = 'Flat Agreement / other documents'
      this.Doc3Text = 'NOC'

      this.Data1 = this.Data11
      this.Data2 = this.Data21
      this.Data3 = this.Data31
      this.ShowDoc1 = true
      this.ShowDoc2 = true
      this.ShowDoc3 = true

    }else if(val == "Property acquired by way of court order/ Aution/ Transfer of Tenancy/Leave and Licence Agreement / Lease"){

      this.Doc1Text = 'Flat Agreement'
      this.Doc2Text = 'NOC from Landlord'
      this.Doc3Text = 'NOC'

      this.Data1 = this.Mortaged1
      this.Data2 = this.Mortaged2
      this.Data3 = this.Mortaged3
      this.ShowDoc1 = true
      this.ShowDoc2 = true
      this.ShowDoc3 = true

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName3')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName3')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName3File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName3File')?.updateValueAndValidity()

    }else if(val == "Sale of Flat"){

      this.Doc1Text = 'Purchase Agreement'
      this.Doc2Text = 'NOC'
      

      this.Data1 = this.SaleOfFlat1
      this.Data2 = this.SaleOfFlat2
      
      this.ShowDoc1 = true
      this.ShowDoc2 = true

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
      
     
      

    }else if(val == "Demise of Registered Customer"){

      this.Doc1Text = 'Death Certificate'
      this.Doc2Text = 'NOC of Legal Heirs'
      this.Doc3Text = 'NOC from Society'

      this.Data1 = this.DeathOfReg1
      this.Data2 = this.DeathOfReg2
      this.Data3 = this.DeathOfReg3
      this.ShowDoc1 = true
      this.ShowDoc2 = true
      this.ShowDoc3 = true

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName3')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName3')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName3File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName3File')?.updateValueAndValidity()
      
    }else if(val == "Inheritance of Property / Succession Agreement to sale"){

      this.Doc1Text = 'Gift Deed'
      this.Doc2Text = 'NOC'

      this.Data1 = this.GiftTransfer1
      this.Data2 = this.GiftTransfer2
      
      this.ShowDoc1 = true
      this.ShowDoc2 = true

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
      
    
      
      
    }else if(val == "Other- Builder Transfer"){

      this.Doc1Text = 'Purchase Agreement'
      this.Doc2Text = 'Registration Form'

      this.Data1 = this.BuilderTransfer1
      this.Data2 = this.BuilderTransfer2
      
      this.ShowDoc1 = true
      

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      

      
      
      
    }else if(val == "Other- Institutional Transfer"){

      this.Doc1Text = 'NOC'

      this.Data1 = this.InstituteTransfer1
     
      
      this.ShowDoc1 = true

      this.CustomerInfoForm.get('DocName1')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()
      
     
      
      
      
    }else{
      this.ShowDoc1 = true
      
      this.CustomerInfoForm.get('DocName1File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName1File')?.updateValueAndValidity()

    }
  }

  HaveConnection(val: any) {

    //console.log(val)

    if (val == 'Yes') {
      this.CustomerInfoForm.get('GasSuppli')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('GasSuppli')?.updateValueAndValidity()
      this.CustomerInfoForm.get('GasDistri')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('GasDistri')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgConnNo')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('LpgConnNo')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgSurrSlip')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('LpgSurrSlip')?.updateValueAndValidity()
      this.LpgConn = true
    } else if (val == 'No') {

      this.CustomerInfoForm.get('GasSuppli')?.clearValidators()
      this.CustomerInfoForm.get('GasDistri')?.clearValidators()
      this.CustomerInfoForm.get('LpgConnNo')?.clearValidators()
      this.CustomerInfoForm.get('GasSuppli')?.updateValueAndValidity()
      this.CustomerInfoForm.get('GasDistri')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgConnNo')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgSurrSlip')?.clearValidators()
      this.CustomerInfoForm.get('LpgSurrSlip')?.updateValueAndValidity()
      this.LpgConn = false
    }

  }

  openPopup() {
    this.Popup = 'show'
    this.display = 'block'
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
    this.stopTimer = true
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getAllReason() {
   // console.log("running")
    this.PngService.getAllReason().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.AllReason = data
     // console.log(data)
      this.AllReason.sort(function(a,b){
        if(a.ReasonText< b.ReasonText) return -1;
        if(a.ReasonText >b.ReasonText) return 1;
        if(a.ReasonText< b.ReasonText) return -1;
        if(a.ReasonText >b.ReasonText) return 1;
        return 0;
      });
    })
  }

  fileCheckForMeter(val: any) {
   // console.log(val.files)
    this.meter_img = val.target.files;
    this.meter_img_name = val.target.files[0];

    // if (val.files[0].type === 'image/jpeg' || val.files[0].type === 'image/png' || val.files[0].type === 'image/jpg' && val.files[0].size <= (1 * 1024 * 1024)) {

    // } else {
    //   this.MeterPhoto = null
    //   return alert('Please Select .jpg, .jpeg, .png*')
    // }
  }

  fileCheckForSlip(val: any,name:any) {
    if (name=='LpgSurrSlip') {
      this.LpgSurrSlip_img = val.target.files;
      this.LpgSurrSlip_img_name = val.target.files[0];
      
    }else if(name=='DocName1File'){
      this.DocName1File_img = val.target.files;
      this.DocName1File_img_name = val.target.files[0];

    }else if(name=='DocName2File'){
      this.DocName2File_img = val.target.files;
      this.DocName2File_img_name = val.target.files[0];

    }else if(name=='DocName3File'){
      this.DocName3File_img = val.target.files;
      this.DocName3File_img_name = val.target.files[0];

    }
   
    // console.log(val.files)
    // if (val.files[0].type === 'application/pdf' && val.files[0].size < (2 * 1024 * 1024)) {

    // } else {
    //   this.CustomerInfoForm.get(name)?.setValue('')
    //   return alert('Please Select .pdf*')
    // }
  }

  clearAlert() {
    this.alertType = ''
  }

  

  submitCustomerForm() {

    const controls = this.CustomerInfoForm.controls;
    for (const name in this.CustomerInfoForm.controls) {
     
      if (controls[name].invalid) {
        document.querySelector<HTMLInputElement>(`input[formControlName=${name}]`)?.focus()
             if(name == 'NameInitial' || name == 'Occupation' || name == 'LpgGasConn' || name =='ResForTrans'){
         document.querySelector<HTMLInputElement>(`select[formControlName=${name}]`)?.focus()
         }else{
           document.querySelector<HTMLInputElement>(`input[formControlName=${name}]`)?.focus()
         }
        
        
        break;
        
      }
  }


    var d1 = new Date(this.CustomerInfoForm.get('DOB')?.value);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    var daydiff = (diff / 31536000000).toFixed(0);

    if (parseInt(daydiff) < 18) {
      this.CustomerInfoForm.get('DOB')?.setValue('')
      this.DOBResponse = "Should be Above 18"
    }

    if (this.CustomerInfoForm.get('Captcha')?.value != this.captcha) {
      this.CustomerInfoForm.get('Captcha')?.setValue('')
    }

    this.CustomerformSubmited = true

    if (this.CustomerInfoForm.get('Declaration')?.value == false) {
      this.CustomerInfoForm.get('Declaration')?.setValue('')
    }
    if (this.CustomerInfoForm.get('Noc')?.value == false) {
      this.CustomerInfoForm.get('Noc')?.setValue('')
    }
    if (this.CustomerInfoForm.get('TNC')?.value == false) {
      this.CustomerInfoForm.get('TNC')?.setValue('')
    }

    
  //  console.log(this.CustomerInfoForm.get('MeterPhoto')?.value)
    if (this.CustomerInfoForm.invalid) {
      return
    }
    
    this.CustomerInfoForm.get('BPno')?.setValue(this.previouspageData1.BPno)
    this.CustomerInfoForm.get('CAno')?.setValue(this.previouspageData1.CAno)

    const form = new FormData()

    if (this.meter_img_name != null) {
        let meterImg = this.meter_img[0].name.split('.').pop()
    let meterImg1  =this.meter_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData1.BPno + new Date() +'.'+ meterImg;
      form.append("MeterPhoto", this.meter_img[0], meterImg1)
     // form.append("MeterPhoto", this.meter_img[0], this.meter_img[0].name)
    }

    if (this.LpgSurrSlip_img_name != null) {
    let LpgSurrSlipImg = this.LpgSurrSlip_img[0].name.split('.').pop()
    let LpgSurrSlipImg1  =this.LpgSurrSlip_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData1.BPno + new Date() +'.'+ LpgSurrSlipImg;
      form.append("LpgSurrSlip", this.LpgSurrSlip_img[0], LpgSurrSlipImg1)

      //form.append("LpgSurrSlip", this.LpgSurrSlip_img[0], this.LpgSurrSlip_img[0].name)
    }

    if (this.DocName1File_img_name != null) {
     
       let DocName1Img = this.DocName1File_img[0].name.split('.').pop()
    let DocName1Img1  =this.DocName1File_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData1.BPno + new Date() +'.'+ DocName1Img;
      form.append("DocName1File", this.DocName1File_img[0], DocName1Img1)

      //form.append("DocName1File", this.DocName1File_img[0], this.DocName1File_img[0].name)
    }

    if (this.DocName2File_img_name != null) {
       let DocName2Img = this.DocName2File_img[0].name.split('.').pop()
    let DocName2Img1  =this.DocName2File_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData1.BPno + new Date() +'.'+ DocName2Img;
      form.append("DocName2File", this.DocName2File_img[0], DocName2Img1)
     
      //form.append("DocName2File", this.DocName2File_img[0], this.DocName2File_img[0].name)
    }

    if (this.DocName3File_img_name != null) {
         let DocName3Img = this.DocName3File_img[0].name.split('.').pop()
    let DocName3Img1  =this.DocName3File_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData1.BPno + new Date() +'.'+ DocName3Img;
     
    form.append("DocName3File", this.DocName3File_img[0], DocName3Img1)
    
     // form.append("DocName3File", this.DocName3File_img[0], this.DocName3File_img[0].name)
    }
    form.append('DocName3Name',this.CustomerInfoForm.get('DocName3')?.value)
    form.append('DocName2Name',this.CustomerInfoForm.get('DocName2')?.value)
    form.append('DocName1Name',this.CustomerInfoForm.get('DocName1')?.value)
    form.append('NameInitial', this.CustomerInfoForm.get('NameInitial')?.value)
    form.append('Fname', this.CustomerInfoForm.get('Fname')?.value)
    form.append('Mname', this.CustomerInfoForm.get('Mname')?.value)
    form.append('lname', this.CustomerInfoForm.get('lname')?.value)
    form.append('FHusbandName', this.CustomerInfoForm.get('FHusbandName')?.value)
    form.append('DOB', this.CustomerInfoForm.get('DOB')?.value)
    form.append('Occupation', this.CustomerInfoForm.get('Occupation')?.value)
    form.append('Pan', this.CustomerInfoForm.get('Pan')?.value)
    form.append('MobileNo', this.CustomerInfoForm.get('MobileNo')?.value)
    form.append('TelePhone_Res', this.CustomerInfoForm.get('TelePhone_Res')?.value)
    form.append('TelePhone_Off', this.CustomerInfoForm.get('TelePhone_Off')?.value)
    form.append('Email', this.CustomerInfoForm.get('Email')?.value)
    form.append('MeterNo', this.CustomerInfoForm.get('MeterNo')?.value)
    form.append('MeterReading', this.CustomerInfoForm.get('MeterReading')?.value)
    form.append('LpgGasConn', this.CustomerInfoForm.get('LpgGasConn')?.value)
    form.append('GasSuppli', this.CustomerInfoForm.get('GasSuppli')?.value)
    form.append('GasDistri', this.CustomerInfoForm.get('GasDistri')?.value)
    form.append('LpgConnNo', this.CustomerInfoForm.get('LpgConnNo')?.value)
    form.append('ResForTrans', this.CustomerInfoForm.get('ResForTrans')?.value)
    form.append('BPno', this.previouspageData1.BPno)
    form.append('CAno', this.previouspageData1.CAno)
    form.append('existingCustName', this.existingCustName)
    form.append('FlatNo', this.FlatNo)
    form.append('Floor', this.Floor)
    form.append('Wing', this.Wing)
    form.append('PlotNo', this.PlotNo)
    form.append('BuildingName', this.BuildingName)
    form.append('Colony', this.Colony)
    form.append('RoadName', this.RoadName)
    form.append('Location', this.Location)
    form.append('LandMark', this.LandMark)
    form.append('City', this.City)
    form.append('Pin', this.Pin)
    form.append('OutstandingAmt', this.OutstandingAmt)
    form.append('PaymentStatus', this.PaymentStatus)
    form.append('AltMobileNo', this.CustomerInfoForm.get('AltMobileNo')?.value)
    form.append('ApplicationNo', this.previouspageData1.ApplicationNo)
    form.append('Reason', this.previouspageData1.Reason)
    
    

    this.PngService.updatepostNameTransferForm(form).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      this.finalSubmit=true
     // console.log(response)
      this.alertType = '3rdSubmit'
      if (data.Response) {
        this.CustomerInfoForm.get('ApplicationId')?.setValue(data.details.ApplicationId)
       
        this.UserService.ApplicationNo=data.details.ApplicationId
        // this.CustomerInfoForm.get('BPnoCAno')?.setValue(this.ValidationForm.get('BPnoCAno')?.value)
       // this.CustomerInfoForm.get('BPnoCAno')?.setValue(this.ValidationForm.get('BPnoCAno')?.value)

        this.UserService.printNameTransfer = this.CustomerInfoForm.value
        this.alertMsg = "Submited Successfully | Application No." + data.details.ApplicationId
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
          this.router.navigate(['/thank-you'])
        }, 1000);
      } else {
        this.alertMsg = "Something went wrong"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
      }
    })
  }

  

  
}

