<!-- Inner Page Content Section Start -->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>NACH Cancel Form For Commercial</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page">NACH Cancel Form For Commercial</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-industrial-service-section></app-industrial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="latestnews-section">
                    <app-recent-post></app-recent-post>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                            <div class="customer-login-page">
                                <!-- <div class="log-meter-text">
                                    <ul>
                                        <li>* Kindly enter <b>CA</b> number as appearing on your bill.</li>
                                    </ul>
                                </div> -->
                                <div class="row">
                                      <form [formGroup]="UserForm">

                                        
                                        <div class="row">

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="MandateId"
                                                        placeholder="MandateId"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MandateId'].errors }"
                                                        id="MandateId" />
                                                    <div *ngIf="submitted && UserForm.controls['MandateId'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['MandateId'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="MandateId">Mandate Id<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input onKeyPress="if(this.value.length==12) return false;" type="number" class="form-control" formControlName="CAno"
                                                    placeholder="CAno"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['CAno'].errors }"
                                                    id="CAno" />
                                                <div *ngIf="submitted && UserForm.controls['CAno'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['CAno'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="CAno">CA No<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control" formControlName="BPNo"
                                                    placeholder="BPNo"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['BPNo'].errors }"
                                                    id="BPNo" />
                                                <div *ngIf="submitted && UserForm.controls['BPNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['BPNo'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="BPNo">BP No<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Name"
                                                    placeholder="Name"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Name'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && UserForm.controls['Name'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Name'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Name">Full Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control" formControlName="Contact"
                                                    placeholder="Contact"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Contact'].errors }"
                                                    id="Contact" />
                                                <div *ngIf="submitted && UserForm.controls['Contact'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Contact'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Contact<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" formControlName="Email"
                                                    placeholder="Email"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                                                    id="Email" />
                                                <div *ngIf="submitted && UserForm.controls['Email'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && UserForm.controls['Email'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Email">Email<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        
                                    </div>


                                        


                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                                <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                                <div class="mt-20 ml-15">
                                                    <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                   <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                    aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-7 col-sm-12">
                                                
                                                
    
                                                <div class="form-floating mb-3">
                                                    <input [ngClass]="{'is-invalid':submitted && UserForm.controls['Captcha'].errors}" type="text" class="form-control" id="Captcha" placeholder="Captcha" formControlName="Captcha">
                                                    <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                                                    <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                          class="invalid-feedback">
                                                          <div
                                                              *ngIf="UserForm.controls['Captcha'].errors">Required Field</div>
                                                      </div>
                                                  </div>
                                            </div>
    
                                            
                                        </div>
                                        <div class="mt-20 ml-15">
                                            <button (click)="submit()" style="border: none;" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                    </form>

                                    <div *ngIf="alertType == 'CA'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>



                                </div>

                                <span *ngIf="confirmationStatus">
                                    <div class="mb-20 mt-10">
                                        <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                        CA Number : {{Cadata[0].Name}}


                                    </div>

                                    <div class="mb-20 mt-10">
                                        <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->


                                        Email : {{Cadata[0].Email}}


                                    </div>

                                    <div class="mb-20 mt-10">
                                        <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->

                                        Mobile : {{Cadata[0].Contact}}
                                    </div>

                                    <div class="mt-20">
                                        <button (click)="confirm()" style="border: none;" class="greenbtn2">Confirm <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                </span>

                                <span *ngIf="confirmedStatus" >
                                    <h6 class="mt-20">This Page Is Under Construction</h6>
                                    
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>



<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            * Kindly enter Otp as appearing on your Mobile.
                        </div>
                        <form [formGroup]="checkOtpForm">
                            <div class="row">
                                


                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-floating mb-3">
                                        <input onKeyPress="if(this.value.length==6) return false;"  type="number" class="form-control"
                                            formControlName="Otp" placeholder="Otp"
                                            [ngClass]="{ 'is-invalid': OtpSubmitted && checkOtpForm.controls['Otp'].errors }"
                                            id="Otp" />
                                        <div *ngIf="OtpSubmitted && checkOtpForm.controls['Otp'].errors"
                                            class="invalid-feedback">
                                            <div
                                                *ngIf="checkOtpForm.controls['Otp'].errors.required">
                                                Required Field
                                            </div>
                                        </div>
                                        <label for="Otp">Enter OTP<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button [disabled]="submitStatus" (click)="verifyOtp()" class="{{submitStatus ? 'greybtn' :'greenbtn2' }}">Submit OTP<i
                                            class="demo-icon uil-arrow-right"></i></button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button [disabled]="OtpButtonActive"
                                        (click)="OtpGenerate()" class="{{OtpButtonActive ? 'greybtn' :'greenbtn2' }}">{{OtpButtonActive ?
                                        this.OtpMinute+":"+this.OtpSec:
                                        "Resend OTP"}}<i class="demo-icon uil-arrow-right"></i></button>
                                </div>
                            </div>


                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>


                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>