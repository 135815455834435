import { Component } from '@angular/core';
import { MenuService } from 'src/app/Service/menu.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-investor-update-and-presentation',
  templateUrl: './investor-update-and-presentation.component.html',
  styleUrls: ['./investor-update-and-presentation.component.css']
})
export class InvestorUpdateAndPresentationComponent {

  allData:any[]=[]
  baseUrl=environment.baseUrl
  constructor(private Service:MenuService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getPdf()
  }

  getPdf(){
    this.Service.getPdfByCategory({PdfCategory:'Investor Updates & Presentation'}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
        this.allData=data.filter((x:any)=>{
        return x.IsDeleted != true
      })

     // this.allData=data
    })
  }
}
