<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Education </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a [routerLink]="['/csrinitiative']">CSR Initiatives</a></li>
                <li class="active" aria-current="page">Education</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>
                 <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
                <!-- <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="mt-30 mb-30">
                        <p> We firmly believe that the only way to transform a nation is through the means of education.
                            We at MGL therefore place education as one of the topmost priority areas of intervention.
                            MGL Unnati is our flagship CSR intervention under which meritorious youths from the
                            socio-economically weaker strata are trained and mentored to face competitive entrance
                            examinations for engineering colleges. Focussing on long term goals of life, the project
                            believe in shaping the future so that the path unknown is defined and not derived. 50
                            students have been trained under the initiative with 97% students getting admissions in
                            various engineering colleges and 23.3% in IITs / NITs. MGL also extends one time
                            scholarships to the best performers.</p>
                    </div>

                    <div class="light-blue-bg mt-30 csr-policies">
                        <img src="assets/images/education_pic1.jpg" class="w-100">
                    </div>

                    <div class="mt-30 mb-30">
                        <h3>Aakash Jagtap (Saga of change…)</h3>
                        <p>Akash Vinodrao Jagtap is a teenager from a remote village of Maharashtra. Akash born and
                            brought up in a remote village had extraordinary intelligence. This enabled him to seek
                            admission in Navodaya Vidyalaya which exposed him to the opportunities in various spheres of
                            life. His interaction with other students fascinated him to aspire for technical studies.
                        </p>

                    </div>

                    <div class="mt-30 mb-30">
                        <h6>In his own words Akash says:-</h6>

                        <p>“My village is not so developed so people are not highly literate over there. I am the first
                            boy from my village to pass 12th standard. At Navodaya my friends discussed about IITs and
                            that fascinated me. Getting into IIT became a dream for me.”</p>
                    </div>
                    <div class="light-blue-bg mt-30 mb-30 csr-policies">
                        <img src="assets/images/education_pic2.jpg" class="w-100">
                    </div>
                    <p>Unfortunately his father Vinodrao Jagtap died when Akash was still studying. His mother’s income
                        of Rs. 12,000/- per month somehow facilitated their living. After completing his 12th standard
                        Akash still required handholding and training to get equipped to face the tough competitive
                        entrance examination. However, his mother’s income could not support this. This was when Akash
                        heard about ‘MGL Unnati’- an initiative of Mahanagar Gas Limited which provides free of cost
                        training to students from the lower socio-economic strata of the society. Akash appeared for the
                        entrance examination for MGL Unnati and got selected. What followed was 11 months of rigorous
                        study, training and guidance that equipped Akash and 19 other students to appear in the IIT
                        entrance examination. Leading the bunch from the front Akash cleared the entrance examination
                        and is now studying in IIT Delhi. Following his success Mahanagar Gas Limited felicitated Akash
                        Jagtap and 3 other successful students of MGL Unnati with one time scholarships of Rs. 50,000/-.
                    </p>
                    <b>After clearing the examination Akash had the following to say:-</b>
                    <p>“I cannot stop thanking MGL Unnati for the support extended to me. I am moving towards fulfilling
                        my dream of becoming an IITian now. I want to work for my village so that children there should
                        not be deprived of knowledge and opportunities.”</p>
                    <p><b>Sports for Development</b> is a child centric CSR intervention being implemented by MGL. The
                        project focuses on activity based learning and reaches out to more than 1500 children across two
                        slum areas in and around Mumbai.</p>
                    <p><b>World Water Day ‘Save Water Campaign</b></p>
                    <p>On the occasion of World Water Day ‘Save Water Campaign’ was organized by students of Sharda
                        School, Bhuvapada and R. K. School, Bhuvapada on 21st and 22nd of March, 2016 respectively. The
                        campaign was organized under ‘Sports for Development Program’. Nearly 400 children participated
                        in the campaign.</p>
                    <div class="light-blue-bg mb-30 mt-30 csr-policies">
                        <img src="assets/images/education_pic3.jpg" class="w-100">
                    </div>

                    <p>The objective of the campaign was to raise the awareness of the community about the vitality of
                        water and the urgent need to check wastage of water. The idea was to enable residents correlate
                        their day to day activity with acute water crisis the community is facing. Children designed
                        placards and banners for the campaign and roamed through the streets of the locality while
                        raising slogans in support of water conservation.</p>

                    <p>The campaign received active participation from the management of both the schools and caught the
                        imagination of the residents. Many residents informed that the word of our children pleading to
                        save water was really a touching episode for us. We will definitely look to adopt mechanism and
                        practices to reduce wastage of water.</p>

                    <p>MGL has also taken up various interventions at schools intended to enhance the learning level
                        outcomes. We have taken up installation of computer laboratory in 2 MCGM Schools in Mumbai.
                        Apart from the infrastructure MGL has also extended the support of one computer teacher to
                        ensure proper utilization of the infrastructure.</p>

                    <p>We have established 5 mini science centres in schools to facilitate interactive learning of
                        science and maths. Each science centre consists of 60 table top models that relates to
                        theoretical inputs on sciences and utilizes a child’s natural curiosity to explore.</p>

                    <div class="light-blue-bg mb-30 mt-30 csr-policies">
                        <img src="assets/images/education_pic4.jpg" class="w-100">
                    </div>
                    <p>MGL Komal Jivan is an initiative designed for welfare of street children – such children whose
                        cries and pain are lost amidst the hustles and bustles of the city. Leading a meaningless life;
                        these children are most vulnerable to all sorts of exploitations. A wide gamut of reasons like
                        death of one or both parents, poverty, breakdown of families, sexual abuse, physical abuse,
                        emotional abuse, domestic violence, mental health problems, substance abuse, gender identity,
                        sexual orientation, etc. drives these children out of their home and exposes them to abuse and
                        extortion. Because of the lack of support structure these children adopt strategies to cope with
                        the harsh realities of their lives. These strategies include developing a tough exterior, strong
                        independence to hide their vulnerability and forming groups amongst others. The circumstances
                        lead children to engage in behaviours that children in families typically do not.</p>

                    <p>Maladaptive strategies like drinking alcohol, using drugs, substance abuse, theft (Either
                        voluntary or forced), beggary, aggressive outlook, etc. can be identified as the strategies
                        which pushes the children to be outlaws. </p>

                    <p>The vulnerabilities, the abuses and the adaptive strategies often create a negative framework
                        which subtly nurtures more aggressive and violent behaviour thereby destructing a child’s
                        innocence and creating an outlaw.</p>

                    <p>The goal of the project is to reach out to the less blessed, roofless and rootless children who
                        lead a life of hopelessness and utter despair. The idea is to nurture these tender lives so that
                        they can lead a life of dignity and contribute positively to the society. 65 such children are
                        being rehabilitated in shelter home while another 2500 have been reached out under the outreach
                        activities. 100% of children in the shelter home have been admitted in formal schools. Computer
                        literacy and personality development classes, regular workshops, seminars and counselling are
                        conducted to assist them to pursue career option of their choice.</p>

                    <div class="light-blue-bg mb-30 mt-30 csr-policies">
                        <img src="assets/images/education_pic6.jpg" class="w-100">
                    </div>

                    <h3>MGL MUSKAAN</h3>
                    <p>‘MGL Muskaan’ is a community development initiative designed with the objective of enhancing
                        quality of life amongst members of slum community.</p>
                    <div class="blue-tick-list mb-40">
                        <ul>
                            <li>More than 60 children benefitting from support classes and computer classes.</li>
                            <li>130 children benefitting from Balwadi and supplementary nutrition.</li>
                            <li>40 children benefited from Spoken English classes.</li>
                            <li>More than 550 patients benefitted from free health camps.</li>
                            <li>50 youths getting trained on computer skills and more than 30 females covered under
                                tailoring classes.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    