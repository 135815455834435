  import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { vehiclepromotionResp } from '../models/vehiclepromotion';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Retrofitters20Service {

   baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }



  insertRetrofitters(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/insertRetrofitters',data)
    }


      search_Retrofitters_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/search_Retrofitters_Application',data)
    }

     search_Level_one_Retrofitters_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/search_Level_one_Retrofitters_Application',data)
    }

      search_Level_two_Retrofitters_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/search_Level_two_Retrofitters_Application',data)
    }

     edit_Retrofitters(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/edit_Retrofitters',data)
    }


      insert_Retrofitters_Level_one(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/insert_Retrofitters_Level_one',data)
    }

     insert_Retrofitters_Level_one_Edit(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/insert_Retrofitters_Level_one_Edit',data)
    }

    insert_Retrofitters_Level_two(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/insert_Retrofitters_Level_two',data)
    }


     getRetrofitters_levle_one_list(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters_2_0/getRetrofitters_levle_one_list',data)
    }

}
