import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-customer-user-registration',
  templateUrl: './customer-user-registration.component.html',
  styleUrls: ['./customer-user-registration.component.css']
})
export class CustomerUserRegistrationComponent implements OnInit {

  btnStatus:boolean=false
  display:string='none'
  Popup:any=''
  stopTimer:boolean=false
  submitStatus:boolean=false
  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  dialog: boolean = false

  UserForm !: FormGroup

  OtpForm = new FormGroup({
    originalOtp: new FormControl(''),
    InputOtp: new FormControl('')
  })
  allFaq: any[] = []

  submitted: any = false

  Minute: any = 5
  Sec: any = 59

  OtpButtonActive: boolean = true

  siteKey: any = '6Ldkyr0lAAAAAGBd8ykRHYMJqyuxZe70ugOmzBZk'
  captcha: any
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: any = 'image' || 'audio';

  constructor(private FormBuilder: FormBuilder, private router: Router, private service: UserRegistrationService, public FaqService: FaqServiceService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    this.UserForm = this.FormBuilder.group({
      Name: ['', Validators.required],
      BpNo: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      CaNo: ['', [Validators.required, Validators.pattern(/^\d{12}$/)]],
      MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]],
      Password: ['', [Validators.required, , Validators.minLength(7), Validators.pattern(/^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[#?!@$%^&*-_]).{8,}$/)]],
      Captcha: ['',Validators.required],
      Email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      Otp: [''],
    })

    this.getAllFaq()
  }

  openPopup(){
    this.Popup='show'
    this.display='block'
  }

  closeModel(){
    this.Popup=''
    this.display='none'
    this.stopTimer=true
  }

  otpTimer() {

    var minute = 5;
    var sec = 59;
    const count = setInterval(function () {

      sec--;

      if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0

        clearInterval(count)

      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }

      test()


    }, 1000);

    var test = () => {
      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.btnStatus = true
        
        clearInterval(count)

      }
      this.Minute = minute
      this.Sec = sec

    }

  }

  getAllFaq(){
    this.FaqService.getAllFaq().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      let filtered = data.filter((x:any)=>{
        return x.caption == 'Customer Awareness'
      })
      this.allFaq=filtered
    })
  }

  handleSuccess(data: any) {
    //console.log(data);
  }

  openDialog() {
    if (this.dialog === false) {
      this.dialog = true
    } else {
      this.dialog = false
    }
  }

  resendOtp() {
    this.captchaGenerate()
    this.service.sendOtp(this.OtpForm.get('originalOtp')?.value, this.UserForm.get('MobileNo')?.value)
    this.service.updateOtpToDb(this.UserForm.value).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      this.otpTimer()
      this.OtpButtonActive = true
      this.alertType = 'secSubmit'
      this.alertMsg = "OTP Resend Succesful"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
      this.btnStatus = false
      setTimeout(() => {
        this.clearStatus()
      }, 500);
    })
  }

  clearStatus(){
    this.alertType=''
  }

  verify() {

    //this.UserForm.get('Otp')?.setValue(this.OtpForm.value.InputOtp)
    this.service.checkOtp(this.UserForm.value).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      //console.log(data)
      this.alertType = 'secSubmit'
      if (data === 'Sucessfully Registered') {
        this.alertMsg = "OTP Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
        
        setTimeout(() => {
          //this.dialog=false
          this.router.navigate(['/user-dashboard'])
          this.OtpForm.reset()
        }, 500)
        
      }else{
        this.alertMsg = "OTP Did Not Match"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.clearStatus()
          this.OtpForm.reset()
          
        }, 500)
      }
      
    })
  }

  captchaGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    //console.log(result)
    this.OtpForm.get('originalOtp')?.setValue(result)
    this.UserForm.get('Otp')?.setValue(result)
  }

  submit() {

    this.submitted = true

    // if((this.UserForm.get('BpNo')?.value).slice(0,2) != 11){
    //   this.UserForm.get('BpNo')?.setValue('')
    // }
    // if((this.UserForm.get('CaNo')?.value).slice(0,2) != 21){
    //   this.UserForm.get('CaNo')?.setValue('')
    // }
    //console.log(this.UserForm.invalid)
    if(this.UserForm.invalid) {
      return
    }

    if(this.UserForm.get('Password')?.invalid){
      return
    }

    if(this.UserForm.get('BpNo')?.invalid){
      return
    }

    if (this.UserForm.get('BpNo')?.value !== '' && this.UserForm.get('CaNo')?.value !== '' &&
      this.UserForm.get('Name')?.value !== '' && this.UserForm.get('MobileNo')?.value !== '' &&
      this.UserForm.get('Password')?.value !== '' && this.UserForm.get('Captcha')?.value !== '' &&
      this.UserForm.get('Email')?.value !== '') {
      this.captchaGenerate()
      this.service.registerUser(this.UserForm.value).subscribe(response => {
        let res = JSON.parse(JSON.stringify(response)).Response
        //console.log(res)
        if (res) {
          // console.log('Working')
          this.service.sendOtp(this.OtpForm.get('originalOtp')?.value, this.UserForm.get('MobileNo')?.value)
          this.OtpButtonActive = true
          this.otpTimer()
          this.openPopup()
        }else{
          return alert(JSON.parse(JSON.stringify(response)).message)
        }
      })
    }
  }
}
