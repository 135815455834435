import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';

@Component({
  selector: 'app-conversion-enquiry-form',
  templateUrl: './conversion-enquiry-form.component.html',
  styleUrls: ['./conversion-enquiry-form.component.css']
})
export class ConversionEnquiryFormComponent {


  fuelType: string[] = []
  UserForm!: FormGroup
  submitted: boolean = false;


  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  captcha:any

  constructor(private Service : FormServiceService,private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.captchaGenerate()

    this.fuelType = ['Petrol', 'Diesel', 'LPG', 'CNG']

    this.UserForm = this.FormBuilder.group({
      Name: ['', Validators.required],
      Address: [''],
      City: [''],
      Area: ['', Validators.required],
      MobileNo: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      PhoneNo: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      Email: ['', Validators.required],
      VehicleManu: ['', Validators.required],
      Model: [''],
      Year: [''],
      type: ['', Validators.required],
      AvgKm: ['', Validators.required],
      Comment: [''],
      Captcha: ['', Validators.required],

    })


    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }


  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }


  reset() {
    this.fuelType = []
    this.UserForm.reset()
    this.submitted = false

    setTimeout(() => {
      this.fuelType = ['Petrol', 'Diesel', 'LPG', 'CNG']
    }, 200);
  }

  submit() {
    this.submitted = true


    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
	this.captchaGenerate();

    if (this.UserForm.invalid) {
      return
    }


    this.Service.postConversionForm(this.UserForm.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.Response){
        this.alertType='submit'
        this.alertMsg = "Submitted"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {
          this.alertType=''
          this.UserForm.reset()
          this.submitted = false
        }, 1000);
      }

    })
  }
}
