import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';
declare var Digio:any

@Component({
  selector: 'app-nach-online',
  templateUrl: './nach-online.component.html',
  styleUrls: ['./nach-online.component.css']
})
export class NachOnlineComponent implements OnInit {

  UserForm!:FormGroup
  submited = false

  status:boolean=false
  captcha:any

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center' 
  mandate_id:any
  customer_identifier:any

      nachAmt:any
      nachEmail:any 
      nachName:any 
      nachDates:any 
      nachCAno:any


  constructor(private FormBuilder : FormBuilder,public Service : UserRegistrationService, private pageLoaderService: PageLoaderService,private dashboardService : DashboardServiceService,
    private activatedRoute: ActivatedRoute,
    public router: Router,) {
    
     this.activatedRoute.queryParams.subscribe((data: any) => {
       // console.log(data);
       if(data.data=="ENach"){
        this.Service.checkLogin();
         this.status=false;
       }else if(data.data=="Nach"){
        this.status=true
      }
      });
 
     }

  ngOnInit(): void {

    this.captchaGenerate()

    this.UserForm = this.FormBuilder.group({
      Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),Validators.required]],
      Name:['',[Validators.required]],
      Dates:['',[Validators.required]],
      Captcha:['',[Validators.required]],
      CAno:[''],
    Amt:['',[Validators.required]],
    })

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    let data = sessionStorage.getItem('UserCredential')
    if(!data){
      //this.status=true
    }else{
      this.UserForm.get('CAno')?.setValue(JSON.parse(data).CaNo)
      this.UserForm.get('Name')?.setValue(JSON.parse(data).FirstName)
     // console.log(this.UserForm.value)
    }
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  } 

  submit(){
    this.submited=true 
 
    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
    this.captchaGenerate();
    if(this.UserForm.invalid){
      return
    }

    if(this.UserForm.get('Amt')?.value < 1000 || this.UserForm.get('Amt')?.value > 100000){
    return alert("Minimum amount should be 1000 and maximum amount should be 100000");
    }
      let numconvert = this.UserForm.get('Amt')?.value
     this.nachAmt = numconvert.toString()
      this.nachEmail = this.UserForm.get('Email')?.value
       this.nachName = this.UserForm.get('Name')?.value
        this.nachDates = this.UserForm.get('Dates')?.value
         this.nachCAno = this.UserForm.get('CAno')?.value

          //console.log(this.nachAmt,this.nachEmail,this.nachName,this.nachDates,this.nachCAno)

     var  nachAmt = crypto.AES.encrypt(this.nachAmt, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
      var  nachEmail = crypto.AES.encrypt( this.nachEmail, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
       var  nachName = crypto.AES.encrypt( this.nachName, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
        var  nachDates = crypto.AES.encrypt(this.UserForm.get('Dates')?.value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
         var  nachCAno = crypto.AES.encrypt( this.nachCAno, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
  let obj ={"Email":nachEmail,"Name":nachName,"Dates":nachDates,"CAno":nachCAno,"Amt":nachAmt}
 // console.log(obj)
 this.Service.openEnach1(obj).subscribe(response=>{
  //console.log(response)
  let data = JSON.parse(JSON.stringify(response))
    // console.log(data)
      if (data.response) {

        alert('NACH mandate information is submitted partially. Please fill the bank details from NACH popup')

         this.mandate_id = data.details.mandate_id;
         //console.log('this.mandate_id',this.mandate_id);
         
         //this.UserForm.get('Email')?.value
         this.customer_identifier = this.UserForm.get('Email')?.value;

                  var options = {
          //default environment staging (sandbox)
           "environment" : "production",
          //"environment" : "sandbox",
          // "environment" : "https://api.digio.in/v3/client/mandate/",// here
           "callback":(t:any)=>{
        if(t.error_code!=undefined){
        alert("failed to register");
         this.checkStatus()
       
        }else{ 
        alert("register successful for "+t.digio_doc_id);
        this.checkStatus()
        
      }
         },                                                    
         "logo":" https://mahanagargas.com/assets/images/logo.png", //Pass Logo URL here
          "is_iframe": true ,//open in iframe,
         "dg_preferred_auth_type": "debit"  // debit or net_banking
       };

var digio:any = new Digio(options);
digio.init();  // Call init on user action (eg.button press and proceed to asynchronous upload)
digio.submit(this.mandate_id,this.customer_identifier); 
// Get the documentId by calling Digio upload APIs. Email/Mobile is signer’s identifier
//In case of failure to retrieve DocumentId call digio.cancel();

      }else{

        alert('NACH mandate form is not submitted due to some problem, try later!')

      }

    
 

  })
    // this.dashboardService.postNachOnline(this.UserForm.value).subscribe(response=>{
    //   let data = JSON.parse(JSON.stringify(response))

    //   if(data.Response){

    //     this.alertType='nach'
    //     this.alertMsg = "Submitted"
    //     this.alertColor = 'rgba(4, 183, 107, 0.2)'
    //     this.alertTextColor = '#038b51'
    //     this.alertBorder = '1px solid #04a862;'

    //     setTimeout(() => {
    //       this.alertType=''
    //     }, 1000);


    //   }else{
    //     this.alertType='nach'
    //     this.alertMsg = "Something Went Wrong"
    //     this.alertColor = 'rgba(255, 94, 109, 0.2)'
    //     this.alertTextColor = '#c24753'
    //     this.alertBorder = '1px solid #eb5664;'
    //     setTimeout(() => {
    //       this.alertType=''
    //     }, 1000);
    //   }

        
    // })





  }

checkStatus(){
    let obj = {Mandate_Id:this.mandate_id}
     this.Service.get_NachMandate_status(obj).subscribe(response=>{
    // console.log(response)
     let data = JSON.parse(JSON.stringify(response))
     if (data.Response) {
      // console.log(data)

        let obj1={CAno:this.UserForm.get('CAno')?.value,Email:this.UserForm.get('Email')?.value,Name:this.UserForm.get('Name')?.value,
        Dates:this.UserForm.get('Dates')?.value,Amt:this.UserForm.get('Amt')?.value,UMRN:data.details.umrn,
          Mandate_Id:data.details.mandate_id,Mandate_Status:data.details.state}
        this.dashboardService.postNachOnline(obj1).subscribe(res=>{
      let data1 = JSON.parse(JSON.stringify(res))

      if(data1.Response){
       //  console.log('in response',data)
         if(data.details.state=='partial'){
          this.router.navigate(['/pay-error/'+data.details.mandate_id+'/You have cancelled the Payment'])

         }else if(data.details.state=='auth_failed'){
          this.router.navigate(['/pay-error/'+data.details.mandate_id+'/Your Payment is Failed,Please try again'])

         }else{
           this.router.navigate(['/pay-success/'+data.details.mandate_id])
         }
         }else{
        this.alertType='nach'
        this.alertMsg = "Something Went Wrong"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.alertType=''
        }, 1000);
      }

        
    })

      }else{

         this.alertType='nach'
        this.alertMsg = "Something Went Wrong"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.alertType=''
        }, 1000);

      }
  })
  }



}

 

