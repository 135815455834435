import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewslettersService {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

   getNewsletters(){

      return this.http.post<any>(this.baseUrl+'getNewsletters',{})
    }
}
