<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>If You Smell Gas</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a href="">Residential PNG</a></li>

                <li class="active" aria-current="page">If You Smell Gas</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                   <app-news-comp></app-news-comp>

                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="if-you-smell-gas innerpage-right-content rs-services style2 rs-services-style2 ">
                    <h6>If you smell gas</h6>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-20">
                            <div class="service-wrap">
                                <div class="image-part">
                                    <img src="assets/images/safty-img1.jpg" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">Open all Doors and Windows</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-20">
                            <div class="service-wrap">
                                <div class="image-part">
                                    <img src="assets/images/safty-img2.jpg" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">Turn off your gas supply by using Meter Control Valve (MCV)</div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-20">
                            <div class="service-wrap">
                                <div class="image-part">
                                    <img src="assets/images/safty-img3.jpg" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">Do not switch on or off any electrical switch</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 md-mb-20">
                            <div class="service-wrap">
                                <div class="image-part">
                                    <img src="assets/images/safty-img4.jpg" alt="">
                                </div>
                                <div class="content-part">
                                    <div class="desc">Extinguish all naked flames</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 sm-mb-20">
                            <div class="service-wrap">
                                <div class="image-part">
                                    <img src="assets/images/safty-img5.jpg" alt="">
                                </div>
                                <div class="content-part">
                                    <h3 class="title"><a></a></h3>
                                    <div class="desc">
                                        Call emergency service no <b>18002669944</b> (Toll free) / <b>(022)-68759400
                                        /
                                        (022)-24012400</b> after coming out from the danger area</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>