<!--Full width header Start-->
<div class="full-width-header">
    <!--Header Start-->
    <header id="rs-header" class="rs-header style2 header-transparent">
        <!-- Topbar Area Start -->
        <div class="topbar-area style1">
            <div class="container custom">
                <div class="row y-middle">
                    <div class="col-lg-5">
                        <div class="topbar-contact">
                            <ul>
                               <!-- <li> <i class="demo-icon uil-calling"></i> Emergency No/ Dial before Dig: <a href="tel:180026699">1800 266 99</a> 
                                <a href="tel:180026644">44</a>
                                <a href="tel:68674500">6867 4500</a>
                               </li> -->

                               <li>
                                <i class="demo-icon uil-calling"></i> Emergency No : 1800 <a href="tel:180026699">266 99</a> 
                                <a href="tel:180026644">44</a> / MGL Sahayogi : <a  href="tel:1800 2100 2100">1800 2100 2100</a></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-7 text-right">
                        <div class="toolbar-sl-share">
                            <ul>
                                <li>
                                <div class='cngrate-slider'>
                                  <!-- <div class='cngrate-slide1'><a href="JavaScript:void(0)">CNG Rate:{{CngPrice}}/Kg</a></div> -->
                                  <div class='cngrate-slide1'><a href="JavaScript:void(0)">CNG Rate:₹ 75/Kg</a></div>
                                  <div class='cngrate-slide2'><a href="JavaScript:void(0)">PNG Rate: ₹ 48/SCM</a></div>
                                  <div class='cngrate-slide3'><a href="JavaScript:void(0)">CNG Rate: ₹ 75/kg</a></div>
                                  <div class='cngrate-slide4'><a href="JavaScript:void(0)">PNG Rate: ₹ 48/SCM</a></div>
                                </div>
                                </li> 
                                <li class="opening"><a [routerLink]="['/quick-bill-pay']"><i class="demo-icon uil-lightbulb-alt"></i> Quick Bill Pay</a></li>
                                <!-- <li><a href="JavaScript:void(0)">CNG Rate: {{CngPrice}}</a> <a href="JavaScript:void(0)">PNG Rate: ₹13.60/scm</a></li> -->
                                <li><a [routerLink]="['/']"><i class="demo-icon uil-home-alt"></i></a></li>
								
                                <li class="select-language">
                                    <select (change)="redirecturl(lang.value)" #lang (change)="selected=lang.value" class="form-select" aria-label=".form-select-lg example">
                                        <option disabled >Select Language</option>
                                        
                                        <option *ngFor="let i of langauge" value="{{i.url}}" hidden="{{selected == i ? 'hidden':''}}">{{i.lang}}</option>
                                        
                                    </select>
                                </li>
                                <li><a [routerLink]="['/cng/customer-zone/cng-locator']"><i
                                            class="demo-icon uil-location-pin-alt"></i> CNG Locator</a></li>
                                <li class="search-parent position-relative {{SearchStatus ? 'open_add_class' :''}}">
                                    <div class="search-toggle {{SearchStatus ? 'opened' : ''}}">
                                        <button (click)="openSearch()" class="search-icon icon-search"> <i
                                                class="demo-icon uil-search"></i></button>
                                        <button (click)="openSearch()" class="search-icon icon-close"><i
                                                class="demo-icon uil-multiply"></i></button>
                                    </div>
                                    <div class="search-container {{SearchStatus ? 'opened' : ''}}">
                                        <form [formGroup]="userForm">
                                            <input (ngModelChange)="modelChanged(inputs.value)" #inputs formControlName="inputs" type="text" name="q" id="search-terms"
                                                placeholder="Search terms..." list="browsers"/>

                                                <datalist id="browsers">
                                                    <option *ngFor="let i of routerUrl" value="{{i.data.name}}">
                                                
                                                  </datalist>
                                            <button (click)="navigateTo(inputs.value)" type="submit" name="submit" value="Go" class="search-icon"><i
                                                    class="demo-icon uil-search"></i></button>
                                        </form>
                                    </div>
                                </li>
                                 <!-- <li *ngIf="this.UserService.loginStatus">
                                    <a (click)="login()"><i class="demo-icon uil-sign-out-alt"></i></a>
                                 </li> -->
                                 <li>
                                   <div class="dropdown">
                                    <button class="dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false">
                                     <i class="demo-icon uil-user-circle"></i>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login']">My Account</a></li>
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">Update Contact Details</a></li>
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/change-password']">Change Password</a></li>
                                      <li *ngIf="this.UserService.loginStatus"><a class="dropdown-item" [routerLink]="['/residential-png/customer-zone/log-complaint']">Log Complaint</a></li>
									  <li *ngIf="!this.UserService.loginStatus"><a (click)="login()" class="dropdown-item" [routerLink]="['/residential-png/customer-zone/customer-login']">User Login</a></li>
									  
                                      <li><hr class="dropdown-divider"></li> 
                                      <li *ngIf="this.UserService.loginStatus"><a (click)="login()" class="dropdown-item">Logout</a></li>
									  <li *ngIf="!this.UserService.loginStatus"><a  class="dropdown-item" [routerLink]="['/user-registration']">User Registration</a></li>
                                      <li *ngIf="this.UserService.loginStatus1"><a (click)="login1()" class="dropdown-item">Retrofitters Logout</a></li>
                                      <li *ngIf="this.UserService.loginStatus2"><a (click)="login2()" class="dropdown-item">OEM Logout</a></li>
                                    </ul>
                                  </div>  
                                 </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Topbar Area End -->

        <app-nav-bar></app-nav-bar>

    </header>
    <!--Header End-->
</div>
<!--Full width header End-->