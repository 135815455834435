import { Component } from '@angular/core';

@Component({
  selector: 'app-apppayupaymenterror',
  templateUrl: './apppayupaymenterror.component.html',
  styleUrls: ['./apppayupaymenterror.component.css']
})
export class ApppayupaymenterrorComponent {

}
