<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
       <h1>धन्यवाद
       </h1>
       <div class="breadcrumbs">
          <ul class="">
             <li class=""><a [routerLink]="['/']">मुखप्रुष्ट</a></li>
             
             <li class="active" aria-current="page">धन्यवाद</li>
          </ul>
       </div>
    </div>
 </div>
 <!-- Services Section Start -->
 <app-service-section></app-service-section>
 <!-- Services Section End -->
 
 <div class="innerpage-content-section">
    <div class="container custom">
        <div class="light-blue-bg text-center">
            <h2 class="dual-heading mb-30">
              
                फॉर्म भरल्याबद्दल धन्यवाद!  <span>तुमचा अर्ज आयडी आहे <b>{{id}}</b></span></h2>
                 <div class="mt-30 mb-30">
             <img src="assets/images/thank-your-application-graphic.png">
          </div> 
          <!-- <a (click)="printForm()" class="greenbtn2 mr-10">View Application  <i class="demo-icon uil-file-check"></i></a> -->
           <a  (click)="printFormEOI()" class="greenbtn2 mr-10">EOI अर्ज फॉर्म प्रिंट करा<i class="demo-icon uil-file-check"></i></a>
            <a [routerLink]="['/']" class="bluebtn2">मुखप्रुष्ट वर जा <i class="demo-icon uil-home-alt"></i></a>
        </div>
        </div>
    </div>