<!--Full width header End-->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CNG Testing Center</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a href="#">CNG</a></li>
                <li class=""><a href="#">Customer Zone </a></li>
                <li class="active" aria-current="page">CNG Testing Center</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>NAME AND ADDRESS</th>
                                    <th>SITE ADDRESS</th>
                                    <th>APPROVAL NO</th>
                                    <th>DATE OF ISSUE</th>
                                    <th>GAS</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>M/s. GreenTech Solution, Shop No. 13 to 16, Road No. 17, Parksite Vikhroli (W),
                                        Mumbai. Tel: 022-65723604</td>
                                    <td>Survey No. 47, Village Padale, Navi Mumbai-Kalyan Highway, Taluka-Thane,
                                        Dist-Thane, Maharashtra - 421 204. Ph: 91-9833880325 </td>
                                    <td>G.3(3)29/597</td>
                                    <td>09/12/2013</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>M/s. Amogsiddha Auto CNG & LPG Works, Shop No. 27, Ganga Nagar, W. E. Highway,
                                        Opp. Mahindra Gate, Kandivali (East), Mumbai - 400 101. Ph: 022-65551625</td>
                                    <td>Survey No. 103/B, Village Maljipada, Tal. Vasai, Dist. Thane </td>
                                    <td>G.3(3)29/658</td>
                                    <td>07/05/2014</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>M/s. S.V. Greentech Pvt. Ltd, B-201, Amrut Industrial Estate, Opp. Vasani Petrol
                                        Pump, Western Express Highway, Mira Road(East), Thane - 401 104</td>
                                    <td>Gut no.517/1 & 529 ,Village Kelthan Taluka Wada, Dist Thane, Thane - 401204 Ph :
                                        022-28456621 </td>
                                    <td>G.3(3)29/656</td>
                                    <td>09/06/2015</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>M/s. Everest Kanto Cylinder Ltd., Plot No - 62, M. I. D. C, Industrial Area,
                                        Kumbhavali Naka, Tarapur - 401 506. Maharashtra Ph: 022-30268300/01</td>
                                    <td>Plot No. 62, M.I.D.C Industrial Area, Kumbhavali Naka, Tarapur- 401 506,
                                        Maharashtra Ph: 022-30268300/01 </td>
                                    <td>G.3(3)29/241</td>
                                    <td>26/06/2007</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td> M/s. Sainath Autogas Systems Pvt. Ltd., Plot No. 2698 B & 2699 B, Sunrise
                                        Compound, Daulat Nagar, S.V. Road, Borivali (E), Mumbai - 400 066.</td>
                                    <td>Village Maljipada, S.No. 111(PT), Taluka Vasai, Dist. Thane. Ph: 91-9594057672,
                                        91-9004090161. </td>
                                    <td>G.3(3)29/460</td>
                                    <td>21/05/2009</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>M/s. Care Cylinders Inspection Agency, House No. 394, Block No. 111, Near Trupti
                                        Hotel, Lodha Paradise Service Road, Majiwada, Thane West, Thane - 400 601. Ph:
                                        022-65243253, 91-7506264848,</td>
                                    <td>Mankoli Office- Survey No. 93, Hissa No. 21(P), Village Dapode, Post Rahanal,
                                        Tal. Bhiwandi, Mankoli Naka, Distt. Thane, Maharashtra Ph: 91-9819113253,
                                        91-9702773253 Shilphata Office- Gut No. 59/1, Near Prit Weigh Bridge, Opp. HP
                                        Petrol Pump, Panvel-Mumbra Highway, Village Valivali, Grampanchayat Dahisar
                                        Mori, Shilphata, Thane - 400612 Ph: 91-8422983253, 022-65243253, 91-9860260339
                                    </td>
                                    <td>G.3(3)29/538</td>
                                    <td>21/12/2011</td>
                                    <td>CNG & Hydrogen
                                    </td>
                                </tr>

                                <tr>
                                    <td>7</td>
                                    <td>M/s. Greenglobe Fuel Solutions, Plot No. 151, Brick Factory Compound, Shastri
                                        Nagar, Mulund Colony, Mulund(W), Mumbai - 400 082. Ph: 022-25673221, 022
                                        25677775</td>
                                    <td>Plot No. 151, Brick Factory Compound, Shastri Nagar, Mulund Colony, Mulund(W),
                                        Mumbai - 400 082. Ph: 022-25673221, 022-25677775, 91-9819749350 </td>
                                    <td>G.3(3)29/332</td>
                                    <td>10/01/2006</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>M/s. Confidence Petroleum India Ltd., 404, Satyam Apartment, 8-Wardha Road,
                                        Dhantoli, Nagpur - 440012. Ph: 0712 3250318/19, 9833534748</td>
                                    <td>Near HP Building, Gadkari Khadan, BPT Road, Chembur, Mumbai (E) Ph:
                                        91-9833534748 </td>
                                    <td>G.3(3)29/663</td>
                                    <td>30/07/2014</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>M/s. Apna Green 18 Automobiles, 3/23, Prabhunath Dubey Estate, Shyam Nagar,
                                        Jogeshwari (E), Mumbai - 400 060. Ph: 91-9892833592</td>
                                    <td>Survey No - 19/1, Varsave Gaon, Near Fountain Hotel, Beside Shipyard, Ghodbunder
                                        Gaon Road, Mira Road (E), Thane- 401104 Ph: 91-9892833592, 91-8097091011</td>
                                    <td>G.3(3)29/666</td>
                                    <td>11/09/2015</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>M/s Navkar Cylinder Testing Co., CTS No. 339, Near Sonata Building, Jan Kalyan
                                        Nagar, Gate No.1, Marve Road, Malad (West) Mumbai - 400095 Ph: 91-9717301717
                                    </td>
                                    <td>CTS No. 339, Near Sonata Building, Jan Kalyan Nagar, Gate No.1, Marve Road,
                                        Malad (West) Mumbai - 400095 Ph: 91-9717301717, 91-8433961117/ 18 </td>
                                    <td>G.3(3)29/813</td>
                                    <td>23/11/2016</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>M/s Apna Motors Shop No 2, Abdullqa compound , Gandhi Nagar, Kurar Village,
                                        Malad (East), Mumbai- 400097 Ph: 91-7045552053, 91-7045552056</td>
                                    <td>Survey No- 79, Hissa No- 07, Madarsa Compound, Ajijia Nagar, Mandavi Pada,
                                        Kashigaon, Mira road (E ) Ph: 91-7045552053, 91-7045552056 </td>
                                    <td>G.3(3)29/597</td>
                                    <td>20/01/2016</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>GREEN DRIVE, Survey No. 29, Village - Wardoli, Shedung, Tal. Panvel, Dist.
                                        Raigad, Maharashtra - 410206</td>
                                    <td>Survey No. 29, Village - Wardoli, Shedung, Tal. Panvel, Dist. Raigad,
                                        Maharashtra - 410206, Contact Person - Mr. DHARMIK BHADRA, 9594022110 /
                                        9372051390; greendrive.solutions@gmail.com </td>
                                    <td> </td>
                                    <td>21/12/2018</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>M/s. Fortune Fuel Solutions, Mira Road, Thane-401107. Tel: +919768112786</td>
                                    <td>Survey No. 263/3, Near Horizon School, Anand Nagar,Ghodbunder Road, Kavesar,
                                        Thane-400610 </td>
                                    <td>A/G/WC/MH/ GCT/1 (G52736)</td>
                                    <td>11/10/2019</td>
                                    <td>CNG</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>M/s Autosonic Inspection & Engineering, I-302, Garden Residency, Near Shyam
                                        Bunglow, Gala, South Bopal, Bopal Ahmedabad, Ahmedabad City, Taluka: Ahmedabad
                                        City, District: Ahmedabad, State: Gujrat, Pin:380058</td>
                                    <td>Survey No. 119/1, Opp Hotel Kohinoor Village, Khoni, District: Thane, State:
                                        Maharashtra, Pin:421204. </td>
                                    <td>A/G/WC/MH/ GCT/5 (G59413)</td>
                                    <td>10/10/2019</td>
                                    <td>CNG</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>