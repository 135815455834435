import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ntlc',
  templateUrl: './ntlc.component.html',
  styleUrls: ['./ntlc.component.css']
})
export class NtlcComponent {
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      window.location.href = 'https://mgl.ebillz.in/Notice/LowConsumptionNotice.php?billKey=' + id;
      console.log('Url Id: ', id);
    })
  }
}
