<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>MGL HSE Policy</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class=""><a >Policies</a></li>
                <li class="active" aria-current="page">MGL HSE Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                      <app-news-comp></app-news-comp>
                    <!-- <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 w-100">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>

                    </div> -->


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="mt-30 mb-30">
                        <p>MGL is supplying piped and compressed natural gas to its stakeholders. Our Goal is zero injuries because we believe that injuries are preventable. We conduct our business in a responsible manner while adhering to internationally accepted good practices. HSE performance is everyone's responsibility and each one of us has a duty to intervene to prevent unsafe actions and to reinforce safe behavior through demonstrating HSE leadership.</p>
                        <h6>At MGL we are all committed to:</h6>
                        <div class="blue-tick-list">
                            <ul>
                                <li>Provide safe & healthy working conditions for prevention of work related injury and ill health by eliminating hazards and reducing occupational health and safety risks</li>
                                <li>Protect the environment by efficient use of material and energy and develop energy resources, products and services</li>
                                <li>Respect our neighbours and contribute to the societies in which we operate</li>
                                <li>Play a leading role in promoting best HSE practices by adopting relevant techniques and methods for periodical assessment of the status on HSE and taking all the remedial measures</li>
                                <li>Integrate HSE in all decisions by managing HSE matters as any other critical business activity</li>
                                <li>Promote a HSE culture in which all MGL employees share this commitment by training, education, consultation and participation of workers and their representatives</li>
                            </ul>
                        </div>
                        <p>In this way we aim to have an HSE performance that we can be proud of and earn the confidence of stakeholders, apart from contributing to sustainable development including prevention of pollution.</p>
                        <h6>In implementing this policy, we:</h6>
                        <div class="blue-tick-list">
                            <ul>
                                <li>Have a systematic approach to HSE management designed to ensure compliance obligation with the legal and other requirements, MGL Life Saving Rules and to achieve continual performance improvement</li>
                                <li>Set targets to measure, improve, appraise and report HSE performance in Annual Reports</li>
                                <li>Require the business partners to manage HSE in line with this policy</li>
                                <li>Engage effectively with interested parties and impacted communities</li>
                                <li>Include HSE performance in the appraisal of staff, reward and career advancement</li>
                            </ul>
                        </div>
                        <p><b><i>NB: The Policy is communicated within MGL and made available to the public and interested parties.</i></b></p>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->