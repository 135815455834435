<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Billing <span>Cycle</span></h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>Residential PNG</a></li>
        <li class=""><a >Customer Zone</a></li>
        <li class=""><a >Customer Login</a></li>
        <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
        <li class="active" aria-current="page">Billing Cycle</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->

<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <div class="latestnews-section">
          <app-dashboard-info-card></app-dashboard-info-card>
        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="mb-30">
          <h6> You can log your Meter Reading between following dates only</h6>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="billing-cycle-section">
                <i class="demo-icon uil-calender"></i>
                <div class="billing-cy-content">
                  <b>Normal</b>
				  <div class="mb-10">{{access1}}</div>
                  <div class="mb-10">{{access2}}</div>
                  <div class="mb-10">{{access3}}</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="billing-cycle-section">
                <i class="demo-icon uil-calender"></i>
                <div class="billing-cy-content">
                  <b>Assessed</b>
                  <div class="mb-10">{{normal1}}</div>
                  <div class="mb-10">{{normal2}}</div>
                  <div class="mb-10">{{normal3}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->