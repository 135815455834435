import { Component } from '@angular/core';

import { IndustrialCommercialTariffService } from 'src/app/Service/industrial-commercial-tariff.service';

import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-commercialtariffcard',
  templateUrl: './commercialtariffcard.component.html',
  styleUrls: ['./commercialtariffcard.component.css']
})
export class CommercialtariffcardComponent {
  industArray: any[] = [];
   myTitle:any;
  constructor(private pageLoaderService: PageLoaderService, private service: IndustrialCommercialTariffService) { }

   ngOnInit(): void {
    this.getData();
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
   

 

  }

   getData() {

   this.service.getCommercialtariff().subscribe(response => { 
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         this.industArray =res.details; 
         this.myTitle = this.industArray[0].title;
        // this.alertColor = 'rgba(4, 183, 107, 0.2)'
        // this.alertTextColor = '#038b51'
        // this.alertBorder = '1px solid #04a862;'
        // this.alertType = 'vehiclepromotion'
        // this.alertMsg = res.message;
       
        // setTimeout(() => {
        //   this.clearAlert()

        // }, 5000);

      } else {
        this.industArray = [];
        // this.alertColor = 'rgba(255, 94, 109, 0.2)'
        // this.alertTextColor = '#c24753'
        // this.alertBorder = '1px solid #eb5664;'
        // this.alertType = 'vehiclepromotion'
        // this.alertMsg = res.message;
        // //  this.promotionForm.reset();
        // setTimeout(() => {
        //   this.clearAlert()

        // }, 5000);
      }



    }, err => {
      //console.log(err)
    })

  }
}
