import { Component } from '@angular/core';
import { MenuService } from 'src/app/Service/menu.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-openplotnearjitegaon',
  templateUrl: './openplotnearjitegaon.component.html',
  styleUrls: ['./openplotnearjitegaon.component.css']
})
export class OpenplotnearjitegaonComponent {
 allData:any[]=[]
  baseUrl=environment.baseUrl
  constructor(private Service:MenuService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getPdf()
  }

  getPdf(){
    this.Service.getPdfByCategory({PdfCategory:'Requirement of Open Plot near Jitegaon'}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
       let filtered = data.filter((x:any)=>{
        return x.IsDeleted == false
      })
      // this.blogdata=filtered
      // console.log(filtered)
      this.allData=filtered
    })
  }
}
