<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Overview  <span></span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class="active" aria-current="page">Overview </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                   <!--  <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>

                    </div> -->


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <p><b>What is Compressed<span> Natural Gas (CNG)?</span></b></p>
                            <p>CNG is a fossil fuel substitute for other auto fuels such as petrol, diesel, Auto LPG
                                etc. For use in Automobiles as fuel, Natural Gas is compressed & dispensed to vehicles
                                at high pressure of 200 bar to enhance the vehicle on board storage capacity.</p>

                            <p>Today, almost all the auto-rickshaws and taxies are plying on CNG. CNG is also being
                                supplied to transport undertakings such as BEST, TMT, MSRTC & NMMT. Besides these,
                                passenger cars, postal vans, school buses, courier vans, Light and Heavy commercial
                                vehicles are also enjoying the benefits of CNG.</p>

                            <p>CNG is substantially economical and Eco-friendly than the conventional liquid auto fuel.
                                Moreover, it is easily available in and around the Mumbai city, making it the preferred
                                auto fuel of Mumbai.</p>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <p><b>CNG Facts</b></p>
                                    <img src="assets/images/cng-facts.png" alt="CNG Facts">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-30 mb-30">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="light-green-bg">
                                    <h4>Economical </h4>
                                    <div class="blue-tick-list">
                                        <ul class="">
                                            <li>Easy on the pocket.</li>
                                            <li>CNG delivers high performance at low cost, as it gives your vehicle a
                                                better mileage.</li>
                                            <li>CNG does not contaminate or dilute crank case oil, giving engine an
                                                extended life.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="light-yellow-bg">
                                    <h4>Easily Available </h4>
                                    <div class="blue-tick-list">
                                        <ul class="unordered-list">
                                            <li>MGL has a robust network of CNG filling stations across its areas of
                                                operation.</li>
                                            <li>MGL is also expanding the CNG network in areas of Kalyan, Dombivali,
                                                Ambernath, Badlapur, Ulhasnagar, Bhiwandi, Panvel and Raigad district.
                                            </li>
                                            <li>In case of Petrol Car / Taxi, dual fuel option is available that helps
                                                in switching between CNG and petrol as and when required.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-30 mb-30">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="light-green-bg">
                                    <h4> Eco Friendly </h4>
                                    <div class="blue-tick-list">
                                        <ul class="">
                                            <li>The use of CNG significantly reduces harmful vehicular exhaust
                                                gas&nbsp;emissions like carbon dioxide, carbon monoxide and other
                                                suspended particles.</li>
                                            <li>It protects the environment by reducing the effects of global warming.
                                            </li>
                                            <li>Non –toxic, non-corrosive, non-carcinogenic and hence improves public
                                                health.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="light-yellow-bg">
                                    <h4>Safe & Reliable </h4>
                                    <div class="blue-tick-list">
                                        <ul class="">
                                            <li>CNG is lighter than air and hence disperses quickly without any
                                                dangerous accumulation.</li>
                                            <li>CNG has a narrow flammability range making it much safer than other
                                                fuels.</li>
                                            <li>CNG has a higher ignition temperature than other fuels, reducing chances
                                                of accidental and spontaneous ignition.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Inner Page Content Section End -->