<div class="latestnews-section">
    <div class="customer-aw-faq">

        <div class="widget-title">
            <h3 class="title">Customer Awareness</h3>
          <!--  <h3 class="title">FAQs Pertaining to PNG to be 
         Taken</h3> -->
        </div>
        <div id="rs-faq" class="rs-faq mt-20">
            <div class="row y-middle">

                <div class="col-lg-12">
                    <div class="faq-content">
                        <div id="customer-aw" class="accordion mb-40">
                            <div *ngFor="let i of allFaq | slice: 0:4 ,index as j" class="card">
                                
                                    <div class="card-header">
                                        <a class="card-link collapsed" data-toggle="collapse" href="#collapse{{j}}"
                                            aria-expanded="false">{{i.question}}</a>
                                    </div>
                                    <div id="collapse{{j}}" class="collapse"
                                        data-bs-parent="#customer-aw">
                                        <div class="card-body">
                                            <span [innerHTML]="i.description"></span>
                                        </div>
                                    </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a (click)="this.FaqService.filterFaq('Customer Awareness')" [routerLink]="['/faq-list']"
            class="greenbtn2 d-block">View All <i class="demo-icon uil-arrow-right"></i></a>

    </div>
</div>