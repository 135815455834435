import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { ApplyforoutletService } from 'src/app/Service/applyforoutlet.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-applyoutletstatus',
  templateUrl: './applyoutletstatus.component.html',
  styleUrls: ['./applyoutletstatus.component.css']
})
export class ApplyoutletstatusComponent {
  searchRetailForm!: FormGroup;
  submitted = false;
   captcha: any;
  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';
  nextPageres:any;
  nextMatchres:any;

  constructor(private pageLoaderService: PageLoaderService,
   private router: Router, private FormBuilder: FormBuilder, 
   private service: ApplyforoutletService) { }


  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    
    this.captchaGenerate();

    this.searchRetailForm = this.FormBuilder.group({
      ApplicationNo: ['', [Validators.required]],
      Captcha: ['', [Validators.required]],

    });

   
  }

 ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }


  captchaGenerate() {
    let length = 5
    let result = '';
   // const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const characters = '0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }


   submit(){
    this.submitted = true;
    if (this.searchRetailForm.get('Captcha')?.value != this.captcha) {
      this.searchRetailForm.get('Captcha')?.setValue('')
    }
    if (this.searchRetailForm.invalid) {
      return;
    }
    let obj = {'ApplicationNo':this.searchRetailForm.get('ApplicationNo')?.value}

    this.service.get_outlet_details(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
          this.nextPageres = res;
          this.nextMatchres = this.nextPageres.details[0];
          console.log(this.nextMatchres.form_status)
          if(this.nextMatchres.form_status == "EOI Submitted"){
            this.router.navigate(['/cng/dealer-zone/apply-for-cng-retail-oulet'],
           {queryParams:{data:JSON.stringify(this.nextPageres)}})

          }else if(this.nextMatchres.form_status == "Draft"){
            this.router.navigate(['/cng/dealer-zone/apply-for-cng-retail-oulet'],
           {queryParams:{data:JSON.stringify(this.nextPageres)}})

          }else{

            this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'searchPro'
        this.alertMsg = "Your application already submitted";
        setTimeout(() => {
          this.clearAlert()

        }, 4000);

          }

          
          

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'searchPro'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      //console.log(err)
    })

  }

   clearAlert() {
    this.alertType = ''
  }


}
