import { Component } from '@angular/core';
import { ManageClientService } from 'src/app/Service/manage-client.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clientele',
  templateUrl: './clientele.component.html',
  styleUrls: ['./clientele.component.css']
})
export class ClienteleComponent {
  
  public base = environment.baseUrl
  allClient:any[]=[]

  constructor(private clientService : ManageClientService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    //this.UserService.checkLogin()
    this.getAllClient()
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getAllClient(){
    this.clientService.getAllClient().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      this.allClient=data
      //console.log(this.allClient)
    })
  }
}
