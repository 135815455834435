import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment'; 
import { Location } from '@angular/common';

@Component({
  selector: 'app-applyfornametstepthreeedit',
  templateUrl: './applyfornametstepthreeedit.component.html',
  styleUrls: ['./applyfornametstepthreeedit.component.css']
})
export class ApplyfornametstepthreeeditComponent {
 public base = environment.baseUrl
    allPost: any[] = [];
    //captcha: any;
    CustomerInfoForm!: FormGroup
    submitted = false
    finalSubmit : boolean = false
    loading = false;
    ApplicationNoID:any;

    alertType: any = ''
    alertMsg: any = ''
    alertColor: string = ''
    alertTextColor: string = ''
    alertBorder: string = ''
    alertTextAlign: string = 'center'

    nocDoc:boolean = false;
    NocNameFile_img:any;
    NocNameFile_img_name:any;

    previouspageData:any;

    display:string='none';
    Popup:any='';
    popuppData:any;
 constructor(private PostService: SliderService,private el: ElementRef,private datePipe: DatePipe, private FormBuilder: FormBuilder, private PngService: FormServiceService, public UserService: UserRegistrationService,
   public router: Router, private pageLoaderService: PageLoaderService,
   private activatedRoute: ActivatedRoute, private location: Location) {

          this.activatedRoute.queryParams.subscribe((data: any) => {
        this.ApplicationNoID = JSON.parse(data.data);
        // console.log('this.ApplicationNoID',this.ApplicationNoID);

          let obj = {'ApplicationNo': this.ApplicationNoID}
     this.PngService.getUserinfoforPrint(obj).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
         this.previouspageData = data.details[0]
        //  console.log(this.previouspageData)

          this.CustomerInfoForm.get('Noc')?.setValue(this.previouspageData.Noc)
   this.CustomerInfoForm.get('NocNameFile')?.setValue(this.previouspageData.NocNameFile)
   this.CustomerInfoForm.get('Declaration')?.setValue("true")
     this.CustomerInfoForm.get('TNC')?.setValue("true")

       this.getGoreenEidt(this.previouspageData.Noc)
  

  })
        
      
      });

    }


 ngOnInit(): void {
//  this.captchaGenerate()
  this.getAllHighlight()

     this.CustomerInfoForm = this.FormBuilder.group({
      Declaration: ['', [Validators.required]],
      Noc: ['', [Validators.required]],
      NocNameFile:[''],
      TNC: ['', [Validators.required]],
     // Captcha: ['', [Validators.required]],
    })

 

   this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

 }

 ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

//   goBack(){
// this.location.back();
//   }

 getAllHighlight(){
    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
     this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
     })
    }

 //  captchaGenerate() {
 //    let length = 7
 //    let result = '';
 //    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
 //    const charactersLength = characters.length;
 //    let counter = 0;
 //    while (counter < length) {
 //      result += characters.charAt(Math.floor(Math.random() * charactersLength));
 //      counter += 1;
 //    }
 //   this.captcha = result
 // }

  getGoreen(e:any) {
   // console.log(e.target.value)
   // this.type= e.target.value;
   this.CustomerInfoForm.get('Noc')?.setValue(e.target.value)

   if(e.target.value=="Yes"){

    this.nocDoc=true;
     this.CustomerInfoForm.get('NocNameFile')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('NocNameFile')?.updateValueAndValidity()

   }else if(e.target.value=="No"){
     this.nocDoc=false;
       this.CustomerInfoForm.get('NocNameFile')?.clearValidators()
      this.CustomerInfoForm.get('NocNameFile')?.updateValueAndValidity()

   }
}

 getGoreenEidt(val:any) {
  

   if(val=="Yes"){

    this.nocDoc=true;
     this.CustomerInfoForm.get('NocNameFile')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('NocNameFile')?.updateValueAndValidity()

   }else if(val=="No"){
     this.nocDoc=false;
       this.CustomerInfoForm.get('NocNameFile')?.clearValidators()
      this.CustomerInfoForm.get('NocNameFile')?.updateValueAndValidity()

   }
}

fileCheckForSlip(val: any,name:any) {
      if (name=='noc') {
         if(val.target.files[0].type == "application/pdf"){
       if(val.target.files[0].size/1024/1024>2){
          this.CustomerInfoForm.get('NocNameFile')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
         this.NocNameFile_img = val.target.files;
         this.NocNameFile_img_name = val.target.files[0];
      }
    }else{
      this.CustomerInfoForm.get('NocNameFile')?.setValue('');
        return alert('Please select only PDF less than 2 MB ')
     
    }
    }

  }

submitCustomerForm(){

   this.submitted = true
   this.loading = true;
    // if (this.CustomerInfoForm.get('Captcha')?.value != this.captcha) {
    //   this.CustomerInfoForm.get('Captcha')?.setValue('')
    // }
    if (this.CustomerInfoForm.invalid) {
       this.loading = false;
      return;
    }

     const form = new FormData()

   form.append('ApplicationNo',this.ApplicationNoID)

    if (this.NocNameFile_img_name != null) {
       let NocNameFileImg = this.NocNameFile_img[0].name.split('.').pop()
    let NocNameFileImg1  =this.NocNameFile_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData.Partner + new Date() +'.'+ NocNameFileImg;
      form.append("NocNameFile", this.NocNameFile_img[0], NocNameFileImg1)
      //form.append("NocNameFile", this.NocNameFile_img[0], this.NocNameFile_img[0].name)
    }


     form.append('Noc', this.CustomerInfoForm.get('Noc')?.value)
     this.PngService.updateCustomerFormThree(form).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      
     // console.log(response)
      this.alertType = '3rdSubmit'
      if (data.Response) {
        this.alertMsg = data.message+"| Application No." + data.details.ApplicationId
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
           this.loading = false;
          // this.openPopup();
           this.router.navigate(['/name-transfer-thank-you/'+data.details.ApplicationId])
        }, 1000);
      } else {
         this.loading = false;
        this.alertMsg = data.message
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
      }
    })

  
}

submitPop(){
  this.router.navigate(['/name-transfer-thank-you/'+ this.ApplicationNoID])
}


  openPopup(){
      let  obj = {'ApplicationNo': this.ApplicationNoID}
     this.PngService.getUserinfoforPrint(obj).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
         this.popuppData = data.details[0]
        //  console.log(this.popuppData)
          this.Popup='show';
          this.display='block';
   

  })
    
  }

  closeModel(){
    this.Popup=''
    this.display='none'
   
  }

  gotoEdit(){
    this.Popup=''
    this.display='none'

    this.router.navigate(['/residential-png/customer-zone/name-transfer/apply-for-name-step-one-edit/'+ this.ApplicationNoID])
  }


}

