<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>About <span>MGL</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a href="#">MGL Corporate</a></li>
                <li class="active" aria-current="page">About MGL</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <!-- Tips -->
                <app-tips></app-tips>
                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                    
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <p>Mahanagar Gas Limited, (MGL) one of the India's leading Natural Gas Distribution Company was incorporated on 8th May 1995. GAIL (India) Limited (Maharatna Company of Govt. of India) is the promoter of MGL.
                    </p>
                  
                    <div class="light-blue-bg mt-20">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <p class="text-center">MGL is certified as</p>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="certified mb-lg-0 mb-20">
                                            <img src="assets/images/mgl-certified-iso.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="certified mb-lg-0 mb-20">
                                            <img src="assets/images/mgl-certified-iso-9001-2015.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="certified mb-lg-0 mb-20">
                                            <img src="assets/images/mgl-certified-iso-45001-2018.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <p class="text-center">MGL Connects</p>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="certified mb-lg-0 mb-20 mgl-lakh">
                                            <img src="assets/images/mgl-connect-million-households.png" alt="">
                                            <span class="mgl-lakh-num">{{pngRate}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="certified mb-lg-0 mb-20 small-commerical">
                                            <img src="assets/images/mgl-connect-small-commercial-est.png" alt="">
                                            <span class="small-com-num">{{commercialestablishments}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <div class="certified mb-lg-0 mb-20 industrial-est">
                                            <img src="assets/images/mgl-connect-industrial-est.png" alt="">
                                            <span class="industrial-est-num">{{Industrialestablishments}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-30 text-center millions-vehicles-graphic">
                            <div class="lakh-vehicles">{{Vehicles}}</div>
                            <img src="assets/images/millions-vehicles-graphic.png" alt="">
                            <div class="lakh-rickshaw">Above {{Rickshaws}} Lakhs Rickshaw</div>
                            <div class="lakh-taxis">Above {{Taxis}} Lakhs Taxis</div>
                            <div class="lakh-cars">{{Cars}} Lakhs Cars</div>
                            <div class="best-tmt">{{NMMTBuses}} BEST / TMT / MSRTC / NMMT Buses</div>
                            <div class="lcv-tempos">Above {{PrivateBuses}} LCV / Tempos / Trucks / Private Buses</div>
                        </div>
                        <div class="mt-30 text-center mobile-millions-vehicles-graphic">
                            <div class="lakh-vehicles">{{Vehicles}}</div>
                            <img src="assets/images/millions-vehicles-graphic-mobile.png" alt="">
                            <div class="milions-vech-graph-text">
                            <div class="lakh-rickshaw">Above {{Rickshaws}} Lakhs Rickshaw</div>
                            <div class="lakh-taxis">Above {{Taxis}} Lakhs Taxis</div>
                            <div class="lakh-cars">{{Cars}} Lakhs Cars</div>
                            <div class="best-tmt">{{NMMTBuses}} BEST / TMT / MSRTC / NMMT Buses</div>
                            <div class="lcv-tempos">Above {{PrivateBuses}} LCV / Tempos / Trucks / Private Buses</div>
                            </div>
                        </div>
                        <!-- <p>Its adjoining areas and Raigad district. Over 2.01 million domestic customers are connected through our wide network of over 6342 kms. which comprises of carbon steel (CS) and polyethylene (PE) pipeline. Domestic PNG is used for various purposes like cooking and water heating.</p> -->
                    </div>

                    <div class="mt-30 mb-30">
                        <h6>These Are Running On CNG Supplied  Through MGL’s Wide Distribution Network :</h6>
                        <div class="row align-items-center">
                             <div class="col-lg-4 col-md-4 col-sm-12">
                               <div class="steel-pipeline">
                                  <div class="steel-pipeline-txt"><span>{{Steelpipeline}}</span> kms Of Steel Pipeline</div>
                               </div> 
                               <!-- <img src="assets/images/above400kms.png" class="w-100" alt=""> -->
                             </div> 
                             <div class="col-lg-4 col-md-4 col-sm-12">
                                <!-- <img src="assets/images/over4000.png" class="w-100" alt=""> -->
                                <div class="pe-pipeline">
                                  <div class="pe-pipeline-txt"><span>{{PEpipeline}}</span>  kms of PE Pipeline</div>
                               </div> 
                             </div> 
                             <div class="col-lg-4 col-md-4 col-sm-12">
                                <!-- <img src="assets/images/_over200_fb69ee6bf0.png" class="w-100" alt=""> -->
                                <div class="cng-filling-st">
                                  <div class="cng-filling-st-txt"><span>{{Fillingstation}}</span>  CNG Filling Stations Having <span>{{Dispensingpoints}}</span> Dispensing Points</div>
                               </div> 
                             </div>   
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <!-- <div class="blue-text mb-20">Safe and uninterrupted supply of gas to the customers is the priority of the Company. </div> -->
                                <p class="mt-20">Safe and uninterrupted supply of gas to the customers is the priority of the Company. To ensure safety, MGL has put in place robust systems and processes which match with the best in the world. MGL has adopted a Health, Safety, Security & Environment (HSS&E) Management System which provides a framework for continual improvement in its performance: </p>
                            </div>
                            
                        </div>
                    </div>

                    <div class="mt-30 mb-30">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="emergency-control">
                                    <h6>Emergency Control Room </h6>
                                    <div class="text-center"><img src="assets/images/emergency-control-room.png"> </div>
                                    <div class="emergency-control-content">
                                        <ul class="mb-30">
                                            <li><i class="demo-icon uil-check-circle"></i>Toll Free Number 1800 266 9944
                                            </li>
                                            <li><i class="demo-icon uil-check-circle"></i>Available 24 hrs a day 365
                                                days in a year.</li>
                                        </ul>
                                        <!-- <a href="#" class="greenbtn2">Download App <i
                                                class="demo-icon uil-mobile-android-alt"></i></a> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <div class="sahayogi-dial">
                                    <h6>MGL Sahayogi Dial-before-dig </h6>
                                    <div class="text-center"><img src="assets/images/sahayogi-dial-before-dig.jpg">
                                    </div>
                                    <div class="mt-30">
                                        <p>Information of third party digging activity can be given to MGL through any
                                            of the following channels:</p>
                                        <ol>
                                            <li>Calling on 18002669944 (Toll Free) or 022-68759400</li>
                                            <li> Sending the details of digging activity by email on
                                                emergencycontrolroom@mahanagargas.com </li>
                                            <li>Connecting through MGL's Mobile App - MGL Connect app is available on Android and iOS</li>
                                            <li>Logging on to MGL Website www.mahanagargas.com </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="mt-30 mb-30">
                        <h6>Besides this our initiative and continuous pipeline surveillance also contributes to the
                            safety of the system.</h6>
                        <p>MGL has all the resources and competencies required from concept to commissioning of gas
                            distribution networks available in-house. The entire business in MGL has been integrated
                            through ERP-SAP system. MGL has got a track record of almost 100% reliability in its gas
                            supply. During the devastating floods of Mumbai in July 2005, when most other utilities
                            failed, MGL's gas supply continued uninterrupted.</p>
                        <h6>Our customer-friendly initiatives include:</h6>
                        <div class="blue-tick-list">
                            <ul>
                                <li>Multiple bill payment options.</li>
                                <li>Multiple customer contact channels.</li>
                            </ul>
                        </div>
                    </div>
					<!-- <div class="mt-30 mb-30">
                        <h3>Vision</h3>
                        <h6>Our vision:</h6>
                        <p>To be a World Class, Consumer & Environment Friendly, Employer of Choice; committed to provide Safe, Efficient and Reliable energy; while creating Value for all our Stakeholders.</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->