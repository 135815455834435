import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OuletItem } from 'src/app/models/outlet';
import { AreaItem } from 'src/app/models/area';
import { CnglocatorService } from 'src/app/Service/cnglocator.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-cnglocators',
  templateUrl: './cnglocators.component.html',
  styleUrls: ['./cnglocators.component.css']
})
export class CnglocatorsComponent {
  outletdata: OuletItem[] = [];
   outletdata1: OuletItem[] = [];
   areas: any[] = [];
  areadata: AreaItem[] = [];
  locationdata: AreaItem[] = [];
  cityselected: any = ''
  searchForm!: FormGroup;
  safeSrc: SafeResourceUrl = "";
  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 1,
  };
  markers: any[] = [];
  name: any;
  contact: any;
beaches: any[] = [];
map:any;

 alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';
   lat:any;
  lng:any;
  myLocationFlag:any;
  constructor(private service: CnglocatorService, private FormBuilder: FormBuilder, private pageLoaderService: PageLoaderService, public sanitizer:DomSanitizer) {
this.beaches = [
  ["Bondi Beach", -33.890542, 151.274856, 4],
  ["Coogee Beach", -33.923036, 151.259052, 5],
  ["Cronulla Beach", -34.028249, 151.157507, 3],
  ["Manly Beach", -33.80010128657071, 151.28747820854187, 2],
  ["Maroubra Beach", -33.950198, 151.259302, 1],
];
   }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=51.678418,7.809007&key=AIzaSyAVn6ea2iJcMq9Wp0pKGlr3RpA8SVK1MCM&hl=es&z=14&output=embed");
  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.searchForm = this.FormBuilder.group({
      Location: ['', Validators.required],
      AreaName: ['', Validators.required],
    })

     this.getlocations();
      this.getcng();

    this.get();
   

    // this.center = {
    //   lat: 19.0760,
    //   lng: 72.8777,
    // };
  }

   clearAlert() {
    this.alertType = ''
  }

  getlocations() {
    this.service.getlocation().subscribe((res: any) => {
      this.locationdata = res
      // console.log(res)
      // console.log('this.locations', this.locationdata);
    })
  }

  getArea(locations: string) {
    this.service.getarea(locations).subscribe((res: any) => {
      this.areadata = res
      //console.log('this.areadata', this.areadata);

    })
  }

  get() {  
    if (navigator.geolocation) {  
        navigator.geolocation.getCurrentPosition((position:any) => {  
            if (position) {  
             // console.log('position+',position)
                 this.lat = position.coords.latitude;  
                 this.lng = position.coords.longitude;  
                // console.log('position+',this.lat,this.lng);
                 this.getlocations();
                 this.getcng();
                 this.myLocationFlag = true;
                //  this.center = {
                //   lat: this.lat,
                //   lng: this.lng,
                // };
                
                // this.getAddress = (this.lat, this.lng)  
                // console.log(position)  
                // this.apiloader.load().then(() => {  
                //     let geocoder = new google.maps.Geocoder;  
                //     let latlng = {  
                //         lat: this.lat,  
                //         lng: this.lng  
                //     };  
                //     geocoder.geocode({  
                //         'location': latlng  
                //     }, function(results) {  
                //         if (results[0]) {  
                //             this.currentLocation = results[0].formatted_address;  
                //             console.log(this.assgin);  
                //         } else {  
                //             console.log('Not found');  
                //         }  
                //     });  
                // });  
            } 			
        })  
    }  
}   
  



//  initMap(): void {
//   const map = new google.maps.Map(
//     document.getElementById("map") as HTMLElement,
//     {
//       zoom: 13,
//       // center: { lat: 19.0760, lng: 72.8777 },
//       center: { lat: this.lat, lng: this.lng },
//     }
//   );

//   this.setMarkers(map);
// }

 initMap(): void {
  if ( this.myLocationFlag==true) {
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 15,
       // center: { lat:19.2183, lng: 72.9781 },
         center: { lat: this.lat, lng: this.lng },
        // center: { lat: 19.0760, lng: 72.8777 },
        // lat: this.lat,
        // lng: this.lng,
      }
    );
    this.setMarkers(map);
    
  } else {

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 10,
       // center: { lat:19.2183, lng: 72.9781 },
       //  center: { lat: this.lat, lng: this.lng },
         center: { lat: 19.0760, lng: 72.8777 },
        // lat: this.lat,
        // lng: this.lng,
      }
    );
    this.setMarkers(map);
    
  }
 
 
}


initMap1(): void {
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 10,
       // center: { lat:19.2183, lng: 72.9781 },
       //  center: { lat: this.lat, lng: this.lng },
         center: { lat: 19.0760, lng: 72.8777 },
        // lat: this.lat,
        // lng: this.lng,
      }
    );
    this.setMarkers(map);
    
  
 
 
}

setMarkers(map: google.maps.Map) {
 const image = {
    url: "../../assets/images/pin-logo2.png",
    size: new google.maps.Size(32, 32),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(0, 32),
  };
  
  const shape = {
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: "poly",
  };
 
 var markers:any[]=[];

  for (let i = 0; i < this.areas.length; i++) {
    const beach = this.areas[i];
    // console.log('beach',beach)

    var marker = new google.maps.Marker({
      position: { lat: beach[1], lng: beach[2] },
      map,
      icon: image,
      shape: shape,
     title: beach[0],
      zIndex: i+1,
    });

markers.push(marker);
    // var popup = new google.maps.InfoWindow({

    // });
    //    popup.open(map, marker);
    //     popup.setContent(beach[0]+"<br>"+"<a href='https://maps.google.com/?q="+beach[1]+","+beach[2]+"'>Get Direction</a>");
    //     google.maps.event.addListener(popup, "closeclick", function() {
    //    // map.panTo(center);
    //     // currentPopup = null;
    //   });

    
  }

var mylat = this.lat;
 var mylong = this.lng;

  for (let i = 0; i < markers.length; i++) {
    //console.log(markers);

        var popup = new google.maps.InfoWindow({});
        // popup.open(map, markers[i]);
       
        google.maps.event.addListener(popup, "closeclick", function() {
          //map.panTo(center);
          //currentPopup = null;
        });
        markers[i].addListener('click', function() {
           popup.setContent( '<div id="content">'+"<b>"+markers[i].title+"</b>"+"<br>"+"<a href='https://www.google.com/maps/dir/?api=1&origin="+ mylat+","+mylong+"&destination="+markers[i].getPosition().lat()+","+markers[i].getPosition().lng()+"' target='_blank'>Get Direction</a>" +
    "</div>");
          popup.open(map, markers[i]);
          // console.log(this.outletdata[i]);
        });
      }
}

// popup.setContent( '<div id="content">'+"<b>"+markers[i].title+"</b>"+"<br>"+"<a href='https://www.google.com/maps?saddr=My+Location&daddr="+markers[i].getPosition().lat()+","+markers[i].getPosition().lng()+"' target='_blank'>Get Direction</a>" +
//     "</div>");



 getcng() {
    var Location = this.searchForm.controls['Location'].value
    var AreaName = this.searchForm.controls['AreaName'].value
    this.outletdata = []
    this.service.getoutlet(Location, AreaName).subscribe(response => {
      var data = JSON.parse(JSON.stringify(response));
      let filtered = data.details.filter((x: any) => {
        return x.IsDeleted !== 'true'
      })
      this.outletdata = filtered;
   for (let i = 0; i < this.outletdata.length; i++) {
    //Number(beach.Latitude), lng: Number(beach.Longitude)
    if(this.outletdata[i].Latitude !=''){
      var obj = [this.outletdata[i].OutletName,Number(this.outletdata[i].Latitude),Number(this.outletdata[i].Longitude)]
         this.areas.push(obj)
    }
    
    }

    // console.log('areas',this.areas)
       // ["Bondi Beach", -33.890542, 151.274856, 4],

       this.initMap();
   
      //console.log(filtered)
    })
  }

  getcng1() {
    //  if (this.searchForm.invalid) {
    //   return;
    // }
    var Location = this.searchForm.controls['Location'].value
    var AreaName = this.searchForm.controls['AreaName'].value
    this.outletdata = []
    this.service.getoutlet(Location, AreaName).subscribe(response => {
      var data = JSON.parse(JSON.stringify(response));
       if(data.response==true){
         let filtered = data.details.filter((x: any) => {
        return x.IsDeleted !== 'true'
      })
      this.outletdata = filtered;
       this.outletdata1 = filtered;
      this.areas = [];
        for (let i = 0; i < this.outletdata.length; i++) {
    //Number(beach.Latitude), lng: Number(beach.Longitude)
    if(this.outletdata[i].Latitude !=''){
      var obj = [this.outletdata[i].OutletName,Number(this.outletdata[i].Latitude),Number(this.outletdata[i].Longitude)]
         this.areas.push(obj)
    }
    
    }

        this.initMap1();
      }else{
        
          this.outletdata1 = [];
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'cnlocater'
        this.alertMsg = 'No CNG Locater Found In Your Select City and Area';
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 2000);

      }
      
   
      // console.log(filtered)
    })
  }

  // getcng() {
  //   var Location = this.searchForm.controls['Location'].value
  //   var AreaName = this.searchForm.controls['AreaName'].value
  //   this.outletdata = []
  //   this.service.getoutlet(Location, AreaName).subscribe(response => {
  //     var data = JSON.parse(JSON.stringify(response));
  //     let filtered = data.details.filter((x: any) => {
  //       return x.IsDeleted !== 'true'
  //     })
  //     this.outletdata = filtered;
	 //  this.markers = [];
  //     for (let i=0; i<this.outletdata.length; i++){
  //       this.markers.push({
  //         position: {
  //           lat: Number(this.outletdata[i].Latitude),
  //           lng: Number(this.outletdata[i].Longitude),
  //         },
          
  //         title: this.outletdata[i].OutletName,
  //         options: { animation: google.maps.Animation.DROP },
  //       });
  //     }
  //     console.log(filtered)
  //   })
  // }

  // location(x: any) {
  //   this.latitude = x.coords.lat;
  //   this.longitude = x.coords.lng;
  // }

  Change(event: any) {
    this.cityselected = event.target.options[event.target.options.selectedIndex].text;
    this.getArea(this.cityselected)
  }

  showMap(item:any){
   // console.log('map lat',item)
    var latitude1 = Number(item.Latitude);
    var longitude1 = Number(item.Longitude)
     window.open("https://www.google.com/maps/dir/?api=1&origin="+ this.lat+","+this.lng+"&destination="+latitude1+","+longitude1+"",'_blank');
        //window.open("https://www.google.com/maps?saddr=My+Location&daddr="+latitude1+","+longitude1+"",'_blank');
    // this.name = name;
    // this.contact = contact;
    // this.center = {
    //   lat: Number(lat),
    //     lng: Number(lng),
    // };
    // this.markers = [];
    // this.markers.push({
    //   position: {
    //     lat: Number(lat),
    //     lng: Number(lng),
    //   },
      
    //   title: name,
    //   options: { animation: google.maps.Animation.DROP },
    // });
  }
}
