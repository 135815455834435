<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Contact Us</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a href="#">Industrial & Commercial</a></li>
                <li class="active" aria-current="page">Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-industrial-service-section></app-industrial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-customer-awareness></app-customer-awareness>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="customer-login-page">

                                    <form [formGroup]="UserForm" role="form">
                                        <h6>Contact Form</h6>
                                         <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Name"
                                                    placeholder="Name"
                                                    [ngClass]="{ 'is-invalid': Submitted && UserForm.controls['Name'].errors }" id="fullname" />
                                                <div *ngIf="Submitted && UserForm.controls['Name'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="Submitted && UserForm.controls['Name'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="fullname">Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" formControlName="Email"
                                                    placeholder="Name"
                                                    [ngClass]="{ 'is-invalid': Submitted && UserForm.controls['Email'].errors }" id="Email" />
                                                <div *ngIf="Submitted && UserForm.controls['Email'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Email'].errors.required">Required Field</div>
                                                        <div *ngIf="UserForm.controls['Email'].errors.pattern">Email must be a valid email address.</div>
                                                </div>
                                                <label for="Email">Email<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
    
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="form-floating">
                                                <input type="number" class="form-control" formControlName="Contact"
                                                    placeholder="Contact" onKeyPress="if(this.value.length==10) return false;"
                                                    [ngClass]="{ 'is-invalid': Submitted && UserForm.controls['Contact'].errors }" id="Contact" />
                                                <div *ngIf="Submitted && UserForm.controls['Contact'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Contact'].errors.required">Required Field</div>
                                                    <div *ngIf="UserForm.controls['Contact'].errors.pattern">Contact Number should be in 10 digits.</div>
                                                </div>
                                                <label for="Contact">Contact<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Subject"
                                                    placeholder="Subject"
                                                    [ngClass]="{ 'is-invalid': Submitted && UserForm.controls['Subject'].errors }" id="Subject" />
                                                <div *ngIf="Submitted && UserForm.controls['Subject'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="Submitted && UserForm.controls['Subject'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Subject<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="form-floating">
                                                <textarea  class="form-control" formControlName="Message"
                                                    placeholder="Message"
                                                    [ngClass]="{ 'is-invalid': Submitted && UserForm.controls['Message'].errors }" id="Message" ></textarea>
                                                <div *ngIf="Submitted && UserForm.controls['Message'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="Submitted && UserForm.controls['Message'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Message<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                         <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                                <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                                <div class="mt-20">
                                                    <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                   <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                    aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-7 col-sm-12">
                                                <div class="form-floating mb-3">
                                                    <input [ngClass]="{'is-invalid':Submitted && UserForm.controls['Captcha'].errors}" type="text" class="form-control" id="Captcha" placeholder="Captcha" formControlName="Captcha">
                                                    <label for="Captcha">Captcha</label>
                                                    <div *ngIf="Submitted && UserForm.controls['Captcha'].errors"
                                                          class="invalid-feedback">
                                                          <div style="margin-left: 27px;"
                                                              *ngIf="UserForm.controls['Captcha'].errors">Captcha required</div>
                                                      </div>
                                                  </div>
                                            </div>
                                        </div>
                                        </div>
                                        
                                        
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                <button (click)="submit()" class="greenbtn2">Submit <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                <div class="text-right md-text-left">
                                                    <button (click)="UserForm.reset()" (click)="Submitted=false"class="bluebtn2">Reset <i
                                                            class="demo-icon uil-redo"></i></button>
                                                </div>
                                            </div>
                                        

                                        <div *ngIf="alertType == 'submit'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>