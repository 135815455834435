import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { VehiclepromotionService } from 'src/app/Service/vehiclepromotion.service';
import { ApplyOutletItem } from 'src/app/models/applyforoutket';
import { DistrictItem } from 'src/app/models/applyforoutket';
import { PlotItem } from 'src/app/models/applyforoutket';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
//import { PSM, createWorker } from 'tesseract.js';
import { log } from 'console';

@Component({
  selector: 'app-vehiclepromotion',
  templateUrl: './vehiclepromotion.component.html',
  styleUrls: ['./vehiclepromotion.component.css']
})
export class VehiclepromotionComponent {

  promotionForm!: FormGroup
  submitted = false

  gift_card1: any;
  gift_card2: any;
  gift_card3: any;
  gift_card4: any;
  gift_card5: any;
  challan: any;
   pancardimg: any;
  LpgSlip: any
  captcha: any;
  myFileArray: any[] = [];
  addProofBinary: any;
  otherdocBinary: any;
  gift_no2Flag = false;



  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  gift_card1Flag = false;
  gift_card2Flag = false;
  gift_card3Flag = false;
  gift_card4Flag = false;
  gift_card5Flag = false;

  scvImgFlag = false;
  lcvImgFlag = false;
  hcvImgFlag = false;

  otherDocimgFlag = false;
  otherDocFlag = false;

  gift_card2re = false;

  display:string='none';
  Popup:any='';
  popuppData:any;

  gift_card11:any;
  gift_card22:any;
  gift_card33:any;
  gift_card44:any;
  gift_card55:any;

  gift_card111:any;
  gift_card222:any;
  gift_card333:any;
  gift_card444:any;
  gift_card555:any;

  gift_no11:any;
  gift_no22:any;
  gift_no33:any;
  gift_no44:any;
  gift_no55:any;

  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: VehiclepromotionService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    // this.getdist()
    // this.getPlot()
    this.captchaGenerate()
    this.promotionForm = this.FormBuilder.group({
      Name: ['', [Validators.required]],
      // caNumber: ['', [Validators.required, Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
      //caNumber: ['', Validators.compose([Validators.maxLength(12), Validators.minLength(12), Validators.pattern("^[0-9]*$"),Validators.required])],
      // ContactNumber: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      //Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      vehicleregDate: ['', [Validators.required]],
     // vehicleNo: ['', Validators.compose([Validators.pattern("^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$"), Validators.required])],
       vehicleNo: ['', Validators.compose([Validators.pattern("^[A-Za-z]{2}[0-9]{1,2}[A-Za-z]{1,2}[0-9]{4}$"), Validators.required])],
      vehicleType: ['', [Validators.required]],
      // vehicleModel: [''],
      dlearName: ['', [Validators.required]],
      otherDoc: [''],
      otherDocimg: [''],
      // addProof: ['', [Validators.required]],
      // barcodeNumber: ['', [Validators.required, Validators.maxLength(16), Validators.pattern("^[0-9]*$")]],
      // barcodeNumber: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16),Validators.pattern("^[0-9]*$"), Validators.required])],
      gift_card1: ['', Validators.required],
      gift_card2: [''],
      gift_card3: [''],
      gift_card4: [''],
      gift_card5: [''],
      // gift_no1: ['', Validators.required],
      // gift_no2:  [''],
      // gift_no3:  [''],
      // gift_no4:  [''],
      // gift_no5:  [''],
      gift_no1: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$"), Validators.required])],
      gift_no2: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")])],
      gift_no3: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")])],
      gift_no4: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")])],
      gift_no5: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")])],
      delivery_challan: ['', Validators.required],
      pan_card_img: ['', Validators.required],
      Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
      dob: ['', Validators.required],
      Captcha: ['', [Validators.required]],
      TNC: ['', [Validators.required]],

    });

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }

  fileCheckForSlip(val: any) {
    //console.log(val.files)
    if ((val.files[0].type == "application/pdf" || val.files[0].type == "application/msword" || val.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && val.files[0].size < (5 * 1024 * 1024)) {

    } else {
      this.LpgSlip = null
      return alert('Please Select .pdf .doc or .docx')
    }
  }

  filechange1(element: any) {
    this.addProofBinary = element.target.files
   // console.log(this.addProofBinary);
  }

  filechange2(element: any) {
    this.otherdocBinary = element.target.files
    //console.log(this.otherdocBinary);
  }

  filechange(element: any, check: any) {
    //console.log(check);
    if (check == 'gift_card1') {
      this.gift_card1 = element.target.files
      //console.log(this.gift_card1);
      this.gift_card11 = element.target.files[0];

      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }

    } else if (check == 'gift_card2') {
      this.gift_card2 = element.target.files
      //console.log(this.gift_card2);
      this.gift_card22 = element.target.files[0];
      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
    } else if (check == 'gift_card3') {
      this.gift_card3 = element.target.files
      //console.log(this.gift_card3);
      this.gift_card33 = element.target.files[0];

      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
    } else if (check == 'gift_card4') {
      this.gift_card4 = element.target.files
      //console.log(this.gift_card4);
      this.gift_card44 = element.target.files[0];

      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }

    } else if (check == 'gift_card5') {
      this.gift_card5 = element.target.files
      //console.log(this.gift_card5);
      this.gift_card55 = element.target.files[0];
      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
    } else if (check == 'challan') {
      this.challan = element.target.files
      //console.log(this.challan);
      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
    }else if (check == 'pancard'){
       this.pancardimg = element.target.files
      //console.log(this.pancardimg);
      
    }




  }



  //async doOCR(item: any) {
  //  console.log(item);


  //  const worker = createWorker({
  //    logger: m => console.log(m),
  //  });

  //  await (await worker).load();
  //  await (await worker).loadLanguage('eng');
  //  await (await worker).initialize('eng');
  //  await (await worker).setParameters({
  //    //tessedit_char_whitelist: '1234567890',
  //    tessedit_pageseg_mode: PSM.AUTO,

  //  });
  //  const { data: { text } } = await (await worker).recognize(item);

  //  console.log(text);
  //  await (await worker).terminate();
  //}

  clearAlert() {
    this.alertType = ''
  }



  getvehicleType() {


    if (this.promotionForm.get('vehicleType')?.value == 'SCV(< 3.5 Ton)') {
      this.gift_card1Flag = true;
      this.gift_card2Flag = false;
      this.gift_card3Flag = false;
      this.gift_card4Flag = false;
      this.gift_card5Flag = false;
      this.scvImgFlag = true;
      this.lcvImgFlag = false;
      this.hcvImgFlag = false;
      //console.log('check');

      this.promotionForm.get('gift_card2')?.clearValidators();
      this.promotionForm.get('gift_card2')?.updateValueAndValidity();
      this.promotionForm.get('gift_card3')?.clearValidators()
      this.promotionForm.get('gift_card3')?.updateValueAndValidity()
      this.promotionForm.get('gift_card4')?.clearValidators();
      this.promotionForm.get('gift_card4')?.updateValueAndValidity();
      this.promotionForm.get('gift_card5')?.clearValidators();
      this.promotionForm.get('gift_card5')?.updateValueAndValidity();

      this.promotionForm.get('gift_no2')?.clearValidators();
      this.promotionForm.get('gift_no2')?.updateValueAndValidity();
      this.promotionForm.get('gift_no3')?.clearValidators()
      this.promotionForm.get('gift_no3')?.updateValueAndValidity()
      this.promotionForm.get('gift_no4')?.clearValidators();
      this.promotionForm.get('gift_no4')?.updateValueAndValidity();
      this.promotionForm.get('gift_no5')?.clearValidators();
      this.promotionForm.get('gift_no5')?.updateValueAndValidity();

      this.promotionForm.get('gift_no2')?.setValue('');
      this.promotionForm.get('gift_no3')?.setValue('');
      this.promotionForm.get('gift_no4')?.setValue('');
      this.promotionForm.get('gift_no5')?.setValue('');

      this.gift_card22 ='';
      this.gift_card2 =null;
      this.gift_card33 ='';
      this.gift_card3 =null;
      this.gift_card44 ='';
      this.gift_card4 =null;
      this.gift_card55 ='';
      this.gift_card5 =null;


    } else if (this.promotionForm.get('vehicleType')?.value == 'LCV(3.5 -10 Ton)') {
      this.gift_card1Flag = true;
      this.gift_card2Flag = true;
      this.gift_card3Flag = false;
      this.gift_card4Flag = false;
      this.gift_card5Flag = false;

      this.scvImgFlag = false;
      this.lcvImgFlag = true;
      this.hcvImgFlag = false;
      //console.log('check1');
      this.promotionForm.get('gift_card2')?.setValidators(Validators.required);
      this.promotionForm.get('gift_card2')?.updateValueAndValidity();
      this.promotionForm.get('gift_card3')?.clearValidators()
      this.promotionForm.get('gift_card3')?.updateValueAndValidity()
      this.promotionForm.get('gift_card4')?.clearValidators()
      this.promotionForm.get('gift_card4')?.updateValueAndValidity()
      this.promotionForm.get('gift_card5')?.clearValidators()
      this.promotionForm.get('gift_card5')?.updateValueAndValidity()

      this.promotionForm.get('gift_no2')?.setValidators([Validators.required, Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")]);
      this.promotionForm.get('gift_no2')?.updateValueAndValidity();
      this.promotionForm.get('gift_no3')?.clearValidators()
      this.promotionForm.get('gift_no3')?.updateValueAndValidity()
      this.promotionForm.get('gift_no4')?.clearValidators()
      this.promotionForm.get('gift_no4')?.updateValueAndValidity()
      this.promotionForm.get('gift_no5')?.clearValidators()
      this.promotionForm.get('gift_no5')?.updateValueAndValidity()

      // this.promotionForm.get('gift_no2')?.setValue('');
      this.promotionForm.get('gift_no3')?.setValue('');
      this.promotionForm.get('gift_no4')?.setValue('');
      this.promotionForm.get('gift_no5')?.setValue('');

      // this.gift_card22 ='';
      // this.gift_card2 ='';
      this.gift_card33 ='';
      this.gift_card3 =null;
      this.gift_card44 ='';
      this.gift_card4 =null;
      this.gift_card55 ='';
      this.gift_card5 =null;




    } else if (this.promotionForm.get('vehicleType')?.value == 'HCV(>10 Ton)') {
      this.gift_card1Flag = true;
      this.gift_card2Flag = true;
      this.gift_card3Flag = true;
      this.gift_card4Flag = true;
      this.gift_card5Flag = true;

      this.scvImgFlag = false;
      this.lcvImgFlag = false;
      this.hcvImgFlag = true;

      this.promotionForm.get('gift_card2')?.setValidators(Validators.required);
      this.promotionForm.get('gift_card2')?.updateValueAndValidity();
      this.promotionForm.get('gift_card3')?.setValidators(Validators.required);
      this.promotionForm.get('gift_card3')?.updateValueAndValidity()
      this.promotionForm.get('gift_card4')?.setValidators(Validators.required);
      this.promotionForm.get('gift_card4')?.updateValueAndValidity()
      this.promotionForm.get('gift_card5')?.setValidators(Validators.required);
      this.promotionForm.get('gift_card5')?.updateValueAndValidity()

      this.promotionForm.get('gift_no2')?.setValidators([Validators.required, Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")]);
      this.promotionForm.get('gift_no2')?.updateValueAndValidity();
      this.promotionForm.get('gift_no3')?.setValidators([Validators.required, Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")]);
      this.promotionForm.get('gift_no3')?.updateValueAndValidity()
      this.promotionForm.get('gift_no4')?.setValidators([Validators.required, Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")]);
      this.promotionForm.get('gift_no4')?.updateValueAndValidity()
      this.promotionForm.get('gift_no5')?.setValidators([Validators.required, Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$")]);
      this.promotionForm.get('gift_no5')?.updateValueAndValidity()



    } else {
      this.gift_card1Flag = false;
      this.gift_card2Flag = false;
      this.gift_card3Flag = false;
      this.gift_card4Flag = false;
      this.gift_card5Flag = false;

      this.scvImgFlag = false;
      this.lcvImgFlag = false;
      this.hcvImgFlag = false;


    }

  }

  openPopup(val:any){
    //console.log(val)
    this.Popup='show';
    this.display='block';

    this.gift_no11 = this.promotionForm.get('gift_no1')?.value;
    this.gift_no22 = this.promotionForm.get('gift_no2')?.value;
    this.gift_no33 = this.promotionForm.get('gift_no3')?.value;
    this.gift_no44 = this.promotionForm.get('gift_no4')?.value;
    this.gift_no55 = this.promotionForm.get('gift_no5')?.value;
    //console.log('this.gift_no22',this.gift_no22);
    
    var reader11 = new FileReader();
		  reader11.readAsDataURL(this.gift_card11);
		  reader11.onload = (_event) => {
       this.gift_card111 = reader11.result; 
		}
    
    var reader22 = new FileReader();
    reader22.readAsDataURL(this.gift_card22);
    reader22.onload = (_event) => {
     this.gift_card222 = reader22.result; 
  }

  var reader33 = new FileReader();
  reader33.readAsDataURL(this.gift_card33);
  reader33.onload = (_event) => {
   this.gift_card333 = reader33.result; 
}

var reader44 = new FileReader();
  reader44.readAsDataURL(this.gift_card44);
  reader44.onload = (_event) => {
   this.gift_card444 = reader44.result; 
}

var reader55 = new FileReader();
  reader55.readAsDataURL(this.gift_card55);
  reader55.onload = (_event) => {
   this.gift_card555 = reader55.result; 
}
   
    

  }

  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }
  

  onSubmit() {
    //console.log(this.promotionForm.value.vehicleNo)
     this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

    if (this.promotionForm.value.otherDoc != '') {
      this.otherDocFlag = false;
      if (this.promotionForm.value.otherDocimg == '') {
        this.otherDocimgFlag = true;
      } else {
        //console.log('else1');
        this.otherDocimgFlag = false;
        // this.submitForm();

        this.openPopup(1);

      }
    } else if (this.promotionForm.value.otherDocimg != '') {
      this.otherDocimgFlag = false;
      if (this.promotionForm.value.otherDoc == '') {
        this.otherDocFlag = true;
      } else {
        //console.log('else2');
        this.otherDocFlag = false;
        // this.submitForm();
        this.openPopup(1);
      }
    } else {
      this.otherDocFlag = false;
      this.otherDocFlag = false;
      //console.log('else');

      // this.submitForm();
      this.openPopup(1);

    }
  }

  submitPop(){
    this.closeModel();
    this.submitForm();
  }


  submitForm() {

    const form = new FormData()
    form.append("Name", this.promotionForm.get('Name')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("vehicleRegDate", this.promotionForm.get('vehicleregDate')?.value)
    form.append("vehicleNo", this.promotionForm.get('vehicleNo')?.value)
    form.append("vehicleType", this.promotionForm.get('vehicleType')?.value)
    form.append("delearName", this.promotionForm.get('dlearName')?.value)
    form.append("dob", this.promotionForm.get('dob')?.value)
    form.append("otherDoc", this.promotionForm.get('otherDoc')?.value)
    form.append("gift_no1", this.promotionForm.get('gift_no1')?.value)
    form.append("gift_no2", this.promotionForm.get('gift_no2')?.value)
    form.append("gift_no3", this.promotionForm.get('gift_no3')?.value)
    form.append("gift_no4", this.promotionForm.get('gift_no4')?.value)
    form.append("gift_no5", this.promotionForm.get('gift_no5')?.value)
    form.append("Pan", this.promotionForm.get('Pan')?.value)
   
     if (this.otherdocBinary != null) {
      let otherdocBinaryImg = this.otherdocBinary[0].name.split('.').pop()
    let otherdocBinaryImg1  =this.otherdocBinary[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ otherdocBinaryImg;
     form.append("files", this.otherdocBinary[0], otherdocBinaryImg1)
      // form.append("files", this.otherdocBinary[0], this.otherdocBinary[0].name)
    }
     let gift_card1Img = this.gift_card1[0].name.split('.').pop()
    let gift_card1Img1  =this.gift_card1[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ gift_card1Img;
      form.append("files", this.gift_card1[0], gift_card1Img1)
    // form.append("files", this.gift_card1[0], this.gift_card1[0].name)
    if (this.gift_card2 != null ) {
      let gift_card2Img = this.gift_card2[0].name.split('.').pop()
    let gift_card2Img1  =this.gift_card2[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ gift_card2Img;
 form.append("files", this.gift_card2[0], gift_card2Img1)
      // form.append("files", this.gift_card2[0], this.gift_card2[0].name)
    }
    if (this.gift_card3 != null ) {
       let gift_card3Img = this.gift_card3[0].name.split('.').pop()
    let gift_card3Img1  =this.gift_card3[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ gift_card3Img;
      
      form.append("files", this.gift_card3[0], gift_card3Img1)
      // form.append("files", this.gift_card3[0], this.gift_card3[0].name)
    }
    if (this.gift_card4 != null ) {
       let gift_card4Img = this.gift_card4[0].name.split('.').pop()
    let gift_card4Img1  =this.gift_card4[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ gift_card4Img;
     form.append("files", this.gift_card4[0], gift_card4Img1)
      // form.append("files", this.gift_card4[0], this.gift_card4[0].name)
    }
    if (this.gift_card5 != null) {
       let gift_card5Img = this.gift_card5[0].name.split('.').pop()
    let gift_card5Img1  =this.gift_card5[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ gift_card5Img;
     form.append("files", this.gift_card5[0], gift_card5Img1)
      // form.append("files", this.gift_card5[0], this.gift_card5[0].name)
    }

      let challanImg = this.challan[0].name.split('.').pop()
    let challanImg1  =this.challan[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ challanImg;
   form.append("files", this.challan[0], challanImg1)
    let pancardimgImg = this.pancardimg[0].name.split('.').pop()
    let pancardimgImg1  =this.pancardimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ pancardimgImg;
    form.append("files", this.pancardimg[0], pancardimgImg1)
    // form.append("files", this.challan[0], this.challan[0].name)
    // form.append("files", this.pancardimg[0], this.pancardimg[0].name)

    


    this.service.insert_Vehicle_pro_data(form).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        this.promotionForm.reset();
        this.scvImgFlag = false;
        this.lcvImgFlag = false;
        this.hcvImgFlag = false;
        setTimeout(() => {
          this.clearAlert()

        }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
     // console.log(err)
    })

  }



  reset() {
    this.promotionForm.reset()
    this.submitted = false;
    this.scvImgFlag = false;
      this.lcvImgFlag = false;
      this.hcvImgFlag = false;

  }
}