import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ntzc',
  templateUrl: './ntzc.component.html',
  styleUrls: ['./ntzc.component.css']
})
export class NtzcComponent {
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      window.location.href = 'https://mgl.ebillz.in/Notice/ZeroConsumptionNotice.php?billKey=' + id;
      console.log('Url Id: ', id);
    })
  }
}
