import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-nach-cancellation',
  templateUrl: './nach-cancellation.component.html',
  styleUrls: ['./nach-cancellation.component.css']
})
export class NachCancellationComponent {


  nextStepStatus:boolean = false

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'


  ShowOtp: boolean = false

  stopTimer:any
  ShowGo:boolean = false
  ShowUserForm:boolean = false
  UserForm !: FormGroup
  data: any
  submitted: boolean = false
  //Otp:any
  Popup:any=''
  display:string='none'
  checkOtpForm!: FormGroup
  OtpSubmitted=false
  submitStatus:boolean=false
  Otp: any
  Minute: any = 1
  Sec: any = 10
  OtpButtonActive: boolean = false
   loading = false;
  constructor(private FormBuilder: FormBuilder, public FaqService: FaqServiceService, private service: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.service.checkLogin()

    this.UserForm = this.FormBuilder.group({
      Name: [''],
      Email: [''],
      Mobile: [''],
      
    });

    this.checkOtpForm = this.FormBuilder.group({
      Otp: ['', Validators.required]
    })


    let data = sessionStorage.getItem('UserCredential')
    if (data)
      this.data = JSON.parse(data)

  //  console.log(this.data)

      this.UserForm.get('Name')?.setValue(this.data.FirstName)
      this.UserForm.get('Email')?.setValue(this.data.Email)
      this.UserForm.get('Mobile')?.setValue(this.data.Mobile)

  }


  openPopup(){
    
    this.Popup='show'
    this.display='block'
    
    

  }

  closeModel(){
    this.Popup=''
    this.display='none'
    this.stopTimer=true
    
  }


  // OtpGenerate() {
  //   let length = 6
  //   let result = '';
  //   const characters = '0123456789'
  //   const charactersLength = characters.length;
  //   let counter = 0;
  //   while (counter < length) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     counter += 1;
  //   }
    
  //   this.Otp=result
    
  //   this.service.sendOtp(this.Otp,this.data.Mobile)
  //   this.otpTimer()
  //   this.OtpButtonActive = true
  //   this.submitStatus=false


  // }

   OtpGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
   // console.log(result)
    this.Otp = result
    this.service.sendOtp(this.Otp, this.data.Mobile)
    this.otpTimer()
    this.OtpButtonActive = true
    this.submitStatus = false
  }

  clearStatus(){
    setTimeout(()=>{
      this.alertMsg=''
    },3000)
  }

  clearAlert() {
    this.alertType = ''
  }


  verifyOtp() {

    this.OtpSubmitted = true

    // console.log(this.Otp)
    // console.log(this.checkOtpForm.get('Otp')?.value)

    if (this.checkOtpForm.invalid) {
      return
    }
    this.alertType = 'secSubmit'
    if (this.Otp != this.checkOtpForm.get('Otp')?.value) {

      this.alertMsg = "Otp Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
    } else {
      this.alertMsg = "Otp Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
      this.ShowGo = true
      // this.nextStepStatus=true
      setTimeout(() => {
        this.clearAlert()
        this.ShowOtp = false
        this.closeModel()
        this.ShowUserForm = true
      }, 1000);
    }
  }



  otpTimer() {

    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {

      sec--;

      

      if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0
        clearInterval(count)
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);

    var test = () => {
      if(this.stopTimer){
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp=''
        this.submitStatus=true
        clearInterval(count)
      }
      this.Minute = minute
      this.Sec = sec
    }
  }

  submit() {
    this.submitted = true
     this.loading = true;
  //  console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
       this.loading = false;
      return;
    }


     // this.OtpGenerate()
     // this.openPopup()

     let obj={"CANo":this.data.CaNo,
     "CustomerName":this.UserForm.get('Name')?.value,
     "Email":this.UserForm.get('Email')?.value,
     "Contact":this.UserForm.get('Mobile')?.value}

      this.service.insertNachcancel(obj).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.response){
       this.loading = false;
        this.alertType='nach'
        this.alertMsg = "Your nach cancellation request submitted"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {
          this.alertType=''
        }, 2000);


      }else{
        this.loading = false;
        this.alertType='nach'
        this.alertMsg = "You not eligible for nach cancellation"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.alertType=''
        }, 2000);
      }

        
    })



    


  }

}
