<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Customer <span>Dashboard</span></h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>Residential PNG</a></li>
        <li class=""><a >Customer Zone</a></li>
        <li class=""><a >Customer Login</a></li>
        <li class="active" aria-current="page">Customer Dashboard</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <div class="latestnews-section">
          <app-dashboard-info-card></app-dashboard-info-card>
        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="mb-30 text-shadow">
          <h6>Billing Cycle</h6>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="billing-cycle-section">
                <i class="demo-icon uil-calender"></i>
                <div class="billing-cy-content">
                  <b>Normal</b>
                  {{access}}
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="billing-cycle-section">
                <i class="demo-icon uil-calender"></i>
                <div class="billing-cy-content">
                  <b>Assessed</b>
                  {{normal}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-30">
            <div class="light-blue-bg">
              <div class="billing-section">
                <h6>You can access the following for Billing</h6>
                <ul>
                  <li><a [routerLink]="['/payment-link']">
                      <div class="billing-icon"><img src="assets/images/billing-pay-bill.png"></div>
                      <p>Pay your Bill</p>
                    </a></li>
                  <li><a [routerLink]="['/residential-png/customer-zone/billing-cycle']">
                      <div class="billing-icon"><img src="assets/images/billing-billing-cycle.png"></div>
                      <p>Billing Cycle</p>
                    </a></li>
                  <li><a [routerLink]="['/residential-png/customer-zone/current-bill']">
                      <div class="billing-icon"><img src="assets/images/billing-current-bill.png"></div>
                      <p>Current Bill</p>
                    </a></li>
                  <li><a [routerLink]="['/residential-png/customer-zone/billing-history']">
                      <div class="billing-icon"><img src="assets/images/billing-billing-history.png"></div>
                      <p>Billing History</p>
                    </a></li>
                  <li><a [routerLink]="['/residential-png/customer-zone/log-meter-reading']">
                      <div class="billing-icon"><img src="assets/images/billing-log-your-reading.png"></div>
                      <p>Log Your Meter Reading</p>
                    </a></li>
                  <li><a [routerLink]="['/residential-png/customer-zone/payment-history']">
                      <div class="billing-icon"><img src="../../../assets/images/billing-payment-history.png"></div>
                      <p>Payment History</p>
                    </a></li>
                     <li><a href="" onclick="return false;" (click)="gotoENach()">
                      <div class="billing-icon"><img src="../../../assets/images/billing-nach-e-mandate.png"></div>
                      <p>Nach E-Mandate</p>
                    </a></li>
                 <!--  <li><a [routerLink]="['/residential-png/nach-online']">
                      <div class="billing-icon"><img src="../../../assets/images/billing-nach-e-mandate.png"></div>
                      <p>Nach E-Mandate</p>
                    </a></li> -->
                  <li><a [routerLink]="['/residential-png/customer-zone/nach-cancel-form']">
                      <div class="billing-icon"><img src="../../../assets/images/billing-nach-cancellation.png"></div>
                      <p>Nach Cancellation</p>
                    </a></li>

                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="light-yellow-bg">
              <div class="customer-care">
                <h6>You can access the following for Customer Care</h6>
                <ul>
                 <!--  <li><a [routerLink]="['/residential-png/customer-zone/log-complaint']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-complaint.png"></div>
                      <p>Log a Complaint</p>
                    </a>
                  </li> -->
                 <!--   <li><a [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/status-of-open-request']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-complaint.png"></div>
                      <p>Log a Complaint</p>
                    </a>
                  </li> -->
                    <li><a [routerLink]="['/residential-png/customer-zone/log-complaint']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-complaint.png"></div>
                      <p>Log a Complaint</p>
                    </a>
                  </li>
                  <!-- <li><a [routerLink]="['/residential-png/customer-zone/view-complaint']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-view-complaint.png"></div>
                      <p>View complaint</p>
                    </a>
                  </li> -->
                  <li><a [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/status-of-open-request']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-view-complaint.png"></div>
                      <p>View complaint</p>
                    </a>
                  </li>
                  <li><a [routerLink]="['/residential-png/customer-zone/change-password']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-change-password.png"></div>
                      <p>Change Password</p>
                    </a>
                  </li>
                  <li><a [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">
                      <div class="billing-icon"><img src="../../../assets/images/cc-edit-profile.png"></div>
                      <p>Update Contact Details</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>