import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { AuthorizedService } from 'src/app/Service/authorized.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-authorizedperson',
  templateUrl: './authorizedperson.component.html',
  styleUrls: ['./authorizedperson.component.css']
})
export class AuthorizedpersonComponent {
  SearchForm!: FormGroup
  submitted = false
  allAuth: any[] = []
  captcha: any
  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  constructor(private FormBuilder: FormBuilder, private service: AuthorizedService) { }
  ngOnInit(): void {
    this.captchaGenerate()
    this.SearchForm = this.FormBuilder.group({
      AuthPersonCode: ['', [Validators.required]],
      Captcha: ['', [Validators.required]],
      ValidTo: new FormControl(''),
    })
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result
  }

  getAllAuthPerson() {
    this.service.getAllAuthUser().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.allAuth = data
     // console.log(this.allAuth)
    })
  }

  // submitSearch() {
  //   this.alertType = ''
  //   this.submitted = true
  //   if (this.SearchForm.get('Captcha')?.value != this.captcha) {
  //     this.SearchForm.get('Captcha')?.setValue('')
  //   }
  //   this.captchaGenerate()
  //   if (this.SearchForm.invalid) {
  //     return
  //   }
  //   this.service.getFilteredData(this.SearchForm.value).subscribe(response => {
  //     let data = JSON.parse(JSON.stringify(response)).details
  //     this.allAuth = data
  //     console.log(data)
  //     if (data.length == 0) {
  //       this.alertType = 'submit'
  //       this.alertMsg = "Data Not Found"
  //       this.alertColor = 'rgba(255, 94, 109, 0.2)'
  //       this.alertTextColor = '#c24753'
  //       this.alertBorder = '1px solid #04a862;'
  //     }
  //   })
  // }

  submitSearch() {
    this.alertType = ''
    this.submitted = true
    if (this.SearchForm.get('Captcha')?.value != this.captcha) {
      this.SearchForm.get('Captcha')?.setValue('')
    }
    this.captchaGenerate()
    if (this.SearchForm.invalid) {
      return
    }
 

     // this.UserForm.get('Email')?.value
     var  convertAuthPersonCode = crypto.AES.encrypt(this.SearchForm.get('AuthPersonCode')?.value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
    let obj ={"AuthPersonCode":convertAuthPersonCode}
    this.service.getAuthPersonweb(obj).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.allAuth = data
    //  console.log(data)
      if (data.length == 0) {
        this.alertType = 'submit'
        this.alertMsg = "Data Not Found"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #04a862;'
      }
    })
  }

  reset() {
    window.location.reload();
    this.submitted = false
  }
}
