import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { Router,ActivatedRoute } from '@angular/router';
import * as crypto from 'crypto-js';
declare var $: any; 

@Component({
  selector: 'app-pledge',
  templateUrl: './pledge.component.html',
  styleUrls: ['./pledge.component.css']
})
export class PledgeComponent {
  campaignsForm!: FormGroup
  submitted = false

  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

   display:string='none';
  Popup:any='';
 
 constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder,
  public UserService: UserRegistrationService,public router: Router) { }

  ngOnInit(): void {
  
     this.campaignsForm = this.FormBuilder.group({
      Name: ['', [Validators.required]],
      ContactNumber: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]],
      Email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
  

    });

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }


  

  submitPop(){
      this.submitted = true
      if (this.campaignsForm.invalid) {
      return;
    }
  
     this.alertType = 'secSubmit'
      let obj = {"Name":this.campaignsForm.get('Name')?.value,
      "ContactNumber":this.campaignsForm.get('ContactNumber')?.value,
      "Email":this.campaignsForm.get('Email')?.value}
     this.UserService.postCampaigns(obj).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      
      if (data.Response==true) {

        //console.log(data)
        //this.printForm()
       
        this.alertMsg = "Certificate send on your email id"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
          this.clearAlert();
          //this.closeModel()
        var ciphertext1 =  this.campaignsForm.get('Name')?.value
           var ciphertext = crypto.AES.encrypt(ciphertext1, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
   
         this.router.navigate(['/pledge-thank-you/'+this.campaignsForm.get('Name')?.value])
          

        }, 1000);
      } else {
        this.alertMsg = "Please try again"
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
         setTimeout(() => {
          this.clearAlert()
        }, 2000);
      }
    })

  }

   clearAlert() {
    this.alertType = ''
  }

    openPopup(){
    this.Popup='show';
    this.display='block';

  }

  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }

  reset(){
    this.campaignsForm.reset();
  }



    printForm() {
  
    const Application: any = `<!doctype html>
    <html>
    
    <head>
      <meta charset="utf-8">
      <title>PLEDGE</title>
      <style></style>
    </head>
    
    <body>
 <div style="width:900px; margin: 0 auto; background:#efeef4; -webkit-print-color-adjust: exact; font-family:Montserrat, sans-serif;">
  <div style="padding:4%">
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="20%"><img src="https://mahanagargas.com:3000/pledge/left-butterfly-img.png" style="width: 100%"></td>
      <td width="60%" align="center"><img src="https://mahanagargas.com:3000/pledge/pledge-text.jpg" style="width: 50%;"></td>
      <td width="19%" align="center"><img src="https://mahanagargas.com:3000/pledge/logo.png" style="width:65%"></td>
    </tr>
  </tbody>
</table>

    </div>
    <div style="font-family: Montserrat; padding:4%; font-size:32px; font-weight: 500;line-height: 42px;">
      <div style="color:#12472b">
      <table width="100%" border="0" cellspacing="0" cellpadding="2" style="font-family: Montserrat; font-size:32px; font-weight: 500;line-height: 42px;">
        <tbody>
        <tr>
          <td width="3%">I</td>
          <td width="62%"><div style="height: 30px; width: 96%; position: relative; padding-bottom:10px; font-weight: 800;">
        ${this.campaignsForm.get('Name')?.value}
        <div style="position: absolute; left: 0; bottom:0px; width: 100%; height:2px; background:#12472b;-webkit-print-color-adjust: exact;"></div>
        </div></td>
          <td width="35%">solemnly pledge</td>
        </tr>
        </tbody>
      </table>

      </div>
      <div style="color:#12472b; text-align: justify;">that I will shoulder the responsibility of a good citizen by using eco-f riendly fuel for household and vehicular consumption and endeavour to educate
my immediate family members and friends to use cleaner fuels for environment protection.</div>
      <div style="font-weight: 600; color:#509141;margin-top: 30px;text-align: justify;">MGL endeavours to protect and restore the planet through your support for generations to come.</div>
    </div>
    <div><img src="https://mahanagargas.com:3000/pledge/footer-graphic-img.png" style="width: 100%"></div>
  </div>
</body>
    
    </html>`

    var popupWin = window.open('', '_blank', 'width=1000,height=600');
    popupWin!.document.open();
    popupWin!.document.write(Application);

    setTimeout(function() {
      popupWin?.print()
      popupWin!.document.close();
  }, 500);
  }


}
