import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { AwardsItem } from 'src/app/models/award';
import { AwardsService } from 'src/app/Service/awards.service';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnInit {
  public base = environment.baseUrl

  allawards:any[]=[]
  backupawards:any[]=[]
  filteredAwards:any[]=[]

  constructor(private slideService : AwardsService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    this.getAllawards()
  }

  openCity(evt :any, cityName:any) {
    var i, tabcontent, tablinks;

    this.filteredAwards = this.backupawards.filter((x:any)=>{
      return new Date(x.Year).getFullYear() == cityName
    })


   // console.log(this.filteredAwards)
    

    tabcontent = document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName)!.style.display = "block";
    evt.currentTarget.className += " active";
  }


  getAllawards(){
    this.slideService.getAll().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      
      this.allawards = data

      

      for(let i of this.allawards){
        //console.log(i.PublishDate)
      }

      const uniqueAges = [...new Set( this.allawards.map(obj => new Date(obj.Year).getFullYear())) ];
      //console.log(data)

      
      this.allawards = uniqueAges
      this.backupawards = data

      this.openCity('',this.allawards[0])
      
    })
  }
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
