import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css'],
  
  
})
export class NewsListComponent implements OnInit {

  public base = environment.baseUrl

  allNews:any[]=[]
  backupnews:any[]=[]
  filteredNews:any[]=[]
  currentYear:any
  year='2023'

  constructor(public datePipe: DatePipe,private slideService : SliderService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    this.getAllNews()

    
  }

  openCity(evt :any, cityName:any) {
    var i, tabcontent, tablinks;

    this.filteredNews = this.backupnews.filter((x:any)=>{
      return new Date(x.PublishDate).getFullYear() == cityName
    })

    this.currentYear = cityName
    //console.log(this.filteredNews)
    

    tabcontent = document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName)!.style.display = "block";
    evt.currentTarget.className += " active";
  }


  getAllNews(){
    this.slideService.getAllNews().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      
      this.allNews = data

      

      for(let i of this.allNews){
        //console.log(i.PublishDate)
      }

      //console.log(this.allNews.sort((a, b) => Date.parse(b.PublishDate) - Date.parse(a.PublishDate)));

      const uniqueAges = [...new Set( this.allNews.map(obj => new Date(obj.PublishDate).getFullYear())) ];
      //console.log(uniqueAges)

      
      this.allNews = uniqueAges
      this.backupnews = data

      this.currentYear = this.allNews[0]
      //console.log(this.currentYear,this.allNews[0])
      this.openCity('',this.allNews[0])
      
    })
  }
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
