<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CNG Conversion Enquiry</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a  >CNG</a></li>
                <li class="active" aria-current="page">CNG Conversion Enquiry</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="customer-login-page log-meter-reading">
                               
                                <form [formGroup]="UserForm">
                                    <div class="">
                                        <div class="row">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input
                                                        type="text" class="form-control" formControlName="Name"
                                                        placeholder="Name"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Name'].errors }"
                                                        id="Name" />
                                                    <div *ngIf="submitted && UserForm.controls['Name'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Name'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Name<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input 
                                                        type="text" class="form-control" formControlName="Address"
                                                        placeholder="Address"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Address'].errors }"
                                                        id="Address" />
                                                    <div *ngIf="submitted && UserForm.controls['Address'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Address'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Address</label>
                                                </div>
                                            </div>

                                        </div>



                                        <div class="row">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input 
                                                        type="text" class="form-control" formControlName="City"
                                                        placeholder="City"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['City'].errors }"
                                                        id="City" />
                                                    <div *ngIf="submitted && UserForm.controls['City'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['City'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">City</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input
                                                        type="text" class="form-control" formControlName="Area"
                                                        placeholder="Area"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Area'].errors }"
                                                        id="Area" />
                                                    <div *ngIf="submitted && UserForm.controls['Area'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Area'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Area<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                        </div>


                                        <div class="row">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input  onKeyPress="if(this.value.length==10) return false;"
                                                        type="number" class="form-control" formControlName="MobileNo"
                                                        placeholder="MobileNo"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MobileNo'].errors }"
                                                        id="MobileNo" />
                                                    <div *ngIf="submitted && UserForm.controls['MobileNo'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['MobileNo'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Mobile No.<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input onKeyPress="if(this.value.length==10) return false;"
                                                        type="text" class="form-control" formControlName="PhoneNo"
                                                        placeholder="PhoneNo"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['PhoneNo'].errors }"
                                                        id="PhoneNo" />
                                                    <div *ngIf="submitted && UserForm.controls['PhoneNo'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['PhoneNo'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Phone No.<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                        </div>


                                        <div class="row">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input  
                                                        type="email" class="form-control" formControlName="Email"
                                                        placeholder="Email"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                                                        id="Email" />
                                                    <div *ngIf="submitted && UserForm.controls['Email'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Email'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Email<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input onKeyPress="if(this.value.length==10) return false;"
                                                        type="text" class="form-control" formControlName="VehicleManu"
                                                        placeholder="VehicleManu"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['VehicleManu'].errors }"
                                                        id="VehicleManu" />
                                                    <div *ngIf="submitted && UserForm.controls['VehicleManu'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['VehicleManu'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Vehicle Manufacturer<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                        </div>



                                        <div class="row">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input  
                                                        type="text" class="form-control" formControlName="Model"
                                                        placeholder="Model"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Model'].errors }"
                                                        id="Model" />
                                                    <div *ngIf="submitted && UserForm.controls['Model'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Model'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Model</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input onKeyPress="if(this.value.length==4) return false;"
                                                        type="number" class="form-control" formControlName="Year"
                                                        placeholder="Year"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Year'].errors }"
                                                        id="Year" />
                                                    <div *ngIf="submitted && UserForm.controls['Year'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Year'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Year</label>
                                                </div>
                                            </div>

                                                </div>
                                        <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mb-15 form-floating">
                                                <!-- <i class="demo-icon uil-lock-alt"></i> -->
                                                <select
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['type'].errors}"
                                                    #type (change)="this.UserForm.get('type')?.setValue(type.value)"
                                                    class="form-select" id="floatingSelect">
                                                    <option value="" selected></option>
                                                    <option *ngFor="let i of fuelType">{{i}}</option>

                                                </select>
                                                <label for="floatingSelect">Select Fuel Type<span
                                                    style="color: crimson;">*</span></label>

                                                <div *ngIf="submitted && UserForm.controls['type'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['type'].errors">Type required
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input  onKeyPress="if(this.value.length==2) return false;"
                                                        type="number" class="form-control" formControlName="AvgKm"
                                                        placeholder="AvgKm"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['AvgKm'].errors }"
                                                        id="AvgKm" />
                                                    <div *ngIf="submitted && UserForm.controls['AvgKm'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['AvgKm'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Averages Kms Run/day<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>          

                                        <div class="row">
                                            
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <textarea 
                                                        type="text" class="form-control" formControlName="Comment"
                                                        placeholder="Comment"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Comment'].errors }"
                                                        id="Comment" ></textarea>
                                                    <div *ngIf="submitted && UserForm.controls['Comment'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submitted && UserForm.controls['Comment'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Comment</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                     <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                                <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                                <div class="mt-20">
                                                    <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                   <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                    aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-7 col-md-7 col-sm-12">
                                              <div class="form-floating">
                                                    <input [ngClass]="{'is-invalid':submitted && UserForm.controls['Captcha'].errors}" type="text" class="form-control" id="Captcha" placeholder="Captcha" formControlName="Captcha">
                                                    <div *ngIf="submitted && UserForm.controls['Captcha'].errors" class="invalid-feedback">
                                                          <div *ngIf="UserForm.controls['Captcha'].errors">Captcha required</div>
                                                         
                                                      </div>
                                                      <label for="Captcha">Captcha<span
                                                        style="color: crimson;">*</span></label>
                                                  </div>
                                            </div>
                                            </div>
                                            </div>

                                        </div>
                                        
                                        </div>
                                        <div class="mt-30">
                                        <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    <div *ngIf="alertType == 'submit'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                </form>
                                <div class="text-right">
                                <p class="mt-20 mb-0">It's time to #switchtoMGLeCNG</p>
                                <p><b>Please note all fields marked <span class="text-danger">*</span> are compulsory.</b></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <!-- Inner Page Content Section End -->
</div>