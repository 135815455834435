<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Process of LoI issuance  <span></span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a>Dealer Zone</a></li>
                <li class="active" aria-current="page">Process of LoI issuance </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <!-- Tips -->
                <app-tips></app-tips>
                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content mb-lg-0 mb-30">
         				
<div class="process-wrapper">
	  <h1>The Process for Issuance of Letter of Intent(LOI)</h1>
  <div class="timeline">
    <dl class="timeline--entry">
      <dt class="timeline--entry__title">Step 1</dt>
      <dd class="timeline--entry__detail">Submit Expression of Interest(EOI) <a class="step-one-a" href="https://www.mahanagargas.com/cng/dealer-zone/apply-for-cng-oulet">Click here to apply</a></dd>
    </dl>
    <dl class="timeline--entry">
      <dt class="timeline--entry__title">Step 2</dt>
      <dd class="timeline--entry__detail"> Preliminary Site Assessment</dd>
    </dl>
    <dl class="timeline--entry">
      <dt class="timeline--entry__title">Step 3</dt>
      <dd class="timeline--entry__detail">If found preliminary feasible, submit the Application Form with supporting documents and fees <a class="step-three-a" href="https://mahanagargas.com/cng/dealer-zone/list-of-documents-required">List of Documents Required</a></dd>
    </dl>
    <dl class="timeline--entry">
      <dt class="timeline--entry__title">Step 4</dt>
      <dd class="timeline--entry__detail">Technical Feasibility Study and Legal Due Diligence</dd>
    </dl>
    <dl class="timeline--entry">
      <dt class="timeline--entry__title">Step 5</dt>
      <dd class="timeline--entry__detail">Interview of the shortlisted applicants</dd>
    </dl>
    <dl class="timeline--entry">
      <dt class="timeline--entry__title">Step 6</dt>
      <dd class="timeline--entry__detail">Issuance of Letter of Intent(LOI)</dd>
    </dl>
  </div>
</div>           
         
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Inner Page Content Section End -->