import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WalkinResp } from '../models/walkincenter';

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {
  billno:any
  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getBillingHistory(canum: any, bpnum: any) {
    return this.http.post(this.baseUrl+'history/getBillHistory', {canum: canum, bpnum: bpnum})
  }

   getBillHistoryNew(canum: any, bpnum: any) {
    return this.http.post(this.baseUrl+'history/getBillHistoryNew', {canum: canum, bpnum: bpnum})
  }

  getPaymentHistory(data: any) {
    return this.http.post(this.baseUrl+'history/getPaymentHistory', {canum: data}, data)
  }

  getCurrentBill(data:any){
    return this.http.post(this.baseUrl+'history/getBillingHistoryInfo',data)
  }

  getBillingHistoryInfoNew(data:any){
    return this.http.post(this.baseUrl+'history/getBillingHistoryInfoNew',data)
  }

   getCurrentBill1(data:any){
    return this.http.post(this.baseUrl+'history/getBillingHistoryInfo_forwithoutlogin',data)
  }

     getUsernameforlogmeter(data:any){
    return this.http.post(this.baseUrl+'reg/getUserAddress',data)
  }
  
  postNachOnline(data:any){
    return this.http.post(this.baseUrl+'RegNachMandate',data)
  }

  postMeterReading(data:any){
    return this.http.post(this.baseUrl+'logMeterReading/postLogMeterReading',data)
  }

   generateSelfbill(data:any){
    return this.http.post(this.baseUrl+'logMeterReading/generateSelfbill',data)
  }
  
  getBillingCycle(data:any){
    return this.http.post(this.baseUrl+'history/getCurrentBillingInfo',data)
  }

  getMobilityData(canum: any) {
  
    const headerDict = {
      'Content-Type': 'application/json',
      'requestform': 'WEB'
    }

    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    const arr = [];
    arr.push({"ca_no": canum})
    const data = JSON.stringify(arr);
    return this.http.post('https://mobility.mahanagargas.com:8443/BillPaySMS/getMglBillInfo', arr, requestOptions);
  }
  
  getQRPay(canum: any){
    return this.http.post(this.baseUrl+'getQRPay', {"ca_no": canum})
  }

   getQRPayNew(canum: any){
    return this.http.post(this.baseUrl+'getQRPayNew', {"ca_no": canum})
  }
  
  getICQRPay(canum: any){
    return this.http.post(this.baseUrl+'getICQRPay', {"ca_no": canum})
  }
  
  getBillImage(data: any){
    return this.http.post(this.baseUrl+'history/getBillImage', data)
  }

  getWalkinCenter(){
    return this.http.get<WalkinResp>(this.baseUrl+'history/getWalkinCenter')
  }
}
