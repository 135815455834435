import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.css']
})
export class OnlinePaymentComponent {

  alertType:any=''
  alertMsg:any=''
  alertColor : string=''
  alertTextColor : string=''
  alertBorder : string=''
  alertTextAlign:string='center'

  MobileNo: any = ''

  data: any = ''

  UserForm !: FormGroup
  Minute : any =2
  Sec : any=59

  OtpButtonActive : boolean = true

  captcha:any
  Otp:any
  OtpSent:boolean=false
  PopUp:boolean = false

  submitted: boolean = false
  constructor(private FormBuilder: FormBuilder, public UserService: UserRegistrationService,private router: Router, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.UserForm = this.FormBuilder.group({
      CAno: ['', [Validators.required]],
      Captcha: ['', [Validators.required]],
      Otp:['']
    })

    this.captchaGenerate()
    

  }

  resendOtp(){
    this.OtpGenerate()
    this.sendOtp()
  }

  clearStatus(){
    setTimeout(()=>{
      this.alertType=''
    },3000)
  }
  
  otpTimer(){
    
    var minute = 1;
    var sec = 1;
    const count = setInterval(function() {
      
      sec--;

      if (sec == 0 && minute == 0) {
        minute = 0
        sec =0
        
        clearInterval(count)
        
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }

      test()
      
    
    }, 1000);

    var test=()=>{
      if (sec == 0 && minute == 0) {
        this.OtpButtonActive=false
        
        clearInterval(count)
        
      }
      this.Minute = minute
      this.Sec = sec
      
    }

    
    
    
  }

  

  verifyOtp(){
    

    if(this.Otp == this.UserForm.get('Otp')?.value){
      this.router.navigate(['/paymentlink'])

      this.alertType='verifyOtp'
      this.alertMsg="Otp Verified"
      this.alertColor='rgba(4, 183, 107, 0.2)'
      this.alertTextColor='#038b51'
      this.alertBorder='1px solid #04a862;'
      this.clearStatus()

    }else{
      this.alertType='verifyOtp'
      this.alertMsg="Otp is Incorrect"
      this.alertColor='rgba(255, 94, 109, 0.2)'
      this.alertTextColor='#c24753'
      this.alertBorder='1px solid #eb5664;'
      this.clearStatus()
    }

  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  OtpGenerate() {
    let length = 6
    let result = '';
    const characters = '1234567890'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
   // console.log(result)
    this.Otp=result


  }

  sendOtp() {
    this.OtpGenerate()
    if(this.MobileNo.length == 10){
      this.UserService.sendOtp(this.Otp,this.MobileNo)
      this.OtpSent = this.UserService.otpStatus
      this.alertType='sendOtp'
      this.alertMsg="Otp Sent"
      this.alertColor='rgba(4, 183, 107, 0.2)'
      this.alertTextColor='#038b51'
      this.alertBorder='1px solid #04a862;'
      this.clearStatus()
      this.otpTimer()
      this.OtpButtonActive=true
    }
    
  }


  submit() {

    this.submitted = true
   // console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    if(this.captcha == this.UserForm.get('Captcha')?.value){

      this.UserService.getCaNo(this.UserForm.value).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
        this.alertType='CA'
       // console.log(data)
        if (data.Response) {
          this.data = data.details
          this.alertMsg="CA No.Found"
          this.alertColor='rgba(4, 183, 107, 0.2)'
          this.alertTextColor='#038b51'
          this.alertBorder='1px solid #04a862;'
          this.clearStatus()
        } else {
          
          this.alertMsg="CA No. Not Found"
          this.alertColor='rgba(255, 94, 109, 0.2)'
          this.alertTextColor='#c24753'
          this.alertBorder='1px solid #eb5664;'
          this.clearStatus()
          
        }
      })

    }else{
      
      this.alertMsg="Captcha Did Not Match"
      this.alertColor='rgba(255, 94, 109, 0.2)'
      this.alertTextColor='#c24753'
      this.alertBorder='1px solid #eb5664;'
      this.clearStatus()

    }

    



  }



}
