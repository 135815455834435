<!-- <footer>
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 p-0">
            <div class="footer-right">
                <div class="customer-care">
                    <i><img src="./assets/images/customer-care-icon.png"></i>
                    <div class="customer-care-details">
                        <p>Customer Care No.</p>
                        <h3><a href="tel:(022)-68674500">(022)-68674500</a> / <a
                                href="tel:(022)-61564500">(022)-61564500</a></h3>
                    </div>
                </div>
                <div class="customer-care mb-0">
                    <i><img src="./assets/images/helpline-icon.png"></i>
                    <div class="customer-care-details">
                        <p>Emergency Helpline</p>
                        <h3 class="mb-0"><a href="tel:18002669944">18002669944</a> (Toll free) <br> <a
                                href="tel:(022)-68759400">(022)-68759400</a> | <a href="tel:(022)-68759400">(022)
                                -24012400</a></h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 p-0">
            <div class="footer-left">
                <div class="row">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <ul>
                            <li><a [routerLink]="['/residential-png/customer-care/customer-care-overview']">Residential PNG</a></li>
                            <li><a [routerLink]="['/cng/overview/overview-enviro-benifits']">CNG</a></li>
                            <li><a [routerLink]="['/commercial-png-application']">Industrial &</a></li>
                            <li><a [routerLink]="['/commercial-png-application']">Commercial</a></li>
                            <li><a [routerLink]="['/MGL-corporate/about-MGL']">MGL Corporate</a></li>
                            <li><a [routerLink]="['/']">Employee Zone</a></li>
                        </ul>
                        <p class="copyright">© 2023 Mahanagar Gas Ltd.</p>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12">
                        <div class="download-mgl">
                            <p>Download MGL Connect</p>
                            <div class="app-store-icon">
                                <a href="https://apps.apple.com/in/app/mgl-connect/id1294636901"><img
                                        src="./assets/images/app-store.png"></a>
                                <a href="https://play.google.com/store/apps/details?id=com.mobicule.mgl"><img
                                        src="./assets/images/play-store.png"></a>
                            </div>
                        </div>
                        <div class="privacy-policy-links">
                            <a (click)="this.FaqService.filterFaq('')" [routerLink]="['/faq-list']">FAQs</a> | 
                            <a [routerLink]="['/disclamer']">Disclaimer</a> | <a [routerLink]="['/privacy-policy']">Privacy Policy</a> | 
                            <a [routerLink]="['/sitemap']">Sitemap</a>
                        </div>
                        <div class="follow-us-on">
                            <p>Follow Us On </p>
                            <ul class="">
                                <li><a href="https://www.facebook.com/mglltd" target="_blank"><span><i class="fa fa-facebook"></i></span></a> </li>
                                <li><a href="https://twitter.com/mahanagargas" target="_blank"><span><i class="fa fa-twitter"></i></span></a></li>
                                <li><a href="https://youtu.be/07Jxsttz6X8" target="_blank"><span><i class="fa fa-youtube-play"></i></span></a></li>
                                <li><a href="#" target="_blank"><span><i class="fa fa-instagram"></i></span></a></li>
                                <li><a href="#" target="_blank"><span><i class="fa fa-linkedin"></i></span></a></li>
                                                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</footer> -->
<footer>
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 p-0">
            <div class="footer-right">
                <div class="customer-care">
                    <i><img src="./assets/images/customer-care-icon.png"></i>
                    <div class="customer-care-details">
                        <p>Customer Care No.</p>
                        <h3><a href="tel:(022)-68674500">(022)-68674500</a> / <a
                                href="tel:(022)-61564500">(022)-61564500</a></h3>
                    </div>
                </div>
                <div class="customer-care">
                    <i><img src="./assets/images/whats-app-icon.png"></i>
                    <div class="customer-care-details">
                        <p>WhatsApp Number</p>
                        <h3 class="mb-0">
							<a href="https://wa.me/message/TKTPMXSNNNPLN1">+919899203843</a>
						</h3>
                    </div>
                </div>
                <div class="customer-care">
                    <i><img src="./assets/images/helpline-icon.png"></i>
                    <div class="customer-care-details">
                        <p>Emergency Helpline</p>
                        <h3 class="mb-0">
                            <a href="tel:18002669944">18002669944</a> (Toll free) <br> 
                            <a href="tel:(022)-68759400">(022)-68759400</a> | <a href="tel:(022)-68759400">(022)-24012400</a><br>
                            
                        </h3>
                    </div>
                </div>
                 <div class="customer-care mb-0">
                    <i><img src="./assets/images/helpline-icon.png"></i>
                    <div class="customer-care-details">
                        <p>Dial Before Dig/ MGL Sahayogi</p>
                        <h3 class="mb-0">
                            <a href="tel:1800 2100 2100">1800 2100 2100</a>
                            
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 p-0">
            <div class="footer-left">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                     <div class="follow-us-on">
                            <p>Follow Us On </p>
                            <ul class="">
                                <li><a href="https://www.facebook.com/mglltdofficial" target="_blank"><span><i class="fa fa-facebook"></i></span></a> </li>
                                <li><a href="https://twitter.com/mahanagargas" target="_blank"><span><i class="fa fa-twitter"></i></span></a></li>
                                <li><a href="https://youtu.be/07Jxsttz6X8" target="_blank"><span><i class="fa fa-youtube-play"></i></span></a></li>
                                <li><a href="https://instagram.com/mahanagargas?igshid=YmMyMTA2M2Y=" target="_blank"><span><i class="fa fa-instagram"></i></span></a></li>
                                <li><a href="https://in.linkedin.com/company/mglltd" target="_blank"><span><i class="fa fa-linkedin"></i></span></a></li>
                                                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="download-mgl">
                            <p>Download MGL Connect</p>
                            <div class="app-store-icon">
                                <a href="https://apps.apple.com/in/app/mgl-connect/id1294636901" target="_blank"><img
                                        src="./assets/images/app-store.png"></a>
                                <a href="https://play.google.com/store/apps/details?id=com.mobicule.mgl" target="_blank"><img
                                        src="./assets/images/play-store.png"></a>
                            </div>
                        </div>
                    </div>

                    <div class="footer-links">
                        <ul>
                            <li><a [routerLink]="['/residential-png/customer-care/customer-care-overview']">Residential PNG</a></li>
                            <li><a [routerLink]="['/cng/overview/overview-enviro-benifits']">CNG</a></li>
                            <li><a [routerLink]="['/commercial-png-application']">Industrial & Commercial</a></li>
                            <li><a [routerLink]="['/MGL-corporate/about-MGL']">MGL Corporate</a></li>
                            <!-- <li><a [routerLink]="'https://apps.mahanagargas.com/mglintranet/login.aspx'">Employee Zone</a></li> -->
                            <li>
                              <a href="https://apps.mahanagargas.com/mglintranet/login.aspx">Employee Zone</a>  
                            </li>
                            <!-- <li><a [routerLink]="'https://apps.mahanagargas.com/mglintranet/login.aspx'">Employee Zone</a></li> -->

                            <!-- <li><a herf="https://apps.mahanagargas.com/mglintranet/login.aspx">bxEmployee Zone</a></li> -->
                        </ul>
                        </div>

                </div>
            </div>
        </div>
        
    </div>
    <div class="footer-bottom">
        <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="privacy-policy-links">
                <a (click)="this.FaqService.filterFaq('')" [routerLink]="['/faq-list']">FAQs</a> | 
                <a [routerLink]="['/disclamer']">Disclaimer</a> | <a [routerLink]="['/privacy-policy']">Privacy Policy</a> | 
                <a [routerLink]="['/sitemap']">Sitemap</a>
        </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12"><p class="copyright">© 2024 Mahanagar Gas Ltd.</p></div>

        </div>
    </div>
</footer>