import { Component } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-marutisuzuki20thankyou',
  templateUrl: './marutisuzuki20thankyou.component.html',
  styleUrls: ['./marutisuzuki20thankyou.component.css']
})
export class Marutisuzuki20thankyouComponent {
id: any
  constructor(public datePipe: DatePipe, public UserService: UserRegistrationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.nametransferData = this.UserService.printNameTransfer
    this.id = this.route.snapshot.paramMap.get('id');
  }
}
