<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Empanelment<span> </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Contracts & Procurements</a></li>
                <li class=""><a >Tenders</a></li>
                <li class=""><a >Archives</a></li>
                <li class="active" aria-current="page">Empanelment</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>                                       
                    <!-- <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <a href="#" class="bluebtn2 d-block">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
 -->

                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">



                    <div class="box882_left">

                        <div class="archieved_table">
                            <ul>
                                <li class="archieved2-box"> 
                                    <span class="srNo">Sr.No</span> 
                                    <span class="NameofNotice">Name of Notice</span> 
                                    <span class="NoticeNo">Notice No</span> 
                                    <span class="Description">Description</span> 
                                    <span class="LastDateSubmission">Last Date for Form Submission</span> 
                                    <span class="Downloads">Downloads</span>
                                </li>
                                <li> 
                                    <span class="srNo">1</span> 
                                    <span class="NameofNotice">Invitation for Empanelment
                                        of GI Contractor</span> 
                                        <span class="NoticeNo">MGL/EMPANELMENT FOR GI CONTRACTOR
                                        /2012</span> 
                                        <span class="Description">Installation of GI Pipe Network (risers)
                                        inside the Residential Complex and associated activities required for PNG
                                        connection like installation of Gas Meter, Copper Tube, Meter Regulator, Brass
                                        Valves and providing connection up to Gas Appliances inside Kitchen.</span>
                                    <span class="LastDateSubmission">16.10.2012</span> 
                                    <span class="Downloads"><a href="#">Download Empanelment Form</a></span>
                                        </li>
                                <li> 
                                    <span class="srNo">2</span> 
                                    <span class="NameofNotice">Invitation for Empanelment of Steel Pipeline Laying and HDD / Augur Boring Contractor</span> 
                                        <span class="NoticeNo">MGL/C&amp;P/EMP/STEEL CONTRACT/2013</span> 
                                        <span class="Description">Category A: HDD &amp; Augur Boring Contractors for Laying of Carbon Steel Pipeline across Road/ Railway Crossing by Trenchless Method.<br>
                                        Category B: Contractors for Laying of Carbon Steel Pipeline by Open Trench
                                        method</span> 
                                        <span class="LastDateSubmission">30.07.2013</span> 
                                        <span class="Downloads"><a href="#">Download Empanelment Form</a></span>
                                 </li>
                                <li>
                                    <span class="srNo">3</span> 
                                    <span class="NameofNotice">Invitation for Empanelment of Suppliers for CNG Reciprocating Compressors</span> 
                                        <span class="NoticeNo">MGL/C&amp;P/EMP/COMPRESSOR/2013</span> 
                                        <span class="Description">Supply of CNG Reciprocating Compressors of various capacities</span>
                                        <span class="LastDateSubmission">09.08.2013</span>
                                        <span class="Downloads"><a href="#">Download Empanelment Form</a></span>
                                </li>
                                <li> 
                                    <span class="srNo">4</span>
                                    <span class="NameofNotice">Invitation for Empanelment for Supply of Diaphragm Gas Meters of Size G1.6(Qmax-2.5 SCMH)</span>
                                    <span class="NoticeNo">MGL/C&amp;P/EMP/DIAPHRAGM GAS METER /2013</span> 
                                    <span class="Description">Manufacture and Supply of Diaphragm Gas Meters of Size
                                        G1.6(Qmax-2.5 SCMH).</span> <span class="LastDateSubmission">09.08.2013</span>
                                    <span class="Downloads"><a href="#">Download Empanelment Form</a></span>
                                </li>
                                <li> <span class="srNo">5</span> <span class="NameofNotice">Invitation for Empanelment
                                        of PE / GI Contractors</span> <span class="NoticeNo">MGL/EMPANELMENT FOR PE/GI
                                        CONTRACTOR /2015</span> <span class="Description">Laying of Poly Ethylene (PE)
                                        Pipeline for Medium / Low Pressure Network. Erection of GI pipe and associated
                                        activities required for Piped gas Connection like installation of Gas Meter,
                                        Copper Tube, Meter Regulator, Brass Valves and providing connection up to Gas
                                        Appliances inside Kitchen. </span> <span
                                        class="LastDateSubmission">08.06.2015</span> <span class="Downloads"><a
                                            href="#">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">6</span> <span class="NameofNotice">Invitation for Empanelment
                                        of Construction Contractors for Proposed Construction of Stores Building at
                                        Taloja</span> <span class="NoticeNo">MGL/EMPANELMENT FOR CONTRACTORS
                                        /2016</span> <span class="Description">Proposed Construction of Store building
                                        Including Civil, Mechanical, Electrical, Plumbing, Pre-Engineered building
                                        structure and other ancillary works at MIDC Taloja.</span> <span
                                        class="LastDateSubmission">22.03.2016</span> <span class="Downloads"><a
                                            href="#">Download Empanelment Form</a> </span></li>
                                <li> <span class="srNo">7</span> <span class="NameofNotice">Empanelment Of Contractors/
                                        Service Providers</span> <span class="NoticeNo">EMPANELMENT OF CONTRACTORS/
                                        SERVICE PROVIDERS/ 2016</span> <span class="Description">Empanelment form for
                                        contractors and service providers with terms &amp; conditions.</span> <span
                                        class="LastDateSubmission">09.16.2016</span> <span class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_EmpanelmentForm_f1be54b9e4.pdf"
                                            target="_blank">Download Empanelment Form</a> </span></li>
                                <li> <span class="srNo">8</span> <span class="NameofNotice">Invitation for Empanelment
                                        of Suppliers for CS Pipes</span> <span class="NoticeNo">Invitation for
                                        Empanelment of Carbon Steel Pipe Suppliers</span> <span
                                        class="Description">Carbon Steel Pipes as per API 5L, PSL-2, sizes 4"NB to
                                        12"NB</span> <span class="LastDateSubmission">05.01.2017</span> <span
                                        class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_Empanelment_Form_for_CS_pipes_cb3d2bac71.pdf"
                                            target="_blank">Download Empanelment Form</a>
                                        <!--<a href="/UploadAssets/UploadedFiles/_MGLEmpanelTn2_78aafaa275.pdf" target="_blank">Download Empanelment of News Paper</a>-->
                                    </span></li>
                                <li> <span class="srNo">9</span> <span class="NameofNotice">Invitation for Empanelment
                                        for providing Operation Services</span> <span
                                        class="NoticeNo">MGL/C&amp;P/EMPANELMENT/OPERATIONS/2017</span> <span
                                        class="Description">Service Providers for Operation and up-keeping of CNG
                                        Compressor packages, Cascades and other accessories associated with CNG Stations
                                        located in Mumbai &amp; Adjoining Areas.</span> <span
                                        class="LastDateSubmission">15.02.2017</span> <span class="Downloads"><a
                                            href="#">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">10</span> <span class="NameofNotice">Invitation for Empanelment
                                        for providing dedicated HCVs on hire basis</span> <span
                                        class="NoticeNo">MGL/C&amp;P/EMPANELMENT/HCV/2017</span> <span
                                        class="Description">Providing Diesel fueled HCVs (Payload Capacity 7 - 10 MT) on
                                        dedicated basis for carrying CNG Cylinder Cascades from any MGL Mother Station
                                        to various DB Stations in Mumbai &amp; Adjoining Areas.</span> <span
                                        class="LastDateSubmission">15.02.2017</span> <span class="Downloads"><a
                                            href="#">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">11</span> <span class="NameofNotice">Invitation for Empanelment
                                        of PE / GI / Online / Steel Contractors</span> <span
                                        class="NoticeNo">MGL/EMPANELMENT FOR PE /GI/ ONLINE / STEEL CONTRACTOR -
                                        2017</span> <span class="Description">Laying of Poly Ethylene (PE) Pipeline for
                                        Medium/ Low pressure network. Erection of GI pipeline (above ground) and
                                        associated activities required for Piped Natural Gas (PNG) Connection. Online
                                        PNG Connection in gasified building. Laying of Steel Pipeline.</span> <span
                                        class="LastDateSubmission">30.06.2017</span> <span class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_empanelment-form-10jun17_35de358b97.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">12</span> <span class="NameofNotice">Empanelment of Service
                                        Providers for Call Centre CRM ECR &amp; Metering July 2018</span> <span
                                        class="NoticeNo">MGL/EMPANELMENT FOR SERVICE PROVIDER FOR OUTSOURCING CALL
                                        CENTRE OPERATIONS – 2018</span> <span class="Description">Providing Servicesfor
                                        Call Centre operations forCustomer Relationship Management for PNG
                                        Customers(CRM), Emergency Control Room for Operations and Maintenance (ECR) and
                                        Metering Operations.</span> <span class="LastDateSubmission">14.08.2018</span>
                                    <span class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_Empanelment-of-Service-July-2018_79ac126d7c.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">13</span> <span class="NameofNotice">Empanelment of Mutual Fund
                                        Investment Advisors</span> <span class="NoticeNo">EMPANELMENT OF MUTUAL FUND
                                        INVESTMENT ADVISORS</span> <span
                                        class="Description"><!--Providing Servicesfor Call Centre operations forCustomer Relationship Management  for  PNG  Customers(CRM),  Emergency  Control  Room  for Operations and Maintenance (ECR) and Metering Operations.--></span>
                                    <span class="LastDateSubmission">15.01.2018</span> <span class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_empanelmentMutualFund_8d0c983295.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">14</span> <span class="NameofNotice">Empanelment of HR
                                        Consultant for Assistance in Hiring</span> <span
                                        class="NoticeNo"><!--EMPANELMENT OF MUTUAL FUND INVESTMENT ADVISORS--></span>
                                    <span
                                        class="Description"><!--Providing Servicesfor Call Centre operations forCustomer Relationship Management  for  PNG  Customers(CRM),  Emergency  Control  Room  for Operations and Maintenance (ECR) and Metering Operations.--></span>
                                    <span class="LastDateSubmission">03.07.2018</span> <span class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/empanelment-for-hr-consultant-for-assistance-in-hiring.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">15</span> <span class="NameofNotice">Empanelment for
                                        Photography&amp; Videography</span> <span class="NoticeNo"> MGL/EMPANELMENT
                                        OFSERVICE PROVIDER FOR PHOTOGRAPHY AND VIDEOGRAPHY-2019</span> <span
                                        class="Description">Providing Servicesfor Photography and Videography of MGL
                                        events</span> <span class="LastDateSubmission">28.02.2019</span> <span
                                        class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_empanelment-for-photography-and-videography_cbb3e392b8.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li> <span class="srNo">16</span> <span class="NameofNotice">Empanelment of Contractors"
                                        Published in Times Of India Edition on 29.05.2019</span> <span class="NoticeNo">
                                        <!--MGL/EMPANELMENT OFSERVICE PROVIDER   FOR PHOTOGRAPHY AND VIDEOGRAPHY-2019--></span>
                                    <span
                                        class="Description"><!--Providing Servicesfor Photography and Videography of MGL events--></span>
                                    <span class="LastDateSubmission">10.06.2019</span> <span class="Downloads"><a
                                            href="/UploadAssets/UploadedFiles/_empanelment-form_725c784809.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li><span class="srNo">17</span><span class="NameofNotice">Empanelment for Advertising
                                        Agency Published On MGL Website 10.10.2019</span><span
                                        class="NoticeNo">MGL/C&amp;P/EMPANELMENT/ADVERTISING/2019-20/PP</span><span
                                        class="Description"></span><span
                                        class="LastDateSubmission">24.10.2019</span><span class="Downloads"><a
                                            href="https://www.mahanagargas.com/UploadAssets/UploadedFiles/_empanelment10oct19_2242d02c14.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                                <li><span class="srNo">18</span><span class="NameofNotice">Empanelment of
                                        Contractors</span><span class="NoticeNo">&nbsp;</span><span
                                        class="Description">Installation of GI Pipe Network (Risers) inside the
                                        Residential Complex and associated activities required for PNG connections like
                                        installation of Gas Meters, Copper Tubes, Meter Regulators, Brass Valves and
                                        providing connections up to Gas Appliances inside Kitchen and Laying of
                                        Underground Poly Ethylene Pipeline.</span><span
                                        class="LastDateSubmission">06.04.2021</span><span class="Downloads"><a
                                            href="https://www.mahanagargas.com/UploadAssets/UploadedFiles/_Empanelment-Form-21_eeefd5186c.pdf"
                                            target="_blank">Download Empanelment Form</a></span></li>
                            </ul>
                        </div>

                    </div>

















                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>