import { Component } from '@angular/core';

@Component({
  selector: 'app-downloadapplink',
  templateUrl: './downloadapplink.component.html',
  styleUrls: ['./downloadapplink.component.css']
})
export class DownloadapplinkComponent {

}
