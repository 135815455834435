import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CngSavingCalculatorService {

  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }

  getPetrolPrice(){
    return this.http.get(this.baseUrl+'getPetrolPrice')
  }
  getDieselPrice(){
    return this.http.get(this.baseUrl+'getDieselPrice')
  }
  getLpgPrice(){
    return this.http.get(this.baseUrl+'getLpgPrice')
  }
  getCngPrice(){
    return this.http.get(this.baseUrl+'getCngPrice')
  }
}
