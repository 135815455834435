import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-meterreadingproject',
  templateUrl: './meterreadingproject.component.html',
  styleUrls: ['./meterreadingproject.component.css']
})
export class MeterreadingprojectComponent {
   constructor(private activatedRoute: ActivatedRoute,
    private pageLoaderService: PageLoaderService) { 
   }

  ngOnInit(): void {
     this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
    //https://mread.in/Panel/#/authentication/index?billKey=t944guc  
      window.location.href = 'https://mread.in/Panel/#/authentication/index?billKey=' + id;
      console.log('Url Id: ', id);
    })
  }

}
