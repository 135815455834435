import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-mgltez',
  templateUrl: './mgltez.component.html',
  styleUrls: ['./mgltez.component.css']
})
export class MgltezComponent {

  constructor(private pageLoaderService: PageLoaderService,) {

    }

      ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   
  }


}
