import { Component } from '@angular/core';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-news-comp',
  templateUrl: './news-comp.component.html',
  styleUrls: ['./news-comp.component.css']
})
export class NewsCompComponent {
  public base = environment.baseUrl;
  allNews:any[]=[]

  constructor(private slideService : SliderService){}

  ngOnInit(): void {

    this.getAllNews()
  }


  getAllNews(){
    this.slideService.getAllNews().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    //  console.log(response)
      this.allNews = data

      
      //console.log(this.allNews)
    })
  }

}
