import { HttpClient } from '@angular/common/http';
import { map, Observable} from 'rxjs';
import { ComplaintResp } from 'src/app/models/complaint';
import { CategoryResp } from 'src/app/models/cat';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogcomplainttestService {
 baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  
  getAllGasified(){
    return this.http.get(this.baseUrl+'gasifiedTest/getAllGasified')
  }

  postDomConnGasifiedBuild(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/postGasifiedBuild',data)
  }

  UpdateDomConnGasifiedBuildDoc(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/updateGasifiedBuild',data)
  }

  UpdateDomConnGasifiedBuildDocNew(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/updateGasifiedBuildNew',data)
  }

   UpdateEmiproof(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/updateEmiproof',data)
  }

   UpdateEmiproofNew(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/updateEmiproofNew',data)
  }



  updatePayamt(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/updatePayamt',data)
  }


  getAddressByPincode(data:any){
    return this.http.post(this.baseUrl+'area/getAddressByPincode',data)
  }

  getAllOutlet(){
    return this.http.get(this.baseUrl+'outlet/get_AllOutlet')
  }


  getAllCng_DealerFeedBack(){
    return this.http.get(this.baseUrl+'outlet/get_AllCng_DealerFeedBack')
  }

  postCngDealerFeedBack(data:any){
    return this.http.post(this.baseUrl+'outlet/postCngDealerFeedback',data)
  }


 getAll(bpnum: any): Observable<ComplaintResp[]> {
    return this.http.post<ComplaintResp[]>(this.baseUrl + 'complaint/get_complaint', { bpnum: bpnum })
    .pipe(map(user => {
      return user;
    }))
  }
 
  getcomp():Observable<CategoryResp[]> {
    return this.http.get<CategoryResp[]>(this.baseUrl+'catergory/get_complainttype');
  }

  getcomplaint(Type_of_complaint: string): Observable<CategoryResp[]> {
    return this.http.post<CategoryResp[]>(this.baseUrl+'catergory/get_compact',{Type_of_complaint:Type_of_complaint})
    .pipe(map(user => {
      return user;
    }))
}

  insertcomplaint(form:any) {
    return this.http.post<ComplaintResp>(`${this.baseUrl}complaint/insert_complaint`,form)
  }


  insertwithoutimage(form:any) {
    return this.http.post<ComplaintResp>(`${this.baseUrl}complaint/insert_withoutimage`,form)
  }
  
  
  getGasifiedCount(){
    return this.http.get(this.baseUrl+'gasifiedTest/getGasifiedCount')
  }

  getSingleGasified(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/getSingleDomestic',data)
  }

  getSingleGasifiedTransaction(data:any){
    return this.http.post(this.baseUrl+'gasifiedTest/getSingleDomesticTransaction',data)
  }

 get_All_cng_complaint_statuses(data:any){
    return this.http.post(this.baseUrl+'outlet/get_All_cng_complaint_statuses',data)
  }
}
