import { Component } from '@angular/core';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-domestic-con-req-form',
  templateUrl: './domestic-con-req-form.component.html',
  styleUrls: ['./domestic-con-req-form.component.css']
})
export class DomesticConReqFormComponent {

  public base = environment.baseUrl
  allPost: any[] = [];

  constructor(private PostService: SliderService){}

  ngOnInit(): void {
    this.getAllHighlight()
   
  }

  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    
      this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
      

    })
    
  }

}
