<div class="profile-section1">
  <!--   <div *ngIf="data != undefined" class="profile-name mb-20">
        <h4 *ngIf="data.FirstName">{{data.FirstName}} {{data.LastName}}</h4>
		<p *ngIf="data.CaNo">CA No.:<b> {{data.CaNo}}</b></p>
        <p *ngIf="data.BpNo">BP No.: <b>{{data.BpNo}}</b></p>
        
    </div> -->
      <div *ngIf="address1 != undefined" class="profile-name mb-20">
        <h4 *ngIf="data.FirstName">{{address[0].Name}}</h4>
        <p *ngIf="data.CaNo">CA No.:<b> {{address1.CANo}}</b></p>
        <p *ngIf="data.BpNo">BP No.: <b>{{address1.BPNo}}</b></p>
        
    </div>
    <div class="user-address mb-20">
        <div class="user-add-heading">
            Address
        </div>
        <ul *ngIf="address[0] != undefined">
            <li><i class="demo-icon uil-home-alt"></i> {{address[0].Flat_No}}, {{address[0].Bldg_Name}},{{address[0].SociatyName}}, {{address[0].Road_name}}, {{address[0].City}},
                {{address[0].Postal_code}}</li>
        </ul>
    </div>

    
      <div *ngIf="address1 != undefined" class="user-address mb-20">
        <div class="user-add-heading">
            Contact Details
        </div>
        <ul>
            <li><i class="demo-icon uil-phone-volume"></i>{{address[0].Mobile_No}}</li>
            <li><i class="demo-icon uil-envelope"></i> <a href="mailto:radhika.malik@hohtechlabs.com">{{address[0].Email}}</a>
            </li>
        </ul>
    </div>

    <!-- <div *ngIf="data != undefined" class="user-address mb-20">
        <div class="user-add-heading">
            Contact Details
        </div>
        <ul>
            <li><i class="demo-icon uil-phone-volume"></i>{{data.Mobile}}</li>
            <li><i class="demo-icon uil-envelope"></i> <a href="mailto:radhika.malik@hohtechlabs.com">{{data.Email}}</a>
            </li>
        </ul>
    </div> -->

</div>

<!-- <app-recent-post></app-recent-post> -->