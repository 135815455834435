<!--Full width header End-->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <!-- <h1>After sales service provider's list
        </h1> -->
        <h1>Service provider's list
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Policies and Procedures</a></li>
                <li class=""><a >Aftersales Services</a></li>
                <li class="active" aria-current="page">Service provider's list 
                </li>
            </ul>
        </div>
        <!-- <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Policies & Procedures</a></li>
                <li class=""><a >Aftersales Services</a></li>
                <li class="active" aria-current="page">After sales service provider's list
                </li>
            </ul>
        </div> -->
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                 <div class="mt-30">
                    <app-news-comp></app-news-comp>


                </div>
            </div> 
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <h2 class="dual-heading mb-30"></h2>
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="rs-faq applications-natural-gas">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link" href="#collapseOne" data-toggle="collapse"
                                            aria-expanded="true">
                                            MGL Approved service providers for carrying out After Sales Services for MGL
                                            installation- Area distribution</a>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group">
                                                  <div class="form-floating">
                                                    <div class="ng-autocomplete" >
                                                        <ng-autocomplete 
                                                          [data]="serviceProArr"
                                                          [searchKeyword]="keyword"
                                                          [(ngModel)]= userLocation
                                                          placeholder="Search Service Providers"
                                                          (selected)='selectEvent($event)'
                                                          (inputChanged)='onChangeSearch($event)'
                                                          (inputFocused)='onFocused($event)'
                                                          historyIdentifier="serviceProArr"
                                                          [itemTemplate]="itemTemplate"
                                                          [notFoundTemplate]="notFoundTemplate">
                                                        </ng-autocomplete>
                                                      
                                                        <ng-template #itemTemplate let-item>
                                                          <a [innerHTML]="item.name"></a>
                                                        </ng-template>
                                                      
                                                        <ng-template #notFoundTemplate let-notFound>
                                                          <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                      </div>
                                          
                                          
                                        </div>

                                    </div>
                                         <div
                                                    class="accordianindustriesCont dealers_contace_table"
                                                    style="width: 100%; margin-right: 0px;" *ngIf="userLocationdata==true">
                                                    <div style="overflow-x: auto;">
                                                        <table id="tablelist"
                                                            class="table table-bordered">
                                                            <tbody>
                                                                <tr
                                                                    style="text-transform: uppercase;">
                                                                    <th *ngIf="MaxNaturalActive==true" colspan="2">Max Natural
                                                                        gas.Pvt.Ltd</th>
                                                                    <th  *ngIf="FIXOFITActive==true" colspan="2">Fixofit-Well
                                                                    </th>
                                                                    <th  *ngIf="VVPRASADActive==true" colspan="2">V.V.Prasad
                                                                    </th>
                                                                    <th  *ngIf="SAHEDULActive==true" colspan="2">Sahedul
                                                                        Sheikh</th>
                                                                    <th  *ngIf="AARTIENTERPRISESActive==true" colspan="2">Aarti
                                                                        Enterprises</th>
                                                                    <th  *ngIf="RISHIINFRAActive==true" colspan="2">Rishi Infra
                                                                    </th>
                                                                    <th  *ngIf="SAMRUDDHIActive==true" colspan="2">Samruddhi
                                                                        Express</th>
                                                                   <!--  <th  *ngIf="SHRIENGINEERSActive==true" colspan="2">Shri
                                                                        Engineers</th> -->
                                                                    <th  *ngIf="RATHODBROTHERSActive==true" colspan="2">Rathod
                                                                        Brothers</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <!-- <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th>
                                                                    <th>DRS No.</th>
                                                                    <th>AREA</th> -->
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">1</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Chembur,Chunabhatti</td>
                                                                    <td *ngIf="FIXOFITActive==true">14</td>
                                                                    <td *ngIf="FIXOFITActive==true">Juhu / Vile Parle (W)
                                                                    </td>
                                                                    <td *ngIf="VVPRASADActive==true">105 , 106, 107 ,108, 109
                                                                    </td>
                                                                    <td *ngIf="VVPRASADActive==true">Thane</td>
                                                                    <td *ngIf="SAHEDULActive==true">5</td>
                                                                    <td *ngIf="SAHEDULActive==true">Powai,
                                                                        Chandivali,Sakinaka</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">34</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Parel</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">16A</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">Malad (E)</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">6</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">Andheri (E)</td>
<!--                                                                     <td  *ngIf="SHRIENGINEERSActive==true">301A</td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true">Uran</td> -->
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">
                                                                        100A,101,101B,102A,102B102C,103A,104</td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">Mira Road, Bhayander</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">2</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Chembur, Deonar,&nbsp;
                                                                    </td>
                                                                    <td *ngIf="FIXOFITActive==true">12</td>
                                                                    <td *ngIf="FIXOFITActive==true">Andheri (W), Jogeshwari
                                                                        (W)</td>
                                                                    <td *ngIf="VVPRASADActive==true">142&amp;143</td>
                                                                    <td *ngIf="VVPRASADActive==true">Badlapur</td>
                                                                    <td *ngIf="SAHEDULActive==true">8</td>
                                                                    <td *ngIf="SAHEDULActive==true">Andheri (E ),Jogeshwari
                                                                        (E )</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">29</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Sion, Wadala,Matunga ( E)
                                                                    </td>
                                                                    <td *ngIf="RISHIINFRAActive==true">17</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">Kandivali (E)</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">11</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">Vile Parle (E)</td>
                                                                   <!--  <td  *ngIf="SHRIENGINEERSActive==true">302A</td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true">Ulwe</td> -->
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">24A</td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">Dahisar (W)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">3</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Ghatkopar (E), Tilak
                                                                        Nagar, Pestom Sagar</td>
                                                                    <td *ngIf="FIXOFITActive==true">13</td>
                                                                    <td *ngIf="FIXOFITActive==true">Andheri (W)</td>
                                                                    <td *ngIf="VVPRASADActive==true">
                                                                        147A,148A&amp;148B,151A&nbsp;</td>
                                                                    <td *ngIf="VVPRASADActive==true">Dombivali</td>
                                                                    <td *ngIf="SAHEDULActive==true">15A</td>
                                                                    <td *ngIf="SAHEDULActive==true">Goregaon (E)</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">&nbsp;36A</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Patiksha nagar</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">15B</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">Goregaon (W)</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">9</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">Bandra (E) &amp; Khar (E)
                                                                    </td>
                                                                   <!--  <td  *ngIf="SHRIENGINEERSActive==true">310A</td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true">Fortune city</td> -->
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">24B</td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">Borivali &amp; Dahisar
                                                                        (W)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">4</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Kurla (W)</td>
                                                                    <td *ngIf="FIXOFITActive==true">27</td>
                                                                    <td *ngIf="FIXOFITActive==true">Santacruz (W)</td>
                                                                    <td *ngIf="VVPRASADActive==true">135, 136, 137</td>
                                                                    <td *ngIf="VVPRASADActive==true">Ambarnath</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">&nbsp;35A</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Wadala</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">16B,37A</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">Malad (W)</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">26</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">Santacruz (E)</td>
                                                                   <!--  <td  *ngIf="SHRIENGINEERSActive==true">401A</td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true">Pen</td> -->
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">23A,23B</td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">Borivali (W)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">7</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Ghatkopar (W)</td>
                                                                    <td *ngIf="FIXOFITActive==true">18A,18B</td>
                                                                    <td *ngIf="FIXOFITActive==true">Bandra (W) &amp;Khar (W)
                                                                    </td>
                                                                    <td *ngIf="VVPRASADActive==true">146A,140A,141A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Kalyan</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">25A,25B</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Matunga, Dadar / Mahim
                                                                        (W)</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">16C</td>
                                                                    <td *ngIf="RISHIINFRAActive==true">Kandivali (W)</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">28</td>
                                                                    <td *ngIf="SAMRUDDHIActive==true">Bandra Kurla Complex</td>
                                                                  <!--   <td  *ngIf="SHRIENGINEERSActive==true">308A</td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true">India Bulls Panvel</td> -->
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">104</td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">Bhayander</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">10</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Vikhroli (E) &amp; (W)
                                                                    </td>
                                                                    <td *ngIf="FIXOFITActive==true">28</td>
                                                                    <td *ngIf="FIXOFITActive==true">Bandra Kurla Complex</td>
                                                                    <td *ngIf="VVPRASADActive==true">139A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Shahad</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">30</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Worli, Prabhadevi</td>
                                                                  <!--   <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                     <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td> -->
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">22B</td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true">Dahisar (E)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">19</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Mulund (W)</td>
                                                                   <!--  <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">114</td>
                                                                    <td *ngIf="VVPRASADActive==true">Juinagar</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">31</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Tardeo</td>
                                                                   <!--  <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td> -->
                                                                    <td *ngIf="RATHODBROTHERSActive==true">22A</td>
                                                                    <td *ngIf="RATHODBROTHERSActive==true">Borivali (E)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">19A</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Mulund (E)</td>
                                                                    <!-- <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">121A,121B</td>
                                                                    <td *ngIf="VVPRASADActive==true">CBD Belapur</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">32</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Mahalaxmi</td>
                                                                    <!-- <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">20</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Bhandup (W), Kanjur Marg
                                                                        (W)</td>
                                                                   <!--  <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">120A,120B</td>
                                                                    <td *ngIf="VVPRASADActive==true">Nerul</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">33</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Marine Drive, Colaba</td>
                                                                   <!--  <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">21</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Bhandup (E), Kanjur Marg
                                                                        (E)</td>
                                                                   <!--  <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">115A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Seawoods</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td> -->
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">40</td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true">Navi nagar</td>
                                                                    <!-- <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <td *ngIf="MaxNaturalActive==true">42</td>
                                                                    <td *ngIf="MaxNaturalActive==true">Kurla (W)</td>
                                                                   <!--  <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">131A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Kharghar</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td *ngIf="MaxNaturalActive==true"></td >
                                                                     <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                   
                                                                    <td *ngIf="VVPRASADActive==true">132A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Taloja</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">123A,123B</td>
                                                                    <td *ngIf="VVPRASADActive==true">Kharghar</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">118,118B</td>
                                                                    <td *ngIf="VVPRASADActive==true">Vashi</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">119</td>
                                                                    <td *ngIf="VVPRASADActive==true">Sanpada</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">129A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Kalamboli</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">117,117B</td>
                                                                    <td *ngIf="VVPRASADActive==true">Ghansoli</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">116,116B</td>
                                                                    <td *ngIf="VVPRASADActive==true">Airoli</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">122</td>
                                                                    <td *ngIf="VVPRASADActive==true">Rabale</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">124</td>
                                                                    <td *ngIf="VVPRASADActive==true">Mahape</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">125</td>
                                                                    <td *ngIf="VVPRASADActive==true">Turbhe&nbsp;</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">126A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Taloja</td>
                                                                   <!--  <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                    <!-- <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">127A,128A</td>
                                                                    <td *ngIf="VVPRASADActive==true">New and Old Panvel</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">130A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Kamothe</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">301A</td>
                                                                    <td *ngIf="VVPRASADActive==true">301A Raigadh Uran</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">130A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Fortune city Panvel</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">302A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Ulwe</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">401A</td>
                                                                    <td *ngIf="VVPRASADActive==true">India Bulls</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">308A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Pen</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">128B</td>
                                                                    <td *ngIf="VVPRASADActive==true">KARANJADE</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">133A</td>
                                                                    <td *ngIf="VVPRASADActive==true">Taloja</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">144A</td>
                                                                    <td *ngIf="VVPRASADActive==true">BHIWANDI</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">150A</td>
                                                                    <td *ngIf="VVPRASADActive==true">DOMBIVALI</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">302B</td>
                                                                    <td *ngIf="VVPRASADActive==true">302B Ulwe</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">152A</td>
                                                                    <td *ngIf="VVPRASADActive==true">152A-LODHA CASA RIO</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">152B</td>
                                                                    <td *ngIf="VVPRASADActive==true">152B-LODHA CASA BELA</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">153A</td>
                                                                    <td *ngIf="VVPRASADActive==true">153A-RUNWAL</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">153B</td>
                                                                    <td *ngIf="VVPRASADActive==true">153B-LODHA PREMIER</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                                 <tr>
                                                                   <!--  <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="MaxNaturalActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td>
                                                                    <td *ngIf="FIXOFITActive==true"></td> -->
                                                                    <td *ngIf="VVPRASADActive==true">133A</td>
                                                                    <td *ngIf="VVPRASADActive==true">133A PANCHANAND</td>
                                                                    <!-- <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="SAHEDULActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="AARTIENTERPRISESActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="RISHIINFRAActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td *ngIf="SAMRUDDHIActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="SHRIENGINEERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td>
                                                                    <td  *ngIf="RATHODBROTHERSActive==true"></td> -->
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p>All requests to be logged in at MGL Customer Care on 68674500 /
                                                        61564500</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                      <!--   <a class="card-link collapsed" href="#collapsetwentytwo" data-toggle="collapse"
                                            data-bs-target="#collapsetwentytwo" aria-expanded="false">MGL Approved
                                            service providers for carrying out stove related Services- Area distribution
                                        </a> -->
                                          <a class="card-link collapsed" href="#collapsetwentytwo" data-toggle="collapse"
                                            data-bs-target="#collapsetwentytwo" aria-expanded="false">List of service providers for carrying out stove related services


                                        </a>
                                    </div>
                                    <div id="collapsetwentytwo" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                             <p style="font-size: 12px;">Disclaimer: The service providers listed herein below are solely for the information purposes and any services availed by the customers from said service providers would at their own cost and responsibility and MGL shall in no way be liable or responsible for the services rendered by the said service providers.</p>
                                                     <div class="form-group">
                                                  <div class="form-floating">
                                                    <div class="ng-autocomplete" >
                                                        <ng-autocomplete 
                                                          [data]="serviceProArr1"
                                                          [searchKeyword]="keyword1"
                                                          [(ngModel)]= userLocation1
                                                          placeholder="Search Service Area"
                                                          (selected)='selectEvent1($event)'
                                                          (inputChanged)='onChangeSearch1($event)'
                                                             (inputFocused)='onFocused1($event)'
                                                          historyIdentifier="serviceProArr1"
                                                          [itemTemplate]="itemTemplate"
                                                          [notFoundTemplate]="notFoundTemplate">
                                                        </ng-autocomplete>
                                                      
                                                        <ng-template #itemTemplate let-item>
                                                          <a [innerHTML]="item.name"></a>
                                                        </ng-template>
                                                      
                                                        <ng-template #notFoundTemplate let-notFound>
                                                          <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                      </div>
                                          
                                          
                                        </div>

                                    </div>

                                     <table *ngIf="userLocationdata1==true" id="tablelist" class="table table-bordered">
                                                <tbody>
                                                    <tr style="text-transform: uppercase;">
                                                        <th>Sr no</th>
                                                        <th>Name of Service provider&nbsp;</th>
                                                        <th >Name of owner</th>
                                                        <th>Contact of owner</th>
                                                        <th>DRS Area</th>
                                                        <th>DRS Nos</th>
                                                    </tr>
                                                    <tr *ngIf="firstActive==true">
                                                        <td>1</td>
                                                        <td >Aarkay & Sons</td>
                                                        <td>Ashfaq Khan</td>
                                                        <td>9892213424</td>
                                                        <td>016B Malad (W),016C Kandivali (W)</td>
                                                        <td>016B,016C</td>
                                                    </tr>
                                                    <tr *ngIf="secActive==true">
                                                        <td>2</td>
                                                        <td>Bright Gas Service</td>
                                                        <td>Akash chokshi</td>
                                                        <td>9999333281</td>
                                                        <td>014 Juhu, Andheri (W),027 Santacruz (W)</td>
                                                        <td>14027</td>
                                                    </tr>
                                                    <tr *ngIf="thirdActive==true">
                                                        <td>3</td>
                                                        <td>Excel Gas Service</td>
                                                        <td>Ravi ramchandran</td>
                                                        <td>9819538628</td>
                                                        <td>002 Postal Colony, Chembur</td>
                                                        <td>2</td>
                                                    </tr>
                                                    <tr *ngIf="fourthActive==true">
                                                        <td>4</td>
                                                        <td>Hari Gas Service</td>
                                                        <td>Naresh R</td>
                                                        <td>9819119822</td>
                                                        <td>030 Worli,031 Tardeo,0034 Parel,025A Mahim to Dadar (W),025B Mahim to Dadar (W)</td>
                                                        <td>30,31,34,25A & 25B</td>
                                                    </tr>
                                                    <tr *ngIf="fifthActive==true">
                                                        <td>5</td>
                                                        <td>Jyoti Gas Distributors</td>
                                                        <td>Pratik Shah</td>
                                                        <td>9819642562</td>
                                                        <td>013 Andheri (W)</td>
                                                        <td>13</td>
                                                    </tr>
                                                    <tr *ngIf="sixActive==true">
                                                        <td>6</td>
                                                        <td>Lakshmi Agencies</td>
                                                        <td>Lalit Agarwal</td>
                                                        <td>9967044446</td>
                                                        <td>016A Malad (E&W),022A Borlivali (E),022B Borlivali (E )</td>
                                                        <td>016A,022A,022B</td>
                                                    </tr>
                                                    <tr *ngIf="sevenActive==true">
                                                        <td>7</td>
                                                        <td>Mohan Gas Service</td>
                                                        <td>Mohan Randhive</td>
                                                        <td>9820354320</td>
                                                        <td>003 Pestom Sagar, Chembur</td>
                                                        <td>3</td>
                                                    </tr>
                                                    <tr *ngIf="eightActive==true">
                                                        <td>8</td>
                                                        <td>Mukta Gas Service</td>
                                                        <td >Rajiv K</td>
                                                        <td>8452853332</td>
                                                        <td>017 Kandivali (E),037A -Charkop, Mumbai</td>
                                                        <td>17,037A</td>
                                                    </tr>
                                                    <tr *ngIf="nineActive==true">
                                                        <td>9</td>
                                                        <td>National Gas Service</td>
                                                        <td>Chetan Modi</td>
                                                        <td>9821083651</td>
                                                        <td>029 Sion (E) to Dadar (E),0032 Maratha Mandir,033 Mantralaya</td>
                                                        <td>29,32,33</td>
                                                    </tr>
                                                    <tr *ngIf="tenActive==true">
                                                        <td>10</td>
                                                        <td>Om Vinayak Gas Service</td>
                                                        <td>Deepak Singh</td>
                                                        <td>9987060845</td>
                                                        <td>008 Sher-e-Punjab, Andheri(E)</td>
                                                        <td>8</td>
                                                    </tr>
                                                    <tr *ngIf="elevenActive==true">
                                                        <td>11</td>
                                                        <td>Padma Gas Agency</td>
                                                        <td>Mohan R Keni</td>
                                                        <td>9870219689</td>
                                                        <td>024A Borivali (W) & Part of Dahisar (W),024B Dahisar (W)</td>
                                                        <td>024A,024B</td>
                                                    </tr>
                                                    <tr *ngIf="twelveActive==true">
                                                        <td>12</td>
                                                        <td>Prasan Gas Service</td>
                                                        <td>Ajmal Sheikh</td>
                                                        <td>9702177992/9892197382</td>
                                                        <td>019 Mulund(W),020 Bhandup,019A Mulund (E)</td>
                                                        <td>19,20,019A</td>
                                                    </tr>
                                                    <tr *ngIf="thirteenActive==true">
                                                        <td>13</td>
                                                        <td>Radha Gas Service</td>
                                                        <td>Cap.Kopikar/td>
                                                        <td>9323533392</td>
                                                        <td>007 Pant Nagar, Ghatkopar,010 Kanjur Marg(W),021 Godrej</td>
                                                        <td>7,10,21</td>
                                                    </tr>
                                                    <tr *ngIf="fourteenActive==true">
                                                        <td>14</td>
                                                        <td>Rahul Gas Service</td>
                                                        <td>Naina Choudhary</td>
                                                        <td>9869426880</td>
                                                        <td>011 Andheri (E)
                                                        </td>
                                                        <td>11</td>
                                                    </tr>
                                                    <tr *ngIf="fifteenActive==true">
                                                        <td>15</td>
                                                        <td>Ramchandra Gas Agency</td>
                                                        <td>Pratik Keni</td>
                                                        <td>9833417761</td>
                                                        <td>100A Kashigaon, Miraroad (E), 101 Mira Road (E), 102 Mira Road, 103 Bhayandar (E), 104 Bhayandar (W)</td>
                                                        <td>100A , 101 , 101B , 102A , 102B , 102C , 103A , 104</td>
                                                    </tr>
                                                    <tr *ngIf="sixteenActive==true">
                                                        <td>16</td>
                                                        <td>Regency Gas</td>
                                                        <td>Narendra Tejwani</td>
                                                        <td>9819384053</td>
                                                        <td>005 Sakinaka (Inside Metro Hotel),006 Hotel Leela</td>
                                                        <td>5,6</td>
                                                    </tr>
                                                    <tr *ngIf="seventeenActive==true">
                                                        <td>17</td>
                                                        <td>Rishi Infra</td>
                                                        <td>Gopal Singh</td>
                                                        <td>8850592932</td>
                                                        <td>023A&B Borlivali(W),105 Thane (W),106 Thane (W),107 Thane (W),108 Thane (W),109 Thane (W)</td>
                                                        <td>023A&B,105,106,107,108,109</td>
                                                    </tr>
                                                    <tr *ngIf="eighteenActive==true">
                                                        <td>18</td>
                                                        <td>Sahedul Shaikh</td>
                                                        <td>Sahedul Shaikh</td>
                                                        <td>9892216802</td>
                                                        <td>015A Goregaon (E)</td>
                                                        <td>15A</td>
                                                    </tr>
                                                    <tr *ngIf="nineteenActive==true">
                                                        <td>19</td>
                                                        <td>Shakuntala Bharat Gas</td>
                                                        <td>Mrs. Chanchmalhatpuri</td>
                                                        <td>9819529532</td>
                                                        <td>301A Raigadh</td>
                                                        <td>301A</td>
                                                    </tr>
                                                    <tr *ngIf="twentyActive==true">
                                                        <td>20</td>
                                                        <td>Snebhi Gas Service</td>
                                                        <td>Parvin walinkar</td>
                                                        <td>9769348464</td>
                                                        <td>012 Andheri-(W)
                                                        </td>
                                                        <td>12</td>
                                                    </tr>
                                                    <tr *ngIf="twentyoneActive==true">
                                                        <td>21</td>
                                                        <td>Vijay Gas Service</td>
                                                        <td>Nilesh Ahire</td>
                                                        <td>9834205844</td>
                                                        <td>009 Kala Nagar, Bandra (E),018A Bandra (W),018B Bandra & Khar (W ),026 Santacruz (E),028 Bandra Kurla Complex</td>
                                                        <td>9,26,28,018A,018B</td>
                                                    </tr>
                                                    <tr *ngIf="twentytwoActive==true">
                                                        <td>22</td>
                                                        <td>Vimal Gas Service</td>
                                                        <td>Saloni Bhayani</td>
                                                        <td>9022435770</td>
                                                        <td>001 Suman Nagar, Chembur,004 Kamani, Kurla,0040 Navi Nagar,0042 Taxi-men Colony, Kurla(W),035A Bhakti Park, Wadala,036A - Pratiksha Nagar, Mumbai</td>
                                                        <td>1,4,40,42,035A,036A</td>
                                                    </tr>
                                                    <tr *ngIf="twentythreeActive==true">
                                                        <td>23</td>
                                                        <td>Zaveri Gas Service</td>
                                                        <td>Vinod Mane</td>
                                                        <td>9821722231</td>
                                                        <td>015B Goregaon (W)</td>
                                                        <td>015B</td>
                                                    </tr>
                                                     <tr *ngIf="twentyfourActive==true">
                                                        <td>24</td>
                                                        <td>V.V.prasad</td>
                                                        <td>V V prasad</td>
                                                        <td>9323658829</td>
                                                        <td>116A  Airoli, Navi Mumbai,116B  Airoli, Navi Mumbai,117B  Koparkhairane, Navi Mumbai,117A  Koparkhairane, Navi Mumbai,118A Vashi, Navi Mumbai,0119 Sanpada, Navi Mumbai,0122 Rabale, Navi Mumbai,0124 Mahape, Navi Mumbai,0125 Turbhe, Navi Mumbai,114A Juinagar (W), Nerul (W),115A Seawoods (W),118B Navi Mumbai (Sanpada),120A Nerul, Navi Mumbai,120B Nerul, Navi Mumbai,121A Belapur, Navi Mumbai,121B Belapur, Navi Mumbai,123A Kharghar, Navi Mumbai,123B Kharghar, Navi Mumbai,126A Taloja MIDC, Navi Mumbai,127A New Panvel,128A Old Panvel,129A Kalamboli,130A Kamothe (Mansarovar),131A KHARGHAR,132A MIDC,Taloja, Navi Mumbai,135A Ambernath (E),136A Ambernath (E) Additional MIDC,137A Ambernath(W) CGS,139A Shahad,140A Kalyan (W),141A Kalyan (W),142A Badlapur (W),143A Badlapur (E),146A Kalyan (E),147A MIDC Dombivali,148A Dombivali (E),148B Dombivali (E),149A MIDC Dombivali,151A Dombivali (W),302B Ulwe,302A Ulwe,128B KARANJADE,133A Taloja,144A BHIWANDI,150A DOMBIVALI,152A-LODHA CASA RIO,152B-LODHA CASA BELA,153A-RUNWAL,153B-LODHA PREMIER,310A Fortune city Panvel, Rees Village Khalapur,308A India Bulls,401A Pen,303A-JNPT,311A- RASAYANI MIDC,313A- SAVROLI,314A-DHEKU,320A - ROHA,402A - NERAL,403A- KARJAT,405A-KHOPOLI,301A Uran</td>
                                                        <td>116A,116B,117B,117A,118A,119,122,124,125,114A,115A,118B,120A,120B,121A,121B,123A,123B,126A,127A,128A,129A,130A,131A,132A,135A,136A,137A,139A,140A,141A,142A,143A,146A,147A,148A,148B,149A,151A,302B,302A,128B,133A,144A,150A,152A,152B,153A,153B,310A,308A,401A,303A,311A,313A,314A,320A,402A,403A,405A,301A</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                             
                                           <!--  <table *ngIf="userLocationdata1==true" id="tablelist" class="table table-bordered">
                                                <tbody>
                                                    <tr style="text-transform: uppercase;">
                                                        <th>Sr no</th>
                                                        <th>Name of Service provider&nbsp;</th>
                                                        <th >Name of owner</th>
                                                        <th>Contact of owner</th>
                                                        <th>DRS Area</th>
                                                        <th>DRS Nos</th>
                                                    </tr>
                                                    <tr *ngIf="firstActive==true">
                                                        <td>1</td>
                                                        <td >ZAVERI GAS SERVICE</td>
                                                        <td>VINOD MANE&nbsp;</td>
                                                        <td>9821722231</td>
                                                        <td>015B Goregaon (W)</td>
                                                        <td>015B</td>
                                                    </tr>
                                                    <tr *ngIf="secActive==true">
                                                        <td>2</td>
                                                        <td>SAHEDUL SHIEKH</td>
                                                        <td>SAHEDUL SHIEKH</td>
                                                        <td>9892216802</td>
                                                        <td>015A Goregaon (E)&nbsp;</td>
                                                        <td>015A</td>
                                                    </tr>
                                                    <tr *ngIf="thirdActive==true">
                                                        <td>3</td>
                                                        <td>PADMA GAS AGENCY</td>
                                                        <td>MOHAN R KENI</td>
                                                        <td>9870219689</td>
                                                        <td>024A Borivali (W) &amp; Part of Dahisar (W), 024B Dahisar
                                                            (W)</td>
                                                        <td>024B, 024A</td>
                                                    </tr>
                                                    <tr *ngIf="fourthActive==true">
                                                        <td>4</td>
                                                        <td>BRIGHT GAS AGENCY</td>
                                                        <td>AKASH CHOKSHI</td>
                                                        <td>9833452313</td>
                                                        <td>014 Juhu, Andheri (W), 027 Santacruz (W)</td>
                                                        <td>14,27</td>
                                                    </tr>
                                                    <tr *ngIf="fifthActive==true">
                                                        <td>5</td>
                                                        <td>HARI GAS SERVICE</td>
                                                        <td>NARESH&nbsp; ENGINEER</td>
                                                        <td>9819119822</td>
                                                        <td>0032 Maratha Mandir, 0034 Parel, 025A Mahim to Dadar (W),
                                                            025B Mahim to Dadar (W), 030 Worli, 031 Tardeo, 033
                                                            Mantralaya</td>
                                                        <td>30 , 31 , 32 , 33 , 34 , 025A , 025B</td>
                                                    </tr>
                                                    <tr *ngIf="sixActive==true">
                                                        <td>6</td>
                                                        <td>RAHUL GAS SERVICE</td>
                                                        <td>NAINA CHOUDHARY</td>
                                                        <td>9869426880</td>
                                                        <td>011 Andheri (E)</td>
                                                        <td>11</td>
                                                    </tr>
                                                    <tr *ngIf="sevenActive==true">
                                                        <td>7</td>
                                                        <td>PRASAN GAS CO.</td>
                                                        <td>AJAMAL FAROOQUI</td>
                                                        <td>7039282801</td>
                                                        <td>Bhandup, Mulund (E ), Mulund (W)</td>
                                                        <td>19,19 A,20</td>
                                                    </tr>
                                                    <tr *ngIf="eightActive==true">
                                                        <td>8</td>
                                                        <td>JYOTI GAS DISTRIBUTORS</td>
                                                        <td >PRATIK SHAH</td>
                                                        <td>9819642562</td>
                                                        <td>013 Andheri (W)</td>
                                                        <td>13</td>
                                                    </tr>
                                                    <tr *ngIf="nineActive==true">
                                                        <td>9</td>
                                                        <td>VIMAL GAS SERVICE</td>
                                                        <td>RAJENDRA GOSALIYA</td>
                                                        <td>9920957845</td>
                                                        <td>Kamani, Postal Colony, Suman Nagar,Taxi-men Colony, Kurla(E
                                                            &amp; W),Pratiksha Nagar, Sion (E) to Dadar (E ),Sion
                                                            west,Wadala,Sindhi society,Chunabhatti,Some parts of Chembur
                                                            Naka</td>
                                                        <td>1,4,42,29,36A</td>
                                                    </tr>
                                                    <tr *ngIf="tenActive==true">
                                                        <td>10</td>
                                                        <td>EXCEL GAS SERVICE</td>
                                                        <td>RAVI RAMCHANDRAN</td>
                                                        <td>9819538628</td>
                                                        <td>Deonar, Govandi, Mahul, Collector colony, Some parts of
                                                            Chembur naka</td>
                                                        <td>2</td>
                                                    </tr>
                                                    <tr *ngIf="elevenActive==true">
                                                        <td>11</td>
                                                        <td>V V PRASAD&nbsp;</td>
                                                        <td>V V PRASAD&nbsp;</td>
                                                        <td>9702514483</td>
                                                        <td>0119 Sanpada , 0122 Rabale, 0124 Mahape , 0125 Turbhe, 023A
                                                            Borlivali(W) , 023B Borlivali (W) , 105 Thane (W) , 106
                                                            Thane (W) , 107 Thane (W) , 108 Thane (W) , 109 Thane (W) ,
                                                            116A&amp;B&nbsp; Airoli ,117A &amp;B Koparkhairane,
                                                            118A&amp;B Vashi, 135A Ambernath (E) , 136A Ambernath (E)
                                                            Additional MIDC , 137A Ambernath(W) CGS , 139A Shahad , 140A
                                                            Kalyan (W) , 141A Kalyan (W) , 142A Badlapur (W) , 143A
                                                            Badlapur (E) , 146A Kalyan (E) , 147A MIDC Dombivali , 148A
                                                            Dombivali (E) , 148B Dombivali (E) , 149A MIDC Dombivali ,
                                                            151A Dombivali (W),114A Juinagar (W), 120A Nerul , 120B
                                                            Nerul, 115A Seawoods (W), 121A&amp;B Belapur, 123A Kharghar,
                                                            123B Kharghar, 131A Kharghar, 129A Kalamboli , 130A Kamothe
                                                            (Mansarovar) , 132A MIDC, Taloja, 126A Taloja MIDC, 127A New
                                                            Panvel , 128A Old Panvel, Ulwe</td>
                                                        <td>106,107 , 105 , 023B , 023A , 109 , 117B , 108 , 119 , 118 ,
                                                            116B , 117A , 135A , 116&amp;B , 118B , 146A , 122 , 124 ,
                                                            125, 136A , 137A , 139A , 140A , 141A , 142A , 143A , 147A ,
                                                            148A , 148B , 149A , 151A,114A,120A,120B, 115A,
                                                            121A&amp;B,123A, 123B , 131A, 129A , 130A , 132A , 126A,
                                                            127A, 128A, 302A</td>
                                                    </tr>
                                                    <tr *ngIf="twelveActive==true">
                                                        <td>12</td>
                                                        <td>AARKAY &amp; SONS</td>
                                                        <td>ASHFAQ KHAN&nbsp; &nbsp;</td>
                                                        <td>7400056709</td>
                                                        <td>016C Kandivali (W), 016B Malad (W)</td>
                                                        <td>16C,016B</td>
                                                    </tr>
                                                    <tr *ngIf="thirteenActive==true">
                                                        <td>13</td>
                                                        <td>RAMCHANDRA GAS AGENCY</td>
                                                        <td>PRATIK KENI</td>
                                                        <td>9833417761</td>
                                                        <td>100A Kashigaon, Miraroad (E), 101 Mira Road (E), 102 Mira
                                                            Road, 103 Bhayandar (E), 104 Bhayandar (W)</td>
                                                        <td>100A , 101 , 101B , 102A , 102B , 102C , 103A , 104</td>
                                                    </tr>
                                                    <tr *ngIf="fourteenActive==true">
                                                        <td>14</td>
                                                        <td>LAKSHMI AGENCIES</td>
                                                        <td>LALIT AGARWAL / KIRAN AGARWAL</td>
                                                        <td>9967044446 / 9987066850</td>
                                                        <td>022A Borlivali (E), 022B Borlivali (E),016A Malad (E&amp;W)
                                                        </td>
                                                        <td>022B,022A,016A</td>
                                                    </tr>
                                                    <tr *ngIf="fifteenActive==true">
                                                        <td>15</td>
                                                        <td>OM VINAYAK GAS SERVICE</td>
                                                        <td>PREETI SINGH</td>
                                                        <td>9987060845</td>
                                                        <td>008 Sher-e-Punjab, Andheri(E)</td>
                                                        <td>8</td>
                                                    </tr>
                                                    <tr *ngIf="sixteenActive==true">
                                                        <td>16</td>
                                                        <td>MOHAN GAS SERVICE</td>
                                                        <td>MOHAN RANDHIVE</td>
                                                        <td>9833024093</td>
                                                        <td>03 Pestom Sagar,Tilak nagar,mankhurd</td>
                                                        <td>3</td>
                                                    </tr>
                                                    <tr *ngIf="seventeenActive==true">
                                                        <td>17</td>
                                                        <td>MUKTA GAS SERVICE</td>
                                                        <td>RAJEEV TUNGARE</td>
                                                        <td>9892355665</td>
                                                        <td>017 Kandivali (E), 037A -Charkop, Mumbai</td>
                                                        <td>17, 037A</td>
                                                    </tr>
                                                    <tr *ngIf="eighteenActive==true">
                                                        <td>18</td>
                                                        <td>SNEBHI GAS SERVICE</td>
                                                        <td>PRAVIN WALINJKAR&nbsp;</td>
                                                        <td>9769348464</td>
                                                        <td>012 Andheri-(W),Jogeshwari west</td>
                                                        <td>12</td>
                                                    </tr>
                                                    <tr *ngIf="nineteenActive==true">
                                                        <td>19</td>
                                                        <td>VIJAY GAS SERVICE</td>
                                                        <td>VIJAY CHAWLA</td>
                                                        <td>9892635937</td>
                                                        <td>009 Kala Nagar, Bandra (E),018A Bandra (W),018B Bandra &amp;
                                                            Khar (W ),026 Santacruz (E),028 Bandra Kurla Complex</td>
                                                        <td>9 , 26 , 28 , 018A , 018B</td>
                                                    </tr>
                                                    <tr *ngIf="twentyActive==true">
                                                        <td>20</td>
                                                        <td>RADHA GAS SERVICES</td>
                                                        <td>CAPTAIN DILIP V KOPIKAR&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                        <td>&nbsp;022-25743893</td>
                                                        <td>007 Pant Nagar, Ghatkopar , 010 Kanjur Marg(W),021 Godrej
                                                        </td>
                                                        <td>7,10,21</td>
                                                    </tr>
                                                    <tr *ngIf="twentyoneActive==true">
                                                        <td>21</td>
                                                        <td>REGENCY GAS</td>
                                                        <td>NARENDRA&nbsp;</td>
                                                        <td>9819384053</td>
                                                        <td>005 Sakinaka (Inside Metro Hotel), 006 Hotel Leela</td>
                                                        <td>5,6</td>
                                                    </tr>
                                                    <tr *ngIf="twentytwoActive==true">
                                                        <td>22</td>
                                                        <td>SHAKUNTALA BHARAT GAS</td>
                                                        <td>MRS. CHANCHMALHATPURI</td>
                                                        <td>9819529523</td>
                                                        <td>301A Uran</td>
                                                        <td>301A</td>
                                                    </tr>
                                                    <tr *ngIf="twentythreeActive==true">
                                                        <td>23</td>
                                                        <td>SHRI ENGINEERS</td>
                                                        <td>SANTOSH NIKAM</td>
                                                        <td>9324213780/8369171584</td>
                                                        <td>310A Fortune city Panvel,401A India Bulls,308A Pen</td>
                                                        <td>310A,401A,308A</td>
                                                    </tr>
                                                </tbody>
                                            </table> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>