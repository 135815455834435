import { Component } from '@angular/core';
import { MenuService } from 'src/app/Service/menu.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-unison-enviro-private-limited',
  templateUrl: './unison-enviro-private-limited.component.html',
  styleUrls: ['./unison-enviro-private-limited.component.css']
})
export class UnisonEnviroPrivateLimitedComponent {
 allData:any[]=[]
  baseUrl=environment.baseUrl
  constructor(private Service:MenuService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.getPdf()
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getPdf(){
    this.Service.getPdfByCategory({PdfCategory:'Unison Enviro Private Limited'}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.allData=data
    })
  }
}

