import { Component } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nachpaymenterror',
  templateUrl: './nachpaymenterror.component.html',
  styleUrls: ['./nachpaymenterror.component.css']
})
export class NachpaymenterrorComponent {
   id: any
  err: any
  constructor(public datePipe: DatePipe, public UserService: UserRegistrationService, private route: ActivatedRoute) { }
  
   ngOnInit(): void {
    // this.nametransferData = this.UserService.printNameTransfer
    this.id = this.route.snapshot.paramMap.get('id');
    this.err = this.route.snapshot.paramMap.get('err');
}
}
