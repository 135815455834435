import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment'; 

@Component({
  selector: 'app-applyfornametstepone',
  templateUrl: './applyfornametstepone.component.html',
  styleUrls: ['./applyfornametstepone.component.css']
})
export class ApplyfornametsteponeComponent {
  public base = environment.baseUrl
   allPost: any[] = [];
   captcha: any;
   CustomerInfoForm!: FormGroup
   submitted = false
   finalSubmit : boolean = false
   loading = false;

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

   NameInitial: any[] = ['Mr.','Mrs.','Miss.','M/s.','Dr.'];
   previouspageData:any;

  constructor(private PostService: SliderService,private el: ElementRef,private datePipe: DatePipe, private FormBuilder: FormBuilder, private PngService: FormServiceService, public UserService: UserRegistrationService,
   public router: Router, private pageLoaderService: PageLoaderService,
   private activatedRoute: ActivatedRoute,) {

      this.activatedRoute.queryParams.subscribe((data: any) => {
        this.previouspageData = JSON.parse(data.data);
        // console.log('this.previouspageData',this.previouspageData);
        
      
      });

    }


 ngOnInit(): void {
  this.captchaGenerate()
  this.getAllHighlight()

  this.CustomerInfoForm = this.FormBuilder.group({
      flatNo: [''],
      floor: [''],
      plot: [''],
      wing: [''],
      Sector: [''],
      BuildingName: [''],
      SocietyName: [''],
      Colony: [''],
      RoadName: [''],
      Landkmark: [''],
      Location: [''],
      cityDist: [''],
      Pincode: [''],
      NameInitial: ['', [Validators.required]],
      Fname: ['', [Validators.required]],
      Mname: [''],
      lname: ['', [Validators.required]],
      FHusbandName: [''],
      Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
      MobileNo: ['', [Validators.required, Validators.pattern(/[0-9]{10}/)]],
      AltMobileNo: ['', [Validators.pattern(/[0-9]{10}/)]],
      Email: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      Captcha: ['', [Validators.required]],
      BPno: [''],
      CAno: ['']
     })

   this.CustomerInfoForm.get('MobileNo')?.setValue(this.previouspageData.otpmobileno)


   this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

 }

 ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

 getAllHighlight(){
    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
     this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
     })
    }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
   this.captcha = result
 }


 submitCustomerForm(){

 

   this.submitted = true
   this.loading = true;
    if (this.CustomerInfoForm.get('Captcha')?.value != this.captcha) {
      this.CustomerInfoForm.get('Captcha')?.setValue('')
    }
    if (this.CustomerInfoForm.invalid) {
       this.loading = false;
      return;
    }

  
   

      this.CustomerInfoForm.get('BPno')?.setValue(this.previouspageData.Partner)
      this.CustomerInfoForm.get('CAno')?.setValue(this.previouspageData.Contract_Account)
     let obj={"NameInitial":this.CustomerInfoForm.get('NameInitial')?.value,"Fname":this.CustomerInfoForm.get('Fname')?.value,"Mname":this.CustomerInfoForm.get('Mname')?.value,"lname":this.CustomerInfoForm.get('lname')?.value,"FHusbandName":this.CustomerInfoForm.get('FHusbandName')?.value,"Pan":this.CustomerInfoForm.get('Pan')?.value,"MobileNo":this.CustomerInfoForm.get('MobileNo')?.value,"AltMobileNo":this.CustomerInfoForm.get('AltMobileNo')?.value,"Email":this.CustomerInfoForm.get('Email')?.value,"BPno":this.CustomerInfoForm.get('BPno')?.value,"CAno":this.CustomerInfoForm.get('CAno')?.value,"existingCustName":this.previouspageData.Name,"FlatNo":this.previouspageData.Flat_No,"Floor":this.previouspageData.Floor,"Wing":this.previouspageData.Wing,"PlotNo":this.previouspageData.Plot_No,"BuildingName":this.previouspageData.Bldg_Name,"Colony":this.previouspageData.Colony,"RoadName":this.previouspageData.Road_name,"Location":this.previouspageData.Location,"LandMark":this.previouspageData.Land_Mark,"City":this.previouspageData.City,"Pin":this.previouspageData.Postal_code,"OutstandingAmt":this.previouspageData.OutstandingAmt,}

    this.PngService.postNameTransferForm(obj).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      
      //console.log(response)
      this.alertType = '3rdSubmit'
      if (data.Response) {
        // this.CustomerInfoForm.get('ApplicationId')?.setValue(data.details.ApplicationId)
       
        // this.UserService.ApplicationNo=data.details.ApplicationId
        // this.CustomerInfoForm.get('BPnoCAno')?.setValue(this.ValidationForm.get('BPnoCAno')?.value)

        // this.UserService.printNameTransfer = this.CustomerInfoForm.value
        // this.alertMsg = data.message+"| Application No." + data.details
         this.alertMsg = "Please fill up next level form"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
           this.loading = false;
        this.previouspageData.ApplicationId = data.details
        this.previouspageData.Fname = this.CustomerInfoForm.get('Fname')?.value
        this.previouspageData.Mname = this.CustomerInfoForm.get('Mname')?.value
        this.previouspageData.lname = this.CustomerInfoForm.get('lname')?.value
    //console.log('this.previouspageData',this.previouspageData)
        this.router.navigate(['/residential-png/customer-zone/name-transfer/apply-for-name-step-two'],
        {queryParams:{data:JSON.stringify(this.previouspageData)}})
          // this.printForm()
          // this.router.navigate(['/thank-you'])
        }, 1000);
      } else {
         this.loading = false;
        this.alertMsg = data.message
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
      }
    })
    
     

 }

}
