import { Component } from '@angular/core';

@Component({
  selector: 'app-retrofitment-faqs',
  templateUrl: './retrofitment-faqs.component.html',
  styleUrls: ['./retrofitment-faqs.component.css']
})
export class RetrofitmentFAQsComponent {

}
