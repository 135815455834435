import { Component } from '@angular/core';

@Component({
  selector: 'app-new-cngvehicle',
  templateUrl: './new-cngvehicle.component.html',
  styleUrls: ['./new-cngvehicle.component.css']
})
export class NewCNGVehicleComponent {

}
