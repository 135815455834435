import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SliderService {

  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }


  getAllSlide(){
    return this.http.get(this.baseUrl+'getAllSlide')
  }

  getAllNews(){
    return this.http.get(this.baseUrl+'getAllpublishNews')
  }

  getAllHighlight(){
    return this.http.get(this.baseUrl+'getAllpublishHighLight')
  }
  
  getAllTips(){
    return this.http.get(this.baseUrl+'tips/getAllTips')
  }
}
