import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormServiceService {


  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }

  postConversionForm(data:any){
    
    return this.http.post(this.baseUrl+'complaint/postCngConversion',data)
  }



  getNameTransferStatus(data:any){
    return this.http.post(this.baseUrl+'getNameTransferApplicationNo',data)
  }

  getNameTransferStatusbyBPno(data:any){
    return this.http.post(this.baseUrl+'getNameTransferbpNo',data)
  }

  getNameByBpnoAndContact(data:any){
    return this.http.post(this.baseUrl+'reg/getNameByBpAndMobile',data)
  }

  getNameByBpAndMobileNew(data:any){
    return this.http.post(this.baseUrl+'reg/getNameByBpAndMobileNew',data)
  }

  postFeedbackEnquiry(data:any){
    
    return this.http.post(this.baseUrl+'reg/postFeedbackEnquiry',data)
  }

  getAllReason(){
    return this.http.get(this.baseUrl+'getAllReason')
  }

  postContactForm(data:any){
    
    return this.http.post(this.baseUrl+'complaint/postContactUs',data)
  }


  postEnquiryForm(data:any){
    
    return this.http.post(this.baseUrl+'form/postEnquiry',data)
  }

  postNameTransferForm(data:any){
    
    return this.http.post(this.baseUrl+'addCustomerForm',data)
  }

  //  updatepostNameTransferForm(data:any){
    
  //   return this.http.post(this.baseUrl+'updateCustomerForm',data)
  // }

    updatepostNameTransferForm(data:any){
    
    return this.http.post(this.baseUrl+'updateCustomerFormNew',data)
  }

    updateCustomerFormThree(data:any){
    
    return this.http.post(this.baseUrl+'updateCustomerFormThree',data)
  }

   editCustomerFormone(data:any){
    
    return this.http.post(this.baseUrl+'editCustomerFormone',data)
  }

   getUserinfoforPrint(data:any){
    return this.http.post(this.baseUrl+'getUserinfoforPrint',data)
  }

  getnameTransCount(){
    return this.http.get(this.baseUrl+'getnameTransCount')
  }
}
