import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-ebillzproject',
  templateUrl: './ebillzproject.component.html',
  styleUrls: ['./ebillzproject.component.css']
})
export class EbillzprojectComponent {
   constructor(private activatedRoute: ActivatedRoute,
    private pageLoaderService: PageLoaderService) { 
   }

  ngOnInit(): void {
     this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
    //https://ebillz.in/t944guc 
      window.location.href = 'https://ebillz.in/' + id;
      console.log('Url Id: ', id);
    })
  }

}
