import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { WalkincentersService } from 'src/app/Service/walkincenters.service';


@Component({
  selector: 'app-walkin-center',
  templateUrl: './walkin-center.component.html',
  styleUrls: ['./walkin-center.component.css']
})
export class WalkinCenterComponent {
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
walkcenterArray: any[] = [];
  constructor(private pageLoaderService: PageLoaderService,private service: WalkincentersService) { }

  ngOnInit(): void {
    this. getData()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  getData() {

   this.service.getWalkincenters().subscribe(response => { 
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         this.walkcenterArray =res.details; 
        
       

      } else {
        this.walkcenterArray = [];
       
      }



    }, err => {
     // console.log(err)
    })

  }
}
