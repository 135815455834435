import { Component } from '@angular/core';

@Component({
  selector: 'app-processofloiissuance',
  templateUrl: './processofloiissuance.component.html',
  styleUrls: ['./processofloiissuance.component.css']
})
export class ProcessofloiissuanceComponent {

}
