import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.css']
})
export class BillingInfoComponent {

  data : any

  constructor(private router: Router,private UserService : UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.UserService.checkLogin()
    this.getSessionData()
  }

  checkLogin(){
    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    if(!data){
      this.router.navigate(['/login'])
    }
  }

  getSessionData(){
    let data = sessionStorage.getItem('UserCredential')
    if(data)
    this.data=JSON.parse(data)
    //console.log(this.data)
  }

}
