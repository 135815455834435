import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { logcomplaint } from 'src/app/Service/logcomplaint.service';
import { ComplaintItem } from 'src/app/models/complaint';
import { CategoryItem } from 'src/app/models/cat';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { Console } from 'console';
@Component({
  selector: 'app-logcomplaint',
  templateUrl: './logcomplaint.component.html',
  styleUrls: ['./logcomplaint.component.css']
})
export class LogcomplaintComponent {
  complaintForm!: FormGroup
  submitted = false
  complaintdata: ComplaintItem[] = []
  complaint: CategoryItem[] = []
  compdata: CategoryItem[] = []
  allFaq: any[] = []
  captcha: any
  files!: any;
  LpgSlip:any;

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'
  constructor(private FormBuilder: FormBuilder, private router: Router, private service: logcomplaint, public FaqService: FaqServiceService, private UserService: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

        this.UserService.checkLogin();
      this.complaintForm = this.FormBuilder.group({
      Type_of_complaint: ['', Validators.required],
      category: ['', Validators.required],
      complaint_message: ['', Validators.required],
        BpNo:[''],
        CaNo:[''],
        FirstName:[''],
      customername: [''],
	    Attacment:[''],
    email:[''],
    mobile_no:[''],
      Captcha: ['', [Validators.required]],

    });
    this.get();
    this.getComplaint();
    this.captchaGenerate()
  }

  reset() {
    this.complaintForm.get('Type_of_complaint')?.setValue('', {
      onlySelf: true
    })
    this.complaintForm.get('category')?.setValue('', {
      onlySelf: true
    })
	
    this.complaintForm.reset()
    this.compdata = []
    this.complaint = []
    setTimeout(() => {
      this.getComplaint()
    }, 300);

    this.submitted = false
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result
  }

  getComplaint() {
    this.service.getcomp().subscribe((res: any) => {
      this.complaint = res
     // console.log('this.complaintdata', this.complaint);
    })
  }
  
  
  // fileCheckForSlip(val:any){
  //   console.log(val.files)
  //   if(val.files[0].type==="application/pdf"|| val.files[0].type==="application/.doc"||val.files[0].type==="application/vnd.openxmlformats-officedocument.wordprocessingml.document/.docx" && val.files[0].size < (1 * 1024 * 1024)){
      
  //   }else{
  //     this.LpgSlip=null
  //     return alert('Please Select .pdf .doc or .docx')
  //   }
  // }

  filechange(element:any){
    this.files = element.target.files
   // console.log(this.files)
    }


  get() {
    let data = sessionStorage.getItem('UserCredential')
    if (data) {
      var demo = JSON.parse(data)
      this.service.getAll(demo.CaNo).subscribe((res: any) => {
        this.complaintdata = res
        //console.log(this.complaintdata)
      })
    }
  }

  getAllFaq() {
    this.FaqService.getAllFaq().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      //console.log(data)
      this.allFaq = data
    })
  }

   onSubmit() {
        this.submitted = true
        if (this.complaintForm.get('Captcha')?.value != this.captcha) {
          this.complaintForm.get('Captcha')?.setValue('')
        }

        this.captchaGenerate();
        if(this.complaintForm.invalid){
          return;
        }	
		let data = sessionStorage.getItem('UserCredential')
        if (data) {
        var demo = JSON.parse(data)
        //this.complaintForm.get('Attacment')?.setValue(this.files)
        this.complaintForm.get('BpNo')?.setValue(demo.BpNo)
        this.complaintForm.get('CaNo')?.setValue(demo.CaNo)
        this.complaintForm.get('FirstName')?.setValue(demo.FirstName)
        this.complaintForm.get('email')?.setValue(demo.Email)
        this.complaintForm.get('mobile_no')?.setValue(demo.Mobile)
        }
        // this.complaintForm.get('Attacment')?.setValue(this.files)
        // console.log(this.complaintForm.value)
        if(this.files ==undefined ){
          this.service.insertwithoutimage(this.complaintForm.value).subscribe(response=>{
            let data = JSON.parse(JSON.stringify(response))
            this.alertType='submit'
              this.alertMsg = "Thank You For Complaint"
              this.alertColor = 'rgba(4, 183, 107, 0.2)'
              this.alertTextColor = '#038b51'
              this.alertBorder = '1px solid #04a862;'                      
              setTimeout(() => {
                this.alertType=''
                this.complaintForm.reset()
                this.submitted = false
                this.router.navigate(['/residential-png/customer-zone/view-complaint'])
              }, 1500);
            })  

          

        }else{
         let data = sessionStorage.getItem('UserCredential')
        if (data) {
        var demo = JSON.parse(data)
        const form = new FormData()
       // console.log(this.files)
        form.append("Type_of_complaint",this.complaintForm.get('Type_of_complaint')?.value)
        form.append("category",this.complaintForm.get('category')?.value)
        form.append("complaint_message",this.complaintForm.get('complaint_message')?.value) 
        form.append("BpNo",demo.BpNo)
        form.append("CaNo",demo.CaNo)
        form.append("FirstName",demo.FirstName)
        form.append("email",demo.Email)
        form.append("Mobile",demo.Mobile)
        form.append("Attacment",this.files[0],this.files[0].name)  
        //console.log("running")      
        this.service.insertcomplaint(form).subscribe(response=>{
          let data = JSON.parse(JSON.stringify(response))
          this.alertType='submit'
            this.alertMsg = "Thank You For Complaint"
            this.alertColor = 'rgba(4, 183, 107, 0.2)'
            this.alertTextColor = '#038b51'
            this.alertBorder = '1px solid #04a862;'
                              
            setTimeout(() => {
              this.alertType=''
              this.complaintForm.reset()
              this.submitted = false
              this.router.navigate(['/residential-png/customer-zone/view-complaint'])
            }, 1500);
          
          if(data){
            
          }
        })
        
        
      }
    
    }
  }



  getComp(Type_of_complaint: string) {
    this.service.getcomplaint(Type_of_complaint).subscribe((res: any) => {
      this.compdata = res
      //console.log('this.compdata', this.compdata);

    })
  }

  complaintChange(event: any) {
    this.complaintForm.get('Type_of_complaint')?.setValue(event.target.value, {
      onlySelf: true
    })
    this.getComp(event.target.value)
  }

  categoryChange(event: any) {
    this.complaintForm.get('category')?.setValue(event.target.value, {
      onlySelf: true
    })
  }
}
