<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Security Deposit <span> </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a  >MGL Corporate</a></li>
                <li class=""><a  >Contracts & Procurements </a></li>
                <li class=""><a  >Downloads (Forms/Formats) </a></li>
                <li class="active" aria-current="page">Security Deposit </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->

<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                    <!-- <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 d-block">View All <i
                                        class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>

                    </div> -->


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">



                    <div class="light-blue-bg mb-30">
                        


                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Bank Guarantee format for Material
                                </div>
                                <a href="assets/images/pdf/_24_24_bg_po_16da92293e.pdf" target="_blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="light-blue-bg ">

                        <h3>Bank Guarantee format for Contracts</h3>
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Bank Guarantee Format For Contracts Other Than Dispensing Contracts
                                </div>
                                <a href="assets/images/pdf/_BG_Format-Contracts-Except-Dispensing-Contracts_c4bc9439dd.pdf" target="_blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Bank Guarantee Format For Dispensing Contracts
                                </div>
                                <a href="assets/images/pdf/_BG-Format_For_Dispensing_Contracts_8d20db4f70.pdf" target="_blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Bank Guarantee format for Earnest Money Deposit
                                </div>
                                <a href="assets/images/pdf/_BG-format_for_Earnest_Money_Deposit_fa024104cb.pdf" target="_blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>