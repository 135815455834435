import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-our-approachto-sustainability',
  templateUrl: './our-approachto-sustainability.component.html',
  styleUrls: ['./our-approachto-sustainability.component.css']
})
export class OurApproachtoSustainabilityComponent {
  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }
}
