import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-important-policy',
  templateUrl: './important-policy.component.html',
  styleUrls: ['./important-policy.component.css']
})
export class ImportantPolicyComponent {

  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
