<div class="innerpage-banner">
   <img src="assets/images/innerpage-banner/awards-banner.jpg">
   <div class="banner-heading">
      <h1>Thank You 
      </h1>
      <div class="breadcrumbs">
         <ul class="">
            <li class=""><a [routerLink]="['/']">Home</a></li>
            
            <li class="active" aria-current="page">Thank You</li>
         </ul>
      </div>
   </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<div class="innerpage-content-section">
   <div class="container custom">
   	<div class="light-blue-bg text-center">
   		<h2 class="dual-heading mb-30">
             
   			Thank you for filling the form!  <span>Your Certificate send on your email id</span></h2>
   			 <div class="mt-30 mb-30">
            <img src="assets/images/thank-your-application-graphic.png">
         </div> 
         <a (click)="printForm()" class="greenbtn2 mr-10">Download Certificate  <i class="demo-icon uil-file-check"></i></a>
   		<a [routerLink]="['/']" class="bluebtn2">Back to Home  <i class="demo-icon uil-home-alt"></i></a>
   	</div>
   	</div>
   </div>