<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Vision</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a href="#">MGL Corporate</a></li>
                <li class="active" aria-current="page">Vision</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
					<div class="mt-30 mb-30">
                        <!-- <h3>Vision</h3> -->
                        <h6>Our Vision</h6>
                        <p>To be a World Class, Consumer & Environment Friendly, Employer of Choice; committed to provide Safe, Efficient and Reliable energy; while creating Value for all our Stakeholders.</p>
                </div>
                <div class="img text-center"><img src="assets/images/vision-info.jpg"></div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->