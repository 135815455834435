import { Injectable } from '@angular/core';
import { map, Observable} from 'rxjs';
import { CarrierResp } from '../models/carrier';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CarrierjobapplyService {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getgender():Observable<CarrierResp[]> {
    return this.http.get<CarrierResp []>(this.baseUrl+'gender/gender');
  }
  
  //insertcarrier(data:any) {
  //  this.http.post<CarrierResp>(`${this.baseUrl}Applyforjob/insert_applycarrierjob`,data).subscribe(()=>{
  //    return alert("inserted  successfully")
  //  })
      
   //   }

insertcarrier(data:any) {
   return  this.http.post<CarrierResp>(`${this.baseUrl}Applyforjob/insert_applycarrierjob`,data)
    }
      






}
