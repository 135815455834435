<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Feedback/Enquiry Form</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a  [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a>Customer Care</a></li>
                <li class="active" aria-current="page">Feedback/Inquiry Form</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                   
                  <app-news-comp></app-news-comp>

                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

                            <div class="customer-login-page log-meter-reading">

                                <form [formGroup]="UserForm">
                                    
                                        <div class="mb-20">
                                        <div class="row">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input onKeyPress="if(this.value.length==12) return false;" type="text" class="form-control" formControlName="BPno"
                                                        placeholder="BPno" [ngClass]="{ 'is-invalid': GoSubmitted && UserForm.controls['BPno'].errors }" id="BPno" />
                                                    <div *ngIf="GoSubmitted  && UserForm.controls['BPno'].errors" class="invalid-feedback">
                                                        <div *ngIf="GoSubmitted  && UserForm.controls['BPno'].errors"> Required Field</div>
                                                    </div>
                                                    <label for="Contact">BP No<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input  type="text" class="form-control" formControlName="MobileNo"
                                                        placeholder="MobileNo" [ngClass]="{ 'is-invalid': GoSubmitted  && UserForm.controls['MobileNo'].errors }" id="MobileNo" />
                                                    <div *ngIf="GoSubmitted  && UserForm.controls['MobileNo'].errors" class="invalid-feedback">
                                                      <div *ngIf="GoSubmitted  && UserForm.controls['MobileNo'].errors"> Required Field</div>
                                                    </div>
                                                    <label for="Contact">Mobile No<span  style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-lg-6 col-md-6 col-sm-6 mb-3">
                                                <button (click)="Go()" class="greenbtn2">Go <i  class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                            <div *ngIf="alertType == 'Go'"
                                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                class="card card-inverse-info" id="context-menu-simple">
                                                <div class="card-body">
                                                    <p class="card-text">
                                                        {{alertMsg}}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="mb-30"><div class="border-single"></div></div>

                                        <div class="row clearfix">
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="CustName"
                                                        placeholder="CustName"
                                                        [ngClass]="{ 'is-invalid': submited && UserForm.controls['CustName'].errors }"
                                                        id="CustName" />
                                                    <div *ngIf="submited && UserForm.controls['CustName'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submited && UserForm.controls['CustName'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Customer Name</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input 
                                                        type="email" class="form-control" formControlName="Email"
                                                        placeholder="Email"
                                                        [ngClass]="{ 'is-invalid': submited && UserForm.controls['Email'].errors }"
                                                        id="Email" />
                                                    <div *ngIf="submited && UserForm.controls['Email'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submited && UserForm.controls['Email'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Email<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>


                                        </div>



                                            <div class="row">

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <!-- <label>Matter Related To</label> -->
                                            <div class="mb-30 form-floating mt-10">
                                                <select [ngClass]="{'is-invalid':submited && UserForm.controls['Matter'].errors}"
                                                    #matter  (change)="this.UserForm.get('Matter')?.setValue(matter.value)" class="form-select" aria-label="Floating label select example" required>
                                                    <option value="" selected>Matter Related To</option>
                                                    <option *ngFor="let i of Data" value="{{i}}">{{i}}</option>

                                                </select>
                                                 <label for="floatingSelectGrid">Matter Related To<span
                                                    style="color: crimson;">*</span></label>

                                                <div *ngIf="submited && UserForm.controls['Matter'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Matter'].errors">Matter is required
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Please Rate the Services of MGL<span
                                                    class="text-danger">*</span></label>
                                            <div class="mb-20">
                                                <div class="row service-label">
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="html"
                                                            name="fav_language" value="Excellent" class="form-check-input">
                                                        <label for="html" class="">Excellent</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="css"
                                                            name="fav_language" value="Very Good" class="form-check-input">
                                                        <label for="css" class="">Very Good</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="javascript"
                                                            name="fav_language" value="Average" class="form-check-input">
                                                        <label for="javascript" class="">Average</label>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="javascript"
                                                            name="fav_language" value="Bad" class="form-check-input">
                                                        <label for="javascript" class="">Bad</label>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                        <input formControlName="Rating" type="radio" id="nocomment"
                                                            name="fav_language" value="No Comments" class="form-check-input">
                                                        <label for="nocomment" class="">No Comments</label>
                                                    </div>
                                                    </div>
                                                    <div *ngIf="submited && UserForm.controls['Rating'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm.controls['Rating'].errors">Rating is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                             </div>


                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <div class="form-floating">
                                                    <textarea type="text" class="form-control"
                                                        formControlName="Feedback"
                                                        placeholder="Feedback(Max limit 2500 characters)"
                                                        [ngClass]="{ 'is-invalid': submited && UserForm.controls['Feedback'].errors }"
                                                        id="Feedback"></textarea>
                                                    <div *ngIf="submited && UserForm.controls['Feedback'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="submited && UserForm.controls['Feedback'].errors">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="Contact">Feedback(Max limit 2500 characters)<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                               <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                            <div class="form-floating mt-10">
                                                <div class="captcha-text">{{this.captcha}} <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i> </div>
                                            </div>
                                          </div>
                                            <div class="col-lg-7 col-md-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha" placeholder="Captcha" [ngClass]="{ 'is-invalid': submited && UserForm.controls['Captcha'].errors }" id="Captcha" />
                                                    <div *ngIf="submited && UserForm.controls['Captcha'].errors" class="invalid-feedback">
                                                        <div *ngIf="submited && UserForm.controls['Captcha'].errors"> Required Field</div>
                                                    </div>
                                                    <label for="Contact">Captcha<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                            </div>
                                             </div>
                                        </div>
                                        <div class="mt-20">
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div *ngIf="alertType == 'login'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                </form>

                                <div class="text-right">
                                    <!-- <p  class="mt-30 mb-0">It's time to #switchtoMGLeCNG</p> -->
                                    <p class="mt-10 mb-0"><i><b>Please note all fields marked <span class="text-danger">*</span> are compulsory.</b></i></p>

                                </div>


                            </div>

                        </div>
                    </div>




                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>