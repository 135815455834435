import { Component, OnInit} from '@angular/core';
import { first } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OuletItem } from 'src/app/models/outlet';
import { AreaItem } from 'src/app/models/area';
import { CnglocatorService } from 'src/app/Service/cnglocator.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-cng-fillingstation',
  templateUrl: './cng-fillingstation.component.html',
  styleUrls: ['./cng-fillingstation.component.css']
})
export class CngFillingstationComponent {
  outletdata: OuletItem[] = [];
  areadata: AreaItem[] = [];
  locationdata: AreaItem[] = [];
  cityselected: any = ''
  searchForm!: FormGroup;
  safeSrc: SafeResourceUrl = "";
  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    //mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 1,
  };
  markers: any[] = [];
  name: any;
  contact: any;

  constructor(private service: CnglocatorService, private FormBuilder: FormBuilder, private pageLoaderService: PageLoaderService, public sanitizer:DomSanitizer) { }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=51.678418,7.809007&key=AIzaSyAVn6ea2iJcMq9Wp0pKGlr3RpA8SVK1MCM&hl=es&z=14&output=embed");
  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.searchForm = this.FormBuilder.group({
      Location: ['', Validators.required],
      AreaName: ['', Validators.required],
    })

    this.getlocations();
    this.getcng();

    this.center = {
      lat: 19.0760,
      lng: 72.8777,
    };
  }

  getlocations() {
    this.service.getlocation().subscribe((res: any) => {
      this.locationdata = res
      // console.log(res)
      // console.log('this.locations', this.locationdata);
    })
  }

  getArea(locations: string) {
    this.service.getarea(locations).subscribe((res: any) => {
      this.areadata = res
      //console.log('this.areadata', this.areadata);

    })
  }
  
  getcng() {
    var Location = this.searchForm.controls['Location'].value
    var AreaName = this.searchForm.controls['AreaName'].value
    this.outletdata = []
    this.service.getoutlet(Location, AreaName).subscribe(response => {
      var data = JSON.parse(JSON.stringify(response));
      let filtered = data.details.filter((x: any) => {
        return x.IsDeleted !== 'true'
      })
      this.outletdata = filtered;
	  this.markers = [];
      for (let i=0; i<this.outletdata.length; i++){
        this.markers.push({
          position: {
            lat: Number(this.outletdata[i].Latitude),
            lng: Number(this.outletdata[i].Longitude),
          },
          
          title: this.outletdata[i].OutletName,
          options: { animation: google.maps.Animation.DROP },
        });
      }
     // console.log(filtered)
    })
  }

  // location(x: any) {
  //   this.latitude = x.coords.lat;
  //   this.longitude = x.coords.lng;
  // }

  Change(event: any) {
    this.cityselected = event.target.options[event.target.options.selectedIndex].text;
    this.getArea(this.cityselected)
  }

  showMap(lat: any, lng: any, name: any, contact: any){
    this.name = name;
    this.contact = contact;
    this.center = {
      lat: Number(lat),
        lng: Number(lng),
    };
    this.markers = [];
    this.markers.push({
      position: {
        lat: Number(lat),
        lng: Number(lng),
      },
      
      title: name,
      options: { animation: google.maps.Animation.DROP },
    });
  }
}
