import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MenuService {

  baseUrl:string=environment.baseUrl

  dataMap = new Map<string, string[]>([
    // ["Residential PNG", ["Apple", "Orange", "Banana"]],
    // ["Vegetables", ["Tomato", "Potato", "Onion"]],
    // ["Apple", ["Fuji", "Macintosh"]],
    // ["Onion", ["Yellow", "White", "Purple"]],
    // ["Macintosh", ["Yellow", "White", "Purple"]],
  ]);

  newMap = new Map<string, string[]>()

  // this can also come from database as initial data
  rootLevelNodes: string[] = ["CNG"];

  constructor(private http : HttpClient) { }

  getChildren(node: string) {
    // adding delay to mock a REST API call
    this.getAllMenu()
    //console.log(this.dataMap.get(node))
    return of(this.dataMap.get(node));
  }

  getAllMenus(){

    this.http.get('http://localhost:3000/getAllMenu').subscribe((response:any)=>{
      let data = JSON.parse(JSON.stringify(response))

      for(let i of data){
        if(i.SubMenu !== ''){
          this.dataMap.set(i.SubMenu[0],i.SubMenu[1])
        }
        
      }

      //console.log(this.dataMap)

      
    })

  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);

  }

  getAllMenu(){
    return this.http.get(this.baseUrl+'getAllDbMenu')
  }

  postMenu(data:any){
    this.http.post(this.baseUrl+'checkMenu',data).subscribe(response=>{
      if(response==="Found"){
        return alert("Already Present")
      }else{
        this.http.post(this.baseUrl+'addSubMenu',data).subscribe(response=>{
          return alert(response)
        })

      }
    })
    
  }

  getPdfByCategory(data:any){
    return this.http.post(this.baseUrl+'pdf/getPdfByCategory',data)
  }
}
