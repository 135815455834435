import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from "@angular/router"
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UpiautopaymandateService {
  baseUrl:string=environment.baseUrl;
   constructor(private http : HttpClient, private router: Router) { 
    
  }

   RegUpiautoMandate(data:any){
    console.log('service',data)
    return this.http.post(this.baseUrl+'RegUpiautoMandate',data)
  }
}
