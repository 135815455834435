<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Apply for <span>Commercial Use </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Industrial & Commercial   </a></li>
                <li class=""><a  >Commercial PNG</a></li>
                <li class=""><a>Apply For Commercial Connection</a></li>
                <li class="active" aria-current="page">Apply for Commercial Use </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <div class="box882_left">

                            <div class="con center">
                                <h6>The procedure to apply for Commercial PNG</h6>
                                <div class="apply-commercial"><img class="big"
                                        src="assets/images/commercial-use-big.png"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>