import { Component } from '@angular/core';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-customer-billing-history',
  templateUrl: './customer-billing-history.component.html',
  styleUrls: ['./customer-billing-history.component.css']
})
export class CustomerBillingHistoryComponent {
  data: any;
  allData: any[] = []

  constructor(public DashboardService:DashboardServiceService,private UserService: UserRegistrationService, private CustomerService: DashboardServiceService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    this.getSessionData()
    this.UserService.checkLogin()
    this.getBillingHistory()
  }

  getBillingHistory() {
     var CaNotext = crypto.AES.encrypt(this.data.CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
      var BpNotext = crypto.AES.encrypt(this.data.BpNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
    this.CustomerService.getBillHistoryNew(CaNotext, BpNotext).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response));
      this.allData = data.details
      //console.log(this.allData)
    })
  }

  getSessionData() {
    let data = sessionStorage.getItem('UserCredential')
    if (data)
      this.data = JSON.parse(data)
   // console.log(JSON.parse(data!).CaNo)
  }
}
