import { Component } from '@angular/core';

@Component({
  selector: 'app-png-network',
  templateUrl: './png-network.component.html',
  styleUrls: ['./png-network.component.css']
})
export class PngNetworkComponent {

}
