import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';
  //import { vehiclepromotionResp } from '../models/vehiclepromotion';

@Injectable({
  providedIn: 'root'
})
export class WalkincentersService {
 baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

   getWalkincenters(){

      return this.http.post<any>(this.baseUrl+'getWalkincenters',{})
    }

  

}