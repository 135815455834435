import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-about-mgl',
  templateUrl: './about-mgl.component.html',
  styleUrls: ['./about-mgl.component.css']
})
export class AboutMglComponent implements OnInit {
 networkData:any[]=[];
   pngRate:any;
    Industrialestablishments:any;
    commercialestablishments:any;
      Fillingstation:any;
    Dispensingpoints:any;
    NMMTBuses:any;
    PrivateBuses:any;
    Vehicles:any;
    Taxis:any;
    Rickshaws:any;
    Cars:any;
    Steelpipeline:any;
    PEpipeline:any;
  constructor(private pageLoaderService: PageLoaderService,private service: UserRegistrationService,) { }

  ngOnInit(): void {
    this.mglNetworklist();
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

    mglNetworklist(){

    this.service.mglNetworklist().subscribe((res: any) => {
      let data = JSON.parse(JSON.stringify(res))

     // console.log(data)

       this.networkData = data.details;
       //console.log('this.networkData',this.networkData)

            for (var i = 0; i < this.networkData.length; i++) {
              if(this.networkData[i].titles=='PNG'){
                 this.pngRate=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Industrial establishments'){
                 this.Industrialestablishments=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Small commercial establishments'){
                 this.commercialestablishments=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Filling station'){
                 this.Fillingstation=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='Dispensing points'){
                 this.Dispensingpoints=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='BEST / TMT / MSRTC / NMMT Buses'){
                 this.NMMTBuses=this.networkData[i].rates;
              }
               if(this.networkData[i].titles=='LCV / Tempos / Trucks / Private Buses'){
                 this.PrivateBuses=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Vehicles'){
                 this.Vehicles=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Taxis'){
                 this.Taxis=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Rickshaws'){
                 this.Rickshaws=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Cars'){
                 this.Cars=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='Steel pipeline'){
                 this.Steelpipeline=this.networkData[i].rates;
              }
              if(this.networkData[i].titles=='PE pipeline'){
                 this.PEpipeline=this.networkData[i].rates;
              }

           

            }

       // if(this.networkData.titles=='PNG'){
       //      this.pngRate = this.networkData.rates;
       //      console.log(this.pngRate)

       // }
      
  })

  }


}
