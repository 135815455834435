import { Component } from '@angular/core';

@Component({
  selector: 'app-applyfornametstepfour',
  templateUrl: './applyfornametstepfour.component.html',
  styleUrls: ['./applyfornametstepfour.component.css']
})
export class ApplyfornametstepfourComponent {

}
