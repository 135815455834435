<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Corporate <span>Governance </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Investors</a></li>
                <li class="active" aria-current="page">Corporate Governance</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <div *ngFor="let i of allData" class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    {{i.Title}}
                                </div>
                                <a href="{{baseUrl}}{{i.PdfFile}}" target="_Blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>

                     <h6>Details of the Composition of Board of MGL</h6>

                    <div class="composition_table">
                        <ul>
                            <li class="composition-box"><span class="Committee-Members">Name</span>
                                <span class="Designation">Designation</span></li>
                           
                           <!--  <div class="relative-box"> -->
                                <li>
                                    <span class="Committee-Members">Mr. Sandeep Kumar Gupta</span>
                                    <span class="Designation">Chairman, Non- Executive Non- Independent Director</span>
                                </li>
                                <li>
                                    <span class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Managing Director</span></li>
                                <li><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Deputy Managing Director</span></li>
                                <li><span
                                        class="Committee-Members">Mr. Harshadeep Kamble</span><span
                                        class="Designation">Nominee Director</span></li>
                                <li><span
                                        class="Committee-Members">Mrs. Malvika Sinha</span><span
                                        class="Designation">Independent Director</span></li>
                                        <li><span class="Committee-Members">Mr. Syed S. Hussain</span><span
                                        class="Designation">Independent Director</span></li>
                                         <li><span class="Committee-Members">Mr. Harish Kumar Agarwal</span><span
                                        class="Designation">Independent Director</span></li>
                                         <li><span class="Committee-Members">Mr. Mahesh Kumar Gupta</span><span
                                        class="Designation">Independent Director</span></li>
                          <!--   </div> -->
                           
                        </ul>
                    </div>

                    <h6>Details of the composition of the committees of the Board of MGL</h6>

                    <div class="composition_table">
                        <ul>
                            <li class="composition-box"><span class="Names-Committees">Names of the
                                    Committees</span><span class="Committee-Members">Committee Members</span><span
                                    class="Designation">Designation</span></li>
                            <div class="relative-box"><strong>
                                    <div><big>Audit Committee</big></div>
                                </strong>
                                <li><span class="Names-Committees">Audit Committee</span><span
                                        class="Committee-Members">Mr. Harish Kumar Agarwal</span><span
                                        class="Designation">Chairman</span></li>
                                <li><span class="Names-Committees">Audit Committee</span><span
                                        class="Committee-Members">Mr. Syed S. Hussain</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Audit Committee</span><span
                                        class="Committee-Members">Mrs. Malvika Sinha</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Audit Committee</span><span
                                        class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Audit Committee</span><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Permanent Invitee</span></li>
                            </div>
                            <!-- <div class="relative-box"><strong>
                                    <div><big>Board Composition</big></div>
                                </strong>
                                <li><span class="Names-Committees">Board Composition</span><span
                                        class="Committee-Members">Mr. Sandeep Kumar Gupta</span><span
                                        class="Designation">Chairman, Non- Executive Non- Independent Director</span></li>
                                <li><span class="Names-Committees">Board Composition</span><span
                                        class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Managing Director</span></li>
                                <li><span class="Names-Committees">Board Composition</span><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Deputy Managing Director</span></li>
                                <li><span class="Names-Committees">Board Composition</span><span
                                        class="Committee-Members">Mr. Harshadeep Kamble</span><span
                                        class="Designation">Nominee Director</span></li>
                                <li><span class="Names-Committees">Board Composition</span><span
                                        class="Committee-Members">Mrs. Malvika Sinha</span><span
                                        class="Designation">Independent Director</span></li>
                                        <li><span class="Names-Committees">Board Composition</span><span class="Committee-Members">Mr. Syed S. Hussain</span><span
                                        class="Designation">Independent Director</span></li>
                                         <li><span class="Names-Committees">Board Composition</span><span class="Committee-Members">Mr. Rajeev Bhaskar Sahi</span><span
                                        class="Designation">Independent Director</span></li>
                                         <li><span class="Names-Committees">Board Composition</span><span class="Committee-Members">Mr. Venkataraman Srinivasan</span><span
                                        class="Designation">Independent Director</span></li>
                            </div> -->
                            <div class="relative-box"><strong>
                                    <div><big>Nomination and Remuneration Committee</big></div>
                                </strong>
                                <li><span class="Names-Committees">Nomination and Remuneration Committee</span><span
                                        class="Committee-Members">Mr. Syed S. Hussain</span><span
                                        class="Designation">Chairman</span></li>
                                <li><span class="Names-Committees">Nomination and Remuneration Committee</span><span
                                        class="Committee-Members"> Mr. Sandeep Kumar Gupta</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Nomination and Remuneration Committee</span><span
                                        class="Committee-Members">Mr. Mahesh Kumar Gupta</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Nomination and Remuneration Committee</span><span
                                        class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Permanent Invitee</span></li>
                                <li><span class="Names-Committees">Nomination and Remuneration Committee</span><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Permanent Invitee</span></li>
                            </div>
                            <div class="relative-box"><strong>
                                    <div><big>Corporate Social Responsibility Committee</big></div>
                                </strong>
                                <li><span class="Names-Committees">Corporate Social Responsibility Committee</span><span
                                        class="Committee-Members">Mrs. Malvika Sinha</span><span
                                        class="Designation">Chairperson</span></li>
                                <li><span class="Names-Committees">Corporate Social Responsibility Committee</span><span
                                        class="Committee-Members">Mr. Syed S. Hussain</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Corporate Social Responsibility Committee</span><span
                                        class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Corporate Social Responsibility Committee</span><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Member</span></li>
                            </div>
                            <div class="relative-box"><strong>
                                    <div><big>Stakeholders Relationship Committee</big></div>
                                </strong>
                                <li><span class="Names-Committees">Stakeholders Relationship Committee</span><span
                                        class="Committee-Members">Mr. Syed S. Hussain</span><span
                                        class="Designation">Chairman</span></li>
                                <li><span class="Names-Committees">Stakeholders Relationship Committee</span><span
                                        class="Committee-Members">Mr. Harish Kumar Agarwal</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Stakeholders Relationship Committee</span><span
                                        class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Stakeholders Relationship Committee</span><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Member</span></li>
                            </div>
                            <div class="relative-box"><strong>
                                    <div><big>Risk Management Committee</big></div>
                                </strong>
                                <li><span class="Names-Committees">Risk Management Committee</span><span
                                        class="Committee-Members">Mr. Mahesh Kumar Gupta</span><span
                                        class="Designation">Chairman</span></li>
                                <li><span class="Names-Committees">Risk Management Committee</span><span
                                        class="Committee-Members">Mr. Harish Kumar Agarwal</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Risk Management Committee</span><span
                                        class="Committee-Members">Mr. Ashu Shinghal</span><span
                                        class="Designation">Member</span></li>
                                <li><span class="Names-Committees">Risk Management Committee</span><span
                                        class="Committee-Members">Mr. Sanjay Shende</span><span
                                        class="Designation">Member</span></li>
                            </div>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>