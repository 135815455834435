import { Component } from '@angular/core';

@Component({
  selector: 'app-payuwalletthankyou',
  templateUrl: './payuwalletthankyou.component.html',
  styleUrls: ['./payuwalletthankyou.component.css']
})
export class PayuwalletthankyouComponent {

}
