import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FaqServiceService {

  allFaq: any[] = []
  backupData: any[] = []
  title: any = 'All FAQS'
  baseUrl: string = environment.baseUrl

  constructor(private http: HttpClient) { }

  public ngOnInit(): void {

  }

  getAllFaq() {
    return this.http.get(this.baseUrl + 'faqd/get_faqd')
  }

  getAllFaqDetail() {
    return this.http.get(this.baseUrl + 'faq/get_faq')
  }

  getAllFaqs() {
    this.allFaq = []
    this.getAllFaq().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      //console.log(data)
      this.allFaq = data
      this.backupData = data
    })
  }

  filterFaq(val: any) {
    console.log(val)
    this.allFaq = []

    if (val === '') {
      this.getAllFaqs()
      this.title = "All FAQS"
    } else {
      this.getAllFaq().subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
        //console.log(data)

        let filtered = data.filter((x: any) => {
          return x.caption == val
        })
        console.log(filtered)
        this.allFaq = filtered
        if (this.allFaq.length > 0) {
          this.title = this.allFaq[0].caption
        } else {
          this.title = "All FAQS"
          this.getAllFaqs()
        }
      })
    }
    console.log(this.allFaq)
  }
}
