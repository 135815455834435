<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Overview</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
				<li class=""><a>Residential PNG</a></li>
				<!-- <li class=""><a>Domestic Connection Request</a></li> -->
				<li class=""><a>Customer Support</a></li>
                <li class="active" aria-current="page"><a href="#">Overview
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <!-- Tips -->
                <app-tips></app-tips>

                
                <div class="mt-30">
                    
                 <app-news-comp></app-news-comp>

                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content xs-mt-30">
                    <!-- <p >MGL is the first City Gas Distribution Company <span>to have an SAP
                        based Customer Care Module,</span></p>
                    <p>which is easily accessed across all its offices in and around Mumbai. </p> -->
                    <p>We, at MGL believe in sustainable growth through quality and are committed to provide safe and
                        un-interrupted supply of Natural Gas at competitive price to our valued customers.</p>
                   

                    <p>In its pursuit of providing excellent service to its customers,MGL has taken several
                        customer-friendly initiatives such as:</p>


                        
                    <div class="light-blue-bg mt-30 customer-care-overview-icon">
                        <div id="rs-services" class="rs-services style4">

                            <div class="row">
                                <div class="col-lg-3 col-md-6 md-mb-30">
                                    <div class="services-item text-center">
                                        <div class="services-icon">
                                            <img class="dance_hover"
                                                src="assets/images/careers/icons/customer-care-icon.png"
                                                alt="Customer Care">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Customer Care</h3>
                                            <p class="margin-0"> A well-equipped Customer Care Centre to cater to
                                                customer requirements. Competent and adequately trained personnel are
                                                deployed to respond to customer's requirements.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 md-mb-30">
                                    <div class="services-item text-center">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/icons/location.png"
                                                alt="Distribution Company">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Distribution Company</h3>
                                            <p class="margin-0"> First City Gas Distribution Company to have a SAP based
                                                Customer Care Module, which is easily accessed across all its offices in
                                                and around Mumbai.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 sm-mb-30">
                                    <div class="services-item text-center">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/icons/smartphone.png"
                                                alt="MGL Connect">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL Connect</h3>
                                            <p class="margin-0"> MGL Connect, a mobile app available on Google PlayStore
                                                free of cost to enhance consumer service.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="services-item text-center">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/icons/shareicon.png"
                                                alt="MGL Social Media">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Social Media</h3>
                                            <p class="margin-0"> Presence on social media: Facebook,Twitter, Instagram and YouTube.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-30">Apart from the above we have multiple channels of communication for our
                                valued customers:</p>
                        </div>
                    </div>

                    <div class="customer-care-overview-call mt-30 mb-30">
                        <div class="heading-sect">
                            <i class="demo-icon uil-phone-volume"></i>
                            <h6>Call us on</h6>
                        </div>
                        <div class="description-sect">
                            <span>(022) 68674500 / (022) 61564500</span><br>
                            Our Customer Helpline is available 24*7<!--operative from 8:00 hrs. - 22:00 hrs. daily.-->
                        </div>
                    </div>
                    <div class="customer-care-overview-call mt-30 mb-30">
                        <div class="heading-sect">
                            <i class="fa fa-whatsapp"></i>
                            <h6>WhatsApp Number</h6>
                        </div>
                        <div class="description-sect">
                            <span><a href="https://wa.me/message/TKTPMXSNNNPLN1" target="_blank">+919899203843</a></span><br>
                        </div>
                    </div>

                    <div class="customer-care-overview-call mt-30 mb-30">
                        <div class="heading-sect">
                            <i class="demo-icon uil-location-pin-alt"></i>
                            <h6><a [routerLink]="['/residential-png/customer-care/walk-in-center']">Walk-in Centers</a></h6>
                        </div>
                        <div class="description-sect">
                            Customers can visit <b>Andheri(W), Bandra, Borivali, Grand Road, Kalyan, Mira Road, Nahur, Sanpada, Thane and Uran offices between 9.30 am and 5.30 pm.</b> Except on Sunday & Public Holidays.
                    </div>
                    </div>

                    <div class="customer-care-overview-call mt-30 mb-30">
                        <div class="heading-sect">
                            <i class="demo-icon uil-envelope-edit"></i>
                            <h6>Write to us at</h6>
                        </div>
                        <div class="description-sect">
                            <a href="mailto:support@mahanagargas.com">support@mahanagargas.com</a>
                        </div>
                    </div>

                    <div class="customer-care-overview-call mt-30 mb-30">
                        <div class="heading-sect">
                            <i class="demo-icon uil-mobile-vibrate"></i>
                            <h6>Emergency Contact</h6>
                        </div>
                        <div class="description-sect">
                            In case of emergencies like gas leakage or fire call our 24 hours Emergency Helpline number
                            <br>
                            <span> 18002669944 (Toll free) / (022)-68759400 / (022) -24012400</span>
                        </div>
                    </div>

                    <div class="customer-care-overview-call mt-30 mb-30">
                        <div class="heading-sect">
                            <i class="demo-icon uil-mobile-vibrate"></i>
                            <h6>For Unresolved Issues Please Contact</h6>
                        </div>
                        <div class="description-sect">
                            <div class="mb-10"><b>Nodal Office:</b></div>
                            
                            <div class="description-sect">
                            Mahanagar Gas Limited, Pay and Accounts Building, Ground Floor, Near Family Court, Bandra Kurla Complex, Bandra (East), Mumbai-400 051.<br>
                            E-mail ID:<b> support@mahanagargas.com</b><br>
                            Telephone Number: <b>022-61564555</b>
                        </div>
                            <div class="description-sect"> 
                               Complaint in-charge: <b>Mr. Karan Mhole - Ext. No. 4549</b> <br>
                               Nodal Officer: <b>Mr. Vikas Sarmalkar - Ext. No.4548</b> <br>
                               Appellate Authority: <b>Mr. Paresh Chanpur - Ext. No. 4535 </b><br>
                               Timing 3:00 PM to 5:00 PM from Monday to Friday except Public & Bank Holidays. 
                            </div>

                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->