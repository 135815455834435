import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CngSavingCalculatorService } from 'src/app/Service/cng-saving-calculator.service';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';



@Component({
  selector: 'app-cngsavingcalculator',
  templateUrl: './cngsavingcalculator.component.html',
  styleUrls: ['./cngsavingcalculator.component.css']
})
export class CNGsavingcalculatorComponent {

  FuelType:any[]=[]
  Mileage:any[]=[]

  loader:boolean=false

  

  complaintForm!: FormGroup

  CngPrice:any
  submitted = false
  status:boolean = false

  daily_savings:any
  monthly_savings:any
  yearly_savings:any
  allFaq: any[] = []
  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private router: Router, public FaqService: FaqServiceService, private CngService:CngSavingCalculatorService) { }
  
  
  ngOnInit(): void {

	this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    this.Mileage=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
      21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]

    this.FuelType=['Petrol','Diesel','LPG']

    this.getCngPrice()
    this.complaintForm = this.FormBuilder.group({
      FuelType: ['', Validators.required],
      Fuelrate: ['', Validators.required],
      CarMileage: ['', Validators.required],
      AvgRunning: ['', Validators.required],

    });
  }
  
  resetForm(){

    this.Mileage=[]
    this.FuelType=[]

    this.complaintForm.reset()
    this.status=false
    this.submitted=false

    setTimeout(() => {
      this.Mileage=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
        21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]

      this.FuelType=['Petrol','Diesel','LPG']
    }, 200);

  }

  getCngPrice(){
    this.CngService.getCngPrice().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.CngPrice=data.CurrentPrice
    })
  }


  submit(){
    var toCompare
    var withCompare
    var Compare

    this.submitted=true

    if(this.complaintForm.invalid){
      return
    }
    
    
   // console.log((this.complaintForm.get('Fuelrate')?.value).replace("₹ ",''))
    if(this.complaintForm.get('FuelType')?.value== 'Petrol' || this.complaintForm.get('FuelType')?.value== 'Diesel'){
      withCompare = (this.complaintForm.get('AvgRunning')?.value / this.complaintForm.get('CarMileage')?.value) * parseFloat((this.complaintForm.get('Fuelrate')?.value).replace("₹ ",''))
      toCompare = (this.complaintForm.get('AvgRunning')?.value / (this.complaintForm.get('CarMileage')?.value * 1.5))*parseFloat(this.CngPrice.replace("₹ ",''))
      Compare = (withCompare / this.complaintForm.get('AvgRunning')?.value) - (toCompare / this.complaintForm.get('AvgRunning')?.value)
      
      this.daily_savings = Math.floor(this.complaintForm.get('AvgRunning')?.value * Compare)
      this.monthly_savings = (this.daily_savings*30);
      this.yearly_savings = (this.monthly_savings*12);
      this.status=true
    
    }

    if(this.complaintForm.get('FuelType')?.value== 'LPG'){
      withCompare = (this.complaintForm.get('AvgRunning')?.value / this.complaintForm.get('CarMileage')?.value) * (this.complaintForm.get('Fuelrate')?.value).replace("₹ ",'')
      toCompare = Number(this.complaintForm.get('AvgRunning')?.value / (this.complaintForm.get('CarMileage')?.value * 2)*this.CngPrice.replace("₹ ",''))
      Compare = (withCompare / this.complaintForm.get('AvgRunning')?.value) - (toCompare / this.complaintForm.get('AvgRunning')?.value)
      //console.log(parseFloat(this.CngPrice.replace("₹ ",'')))
      
      this.daily_savings = Math.round(this.complaintForm.get('AvgRunning')?.value * Compare);
      this.monthly_savings = Math.round((this.daily_savings*30));
      this.yearly_savings = Math.round((this.monthly_savings*12));
      this.status=true
    
    }


    

  }

  getPrice(val:any){
    if(val != ''){
      this.loader=true
    }else{
      this.complaintForm.get('Fuelrate')?.setValue('')
    }
    
    if(val == 'Petrol'){
      this.CngService.getPetrolPrice().subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response)).details

        this.complaintForm.get('Fuelrate')?.setValue(data.CurrentPrice)
        this.loader=false
      })
    }else if(val == 'Diesel'){
      this.CngService.getDieselPrice().subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response)).details

        this.complaintForm.get('Fuelrate')?.setValue(data.CurrentPrice)
        this.loader=false
      })
    }else if(val == 'LPG'){
      this.CngService.getLpgPrice().subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response)).details

        this.complaintForm.get('Fuelrate')?.setValue(data.CurrentPrice)
        this.loader=false
      })
    }
  }
}
