import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-mglcngmaostav20dashboard',
  templateUrl: './mglcngmaostav20dashboard.component.html',
  styleUrls: ['./mglcngmaostav20dashboard.component.css']
})
export class Mglcngmaostav20dashboardComponent {


  constructor(private pageLoaderService: PageLoaderService,
    private UserService : UserRegistrationService) { }


    ngOnInit(): void {
    this.UserService.checkLogin1()

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   
   

  }


   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

}
