<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CSR <span>Policies </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a>CSR</a></li>
                <li class="active" aria-current="page">CSR Policies</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <div class="customer-slider mt-30 mb-30">
                    <app-tips></app-tips>
                </div>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                  <!--   <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="mt-30 mb-30">
                        The Company has CSR Policy, which is reviewed on time-to-time basis, based on current happenings
                        on CSR front. Last review was done in 2019 which was to align it with the guidelines formulated
                        by the Companies (Corporate Social Responsibility Policy) Rules, 2014 and subsequent amendments
                        thereof.
                    </div>
                    <div class="mt-30 mb-30">
                        <h3>Vision</h3>
                        <p>In accordance with vision of the company, its CSR initiatives will supplement its
                            contribution in environment protection, development of healthy and enlightened citizens,
                            social upliftment and sustainable community development through its service, conduct and
                            social initiatives.</p>
                    </div>
                    <div class="mt-30 mb-30">
                        <h3>CSR Committee</h3>
                        <p>MGL has constituted the CSR Committee as per mandate of the relevant rules. The CSRC has
                            adequate representation of Independent Directors. The link to the current membership of CSR
                            Committee is appended herein under: - <a
                                href="https://www.mahanagargas.com/MGL-corporate/investors/corporate-governance">https://www.mahanagargas.com/MGL-corporate/investors/corporate-governance</a>
                        </p>
                    </div>
                    <div class="mt-30 mb-30">
                        <h3>Objectives</h3>
                        <p>Integrate business function, goals and strategy with planned community development agenda.
                            Play a role in transforming the society through education, health, empowerment and
                            environment awareness.</p>
                    </div>
                    <div class="mt-30 mb-30">
                        <h3>Financial outlay</h3>
                        <p>For achieving the Company's CSR objectives through implementation of strategic and
                            sustainable CSR programs, MGL will allocate 2% of its average net profit made during the
                            three immediately preceding financial years as its Annual CSR Budget. With the activities
                            focused on the thrust areas specified in the Schedule VII of The Companies Act.</p>

                        <p>The policy outlines the implementation, monitoring and evaluation of the CSR activities to be
                            undertaken. </p>
                    </div>
                 <!--    <div class="mt-30 mb-30">
                        <h3>CSR Initiatives</h3>
                        <p>With deep rooted tradition, constant participation and belief, MGL through its community
                            outreach programmes and initiatives continues to contribute and focus on Education,
                            Environment and Empowerment thereby fostering the philosophy of taking care of the community
                            for a sustainable tomorrow. Entrenched with a vision for working towards the betterment of
                            the society, MGL's sustained efforts have touched and enhanced lives reflecting its
                            commitment of serving for the community.</p>

                        <p>Under the banner of <b>'MGL We Care'</b> various sustainable social activities are conducted
                            throughout the year reaching out to many with a helping hand because we care. </p>
                        <p>Mahanagar Gas Limited being a responsible Corporate has been contributing in social
                            investments primarily in the areas of:</p>
                    </div> -->

                    <div class="light-blue-bg mt-30 csr-policies">
                        <div id="rs-services" class="rs-services style4">

                            <div class="row">
                                <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/mgl-vidya-icon.png"
                                                alt="Customer Care">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL VIDYA</h3>
                                            <p class="margin-0"> MGL VIDYA FOR EDUCATION</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/mgl-saksham-icon.png"
                                                alt="Distribution Company">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL SAKSHAM</h3>
                                            <p class="margin-0">MGL SAKSHAM FOR EMPOWERMENT</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/mgl-hunar-icon.png"
                                                alt="MGL Connect">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL HUNAR</h3>
                                            <p class="margin-0"> MGL HUNAR FOR SKILLS DEVELOPMENT</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/mgl-vikas-icon.png"
                                                alt="MGL Social Media">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL VIKAS </h3>
                                            <p class="margin-0"> MGL VIKAS FOR RURAL DEVELOPMENT</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/mgl-aarogya-icon.png"
                                                alt="MGL Connect">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL AAROGYA</h3>
                                            <p class="margin-0"> MGL AAROGYA FOR HEALTH CARE</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                                    <div class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/mgl-hariyali-icon.png"
                                                alt="MGL Social Media">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">MGL HARIYALI</h3>
                                            <p class="margin-0"> MGL HARIYALI FOR ENVIRONMENT PROTECTION</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- <div class="light-blue-bg mt-30">
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    MGL CSR Project Proposal format
                                </div>
                                <a href="assets/images/pdf/_csr-projects-2022-23_8d99969b42.DOCX" target="_blank" class="bluebtn2 d-block">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>