import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';
import { Router,ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-nametransferdetails',
  templateUrl: './nametransferdetails.component.html',
  styleUrls: ['./nametransferdetails.component.css']
})
export class NametransferdetailsComponent {
  public base = environment.baseUrl;
  allPost: any[] = [];
  captcha:any;
  previouspageData:any;
  previouspageData1:any;
  ApplicationNoID:any;
  constructor(private PostService: SliderService,private FormBuilder : FormBuilder,private Service:FormServiceService,
    private pageLoaderService: PageLoaderService,private activatedRoute: ActivatedRoute,
    public router: Router,){
      this.activatedRoute.queryParams.subscribe((data: any) => {
        // console.log(data);
        this.previouspageData = JSON.parse(data.data);
        // console.log(this.previouspageData);
        this.previouspageData1 = this.previouspageData[0];
        // console.log(this.previouspageData1);

        this.ApplicationNoID = this.previouspageData1.ApplicationNo;
       // console.log(this.ApplicationNoID);
       
      });
    }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    this.captchaGenerate()
    this.getAllHighlight()
 

    
    
  }

  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    
      this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
      

    })
    
  }


  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  gotoEdit(){
    // this.router.navigate(['/residential-png/customer-zone/name-transfer/name-transfer-edit'],
    // {queryParams:{data:JSON.stringify(this.previouspageData)}})
    this.router.navigate(['/residential-png/customer-zone/name-transfer/apply-for-name-step-one-edit/'+ this.ApplicationNoID])
  }

  // residential-png/customer-zone/name-transfer/name-transfer-edit



}
