import { Component } from '@angular/core';

@Component({
  selector: 'app-payupaymenterror',
  templateUrl: './payupaymenterror.component.html',
  styleUrls: ['./payupaymenterror.component.css']
})
export class PayupaymenterrorComponent {

}
