import { Component, OnInit } from '@angular/core';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css']
})
export class FaqListComponent implements OnInit {

  allFaq:any[]=[]
  backupData:any[]=[]
  allFaqDetail:any[]=[]
  selectedFaq :any
  title:any='All FAQS'

  constructor(public FaqService : FaqServiceService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    // this.getAllFaq()
    this.getAllFaqCategory()
   // console.log(this.FaqService.title)
    if(this.FaqService.title ==='All FAQS'){
      this.FaqService.getAllFaqs()
    }
  }

  getAllFaqCategory(){
    this.FaqService.getAllFaqDetail().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
    
      this.allFaqDetail=data.filter((x:any)=>{
        return x.IsDeleted != true
      })
    })
  }

  // getAllFaq(){
  //   this.FaqService.getAllFaq().subscribe(response=>{
  //     let data = JSON.parse(JSON.stringify(response))
  //     console.log(data)
  //     this.allFaq=data
  //     this.backupData=data
  //   })
  // }

  // filterFaq(val:any){
  //   this.title="No FAQ"
  //   console.log(val)
  //   let filtered = this.backupData.filter((x:any)=>{
  //     return x.caption == val
  //   })
  //   this.allFaq=filtered
  //   if(this.allFaq.length>0){
  //     this.title = this.allFaq[0].caption
  //   }
    
  //   console.log(filtered)
  // }
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
