<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>MGL Spirit statement</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class=""><a >Safety & Sustainability</a></li>
                <li class="active" aria-current="page"><a >MGL Spirit statement</a></li>
            </ul>
        </div>
    </div>
</div>

<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
               <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <div class="mt-30">
                <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">

                <div class="spirit-stat mb-30">
                    <div class="icon-numb">
                        <div class="icon-stat"><img src="assets/images/careers/mindfulness.png" alt=""><i>1</i></div>
                    </div>
                    <div class="icon-desc">A constant state of <b>mindfulness</b> so we remain vigilant and
                        non-complacent.</div>
                </div>

                <div class="spirit-stat mb-30">
                    <div class="icon-numb">
                        <div class="icon-stat"><img src="assets/images/careers/just-and-fair.png" alt=""><i>2</i></div>
                    </div>
                    <div class="icon-desc">An approach which is <b>just and fair</b> based on the principles of
                        accountability and responsibility.</div>
                </div>

                <div class="spirit-stat mb-30">
                    <div class="icon-numb">
                        <div class="icon-stat"><img src="assets/images/careers/openness.png" alt=""><i>3</i></div>
                    </div>
                    <div class="icon-desc"><b>Openness</b> which allows people to freely express their concerns as well
                        as their achievements.</div>
                </div>

                <div class="spirit-stat mb-30">
                    <div class="icon-numb">
                        <div class="icon-stat"><img src="assets/images/careers/reflection.png" alt=""><i>4</i></div>
                    </div>
                    <div class="icon-desc"><b>Reflection</b> that drives and challenges our knowledge growth.</div>
                </div>

                <div class="spirit-stat mb-30">
                    <div class="icon-numb">
                        <div class="icon-stat"><img src="assets/images/careers/challenging.png" alt=""><i>5</i></div>
                    </div>
                    <div class="icon-desc"><b>Challenging</b> the status quo so that good is not good enough and
                        therefore, excellence in safety performance is something to be surpassed not simply achieved.
                    </div>
                </div>
                <div class="spirit-stat">
                    <div class="icon-numb">
                        <div class="icon-stat"><img src="assets/images/careers/consistency.png" alt=""><i>6</i></div>
                    </div>
                    <div class="icon-desc"><b>Consistency</b> in what we say and the action we take.</div>
                </div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>