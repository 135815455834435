import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {

  data : any;
  normal: string = '';
  access: string = '';

  constructor(public datepipe: DatePipe, private service: DashboardServiceService, private pageLoaderService: PageLoaderService,private UserService : UserRegistrationService,
    public router: Router) { }

  ngOnInit(): void {
    this.UserService.checkLogin()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getSessionData()
  }

   gotoENach(){
    this.router.navigate(['/residential-png/nach-online'],
    {queryParams:{data:'ENach'}})
  }
  

  getSessionData(){
    let data = sessionStorage.getItem('UserCredential')
    if(data)
    this.data=JSON.parse(data)
    this.getBillInfo()
  }

  getBillInfo(){
    this.service.getBillingCycle({ CAno: this.data.CaNo }).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      //09/01/2023
      
     // console.log(data.bill_date)
      const temp: string[] = data.bill_date.split("/")
      const date = new Date();
      date.setDate(Number(temp[0]));
      date.setMonth(Number(temp[1])-1);
      date.setFullYear(Number(temp[2]));
      
      //let temp = this.datepipe.transform(new Date(data.bill_date), 'yyyy-MM-dd')
      var normal1 = this.datepipe.transform(new Date(date.setMonth(date.getMonth())), 'yyyy-MMM-dd');
      var normal2 = this.datepipe.transform(new Date(date.setMonth(date.getMonth()+4)), 'yyyy-MMM-dd');
      var normal3 = this.datepipe.transform(new Date(date.setMonth(date.getMonth()+4)), 'yyyy-MMM-dd');
      const ndate = [normal1, normal2, normal3];
      this.sortByMonth(ndate)
      this.normal = temp[0] + "th of " + ndate[0]!.substring(5, 8) + ", " + ndate[1]?.substring(5, 8) + ", " + ndate[2]?.substring(5, 8);

      const date1 = new Date();
      date1.setDate(Number(temp[0]));
      date1.setMonth(Number(temp[1])+1);
      date1.setFullYear(Number(temp[2]));
      var access1 = this.datepipe.transform(new Date(date1.setMonth(date1.getMonth())), 'yyyy-MMM-dd');
      var access2 = this.datepipe.transform(new Date(date1.setMonth(date1.getMonth()+4)), 'yyyy-MMM-dd');
      var access3 = this.datepipe.transform(new Date(date1.setMonth(date1.getMonth()+4)), 'yyyy-MMM-dd');
      const adate = [access1, access2, access3];
      this.access = temp[0] + "th of " + adate[0]?.substring(5, 8) + ", " + adate[1]?.substring(5, 8) + ", " + adate[2]?.substring(5, 8);
    });
  }

  sortByMonth(arr: any) {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    arr.sort(function(a: string, b: string){
        return months.indexOf(a.substring(5, 8))
             - months.indexOf(b.substring(5, 8));
    });
  }
}
