<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>NACH Cancel Form</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential Png</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
                <li class="active" aria-current="page">NACH Cancel Form</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="customer-login-page log-meter-reading">
                        <div class="row">
                            <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                
                                <form [formGroup]="UserForm">
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-floating mb-3">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="Name" placeholder="Name"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Name'].errors }"
                                                    id="Name" />
                                                <div *ngIf="submitted && UserForm.controls['Name'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Name'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Name">Customer Name<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-floating mb-3">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="Email" placeholder="Email"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                                                    id="Email" />
                                                <div *ngIf="submitted && UserForm.controls['Email'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Email'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Email">Email Id<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-floating mb-3">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="Mobile" placeholder="Mobile"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Mobile'].errors }"
                                                    id="Mobile" />
                                                <div *ngIf="submitted && UserForm.controls['Mobile'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['Mobile'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="Mobile">Mobile<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Confirm <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        
                                    </div>

                                    <div *ngIf="alertType == 'nach'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>

                                    <p class="text-right mt-20"><b><i>Please note all fields marked <span class="text-danger">*</span>
                                            are compulsory.</i></b></p>


                                           <!--  <h6 *ngIf="nextStepStatus">Further Work Under Construction</h6> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            * Kindly enter Otp as appearing on your Mobile.
                        </div>
                        <form [formGroup]="checkOtpForm">
                            <div class="row">
                                


                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-floating mb-3">
                                        <input onKeyPress="if(this.value.length==6) return false;"  type="number" class="form-control"
                                            formControlName="Otp" placeholder="Otp"
                                            [ngClass]="{ 'is-invalid': OtpSubmitted && checkOtpForm.controls['Otp'].errors }"
                                            id="Otp" />
                                        <div *ngIf="OtpSubmitted && checkOtpForm.controls['Otp'].errors"
                                            class="invalid-feedback">
                                            <div
                                                *ngIf="checkOtpForm.controls['Otp'].errors.required">
                                                Required Field
                                            </div>
                                        </div>
                                        <label for="Otp">Enter OTP<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button [disabled]="submitStatus" (click)="verifyOtp()" class="{{submitStatus ? 'greybtn' :'greenbtn2' }}">Submit OTP<i
                                            class="demo-icon uil-arrow-right"></i></button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button [disabled]="OtpButtonActive"
                                        (click)="OtpGenerate()" class="{{OtpButtonActive ? 'greybtn' :'greenbtn2' }}">{{OtpButtonActive ?
                                        this.Minute+":"+this.Sec:
                                        "Resend OTP"}}<i class="demo-icon uil-arrow-right"></i></button>
                                </div>
                            </div>


                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>


                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>