import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { logcomplaint } from 'src/app/Service/logcomplaint.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import axios from "axios";


@Component({
  selector: 'app-cng-feedback-form',
  templateUrl: './cng-feedback-form.component.html',
  styleUrls: ['./cng-feedback-form.component.css']
})
export class CngFeedbackFormComponent {


  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  Matter:any[]=[]
  Category:any[]=[]
  OutletName: any[] = []
  UserForm!: FormGroup
  submitted: boolean = false;

  outletNo:any
  captcha:any

  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: logcomplaint) { }

  ngOnInit(): void {
    this.getAllOutlet()
    this.Matter = ['Dispenser','Compresser']
    this.Category = ['Breakdown','Routine','Others']

    this.captchaGenerate()

    this.UserForm = this.FormBuilder.group({
      RetailOutlet: ['', Validators.required],
      OutletNo: [''],
      Address: [''],
      ConcernPerson: ['', Validators.required],

      Captcha: ['', Validators.required],
      PhoneNo: [''],
      Email: ['', [Validators.required,Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      Rating: ['', Validators.required],
      type: ['', Validators.required],
      Category: ['', Validators.required],
      Remark: ['', Validators.required],



    })




    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  getAllOutlet() {
    this.service.getAllOutlet().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.OutletName = data
      this.OutletName.sort(function(a,b){
        if(a.OutletName< b.OutletName) return -1;
        if(a.OutletName >b.OutletName) return 1;
        if(a.OutletName< b.OutletName) return -1;
        if(a.OutletName >b.OutletName) return 1;
        return 0;
      });
      //console.log(this.OutletName)
    })
  }


  getOutletDetails(val:any){

    let filtered = this.OutletName.filter((x:any)=>{
      return x._id == val
    })

    //console.log(PhoneNo)

    this.UserForm.get('OutletNo')?.setValue(filtered[0].OutletId)
    this.UserForm.get('PhoneNo')?.setValue(filtered[0].ContactNumber)

    const options = {
      method: 'GET',
      url: 'https://address-from-to-latitude-longitude.p.rapidapi.com/geolocationapi',
      params: {lat: filtered[0].Latitude, lng: filtered[0].Longitude},
      headers: {
        'X-RapidAPI-Key': '1929dbb843msh43e76bea737e740p128746jsn4668614787c2',
        'X-RapidAPI-Host': 'address-from-to-latitude-longitude.p.rapidapi.com'
      }
    };
    
    axios.request(options).then(function (response) {
      saveData(response.data.Results[0].address)
     // console.log(response.data.Results[0].address);
    }).catch(function (error) {
      console.error(error);
    });

    var saveData = (val:any)=>{
      this.UserForm.get('Address')?.setValue(val)
    }

  }

  reset() {
    this.OutletName = []
    this.Matter=[]
    this.Category=[]
    this.UserForm.reset()
    this.submitted = false
    setTimeout(() => {
      this.getAllOutlet()
      this.Matter = ['Dispenser','Compresser']
    this.Category = ['Breakdown','Routine','Others']
    }, 200);
  }

  submit() {
    this.submitted = true

   // console.log(this.UserForm.value)


    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }

    this.captchaGenerate()

    if (this.UserForm.invalid) {
      return
    }


    this.service.postCngDealerFeedBack(this.UserForm.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.message=='Data Added'){
        this.alertType='form'
        this.alertMsg = "Sucessfully Added"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        //console.log("Data Added")

        setTimeout(() => {
          this.alertType=''
        }, 1000);
        this.reset()
      }

    })
  }

}
