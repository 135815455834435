<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Blogs</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page"><a href="#">Blogs</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="rs-inner-blog innerpage-content-section">
    <div class="container custom">
        <div class="row ">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="widget-area">
                    <div class="search-widget mb-50">
                        <div class="search-wrap">
                            <input type="search" placeholder="Searching..." name="s" class="search-input" value="">
                            <button type="submit" value="Search"><i class="flaticon-search"></i></button>
                        </div>
                    </div>
                </div>

                <app-recent-post></app-recent-post>

                <div class="blog-cat widget-area">
                    <div class="categories mt-30">
                        <h3 class="">Categories</h3>
                        <a href="#">CNG</a>
                        <a href="#">PNG</a>
                        <a href="#"> Commercial </a>
                        <a href="#">Environment</a>
                        <a href="#">CNG</a>
                        <a href="#">PNG</a>
                        <a href="#"> Commercial </a>
                        <a href="#">Environment</a>
                        <a href="#">CNG</a>
                        <a href="#">PNG</a>
                        <a href="#"> Commercial </a>
                        <a href="#">Environment</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="row">
                    <div class="col-lg-6 mb-50">
                        <div class="blog-item">
                            <div class="blog-img">
                                <a [routerLink]="['/blogdetail']"><img src="assets/images/gallery/_45_f864d05123.jpg"
                                        alt=""></a>
                                <ul class="post-categories">
                                    <li><a href="#">Meter</a></li>
                                </ul>
                            </div>
                            <div class="blog-content">
                                <h3 class="blog-title"><a href="#">In whose favour the payment for the connection and
                                        gas bills is to be made?</a></h3>
                                <div class="blog-meta">
                                    <ul class="btm-cate">
                                        <li>
                                            <div class="blog-date">
                                                <i class="fa fa-calendar-check-o"></i> Aug 7, 2022
                                            </div>
                                        </li>
                                        <li>
                                            <div class="author">
                                                <i class="fa fa-user-o"></i> MGL
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="blog-desc">


                                    DD/ Cheque must be made in favor of “Mahanagar Gas Limited” only. No payment should
                                    be made in cash. If the Payment is made towards the PNG connection, please write
                                    Name and Contact Number on the reverse side of the DD/cheque. If the Payment is made
                                    towards gas bills, please mention Name, Contact Number, Business Partner (BP) Number
                                    and Contract Account (CA) Number on the reverse side of the DD/cheque.

                                </div>
                                <div class="blog-button">
                                    <a class="blog-btn" href="blog-details.html">Continue Reading</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-50">
                        <div class="blog-item">
                            <div class="blog-img">
                                <a [routerLink]="['/blogdetail']"><img src="assets/images/gallery/_46_c29435a6cc.jpg"
                                        alt=""></a>
                                <ul class="post-categories">
                                    <li><a href="#">CNG</a></li>
                                </ul>
                            </div>
                            <div class="blog-content">
                                <h3 class="blog-title"><a href="#">What is the procedure for installation of Gas Geyser
                                        as I want to install a Gas Geyser in my flat?</a></h3>
                                <div class="blog-meta">
                                    <ul class="btm-cate">
                                        <li>
                                            <div class="blog-date">
                                                <i class="fa fa-calendar-check-o"></i> Aug 7, 2022
                                            </div>
                                        </li>
                                        <li>
                                            <div class="author">
                                                <i class="fa fa-user-o"></i> MGL
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="blog-desc">


                                    Existing PNG users need to login the request (Customer Care) for the gas geyser. New
                                    customers need to specify their Gas Geyser requirement at the time of registration.
                                    Always ensure not to install gas geysers through unauthorized persons. Customer
                                    shall call only customer care for geyser installation requirement. Also note that
                                    Geyser will not be installed in bathrooms for safety reasons.

                                </div>
                                <div class="blog-button">
                                    <a class="blog-btn" href="blog-details.html">Continue Reading</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-50">
                        <div class="blog-item">
                            <div class="blog-img">
                                <a [routerLink]="['/blogdetail']"><img src="assets/images/gallery/_47_89f63e14c3.jpg"
                                        alt=""></a>
                                <ul class="post-categories">
                                    <li><a href="#">PNG</a></li>
                                </ul>
                            </div>
                            <div class="blog-content">
                                <h3 class="blog-title"><a href="#">Is the supply of PNG regular?</a></h3>
                                <div class="blog-meta">
                                    <ul class="btm-cate">
                                        <li>
                                            <div class="blog-date">
                                                <i class="fa fa-calendar-check-o"></i> Aug 7, 2022
                                            </div>
                                        </li>
                                        <li>
                                            <div class="author">
                                                <i class="fa fa-user-o"></i> MGL
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="blog-desc">


                                    Yes, the supply is absolutely regular. The pipeline distribution network is based on
                                    a positive gas pressure which is also looped for redundancy. Thus in normal
                                    condition, an un-interrupted supply at a constant pressure is assured.

                                </div>
                                <div class="blog-button">
                                    <a class="blog-btn" href="blog-details.html">Continue Reading</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-50">
                        <div class="blog-item">
                            <div class="blog-img">
                                <a [routerLink]="['/blogdetail']"><img src="assets/images/gallery/_48_3e5bbb6473.jpg"
                                        alt=""></a>
                                <ul class="post-categories">
                                    <li><a href="#">Environment</a></li>
                                </ul>
                            </div>
                            <div class="blog-content">
                                <h3 class="blog-title"><a href="#">How does a Natural Gas Geyser work?</a></h3>
                                <div class="blog-meta">
                                    <ul class="btm-cate">
                                        <li>
                                            <div class="blog-date">
                                                <i class="fa fa-calendar-check-o"></i> Aug 7, 2022
                                            </div>
                                        </li>
                                        <li>
                                            <div class="author">
                                                <i class="fa fa-user-o"></i> MGL
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="blog-desc">


                                    Gas based geysers work by utilizing the energy from the combustion of natural gas,
                                    transferred directly from the flame to water. These systems are incredibly efficient
                                    at heating water. MGL aims to provide hot water in kitchen and bathroom as an
                                    economical and reliable solution for domestic customers. For further information
                                    please visit our Customer Care section.

                                </div>
                                <div class="blog-button">
                                    <a class="blog-btn" href="blog-details.html">Continue Reading</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="pagination-area">
                            <div class="nav-link">
                                <span class="page-number white-color">1</span>
                                <a class="page-number" href="#">2</a>
                                <a class="page-number border-none" href="#">Next</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>