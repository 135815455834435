import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Oempromotion20Service } from 'src/app/Service/oempromotion20.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { Router,ActivatedRoute } from '@angular/router';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-oemsnewvehicles20edit',
  templateUrl: './oemsnewvehicles20edit.component.html',
  styleUrls: ['./oemsnewvehicles20edit.component.css']
})
export class Oemsnewvehicles20editComponent {
promotionForm!: FormGroup
   submitted = false;
   captcha: any;
   gift_card1: any;
   gift_card11:any;
   gift_card111:any;
   booking_receipt:any;
   booking_receipt1:any;
    booking_receipt111:any;
     undertaking_receipt:any;
    undertaking_receipt1:any;

   pancardimg:any;
   pancardimg1:any;
    pancardimg111:any;
   gift_no11:any;


   alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  display:string='none';
  Popup:any='';
  popuppData:any;
   VehicleModelArr: any;
   dealerNameArr:any;

   previouspageData:any;

   giftCardliveimg:any;
   panCardliveimg:any;

   vehiclecategoryArr:any;

   oemLoginRole:any;
    oemEmail:any;

    nawagatiDelearArr:any;
    nawagatiDelearcardnoArr:any;

    dealer_id:any;
    card_id:any;


   constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: Oempromotion20Service,
     public router: Router,private activatedRoute: ActivatedRoute,private UserService : UserRegistrationService) {
    
        this.activatedRoute.queryParams.subscribe((data: any) => {
      //  console.log(data);
        this.previouspageData = JSON.parse(data.data);
       // console.log(this.previouspageData);
      });

      }

  ngOnInit(): void {
     this.UserService.checkLogin2()
     
       let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential2')))
    
     if (data) {
      let oemLogindata = JSON.parse(data);

      let oemData = oemLogindata.details
     this.oemLoginRole = oemData.Role;
     this.oemEmail = oemData.Email;
     //console.log('details data',this.oemLoginRole)
    }

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    // this.getdist()
    // this.getPlot()
    this.captchaGenerate()
    this.promotionForm = this.FormBuilder.group({
      Name: ['', [Validators.required]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]],
      vehicleregDate: ['', [Validators.required]],
      vehicleModel: ['', [Validators.required]],
      gift_card1: ['', Validators.required],
     // gift_no1: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$"), Validators.required])],
      gift_no1: ['', Validators.required],
      booking_receipt: ['', Validators.required],
      pan_card_img: ['', Validators.required],
      panNo:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
     // dob: ['', Validators.required],
      Captcha: ['', [Validators.required]],
     // address: ['', Validators.required],
      rto_no: ['', Validators.required],
     // Pincode: ['', Validators.compose([Validators.maxLength(6), Validators.minLength(6), Validators.pattern("^[0-9]*$"), Validators.required])],
      oem: ['', Validators.required],
       vehicle_category: ['', Validators.required],
       dealerName: ['', Validators.required],
       // vehicle_chassis_no: ['', Validators.required],
       vehicle_chassis_no: ['', Validators.compose([Validators.maxLength(17), Validators.minLength(17), Validators.required])],
       vehicleAmount: ['', Validators.required],
      // undertaking_receipt: ['', Validators.required],

    });


     this.promotionForm.get('Name')?.setValue(this.previouspageData.Name);
    this.promotionForm.get('ContactNumber')?.setValue(this.previouspageData.ContactNumber);
    this.promotionForm.get('Email')?.setValue(this.previouspageData.Email);
    this.promotionForm.get('vehicleregDate')?.setValue(this.previouspageData.vehicleRegDate);
    this.promotionForm.get('vehicleModel')?.setValue(this.previouspageData.vehicleModel);
    this.promotionForm.get('dlearName')?.setValue(this.previouspageData.delearName);
    this.promotionForm.get('gift_no1')?.setValue(this.previouspageData.gift_no1);
    this.promotionForm.get('panNo')?.setValue(this.previouspageData.panNo);
   // this.promotionForm.get('dob')?.setValue(this.previouspageData.dob);
   // this.promotionForm.get('address')?.setValue(this.previouspageData.address);
    this.promotionForm.get('rto_no')?.setValue(this.previouspageData.rto_no);
    //this.promotionForm.get('Pincode')?.setValue(this.previouspageData.Pincode);
    this.promotionForm.get('vehicle_chassis_no')?.setValue(this.previouspageData.vehicle_chassis_no);
    this.promotionForm.get('booking_receipt')?.setValue(this.previouspageData.booking_receipt);
    this.promotionForm.get('pan_card_img')?.setValue(this.previouspageData.panCardImg);
    this.promotionForm.get('gift_card1')?.setValue(this.previouspageData.gift_card1);
    this.promotionForm.get('oem')?.setValue(this.previouspageData.oem);
    this.promotionForm.get('vehicle_category')?.setValue(this.previouspageData.vehicle_category);
    this.promotionForm.get('dealerName')?.setValue(this.previouspageData.dealerName);
    this.promotionForm.get('vehicleAmount')?.setValue(this.previouspageData.vehicleAmount);
   // this.promotionForm.get('undertaking_receipt')?.setValue(this.previouspageData.undertaking_receipt);
    this.giftCardliveimg = this.previouspageData.gift_card1;
    this.panCardliveimg = this.previouspageData.panCardImg;
    this.dealer_id = this.previouspageData.dealer_id;

  this.vmodelData()
  this.vmodelcatData();
  this.get_all_oem_delear();
   this.get_oem_delear_carnumber(this.previouspageData.dealer_id)

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }

  vmodelData(){
     // this.promotionForm.get('vehicleModel')?.setValue('');
     // this.VehicleModelArr=[];
     //  this.promotionForm.get('dealerName')?.setValue('');
     // this.dealerNameArr=[];

    if(this.promotionForm.get('oem')?.value =='Ashok Leyland'){
      if(this.oemLoginRole =='Automotive Manufacturers Private Limited'){
            this.dealerNameArr=[
            {id:'Automotive Manufacturers Private Limited',name:'Automotive Manufacturers Private Limited'},
            ] 
        }else if(this.oemLoginRole =='Antony Commercial Vehicles Pvt Ltd'){
            this.dealerNameArr=[
            {id:'Antony Commercial Vehicles Pvt Ltd',name:'Antony Commercial Vehicles Pvt Ltd'},
            ] 
        }
      this.vehiclecategoryArr=[
     {id:'Bus',name:'Bus'},
     {id:'LCV (>3.5 T, <10 T)',name:'LCV (>3.5 T, <10 T)'},
     {id:'ICV ( ≥10T, <15T)',name:'ICV ( ≥10T, <15T)'},
     {id:'HCV (≥15 T)',name:'HCV (≥15 T)'},
     ]
    }else if(this.promotionForm.get('oem')?.value =='TATA Motors'){
   
       if(this.oemLoginRole =='Kamal Motors'){
            this.dealerNameArr=[
            {id:'Kamal Motors',name:'Kamal Motors'},
            ] 
        }else if(this.oemLoginRole =='Modi Motors'){
            this.dealerNameArr=[
            {id:'Modi Motors',name:'Modi Motors'},
            ] 
        }else if(this.oemLoginRole =='Maks Motors'){
            this.dealerNameArr=[
           {id:'Maks Motors',name:'Maks Motors'},
            ] 
        }else if(this.oemLoginRole =='PD Insdusrial Enterprises Pvt. Ltd'){
            this.dealerNameArr=[
           {id:'PD Insdusrial Enterprises Pvt. Ltd',name:'PD Insdusrial Enterprises Pvt. Ltd'},
            ] 
        }else if(this.oemLoginRole =='TATA Motors Ltd.'){
            this.dealerNameArr=[
           {id:'TATA Motors Ltd.',name:'TATA Motors Ltd.'},
            ] 
        }

      this.vehiclecategoryArr=[
     // {id:'Bus',name:'Bus'},
     {id:'LCV (>3.5 T, <10 T)',name:'LCV (>3.5 T, <10 T)'},
     {id:'ICV ( ≥10T, <15T)',name:'ICV ( ≥10T, <15T)'},
     {id:'HCV (≥15 T)',name:'HCV (≥15 T)'},
     ]
      
    }else if(this.promotionForm.get('oem')?.value =='Eicher (VECV)'){
    if(this.oemLoginRole =='Eicher Company Owned Company Operated (COCO)'){
            this.dealerNameArr=[
            {id:'Eicher Company Owned Company Operated (COCO)',name:'Eicher Company Owned Company Operated (COCO)'},
            ] 
        }else if(this.oemLoginRole =='Tholia Motors Pvt Ltd (AV Motors)'){
            this.dealerNameArr=[
            {id:'Tholia Motors Pvt Ltd (AV Motors)',name:'Tholia Motors Pvt Ltd (AV Motors)'},
            ] 
        }else if(this.oemLoginRole =='Fortpoint Automotive Mumbai Pvt Ltd'){
            this.dealerNameArr=[
           {id:'Fortpoint Automotive Mumbai Pvt Ltd',name:'Fortpoint Automotive Mumbai Pvt Ltd'},
            ] 
        }

      this.vehiclecategoryArr=[
     {id:'Bus',name:'Bus'},
     {id:'LCV (>3.5 T, <10 T)',name:'LCV (>3.5 T, <10 T)'},
     {id:'ICV ( ≥10T, <15T)',name:'ICV ( ≥10T, <15T)'},
     {id:'HCV (≥15 T)',name:'HCV (≥15 T)'},
     ]

    }else{
       this.promotionForm.get('vehicleModel')?.setValue('');
     this.VehicleModelArr=[];
      this.promotionForm.get('dealerName')?.setValue('');
     this.dealerNameArr=[];
    }
  }


  //  vmodelcatData(){
  //    // this.promotionForm.get('vehicleModel')?.setValue('');
  //    // this.VehicleModelArr=[];

  //    if(this.promotionForm.get('oem')?.value =='TATA Motors'){

  //       if(this.promotionForm.get('vehicle_category')?.value =='Bus'){
  //            this.VehicleModelArr=[
  //            {id:'34 S SKI NAC/AC LP 410/36 CNG',name:'34 S SKI NAC/AC LP 410/36 CNG'},
  //            {id:'51 S SKI NAC/AC LP 310/52 CNG',name:'51 S SKI NAC/AC LP 310/52 CNG'},
  //            {id:'LP913CNG/52 AC 24V 3.8 SDI TC 125',name:'LP913CNG/52 AC 24V 3.8 SDI TC 125'},
  //            {id:'24 S Staff NAC/AC LP 410/36 CNG',name:'24 S Staff NAC/AC LP 410/36 CNG'},
  //            {id:'40 S Staff AC/NAC'},
              
  //            ]

  //       }else if(this.promotionForm.get('vehicle_category')?.value =='LCV (>3.5 T, <10 T)'){
  //            this.VehicleModelArr=[
  //            {id:'407g GOLD',name:'407g GOLD'},
  //            {id:'609g SFC',name:'609g SFC'},
  //            {id:'709g LPT',name:'709g LPT'},
              
  //            ]

  //       }else if(this.promotionForm.get('vehicle_category')?.value =='ICV ( ≥10T, <15T)'){
  //            this.VehicleModelArr=[
  //            {id:'1109g LPT',name:'1109g LPT'},
  //            // {id:'1412g LPT',name:'1412g LPT'},
  //            // {id:'1612g',name:'1612g'},
  //            // {id:'1812g',name:'1812g'},
              
  //            ]

  //            this.promotionForm.get('vehicleAmount')?.setValue('300000');


  //       }else if(this.promotionForm.get('vehicle_category')?.value =='HCV (≥15 T)'){
  //            this.VehicleModelArr=[
  //              {id:'1512g LPT',name:'1512g LPT'},
  //              {id:'1612g',name:'1612g'},
  //              {id:'1812g',name:'1812g'},
  //              {id:'1912g',name:'1912g'},
  //            ]

  //            this.promotionForm.get('vehicleAmount')?.setValue('450000')

  //       }

  //    }else if(this.promotionForm.get('oem')?.value =='Eicher (VECV)'){

  //       if(this.promotionForm.get('vehicle_category')?.value =='Bus'){
  //            this.VehicleModelArr=[
  //            {id:'Pro 2075 CNG',name:'Pro 2075 CNG'},
  //            {id:'Pro 3010',name:'Pro 3010'},
              
  //            ]

  //       }else if(this.promotionForm.get('vehicle_category')?.value =='LCV (>3.5 T, <10 T)'){
  //            this.VehicleModelArr=[
  //            {id:'Pro 2049 CNG',name:'Pro 2049 CNG'},
  //            {id:'Pro 2059 CNG',name:'Pro 2059 CNG'},
  //            {id:'Pro 2059XP CNG',name:'Pro 2059XP CNG'},
              
  //            ]

  //       }else if(this.promotionForm.get('vehicle_category')?.value =='ICV ( ≥10T, <15T)'){
  //            this.VehicleModelArr=[
  //            {id:'Pro 2095XP CNG',name:'Pro 2095XP CNG'},
  //            {id:'Pro 2109 CNG',name:'Pro 2109 CNG'},
  //            {id:'Pro 2110XP',name:'Pro 2110XP'},
              
  //            ]

  //            this.promotionForm.get('vehicleAmount')?.setValue('300000');


  //       }else if(this.promotionForm.get('vehicle_category')?.value =='HCV (≥15 T)'){
  //            this.VehicleModelArr=[
  //            {id:'Pro 2114XP CNG',name:'Pro 2114XP CNG'},
  //            {id:'Pro 3015',name:'Pro 3015'},
  //            {id:'Pro 3018 CNG',name:'Pro 3018 CNG'},
  //            ]
  //            this.promotionForm.get('vehicleAmount')?.setValue('450000')

  //       }

  //    }else if(this.promotionForm.get('oem')?.value =='Ashok Leyland'){

  //       if(this.promotionForm.get('vehicle_category')?.value =='Bus'){
  //            this.VehicleModelArr=[]

  //       }else if(this.promotionForm.get('vehicle_category')?.value =='LCV (>3.5 T, <10 T)'){
  //            this.VehicleModelArr=[]

  //       }else if(this.promotionForm.get('vehicle_category')?.value =='ICV ( ≥10T, <15T)'){
  //            this.VehicleModelArr=[
  //            {id:'1110',name:'1110'},
  //            {id:'1115',name:'1115'}, 
  //            {id:'1415',name:'1415'}, 
  //            ]

  //            this.promotionForm.get('vehicleAmount')?.setValue('300000');


  //       }else if(this.promotionForm.get('vehicle_category')?.value =='HCV (≥15 T)'){
  //           this.VehicleModelArr=[
  //            {id:'1615',name:'1615'},
  //            {id:'1915',name:'1915'},
  //            {id:'1922',name:'1922'},
  //            ]

  //            this.promotionForm.get('vehicleAmount')?.setValue('450000')

  //       }

  //    }

  // }

  vmodelcatData(){
     // this.promotionForm.get('vehicleModel')?.setValue('');
     // this.promotionForm.get('vehicleAmount')?.setValue('');
     // this.VehicleModelArr=[];

     if(this.promotionForm.get('oem')?.value =='TATA Motors'){

        if(this.promotionForm.get('vehicle_category')?.value =='Bus'){
             this.VehicleModelArr=[
             {id:'34 S SKI NAC/AC LP 410/36 CNG',name:'34 S SKI NAC/AC LP 410/36 CNG'},
             {id:'51 S SKI NAC/AC LP 310/52 CNG',name:'51 S SKI NAC/AC LP 310/52 CNG'},
             {id:'LP913CNG/52 AC 24V 3.8 SDI TC 125',name:'LP913CNG/52 AC 24V 3.8 SDI TC 125'},
             {id:'24 S Staff NAC/AC LP 410/36 CNG',name:'24 S Staff NAC/AC LP 410/36 CNG'},
             {id:'40 S Staff AC/NAC'},
              
             ]

        }else if(this.promotionForm.get('vehicle_category')?.value =='LCV (>3.5 T, <10 T)'){
             this.VehicleModelArr=[
             {id:'407g GOLD',name:'407g GOLD'},
             {id:'609g SFC',name:'609g SFC'},
             {id:'709g LPT',name:'709g LPT'},
              
             ]

              this.promotionForm.get('vehicleAmount')?.setValue('180000');

        }else if(this.promotionForm.get('vehicle_category')?.value =='ICV ( ≥10T, <15T)'){
             this.VehicleModelArr=[
             {id:'1109g LPT',name:'1109g LPT'},
             // {id:'1412g LPT',name:'1412g LPT'},
             // {id:'1612g',name:'1612g'},
             // {id:'1812g',name:'1812g'},
              
             ]

             this.promotionForm.get('vehicleAmount')?.setValue('315000');

        }else if(this.promotionForm.get('vehicle_category')?.value =='HCV (≥15 T)'){
             this.VehicleModelArr=[
               {id:'1512g LPT',name:'1512g LPT'},
               {id:'1612g',name:'1612g'},
               {id:'1812g',name:'1812g'},
               {id:'1912g',name:'1912g'},
             ]

              this.promotionForm.get('vehicleAmount')?.setValue('450000')

        }

     }else if(this.promotionForm.get('oem')?.value =='Eicher (VECV)'){

        if(this.promotionForm.get('vehicle_category')?.value =='Bus'){
             this.VehicleModelArr=[
             {id:'Pro 2075 CNG',name:'Pro 2075 CNG'},
             {id:'Pro 3010',name:'Pro 3010'},
              
             ]

              this.promotionForm.get('vehicleAmount')?.setValue('270000')

        }else if(this.promotionForm.get('vehicle_category')?.value =='LCV (>3.5 T, <10 T)'){
             this.VehicleModelArr=[
             {id:'Pro 2049 CNG',name:'Pro 2049 CNG'},
             {id:'Pro 2059 CNG',name:'Pro 2059 CNG'},
             {id:'Pro 2059XP CNG',name:'Pro 2059XP CNG'},
              
             ]

             this.promotionForm.get('vehicleAmount')?.setValue('180000');

        }else if(this.promotionForm.get('vehicle_category')?.value =='ICV ( ≥10T, <15T)'){
             this.VehicleModelArr=[
             {id:'Pro 2095XP CNG',name:'Pro 2095XP CNG'},
             {id:'Pro 2109 CNG',name:'Pro 2109 CNG'},
             {id:'Pro 2110XP',name:'Pro 2110XP'},
              {id:'Pro 2075',name:'Pro 2075'},
              {id:'Pro 2095',name:'Pro 2095'},
              {id:'Pro 2110',name:'Pro 2110'},
              
             ]

             this.promotionForm.get('vehicleAmount')?.setValue('315000');

        }else if(this.promotionForm.get('vehicle_category')?.value =='HCV (≥15 T)'){
             this.VehicleModelArr=[
             {id:'Pro 2114XP CNG',name:'Pro 2114XP CNG'},
             {id:'Pro 3015',name:'Pro 3015'},
             {id:'Pro 3018 CNG',name:'Pro 3018 CNG'},
               {id:'Pro 2118',name:'Pro 2118'},
             ]
              this.promotionForm.get('vehicleAmount')?.setValue('450000')

        }

     }else if(this.promotionForm.get('oem')?.value =='Ashok Leyland'){

        if(this.promotionForm.get('vehicle_category')?.value =='Bus'){
             this.VehicleModelArr=[]

             this.promotionForm.get('vehicleAmount')?.setValue('270000')

        }else if(this.promotionForm.get('vehicle_category')?.value =='LCV (>3.5 T, <10 T)'){
             this.VehicleModelArr=[]
             this.promotionForm.get('vehicleAmount')?.setValue('180000');

        }else if(this.promotionForm.get('vehicle_category')?.value =='ICV ( ≥10T, <15T)'){
             this.VehicleModelArr=[
             {id:'1110',name:'1110'},
             {id:'1115',name:'1115'}, 
             {id:'1415',name:'1415'}, 
             ]

             this.promotionForm.get('vehicleAmount')?.setValue('315000');

        }else if(this.promotionForm.get('vehicle_category')?.value =='HCV (≥15 T)'){
             this.VehicleModelArr=[
             {id:'1615',name:'1615'},
             {id:'1915',name:'1915'},
             {id:'1922',name:'1922'},
             ]

              this.promotionForm.get('vehicleAmount')?.setValue('450000')

        }

     }

  }

    filechange(element: any, check: any) {
   // console.log(check);
    if (check == 'gift_card1') {
        if(element.target.files[0].size/1024/1024>2){
         this.promotionForm.get('gift_card1')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
         this.gift_card1 = element.target.files
      //console.log(this.gift_card1);
      this.gift_card11 = element.target.files[0];
      }

     

      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
     } else if (check == 'booking receipt') {
          if(element.target.files[0].size/1024/1024>2){
         this.promotionForm.get('booking_receipt')?.setValue('');
        return alert('Please select PDF less than 2 MB')
      }else{
         this.booking_receipt = element.target.files
     // console.log(this.booking_receipt);
        this.booking_receipt1 = element.target.files[0];
      }
     
      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
    }else if (check == 'pancard'){
         if(element.target.files[0].size/1024/1024>2){
         this.promotionForm.get('pan_card_img')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.pancardimg = element.target.files
      //console.log(this.pancardimg);
        this.pancardimg1 = element.target.files[0];
      }
      
      
    }

    // else if (check == 'undertaking') {
    //       if(element.target.files[0].size/1024/1024>2){
    //      this.promotionForm.get('undertaking_receipt')?.setValue('');
    //     return alert('Please select PDF less than 2 MB')
    //   }else{
    //      this.undertaking_receipt = element.target.files
    //   //console.log(this.undertaking_receipt);
    //     this.undertaking_receipt1 = element.target.files[0];
    //   }
    // }




  }

   reset() {
    this.promotionForm.reset()
    this.submitted = false;
   

  }

   submitPop(){
    this.closeModel();
    this.submitForm();
  }

  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }

   openPopup(val:any){
    //console.log(val)
    this.Popup='show';
    this.display='block';

    this.gift_no11 = this.promotionForm.get('gift_no1')?.value;
  
  if(this.gift_card1 !=undefined){
    var reader11 = new FileReader();
      reader11.readAsDataURL(this.gift_card11);
      reader11.onload = (_event) => {
       this.gift_card111 = reader11.result; 
    }

  }
    
    

    //  var reader22 = new FileReader();
    //   reader22.readAsDataURL(this.booking_receipt1);
    //   reader22.onload = (_event) => {
    //    this.booking_receipt111 = reader22.result; 
    // }

    if(this.pancardimg !=undefined){
       var reader33 = new FileReader();
      reader33.readAsDataURL(this.pancardimg1);
      reader33.onload = (_event) => {
       this.pancardimg111 = reader33.result; 
    }
    }

    
    

   
    

  }

  submitForm() {

     const form = new FormData()
    form.append("Name", this.promotionForm.get('Name')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("vehicleRegDate", this.promotionForm.get('vehicleregDate')?.value)
    form.append("vehicleModel", this.promotionForm.get('vehicleModel')?.value)
   // form.append("delearName", this.promotionForm.get('dlearName')?.value)
   // form.append("dob", this.promotionForm.get('dob')?.value)
    form.append("gift_no1", this.promotionForm.get('gift_no1')?.value)
 
    form.append("panNo", this.promotionForm.get('panNo')?.value)
   // form.append("address", this.promotionForm.get('address')?.value)
    form.append("rto_no", this.promotionForm.get('rto_no')?.value)
   form.append("Email", this.promotionForm.get('Email')?.value)
  // form.append("Pincode", this.promotionForm.get('Pincode')?.value)
    form.append("oem", this.promotionForm.get('oem')?.value)
     form.append("vehicle_category", this.promotionForm.get('vehicle_category')?.value)
     form.append("dealerName", this.promotionForm.get('dealerName')?.value)
     form.append("vehicle_chassis_no", this.promotionForm.get('vehicle_chassis_no')?.value)
     form.append("vehicleAmount", this.promotionForm.get('vehicleAmount')?.value)
     form.append("dealer_id", this.dealer_id)
     
     if(this.pancardimg !=undefined){
       let pancardimgImg = this.pancardimg[0].name.split('.').pop()
    let pancardimgImg1  =this.pancardimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('panNo')?.value+ new Date()+'pancardimg'+'.'+ pancardimgImg;
    form.append("pan_card_img", this.pancardimg[0], pancardimgImg1)
     }
 
  
   if(this.booking_receipt !=undefined){
     let booking_receiptImg = this.booking_receipt[0].name.split('.').pop()
    let booking_receiptImg1  =this.booking_receipt[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('panNo')?.value+ new Date()+'booking_receiptImg'+'.'+ booking_receiptImg;
    form.append("booking_receipt", this.booking_receipt[0], booking_receiptImg1)
   }  
 
     if(this.gift_card1 !=undefined){
       let gift_card1Img = this.gift_card1[0].name.split('.').pop()
       let gift_card1Img1  =this.gift_card1[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('panNo')?.value+ new Date()+'gift_cardImg'+'.'+ gift_card1Img;
      form.append("gift_card1", this.gift_card1[0], gift_card1Img1)
     }

   //   if(this.undertaking_receipt !=undefined){
   //   let undertaking_receiptImg = this.undertaking_receipt[0].name.split('.').pop()
   //  let undertaking_receiptImg1  =this.undertaking_receipt[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('panNo')?.value+ new Date()+'undertaking_receiptImg'+'.'+ undertaking_receiptImg;
   //  form.append("undertaking_receipt", this.undertaking_receipt[0], undertaking_receiptImg1)
   // }  

  

        this.service.edit_Vehicle_OEM(form).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        // this.alertColor = 'rgba(4, 183, 107, 0.2)'
        // this.alertTextColor = '#038b51'
        // this.alertBorder = '1px solid #04a862;'
        // this.alertType = 'vehiclepromotion'
        // this.alertMsg = res.message;
        this.promotionForm.reset();
        //console.log('lskdjdsfsd',res.details)
        this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oem-dashboard'])
 
        // setTimeout(() => {
        //   this.clearAlert()
        //   this.router.navigate(['/mgl-cng-mahotsav-thank-you/'+data.details.mandate_id])

        // }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
      //console.log(err)
    })



     

  }

   clearAlert() {
    this.alertType = ''
  }

  onSubmit() {
    // console.log(this.promotionForm.value.vehicleNo)
     this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

  

     this.openPopup(1);
  }

  checkDob(){

      var d1 = new Date(this.promotionForm.get('dob')?.value);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    var daydiff = (diff / 31536000000).toFixed(0);

    if (parseInt(daydiff) < 18) {
      this.promotionForm.get('dob')?.setValue('')
       alert("Should be Above 18")
     
    }

  }

  validDate(){
    
    console.log(this.promotionForm.get('vehicleregDate')?.value)
    var selectDate = new Date(this.promotionForm.get('vehicleregDate')?.value);
    var todayDate = new Date('2024-10-01');

    console.log(selectDate)
     console.log(todayDate)

    if(todayDate > selectDate){
         this.promotionForm.get('vehicleregDate')?.setValue('')
       alert("we have not accept vehicle booking dated before 1 st October 2024")
    }else{
        console.log("okay")
    }
  }


  get_all_oem_delear() {
    let obj ={}
   this.service.get_all_oem_delear(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.nawagatiDelearArr = res.details;
        
      } else {
        // this.alertColor = 'rgba(255, 94, 109, 0.2)'
        // this.alertTextColor = '#c24753'
        // this.alertBorder = '1px solid #eb5664;'
        // this.alertType = 'vehiclepromotion'
        // this.alertMsg = res.message;
        // //  this.promotionForm.reset();
        // setTimeout(() => {
        //   this.clearAlert()

        // }, 5000);
      }
    }, err => {
    //  console.log(err)
    })
  }

getCardno(){
   // this.dealer_id ="";
  //console.log(this.promotionForm.get('dealerName')?.value)
  for (let i = 0; i < this.nawagatiDelearArr.length; i++) {
 // console.log(this.nawagatiDelearArr[i])
  if(this.nawagatiDelearArr[i].name == this.promotionForm.get('dealerName')?.value){
    this.dealer_id = this.nawagatiDelearArr[i].id
   this.get_oem_delear_carnumber(this.nawagatiDelearArr[i].id)
  }
 }
 // this.get_oem_delear_carnumber()
}

  get_oem_delear_carnumber(dealer_id:any) {
    let obj ={"dealer_id":dealer_id}
   this.service.get_oem_delear_carnumber(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.nawagatiDelearcardnoArr = res.details;
        
      } else {
        this.nawagatiDelearcardnoArr = [];
        // this.alertColor = 'rgba(255, 94, 109, 0.2)'
        // this.alertTextColor = '#c24753'
        // this.alertBorder = '1px solid #eb5664;'
        // this.alertType = 'vehiclepromotion'
        // this.alertMsg = res.message;
        // //  this.promotionForm.reset();
        // setTimeout(() => {
        //   this.clearAlert()

        // }, 5000);
      }
    }, err => {
    //  console.log(err)
    })
  }

}