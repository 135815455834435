<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1 *ngIf="!status">Domestic Connection Request</h1>
        <h1 *ngIf="status">Domestic PNG Connection from a Gasified Building</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Domestic Connection Request</a></li>
                <li class=""><a>Domestic PNG Registration</a></li>
                <li *ngIf="!status" class="active" aria-current="page">Domestic Connection Request </li>
                <li *ngIf="status" class="active" aria-current="page">Domestic PNG Connection from a Gasified Building</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Title"></a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM dd, yyyy'}}</span>
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a> 
                        </div>
                    </div>
                </div>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>

                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content mb-lg-0 mb-30">
                    <div  class="box882_left">

                        <div class="con">
                            <p>If you are interested in using PNG for domestic use (i.e. cooking, water heating) you
                                have to fill up our online <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request']" (click)="status=true">Domestic
                                    Connection Request Form**</a>On receipt of the same our Team shall contact you with
                                our registration form, to be submitted duly filled along with any one of the supporting
                                documents i.e. Electricity Bill, Society Receipt, Flat Agreement, Rent Receipt and
                                requisite charges by cheque / draft only.</p>
                            <p><strong><a class="bluebtn2" (click)="status=true" [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']" >Domestic Connection-Gasified Building <i class="demo-icon uil-building"></i></a></strong></p>
                            <h6>OR</h6>
                            <p>Please Contact our Customer Care Executive on 68674500 / 61564500 for the new <a
                                    [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request']"><b>Domestic Connection Request</b></a>.</p>
                            <!-- <h6>Total Amount to be paid per Domestic connection is Rs. 6635. Details of payment is as
                                follows:</h6> -->
                            <div class="domestic_table">
                                <ul>
                                    <div  class="light-blue-bg">
                                        <div class="innerpage-right-content awards-section">
                                          <div>
                                            <p>Total Amount to be paid per Domestic connection is Rs. 6635. Details of payment is as follows:</p>
                                            <div class="">
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="station-list">
                                              <tbody>
                                                <tr>
                                                  <th>SR. NO.</th>
                                                  <th>PARTICULARS</th>
                                                  <th>AMOUNT(₹)</th>
                                                </tr>
                                                <tr>
                                                  <td>1</td>
                                                  <td>Application Fee (Non Refundable)</td>
                                                  <td>750</td>
                                                </tr>
                                                <tr>
                                                  <td>2</td>
                                                  <td>GST @ 18%</td>
                                                  <td>135</td>
                                                </tr>
                                                <tr>
                                                  <td>3</td>
                                                  <td>Security Deposit for the connection (Refundable)</td>
                                                  <td>5000</td>
                                                </tr>
                                                <tr>
                                                  <td>4</td>
                                                  <td>Security Deposit for gas consumption (Refundable)</td>
                                                  <td>1000 <!--750--></td>
                                                </tr>
                                                <tr>
                                                  <td>&nbsp;</td>
                                                  <td><b>Total Amount to be paid per connection (incl. 18% GST on Application Fee)</b></td>
                                                  <td><b>6885</b> <!--<b>6635</b>--></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            </div>

                                             <p class="mt-10"><i class="fa fa-inr" aria-hidden="true"></i> * Mandatory fields.<br>
                                            ** Note: Filling up of online "Domestic Connection Request form" does not entitle for a domestic PNG connection.</p>

                                            <!-- <ul>
                                              <li class="payments-box">
                                                <span class="InstrumentNo">SR. NO.</span>
                                                <span class="PaymentDate">PARTICULARS</span>
                                                <span class="PaymentSource">AMOUNT ( <i class="fa fa-inr" aria-hidden="true"></i> )</span>
                                                
                                              </li>
                                
                                              <li >
                                                <span class="InstrumentNo">1</span>
                                                <span class="PaymentDate">Application Fee (Non Refundable)</span>
                                                <span class="PaymentSource"><i class="fa fa-inr" aria-hidden="true"></i>750</span>
                                                
                                              </li>

                                              <li >
                                                <span class="InstrumentNo">2</span>
                                                <span class="PaymentDate">GST @ 18% </span>
                                                <span class="PaymentSource"><i class="fa fa-inr" aria-hidden="true"></i>135</span>
                                                
                                              </li>

                                              <li >
                                                <span class="InstrumentNo">3</span>
                                                <span class="PaymentDate">Security Deposit for the connection (Refundable)  </span>
                                                <span class="PaymentSource"><i class="fa fa-inr" aria-hidden="true"></i>5000</span>
                                                
                                              </li>
                                              <li >
                                                <span class="InstrumentNo">4</span>
                                                <span class="PaymentDate">Security Deposit for gas consumption (Refundable) </span>
                                                <span class="PaymentSource"><i class="fa fa-inr" aria-hidden="true"></i>750</span>
                                                
                                              </li>
                                              <li >
                                                <span class="InstrumentNo"></span>
                                                <span class="PaymentDate">Total Amount to be paid per connection (incl. 18% GST on Application Fee) </span>
                                                <span class="PaymentSource"><i class="fa fa-inr" aria-hidden="true"></i>6635</span>
                                                
                                              </li>
                                
                                            </ul> -->
                                            <!-- <p class="mt-20">THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 DBS BANK UPI PAYMENTS ARE AS FOLLOWS </p> -->
                                          </div>
                                
                                        </div>
                                      </div>

                                   
                                </ul>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>
</div>







<!-- Inner Page Content Section End -->






<!-- Main content End -->