import { Component } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-payment-institution-error',
  templateUrl: './payment-institution-error.component.html',
  styleUrls: ['./payment-institution-error.component.css']
})
export class PaymentInstitutionErrorComponent {
   nametransferData: any
  nametransferData1: any
  id: any
  err: any
  constructor(public datePipe: DatePipe, public UserService: UserRegistrationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.nametransferData = this.UserService.printNameTransfer
    this.id = this.route.snapshot.paramMap.get('id');
    this.err = this.route.snapshot.paramMap.get('err');
    //console.log('nametransferData',this.nametransferData)

    const obj = {'ApplicationNo': this.id}
     this.UserService.getUserReginfoInstitution(obj).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
         //console.log('sdljsaljlafjlsajflajsfjasl',data)
         this.nametransferData1 = data.details[0]
         // console.log('sdljsaljlafjlsajflajsfjasl',this.nametransferData1)

        })
    }

}
