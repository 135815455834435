import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recent-post',
  templateUrl: './recent-post.component.html',
  styleUrls: ['./recent-post.component.css']
})
export class RecentPostComponent implements OnInit {

  public base = environment.baseUrl

  allPost:any[]=[]

  currPage : any

  constructor(private route:ActivatedRoute,private PostService: SliderService) { }

  ngOnInit(): void {  
    this.getAllHighlight()
    this.currPage =this.route.snapshot.data['name']
    //console.log(this.currPage)
  }

  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      if(this.currPage == 'PNG Rate Card' || this.currPage=='Autorized Person' || this.currPage == 'Charges to be taken' 
      || this.currPage == 'Important Policy' || this.currPage == 'Service Provider' || this.currPage == 'If You Smell Gas' 
      || this.currPage == 'PNG Overview' || this.currPage == 'Walk in Center' || this.currPage == 'Feedback Inquiry Form'
      || this.currPage == 'Dropbox' || this.currPage == 'View Your Bill' || this.currPage == 'Gas Gyser Rate' || this.currPage == 'Connection Application Status'
      || this.currPage == 'Tech Guide For PNG Installation' ||this.currPage=='Customer Care Overview'
      ||this.currPage == 'Commercial PNG Application' ||this.currPage == 'Commercial PNG How to apply' || this.currPage == 'Industrial PNG How to Apply'
      || this.currPage == 'Benefits' || this.currPage == 'Document' || this.currPage == 'Commercial PNG How to apply' ||
      this .currPage == 'Types of Industries' || this.currPage == 'Industrial PNG How to Apply' || this.currPage == 'Industrial Document'||
      this.currPage == 'PNG Network' || this.currPage == 'Customer Care Overview' || this.currPage == 'Name Transfer'
    || this.currPage == 'Track Name Transfer Request' || this.currPage == 'Consumer Safty Checklist' || this.currPage == 'Domestic Connection Req Form' || this.currPage == 'Nach Online'
    || this.currPage == 'Quick Bill Pay' || this.currPage == 'Qr Pay'){
        this.allPost = data.filter((x:any)=>{
          return x.Type == 'PNG'
        })
      }else if(this.currPage == 'Overview Envirnment Benefits' || this.currPage == 'CNG Rate Card' || this.currPage == 'CNG Locators' ||
      this.currPage == 'Car Care' || this.currPage == 'CNG Testing Center' || this.currPage == 'CNG Kit Supplier' || this.currPage == 'CNG Feedback Form' || 
      this.currPage == 'CNG Complaint Status' || this.currPage == 'Apply for CNG outlet' || this.currPage == 'CNG Mobile Refueling Unit' || 
      this.currPage == 'Reqmt For Open Plot' || this.currPage=='Savings Ki Shubh Shuruaat'
      || this.currPage == 'CNG Conversion Enquiry' || this.currPage == 'Savings Ki Shubh Shuruaat Application Status'
      || this.currPage=='Update Savings Ki Shubh Shuruaat' || this.currPage=='CNG Open Request'
      || this.currPage=='Steel Pipe Line Network Layout'
      || this.currPage == 'Mgl Tez' || this.currPage == 'Savings Ki Shubh Shuruaat Brochure'
      ||this.currPage =='Open Plot near Usar' ||this.currPage =='New CNG Vehicle Promotion' 
      ||this.currPage =='Retrofitment'){
        this.allPost = data.filter((x:any)=>{
          return x.Type == 'CNG'
        })

      }else{
         this.allPost = data.filter((x:any)=>{
          return x.Type != 'Both'
        })
        // this.allPost= data
      }
      
      //console.log(this.allPost)

    })
    
  }

}
