import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwardsItem } from 'src/app/models/award';
import { AwardsService } from 'src/app/Service/awards.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-awards-list',
  templateUrl: './awards-list.component.html',
  styleUrls: ['./awards-list.component.css']
})
export class AwardsListComponent {
  Awards: any[]=[];
  baseUrl = environment.baseUrl
  id:any

    constructor(private service : AwardsService,private route: ActivatedRoute) { }
  ngOnInit(): void {
    let Year = this.route.snapshot.paramMap.get('Year');
    this.id=Year
   // console.log(this.id)

    this.service.get(this.id).subscribe(response=>{
      var data = JSON.parse(JSON.stringify(response)).details;
      // let filtered = data.details.filter((x:any)=>{
      //   return x.IsDeleted !== 'true'
      // })
      this.Awards=data
      //console.log(this.Awards)
    })
    this.get()  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }


  get() {
    // this.service.get().subscribe(response=>{
    //   var data = JSON.parse(JSON.stringify(response));
    //   let filtered = data.details.filter((x:any)=>{
    //     return x.IsDeleted !== 'true'
    //   })
    //   this.Awards=filtered
    //   console.log(filtered)
    // })
    }

}



