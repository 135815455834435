import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { VehiclepromotionService } from 'src/app/Service/vehiclepromotion.service';
import { ApplyOutletItem } from 'src/app/models/applyforoutket';
import { DistrictItem } from 'src/app/models/applyforoutket';
import { PlotItem } from 'src/app/models/applyforoutket';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { Router,ActivatedRoute } from '@angular/router';
//import { PSM, createWorker } from 'tesseract.js';
import { log } from 'console';

@Component({
  selector: 'app-cngmahotsavmarutisuzukiapplicationedit',
  templateUrl: './cngmahotsavmarutisuzukiapplicationedit.component.html',
  styleUrls: ['./cngmahotsavmarutisuzukiapplicationedit.component.css']
})
export class CngmahotsavmarutisuzukiapplicationeditComponent {
promotionForm!: FormGroup
   submitted = false;
   captcha: any;
   gift_card1: any;
   gift_card11:any;
   gift_card111:any;
   booking_receipt:any;
   booking_receipt1:any;
    booking_receipt111:any;

   pancardimg:any;
   pancardimg1:any;
    pancardimg111:any;
   gift_no11:any;


   alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  display:string='none';
  Popup:any='';
  popuppData:any;
  previouspageData:any;

   giftCardliveimg:any;
   panCardliveimg:any;

   constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: VehiclepromotionService,
     public router: Router,private activatedRoute: ActivatedRoute,) {
       
         this.activatedRoute.queryParams.subscribe((data: any) => {
        //console.log(data);
        this.previouspageData = JSON.parse(data.data);
        //console.log(this.previouspageData);
      });

      }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    // this.getdist()
    // this.getPlot()
    this.captchaGenerate()
    this.promotionForm = this.FormBuilder.group({
      Name: ['', [Validators.required]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      vehicleregDate: ['', [Validators.required]],
      vehicleModel: ['', [Validators.required]],
      dlearName: ['', [Validators.required]],
      gift_card1: ['', Validators.required],
      gift_no1: ['', Validators.compose([Validators.maxLength(16), Validators.minLength(16), Validators.pattern("^[0-9]*$"), Validators.required])],
      booking_receipt: ['', Validators.required],
      pan_card_img: ['', Validators.required],
      Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
      dob: ['', Validators.required],
      Captcha: ['', [Validators.required]],
      address: ['', Validators.required],
      rto_no: ['', Validators.required],
      Pincode: ['', Validators.compose([Validators.maxLength(6), Validators.minLength(6), Validators.pattern("^[0-9]*$"), Validators.required])],
      vehicle_chassis_no: ['', Validators.required],

    });

    this.promotionForm.get('Name')?.setValue(this.previouspageData.Name);
    this.promotionForm.get('ContactNumber')?.setValue(this.previouspageData.ContactNumber);
    this.promotionForm.get('Email')?.setValue(this.previouspageData.Email);
    this.promotionForm.get('vehicleregDate')?.setValue(this.previouspageData.vehicleRegDate);
    this.promotionForm.get('vehicleModel')?.setValue(this.previouspageData.vehicleModel);
    this.promotionForm.get('dlearName')?.setValue(this.previouspageData.delearName);
    this.promotionForm.get('gift_no1')?.setValue(this.previouspageData.gift_no1);
    this.promotionForm.get('Pan')?.setValue(this.previouspageData.panNo);
    this.promotionForm.get('dob')?.setValue(this.previouspageData.dob);
    this.promotionForm.get('address')?.setValue(this.previouspageData.address);
    this.promotionForm.get('rto_no')?.setValue(this.previouspageData.rto_no);
    this.promotionForm.get('Pincode')?.setValue(this.previouspageData.Pincode);
    this.promotionForm.get('vehicle_chassis_no')?.setValue(this.previouspageData.vehicle_chassis_no);
    this.promotionForm.get('booking_receipt')?.setValue(this.previouspageData.booking_receipt);
    this.promotionForm.get('pan_card_img')?.setValue(this.previouspageData.panCardImg);
    this.promotionForm.get('gift_card1')?.setValue(this.previouspageData.gift_card1);
    this.giftCardliveimg = this.previouspageData.gift_card1;
    this.panCardliveimg = this.previouspageData.panCardImg;
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }

    filechange(element: any, check: any) {
  //  console.log(check);
    if (check == 'gift_card1') {
        if(element.target.files[0].size/1024/1024>2){
         this.promotionForm.get('gift_card1')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
         this.gift_card1 = element.target.files
      //console.log(this.gift_card1);
      this.gift_card11 = element.target.files[0];
      }

     

      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
     } else if (check == 'booking receipt') {
          if(element.target.files[0].size/1024/1024>2){
         this.promotionForm.get('booking_receipt')?.setValue('');
        return alert('Please select PDF less than 2 MB')
      }else{
         this.booking_receipt = element.target.files
      //console.log(this.booking_receipt);
        this.booking_receipt1 = element.target.files[0];
      }
     
      // const file:File = element.target.files[0];
      // if (file) {
      //   var path = (window.URL || window.webkitURL).createObjectURL(file);
      //   this.doOCR(path)
      // }
    }else if (check == 'pancard'){
         if(element.target.files[0].size/1024/1024>2){
         this.promotionForm.get('pan_card_img')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.pancardimg = element.target.files
      //console.log(this.pancardimg);
        this.pancardimg1 = element.target.files[0];
      }
      
      
    }




  }

   reset() {
    this.promotionForm.reset()
    this.submitted = false;
   

  }

   submitPop(){
    this.closeModel();
    this.submitForm();
  }

  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }

   openPopup(val:any){
    //console.log(val)
    this.Popup='show';
    this.display='block';
    // console.log(this.pancardimg1)
    //   console.log(this.booking_receipt1)
    //   console.log(this.gift_card11)

    this.gift_no11 = this.promotionForm.get('gift_no1')?.value;
   
   

     if(this.gift_card11 !=undefined){
       var reader11 = new FileReader();
      reader11.readAsDataURL(this.gift_card11);
      reader11.onload = (_event) => {
       this.gift_card111 = reader11.result; 
    }
    
   }
    
   

    //  var reader22 = new FileReader();
    //   reader22.readAsDataURL(this.booking_receipt1);
    //   reader22.onload = (_event) => {
    //    this.booking_receipt111 = reader22.result; 
    // }

       if(this.pancardimg1 !=undefined){
         var reader33 = new FileReader();
      reader33.readAsDataURL(this.pancardimg1);
      reader33.onload = (_event) => {
       this.pancardimg111 = reader33.result; 
    }

   }

    
    

   
    

  }

  submitForm() {

     const form = new FormData()
    form.append("Name", this.promotionForm.get('Name')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("vehicleregDate", this.promotionForm.get('vehicleregDate')?.value)
    form.append("vehicleModel", this.promotionForm.get('vehicleModel')?.value)
    form.append("delearName", this.promotionForm.get('dlearName')?.value)
    form.append("dob", this.promotionForm.get('dob')?.value)
    form.append("gift_no1", this.promotionForm.get('gift_no1')?.value)
 
    form.append("Pan", this.promotionForm.get('Pan')?.value)
    form.append("address", this.promotionForm.get('address')?.value)
    form.append("rto_no", this.promotionForm.get('rto_no')?.value)
    form.append("Email", this.promotionForm.get('Email')?.value)
    form.append("Pincode", this.promotionForm.get('Pincode')?.value)
    form.append("vehicle_chassis_no", this.promotionForm.get('vehicle_chassis_no')?.value)
   

   if(this.pancardimg1 !=undefined){
     let pancardimgImg = this.pancardimg[0].name.split('.').pop()
    let pancardimgImg1  =this.pancardimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date()+'pancard'+'.'+ pancardimgImg;
    form.append("pan_card_img", this.pancardimg[0], pancardimgImg1)
   }

   if(this.booking_receipt1 !=undefined){
    let booking_receiptImg = this.booking_receipt[0].name.split('.').pop()
    let booking_receiptImg1  =this.booking_receipt[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date()+'booking_receipt'+'.'+ booking_receiptImg;
    form.append("booking_receipt", this.booking_receipt[0], booking_receiptImg1)
   }

   if(this.gift_card11 !=undefined){
     let gift_card1Img = this.gift_card1[0].name.split('.').pop()
    let gift_card1Img1  =this.gift_card1[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date()+'gift_card'+'.'+ gift_card1Img;
      form.append("gift_card1", this.gift_card1[0], gift_card1Img1)
   }

     this.service.editmarutiVehicle(form).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        // this.alertColor = 'rgba(4, 183, 107, 0.2)'
        // this.alertTextColor = '#038b51'
        // this.alertBorder = '1px solid #04a862;'
        // this.alertType = 'vehiclepromotion'
        // this.alertMsg = res.message;
        this.promotionForm.reset();
        //console.log('lskdjdsfsd',res.details)
        this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki-application-status'])
 
        // setTimeout(() => {
        //   this.clearAlert()
        //   this.router.navigate(['/mgl-cng-mahotsav-thank-you/'+data.details.mandate_id])

        // }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
      //console.log(err)
    })



     

  }

   clearAlert() {
    this.alertType = ''
  }

  onSubmit() {
    //console.log('pancardimg',this.pancardimg)
     this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

  

     this.openPopup(1);
  }

  checkDob(){

      var d1 = new Date(this.promotionForm.get('dob')?.value);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    var daydiff = (diff / 31536000000).toFixed(0);

    if (parseInt(daydiff) < 18) {
      this.promotionForm.get('dob')?.setValue('')
       alert("Should be Above 18")
     
    }

  }

}

