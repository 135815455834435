<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>UPI<span> AutoPay Mandate</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
               <!--  <li class=""><a >Customer Zone</a></li>
                <li class=""><a >Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li> -->
                <li class="active" aria-current="page">UPI AutoPay Mandate</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
               <!--  <div class="latestnews-section" *ngIf="Service.loginStatus">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                 <div  class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <form [formGroup]="UserForm">
                                <div class="customer-login-page log-meter-reading">
                                    <div class="log-meter-text">
                                        <ul>
                                            <li>Instructions to Fill UPI Auto Pay Mandate:</li>
                                        </ul>
                                        <ol class="mt-30 mb-30">
                                            <li class="mb-10"> <b>UPI Auto Pay Mandate</b> is a one-time authorization for recurring payments via your virtual payment handle for collection against PNG dues.</li>
                                            <li class="mb-10"> Your <b>CA No., e Mail id and name</b> will automatically
                                                populate from the system.</li>
                                            <li class="mb-10"> <b>Now accepting:</b>@upi, @paytm, @ybl, @ibl, @axl, @okahdfcbank, @okaxis, @apl, @indus, @boi, @cnrb</li>
                                            <li class="mb-10"> Successful UPI mandate registration will give you a UMN with status as "Active" after you have approved the mandate on your UPI APP.</li>
                                            <li class="mb-10">Rejection of UPI mandate will give a status of Rejection without any UMN Number.</li>
                                            <li class="mb-10">Bills are generated on Bimonthly basis by MGL. UPI Mandate will be presented around the due date of your bills.</li>

                                        </ol>
                                       
                                    </div>
                                    <div class="row">

                                      <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="CAno"
                                                placeholder="CA No"
                                                [ngClass]="{ 'is-invalid': submited && UserForm.controls['CAno'].errors }"
                                                id="CAno" />
                                            <div *ngIf="submited && UserForm.controls['CAno'].errors"
                                                class="invalid-feedback">
                                                 <div *ngIf="UserForm.controls['CAno'].errors.required">
                                                    Required Field
                                                </div>
                                              
                                                <div *ngIf="UserForm.controls['CAno'].errors.pattern">
                                                    Invalid CAno </div>
                                            </div>
                                          
                                            <label for="Amt">CA No<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="Name"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submited && UserForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submited && UserForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                 <div *ngIf="UserForm.controls['Name'].errors.required">
                                                    Required Field
                                                </div>
                                              
                                                <div *ngIf="UserForm.controls['Name'].errors.pattern">
                                                    Invalid Name </div>
                                            </div>
                                          
                                            <label for="Amt">Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                        
                                        

                                      <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="Email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submited && UserForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submited && UserForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                 <div *ngIf="UserForm.controls['Email'].errors.required">
                                                    Required Field
                                                </div>
                                              
                                                <div *ngIf="UserForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                          
                                            <label for="Amt">Email<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                        

                                       <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="number" class="form-control" formControlName="Amt"
                                                    placeholder="Amt"
                                                    [ngClass]="{ 'is-invalid': submited && UserForm.controls['Amt'].errors }" id="Amt" />
                                                <div *ngIf="submited && UserForm.controls['Amt'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Amt'].errors.required">Required Field</div>
                                                   <!--  <div *ngIf="UserForm.controls['Name'].errors.pattern">CA should be in 10 digits.</div> -->
                                                </div>
                                                <label for="Amt">Amount (Rs.)<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        
                                           <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input readonly type="text" class="form-control" formControlName="Dates"
                                                    placeholder="Collection Start Date"
                                                    [ngClass]="{ 'is-invalid': submited && UserForm.controls['Dates'].errors }" id="Dates" />
                                                <div *ngIf="submited && UserForm.controls['Dates'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Dates'].errors.required">Required Field</div>
                                                    
                                                </div>
                                                <label for="Dates">Collection Start Date<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                         <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="date" class="form-control" formControlName="FDates"
                                                    placeholder="Final Collection Date"
                                                    [ngClass]="{ 'is-invalid': submited && UserForm.controls['FDates'].errors }" id="FDates" />
                                                <div *ngIf="submited && UserForm.controls['FDates'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['FDates'].errors.required">Required Field</div>
                                                    
                                                </div>
                                                <label for="FDates">Final Collection Date<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                           <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="upi"
                                                    placeholder="UPI Virtual Payment Address"
                                                    [ngClass]="{ 'is-invalid': submited && UserForm.controls['upi'].errors }" id="upi" />
                                                <div *ngIf="submited && UserForm.controls['upi'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['upi'].errors.required">Required Field</div>
                                                     <div *ngIf="UserForm.controls['upi'].errors.pattern">
                                                    Invalid UPI Virtual Payment Address </div>
                                                    
                                                </div>
                                                <label for="upi">UPI Virtual Payment Address<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12 y-middle">
                                                    <div class="captcha-text">
                                                    <b>{{captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i></b>
                                                     </div>
                                                </div>
                                                

                                                <div class="col-sm-6 form-group">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control" formControlName="Captcha"
                                                            placeholder="Captcha"
                                                            [ngClass]="{ 'is-invalid': submited && UserForm.controls['Captcha'].errors }" id="Captcha" />
                                                        <div *ngIf="submited && UserForm.controls['Captcha'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm.controls['Captcha'].errors.required">Required Field</div>
                                                            <div *ngIf="UserForm.controls['Captcha'].errors.pattern">CA should be in 10 digits.</div>
                                                        </div>
                                                        <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <a href="javascript:void(0)" (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></a>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <a href="javascript:void(0)" (click)="this.UserForm.reset()" (click)="this.submited=false" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="alertType == 'nach'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>

                                     <p class="mt-30 mb-0 text-right"><i><b>* All the fields are mandatory</b></i></p>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->