
 
 <div class="innerpage-content-section">
    <div class="container custom">
       <div class="light-blue-bg text-center">
          <h2 class="dual-heading mb-30">
             Thank you for filling the form! <span>Your application id is <b>{{nametransferData1.reg_no}}</b></span>
          </h2>
          <div class="mt-30 mb-30">
             <img src="assets/images/thank-your-application-graphic.png">
          </div>
          <!-- <a (click)="printForm()" class="greenbtn2 mr-10">View Application <i class="demo-icon uil-file-check"></i></a> -->
            <a *ngIf="fullPayFlag==true || pngkamahostavPayFlag==true" (click)="printApplFull()" class="greenbtn2 mr-10">Print Application Form<i class="demo-icon uil-file-check"></i></a>
            <a *ngIf="emiPayFlag==true || superemiPayFlag==true" (click)="printApplEMI()" class="greenbtn2 mr-10">Print Application Form<i class="demo-icon uil-file-check"></i></a>
            <a *ngIf="fdcPayFlag==true" (click)="printApplFDC()" class="greenbtn2 mr-10">Print Application Form<i class="demo-icon uil-file-check"></i></a>
         <!--    <a *ngIf="emiPayFlag==true || superemiPayFlag==true" (click)="printReceiptEMI()" class="greenbtn2 mr-10">View Receipt <i class="demo-icon uil-file-check"></i></a>
            <a *ngIf="fdcPayFlag==true" (click)="printReceiptFDC()" class="greenbtn2 mr-10">View Receipt <i class="demo-icon uil-file-check"></i></a>
 -->
             <!--  <a  (click)="printReceiptFull()" class="greenbtn2 mr-10">View Receipt <i class="demo-icon uil-file-check"></i></a>
            <a  (click)="printReceiptEMI()" class="greenbtn2 mr-10">View Receipt <i class="demo-icon uil-file-check"></i></a>
            <a (click)="printReceiptFDC()" class="greenbtn2 mr-10">View Receipt <i class="demo-icon uil-file-check"></i></a> -->
        <!--   <a [routerLink]="['/']" class="bluebtn2">Back to Home <i class="demo-icon uil-home-alt"></i></a> -->
       </div>
    </div>
 </div>