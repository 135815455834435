import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormServicetestService {
  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }

  postConversionForm(data:any){
    
    return this.http.post(this.baseUrl+'complaint/postCngConversion',data)
  }



  getNameTransferStatus(data:any){
    return this.http.post(this.baseUrl+'pngnametransferTest/getNameTransferApplicationNo',data)
  }

  getNameTransferStatusbyBPno(data:any){
    return this.http.post(this.baseUrl+'pngnametransferTest/getNameTransferbpNo',data)
  }

  getNameByBpnoAndContact(data:any){
    return this.http.post(this.baseUrl+'reg/getNameByBpAndMobile',data)
  }

  postFeedbackEnquiry(data:any){
    
    return this.http.post(this.baseUrl+'reg/postFeedbackEnquiry',data)
  }

  getAllReason(){
    return this.http.get(this.baseUrl+'pngnametransferTest/getAllReason')
  }

  postContactForm(data:any){
    
    return this.http.post(this.baseUrl+'complaint/postContactUs',data)
  }


  postEnquiryForm(data:any){
    
    return this.http.post(this.baseUrl+'form/postEnquiry',data)
  }

  postNameTransferForm(data:any){
    
    return this.http.post(this.baseUrl+'pngnametransferTest/addCustomerForm',data)
  }

   updatepostNameTransferForm(data:any){
    
    return this.http.post(this.baseUrl+'pngnametransferTest/updateCustomerFormNew',data)
  }

    updateCustomerFormThree(data:any){
    
    return this.http.post(this.baseUrl+'pngnametransferTest/updateCustomerFormThree',data)
  }

   editCustomerFormone(data:any){
    
    return this.http.post(this.baseUrl+'pngnametransferTest/editCustomerFormone',data)
  }

   getUserinfoforPrint(data:any){
    return this.http.post(this.baseUrl+'pngnametransferTest/getUserinfoforPrint',data)
  }

  getnameTransCount(){
    return this.http.get(this.baseUrl+'pngnametransferTest/getnameTransCount')
  }
}
