<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Environment </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a [routerLink]="['/csrinitiative']">CSR Initiatives</a></li>
                <li class="active" aria-current="page">Environment</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>
                 <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
                <!-- <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="mt-30 mb-30">
                        <p> Maintaining harmony with nature is centrifugal to MGL’s vision. MGL therefore puts utmost
                            priority to environmental issues.</p>

                        <p>Animals and Birds form an important part of our eco system. Like us, they need space, food
                            and clean water. MGL has supported a voluntary animal welfare organization. The intervention
                            targets stray animals who not only suffers from homelessness, but starvation and cruelty.
                            They are often regarded as problems with issues like causing road accidents, spreading
                            diseases, bites, howling, littering, fights, etc. are associated with them. The quality of
                            life itself is extremely fragile. </p>

                    </div>

                    <div class="light-blue-bg mt-30 csr-policies">
                        <img src="assets/images/csr-initiatives-environment.jpg" class="w-100">
                    </div>

                    <div class="mt-30 mb-30">
                        <h3>The project components includes</h3>
                        <div class="blue-tick-list">
                            <ul>
                                <li>Controlling stray animal population through sterilization:- 1800 strays are targeted
                                    to be sterilized.</li>
                                <li>Treating diseased:- Treating diseased animals and</li>
                                <li>Fostering adoption</li>
                            </ul>
                        </div>
                    </div>

                    <div class="mt-30 mb-30">
                        <h3>To serve the same MGL has extended facilitated</h3>
                        <div class="blue-tick-list">
                            <ul class="">
                                <li>Establishment of veterinary ICCU with latest technology for sterilization and
                                    treatment,</li>
                                <li>Installing wards and cages to facilitate pre and post operations for sterilization
                                    and treatments,</li>
                                <li>Solar power plant to convert the entire operations to green energy,</li>
                                <li>Veterinary Ambulance for quick response,</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    