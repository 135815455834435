<!-- Inner Page Content Section Start -->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>IC <span>Bill</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page">Industrial& Commercial</li>
                <li class="active" aria-current="page">IC Bill</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-industrial-service-section></app-industrial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                    <app-recent-post></app-recent-post>
                
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                            <div class="customer-login-page">
                                <div class="log-meter-text">
                                    <ul>
                                        <li>* Kindly enter <b>CA</b> number as appearing on your bill.</li>
                                    </ul>
                                </div>
                                <div class="">
                                    <form [formGroup]="UserForm">
                                        <div class="form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="CAno"
                                                    placeholder="Enter CA.No. (Contract Account)" maxlength="12"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['CAno'].errors }"
                                                    id="ca-no" />
                                                <label for="ca-no">CA.No. (Contract Account)<span
                                                        style="color: crimson;">*</span></label>
                                                <div *ngIf="submitted && UserForm.controls['CAno'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['CAno'].errors.required">CA No. is
                                                        required</div>
                                                    <div *ngIf="UserForm.controls['CAno'].errors.pattern">CA Number
                                                        should be in digits.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="MobileNo"
                                                    placeholder="Enter Mobile No" maxlength="10"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MobileNo'].errors }"
                                                    id="MobileNo" />
                                                <label for="MobileNo">Mobile No<span
                                                        style="color: crimson;">*</span></label>

                                                <div *ngIf="submitted && UserForm.controls['MobileNo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['MobileNo'].errors.required">Mobile
                                                        No. required</div>
                                                    <div *ngIf="UserForm.controls['MobileNo'].errors.pattern">Mobile
                                                        number should be in digits.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-20">
                                            <button (click)="submit()" style="border: none;" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                    </form>

                                    <div *ngIf="alertType == 'CA'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl modal-video">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    <div class="customer-login-page log-meter-reading">
                        <form [formGroup]="otpForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
            
                                    <div class="customer-login-page">
                                        <p><i> * Kindly enter <b>OTP</b> number as appearing on your Mobile.</i></p>
                                        <div class="">
                                            <div  class="mb-20 cu-login-input2">
                                                <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                <input formControlName="InputOtp" class="from-control" type="text" id="name"
                                                    name="name" placeholder="Enter your OTP" required="">
                                            </div>
            
                                            <div class="d-flex justify-content-between">
                                                <button [disabled]="btnStatus" style="margin-right: 10px;" (click)="verifyOtp()" class="greenbtn2">Verify <i class="demo-icon uil-check"></i>
                                                </button>
                                                <button [disabled]="OtpButtonActive" (click)="OtpGenerate()"
                                                    class="greenbtn2">{{OtpButtonActive ? this.Minute+":"+this.Sec:
                                                    "Resend"}} <i class="demo-icon uil-arrow-circle-left"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                        <div class="text-right mt-20">
                            <p class="mb-0"><b><i>Please note all fields marked <span class="text-danger">*</span> are
                                        compulsory.</i></b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>