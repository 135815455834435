import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  captcha: any
  UserForm !: FormGroup
  data: any
  submitted: boolean = false
  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  constructor(private FormBuilder: FormBuilder, public router: Router, public FaqService: FaqServiceService, private service: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.captchaGenerate()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    //this.service.checkLogin()

    this.UserForm = this.FormBuilder.group({
      CaNo: ['',[Validators.required, Validators.pattern(/^\d{12}$/)]],
      Captcha: ['',[Validators.required]],
      Email: ['',[Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
    });
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    this.captcha=result
  }

  reset(){
    this.UserForm.reset()
    this.submitted=false
  }

  clearStatus(){
    setTimeout(()=>{
      this.alertMsg=''
    },3000)
  }

  submit() {
    this.submitted = true 

    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
   // console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    this.service.forgotPassword(this.UserForm.get('CaNo')?.value, this.UserForm.get('Email')?.value)
    .pipe(first())
      .subscribe(
        data => {
          if (data.response) {
            this.alertType = 'login'
            this.alertMsg = "Password reset link sent on your registered email."
            this.alertColor = 'rgba(4, 183, 107, 0.2)'
            this.alertTextColor = '#038b51'
            this.alertBorder = '1px solid #eb5664;'
            setTimeout(() => {
              this.router.navigate(['/residential-png/customer-zone/customer-login'])
              this.clearAlert();
            }, 2000);
          } else {
             this.UserForm.get('Captcha')?.setValue('')
            this.captchaGenerate()
            this.alertType = 'login'
            this.alertMsg = "Your CA Number or Email not matched."
            this.alertColor = 'rgba(255, 94, 109, 0.2)'
            this.alertTextColor = '#c24753'
            this.alertBorder = '1px solid #04a862;'


            setTimeout(() => {
              this.clearAlert();
            }, 2000);
          }
        }
      )
  }
  
  clearAlert() {
    this.alertType = ''
  }
}
