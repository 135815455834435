<div class="customer-slider mt-30 mb-30">
    <!-- <div  data-loop="true" data-items="1" data-margin="30" data-autoplay="true" data-hoverpause="true" data-autoplay-timeout="5000" data-smart-speed="800" data-dots="false" data-nav="true" data-nav-speed="false" data-center-mode="false" data-mobile-device="1" data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device="1" data-ipad-device-nav="true" data-ipad-device-dots="false" data-ipad-device2="1" data-ipad-device-nav2="true" data-ipad-device-dots2="false" data-md-device="1" data-md-device-nav="true" data-md-device-dots="false"> -->

    <owl-carousel-o [options]="networkOptions">
        <ng-template *ngFor="let i of allTips,index as j" carouselSlide>
            <div class="blog-item">
                <div class="blog-content">
                    <div class="number">{{j+1}}</div>
                    <div class="tips-content">
                    <h3 class="blog-title"><a href="#">{{i.Question}}</a></h3>
                    <p class="desc">{{i.Answer}}</p>
                    </div>
                </div>
                <div class="image-wrap">
                    <a href="#"><img src="{{baseUrl}}{{i.ImageName}}" alt="Blog"></a>
                </div>
            </div>
        </ng-template>
        
    </owl-carousel-o>

</div>