
<a [routerLink]="['/caution-notice']" class="expand-button" *ngIf="rightMenu">
  <span class="info-icon"><img src="assets/images/caution-icon.png"></span>
  <span class="text">Caution Notice</span>
</a>
<app-page-loader></app-page-loader>
<app-right-menu *ngIf="rightMenu"></app-right-menu>
<div (scroll)="onWindowScroll($event);">
	<div class="main-content">
	  <app-top-bar *ngIf="headerFooter"></app-top-bar>
	  <router-outlet></router-outlet>
	</div>
	<app-footer *ngIf="headerFooter"></app-footer>
</div>	