<!--Right Menu Start-->
<a class="right-menu-btn" (click)="this.width='130px'"><i class="demo-icon uil-align-center-alt"></i></a>
<div [ngStyle]="{'width':this.width}" id="mySidenav" class="sidenav">
    <a href="javascript:void(0)" class="closebtn" (click)="this.width='0'">&times;</a>
    <div class="right-menu">
        <a (click)="this.width='0'" [routerLink]="['/residential-png/customer-zone/customer-login']"><img src="assets/images/rm-customer-login.png"><span> Customer Login</span></a>
        <a (click)="this.width='0'" [routerLink]="['/user-registration']"><img src="assets/images/rm-registration.png"><span> Registration</span></a>
        <!-- <a (click)="this.width='0'" [routerLink]="['/residential-png/customer-care/customer-care-overview']"><img src="assets/images/rm-customer-support.png"><span>Customer Support</span></a> -->
        <a (click)="this.width='0'" [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/png-overview']"><img src="assets/images/rm-customer-support.png"><span>Customer Support</span></a>
        <a (click)="this.width='0'" [routerLink]="['/residential-png/customer-zone/name-transfer']"><img src="assets/images/rm-name-transfer.png"><span> Name Transfer Request</span></a>
        <a (click)="this.width='0'" [routerLink]="['/cng/customer-zone/saving-calculator']"><img src="assets/images/rm-saving-calulator.png"><span> Savings Calculator</span></a>
        <a (click)="this.width='0'" [routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/active-tender']"><img src="assets/images/rm-tenders.png"><span> Tenders</span></a>
        <a (click)="this.width='0'" [routerLink]="['/nachcancel_commercial']"><img src="assets/images/rm-industrial.png"><span>Industrial & Commercial Nach Cancellation </span></a>
        <a (click)="this.width='0'" [routerLink]="['/MGL-corporate/expression-of-interest']"><img src="assets/images/rm-cbg.png"><span>Expression of Interest </span></a>
          
         <!-- <a (click)="this.width='0'" target="_blank" href="https://www.mahanagargas.com:3000/Hindi - Invite for EOI_28.pdf"><img src="assets/images/rm-cbg.png"><span>CBG – Hindi/ <a (click)="this.width='0'" target="_blank" href="https://www.mahanagargas.com:3000/English - Invite for EOI_28.pdf"><span>English</span></a> </span></a> -->
          
        <!-- <a (click)="this.width='0'" href="https://play.google.com/store/apps/details?id=com.mobicule.mgl"><img src="assets/images/rm-download-econnect.png"><span> Download MGL Connect</span></a> -->
        
        <!--  <a href="#"><img src="assets/images/rm-registration.png"><span> Registration</span></a> 
   <a href="#"><img src="assets/images/rm-customer-support.png"><span> Customer Care</span></a> 
   <a href="#"><img src="assets/images/rm-name-transfer.png"><span>  Name Transfer Request</span></a> 
   <a href="#"><img src="assets/images/rm-saving-calulator.png"><span> Savings Calculator</span></a> 
   <a href="#"><img src="assets/images/rm-tenders.png"><span> Tenders</span></a> 
   <a href="#"><img src="assets/images/rm-download-econnect.png"><span> Download MGL Connect</span></a>  -->
    </div>
</div>