<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CNG <span>View Complaint Status </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class=""><a >Login For Existing Dealers</a></li>
                <li class=""><a >Feedback</a></li>
                <li class="active" aria-current="page">CNG View Complaint Status </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <div class="customer-login-page log-meter-reading">

                                <form [formGroup]="UserForm">
                                    <div class="row">

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            
                                            

                                            <div class="form-floating mb-3">
                                                <select (change)="this.getFeedbackComplaintIdByOutlet(type.value)"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['RetailOutlet'].errors}" #type
                                                    (change)="this.UserForm.get('RetailOutlet')?.setValue(type.value)"
                                                     class="form-select" 
                                                     >
                                                     <option value="" selected>Select Option</option>
                                                     <option *ngFor="let i of OutletName" [value]="i._id">{{i.OutletName}}</option>
                                                    
                                                </select>
                                                <label for="floatingPassword">Name of Retail Outlet</label>
                                                <div *ngIf="submitted && UserForm.controls['RetailOutlet'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['RetailOutlet'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <select (change)="this.getFeedBackDetailById(Id.value)"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['ComplaintNo'].errors}" #Id
                                                    (change)="this.UserForm.get('ComplaintNo')?.setValue(type.value)"
                                                     class="form-select" 
                                                     >
                                                     <option value="" selected>Select Option</option>
                                                    <option *ngFor="let i of Feedbackdetail">{{i.Complaint_id}}</option>
                                                    
                                                </select>
                                                <label for="floatingPassword">Complaint No</label>
                                                <div *ngIf="submitted && UserForm.controls['ComplaintNo'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['ComplaintNo'].errors">Required Field</div>
                                                  </div>
                                              </div>

                                              
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['ComplaintDate'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="ComplaintDate">
                                                <label for="floatingPassword">Complaint Date</label>
                                                <div *ngIf="submitted && UserForm.controls['ComplaintDate'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['ComplaintDate'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['ComplaintType'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="ComplaintType">
                                                <label for="floatingPassword">Complaint Type</label>
                                                <div *ngIf="submitted && UserForm.controls['ComplaintType'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['ComplaintType'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>
                                        
                                        
                                        

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['ComplaintCat'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="ComplaintCat">
                                                <label for="floatingPassword">Complaint Category</label>
                                                <div *ngIf="submitted && UserForm.controls['ComplaintCat'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['ComplaintCat'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['Remark'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="Remark">
                                                <label for="floatingPassword">Remark </label>
                                                <div *ngIf="submitted && UserForm.controls['Remark'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['Remark'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        

                                        


                                        

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['Feedback'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="Feedback">
                                                <label for="floatingPassword">Feedback  </label>
                                                <div *ngIf="submitted && UserForm.controls['Feedback'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['Feedback'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['Feedback'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="Status">
                                                <label for="floatingPassword">Status   </label>
                                                <div *ngIf="submitted && UserForm.controls['Status'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['Status'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                            <label>Year</label>
                                            <div class="mb-30 cu-login-input2 mt-10">
                                                
                                                <input
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['Year'].errors}"
                                                    formControlName="Year" class="from-control" type="text" id="name"
                                                    name="name" placeholder=" Enter Captcha" required="">
                                                <div *ngIf="submitted && UserForm.controls['Year'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Year'].errors">Captcha required
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        

                                        

                                        
                                    </div>

                                    <!-- <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div> -->
                                </form>
                              
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>