<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Saving Calculator
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a  [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Customer Zone</a></li>
                <li class="active" aria-current="page">Saving Calculator
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-customer-awareness></app-customer-awareness>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <h6>CNG IS ECONOMICAL FUEL THAT YOUR VEHICLE CAN RUN ON!</h6>
                    <p>CNG delivers high performance at low price, as it gives your vehicle a better mileage. CNG being
                        an indigenous fuel also saves valuable foreign exchange.</p>
                    <h6>CNG Car Savings Calculator</h6>
                    <p>Try out our CNG savings calculator for your car to know your daily, monthly or yearly savings
                        when compared to other conventional fuels. </p>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <div class="customer-login-page log-meter-reading">
                                <form [formGroup]="complaintForm">
                                    <div class="row">



                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <select class="form-select" #val (change)="getPrice(val.value)"
                                                    (change)="this.complaintForm.get('FuelType')?.setValue(val.value)"
                                                    required
                                                    [ngClass]="{'is-invalid':submitted && complaintForm.controls['FuelType'].errors}">
                                                    <option selected value="">Select Type of Your Car</option>
                                                    <option *ngFor="let i of FuelType">{{i}}</option>

                                                </select>
                                                <div *ngIf="submitted && complaintForm.controls['FuelType'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && complaintForm.controls['FuelType'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Fuel Type<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>



                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Fuelrate"
                                                    placeholder="Fuelrate"
                                                    [ngClass]="{ 'is-invalid': submitted && complaintForm.controls['Fuelrate'].errors }"
                                                    id="Fuelrate" />
                                                <div *ngIf="this.loader" class="loader"></div>
                                                <div *ngIf="submitted && complaintForm.controls['Fuelrate'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="submitted && complaintForm.controls['Fuelrate'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Fuel Rate<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>



                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <select #Mil class="form-select"
                                                    (change)="this.complaintForm.get('CarMileage')?.setValue(Mil.value)"
                                                    [ngClass]="{'is-invalid':submitted && complaintForm.controls['CarMileage'].errors}">

                                                    <option selected value="">Select Your Car Current Mileage(KM)
                                                    </option>

                                                    <option *ngFor="let i of Mileage" [value]="i">{{i}} kmpl</option>
                                                </select>
                                                <div *ngIf="submitted && complaintForm.controls['CarMileage'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="submitted && complaintForm.controls['CarMileage'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="Contact">Car Current Mileage(KM)<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>




                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="AvgRunning"
                                                    placeholder="AvgRunning"
                                                    [ngClass]="{ 'is-invalid': submitted && complaintForm.controls['AvgRunning'].errors }"
                                                    id="AvgRunning" />

                                                <div *ngIf="submitted && complaintForm.controls['AvgRunning'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="submitted && complaintForm.controls['AvgRunning'].errors">
                                                        Required Field</div>
                                                </div>
                                                <label for="AvgRunning">Avg Running<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="resetForm()" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <!-- <div *ngIf="status" id="ContentPlaceHolder1_divResult" class="calculator_report">
                                    <ul>
                                        <li>
                                            <span>Your daily savings on CNG will be	:</span>
                                            <big id="ContentPlaceHolder1_dailySaving">Rs. {{this.daily_savings}}</big>
                                        </li>
                                        <li>
                                            <span>Your monthly savings on CNG will be	:</span>
                                            <big id="ContentPlaceHolder1_MonthlySaving">Rs. {{this.monthly_savings}}</big>
                                        </li>
                                        <li>
                                            <span>Your yearly savings on CNG will be	:</span>
                                            <big id="ContentPlaceHolder1_YearlySaving">Rs. {{this.yearly_savings}}</big>
                                        </li>
                                    </ul>
                                </div> -->
                                <div *ngIf="status" id="ContentPlaceHolder1_divResult" class="calculator_report">
                                    <div class="mt-30 yearly_savings csr-policies">
                                        <div id="rs-services" class="rs-services style4">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 mb-20">
                                                    <div class="services-item">
                                                        <div class="services-icon">
                                                            <img class="dance_hover"
                                                                src="assets/images/careers/your-daily-savings.png"
                                                                alt="Customer Care">
                                                        </div>
                                                        <div class="services-content">
                                                            <h3 class="title">Your daily savings on CNG will be</h3>
                                                            <div class="yearly_savings_text"><big
                                                                    id="ContentPlaceHolder1_dailySaving">₹
                                                                    {{this.daily_savings}}.00</big></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 mb-20">
                                                    <div class="services-item">
                                                        <div class="services-icon">
                                                            <img class="dance_hover"
                                                                src="assets/images/careers/your-monthly-savings.png"
                                                                alt="Distribution Company">
                                                        </div>
                                                        <div class="services-content">
                                                            <h3 class="title">Your monthly savings on CNG will be</h3>
                                                            <div class="yearly_savings_text"><big
                                                                    id="ContentPlaceHolder1_MonthlySaving">₹
                                                                    {{this.monthly_savings}}.00</big></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="services-item">
                                                        <div class="services-icon">
                                                            <img class="dance_hover"
                                                                src="assets/images/careers/your-yearly-savings.png"
                                                                alt="MGL Connect">
                                                        </div>
                                                        <div class="services-content">
                                                            <h3 class="title">Your yearly savings on CNG will be</h3>
                                                            <div class="yearly_savings_text"><big
                                                                    id="ContentPlaceHolder1_YearlySaving">₹
                                                                    {{this.yearly_savings}}.00</big></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>




                </div>
            </div>
        </div>
    </div>
</div>