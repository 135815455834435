<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Composite Dispensing Unit
            <span> </span>
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class="active" aria-current="page">Composite Dispensing Unit
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Request for Proposal for CNG Composite Dispensing Unit
                                </div>
                                <a href="assets/images/_rfp-120522_abf701b07b.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    List of CDU Manufacturers
                                </div>
                                <a href="assets/images/_list-of-cdu-manufacturers_8b6d898949.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    CNG Composite Dispensing Unit video
                                </div>
                                <a href="https://www.youtube.com/embed/bDEdByV65NE" target="_Blank"
                                    class="bluebtn2 d-block">watch <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    CNG Composite Dispensing Unit - Features video
                                </div>
                                <a href="https://www.youtube.com/embed/6svS3hZdovg" target="_Blank"
                                    class="bluebtn2 d-block">watch <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Inner Page Content Section End -->