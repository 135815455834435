<!--Full width header End-->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Retrofitment FAQs
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav</a></li>
                <li class=""><a >FAQs</a></li>
                <li class="active" aria-current="page"><a>Retrofitment FAQs</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

             
            </div> 
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <h2 class="dual-heading mb-30"></h2>
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="rs-faq applications-natural-gas">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link" href="#collapseOne" data-toggle="collapse"
                                            aria-expanded="false">
                                             What is this MGL CNG Mahotsav?</a>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div class="row">
                                                <div _ngcontent-glm-c121=""
                                                    class="accordianindustriesCont dealers_contace_table"
                                                    style="width: 100%; margin-right: 0px;">
                                                    <div _ngcontent-glm-c121="" style="overflow-x: auto;">
                                                        <p>MGL has announced MGL CNG Mahotsav to promote CNG as a fuel in Commercial Vehicles Segment. This offer will be applicable by converting your existing vehicle to CNG from a MGL authorised retrofitter.</p>
                                                      
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwo" data-toggle="collapse"
                                            data-bs-target="#collapsetwo" aria-expanded="false">What is the duration of this scheme?
                                        </a>
                                    </div>
                                    <div id="collapsetwo" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body" style="overflow-x: auto;">
                                        The scheme is valid from 1 st April 2024 till 31 st August 2024.
                                           
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsethree" data-toggle="collapse"
                                            data-bs-target="#collapsethree" aria-expanded="false">What all segments of vehicles are covered in this scheme?
                                        </a>
                                    </div>
                                    <div id="collapsethree" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body" style="overflow-x: auto;">
                                            <div class="blue-tick-list">
                                                <ul>
                                           <!--  <li>Car</li> -->
                                            <li>Vehicles having Gross vehicle weight >3.5 to < 10 tons </li>
                                            <li>Private Buses</li>
                                            <li>Vehicles having Gross vehicle weight >10 to < 15 tons<li>
                                            <li>Vehicles having Gross vehicle weight >15 tons.</li>
                                                </ul>
                                            
                                        </div>
                                    </div>
                                </div>
    
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link collapsed" href="#collapsefour" data-toggle="collapse"
                                        data-bs-target="#collapsefour" aria-expanded="false">What is the scheme amount which is offered under the scheme?
                                    </a>
                                </div>
                                <div id="collapsefour" class="collapse" data-bs-parent="#accordion" style="">
                                    <div class="card-body" style="overflow-x: auto;">
                                      <p>  MGL is offering a Retrofitment Promotion Amount (“Incentive”) which will be passed to customers from retrofitter which is as given below.</p>
                                      <div class="table-responsive">
                                
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Gross Vehicle Weight (GVW) in Tons</th>
                                                    <th>Incentive Value (₹/ Vehicle)</th>
                                                    
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                 <tr>
                                                    <td>Commercial Vehicle</td>
                                                    <td>> 3.5, < 10</td>   
                                                     <td>1,80,000</td>   
                                                </tr>
                                                 <tr>
                                                    <td>Commercial Vehicle</td>
                                                    <td>≥10, < 15 tons</td>   
                                                     <td>3,15,000</td>   
                                                </tr>
                                                 <tr>
                                                    <td>Commercial Vehicle</td>
                                                    <td>≥15</td>   
                                                     <td>4,50,000</td>   
                                                </tr>
                                                 <tr>
                                                    <td>Pvt Buses</td>
                                                    <td></td>   
                                                     <td>2,70,000</td>   
                                                </tr>
                                                <!-- <tr>
                                                    <td>Car</td>
                                                    <td>19,999/- </td>   
                                                     <td>19,999/- </td>   
                                                </tr>
                                                <tr>
                                                    <td>GVW >3.5, < 10 Ton</td>
                                                    <td> 2,00,000 </td>
                                                         
                                                </tr>
                                                <tr>                              
                                                    <td>Pvt. Buses</td>
                                                    <td> 3,00,000 </td>
                                                      
                                                </tr>
                                                <tr>
                                                    <td>GVW ≥10 - 15 Ton</td>
                                                    <td>3,50,000</td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td>GVW ≥15 Ton</td>
                                                    <td> 5,00,000 </td>
                                                    
                                                </tr> -->
                                               
                                               
                                               
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    </div>
                                    </div>


                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsefive" data-toggle="collapse"
                                                data-bs-target="#collapsefive" aria-expanded="false">How do I avail the scheme?
                                            </a>
                                        </div>
                                        <div id="collapsefive" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                               You have to get the vehicle converted from our authorised enlisted retrofitter and get the promotion incentive amount upfront from the retrofitter 
                                               
                                            </div>
                                        </div>
                                    </div>
                                   <!--  <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseSix" data-toggle="collapse"
                                                data-bs-target="#collapseSix" aria-expanded="false">I have retrofitted my vehicle on 31st August 2023. Will I be eligible for the scheme?
                                            </a>
                                        </div>
                                        <div id="collapseSix" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                                No. The vehicle needs to be retrofitted/converted in between 1st September 2023 till 31’st December 2023 
                                               
                                            </div>
                                        </div>
                                    </div> -->
                                  <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseSeven" data-toggle="collapse"
                                                data-bs-target="#collapseSeven" aria-expanded="false">If I convert 2 vehicles, can I claim 2 discounts?
                                            </a>
                                        </div>
                                        <div id="collapseSeven" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                                This scheme is applicable on every retrofitment/conversion. If you convert 2 vehicles, you will be given discount on both 
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseeight" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">Which areas are covered under the scheme?
                                            </a>
                                        </div>
                                        <div id="collapseeight" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                                The scheme shall be valid only for vehicles registered in Maharashtra under series number MH-01, MH-02, MH-03, MH-04, MH-05, MH-06, MH-43, MH-46, and MH-47 RTOs respectively as these RTOs basically cover MGLs Geographical Area
                                               
                                            </div>
                                        </div>
                                    </div>
                                        
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
