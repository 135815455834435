import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-png-rate-card',
  templateUrl: './png-rate-card.component.html',
  styleUrls: ['./png-rate-card.component.css']
})
export class PngRateCardComponent {
  networkOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      450: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
    }
  }

  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}


