	<div class="container">
       <div class="register-top-section">
        	<a href="#" class="mgl-logo"><img src="assets/images/payulog/logo.png"></a>
       </div>
       <div class="register-content">
       		<!--PayU Left Start-->
	        <div class="left-number-section">
	        		<a class="payu-logo">
	        			<img src="assets/images/payulog/payu-white.png">
	        		</a>
		           <div class="wallet-graphic">
		           		<img src="assets/images/payulog/wallet-graphic.png">
		           </div> 
	           <div class="balance-payment">
		            <div class="balance-amount">
		            		<p>Balance </p>   
		            		<h2><span>INR.</span>{{availableBalance}}</h2>
		            </div>
	            	<i class="wallet-img"><img src="assets/images/payulog/wallet-icon.png"></i>
	           </div>   
	    	</div>
	    	<!--PayU Left End-->

	    	<!--PayU Right Start-->
		    <div class="right-form-section view-transaction-page-right">
		         <h2>Welcome <span>{{FName}}</span></h2>   
			      <div class="view-transaction-section">
			      	<form [formGroup]="dateForm">
			      	 <div class="row">
			      	 	
			      	 	<div class="col-lg-4 col-md-4 col-sm-12">
                           <div class="form-floating mb-3">
                               <input type="date" class="form-control" placeholder="From Date"
                                  formControlName="fromDate" [ngClass]="{ 'is-invalid': submitted && dateForm.controls['fromDate'].errors }"/>
                                       <div *ngIf="submitted && dateForm.controls['fromDate'].errors"
                                        class="invalid-feedback">
                                        <div *ngIf="dateForm.controls['fromDate'].errors.required">
                                            Required Field</div>

                                    </div>
                                <label for="From Date">From Date</label>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                           <div class="form-floating mb-3">
                               <input type="date" class="form-control" placeholder="To Date"
                                  formControlName="toDate"  [ngClass]="{ 'is-invalid': submitted && dateForm.controls['toDate'].errors }"/>
                                       <div *ngIf="submitted && dateForm.controls['toDate'].errors"
                                        class="invalid-feedback">
                                        <div *ngIf="dateForm.controls['toDate'].errors.required">
                                            Required Field</div>

                                    </div>
                                <label for="From Date">To Date</label>
                            </div>
                        </div>
                         <div class="col-lg-4 col-md-4 col-sm-12">
                         	<button type="submit" (click)="filter()">Search</button>
                         </div>
                    
			      	 </div>
			      	  </form>
			      	<table>
			      		<thead>
			      		<tr>
			      		<th width="40%">Transaction Date</th>	
			      		<th>Transaction Type</th>
			      		<th align="right" style="text-align: right;">Transaction Amount</th>
			      		</tr>
			      		</thead>
			      		<tr *ngFor="let item of statementDetails">
			      		<!-- <td data-label="Transaction Date" width="40%">{{item.date}}<span class="tr-time">{{item.time}}</span></td> -->	
			      		<td data-label="Transaction Date" width="40%">{{item.transactionDate}}</td>
			      		<td data-label="Debit Card">{{item.transactionType}}</td>
			      		<td data-label="Transaction Amount" align="right"><span class="amount-text">{{item.transactionAmount}}</span></td>
			      		</tr>
			      		
			      	</table>
			      </div>   
		          </div>
		          <!--PayU Right End-->
       </div>
    </div>
