import { Component } from '@angular/core';

@Component({
  selector: 'app-savings-ki-shubh-shuruaat-brochure',
  templateUrl: './savings-ki-shubh-shuruaat-brochure.component.html',
  styleUrls: ['./savings-ki-shubh-shuruaat-brochure.component.css']
})
export class SavingsKiShubhShuruaatBrochureComponent {

}
