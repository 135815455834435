<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>MGL Sahayogi- Dial before Dig</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class="active" aria-current="page">MGL Sahayogi- Dial before Dig</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<!-- <app-mgl-corporate-service-section></app-mgl-corporate-service-section> -->
<div id="rs-services" class="rs-services style6 bn-service">
    <div class="marketstats">
      <img src="assets/images/market-stat-bg.png" class="mk-bg">
      <div class="marketstats-content">
         <ul>
          <li><!--<i class="demo-icon uil-calling"></i>-->
            <p>Dial before Dig/ MGL Sahayogi </p>
             <!-- <h6 > 1800 266 9944 / 6867 4500</h6> -->
             <h6> 1800 2100 2100</h6>
          </li>
          <li><div class="mid-social">
          <a href=" https://www.facebook.com/mglltdofficial" target="_blank"><i class="fa fa-facebook"></i></a>
          <a href="https://twitter.com/mahanagargas" target="_blank"><i class="fa fa-twitter"></i></a>
          <a href="https://youtu.be/07Jxsttz6X8" target="_blank"><i class="fa fa-youtube-play"></i></a>
          <a href="https://instagram.com/mahanagargas?igshid=YmMyMTA2M2Y=" target="_blank"><i class="fa fa-instagram"></i></a>
          <a href="https://in.linkedin.com/company/mglltd" target="_blank"><i class="fa fa-linkedin"></i></a>
          </div></li> 
          <!-- <li><p>MGL-NSE</p>
              <h6 ><i class="demo-icon uil-chart-line"></i> ₹{{stock.CurrentPrice}} <span [ngStyle]="{'color':NseColors}"  >({{stock.Changes}}%)</span></h6>
          </li>
          <li><p>MGL-BSE</p>
              <h6><i class="demo-icon uil-chart-line"></i> ₹{{stock.BseCurrentPrice}} <span [ngStyle]="{'color':BseColors}" >{{stock.BseChange}}</span> </h6>
          </li>
          <li><p>Call Us on</p>
              <h6><i class="demo-icon uil-phone-alt"></i> 68674500 / 61564500 </h6>
          </li> -->
         </ul>

      </div>
    </div>  
  <div class="looking-for mgl-corporate-service-sect">
      <img src="assets/images/i-looking-for-bg.svg" class="looking-for-bgimg">
      <div class="looking-for-services">
          <h3>I am <span> looking for</span></h3>
          <ul>
           <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/domestic-connection.png">
                  <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request']">
                    <h6>Domestic <span>Connection</span></h6>
                  </a>
                </abbr>
                </li>
                <li>
                    <abbr>
                      <img src="assets/images/looking-for/mgl-corporate/update-your-contact-details.png">
                      <a [routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">
                        <h6>Update your <span>Contact Details</span></h6>
                      </a>
                    </abbr>
                    </li>
            <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/generate-your-bill.png">
                  <a [routerLink]="['/residential-png/customer-zone/current-bill']">
                    <h6>Generate <span>your Bill</span></h6>
                  </a>
                </abbr>
              </li>   
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/view-your-bill.png">
                  <a [routerLink]="['/view-your-bill']">
                    <h6>View <span>your Bill</span></h6>
                  </a>
                </abbr>
              </li>  
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/view-authorised-person.png">
                  <a [routerLink]="['/residential-png/view-authorized-personnel']">
                    <h6>View Authorised<span>Person</span></h6>
                  </a>
                </abbr>
              </li>
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/nearest-cng-station.png">
                  <a [routerLink]="['/cng/customer-zone/cng-locator']">
                    <h6>Nearest CNG <span>Station</span></h6>
                  </a>
                </abbr>
              </li>  
              <li>
                <abbr>
                  <img src="assets/images/looking-for/mgl-corporate/apply-for-cng-ro.png">
                  <a [routerLink]="['/cng/customer-zone/saving-calculator']">
                    <h6>Apply for<span>CNG RO</span></h6>
                  </a>
                  </abbr>
                  </li> 
                 <li>
                    <abbr>
                      <img src="assets/images/looking-for/mgl-corporate/feedback-enquiry-png.png">
                      <a [routerLink]="['/residential-png/customer-care/feedback-inquiry-form']">
                        <h6>Feedback / <span>Enquiry PNG</span></h6>
                      </a>
                    </abbr>
                  </li>   
              </ul>
      </div>
  </div>
</div>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

                            <div class="customer-login-page log-meter-reading">
                                <!-- <p>MGL SAHAYOGI – DIAL BEFORE DIG is a initiative by MGL appealing to all MGL customers and public at large to inform us about any digging activity in a timely manner to protect MGL assets against any third party damage. Information of third party digging activity can be made at 18002669944 (Toll free) / (022)-68759400 / (022)-24012400, email can be sent to emergencycontrolroom@mahanagargas.com Or fill in the following information.</p> -->
                                <p>MGL SAHAYOGI – DIAL BEFORE DIG is a initiative by MGL appealing to all MGL customers and public at large to inform us about any digging activity in a timely manner to protect MGL assets against any third party damage. Information of third party digging activity can be made at 1800 2100 2100 (Toll free), email can be sent to emergencycontrolroom@mahanagargas.com Or fill in the following information.</p>
                                <form [formGroup]="UserForm">
                                    <div class="row">
                               

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofPerson"
                                                placeholder="Enter full name here"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NameofPerson'].errors }" id="NameofPerson" />
                                            <div *ngIf="submitted && UserForm.controls['NameofPerson'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['NameofPerson'].errors.required">Required Field</div>
                                            </div>
                                            <label for="NameofPerson">Name of Person<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="MobileNo"
                                                placeholder="MobileNo"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MobileNo'].errors }" id="MobileNo" />
                                            <div *ngIf="submitted && UserForm.controls['MobileNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['MobileNo'].errors.required">Required Field</div>
                                                <div *ngIf="UserForm.controls['MobileNo'].errors?.pattern">invalid MobileNo</div>
                                              </div>
                                            <label for="MobileNo">Mobile No</label>
                                            </div>
                                          </div>





                                          <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <select #MuncArea [ngClass]="{'is-invalid':submitted && UserForm.controls['MuncipalArea'].errors}" (change)="this.UserForm.get('MuncipalArea')?.setValue(MuncArea.value)" class="form-control" required class="form-select" id="floatingSelect">  
                                                    <option value="" selected></option>
                                                    <option *ngFor="let MunicipalAreaItem  of muncipaldata" value={{MunicipalAreaItem.MunicipalArea}}>{{MunicipalAreaItem.MunicipalArea}}</option>
                                                </select>
                                                
                                                <div *ngIf="submitted && UserForm.controls['MuncipalArea'].errors" class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['MuncipalArea'].errors">Required Field </div>
                                                </div>
                                             <label for="MuncipalArea" for="floatingSelect">Muncipal Area</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Locality"
                                                placeholder="Locality"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Locality'].errors }" id="MobileNo" />
                                            <div *ngIf="submitted && UserForm.controls['Locality'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['Locality'].errors.required">Required Field
                                                </div>
                                              </div>
                                            <label for="Locality">Locality<span style="color: crimson;">*</span></label>
                                            </div>
                                          </div>


                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ExactPlace"
                                                placeholder="ExactPlace"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['ExactPlace'].errors }" id="MobileNo" />
                                            <div *ngIf="submitted && UserForm.controls['ExactPlace'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['ExactPlace'].errors.required">Required Field
                                                </div>
                                              </div>
                                            <label for="ExactPlace">Exact Place<span style="color: crimson;">*</span></label>
                                            </div>
                                          </div>



                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="LandMark"
                                                placeholder="LandMark"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['LandMark'].errors }" id="LandMark" />
                                            <div *ngIf="submitted && UserForm.controls['LandMark'].errors"
                                                class="invalid-feedback">
                                                
                                              </div>
                                            <label for="LandMark">LandMark</label>
                                            </div>
                                          </div>



                                          <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <select #job [ngClass]="{'is-invalid':submitted && UserForm.controls['NatureofJob'].errors}"  class="form-control"
                                                (change)="this.UserForm.get('NatureofJob')?.setValue(job.value)" required class="form-select" id="floatingSelect">
                                                    <option value="" selected></option>
                                                    <option *ngFor="let NatureofJobItem of naturedata"  value={{NatureofJobItem.NatureofJob}}>{{NatureofJobItem.NatureofJob}}</option>
                                                </select>

                                                <div *ngIf="submitted && UserForm.controls['NatureofJob'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="UserForm.controls['NatureofJob'].errors">Required Field
                                                    </div>
                                                    
                                                </div>
                                                <label for="NatureofJob">Nature of Job<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                              <div class="mt-10">
                                               <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i> </div>
                                            </div>  
                                             </div>
                                            <div class="col-lg-7 col-md-7 col-sm-7">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Captcha"
                                                placeholder="Captcha"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Captcha'].errors }" id="Captcha" />
                                            <div *ngIf="submitted && UserForm.controls['Captcha'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['Captcha'].errors.required">Required Field
                                                </div>
                                              </div>
                                            <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                                            </div>
                                            </div>
                                               
                                            </div>
                                          </div>
                                    </div>
                                    <div class="mt-20">
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                                <div class="text-right mt-20">
                                    <p class="mb-0"><b><i>Please note all fields marked <span class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
