<!--Full width header End-->

<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>MGL Quality Policy</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class=""><a >Policies</a></li>
                <li class="active" aria-current="page">MGL Quality Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                    <!-- <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="">
                        <div class="">
                            <!--<img src="/UploadAssets/UploadedFiles/_health-top-hse-Policy_ac02d3d711.jpg" alt="" style="width: 100%;" />-->
                            <p>&nbsp;</p><!--<p>Date: 21.06.2016</p>-->
                            <p>We at Mahanagar Gas Ltd are committed to provide quality service for supplying safe
                                and uninterrupted Natural Gas to our valued customers.</p>
                            <h6>The management is committed in achieving this by:</h6>
                            <ul class="unordered-list">
                                <li>Complying with applicable national/ international standards, codes and
                                    regulatory requirements.</li>
                                <li>Continuous development of competence of employees and contractors through
                                    internal and external assisted training programs.</li>
                                <li>Maintaining a Quality Management System in accordance with ISO 9001: 2015 by
                                    adopting a Risk Based Thinking Approach.</li>
                                <li>Adopting new and updated technologies for emergency preparedness and striving to
                                    upkeep plant &amp; equipment to best possible levels for uninterrupted &amp;
                                    safe supply of natural gas.</li>
                                <li>Continually improve the effectiveness of our processes &amp; quality system by
                                    measurement and monitoring; interaction with all concerned stakeholders for
                                    enhanced customer satisfaction.</li>
                                <li>Communicating the Quality Policy and the management system requirements to all
                                    employees and other interested parties, working with us in ensuring the delivery
                                    of our products and services.</li>
                            </ul>
                            <p><strong><em>NB: The Policy is made available to the public and interested
                                        parties.</em></strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>