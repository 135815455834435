<!doctype html>
<html>
<head>
<meta charset="utf-8">
<title>Untitled Document</title>
</head>

<body>
    <!--Full width header End-->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1> New CNG Vehicle FAQs
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav</a></li>
                <li class=""><a >FAQs</a></li>
                <li class="active" aria-current="page"><a>New CNG Vehicle FAQs</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

             
            </div> 
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <h2 class="dual-heading mb-30"></h2>
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="rs-faq applications-natural-gas">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link" href="#collapseOne" data-toggle="collapse"
                                            aria-expanded="false">
                                           1. What is this MGL CNG Mahotsav?</a>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div class="row">
                                                <div _ngcontent-glm-c121=""
                                                    class="accordianindustriesCont dealers_contace_table"
                                                    style="width: 100%; margin-right: 0px;">
                                                    <div _ngcontent-glm-c121="" style="overflow-x: auto;">
                                                        <p>MGL has announced MGL CNG Mahotsav to promote CNG as a fuel in Passenger car and Commercial Vehicles Segment. This offer will be applicable when you purchase select variant of CNG vehicles in passenger car and commercial vehicle segment. This is a special offer designed for the upcoming festive season i.e., Ganpati festival, Navratri, Diwali, Christmas etc.</p>
                                                      
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwo" data-toggle="collapse"
                                            data-bs-target="#collapsetwo" aria-expanded="false">2. What is the duration of this scheme?
                                        </a>
                                    </div>
                                    <div id="collapsetwo" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body" style="overflow-x: auto;">
                                        <!-- The scheme is valid from 1st September 2023 till 31’st December 2023.  -->
                                        The scheme validity of various OEMs is as under
                                            <div class="table-responsive mt-3">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="5" class="table table-bordered">
  <tr>
    <th>Name of OEM (New purchase) </th>
    <th >Category of Vehicle</th>
    <th>Scheme Period</th>
  </tr>
  <tr>
    <td >Maruti    suzuki </td>
    <td > Cars</td>
    <td>5th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >Maruti    suzuki</td>
    <td >SCV</td>
    <td>5th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >Mahindra    &amp; Mahindra</td>
    <td >SCV</td>
    <td>18th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >TATA    Motors Ltd</td>
    <td >Cars</td>
    <td>18th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >Hyundai    Motors Ltd</td>
    <td >Cars</td>
    <td>18th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >TATA    Motors Ltd</td>
    <td >SCV</td>
    <td>18th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >Ashok    Leyland</td>
    <td >SCV</td>
    <td>18th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >Ashok    Leyland</td>
    <td >LCV/HCV</td>
    <td>5th Sept - 31st Dec</td>
  </tr>
  <tr>
    <td >Eicher</td>
    <td >LCV/HCV</td>
    <td>07th Oct-31st    Dec</td>
  </tr>
</table>
                                                       </div>
                                        </div>
                                        
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsethree" data-toggle="collapse"
                                            data-bs-target="#collapsethree" aria-expanded="false">3. What select models of vehicles are covered in this scheme?
                                        </a>
                                    </div>
                                    <div id="collapsethree" class="collapse padding-space" data-bs-parent="#accordion" style="">
                                       
                                       <div class="table-responsive">
                                
                                        <table border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
  <tr>
    <th valign="top"><strong>OEM</strong></th>
    <th valign="top"><strong>Category</strong></th>
    <th valign="top"><strong>Models of vehicles</strong></th>
  </tr>
  <tr>
    <td  rowspan="6" valign="top">Maruti Suzuki    India Limited</td>
    <td  rowspan="6" valign="top">CAR</td>
    <td  valign="top">Alto K10 CNG,</td>
  </tr>
  <tr>
    <td  valign="top">S-Presso CNG,</td>
  </tr>
  <tr>
    <td  valign="top">Celerio CNG,</td>
  </tr>
  <tr>
    <td  valign="top">Wagon R CNG (Non    H3 Cars),</td>
  </tr>
  <tr>
    <td  valign="top">Swift CNG,</td>
  </tr>
  <tr>
    <td  valign="top">Baleno CNG and    Brezza CNG</td>
  </tr>
  <tr>
    <td  valign="top">Maruti Suzuki    India Limited</td>
    <td  valign="top">SCV</td>
    <td  valign="top">SUPER CARRY CNG</td>
  </tr>
  <tr>
    <td  rowspan="4">Ashok Leyland</td>
    <td  valign="top">ICV</td>
    <td  valign="top">Ecomet 1015 20 FT</td>
  </tr>
  <tr>
    <td  valign="top">ICV</td>
    <td  valign="top">Ecomet 1415 20 FT</td>
  </tr>
  <tr>
    <td  valign="top">SCV</td>
    <td  valign="top">DOST CNG</td>
  </tr>
  <tr>
    <td  valign="top">HCV</td>
    <td  valign="top">Ecomet 1615 22 FT</td>
  </tr>
  <tr>
    <td  valign="top">Hyundai Motors</td>
    <td  valign="top">CAR</td>
    <td valign="top">Grand i10 NIOS,    Aura</td>
  </tr>
  <tr>
    <td  rowspan="2">TATA Motors</td>
    <td  valign="top">CAR</td>
    <td  valign="top">Tiago, Tigor and    Altroz</td>
  </tr>
  <tr>
    <td  valign="top">SCV</td>
    <td  valign="top">Ace CNG, Ace CNG+    and Intra V20</td>
  </tr>
  <tr>
    <td  valign="top">Mahindra &amp; Mahindra    Ltd</td>
    <td  valign="top">SCV</td>
    <td  valign="top">Pick UP CNG and    SUPRO CNG</td>
  </tr>
  <tr>
    <td  valign="top">Eicher</td>
    <td  valign="top">LCV/HCV</td>
    <td  valign="top"><table border="0" cellspacing="0" cellpadding="0" align="left" >
      <tr>
        <td >Pro      2049 CNG ,  Pro 2059 CNG, Pro 2059XP      CNG, Pro 2075 CNG, Pro 2095XP CNG, Pro 2110XP CNG, Pro 2114XP CNG, Pro 3018      CNG</td>
      </tr>
    </table></td>
  </tr>
</table>

                                        </div>
                                        


                                </div>
                                </div>
    
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link collapsed" href="#collapsefour" data-toggle="collapse"
                                        data-bs-target="#collapsefour" aria-expanded="false">4. What is the scheme amount which is offered under the scheme?
                                    </a>
                                </div>
                                <div id="collapsefour" class="collapse" data-bs-parent="#accordion" style="">
                                    <div class="card-body" style="overflow-x: auto;">
                                      <p>     MGL is offering a CNG fuel card (in form of ICICI bank gift prepaid card) for availing free CNG as of amount as given below.</p>
                                      <div class="mb-30">
                                        <img src="assets/images/card.png" class="w-70">
                                    </div>
                                    
                                      <div class="table-responsive">
                                
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Vehicle Segment </th>
                                                    <th>Free CNG Fuel card worth</th>
                                                    
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Car </td>
                                                    <td> Rs. 19,999/- </td>   
                                                </tr>
                                                <tr>
                                                    <td>SCV</td>
                                                    <td>Rs. 19,999/-</td>
                                                         
                                                </tr>
                                                <tr>                              
                                                    <td>LCV / HCV </td>
                                                    <td>Rs. 2 Lakhs to 5 Lakhs depending upon the  vehicle variant. Refer  <a href="https://www.mahanagargas.com/cng/cng-offers-schemes/new-cng-vehicle-promotion">https://www.mahanagargas.com/cng/cng-offers-schemes/new-cng-vehicle-promotion</a> for details</td>
                                                      
                                                </tr>
                                               
                                               
                                               
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    </div>
                                    </div>
                                 </div>

                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsefive" data-toggle="collapse"
                                                data-bs-target="#collapsefive" aria-expanded="false">5. How do I avail the scheme? </a>
                                        </div>
                                        <div id="collapsefive" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                                You must purchase the new vehicle and OEM dealership in the scheme period, and they will hand over the MGL CNG fuel card on delivery of the vehicle.
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseSix" data-toggle="collapse"
                                                data-bs-target="#collapseSix" aria-expanded="false">6.  I purchased my vehicle on 31st August 2023. Will I be eligible for the scheme?
                                            </a>
                                        </div>
                                        <div id="collapseSix" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                                No. The vehicle needs to be purchased in the scheme period as mentioned above.
                                               
                                            </div>
                                        </div>
                                    </div>
                                  <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseSeven" data-toggle="collapse"
                                                data-bs-target="#collapseSeven" aria-expanded="false">7.    If I buy 2 vehicles, can I claim 2 discounts?
                                            </a>
                                        </div>
                                        <div id="collapseSeven" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                                This scheme is applicable to every new vehicle purchase. If you purchase 2 vehicles, you will be given 01 fuel card, but the benefit amount shall be equivalent to two purchases. As per bank norms, if total benefit amount exceeds 20,000/-, then TDS @10% shall be deducted subject to PAN is valid and operative.
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseeight" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">8.    Which areas are covered under the scheme?
                                            </a>
                                        </div>
                                        <div id="collapseeight" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
The scheme shall be valid only for vehicles registered in Maharashtra under series number MH-01, MH-02, MH-03, MH-04, MH-05, MH-06, MH-43, MH-46, and MH-47 RTOs respectively as these RTOs basically cover MGLs Geographical Area.                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsenine" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">9.   Where can we use this fuel card?
                                            </a>
                                        </div>
                                        <div id="collapsenine" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            This fuel card will be used “only for CNG filling” at entire network of MGL of 310+ CNG stations.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseeleven" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">10.   Can the benefit of this scheme be extended to customers purchasing vehicles at MH48 registration?
                                            </a>
                                        </div>
                                        <div id="collapseeleven" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            NO. This scheme has been made applicable and approved for vehicles registered at RTOs within MGL’s Geographical Areas (GA) only. Since Vasai is out of MGL GA, MH48 is not covered.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsetwele" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">11.   Is it necessary to use the gift card for refueling in the purchased vehicle only? 
                                            </a>
                                        </div>
                                        <div id="collapsetwele" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            No. The Card can be used to Refuel any CNG Vehicle at MGL CNG stations.
                                            </div>
                                        </div>
                                    </div>
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsethirteen" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">12.   Is this scheme applicable for customers purchasing the vehicle as a taxi on the yellow number plate? 
                                            </a>
                                        </div>
                                        <div id="collapsethirteen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            NO. Taxi vehicles are not included in the scheme.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsefourteen" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">13.   Is this scheme applicable for customers purchasing the vehicle in the name of the company? 
                                            </a>
                                        </div>
                                        <div id="collapsefourteen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            Yes. But card shall be activated in name of individual user authorised by Company.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsefifteen" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">14.   Is the scheme applicable if invoices in the name of company sole proprietorship? 
                                            </a>
                                        </div>
                                        <div id="collapsefifteen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            Yes, the card shall be activated in the name of proprietor.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapsesixteen" data-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false">15.   Is the scheme applicable for customers who purchase vehicle in BH number plate? 
                                            </a>
                                        </div>
                                        <div id="collapsesixteen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            If the registration of these vehicles is done at 09 regional RTOs falling in MGL Geographical Areas specified above, the same is applicable.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapseseventeen" data-toggle="collapse"
                                                data-bs-target="#collapseseventeen" aria-expanded="false">16.   Is the scheme applicable for subscription cases?
                                            </a>
                                        </div>
                                        <div id="collapseseventeen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                            The scheme is applicable only to new vehicles purchased within the scheme period. Hence leasing/ subscription model is not covered under this scheme.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapse-eighteen" data-toggle="collapse"
                                                data-bs-target="#collapse-eighteen" aria-expanded="false">17.   How much amount will be loaded in the Card at the time of activation of Rs 19,999/ fuel card?
                                            </a>
                                        </div>
                                        <div id="collapse-eighteen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                           Total amount to be loaded by MGL under this scheme is INR 19999. Initially Rs. 10,000/- will be loaded onto the card. Once the initial amount of Rs 10,000/- is utilized and then will be replenished by balance amount in next month subject to maximum of Rs 10000/-.
                                            </div>
                                        </div>
                                    </div>
                                
                                <div class="card">
                                        <div class="card-header">
                                            <a class="card-link collapsed" href="#collapse-nineteen" data-toggle="collapse"
                                                data-bs-target="#collapse-nineteen" aria-expanded="false">18.   How to check the balance in the card?
                                            </a>
                                        </div>
                                        <div id="collapse-nineteen" class="collapse" data-bs-parent="#accordion" style="">
                                            <div class="card-body" style="overflow-x: auto;">
                                           After each usage, SMS will come to registered mobile number like any other credit/debit card. Additionally, you can call customer care no. of ICICI to find out the card balance details. The customer care number is displayed on the back side of the card.
                                            </div>
                                        </div>
                                    </div>
                                        
                                   
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</body>
</html>
