import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''

  UserForm !: FormGroup
  data: any
  submitted: boolean = false

  constructor(private activatedRoute: ActivatedRoute, private FormBuilder: FormBuilder, public FaqService: FaqServiceService, private service: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.UserForm = this.FormBuilder.group({
      NewPass: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]],
      ConfPass: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]],
      BpNo: [''],
      CaNo: [''],
	  Email: ['']
    });
	
	this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      this.service.getUserInfoID(id).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response)).details
        this.UserForm.get('BpNo')?.setValue(data.BPNo)
        this.UserForm.get('CaNo')?.setValue(data.CANo)
        this.UserForm.get('Email')?.setValue(data.Email)
		
      })
    })
  }

  clearStatus() {
    setTimeout(() => {
      this.alertMsg = ''
    }, 3000)
  }

  submit() {
    this.submitted = true
    //console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    if (this.UserForm.get('NewPass')?.value === this.UserForm.get('ConfPass')?.value) {
      
      this.service.resetUserPass(this.UserForm.value).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))

       // console.log(response)
        this.alertMsg = data.message
        if (!data.Response) {
          this.alertColor = 'rgba(255, 94, 109, 0.2)'
          this.alertTextColor = '#c24753'
          this.alertBorder = '1px solid #eb5664;'
          this.clearStatus()
        } else {
          this.alertColor = 'rgba(4, 183, 107, 0.2)'
          this.alertTextColor = '#038b51'
          this.alertBorder = '1px solid #04a862;'
          this.UserForm.reset()
          this.submitted = false
          this.clearStatus()
        }
      })
    } else {
      this.alertMsg = "New Password And Confirm Password is not Matching"
      this.alertColor = "rgba(255, 94, 109, 0.2)"
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      this.clearStatus()
    }
  }
}