import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { VehiclepromotionService } from 'src/app/Service/vehiclepromotion.service';
import { ApplyOutletItem } from 'src/app/models/applyforoutket';
import { DistrictItem } from 'src/app/models/applyforoutket';
import { PlotItem } from 'src/app/models/applyforoutket';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { Router,ActivatedRoute } from '@angular/router';
//import { PSM, createWorker } from 'tesseract.js';
import { log } from 'console';

@Component({
  selector: 'app-retrofittersappedit',
  templateUrl: './retrofittersappedit.component.html',
  styleUrls: ['./retrofittersappedit.component.css']
})
export class RetrofittersappeditComponent {

 promotionForm!: FormGroup
  submitted = false
  invoiceimg: any;
  cngkitimg:any;
  rcbookimg:any;
  vinsuranceimg:any;

  
   pancardimg: any;
   installationcertificateimg: any;
  LpgSlip: any
  captcha: any;
  myFileArray: any[] = [];
  
 



  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';



  

 

 

  display:string='none';
  Popup:any='';
  popuppData:any;
  retroffiterLoginRole:any;

     previouspageData:any;


  NoofCNGCylindersFlag = false;
  NoofCNGCylindersFlag1 = false;
  NoofCNGCylindersFlag2 = false;
  NoofCNGCylindersFlag3 = false;
  NoofCNGCylindersFlag4 = false;
  NoofCNGCylindersFlag5 = false;
  NoofCNGCylindersFlag6 = false;
  NoofCNGCylindersFlag7 = false;
  NoofCNGCylindersFlag8 = false;
  NoofCNGCylindersFlag9 = false;

   commercialrcbookimg: any;
   commercialrcbookimgFlag = false;

  

  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: VehiclepromotionService,
    private UserService : UserRegistrationService,
    public router: Router,private activatedRoute: ActivatedRoute,) {

         this.activatedRoute.queryParams.subscribe((data: any) => {
        //console.log(data);
        this.previouspageData = JSON.parse(data.data);
       // console.log(this.previouspageData);
        this.retroffiterLoginRole = this.previouspageData.role;
      });
//console.log(this.retroffiterLoginRole);

     }

  ngOnInit(): void {
    // this.UserService.checkLogin1()

    // let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential1')))
    
    //  if (data) {
    //   let retroffiterLogindata = JSON.parse(data);

    //   let retroffiterData = retroffiterLogindata.details
    //  this.retroffiterLoginRole = retroffiterData.Role;
    //  console.log('details data',this.retroffiterLoginRole)
    // }

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    // this.getdist()
    // this.getPlot()
    this.captchaGenerate()
    this.promotionForm = this.FormBuilder.group({
        Name: ['', [Validators.required]],
        ContactNumber: ['', Validators.compose([Validators.required,Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$")])],
        Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        invoice_copy: ['', Validators.required],
        cng_certificate: ['', Validators.required],
        rc_book: ['', Validators.required],
        //  l_vehicle_insurance_copy: ['', Validators.required],
        pan_card_img: ['', Validators.required],
        Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
        // dob: ['', Validators.required],
        Captcha: ['', [Validators.required]],
        vehicleTpye: ['', [Validators.required]],
        //vehicleNo: ['', Validators.compose([Validators.pattern("^[A-Za-z]{2}[0-9]{1,2}[A-Za-z]{1,2}[0-9]{4}$"), Validators.required])],
        vehicleNo: ['', [Validators.required]],
        kit_Supplier: ['', [Validators.required]],
        ECMSrNo: ['', [Validators.required]],
        Pressure_regulator_SrNo: ['', [Validators.required]],
        role: [''],
        installation_certificate: ['', Validators.required],
        NoofCNGCylinders: [''],
        cylinder_make: [''],
        cylinder_srno: [''],
        year_of_manufacturing: [''],
        cylinder_make1: [''],
        cylinder_srno1: [''],
        year_of_manufacturing1: [''],
        cylinder_make2: [''],
        cylinder_srno2: [''],
        year_of_manufacturing2: [''],
        cylinder_make3: [''],
        cylinder_srno3: [''],
        year_of_manufacturing3: [''],
        cylinder_make4: [''],
        cylinder_srno4: [''],
        year_of_manufacturing4: [''],
        cylinder_make5: [''],
        cylinder_srno5: [''],
        year_of_manufacturing5: [''],
        cylinder_make6: [''],
        cylinder_srno6: [''],
        year_of_manufacturing6: [''],
        cylinder_make7: [''],
        cylinder_srno7: [''],
        year_of_manufacturing7: [''],
        cylinder_make8: [''],
        cylinder_srno8: [''],
        year_of_manufacturing8: [''],
        cylinder_make9: [''],
        cylinder_srno9: [''],
        year_of_manufacturing9: [''],
        commercial_rc_book: [''],
     

    });

    this.promotionForm.get('Name')?.setValue(this.previouspageData.Name);
    this.promotionForm.get('ContactNumber')?.setValue(this.previouspageData.ContactNumber);
    this.promotionForm.get('Email')?.setValue(this.previouspageData.Email);
    this.promotionForm.get('invoice_copy')?.setValue(this.previouspageData.invoice_copy);
    this.promotionForm.get('cng_certificate')?.setValue(this.previouspageData.cng_certificate);
    this.promotionForm.get('rc_book')?.setValue(this.previouspageData.rc_book);
     this.promotionForm.get('commercial_rc_book')?.setValue(this.previouspageData.commercial_rc_book);
    this.promotionForm.get('pan_card_img')?.setValue(this.previouspageData.pan_card_img);
    this.promotionForm.get('installation_certificate')?.setValue(this.previouspageData.installation_certificate);
    this.promotionForm.get('Pan')?.setValue(this.previouspageData.Pan);
    this.promotionForm.get('vehicleTpye')?.setValue(this.previouspageData.vehicleTpye);
    this.promotionForm.get('vehicleNo')?.setValue(this.previouspageData.vehicleNo);
    this.promotionForm.get('kit_Supplier')?.setValue(this.previouspageData.kit_Supplier);
    this.promotionForm.get('ECMSrNo')?.setValue(this.previouspageData.ECMSrNo);
    this.promotionForm.get('Pressure_regulator_SrNo')?.setValue(this.previouspageData.Pressure_regulator_SrNo);
    this.promotionForm.get('role')?.setValue(this.previouspageData.role);
    this.promotionForm.get('NoofCNGCylinders')?.setValue(this.previouspageData.NoofCNGCylinders)
    this.promotionForm.get('cylinder_make')?.setValue(this.previouspageData.cylinder_make)
    this.promotionForm.get('cylinder_srno')?.setValue(this.previouspageData.cylinder_srno)
    this.promotionForm.get('year_of_manufacturing')?.setValue(this.previouspageData.year_of_manufacturing)
    this.promotionForm.get('cylinder_make1')?.setValue(this.previouspageData.cylinder_make1)
    this.promotionForm.get('cylinder_srno1')?.setValue(this.previouspageData.cylinder_srno1)
    this.promotionForm.get('year_of_manufacturing1')?.setValue(this.previouspageData.year_of_manufacturing1)
    this.promotionForm.get('cylinder_make2')?.setValue(this.previouspageData.cylinder_make2)
    this.promotionForm.get('cylinder_srno2')?.setValue(this.previouspageData.cylinder_srno2)
    this.promotionForm.get('year_of_manufacturing2')?.setValue(this.previouspageData.year_of_manufacturing2)
    this.promotionForm.get('cylinder_make3')?.setValue(this.previouspageData.cylinder_make3)
    this.promotionForm.get('cylinder_srno3')?.setValue(this.previouspageData.cylinder_srno3)
    this.promotionForm.get('year_of_manufacturing3')?.setValue(this.previouspageData.year_of_manufacturing3)
    this.promotionForm.get('cylinder_make4')?.setValue(this.previouspageData.cylinder_make4)
    this.promotionForm.get('cylinder_srno4')?.setValue(this.previouspageData.cylinder_srno4)
    this.promotionForm.get('year_of_manufacturing4')?.setValue(this.previouspageData.year_of_manufacturing4)
    this.promotionForm.get('cylinder_make5')?.setValue(this.previouspageData.cylinder_make5)
    this.promotionForm.get('cylinder_srno5')?.setValue(this.previouspageData.cylinder_srno5)
    this.promotionForm.get('year_of_manufacturing5')?.setValue(this.previouspageData.year_of_manufacturing5)
    this.promotionForm.get('cylinder_make6')?.setValue(this.previouspageData.cylinder_make6)
    this.promotionForm.get('cylinder_srno6')?.setValue(this.previouspageData.cylinder_srno6)
    this.promotionForm.get('year_of_manufacturing6')?.setValue(this.previouspageData.year_of_manufacturing6)
    this.promotionForm.get('cylinder_make7')?.setValue(this.previouspageData.cylinder_make7)
    this.promotionForm.get('cylinder_srno7')?.setValue(this.previouspageData.cylinder_srno7)
    this.promotionForm.get('year_of_manufacturing7')?.setValue(this.previouspageData.year_of_manufacturing7)
    this.promotionForm.get('cylinder_make8')?.setValue(this.previouspageData.cylinder_make8)
    this.promotionForm.get('cylinder_srno8')?.setValue(this.previouspageData.cylinder_srno8)
    this.promotionForm.get('year_of_manufacturing8')?.setValue(this.previouspageData.year_of_manufacturing8)
    this.promotionForm.get('cylinder_make9')?.setValue(this.previouspageData.cylinder_make9)
    this.promotionForm.get('cylinder_srno9')?.setValue(this.previouspageData.cylinder_srno9)
    this.promotionForm.get('year_of_manufacturing9')?.setValue(this.previouspageData.year_of_manufacturing9)
     
     this.selectedCylinders();

     this.checkVtype();

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }



selectedCylinders(){
    //console.log(this.promotionForm.get('NoofCNGCylinders')?.value)

    if (this.promotionForm.get('NoofCNGCylinders')?.value=='1 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = false;
      this.NoofCNGCylindersFlag2 = false;
      this.NoofCNGCylindersFlag3 = false;
      this.NoofCNGCylindersFlag4 = false;
      this.NoofCNGCylindersFlag5 = false;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);

      this.promotionForm.get('cylinder_make1')?.clearValidators();
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.clearValidators();
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.clearValidators();
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.clearValidators();
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make3')?.clearValidators();
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.clearValidators();
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.clearValidators();
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.clearValidators();
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.clearValidators();
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.clearValidators();
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValue('');
      this.promotionForm.get('cylinder_srno1')?.setValue('');
      this.promotionForm.get('year_of_manufacturing1')?.setValue('');

      this.promotionForm.get('cylinder_make2')?.setValue('');
      this.promotionForm.get('cylinder_srno2')?.setValue('');
      this.promotionForm.get('year_of_manufacturing2')?.setValue('');

      this.promotionForm.get('cylinder_make3')?.setValue('');
      this.promotionForm.get('cylinder_srno3')?.setValue('');
      this.promotionForm.get('year_of_manufacturing3')?.setValue('');

      this.promotionForm.get('cylinder_make4')?.setValue('');
      this.promotionForm.get('cylinder_srno4')?.setValue('');
      this.promotionForm.get('year_of_manufacturing4')?.setValue('');

      this.promotionForm.get('cylinder_make5')?.setValue('');
      this.promotionForm.get('cylinder_srno5')?.setValue('');
      this.promotionForm.get('year_of_manufacturing5')?.setValue('');

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');

      
      
    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='2 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = false;
      this.NoofCNGCylindersFlag3 = false;
      this.NoofCNGCylindersFlag4 = false;
      this.NoofCNGCylindersFlag5 = false;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.clearValidators();
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.clearValidators();
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make3')?.clearValidators();
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.clearValidators();
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.clearValidators();
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.clearValidators();
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.clearValidators();
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.clearValidators();
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValue('');
      this.promotionForm.get('cylinder_srno2')?.setValue('');
      this.promotionForm.get('year_of_manufacturing2')?.setValue('');

      this.promotionForm.get('cylinder_make3')?.setValue('');
      this.promotionForm.get('cylinder_srno3')?.setValue('');
      this.promotionForm.get('year_of_manufacturing3')?.setValue('');

      this.promotionForm.get('cylinder_make4')?.setValue('');
      this.promotionForm.get('cylinder_srno4')?.setValue('');
      this.promotionForm.get('year_of_manufacturing4')?.setValue('');

      this.promotionForm.get('cylinder_make5')?.setValue('');
      this.promotionForm.get('cylinder_srno5')?.setValue('');
      this.promotionForm.get('year_of_manufacturing5')?.setValue('');

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');

    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='3 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = false;
      this.NoofCNGCylindersFlag4 = false;
      this.NoofCNGCylindersFlag5 = false;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

    

      this.promotionForm.get('cylinder_make3')?.clearValidators();
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.clearValidators();
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.clearValidators();
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.clearValidators();
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.clearValidators();
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.clearValidators();
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();
      
      this.promotionForm.get('cylinder_make3')?.setValue('');
      this.promotionForm.get('cylinder_srno3')?.setValue('');
      this.promotionForm.get('year_of_manufacturing3')?.setValue('');

      this.promotionForm.get('cylinder_make4')?.setValue('');
      this.promotionForm.get('cylinder_srno4')?.setValue('');
      this.promotionForm.get('year_of_manufacturing4')?.setValue('');

      this.promotionForm.get('cylinder_make5')?.setValue('');
      this.promotionForm.get('cylinder_srno5')?.setValue('');
      this.promotionForm.get('year_of_manufacturing5')?.setValue('');

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');


    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='4 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = false;
      this.NoofCNGCylindersFlag5 = false;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;


      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

    

      this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.clearValidators();
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.clearValidators();
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.clearValidators();
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.clearValidators();
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValue('');
      this.promotionForm.get('cylinder_srno4')?.setValue('');
      this.promotionForm.get('year_of_manufacturing4')?.setValue('');

      this.promotionForm.get('cylinder_make5')?.setValue('');
      this.promotionForm.get('cylinder_srno5')?.setValue('');
      this.promotionForm.get('year_of_manufacturing5')?.setValue('');

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');


    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='5 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = true;
      this.NoofCNGCylindersFlag5 = false;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

    

      this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.clearValidators();
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.clearValidators();
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.setValue('');
      this.promotionForm.get('cylinder_srno5')?.setValue('');
      this.promotionForm.get('year_of_manufacturing5')?.setValue('');

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');
      

    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='6 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = true;
      this.NoofCNGCylindersFlag5 = true;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

    

      this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');
      

    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='7 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = true;
      this.NoofCNGCylindersFlag5 = true;
      this.NoofCNGCylindersFlag6 = true;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

     this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');

    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='8 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = true;
      this.NoofCNGCylindersFlag5 = true;
      this.NoofCNGCylindersFlag6 = true;
      this.NoofCNGCylindersFlag7 = true;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

      
      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

    

      this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');


    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='9 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = true;
      this.NoofCNGCylindersFlag5 = true;
      this.NoofCNGCylindersFlag6 = true;
      this.NoofCNGCylindersFlag7 = true;
      this.NoofCNGCylindersFlag8 = true;
      this.NoofCNGCylindersFlag9 = false;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

    

      this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');


    }else if (this.promotionForm.get('NoofCNGCylinders')?.value=='10 Nos') {
      this.NoofCNGCylindersFlag = true;
      this.NoofCNGCylindersFlag1 = true;
      this.NoofCNGCylindersFlag2 = true;
      this.NoofCNGCylindersFlag3 = true;
      this.NoofCNGCylindersFlag4 = true;
      this.NoofCNGCylindersFlag5 = true;
      this.NoofCNGCylindersFlag6 = true;
      this.NoofCNGCylindersFlag7 = true;
      this.NoofCNGCylindersFlag8 = true;
      this.NoofCNGCylindersFlag9 = true;

      this.promotionForm.get('cylinder_make')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.setValidators(Validators.required);
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.setValidators(Validators.required);
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

    }else{
      this.NoofCNGCylindersFlag = false;
      this.NoofCNGCylindersFlag1 = false;
      this.NoofCNGCylindersFlag2 = false;
      this.NoofCNGCylindersFlag3 = false;
      this.NoofCNGCylindersFlag4 = false;
      this.NoofCNGCylindersFlag5 = false;
      this.NoofCNGCylindersFlag6 = false;
      this.NoofCNGCylindersFlag7 = false;
      this.NoofCNGCylindersFlag8 = false;
      this.NoofCNGCylindersFlag9 = false;

     

      this.promotionForm.get('cylinder_make')?.clearValidators();
      this.promotionForm.get('cylinder_make')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno')?.clearValidators();
      this.promotionForm.get('cylinder_srno')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make1')?.clearValidators();
      this.promotionForm.get('cylinder_make1')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno1')?.clearValidators();
      this.promotionForm.get('cylinder_srno1')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing1')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing1')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make2')?.clearValidators();
      this.promotionForm.get('cylinder_make2')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno2')?.clearValidators();
      this.promotionForm.get('cylinder_srno2')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing2')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing2')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make3')?.clearValidators();
      this.promotionForm.get('cylinder_make3')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno3')?.clearValidators();
      this.promotionForm.get('cylinder_srno3')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing3')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing3')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make4')?.clearValidators();
      this.promotionForm.get('cylinder_make4')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno4')?.clearValidators();
      this.promotionForm.get('cylinder_srno4')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing4')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing4')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make5')?.clearValidators();
      this.promotionForm.get('cylinder_make5')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno5')?.clearValidators();
      this.promotionForm.get('cylinder_srno5')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing5')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing5')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make6')?.clearValidators();
      this.promotionForm.get('cylinder_make6')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno6')?.clearValidators();
      this.promotionForm.get('cylinder_srno6')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing6')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing6')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make7')?.clearValidators();
      this.promotionForm.get('cylinder_make7')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno7')?.clearValidators();
      this.promotionForm.get('cylinder_srno7')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing7')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing7')?.updateValueAndValidity();


      this.promotionForm.get('cylinder_make8')?.clearValidators();
      this.promotionForm.get('cylinder_make8')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno8')?.clearValidators();
      this.promotionForm.get('cylinder_srno8')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing8')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing8')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make9')?.clearValidators();
      this.promotionForm.get('cylinder_make9')?.updateValueAndValidity();
      this.promotionForm.get('cylinder_srno9')?.clearValidators();
      this.promotionForm.get('cylinder_srno9')?.updateValueAndValidity();
      this.promotionForm.get('year_of_manufacturing9')?.clearValidators();
      this.promotionForm.get('year_of_manufacturing9')?.updateValueAndValidity();

      this.promotionForm.get('cylinder_make')?.setValue('');
      this.promotionForm.get('cylinder_srno')?.setValue('');
      this.promotionForm.get('year_of_manufacturing')?.setValue('');

      this.promotionForm.get('cylinder_make1')?.setValue('');
      this.promotionForm.get('cylinder_srno1')?.setValue('');
      this.promotionForm.get('year_of_manufacturing1')?.setValue('');

      this.promotionForm.get('cylinder_make2')?.setValue('');
      this.promotionForm.get('cylinder_srno2')?.setValue('');
      this.promotionForm.get('year_of_manufacturing2')?.setValue('');

      this.promotionForm.get('cylinder_make3')?.setValue('');
      this.promotionForm.get('cylinder_srno3')?.setValue('');
      this.promotionForm.get('year_of_manufacturing3')?.setValue('');

      this.promotionForm.get('cylinder_make4')?.setValue('');
      this.promotionForm.get('cylinder_srno4')?.setValue('');
      this.promotionForm.get('year_of_manufacturing4')?.setValue('');

      this.promotionForm.get('cylinder_make5')?.setValue('');
      this.promotionForm.get('cylinder_srno5')?.setValue('');
      this.promotionForm.get('year_of_manufacturing5')?.setValue('');

      this.promotionForm.get('cylinder_make6')?.setValue('');
      this.promotionForm.get('cylinder_srno6')?.setValue('');
      this.promotionForm.get('year_of_manufacturing6')?.setValue('');

      this.promotionForm.get('cylinder_make7')?.setValue('');
      this.promotionForm.get('cylinder_srno7')?.setValue('');
      this.promotionForm.get('year_of_manufacturing7')?.setValue('');

      this.promotionForm.get('cylinder_make8')?.setValue('');
      this.promotionForm.get('cylinder_srno8')?.setValue('');
      this.promotionForm.get('year_of_manufacturing8')?.setValue('');

      this.promotionForm.get('cylinder_make9')?.setValue('');
      this.promotionForm.get('cylinder_srno9')?.setValue('');
      this.promotionForm.get('year_of_manufacturing9')?.setValue('');
      
    }
  }


  fileCheckForSlip(val: any) {
    //console.log(val.files)
    if ((val.files[0].type == "application/pdf" || val.files[0].type == "application/msword" || val.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") && val.files[0].size < (5 * 1024 * 1024)) {

    } else {
      this.LpgSlip = null
      return alert('Please Select .pdf .doc or .docx')
    }
  }

 
 

  filechange(element: any, check: any) {
    //console.log(check);
    if (check == 'Invoice') {
      this.invoiceimg = element.target.files
     

    } else if (check == 'Kitimg') {
      this.cngkitimg = element.target.files
     
    } else if (check == 'rcbook') {
      this.rcbookimg = element.target.files
      
    } else if (check == 'insurance') {
      this.vinsuranceimg = element.target.files
     

    } else if (check == 'pancard'){
       this.pancardimg = element.target.files
    //  console.log(this.pancardimg);
      
    }else if (check == 'installationcertificate'){
       this.installationcertificateimg = element.target.files
     // console.log(this.installationcertificateimg);
      
    }else if (check == 'commercialrcbook'){
       this.commercialrcbookimg = element.target.files
      //console.log(this.commercialrcbookimg);
      
    }




  }



  //async doOCR(item: any) {
  //  console.log(item);


 

  clearAlert() {
    this.alertType = ''
  }



  

//   openPopup(val:any){
//     console.log(val)
//     this.Popup='show';
//     this.display='block';

//     this.gift_no11 = this.promotionForm.get('gift_no1')?.value;
//     this.gift_no22 = this.promotionForm.get('gift_no2')?.value;
//     this.gift_no33 = this.promotionForm.get('gift_no3')?.value;
//     this.gift_no44 = this.promotionForm.get('gift_no4')?.value;
//     this.gift_no55 = this.promotionForm.get('gift_no5')?.value;
//     console.log('this.gift_no22',this.gift_no22);
    
//     var reader11 = new FileReader();
//      reader11.readAsDataURL(this.gift_card11);
//      reader11.onload = (_event) => {
//        this.gift_card111 = reader11.result; 
//    }
    
//     var reader22 = new FileReader();
//     reader22.readAsDataURL(this.gift_card22);
//     reader22.onload = (_event) => {
//      this.gift_card222 = reader22.result; 
//   }

//   var reader33 = new FileReader();
//   reader33.readAsDataURL(this.gift_card33);
//   reader33.onload = (_event) => {
//    this.gift_card333 = reader33.result; 
// }

// var reader44 = new FileReader();
//   reader44.readAsDataURL(this.gift_card44);
//   reader44.onload = (_event) => {
//    this.gift_card444 = reader44.result; 
// }

// var reader55 = new FileReader();
//   reader55.readAsDataURL(this.gift_card55);
//   reader55.onload = (_event) => {
//    this.gift_card555 = reader55.result; 
// }
   
    

//   }

  closeModel(){
    this.Popup=''
    this.display='none'
   // console.log(this.Popup)
  }
  

  // onSubmit() {
  //   console.log(this.promotionForm.value.vehicleNo)
  //    this.submitted = true
  //   if (this.promotionForm.get('Captcha')?.value != this.captcha) {
  //     this.promotionForm.get('Captcha')?.setValue('')
  //   }
  //   if (this.promotionForm.invalid) {
  //     return;
  //   }

  //   if (this.promotionForm.value.otherDoc != '') {
  //     this.otherDocFlag = false;
  //     if (this.promotionForm.value.otherDocimg == '') {
  //       this.otherDocimgFlag = true;
  //     } else {
  //       console.log('else1');
  //       this.otherDocimgFlag = false;
  //       // this.submitForm();

  //       this.openPopup(1);

  //     }
  //   } else if (this.promotionForm.value.otherDocimg != '') {
  //     this.otherDocimgFlag = false;
  //     if (this.promotionForm.value.otherDoc == '') {
  //       this.otherDocFlag = true;
  //     } else {
  //       console.log('else2');
  //       this.otherDocFlag = false;
  //       // this.submitForm();
  //       this.openPopup(1);
  //     }
  //   } else {
  //     this.otherDocFlag = false;
  //     this.otherDocFlag = false;
  //     console.log('else');

  //     // this.submitForm();
  //     this.openPopup(1);

  //   }
  // }

  // submitPop(){
  //   this.closeModel();
  //   this.submitForm();
  // }


   checkVtype(){
      if(this.promotionForm.get('vehicleTpye')?.value=="LCV (>3.5-10 T)" || this.promotionForm.get('vehicleTpye')?.value=="ICV ( ≥10, < 15 tons)"
      || this.promotionForm.get('vehicleTpye')?.value=="HCV( ≥15)"){

        this.commercialrcbookimgFlag=true;

        this.promotionForm.get('commercial_rc_book')?.setValidators(Validators.required);
      this.promotionForm.get('commercial_rc_book')?.updateValueAndValidity();

    }else{
      this.commercialrcbookimgFlag=false;
      this.promotionForm.get('commercial_rc_book')?.clearValidators();
      this.promotionForm.get('commercial_rc_book')?.updateValueAndValidity();
    }
  }



  submitForm() {

       //console.log(this.pancardimg)

        this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

   

    const form = new FormData()
    form.append("Name", this.promotionForm.get('Name')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("Email", this.promotionForm.get('Email')?.value)
    //form.append("dob", this.promotionForm.get('dob')?.value)
    form.append("Pan", this.promotionForm.get('Pan')?.value)
    form.append("vehicleTpye", this.promotionForm.get('vehicleTpye')?.value)
    form.append("vehicleNo", this.promotionForm.get('vehicleNo')?.value)
    form.append("kit_Supplier", this.promotionForm.get('kit_Supplier')?.value)
    form.append("ECMSrNo", this.promotionForm.get('ECMSrNo')?.value)
    form.append("Pressure_regulator_SrNo", this.promotionForm.get('Pressure_regulator_SrNo')?.value)
    form.append("role", this.retroffiterLoginRole )
     form.append("NoofCNGCylinders", this.promotionForm.get('NoofCNGCylinders')?.value)
    form.append("cylinder_make", this.promotionForm.get('cylinder_make')?.value)
    form.append("cylinder_srno", this.promotionForm.get('cylinder_srno')?.value)
    form.append("year_of_manufacturing", this.promotionForm.get('year_of_manufacturing')?.value)
    form.append("cylinder_make1", this.promotionForm.get('cylinder_make1')?.value)
    form.append("cylinder_srno1", this.promotionForm.get('cylinder_srno1')?.value)
    form.append("year_of_manufacturing1", this.promotionForm.get('year_of_manufacturing1')?.value)
    form.append("cylinder_make2", this.promotionForm.get('cylinder_make2')?.value)
    form.append("cylinder_srno2", this.promotionForm.get('cylinder_srno2')?.value)
    form.append("year_of_manufacturing2", this.promotionForm.get('year_of_manufacturing2')?.value)
    form.append("cylinder_make3", this.promotionForm.get('cylinder_make3')?.value)
    form.append("cylinder_srno3", this.promotionForm.get('cylinder_srno3')?.value)
    form.append("year_of_manufacturing3", this.promotionForm.get('year_of_manufacturing3')?.value)
    form.append("cylinder_make4", this.promotionForm.get('cylinder_make4')?.value)
    form.append("cylinder_srno4", this.promotionForm.get('cylinder_srno4')?.value)
    form.append("year_of_manufacturing4", this.promotionForm.get('year_of_manufacturing4')?.value)
    form.append("cylinder_make5", this.promotionForm.get('cylinder_make5')?.value)
    form.append("cylinder_srno5", this.promotionForm.get('cylinder_srno5')?.value)
    form.append("year_of_manufacturing5", this.promotionForm.get('year_of_manufacturing5')?.value)
    form.append("cylinder_make6", this.promotionForm.get('cylinder_make6')?.value)
    form.append("cylinder_srno6", this.promotionForm.get('cylinder_srno6')?.value)
    form.append("year_of_manufacturing6", this.promotionForm.get('year_of_manufacturing6')?.value)
    form.append("cylinder_make7", this.promotionForm.get('cylinder_make7')?.value)
    form.append("cylinder_srno7", this.promotionForm.get('cylinder_srno7')?.value)
    form.append("year_of_manufacturing7", this.promotionForm.get('year_of_manufacturing7')?.value)
    form.append("cylinder_make8", this.promotionForm.get('cylinder_make8')?.value)
    form.append("cylinder_srno8", this.promotionForm.get('cylinder_srno8')?.value)
    form.append("year_of_manufacturing8", this.promotionForm.get('year_of_manufacturing8')?.value)
    form.append("cylinder_make9", this.promotionForm.get('cylinder_make9')?.value)
    form.append("cylinder_srno9", this.promotionForm.get('cylinder_srno9')?.value)
    form.append("year_of_manufacturing9", this.promotionForm.get('year_of_manufacturing9')?.value)

     if(this.invoiceimg !=undefined){
       let invoiceimgImg = this.invoiceimg[0].name.split('.').pop()
       let invoiceimgImg1  =this.invoiceimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ invoiceimgImg;
      form.append("invoice_copy", this.invoiceimg[0], invoiceimgImg1)

     }

       if(this.cngkitimg !=undefined){
         let cngkitimgImg = this.cngkitimg[0].name.split('.').pop()
         let cngkitimgImg1  =this.cngkitimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ cngkitimgImg;
         form.append("cng_certificate", this.cngkitimg[0], cngkitimgImg1)

       }

       if(this.rcbookimg !=undefined){
         let rcbookimgImg = this.rcbookimg[0].name.split('.').pop()
        let rcbookimgImg1  =this.rcbookimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ rcbookimgImg;
         form.append("rc_book", this.rcbookimg[0], rcbookimgImg1)

       }

        if(this.pancardimg !=undefined){
          let pancardimgImg = this.pancardimg[0].name.split('.').pop()
         let pancardimgImg1  =this.pancardimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ pancardimgImg;
         form.append("pan_card_img", this.pancardimg[0], pancardimgImg1)

        }

         if(this.installationcertificateimg !=undefined){
            let installationcertificateimgImg = this.installationcertificateimg[0].name.split('.').pop()
          let installationcertificateimgImg1  =this.installationcertificateimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ installationcertificateimgImg;
          form.append("installation_certificate", this.installationcertificateimg[0], installationcertificateimgImg1)

         }
   

       // let vinsuranceimgImg = this.vinsuranceimg[0].name.split('.').pop()
       // let vinsuranceimgImg1  =this.vinsuranceimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ vinsuranceimgImg;
       //   form.append("l_vehicle_insurance_copy", this.vinsuranceimg[0], vinsuranceimgImg1)

    
     this.service.edit_Retrofitters(form).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        this.promotionForm.reset();
     
        setTimeout(() => {
          this.clearAlert()
           this.router.navigate(['/cng/cng-offers-schemes/retrofitters-status'])

        }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
      //console.log(err)
    })

  }



  reset() {
    this.promotionForm.reset()
    this.submitted = false;
   

  }

checkDob(){

      var d1 = new Date(this.promotionForm.get('dob')?.value);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    var daydiff = (diff / 31536000000).toFixed(0);

    if (parseInt(daydiff) < 18) {
      this.promotionForm.get('dob')?.setValue('')
       alert("Should be Above 18")
     
    }

  }

  
}
