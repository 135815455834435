import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-csr-initiative-env',
  templateUrl: './csr-initiative-env.component.html',
  styleUrls: ['./csr-initiative-env.component.css']
})
export class CsrInitiativeEnvComponent {
  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }
}
