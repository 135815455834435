import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent {

  Data:any
  id:any

  baseUrl = environment.baseUrl

  constructor(private route: ActivatedRoute,private slideService : SliderService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    const id = this.route.snapshot.paramMap.get('id');
    this.id=id
    this.getNewsData()
  }

  getNewsData(){

    this.slideService.getAllNews().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
      //console.log(response)
      let filtered = data.filter((x:any)=>{
        return x._id == this.id
      })
      this.Data=filtered
     // console.log(this.Data)
    })

  }

}
