<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Generate<span> Your Bill</span></h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>Residential PNG</a></li>
        <li class=""><a >Customer Zone</a></li>
        <li class=""><a >Customer Login</a></li>
        <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
        <li class="active" aria-current="page">Generate Your Bill</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="latestnews-section">
          <app-dashboard-info-card></app-dashboard-info-card>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12">
        <div class="light-blue-bg" *ngIf="pdfOpenFlag==false">
		  <div class="row" >
		  <h6>Dear Consumer,</h6>
			<p>We value your Privacy. If you wish to avoid visit of Meter reader inside your
				premises, please provide us a clear image of the meter for us to raise your Bill
				as per Actual Reading. For this purpose, you will receive an SMS from MGL with a
				link / use <small class="text-img-connect"><img
						src="assets/images/mgl-connect-logo-sml.jpg"></small> app. <b>Please submit
				the reading between <em
					id="ContentPlaceHolder1_lblMrDate1">{{BillingData.n_mr_date1}}</em> and
				<em id="ContentPlaceHolder1_lblMrDate2">{{BillingData.n_mr_date2}}</em>.</b>
			</p>
		  </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

              <div class="customer-login-page log-meter-reading">
                <div class="log-meter-text">
                  <ul>
                    <li>All the fields which are marked by<span class="text-danger">*</span> are mandatory</li>
                  </ul>
                </div>
                <form [formGroup]="UserForm">
                  <div class="row">
                  
                  <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input readonly type="text" class="form-control" formControlName="BPno"
                              placeholder="BP No"/>
                         <label for="BP No">BP No<span style="color: crimson;">*</span></label>
                      </div>
                  </div>
                   <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input readonly type="text" class="form-control" formControlName="CAno"
                              placeholder="CA No"/>
                         <label for="CAno">CA No<span style="color: crimson;">*</span></label>
                      </div>
                  </div>
                  <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input readonly type="text" class="form-control" formControlName="RegName"
                              placeholder="Register Customer Name"/>
                         <label for="RegName">Register Customer Name<span style="color: crimson;">*</span></label>
                      </div>
                  </div>
                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Upload Meter Image<span
                                            style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'meterimg')" class="from-control"
                                                type="file" formControlName="meter_img"
                                                [ngClass]="{'is-invalid':submitted && UserForm.controls['meter_img'].errors}">
                                            <div *ngIf="submitted && UserForm.controls['meter_img'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['meter_img'].errors">Meter Image
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>
                                     
                                    </div>
                 
                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input type="email" class="form-control" formControlName="Email"
                              placeholder="Email"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                              id="Email" />
                              
                          <div *ngIf="submitted && UserForm.controls['Email'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['Email'].errors">
                                  Required Field</div>
                          </div>
                          <label for="Email">Email<span style="color: crimson;">*</span></label>
                      </div>
                  </div>
				  
                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input type="text" class="form-control" formControlName="name"
                              placeholder="name"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['name'].errors }"
                              id="name" />
                              
                          <div *ngIf="submitted && UserForm.controls['name'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['name'].errors">
                                  Required Field</div>
                          </div>
                          <label for="name">Name of the person entering details <span style="color: crimson;">*</span></label>
                      </div>
                  </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                      <div class="meter-reading-box mb-30">
                        
                        <div class="meter-cont">
                          <label>Previous Meter Reading</label>
                          <p>{{this.BillingData.closing}}</p>
                        </div>
                      </div>
                    </div>

              

                  <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="meter-reading-box mb-30">
                        <!-- <i class="demo-icon uil-calender"></i> -->
                        <div class="meter-cont">
                          <label>Meter Reading Window </label>
                          <p>{{BillingData.n_mr_date1}} TO {{BillingData.n_mr_date2}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                      <div class="meter-reading-box mb-30">
                        <!-- <i class="demo-icon uil-calender"></i> -->
                        <div class="meter-cont">
                          <label>Prev Meter Reading Date </label>
                          <p>{{BillingData.desp_date}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label>Meter Reading <span class="text-danger">*</span></label>
                      <ul class="meter-reading-input-box mb-30">
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading1"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading1'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading2"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading2'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading3"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading3'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading4"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading4'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading5"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading5'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li class="d-flex justify-content-center align-items-center">
                          -
                        </li>
                        <li>
                          <div class="otp-input blueborder-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading6"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading6'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input blueborder-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading7"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading7'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input blueborder-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading8"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading8'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>


                        </li>
                      </ul>
                    </div>
                       <div
                          *ngIf="submitted && (UserForm.controls['Reading1'].errors || UserForm.controls['Reading2'].errors || UserForm.controls['Reading3'].errors || UserForm.controls['Reading4'].errors ||
                        UserForm.controls['Reading5'].errors || UserForm.controls['Reading6'].errors || UserForm.controls['Reading7'].errors || UserForm.controls['Reading8'].errors) " class="meter-reading-error">
                          <div style="white-space: nowrap;"
                            *ngIf="UserForm.controls['Reading1'].errors || UserForm.controls['Reading2'].errors || UserForm.controls['Reading3'].errors || UserForm.controls['Reading4'].errors ||
                          UserForm.controls['Reading5'].errors || UserForm.controls['Reading6'].errors || UserForm.controls['Reading7'].errors || UserForm.controls['Reading8'].errors">
                           Meter Reading is Required or Don't enter special characters
                          </div>
                        </div>


                    

                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                        <select class="form-select" #opt (change)="this.UserForm.get('Reason')?.setValue(opt.value)" [ngClass]="{'is-invalid':submitted && UserForm.controls['Reason'].errors}" name="" id="">
                          <option value=""  selected>Select Options</option>
                          <option *ngFor="let i of options">{{i}}</option>
                          
                        </select>
                              
                          <div *ngIf="submitted && UserForm.controls['Reason'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['Reason'].errors">
                                  Required Field</div>
                          </div>
                          <label for="Datess">Reason <span style="color: crimson;">*</span></label>
                      </div>
                  </div>

                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input type="date" class="form-control" formControlName="Datess"
                              placeholder="Datess"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Datess'].errors }"
                              id="Datess" />
                              
                          <div *ngIf="submitted && UserForm.controls['Datess'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['Datess'].errors">
                                  Required Field</div>
                          </div>
                          <label for="Datess">Date <span style="color: crimson;">*</span></label>
                      </div>
                  </div>

                  </div>
                </form>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <a (click)="submit()" href="javascript:void(0)" class="greenbtn2">Submit <i class="demo-icon uil-arrow-right"></i></a>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="text-right md-text-left">
                      <a (click)="reset()" href="javascript:void(0)" class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="alertType == 'submit'"
                  [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                  class="card card-inverse-info" id="context-menu-simple">
                  <div class="card-body">
                    <p class="card-text">
                      {{alertMsg}}
                  </div>
                </div>
                 <div *ngIf="alertType == 'windowsubmit'"
                  [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                  class="card card-inverse-info" id="context-menu-simple">
                  <div class="card-body">
                    <p class="card-text">
                      {{alertMsg}}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- Print Form Start -->


            <div class="md-mt-30 bill-current-recipt" *ngIf="pdfOpenFlag==true">
                    <div class="print_box3">
                        <a  class="print"><button style="background: transparent; border: transparent;" (click)="printPdf()">Printer Friendly Version</button></a>
                     
                    </div>
                    <div style="margin:0 auto; width:90%; font-family:Arial; font-size:12px; border-bottom:5px solid #fbc78c; position:relative;">
<div style="margin-bottom:0px">
<table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td valign="top" width="65%">
        <div style="display:flex; border:2px solid #6877b4; padding:4px 10px; border-radius: 8px;">
    <a href="#"><img src="../../../assets/images/currbillimgnew/logo.png" style="height:70px"></a>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center" style="font-size:12px">Tax Invoice</td>
    </tr>
    <tr>
      <td align="center" style="font-size:20px; color:#0069B3; font-weight:bold">MAHANAGAR GAS LIMITED</td>
    </tr>
    <tr>
      <td align="center" style="font-size:12px; color:#0F9400">An ISO 9001, 14001 and 45001 Certified Company</td>
    </tr>
    <tr>
      <td align="center" style="font-size:12px;color:#0069B3;">www.mahanagargas.com</td>
    </tr>
  </tbody>
</table>

    </div>
        </td>
      <td>&nbsp;</td>
      <td width="35%">
          <table width="100%" border="0" cellspacing="0" cellpadding="5" style="font-size: 12px; font-weight:600">
        <tbody>
          <tr>
            <td style="background:#c5e5ee; padding: 6px 0 6px 10px;border-bottom:2px solid #FFF; border-radius:8px 0 0 0;-webkit-print-color-adjust: exact; ">CA Number</td>
            <td style="background:#c5e5ee; padding: 6px 0 6px 10px;border-bottom:2px solid #FFF; border-radius:0 8px 0 0;-webkit-print-color-adjust: exact;">{{billData.contract_acc}}</td>
          </tr>
          <tr>
            <td style="background: #b6d7a4;padding:6px 0 6px 10px;border-bottom:2px solid #FFF;-webkit-print-color-adjust: exact; text-transform: uppercase;">Bill Date</td>
            <td style="background: #b6d7a4;padding: 6px 0 6px 10px;border-bottom:2px solid #FFF;-webkit-print-color-adjust: exact;">{{billData.bill_date}}</td>
          </tr>
          <tr>
            <td style="background: #fbc78c;padding:6px 0 6px 10px; border-radius:0 0 0 8px;-webkit-print-color-adjust: exact;text-transform: uppercase">Period</td>
            <td style="background: #fbc78c;padding:6px 0 6px 10px; border-radius:0 0 8px 0;-webkit-print-color-adjust: exact;">{{previousDate}} to {{closingDate}}</td>
          </tr>
          
        </tbody>
      </table>
        </td>
    </tr>
    <tr>
      <td valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:0 0 0 0">
        <tbody>
          <tr>
            <td valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #f190a0; border-radius:6px; padding: 5px; ">
              <tbody>
                <tr>
                  <td align="center"><h2 style="margin: 0 0; font-size: 12px">Emergency</h2></td>
                </tr>
                <tr>
                  <td align="center">
                    <p style="margin:5px 0; font-size:9px;">(Gas Leak, Gas Stop, Fire)</p>
                    <h5 style="color:#e80717; font-size: 10px; margin: 5px 0">18002669944 (Tollfree)<br>
                      (022)-68759400,<br>
                      (022)-24012400</h5>
                    <p style="margin: 5px 0 0">(Available 24X7)</p></td>
                </tr>
              </tbody>
            </table></td>
            <td width="10">&nbsp;</td>
            <td valign="top"><div style="border:2px solid #6877b4; border-radius: 6px; padding:5px 10px; min-height:86px">
              <h2 style="font-size: 11px; line-height: 13px;color: #000; text-transform: uppercase; padding: 0 2px 0 4px; margin: 0;">{{billData.name}}</h2>
              <p style="font-size: 11px; line-height: 13px;  color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">{{details}}
                </p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;"> {{flat}}</p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">{{landmark}}</p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">Mobile No: {{mobile}}, Email Id:{{email}}</p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">BP No:{{billData.partner_no}}</p>
            </div></td>
          </tr>
        </tbody>
      </table></td>
      <td>&nbsp;</td>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td>
                <div style="margin:0 auto; width: 65px; height: 65px; background:#FBC78C; border-radius:65px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact;">
                <h2 style="margin:0 0 2px; font-weight: 800;font-size: 11px; ">{{uptoTotal}}</h2>
                <span style="font-size:10px">Amount upto</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 75%; margin:-8px auto 0; border-radius: 40px;font-size: 12px; padding: 10px 5px 7px; text-align: center;-webkit-print-color-adjust: exact">{{dueDate}}</div>
              </td>
            <td>&nbsp;</td>
            <td>
              <div style="margin:0 auto; width: 65px; height: 65px; background:#FBC78C; border-radius:65px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                <h2 style="margin:0 0 2px; font-weight: 800;font-size: 11px;">{{uptodueAmt}}</h2>
                <span style="font-size:10px">Amount after</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width:75%; margin:-8px auto 0; border-radius: 40px;font-size: 12px; padding: 10px 5px 7px; text-align: center;-webkit-print-color-adjust: exact">{{dueDate}}</div>
              </td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>
</div>  
    <div style="margin-bottom:0px">
        <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="49%" align="left" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="3" style="border:2px solid #6877b4; border-radius: 6px; ">
                    <tbody>
                      <tr>
                          <td colspan="3"  style="background:#c5e5ee;-webkit-print-color-adjust: exact; border-radius:4px 0 4px 0;">
                            <div style="display: flex;justify-content: space-between;">
                              <span>MVAT Invoice No</span>
                          <span><strong> {{billData.TAX_INVNO}} </strong></span>
                                </div>
                          </td>
                        </tr>
                      
                      <tr>
                        <td style="background:#fee8d4;-webkit-print-color-adjust: exact;"><strong>SALE OF NATURAL GAS</strong></td>
                        <td style="border-right:1px solid #6877b4; background:#fee8d4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" style="background:#fee8d4;-webkit-print-color-adjust: exact; padding-right: 20px"><strong><strong></strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>Gas Consumption SCM <strong></strong></strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;  ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{billData.cur_cons}}
                        </strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>Gas Consumption Charges @ Rate Per<strong> SCM {{billData.rate}}</strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{billData.cons}}</strong></strong></td>
                      </tr>
                        <tr>
                        <td bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;"><strong>MVAT @3% <strong></strong></strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{billData.vat}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>Arrears<strong></strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>
                        {{str_arrears_emi.toFixed(2)}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;"><strong>Credit Balance / Discount / Rebate</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>
                        {{v_creditbalance.toFixed(2)}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FDDDBC"  style="-webkit-print-color-adjust: exact;"><strong>TOTAL CHARGES A</strong></td>
                        <td bgcolor="#FDDDBC" style="border-right:1px solid #6877b4; -webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact; padding-right: 20px"><strong><strong>{{totalPartA.toFixed(2)}}</strong></strong></td>
                      </tr>
                       <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>GST Invoice No. {{billData.bill_no}}</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px"></td>
                      </tr>
                     
                        <tr>
                        <td bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;"><strong>Other Charges</strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;padding-right: 20px">{{otherCharges.toFixed(2)}}</td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>Minimum Charges</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px">{{billData.min_chg}}</td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>SGST @9%<strong></strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{v_SGST}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>CGST @9%</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{v_CGST}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact;"><strong>TOTAL CHARGES B</strong></td>
                        <td bgcolor="#FDDDBC" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong>{{vPartB.toFixed(2)}}</strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FBC78C" style="-webkit-print-color-adjust: exact;"><strong>TOTAL PAYABLE (A+B)<strong></strong></strong></td>
                        <td bgcolor="#FBC78C" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FBC78C" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong>{{abTotal.toFixed(1)}}</strong></td>
                      </tr>
                      
                      
              </tbody>
                  </table>
        </td>
    </tr>
    <tr>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="3" style="border-radius: 4px; border:2px solid #6877b4;">
        <tbody>
          <tr>
            <td style="border-bottom: 2px solid #6877b4"><strong>Gas Consumption Security Deposit Rs.{{billData.EMI_CLEARED}}</strong></td>
          </tr>
          <tr>
            <td>{{str5000}}</td>
          </tr>
         
        </tbody>
      </table></td>
    </tr>
    <tr>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td>
                <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">Meter No.</h2>
                
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">{{billData.meter_no}}</div></td>
            <td>&nbsp;</td>
            <td>
              <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                  <span style="font-size:8px">Previous Reading </span>
                <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">{{previoseReading}}</h2>
                <span style="font-size:11px">{{assessedActual}}</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">{{previousDate}}</div>
              </td>
            <td>&nbsp;</td>
            <td> <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                  <span style="font-size:8px">Closing Reading</span>
                <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">{{closingReading}}</h2>
                <span style="font-size:11px">{{closingActual}}</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">{{closingDate}}</div></td>
          </tr>
        </tbody>
      </table></td>
    </tr>
      <tr>
        <td>
          <div id="canvasdivid">
                        <canvas id="canvas">{{chart}}</canvas>
                      </div>
        </td>
     <!--  <td><img src="../../../assets/images/currbillimgnew/graph.jpg" height="120"></td> -->
    </tr>
  </tbody>
</table>

        </td>
      
      <td width="49%" align="left" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid  #6877b4; border-radius: 6px">
              <tbody>
                <tr>
                  <td height="25" align="left" style="background:#c5e5ee; color:#000; font-size: 12px; -webkit-print-color-adjust: exact; padding-left:15px; font-weight:800">IMPORTANT INFORMATION</td>
                </tr>
                <tr>
                  <td style="padding:5px 10px; position: relative">
                    <!-- <p style="font-size:11px;margin:0 0 3px">Opt for paperless Invoice by sending SMS to 922555557 "No Hard Copy"</p> -->
                    <p [innerHTML]="important1"></p>
                     <p *ngIf="firstMessageFlag==true">
                                                Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill
                                            </p>

                                            <p *ngIf="secondMessageFlag==true">
                                                We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill
                                            </p>
                     <!--  <p style="font-size:11px; margin:0 0 3px">For registering your E-mail Id, please visit https://www.mahanagargas.com/- Billing/updatecontactnumber.aspx.</p> -->
                     
                      <div style="position:absolute; left: 50%; top:50%; transform: translate(-50%,-50%); opacity:0.1; z-index: -1 ">
                      <img src="../../../assets/images/currbillimgnew/mg-icon.png" style="width: 100%">
                      </div>
                    </td>
                </tr>
              </tbody>
            </table></td>
          </tr>
          
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="5" style="border:2px dashed #6877b4; border-radius: 4px; margin-top: 5px">
              <tbody>
                <tr>
                  <td width="51%" align="center" *ngIf="meterImg">
                    <img src="{{meterImg}}" width="100">
                    </td>
                  <td width="49%" align="center" *ngIf="meterImgTxt"><strong [innerHTML]="meterImgTxt"></strong></td>
                </tr>
              </tbody>
            </table>
              </td>
          </tr>
          
          <tr>
            <td>
              <div style="width:auto; border: 2px solid #6877b4; border-radius: 6px; margin-top:8px;">
                  <p style="background:#c5e5ee; color:#000; font-size: 12px; padding: 5px 5px 5px 15px; margin: 0;-webkit-print-color-adjust: exact;font-weight:800;">Declaration</p>
                  <div style="padding:8px">
                  <p style="margin: 0 0 5px"><strong>It is hereby certified that Registration Certificate of the Company issued under Maharashtra Value Added Tax Act, 2002 is in force as on date and that the transaction of sale covered under this Tax Invoice shall be accounted for in the Turnover of Sales while filing of Returns and tax payable on the sale, if any, has been paid or shall be paid.</strong></p>
                      <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td><p style="font-size: 14px; font-weight:600; margin: 0"><strong>For Mahanagar Gas Ltd</strong></p>
                      <img src="../../../assets/images/currbillimgnew/chief-manager-sign1.jpg">
                  <p style="font-size: 12px">Chief manager - Revenue & Taxation</p></td>
      <td align="right"> <img src="../../../assets/images/currbillimgnew/stamp.jpg" width="120"></td>
    </tr>
  </tbody>
</table>

                  
                      </div>
              </div>
              </td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>

  </div>
    <div style="border-radius: 6px;margin-bottom: 5px; height: 180px; border:2px solid #6877b4;">
      <p style="line-height:23px; font-size: 11px; padding: 5px 5px 5px 15px; margin: 0;-webkit-print-color-adjust: exact;">
        We are excited to present our PNG bill in a new format. We at MGL, constantly endeavour to provide unmatched convenience while maintaining simplicity.<br> 

This changed format will provide you with a cleaner presentation of your account & past consumption along with multiple and easy payment options.<br>

One more important detail about the new format. A young student and budding artist Akash Sthool from Sir J. J. Institute of Applied Arts has designed the format.<br>

So do tell us about the change as well as our services at support @ mahanagargas.com or <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="14" style="margin:0px"> 9899 20 3843<br>

Thanking you once again for the opportunity to serve you.


      </p>
      <div style="text-align:right;font-weight:bold;margin: 10px;">Team MGL</div>
    </div>
  <div style="margin-bottom:0px; border-top:2px dashed #000;padding:5px 0 0">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td width="60%" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px">
                    <tbody>
                      <tr>
                        <td height="20" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight:800">
                          PAYMENT SLIP/CHEQUE
                        </td>
                      </tr>
                      <tr>
                        <td >
                            <table width="100%" border="0" cellspacing="0" cellpadding="3">
  <tbody>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 2px 2px 0"><strong>Cheque No:</strong></td>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong> Cheque Dt:</strong></td>
    </tr>
    <tr>
      <td colspan="2" style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong>Bank Name/ branch:</strong></td>
      </tr>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 2px 2px 0"><strong>CA:{{billData.contract_acc}}</strong></td>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong>Due date:{{billData.due_date}}</strong></td>
    </tr>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0" colspan="2"><strong>Amount Paid:{{uptoTotal}}</strong></td>
      </tr>
    <tr>
      <td colspan="2" style="font-size:10px">Please pay by cheque in favour of Mahanagar Gas Ltd CA No. {{billData.contract_acc}}</td>
      </tr>
  </tbody>
</table>

                        </td>
                      </tr>
                    </tbody>
                  </table>
        
        </td>
      <td>&nbsp;</td>
      <td width="40%" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px">
        <tbody>
          <tr>
            <td height="20" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight:800"> Pay through QR (Quick Response) Code </td>
          </tr>
          <tr>
            <td ><table width="100%" border="0" cellspacing="0" cellpadding="5" style="height:96px">
              <tbody>
                <tr>
                  <td width="50%" align="center" valign="middle" >
                      <div style="display:flex; align-items: center;justify-content: space-around;">
                      <img src="{{qrdata_upi}}" style="height:90px;">
                      <img src="../../../assets/images/currbillimgnew/upi-logo.jpg" style="height:20px;">
                      </div>
                    </td>
                 <!--  <td align="center" valign="middle" >
                    <div style="display:flex; align-items: center;justify-content: space-around;">
                      <img src="{{qrdata_paytm}}" style="height:90px;">
                      <img src="../../../assets/images/currbillimgnew/paytm-logo.jpg" style="height:20px;">
                    </div>
                    </td> -->
                </tr>
              </tbody>
            </table></td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>

    </div>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center" style="font-size: 16px; font-weight: 800">Customer Care - 24x7</td>
    </tr>
    <tr>
      <td align="center" style="display:flex; font-size: 14px;justify-content: center;align-items: center;"><img src="../../../assets/images/currbillimgnew/call-img.jpg" width="20" style="margin:0 10px">(022) 6867 4500 &amp; 6156 4500 <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="20" style="margin:0 10px">9899 20 3843 </td>
    </tr>
    <tr>
      <td align="center">
          <h4 style="background:#c5e5ee; padding:6px 0; color:#000; font-size:11px; text-align: center; margin:5px 0;-webkit-print-color-adjust: exact; font-weight: normal">
              CIN #L40200MH1995PLC088133 | VAT # 27390000364V | C.S.T #27390000364C | GSTIN :27AABCM4640G1ZN |PLACE OF SUPPLY : MAHARASHTRA (27)</h4></td>
    </tr>
  </tbody>
</table>
<div style="position:absolute; left:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
    <div style="position:absolute; right:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
</div>
<div style="margin:20px auto 0; width:90%; font-family:Arial; font-size:12px; border-bottom:5px solid #fbc78c; padding: 10px 30px; position: relative">
    <div style="position:absolute; left:0; top: 0; z-index: -1;transform: rotateX(180deg);"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
    <div style="position:absolute; right:0; top: 0; z-index: -1;transform: rotateX(180deg);"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
    <p style="margin-bottom:3px; text-align: center">Subject To Mumbai Juisdiction</p>
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
      <tbody>
        <tr>
          <td width="59%"><img src="../../../assets/images/currbillimgnew/if-you-smell1.png" style="width:100%"></td>
          <td>&nbsp;</td>
          <td valign="top">
            <div style="border:2px solid #6877b4; border-radius: 6px;margin:35px 0 0 0">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td height="25" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight: 800">
                          Best Safety Practices
                        </td>
                      </tr>
                      <tr>
                        <td>
                            <ul style="padding:24px 10px">
            <li style="margin-bottom:8px">Ensure Appliance Valve / Meter Control Valve is closed when not using Gas.</li>
            <li style="margin-bottom:8px">Keep windows open and allow air circulation inside Kitchen to facilitate ventilation</li>
            <li style="margin-bottom:8px">Electric installation / Wiring should never touch or traverse Gas line. Please maintain a clear distance of at least 01 foot (300mm).</li>
            <li style="margin-bottom:8px"> Do not conceal Gas Pipes in permanent or temporary structures.</li>
             <li > Gas Geyser must not be installed inside bathrooms or closed areas.</li>
            </ul>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
          </td>
        </tr>
      </tbody>
  </table>
    <div style="margin:0 0 0">
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="50%" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td><div style="min-height: 140px">
          <img src="../../../assets/images/currbillimgnew/ad2.png"> 
      </div></td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>
        <div style="border:2px solid #6877b4; border-radius:4px; min-height: 140px; position: relative">
          <h2 style="margin:8px 5px 5px 85px; position: relative; font-size:14px">Dear Consumer, 
              <i style="position: absolute; left:-60px; top:-28px; border:2px solid #24388e; width: 50px; height: 50px; display: flex; border-radius: 50px; background: #FFF;align-items: center;
    justify-content: center;"><img src="../../../assets/images/currbillimgnew/user-icon.jpg"></i>
            </h2>
            <ul  style="padding:10px 20px">
            <li  style="margin-bottom:6px">Invoice is raised once in two months on the basis of meter reading taken by MGL meter reader or meter Reading forwarded by yourself.</li>
                <li  style="margin-bottom:6px">We allow a Credit Period of 20 days to enable you to make payment. If you do not pay your bill by Due Date, Late Payment Charges of Rs. 100/- shall be levied.</li>
                <li  style="margin-bottom:6px"> Continued default in making payment will attract additional Delayed Payment Charges@18% p.a. on unpaid value (beyond 9 days of delay) w.e.f. Due Date.</li>
                <li  style="margin-bottom:6px">In the event of continued default, MGL reserves the right to disconnect the supply. Reconnection shall be done only upon payment of Outstanding, (Including Late
Payment charges, interest on delayed payment), disconnection & reconnection charges.</li>
                <li  style="margin-bottom:6px">Any dispute in invoice should be brought to our notice within 14 days of the Bill Date.</li>
                <li  style="margin-bottom:6px">If you do not consume PNG equivalent to Rs. 100/- in a Bimonthly Billing Period, the shortfall amount will be levied in addition to the Gas Consumption Charges.</li>
                <li  style="margin-bottom:6px">We forward the copy of the Bill by SMS as well as e-mail on demand.</li>
                <li>Piped Natural Gas (PNG) attracts MVAT and all other sale and supply of Goods and Services attract GST.</li>
            </ul>
            <div style="position:absolute; left: 50%; top:50%; transform: translate(-50%,-50%); opacity:0.1; z-index: -1; width: 90% ">
                      <img src="../../../assets/images/currbillimgnew/mg-icon.png" style="width: 100%">
                      </div>
          </div>
        </td>
    </tr>
  </tbody>
</table>
</td>
      <td width="5">&nbsp;</td>
      <td width="49%" align="left" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td>
        <div style="border:2px solid #6877b4; border-radius: 6px; ">
                <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight: 800; line-height:13px">Your Nearest Walk in center (10:00 AM to 5:00 PM)  <span style="font-size: 11px; font-weight:normal;">Monday to Friday except Public &amp; Bank Holidays</span></p>
                    <div style="padding:5px 10px">
                    <p style="margin:0 0 2px 0;line-height:13px">{{walkincenterData}}</p>
                        
                        </div>
                </div>
        </td>
    </tr>
    <tr>
      <td><div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight:800;">For Unresolved Issues, Please Contact</p>
        <div style="padding:5px 10px">
          <p style="margin:0 0 2px 0; line-height:15px">You may contact the officials between 3:00 pm to 5:00 pm from Monday to Friday (Except Public & Bank Holidays) with the Unique Docket number given at the time of registering your complaint.</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong>Nodal Office:</strong><br> Mahanagar Gas Ltd, Pay & Accounts Building, Ground Floor, Near Family Court, Bandra Kurla Complex, Bandra (East), Mumbai-400051.</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Telephone Number :</strong> 022-6156 4555</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Complaint in-charge:</strong> Mr. Karan Mhole - Ext. No. 4549</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Nodal Officer :</strong> Mr. Vikas Sarmalkar - Ext. No. 4548</p>
            <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Appellate Authority :</strong> Mr. Paresh Chanpur - Ext. No. 4535</p>
            <p  style="margin:0 0 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Email ID :</strong>  support@mahanagargas.com</p>
        </div>
      </div></td>
    </tr>
    <tr>
      <td><div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight: 800;">
         CONNECT <i>for</i> CONVENIENCE</p>
        <div style="padding:10px">
          <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="48%" align="center">
          <p style="margin:0 0 5px 0;font-size:11px">MGL CONNECT APP</p>
          <img src="../../../assets/images/currbillimgnew/mgl-connect-app.jpg" width="90">
          <p style="margin:5px 0 0 0">Scan the QR Code to download the MGL CONNECT APP</p>
        </td>
      <td>&nbsp;</td>
      <td width="50%" align="center">
          <p style="margin:0 0 5px 0; font-size:11px">GENERATE YOUR OWN BILL</p>
          <img src="../../../assets/images/currbillimgnew/generate-your-own-bill.jpg" width="90">
          <p style="margin:5px 0 0 0">Scan the QR Code to<br>
            GENERATE YOUR OWN BILL</p>
        </td>
    </tr>
  </tbody>
</table>

        </div>
      </div></td>
    </tr>
    
  </tbody>
</table>
</td>
    </tr>
  </tbody>
</table>

    </div>
    
    <div style="margin:0 0">
         <div style="background:#b6d7a4; color:#000; font-size: 13px; font-weight: 800; -webkit-print-color-adjust: exact; padding:3px 0; text-align:center;">Easy to Pay your Gas Bills here</div>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #0f70b7; border-radius: 6px; margin-top: 4px">
                    <tbody>
                      
                      <tr>
                        <td>
                        <table cellspacing="0" cellpadding="4" border="0" align="center" style="font-size: 11px;">
                                        <tbody>
                                            <tr>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0; font-size: 10px;-webkit-print-color-adjust: exact; font-weight: 800; color:#0c4d7d;">Digital</td>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0;font-size: 10px;-webkit-print-color-adjust: exact; font-weight: 800;color:#0c4d7d;">Across the Counter</td>
                                                <td width="52%" colspan="3" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0; font-size: 10px;-webkit-print-color-adjust: exact;font-weight: 800;color:#0c4d7d;">RTGS/NEFT
                                                </td>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 0 1px 0;font-size: 10px;-webkit-print-color-adjust: exact;font-weight: 800;color:#0c4d7d;">E-NACH</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="5" valign="top" style="border:1px solid #0f70b7; border-width:0 1px 0 0">
                                                    <strong>Online Payments</strong>
                                                    <p>Pay By Net Banking, Cards, UPI, BillDesk <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="11" style="margin-top:0px"></p>
                                                    
                                                </td>
                                                <td rowspan="5" align="left" valign="top" style="border:1px solid #0f70b7; border-width:0 1px 1px 0">
                                                    <strong>Post office location:</strong>Mumbai, Mira Road,<br>Thane <br><br>
                                                    <strong>Drop Box</strong>
                                                    ICICI Bank, Axis Bank <br><br>
                          Outlets of Suvidhaa, Paypoint, Euronet
                                                </td>
                                                <td width="95" style="white-space: nowrap; border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Bank Name</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0" width="122"><strong>YES Bank Ltd.</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0" width="120"><strong>State Bank of India</strong></td>
                                                <td rowspan="5" valign="top" style="border:1px solid #0f70b7; border-width:0 0 1px 0">Please avail 1% Discount on Gas Consumption Charges subject to
                                                    maximum of Rs. 50/-</td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">Account no.</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0">MGL999 {{billData.contract_acc}}</td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0">MGL1 {{billData.contract_acc}}</td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">IFSC</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>YESB 0CM SNOC</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>SBIN 000 4266</strong></td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">Type of Account</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Current Account</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Current Account</strong></td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong style="white-space: nowrap">Branch:</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong>YES BANK, MUMBAI</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong>STATE BANK OF INDIA, MUMBAI</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                       
                          </td>
                      </tr>
                    </tbody>
                  </table>
        <p style="margin:5px 0 0; text-align: center">Corporate & Registered Office: MGL House, G-33, Bandra Kurla Complex, Bandra(East), Mumbai-400 051</p>
    </div>
    <div style="position:absolute; left:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
    <div style="position:absolute; right:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
                </div>
              </div>
   
      <!-- Print Form End -->
      </div>
    </div>
  </div>
  <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->