<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Know Your Application Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Know your application status</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <!-- <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                            
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div> -->
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>

                <div class="mt-30">
                   <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-lg-0 mb-30">
                        <div class="box882_left">
                           <div class="con ">
                                <form  [formGroup]="UserForm1">
                                    <div class="row">
                                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                            <div class="customer-login-page">
                                                <!-- <div class="log-meter-text"></div> -->
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="form-floating form-group">
                                                            <input type="text" class="form-control" formControlName="ApplicationNo"
                                                                placeholder="ApplicationNo"
                                                                [ngClass]="{ 'is-invalid': submitted && UserForm1.controls['ApplicationNo'].errors }" id="ApplicationNo" />
                                                            <div *ngIf="submitted && UserForm1.controls['ApplicationNo'].errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="submitted && UserForm1.controls['ApplicationNo'].errors">
                                                                    Required Field</div>
                                                            </div>
                                                            <label for="ApplicationNo">Application No.<span style="color: crimson;">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="MobileNo"
                                                        placeholder="MobileNo"
                                                        [ngClass]="{ 'is-invalid': submitted && UserForm1.controls['MobileNo'].errors }"
                                                        id="MobileNo" />
                                                    <div *ngIf="submitted && UserForm1.controls['MobileNo'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="UserForm1.controls['MobileNo'].errors.required">
                                                            Required Field
                                                        </div>
                                                        <div *ngIf="UserForm1.controls['MobileNo'].errors.pattern">
                                                            Invalid Contact Number </div>
                                                        <div
                                                            *ngIf="UserForm1.controls.MobileNo.hasError('maxlength') && UserForm1.controls.MobileNo.touched">
                                                            Invalid Contact Number </div>
                                                        <div
                                                            *ngIf="UserForm1.controls.MobileNo.hasError('minlength') && UserForm1.controls.MobileNo.touched">
                                                            Invalid Contact Number </div>
                                                    </div>
                                                    <label>Contact Number<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                              <!--       <div class="col-sm-12">
                                                        <div class="form-floating form-group">
                                                            <input type="text" class="form-control" formControlName="BPNo"
                                                                placeholder="BPNo"
                                                                [ngClass]="{ 'is-invalid': submitted && UserForm1.controls['BPNo'].errors }" id="BPNo" />
                                                            <div *ngIf="submitted && UserForm1.controls['BPNo'].errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="submitted && UserForm1.controls['BPNo'].errors">
                                                                    Required Field</div>
                                                            </div>
                                                            <label for="BPNo">BP No.<span style="color: crimson;">*</span></label>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-lg-5 col-md-5 col-sm-12">
                                                            <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                                            <div class="mt-20">
                                                                <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                               <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                                aria-hidden="true"></i> </div>
                                                            </div>
                                                        </div>

                                                    <div class="col-sm-7 form-group">
                                                        <div class="form-floating">
                                                            <input type="text" class="form-control" formControlName="captcha"
                                                                placeholder="captcha"
                                                                [ngClass]="{ 'is-invalid': submitted && UserForm1.controls['captcha'].errors }" id="captcha" />
                                                            <div *ngIf="submitted && UserForm1.controls['captcha'].errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="submitted && UserForm1.controls['captcha'].errors">
                                                                    Required Field</div>
                                                            </div>
                                                            <label for="captcha">Captcha<span style="color: crimson;">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button (click)="submit1()" class="greenbtn2">Search <i class="demo-icon uil-arrow-right"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="alertType == 'login'"
                                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                    class="card card-inverse-info" id="context-menu-simple">
                                                    <div class="card-body">
                                                        <p class="card-text">
                                                            {{alertMsg}}
                                                    </div>
                                                </div> -->
                                </form>
                                <div class="mt-20">
                                <div class="alert alert-info" *ngIf="status && Data.length > 0">Status: {{Data[0].Status ? "Successful" : "Under Process"}}</div>
                                </div>
                                <div class="mt-20">
                                <div class="alert alert-danger" *ngIf="status && Data.length == 0"><b>Application Number Not Found</b></div>
                                </div>
                                <!-- <div class="form_100per pad"></div>
                                <div class="clear"></div>
                                <span id="ContentPlaceHolder1_lblmsg"></span> -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>

<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            * Kindly enter OTP as appearing on your Mobile.
                        </div>
                        <form [formGroup]="checkOtpForm">
                            <div class="row">
                                


                                <div class="col-sm-6 form-group">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Otp" 
                                            placeholder="Otp"
                                            [ngClass]="{ 'is-invalid': OtpSubmitted && checkOtpForm.controls['Otp'].errors }" id="Otp" />
                                        <div *ngIf="OtpSubmitted && checkOtpForm.controls['Otp'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="checkOtpForm.controls['Otp'].errors.required">Required Field</div>
                                            <!-- <div *ngIf="checkOtpForm.controls['Otp'].errors.pattern">MobileNo should be in 12 digits.</div> -->
                                        </div>
                                        <label for="Captcha">Enter OTP<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button [disabled]="submitStatus" (click)="verifyOtp()"  class="{{submitStatus ? 'greybtn' :'greenbtn2' }}">Submit OTP<i
                                            class="demo-icon uil-arrow-right"></i></button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button [disabled]="OtpButtonActive" greybtn
                                        (click)="OtpGenerate()" class="{{OtpButtonActive ? 'greybtn' :'greenbtn2' }}">{{OtpButtonActive ?
                                        Minute+":"+Sec:
                                        "Resend OTP"}}<i class="demo-icon uil-arrow-right"></i></button>
                                </div>
                            </div>


                            <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>