import { Component } from '@angular/core';

@Component({
  selector: 'app-nationalpngdrive',
  templateUrl: './nationalpngdrive.component.html',
  styleUrls: ['./nationalpngdrive.component.css']
})
export class NationalpngdriveComponent {

}
