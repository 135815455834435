import { Component } from '@angular/core';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-customer-awareness',
  templateUrl: './customer-awareness.component.html',
  styleUrls: ['./customer-awareness.component.css']
})
export class CustomerAwarenessComponent {

  allFaq:any[]=[]

  constructor(public FaqService : FaqServiceService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.getAllFaq()
  }

  getAllFaq(){
    this.FaqService.getAllFaq().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      let filtered = data.filter((x:any)=>{
        return x.caption == 'PNG FAQ'
      })
      this.allFaq=filtered
    })
  }
}
