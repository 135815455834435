import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-feedback-inquiry',
  templateUrl: './feedback-inquiry.component.html',
  styleUrls: ['./feedback-inquiry.component.css']
})
export class FeedbackInquiryComponent {

  Data:string[]=[]

  UserForm!:FormGroup
  submited=false
  GoSubmitted = false;
  captcha:any

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  constructor(private pageLoaderService: PageLoaderService,private FormBilder:FormBuilder,private service : FormServiceService) { }

  ngOnInit(): void {


    this.captchaGenerate()

    this.Data=['No Job Done','Temporary/Permanent','Disconnection','Remove / Install Meter','Refund Request',
    'Bill Related Matter','Activate / Cancel – ECS / VDS','Not Using Gas / shifted','Not Receiving Bills','Name Transfer','Others']
    this.UserForm=this.FormBilder.group({
      BPno:['',[Validators.required]],
      MobileNo:['',[Validators.required]],
      CustName:['',[Validators.required]],
      Email:['',[Validators.required]],
      Matter:['',[Validators.required]],
      Rating:['',[Validators.required]],
      Feedback:['',[Validators.required]],
      CustomerName:[''],
      Captcha:['',[Validators.required]],

    })

    this.UserForm.controls['CustName'].disable();


    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }


  Go(){
    this.GoSubmitted = true

    if(this.UserForm.get('BPno')?.invalid || this.UserForm.get('MobileNo')?.invalid){
      return
    }

  var MobileNo= this.UserForm.get('MobileNo')?.value;
  var BpNo= this.UserForm.get('BPno')?.value
    var BPnotext = crypto.AES.encrypt(BpNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
    var MobileNotext = crypto.AES.encrypt(MobileNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
	
    let obj={"BPno":BPnotext,"MobileNo":MobileNotext}
   // console.log(obj)

    this.service.getNameByBpAndMobileNew(obj).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.details.length != 0){

        this.UserForm.get('CustName')?.setValue(data.details[0].Name)
        this.UserForm.get('CustomerName')?.setValue(data.details[0].Name)

      }else{

        this.alertType='Go'
        this.alertMsg = 'BPN o/Mobile No Not Found'
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'

        setTimeout(() => {
          this.alertType=''
        }, 1000);
      }
    })
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  reset(){
    this.UserForm.reset()
    this.Data=[]
    this.submited=false
    setTimeout(() => {
      this.Data=['No Job Done','Temporary/Permanent','Disconnection','Remove / Install Meter','Refund Request',
    'Bill Related Matter','Activate / Cancel – ECS / VDS','Not Using Gas / shifted','Not Receiving Bills','Name Transfer','Others']
    }, 500);
    
    
  }
    
    


  submit(){
    this.submited=true

    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }

    this.captchaGenerate()

    if(this.UserForm.invalid){
      return
    }

     var MobileNo= this.UserForm.get('MobileNo')?.value;
  var BpNo= this.UserForm.get('BPno')?.value
    var BPnotext = crypto.AES.encrypt(BpNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
    var MobileNotext = crypto.AES.encrypt(MobileNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
  
  

    this.UserForm.get('BPno')?.setValue(BPnotext)
        this.UserForm.get('MobileNo')?.setValue(MobileNotext)

    this.service.postFeedbackEnquiry(this.UserForm.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      if(data.Response){
        this.alertType='login'
        this.alertMsg = "Submitted"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        this.submited= false
        this.GoSubmitted = false
        setTimeout(() => {
          this.alertType=''
           this.reset()
        }, 1000);
      }
    })

    //console.log(this.UserForm.value)
  }
}
