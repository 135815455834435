<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>NACH <span>Online</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Customer Zone</a></li>
                <li class=""><a >Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
                <li class="active" aria-current="page">Customer Login</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
               <!--  <div class="latestnews-section" *ngIf="Service.loginStatus">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">


                    <div *ngIf="status" class="con">
                        <h6>(DEBIT MANDATE FORM NACH (One Time Mandate - OTM)</h6>
                        <p align="justify">Reserve Bank of India (RBI) has authorized National Payments Corporations of India (NPCI) under the NACH (National Automated Clearing House) to provide the services for collection of payments pertaining to utility industry. In the Long Run it will replace the Electronic Clearing System (ECS).</p>

                        <p align="justify">In ECS the Consumer who is giving the mandate has to visit his branch and seek signature of the Branch Manager or his representative. Whereas in the case of NACH the form need to be filled and sent to the MGL, who will through the mechanism deployed by NPCI will use the resources of MGL Bankers and upload the same in the Portal. The portal will automatically direct the same to your banker who will electronically confirm.</p>
                        
                        <p align="justify">An unique UMRN number will be issued which will be the reference. In the current scenario the acceptance by the customer’s bankers takes about 20 days to 30 days, however over time this is likely to improve.</p>

                        <p align="justify">ECS as per the RBI guidelines will be discontinued, hence in order to be on line with the government and RBI initiatives we have introduced the NACH (OTM) forms and is uploaded in MGL website. You can download the form and fil the same and send the form to the following address;</p>

                        <h6 class="mb-10"><strong>MAHANAGAR GAS LIMITED</strong></h6>
                        <p class=""><b> Neelkanth Corporate Park, 3rd Floor, Unit No.306, Kirol Road,<br> Opp. Vidayvihar Railway Station(West),Vidayvihar(W)<br> MUMBAI 400086</b></p>

                        <p align="justify">Please follow the instructions while filling the form, and fill the details legible and machine readable manner which will ensure that we are able to process the request.</p>

                        <p align="justify">Allow us time, and on confirmation of the UMRN number will be sent by SMS and E-mail to your registered Mobile Number and E-mail Id, subsequent bills which will fall due will be adjusted by collecting using the ACH facility.</p>

                        <p align="justify">If you have any query you can write to us at the above address and we shall be happy to assist you.</p>

                        <div class="payment_slip white-box10">
                            <p class="mb-0"><b><a href="../../../assets/images/pdf/_NACH_OTM_fc173bc14d.pdf" target="_blank" class="">Click here for NACH form, instructions, benefits </a></b></p>
                            <p class="mb-0">Please Print on White Sheet: select in Printer Properties with Paper Size to be selected as A4.</p>
                        </div>
                    </div>

                    <div *ngIf="!status" class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <form [formGroup]="UserForm">
                                <div class="customer-login-page log-meter-reading">
                                    <div class="log-meter-text">
                                        <ul>
                                            <li>Instructions to Fill e-NACH (Electronic National Automated Clearing
                                                House,
                                                formerly known as ECS – Electronic Collection system) :</li>
                                        </ul>
                                        <ol class="mt-30 mb-30">
                                            <li class="mb-10"> <b>e-NACH</b> is authorisation to <b>Direct Debit YOUR
                                                    BANK
                                                    ACCOUNT</b> and collection against PNG dues.</li>
                                            <li class="mb-10"> Your <b>CA No., e Mail id and name</b> will automatically
                                                populate from the system.</li>
                                            <li class="mb-10"> <b>CUSTOMER NAME</b> and <b>BANK ACCOUNT HOLDER NAME</b>
                                                to
                                                be <b>IDENTICAL.</b></li>
                                            <li class="mb-10"> Select NET BANKING only as the option. State the <b>
                                                    MAXIMUM
                                                    AMOUNT</b> and <b> PERIOD OF VALIDITY</b> by <b>INSERTING THE
                                                    DETAILS.</b></li>
                                            <li class="mb-10"> When submitted, through Banking channels, <b>UMRN No.</b>
                                                gets generated.</li>
                                            <li class="mb-10"> Bills are generated on Bimonthly basis by MGL.
                                                <b>e-NACH</b>
                                                will be presented around the due date of your bills.</li>

                                        </ol>
                                       
                                    </div>
                                    <div class="row">
                                        

                                      <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submited && UserForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submited && UserForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                 <div *ngIf="UserForm.controls['Email'].errors.required">
                                                    Required Field
                                                </div>
                                              
                                                <div *ngIf="UserForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                          
                                            <label for="Amt">Email<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                        

                                       <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="number" class="form-control" formControlName="Amt"
                                                    placeholder="Amt"
                                                    [ngClass]="{ 'is-invalid': submited && UserForm.controls['Amt'].errors }" id="Amt" />
                                                <div *ngIf="submited && UserForm.controls['Amt'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Amt'].errors.required">Required Field</div>
                                                   <!--  <div *ngIf="UserForm.controls['Name'].errors.pattern">CA should be in 10 digits.</div> -->
                                                </div>
                                                <label for="Amt">Amount (Rs.)<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        
                                           <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="date" class="form-control" formControlName="Dates"
                                                    placeholder="Dates"
                                                    [ngClass]="{ 'is-invalid': submited && UserForm.controls['Dates'].errors }" id="Dates" />
                                                <div *ngIf="submited && UserForm.controls['Dates'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Dates'].errors.required">Required Field</div>
                                                    
                                                </div>
                                                <label for="Dates">Collection Date<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12 y-middle">
                                                    <div class="captcha-text">
                                                    <b>{{captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i></b>
                                                     </div>
                                                </div>
                                                

                                                <div class="col-sm-6 form-group">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control" formControlName="Captcha"
                                                            placeholder="Captcha"
                                                            [ngClass]="{ 'is-invalid': submited && UserForm.controls['Captcha'].errors }" id="Captcha" />
                                                        <div *ngIf="submited && UserForm.controls['Captcha'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm.controls['Captcha'].errors.required">Required Field</div>
                                                            <div *ngIf="UserForm.controls['Captcha'].errors.pattern">CA should be in 10 digits.</div>
                                                        </div>
                                                        <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <a href="javascript:void(0)" (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></a>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <a href="javascript:void(0)" (click)="this.UserForm.reset()" (click)="this.submited=false" class="bluebtn2">Reset <i
                                                        class="demo-icon uil-redo"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="alertType == 'nach'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>

                                     <p class="mt-30 mb-0 text-right"><i><b>* All the fields are mandatory</b></i></p>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->