import { Component,OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-listofdocumentrequired',
  templateUrl: './listofdocumentrequired.component.html',
  styleUrls: ['./listofdocumentrequired.component.css']
})
export class ListofdocumentrequiredComponent {

  constructor(private pageLoaderService: PageLoaderService) { 

  

  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
   
   
  
  }

 

 
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}

