import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { PayuService } from 'src/app/Service/payu.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewpayutransaction',
  templateUrl: './viewpayutransaction.component.html',
  styleUrls: ['./viewpayutransaction.component.css']
})
export class ViewpayutransactionComponent {
   data:any;
   walletData:any;
   FName:any;
   availableBalance:any;
   statementData:any;
   statementDetails:any=[];
   dateForm!: FormGroup;
   submitted = false;


   constructor(private pageLoaderService: PageLoaderService, 
    public payuService: PayuService,public UserService: UserRegistrationService,public router: Router,
    private FormBuilder: FormBuilder) { 
  }


    ngOnInit(): void {
     
     //this.UserService.checkLogin()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

       this.dateForm = this.FormBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required]
    })

 var toDaydate = new Date();
   var toDaydate_date = toDaydate.getDate();
     var toDaydate_month = toDaydate.getMonth();
     var toDaydate_year = toDaydate.getFullYear();
   //  console.log(toDaydate,toDaydate_date,toDaydate_month,toDaydate_year)
 var toDaydate_resultddate = new Date(toDaydate_year, toDaydate_month, toDaydate_date);
var toDaydate_result = toDaydate_resultddate.toLocaleDateString("en-GB", { 
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(toDaydate_result);

var toDaydate_split = toDaydate_result.split('/');
//console.log(toDaydate_split)


var fromDaydate = new Date();
   var fromDaydate_date = fromDaydate.getDate();
     var fromDaydate_month = fromDaydate.getMonth() - 1;
     var fromDaydate_year = fromDaydate.getFullYear();
   //  console.log(fromDaydate,fromDaydate_date,fromDaydate_month,fromDaydate_year)
 var fromDaydate_resultddate = new Date(fromDaydate_year, fromDaydate_month, fromDaydate_date);
var fromDaydate_result = fromDaydate_resultddate.toLocaleDateString("en-GB", { 
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(fromDaydate_result);

var fromDaydate_split = fromDaydate_result.split('/');
//console.log('fromDaydate_split',fromDaydate_split)

 this.dateForm.get('toDate')?.setValue(toDaydate_split[2]+"-"+toDaydate_split[1]+"-"+toDaydate_split[0]);
 this.dateForm.get('fromDate')?.setValue(fromDaydate_split[2]+"-"+fromDaydate_split[1]+"-"+fromDaydate_split[0]);

         let data = sessionStorage.getItem('UserCredential')
     //console.log(data)
    if(data){
       this.data=JSON.parse(data)
        this.findpayUcreatewalletdata();
     
    }

    //  this.statementDetails= [
    //     {
    //         "merchantName": "Card Unload",
    //         "transactionType": "Debit",
    //         "transactionAmount": "500",
    //         "transactionDate": "2023-08-07 17:34:44.069",
    //         "merchantCity": "",
    //         "transRefNumber": "",
    //         "reserved1": "",
    //         "reserved2": "",
    //         "reserved4": "O|70000",
    //         "eventId": 303014,
    //         "rowId": 1691409884069,
    //         "authEpfTxnId": "41398",
    //         "rrn": "",
    //         "stan": "",
    //         "approvalCode": "",
    //         "isMerchantTxn": 0,
    //         "clientTxnId": "Madhuunload0002",
    //         "status": "",
    //         "closingBalance": 99500,
    //         "openningBalance": 100000,
    //         "transactionNarration": ""
    //     },
    //     {
    //         "merchantName": "Card Recharge",
    //         "transactionType": "Credit",
    //         "transactionAmount": "100000",
    //         "transactionDate": "2023-08-07 17:28:09.753",
    //         "merchantCity": "",
    //         "transRefNumber": "",
    //         "reserved1": "",
    //         "reserved2": "",
    //         "reserved4": "I|70130",
    //         "eventId": 303003,
    //         "rowId": 1691409489753,
    //         "authEpfTxnId": "41397",
    //         "rrn": "",
    //         "stan": "",
    //         "approvalCode": "",
    //         "isMerchantTxn": 0,
    //         "clientTxnId": "MadhuReload00001",
    //         "status": "",
    //         "closingBalance": 100000,
    //         "openningBalance": 0,
    //         "transactionNarration": ""
    //     }
    // ]
   }

  //  findpayUcreatewalletdata() {
  //   let obj={"CANo":this.data.CaNo,"MobileNo":this.data.Mobile,
  //   "Email":this.data.Email}
  //    this.payuService.findpayUcreatewalletdata(obj).subscribe(response => {
  //     console.log(response);
  //     let res = JSON.parse(JSON.stringify(response))
  //     if (res.response == true) {
  //       this.walletData = res.details;
  //       console.log(this.walletData[0].urn)
  //       this.FName = this.walletData[0].FName;



  //        this.get_statementInquiry_encrypt()
      
  //     } else {
        
  //     }
  //    }, err => {
  //     console.log(err)
  //   })

  // }


  findpayUcreatewalletdata() {
     let obj={"MobileNo":this.data.Mobile}
     this.payuService.checkwallet(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.walletData = res.details.LstWallet_Response;
        //console.log(this.walletData[0].urn)
        this.FName = this.walletData[0].firstName;



         this.get_statementInquiry_encrypt()
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }

  filter(){
     this.submitted = true
    if (this.dateForm.invalid) {
      return
    }

    // console.log(this.dateForm.get('fromDate')?.value)
    // console.log(this.dateForm.get('toDate')?.value)

     const From = Date.parse(this.dateForm.get('fromDate')?.value);
    const To = Date.parse(this.dateForm.get('toDate')?.value);

    if (From > To) {
      alert('Please Select From Date Lower Than To Date');
      this.dateForm.get('fromDate')?.setValue('');
      this.dateForm.get('toDate')?.setValue('');


    }else{
      this.get_statementInquiry_encrypt(); 
    }

  }


//    get_statementInquiry_encrypt() {

//       var cardLastnumber = this.walletData[0].cardNumber;

//     var cardLastnumbersave = cardLastnumber.slice(-4);
//     //console.log(cardLastnumbersave)

//       var mydate = new Date();
//      var curr_date = mydate.getDate();
//      var curr_month = mydate.getMonth();
//      var curr_year = mydate.getFullYear();
//      //console.log(mydate,curr_date,curr_month,curr_year)
//  var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
// var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
//   year: "numeric",
//   month: "2-digit",
//   day: "2-digit",
// });

// //console.log(result)
// var myresultsplit = result.split('/');
// //console.log(myresultsplit)
// var time = mydate.toLocaleTimeString();
// //console.log(time)
// var timesplit = time.split(':');
// //console.log(timesplit)
//  let text3 = timesplit[2].slice(0, 2);
// // console.log(text3)
// // console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
// var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;

//  var fromDatesplit = this.dateForm.get('fromDate')?.value.split('-');
//  var toDatesplit = this.dateForm.get('toDate')?.value.split('-');

//  var fromDatesplit1 = fromDatesplit[2]+"/"+fromDatesplit[1]+"/"+fromDatesplit[0];
//  var toDatesplit1 = toDatesplit[2]+"/"+toDatesplit[1]+"/"+toDatesplit[0];

//  console.log(fromDatesplit1);
//  console.log(toDatesplit1)

//     let obj={"clientTxnId":this.walletData[0].clientTxnId,"requestDateTime":savetime,
//      "fromDate":fromDatesplit1,"toDate":toDatesplit1,"last4Digits":cardLastnumbersave,
//      "urn":this.walletData[0].urn,"customerId":this.walletData[0].customerId}
//      this.payuService.get_statementInquiry_encrypt(obj).subscribe(response => {
//       console.log(response);
//       let res = JSON.parse(JSON.stringify(response))
//       if (res.response == true) {
//         this.get_statementInquiry(res.details.token)
      
//       } else {
        
//       }
//      }, err => {
//       console.log(err)
//     })

//   }


 get_statementInquiry_encrypt() {

      var cardLastnumber = this.walletData[0].cardNumber;

   // var cardLastnumbersave = cardLastnumber.slice(-4);
    //console.log(cardLastnumbersave)

      var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
     //console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
// console.log(text3)
// console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;

 var fromDatesplit = this.dateForm.get('fromDate')?.value.split('-');
 var toDatesplit = this.dateForm.get('toDate')?.value.split('-');

 var fromDatesplit1 = fromDatesplit[2]+"/"+fromDatesplit[1]+"/"+fromDatesplit[0];
 var toDatesplit1 = toDatesplit[2]+"/"+toDatesplit[1]+"/"+toDatesplit[0];

 // console.log(fromDatesplit1);
 // console.log(toDatesplit1)

    let obj={"customerId":this.walletData[0].customerId,"cardNumber":cardLastnumber,
      "fromDate":fromDatesplit1,"toDate":toDatesplit1,
     "urn":this.walletData[0].urn,}
     this.payuService.walletStatement(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      //console.log(res)
      if (res.response == true) {
        this.statementData = res.details;
        this.availableBalance = this.statementData.LstStatement_Response.availableBalance;
         this.statementDetails = this.statementData.LstStatement_Response.statementDetails;
         //console.log('adjd',this.statementDetails)
       // this.get_statementInquiry(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }

    get_statementInquiry(data:any) {
    let obj={"token":data}
     this.payuService.get_statementInquiry(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      this.get_statementInquiry_decrypt(res.details.token)
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


   get_statementInquiry_decrypt(data:any) {
    let obj={"token":data}
     this.payuService.get_statementInquiry_decrypt(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
      // console.log(res.details);
       this.statementData = res.details;
       //console.log(this.statementData);
       this.availableBalance = this.statementData.availableBalance;
         this.statementDetails = this.statementData.statementDetails;

       for (let i = 0; i < this.statementDetails.length; i++) {
       // console.log(this.statementDetails[i])
        let array = this.statementDetails[i].transactionDate.split(" ", 10);
       // console.log(array)
        this.statementDetails[i].date=array[0];
        this.statementDetails[i].time=array[1]
      
    }
//console.log(this.statementDetails)


       
      
      } else {
        
      }
     }, err => {
      //console.log(err)
    })

  }


}
