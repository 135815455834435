<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Forgot <span>Password</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Customer Zone</a></li>
                <li class=""><a >Customer Login</a></li>
                
                <li class="active" aria-current="page">Change Password</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-customer-awareness></app-customer-awareness>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">

                            <div class="customer-login-page log-meter-reading">
                                <div class="log-meter-text">
                                    <ul>
                                     <!--    <li><b>Note:</b> Kindly enter CA number and registered email id.</li> -->
                                     <li ><b>Note:</b>  Kindly enter CA number and registered email id or <b><a style="color:blue;" [routerLink]="['/residential-png/customer-zone/customer-login-with-otp']"><u>Click here to Login with Mobile OTP.</u></a></b></li>
                                    </ul>
                                </div>
                                <form [formGroup]="UserForm">
                                    <div class="row">
                                        <div class="col-sm-12 mb-3">
                                            <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="CaNo" placeholder="Enter CA.No. (Contract Account)" maxlength="12"
                                                [ngClass]="{ 'is-invalid': submitted && UserForm.controls['CaNo'].errors }" id="ca-no" />
                                            <label for="ca-no">CA.No. (Contract Account)<span style="color: crimson;">*</span></label>    
                                            <div *ngIf="submitted && UserForm.controls['CaNo'].errors" class="invalid-feedback">
                                                <div *ngIf="UserForm.controls['CaNo'].errors.required">CA No. is required</div>
                                                <div *ngIf="UserForm.controls['CaNo'].errors.pattern">CA Number should be in digits.</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mb-3">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Email" placeholder="Enter email id here" [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }" id="email-id" />
                                                <label for="email-id">Email ID<span style="color: crimson;">*</span></label>
                                                <div *ngIf="submitted && UserForm.controls['Email'].errors" class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Email'].errors.required">Email id is required</div>
                                                    <div *ngIf="UserForm.controls['Email'].errors.pattern">Email must be a valid email address.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mb-3">
                                            <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                            <div class="mt-20">
                                                <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                               <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh"
                                                aria-hidden="true"></i> </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Captcha" placeholder="Enter captcha here" [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Captcha'].errors }" id="captcha" />
                                                <label for="captcha">Captcha<span style="color: crimson;">*</span></label>
                                                <div *ngIf="submitted && UserForm.controls['Captcha'].errors" class="invalid-feedback">
                                                    <div *ngIf="UserForm.controls['Captcha'].errors.required">Captcha required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="margin-bottom:20px">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()"
                                                    (click)="this.UserForm.controls.Captcha.reset()"
                                                    class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="alertType == 'login'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                </form>
                                <p class="text-right mt-20"><b><i>Please note all fields marked <span class="text-danger">*</span> are
                                    compulsory.</i></b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    