import { Component } from '@angular/core';
import { CategoryItem } from 'src/app/models/cat';
import { ComplaintItem } from 'src/app/models/complaint';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { logcomplaint } from 'src/app/Service/logcomplaint.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
@Component({
  selector: 'app-viewcomplaint',
  templateUrl: './viewcomplaint.component.html',
  styleUrls: ['./viewcomplaint.component.css']
})
export class ViewcomplaintComponent {
  complaintdata: ComplaintItem[] = []
  allFaq: any[] = []
  constructor(private service: logcomplaint, public FaqService: FaqServiceService, private UserService: UserRegistrationService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
 
    this.UserService.checkLogin();
    this.get();
  }
  
  get() {
    let data = sessionStorage.getItem('UserCredential')
    if (data) {
		var demo = JSON.parse(data)
		this.service.getAll(demo.BpNo).subscribe((res: any) => {
			let result = JSON.parse(JSON.stringify(res))
			if ( result.Response ) {
				this.complaintdata = result.details
				//console.log("complaintdata", this.complaintdata)
			}
		})
    }
  }
}