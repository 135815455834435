import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-retrofitters20levelonedetails',
  templateUrl: './retrofitters20levelonedetails.component.html',
  styleUrls: ['./retrofitters20levelonedetails.component.css']
})
export class Retrofitters20levelonedetailsComponent {
  previouspageData:any;
  previouspageData1:any;
  constructor(private pageLoaderService: PageLoaderService, 
    private activatedRoute: ActivatedRoute,public router: Router) { 
      this.activatedRoute.queryParams.subscribe((data: any) => {
      //  console.log(data);
        this.previouspageData = JSON.parse(data.data);
        this.previouspageData1 = this.previouspageData.details;
        //console.log(this.previouspageData1);
        // if (data && data.id) {
        //   this.mobileNumber = data.mobile_no;
        // }
      });
      
    }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

  }

   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

 

    gotoEdit(){
     this.router.navigate(['/cng/cng-offers-schemes/retrofitters20-booking-intimation-edit'],
        {queryParams:{data:JSON.stringify(this.previouspageData1)}})
  }

 gotoLevel2(){
     this.router.navigate(['/cng/cng-offers-schemes/retrofitters20-bill-processing'],
        {queryParams:{data:JSON.stringify(this.previouspageData1)}})
  }

}


