<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Billing <span>History</span></h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>Residential PNG</a></li>
        <li class=""><a >Customer Zone</a></li>
        <li class=""><a >Customer Login</a></li>
        <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
        <li class="active" aria-current="page">Billing History</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->

<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <div class="latestnews-section">
          <app-dashboard-info-card></app-dashboard-info-card>
        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="innerpage-right-content awards-section">
          <div class="mt-20 billinginfo_table">
            <!-- <p>THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 PAYMENTS ARE AS FOLLOWS</p> -->
            <ul>
              <li class="BillNo-box">
                <span class="BillNo">BILL NUMBER</span>
                <span class="BillDate">BILL DATE</span>
                <span class="DisplayDate">DISPLAY DATE</span>
                <span class="DueDate">DUE DATE</span>
                <span class="NetPay">NET PAY</span>
                <span class="ViewBill"></span>
              </li>

              <li *ngIf="allData.length == 0">
                <span colspan="6">No Data found</span>
              </li>
              <li *ngFor="let i of allData,index as j">
                <span class="BillNo">{{i.bill_no}}</span>
                <span class="BillDate">{{i.bill_date}}</span>
                <span class="DisplayDate">{{i.bill_date}}</span>
                <span class="DueDate">{{i.due_date}}</span>
                <span class="NetPay">{{i.net_pay}}</span>
                <span style="text-decoration:underline;" class="ViewBill" (click)="this.DashboardService.billno = i.bill_no" [routerLink]="['/residential-png/customer-zone/current-bill']">View Bill</span>
              </li>

            </ul>
            <!-- <p class="mt-20">THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 DBS BANK UPI PAYMENTS ARE AS FOLLOWS </p> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>