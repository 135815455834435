<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Empowerment </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a [routerLink]="['/csrinitiative']">CSR Initiatives</a></li>
                <li class="active" aria-current="page">Empowerment</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>
                 <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
               <!--  <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="mt-30 mb-30">
                        <p> MGL believes that to enable someone to achieve his full potential and chase his dream is to
                            empowering them. MGL’s empowerment initiative is therefore directed to enable one to find
                            himself.</p>

                        <p>MGL Hunar is an initiative of MGL for augmenting employment oriented skills of youths in
                            supportive of their quest to discover themselves.</p>

                        <p>MGL is supporting a technical training institute that offers short term courses to students
                            from underprivileged sections of the society. MGL has partnered with the institute to
                            supplement their quest of enabling and empowering youths from the marginalized sections of
                            society. </p>
                    </div>

                    <div class="light-blue-bg mt-30 csr-policies">
                        <img src="assets/images/empowerment_pic1.jpg" class="w-100">
                    </div>

                    <div class="mt-30 mb-30">

                        <p>MGL’s support includes upgrading the overall infrastructure of the training centre, upgrading
                            training equipment, upgrading training curriculum, design new curriculum and operational
                            support towards running batches. A new course on RAC & Gas Plumbing has been introduced
                            based on the employment potential. 75 youths will be trained in the trade every year.</p>


                        <p>The first batch of RAC & Gas plumbing concluded in June, 2016 with 100% successful placement.
                        </p>

                        <p>MGL is implementing short term course on various employment oriented trades. More than 200
                            youths are proposed to be trained in courses like General Duty Assistant (In Nursing),
                            Customer Care Executive and Gas Plumbing. </p>
                    </div>


                    <div class="light-blue-bg mt-30 mb-30 csr-policies">
                        <img src="assets/images/empowerment_pic2.jpg" class="w-100">
                    </div>
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    