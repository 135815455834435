import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-mgl-spirit-stmt',
  templateUrl: './mgl-spirit-stmt.component.html',
  styleUrls: ['./mgl-spirit-stmt.component.css']
})
export class MglSpiritStmtComponent {
  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }
}
