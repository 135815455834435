import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlogResp } from '../models/blogs';
@Injectable({
  providedIn: 'root'
})
export class BlogarticleService {

  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getAll():Observable<BlogResp[]> {
    return this.http.get<BlogResp[]>(this.baseUrl+'blogarticle/get_blogs');
  }

  getAllSlide(){
    return this.http.get(this.baseUrl+'getAllSlide')
  }
}
