import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-mr1',
  templateUrl: './mr1.component.html',
  styleUrls: ['./mr1.component.css']
})
export class Mr1Component {
  constructor(private activatedRoute: ActivatedRoute,
    private pageLoaderService: PageLoaderService) { 
   }

  ngOnInit(): void {
 
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
    //http://182.77.57.154/MGL/portal_clk2pay/index.php?key=4955d7
      window.location.href = 'http://182.77.57.154/MGL/portal_clk2pay/index.php?key=' + id;
      console.log('Url Id: ', id);
    })
  }

}
