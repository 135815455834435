import { Component } from '@angular/core';
import { takeWhile } from 'rxjs';
import { PageLoaderService } from '../Service/page-loader.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.css']
})
export class PageLoaderComponent {
  loading: boolean = true;
  private _subscribed: boolean = true;
  constructor(private pageLoaderService: PageLoaderService) {
    this.subscribe();
  }

  private subscribe() {
    this.pageLoaderService.state
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(loading => {
        this.loading = loading;
      });
  }

  ngOnDestroy() {
    this._subscribed = false;
  }
}
