<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Documents <span> Required</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a href="#">Businesses</a></li>
                <li class=""><a href="#">Commercial PNG</a></li>
                <li class="active" aria-current="page">Documents Required </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

               <!--  <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 d-block">View All <i
                                        class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <div class="box882_left">

                            <div class="con">
                                <h2>Documents required for Customers of Commercial PNG*:</h2>
                                <ul class="unordered-list">
                                    <li>PAN Card and VAT TIN.</li>
                                    <li>BMC Shops &amp; Establishment Certificate.</li>
                                    <li>Food Licence, Health Licence.</li>
                                    <li>Maharashtra Prevention of food adulteration License.</li>
                                    <li>Factory Licence, Factory permit.</li>
                                    <li>Land Purchase Deed (If Owned) Agreement (If Leased/ Rented).</li>
                                    <li>Partnership deed.</li>
                                    <li>Power of attorney.</li>
                                    <li>Articles of Association, Memorandum of Association.</li>
                                    <li>Nursing home registration certificate (For Nursing homes).</li>
                                    <li>Medical Council of India Registration number.</li>
                                    <li>Trade License.</li>
                                    <li>Clearance certificate from fire depatrtment like NOC,fire compliance as
                                        applicable for PNG connection in different municipal corparations i.e MCGM,
                                        NNMC,TMC,KDMC, MBMC etc. </li>
                                    <li>NOC from Society/ Landlord.</li>
                                    <li>Last purchased LPG 19kg cylinder bill.</li>
                                </ul>
                                <p style=" font-size:13px !important; font-style:italic; color: #000000">* Notary/Self
                                    Attested Xerox Copies as applicable.</p>
                                <h2>Documents required for STAR Category Hotels*:</h2>
                                <ul class="unordered-list">
                                    <li>Articles of Association, Memorandum of Association (If Company), registered
                                        Partnership Deed.</li>&nbsp;&nbsp;
                                    <li>Board Resolution or Power of Attorney nominating your authorized signatory on
                                        behalf of your company to execute the agreement.</li>
                                    <li>Copies of Annual Report of immediate last 2 years.&nbsp;</li>
                                    <li>Brief profile of the consumers giving full details of the business including the
                                        particulars for which gas is required.</li>
                                    <li>High Court/ and ROC scheme of arrangement/ settlement/ demerger/ merger.</li>
                                    <li>Name and address of all Partners/ Directors/ Trustees and Members.</li>
                                    <li>Name of CEO/ MD/ Chairman.</li>
                                    <li>Registration number of the Company/ Partnership firm as well as the name of the
                                        authority with whom Company/ Firm is registered.</li>
                                    <li>Pan Card photocopy.&nbsp;</li>
                                    <li>Registered office and site address.&nbsp;&nbsp;</li>
                                    <li>VAT TIN number photocopy showing additional places of Business (If
                                        any).&nbsp;&nbsp;</li>
                                    <li>Star Category certificate (In case of star hotels only) and Business
                                        Commencement Certificate.</li>
                                    <li>Name of Developer, operator and brand name (In case of star hotels only).</li>
                                    <li>License to operate the hotel and any other MCGM certificates (Like Health, Food
                                        License and Bombay Shops &amp; Establishment Certificate as applicable).</li>
                                    <li>NOC from Society/ Landlord/ Owner of land.</li>
                                    <li>Land Purchase Deed (If Owned) Agreement (If Leased/Rented) – For all the plots.
                                    </li><!--<li>Fire NOC as issued by Fire Department for PNG connection. </li>-->
                                    <li>Clearance certificate from fire depatrtment like NOC,fire compliance as
                                        applicable for PNG connection in different municipal corparations i.e MCGM,
                                        NNMC, TMC,KDMC, MBMC etc.</li>
                                </ul>
                                <p style=" font-size:13px !important; font-style:italic; color: #000000">* Notary/ Self
                                    Attested Xerox Copies as applicable.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    