import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-nametranfer',
  templateUrl: './nametranfer.component.html',
  styleUrls: ['./nametranfer.component.css']
})
export class NametranferComponent {

  public base = environment.baseUrl

  allPost: any[] = [];

  constructor(private PostService: SliderService,private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.getAllHighlight()
    
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }


  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    
      this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
      

    })
    
  }
}
