<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/login-banner.jpg">
  <div class="banner-heading">
    <h1>Log Your <span>Meter Reading</span></h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a>Residential Png</a></li>
        <li class=""><a >Customer Zone</a></li>
        <li class=""><a >Customer Login</a></li>
        <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
        <li class="active" aria-current="page">Log Your Meter Reading</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <div class="latestnews-section">
          <app-dashboard-info-card></app-dashboard-info-card>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="light-blue-bg">
		  <div class="row" ><!-- *ngIf='isformShow==true && !isShow' -->
		  <h6>Dear Consumer,</h6>
			<p>We value your Privacy. If you wish to avoid visit of Meter reader inside your
				premises, please provide us a clear image of the meter for us to raise your Bill
				as per Actual Reading. For this purpose, you will receive an SMS from MGL with a
				link / use <small class="text-img-connect"><img
						src="assets/images/mgl-connect-logo-sml.jpg"></small> app. <b>Please submit
				the reading between <em
					id="ContentPlaceHolder1_lblMrDate1">{{BillingData.n_mr_date1}}</em> and
				<em id="ContentPlaceHolder1_lblMrDate2">{{BillingData.n_mr_date2}}</em>.</b>
			</p>
		  </div>
          <div class="row"><!-- *ngIf="isformShow==true && isShow" --> 
            <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">

              <div class="customer-login-page log-meter-reading">
                <div class="log-meter-text">
                  <ul>
                    <li>All the fields which are marked by<span class="text-danger">*</span> are mandatory</li>
                  </ul>
                </div>
                <form [formGroup]="UserForm">
                  <div class="row">
                    

                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input type="email" class="form-control" formControlName="Email"
                              placeholder="Email"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Email'].errors }"
                              id="Email" />
                              
                          <div *ngIf="submitted && UserForm.controls['Email'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['Email'].errors">
                                  Required Field</div>
                          </div>
                          <label for="Email">Email<span style="color: crimson;">*</span></label>
                      </div>
                  </div>
				  
                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input type="text" class="form-control" formControlName="name"
                              placeholder="name"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['name'].errors }"
                              id="name" />
                              
                          <div *ngIf="submitted && UserForm.controls['name'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['name'].errors">
                                  Required Field</div>
                          </div>
                          <label for="name">Name of the person entering details <span style="color: crimson;">*</span></label>
                      </div>
                  </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                      <div class="meter-reading-box mb-30">
                        <!-- <i class="demo-icon uil-basketball-hoop"></i> -->
                        <div class="meter-cont">
                          <label>Previous Meter Reading</label>
                          <p>{{this.BillingData.closing}}</p>
                        </div>
                      </div>
                    </div>

               <!--      <div class="col-sm-3 form-group">
                      <div class="form-floating">
                          <input readonly type="text" class="form-control" formControlName="MeterFrom"
                              placeholder="MeterFrom"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MeterFrom'].errors }"
                              id="MeterFrom" />
                              
                          <div *ngIf="(submitted && UserForm.controls['MeterFrom'].errors) || (submitted && !UserForm.controls['MeterTo'].errors)"
                              class="invalid-feedback">
                              <div style="white-space: nowrap;" *ngIf="(submitted && UserForm.controls['MeterFrom'].errors) || (submitted && !UserForm.controls['MeterTo'].errors)">
                                Please submit meter reading in your Meter reading window</div>
                          </div>
                          <label for="MeterFrom">Meter Reading From<span style="color: crimson;">*</span></label>
                          
                      </div>
                  </div>

                  <div class="col-sm-3 form-group">
                    <div class="form-floating">
                        <input readonly type="text" class="form-control" formControlName="MeterTo"
                            placeholder="MeterTo"
                            [ngClass]="{ 'is-invalid': submitted && UserForm.controls['MeterTo'].errors }"
                            id="MeterTo" />
                            
                     
                        <label for="MeterTo">Meter Reading To <span style="color: crimson;">*</span></label>
                    </div>
                </div> -->

                  <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="meter-reading-box mb-30">
                        <!-- <i class="demo-icon uil-calender"></i> -->
                        <div class="meter-cont">
                          <label>Meter Reading Window </label>
                          <p>{{BillingData.n_mr_date1}} TO {{BillingData.n_mr_date2}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-12">
                      <div class="meter-reading-box mb-30">
                        <!-- <i class="demo-icon uil-calender"></i> -->
                        <div class="meter-cont">
                          <label>Prev Meter Reading Date </label>
                          <p>{{BillingData.desp_date}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label>Meter Reading <span class="text-danger">*</span></label>
                      <ul class="meter-reading-input-box mb-30">
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading1"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading1'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading2"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading2'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading3"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading3'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading4"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading4'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading5"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading5'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li class="d-flex justify-content-center align-items-center">
                          -
                        </li>
                        <li>
                          <div class="otp-input blueborder-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading6"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading6'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input blueborder-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading7"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading7'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>

                        </li>
                        <li>
                          <div class="otp-input blueborder-input">
                            <input onKeyPress="if(this.value.length==1) return false;" formControlName="Reading8"
                              [ngClass]="{'is-invalid':submitted && UserForm.controls['Reading8'].errors}"
                              class="from-control" type="number" id="name" name="name" placeholder="" min="0" step="1">
                          </div>


                        </li>
                      </ul>
                    </div>
                       <div
                          *ngIf="submitted && (UserForm.controls['Reading1'].errors || UserForm.controls['Reading2'].errors || UserForm.controls['Reading3'].errors || UserForm.controls['Reading4'].errors ||
                        UserForm.controls['Reading5'].errors || UserForm.controls['Reading6'].errors || UserForm.controls['Reading7'].errors || UserForm.controls['Reading8'].errors) " class="meter-reading-error">
                          <div style="white-space: nowrap;"
                            *ngIf="UserForm.controls['Reading1'].errors || UserForm.controls['Reading2'].errors || UserForm.controls['Reading3'].errors || UserForm.controls['Reading4'].errors ||
                          UserForm.controls['Reading5'].errors || UserForm.controls['Reading6'].errors || UserForm.controls['Reading7'].errors || UserForm.controls['Reading8'].errors">
                           Meter Reading is Required or Don't enter special characters
                          </div>
                        </div>


                    

                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                        <select class="form-select" #opt (change)="this.UserForm.get('Reason')?.setValue(opt.value)" [ngClass]="{'is-invalid':submitted && UserForm.controls['Reason'].errors}" name="" id="">
                          <option value=""  selected>Select Options</option>
                          <option *ngFor="let i of options">{{i}}</option>
                          
                        </select>
                              
                          <div *ngIf="submitted && UserForm.controls['Reason'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['Reason'].errors">
                                  Required Field</div>
                          </div>
                          <label for="Datess">Reason <span style="color: crimson;">*</span></label>
                      </div>
                  </div>

                    <div class="col-sm-6 form-group">
                      <div class="form-floating">
                          <input type="date" class="form-control" formControlName="Datess"
                              placeholder="Datess"
                              [ngClass]="{ 'is-invalid': submitted && UserForm.controls['Datess'].errors }"
                              id="Datess" />
                              
                          <div *ngIf="submitted && UserForm.controls['Datess'].errors"
                              class="invalid-feedback">
                              <div *ngIf="submitted && UserForm.controls['Datess'].errors">
                                  Required Field</div>
                          </div>
                          <label for="Datess">Date <span style="color: crimson;">*</span></label>
                      </div>
                  </div>

                  </div>
                </form>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <a (click)="submit()" href="javascript:void(0)" class="greenbtn2">Submit <i class="demo-icon uil-arrow-right"></i></a>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="text-right md-text-left">
                      <a (click)="reset()" href="javascript:void(0)" class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="alertType == 'submit'"
                  [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                  class="card card-inverse-info" id="context-menu-simple">
                  <div class="card-body">
                    <p class="card-text">
                      {{alertMsg}}
                  </div>
                </div>
                 <div *ngIf="alertType == 'windowsubmit'"
                  [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                  class="card card-inverse-info" id="context-menu-simple">
                  <div class="card-body">
                    <p class="card-text">
                      {{alertMsg}}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->