<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Connection Application Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Domestic Connection Request</a></li>
               <!--  <li class=""><a>Domestic PNG Registration</a></li> -->
                <li class="active" aria-current="page">Connection Application Status</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-lg-0 mb-30">
                        <div class="box882_left">

                            <div class="con ">

                                <div *ngIf="alertType == 'sucess'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info mb-3" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                                <div *ngIf="alertType == 'status'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                                <p><b><i>Please choose from the options below for verification </i></b></p>
                                <div class="form-check">
                                    <input (click)="open(input1.value)" #input1 id="ContentPlaceHolder1_rb1"
                                        type="radio" name="ctl00$ContentPlaceHolder1$rb" value="rb1"
                                        class="form-check-input">
                                    <label for="ContentPlaceHolder1_rb1"> Yes, I remember My Application No</label>
                                </div>

                                <div class="form-check">
                                    <input (click)="open(input2.value)" #input2 id="ContentPlaceHolder1_rb2"
                                        type="radio" name="ctl00$ContentPlaceHolder1$rb" value="rb2"
                                        class="form-check-input">
                                    <label for="ContentPlaceHolder1_rb2">I don't remember My Application No</label>
                                </div>

                                <div class="mt-20">
                                    <form *ngIf="rb1Open" [formGroup]="UserForm1">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 ">
                                                <div class="customer-login-page">
                                                    <div class="row">
                                                        <div class="col-sm-6 form-group">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control"
                                                                    formControlName="ApplicationNo"
                                                                    placeholder="Enter ApplicationNo"
                                                                    [ngClass]="{ 'is-invalid': submitted && UserForm1.controls['ApplicationNo'].errors }"
                                                                    id="ApplicationNo" />
                                                                <div *ngIf="submitted && UserForm1.controls['ApplicationNo'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm1.controls['ApplicationNo'].errors.required">
                                                                        Required Field</div>
                                                                </div>
                                                                <label for="fullname">Application No.<span
                                                                        style="color: crimson;">*</span></label>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-6 form-group">
                                                            <div class="form-floating">
                                                                <input type="email" class="form-control"
                                                                    formControlName="Email" placeholder="Enter Email"
                                                                    [ngClass]="{ 'is-invalid': submitted && UserForm1.controls['Email'].errors }"
                                                                    id="Email" />
                                                                <div *ngIf="submitted && UserForm1.controls['Email'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm1.controls['Email'].errors.required">
                                                                        Required Field</div>
                                                                    <div
                                                                        *ngIf="UserForm1.controls['Email'].errors.pattern">
                                                                        Email must be a valid email address.</div>
                                                                </div>
                                                                <label for="fullname">Email<span
                                                                        style="color: crimson;">*</span></label>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 ">
                                                                <div class="mb-20">
                                                                    <button (click)="submit1()" class="greenbtn2 mr-2"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>

 View  Status <i class="demo-icon uil-arrow-right"></i></button> 
                                                                </div>
                                                                <!-- *ngIf="isReceipt" -->
                                                                <span >
                                                                    <!-- <button (click)="printForm()"
                                                                        class="greenbtn2 mr-2">Print Application Form <i
                                                                            class="demo-icon uil-arrow-right"></i></button> -->
                                                                <button *ngIf="fullPayFlag==true || pngkamahostavPayFlag==true" (click)="printApplFull()"
                                                                        class="greenbtn2 mr-2">Print Application Form <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                              <button *ngIf="fdcPayFlag==true" (click)="printApplFDC()"
                                                                        class="greenbtn2 mr-2">Print Application Form <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                              <button *ngIf="emiPayFlag==true" (click)="printApplEMI()"
                                                                        class="greenbtn2 mr-2">Print Application Form <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                            </span>

                                                            <!-- <span *ngIf="isReceipt" class=""> -->
                                                                <span *ngIf="AllData.pay_flag == '1'"
                                                                    class="mb-20 ">
                                                                   <!--  <button (click)="printReceipt()" class="greenbtn2">
                                                                        Print Receipt <i
                                                                            class="demo-icon uil-arrow-right"></i></button> -->

                                                                             <button *ngIf="fullPayFlag==true || pngkamahostavPayFlag==true" (click)="printReceiptFull()" class="greenbtn2">
                                                                        Print Receipt <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                             <button *ngIf="emiPayFlag==true" (click)="printReceiptEMI()" class="greenbtn2">
                                                                        Print Receipt <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                             <button *ngIf="fdcPayFlag==true" (click)="printReceiptFDC()" class="greenbtn2">
                                                                        Print Receipt <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                </span>
                                                            <!-- </span> -->
															
                                                            <span *ngIf="result1 && AllData.ProofOfOwnerShip != '' && ( AllData.pay_flag == '0' || AllData.pay_flag == '' )" class="">
																<span>
																	<p>Name: {{AllData.FName}} {{AllData.MName}} {{AllData.LName}}</p>
																	<p>Contact: {{AllData.Contact}}</p>
																	<p>Email: {{AllData.Email}}</p>
																	<p>Address: FlatNo-{{AllData.FlatNo}}, Floor-{{AllData.Floor}}, PlotNo-{{AllData.PlotNo}} Wing-{{AllData.Wing}} </p>
																	<p>Building Name-{{AllData.BuildingName}}, Sociaty Name-{{AllData.SociatyName}}, Colony-{{AllData.Colony}}</p>
																	<p>Road Name-{{AllData.RoadName}}, LandMark-{{AllData.LandMark}}, Location-{{AllData.Location}}</p>
																	<p>City Name-{{AllData.CityDistrict}}, Pincode-{{AllData.Pincode}}</p>
																</span>
																<span>
																	<p>Note- Please wait for system to redirect back to our website and do not close the payment window.</p>
																</span>
                                                                <span class="mb-20 ">
                                                                    <button (click)="makePaymentPop()" data-bs-toggle="modal"
                                                                    data-bs-target="#confirm-pay" class="greenbtn2">
                                                                        Make Payment <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                </span>
                                                            </span>

                                                            <span *ngIf="emisupemiFlag==true" class="">
																<span>
																	
																</span>
                                                                <span class="mb-20">
                                                                    <button data-bs-toggle="modal"
                                                                        data-bs-target="#upload-doc-emiment"
                                                                        class="greenbtn2 "> Select Proof of eligibility for emi scheme <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                </span>
                                                            </span>
                                                             <span *ngIf="result1 && AllData.ProofOfIdentity == ''" class="">
                                                                <span>
                                                                    <p>Note- Please do not select same name document.</p>
                                                                </span>
                                                                <span class="mb-20">
                                                                    <button data-bs-toggle="modal"
                                                                        data-bs-target="#upload-doc-ment"
                                                                        class="greenbtn2 "> Upload Document To Make
                                                                        Payment <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                </span>
                                                            </span>

                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div class="mt-20">
                                    <form *ngIf="rb2Open" [formGroup]="UserForm2" class="">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12">

                                                <div class="customer-login-page">

                                                    <div class="row">

                                                        <div class="col-sm-6 form-group">
                                                            <div class="form-floating">
                                                                <input
                                                                    onKeyPress="if(this.value.length==10) return false;"
                                                                    type="number" class="form-control"
                                                                    formControlName="Mobile" placeholder="Enter Email"
                                                                    [ngClass]="{ 'is-invalid': submitted2 && UserForm2.controls['Mobile'].errors }"
                                                                    id="mobile" />
                                                                <div *ngIf="submitted2 && UserForm2.controls['Mobile'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm2.controls['Mobile'].errors.required">
                                                                        Required Field</div>
                                                                </div>
                                                                <label for="mobile">Mobile<span
                                                                        style="color: crimson;">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 form-group">
                                                            <div class="form-floating">
                                                                <input type="email" class="form-control"
                                                                    formControlName="Email" placeholder="Enter Email"
                                                                    [ngClass]="{ 'is-invalid': submitted2 && UserForm2.controls['Email'].errors }"
                                                                    id="Email" />
                                                                <div *ngIf="submitted2 && UserForm2.controls['Email'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm2.controls['Email'].errors.required">
                                                                        Required Field</div>
                                                                    <div
                                                                        *ngIf="UserForm2.controls['Email'].errors.pattern">
                                                                        Email must be a valid email address.</div>

                                                                </div>
                                                                <label for="mobile">Email<span style="color: crimson;">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 form-group">
                                                            <div class="form-floating">
                                                                <input
                                                                    onKeyPress="if(this.value.length==10) return false;"
                                                                    type="text" class="form-control"
                                                                    formControlName="Pan" placeholder="Enter Pan"
                                                                    [ngClass]="{ 'is-invalid': submitted2 && UserForm2.controls['Pan'].errors }"
                                                                    id="Pan" />
                                                                <div *ngIf="submitted2 && UserForm2.controls['Pan'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm2.controls['Pan'].errors.required">
                                                                        Required Field</div>
                                                                </div>
                                                                <label for="mobile">Pan Card No.<span
                                                                        style="color: crimson;">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
                                                                <div class="mb-20">
                                                                    <button (click)="submit2()" class="greenbtn2"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> View Status
																	<i class="demo-icon uil-eye"></i></button>
                                                                </div>
                                                            
                                                            <div *ngIf="result2">
                                                                <div class="mb-20">
                                                                   <!--  <button (click)="printForm()"
                                                                        class="greenbtn2">Print Application Form <i class="demo-icon uil-print"></i></button> -->

                                                                         <button *ngIf="fullPayFlag==true" (click)="printApplFull()"
                                                                        class="greenbtn2">Print Application Form <i class="demo-icon uil-print"></i></button>
                                                                         <button *ngIf="fdcPayFlag==true" (click)="printApplFDC()"
                                                                        class="greenbtn2">Print Application Form <i class="demo-icon uil-print"></i></button>
                                                                         <button *ngIf="emiPayFlag==true" (click)="printApplEMI()"
                                                                        class="greenbtn2">Print Application Form <i class="demo-icon uil-print"></i></button>

                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div *ngIf="result2 && AllData.ProofOfOwnerShip != '' && ( AllData.pay_flag == '0' || AllData.pay_flag == '' )"
                                                                class="col-lg-12 col-md-12 col-sm-12 ">
																<div class="white-box mt-30 mb-30">
                                                                  <div class="my-application">
																<div class="app-head"><p>Name</p> 
                                                                    <h6>{{AllData.FName}} {{AllData.MName}} {{AllData.LName}}</h6>
                                                                    </div>
                                                                <div class="app-body">    
																<div class="category-sect"><p>Contact</p><h6>{{AllData.Contact}}</h6></div>
																<div class="category-sect"><p>Email</p><h6> {{AllData.Email}}</h6></div>
																<div class="category-sect2"><p>Address</p><h6> FlatNo-{{AllData.FlatNo}}, Floor-{{AllData.Floor}}, PlotNo-{{AllData.PlotNo}} Wing-{{AllData.Wing}} </h6></div>
																<div class="category-sect2"><p>Building Name</p> <h6>{{AllData.BuildingName}}, Sociaty Name-{{AllData.SociatyName}}, Colony-{{AllData.Colony}}</h6></div>
																<div class="category-sect2"><p>Road Name</p> <h6>{{AllData.RoadName}}, LandMark-{{AllData.LandMark}}, Location-{{AllData.Location}}</h6></div>
																<div class="category-sect2 mb-0"><p>City Name</p> <h6>{{AllData.CityDistrict}}, Pincode-{{AllData.Pincode}}</h6></div>
                                                                </div>
                                                                </div>  
															     </div>
															<div class="alert alert-warning">
																Note- Please wait for system to redirect back to our website and do not close the payment window.
															</div>
                                                                <div class="mb-20">
                                                                    <!-- <button (click)="makePayment()" class="greenbtn2">
                                                                        Make Payment <i
                                                                            class="demo-icon uil-arrow-right"></i></button> -->
                                                                            <button (click)="makePaymentPop()" data-bs-toggle="modal"
                                                                    data-bs-target="#confirm-pay" class="greenbtn2">
                                                                        Make Payment <i
                                                                            class="demo-icon uil-arrow-right"></i></button>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="result2 && AllData.ProofOfIdentity == ''"
                                                                class="col-lg-4 col-md-4 col-sm-12 ">
																<span>
																	<p>Note- Please do not select same name document.</p>
																</span>
                                                                <div class="mb-20">
                                                                    <button data-bs-toggle="modal"
                                                                        data-bs-target="#upload-doc-ment"
                                                                        class="greenbtn2"> Upload Document To Make
                                                                        Payment <i class="demo-icon uil-upload-alt"></i></button>
                                                                </div>
                                                            </div>


                                                        </div>

														<div *ngIf="alertMsg"
															[ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
															class="card card-inverse-info" id="context-menu-simple">
															<div class="card-body">
																<p class="card-text">
																	{{alertMsg}}
															</div>
														</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>

                                <div *ngIf="result1" class="station-list mt-20">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <th>Application No</th>
                                                <th>Business Partner No</th>
                                                <th>Remarks</th>
                                                <th>Engineer Remarks</th>
                                                <th>CWL Date</th>
                                            </tr>
                                            <tr>
                                                <td>{{AllData.ApplicationNo}}</td>
                                                <td>{{AllData.Status ? 'Your application is in process.' : 'Your application is in process.'}}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <!-- <p class="mt-20">THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 DBS BANK UPI PAYMENTS ARE AS FOLLOWS </p> -->
                                </div>



                                <!-- <div *ngIf="result2" class="station-list mt-20">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <th>Application No</th>
                                                <th>Business Partner No</th>
                                                <th>Remarks</th>
                                                <th>Engineer Remarks</th>
                                                <th>CWL Date</th>
                                            </tr>
                                            <tr>
                                                <td>{{AllData.ApplicationNo}}</td>
                                                <td>{{AllData.Status ?'Your application is in process.' : 'Your application is in process.'}}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <!-- <ul >
                                        <li class="payments-box">
                                            <span class="InstrumentNo">Application No</span>
                                            <span class="PaymentDate">Business Partner No</span>
                                            <span class="PaymentSource">-</span>
                                            <span class="Amount">Remarks</span>
                                            <span class="Amount">Engineer Remarks</span>
                                            <span class="Amount">CWL Date</span>
                                            
                                        </li>
        
                                        <li>
                                            <span class="InstrumentNo">{{AllData.ApplicationNo}}</span>
                                            <span class="PaymentDate">{{AllData.Status ? 'We have Mailed You' : 'Your application is in process.'}}</span>
                                            <span class="PaymentSource">-</span>
                                            <span class="Amount">-</span>
                                            <span class="Amount">-</span>
                                            <span class="Amount">-</span>
                                            <span class="Amount">-</span>
        
                                        </li>
        
                                    </ul> -->
                                    <!-- <p class="mt-20">THE ACKNOWLEDGMENT TOWARDS YOUR LAST 3 DBS BANK UPI PAYMENTS ARE AS FOLLOWS </p> 
                                </div> -->



                                <div class="form_100per pad">



                                </div>


                                <div class="clear"></div>
                                <span id="ContentPlaceHolder1_lblmsg"></span>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>

<div class="modal fade" id="upload-doc-ment" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="upload-doc-mentModalLabel">Upload Documents</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i class="demo-icon uil-multiply"></i></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="UserForm4">

                    <p class="mb-15"><b><i>Please upload a scanned copy of any of the below mentioned
                                documents (preferably in PDF Format):</i></b></p>
                    <div class="mb-25">
                        <ol class="number-list">
                            <li> Society Maintenance Receipt of the last month (which displays your name &
                                flat number)</li>
                            <li> Agreement copy (which displays your name & flat number) </li>
                            <li> Share Certificate 4. Electricity bill (which displays your name & flat
                                number)</li>
                        </ol>
                    </div>
					<p class="mb-15"><b><i>
													Note- Please upload PDF file and file size should be below 2MB.
												</i></b></p>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="customer-login-page">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-floating mb-3">
                                            <select #types1
                                                [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1'].errors}"
                                                (change)="this.UserForm4.get('type1')?.setValue(types1.value)"
                                                class="form-select">
                                                <option value="" selected>Select Proof of Ownership</option>
                                                <option value="House Tax Receipt">House Tax Receipt</option>
                                                <option value="Sale Deed/ Aggreement to sale">Sale Deed/
                                                    Aggreement
                                                    to sale</option>
                                                <option value="lectricity Bill">Electricity Bill</option>
                                                <option value="Society Share Certificate / Society Letter">
                                                    Society
                                                    Share Certificate / Society Letter</option>
                                                <option value="Society Maintenance Bill / Company Letter">
                                                    Society
                                                    Maintenance Bill / Company Letter</option>
                                                <option value="Lease Deed with Owner's NOC">Lease Deed with
                                                    Owner's
                                                    NOC</option>
                                            </select>
                                            <div *ngIf="submitted4 && UserForm4.controls['type1'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm4.controls['type1'].errors.required">
                                                    Required Field</div>
                                            </div>
                                            <label for="type1">Select Proof of Ownership<span
                                                    class="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="mb-10 cu-login-input2">
                                            <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                            <input formControlName="type1File" accept="application/pdf"
                                                (change)="checkApplictionFile($event,'1st')"
                                                [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1File'].errors}"
                                                class="from-control" type="file" id="name" name="name"
                                                placeholder="Enter your BP Number">
                                            <div *ngIf="submitted4 && UserForm4.controls['type1File'].errors"
                                                class="invalid-feedback">
                                                <div style="margin-left: 50px;"
                                                    *ngIf="UserForm4.controls['type1File'].errors">Required Field
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div *ngIf="AllData" class="form-floating mb-3">
                                            <select #types2
                                                [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type2'].errors}"
                                                (change)="this.UserForm4.get('type2')?.setValue(types2.value)"
                                                class="form-select">
                                                <option value="" selected>Select Proof of identity</option>
                                                <option *ngIf="AllData.IsNRI == 'false' || AllData.IsNRI == '' || AllData.IsNRI == null"
                                                    value="PAN Card">PAN Card</option>
                                                <option *ngIf="AllData.IsNRI == 'false' || AllData.IsNRI == '' || AllData.IsNRI == null"
                                                    value="Voter ID">Voter ID</option>
                                                <option *ngIf="AllData.IsNRI == 'false' || AllData.IsNRI == '' || AllData.IsNRI == null"
                                                    value="Aadhar Card">Aadhar Card</option>
                                                <option *ngIf="AllData.IsNRI == 'false' || AllData.IsNRI == '' || AllData.IsNRI == null"
                                                    value="Driving License">Driving License</option>
                                                      <option *ngIf="AllData.IsNRI == 'false'"
                                                    value="Passport">Passport</option>
                                                <option *ngIf="AllData.IsNRI == 'true'"
                                                    value="Passport">Passport</option>
                                                <option *ngIf="AllData.IsNRI == '' || AllData.IsNRI == null"
                                                    value="Passport">Passport</option>
                                               <!--  <option *ngIf="AllData.IsNRI == true || AllData.IsNRI == '' || AllData.IsNRI == null"
                                                    value="Passport">Passport</option> -->


                                            </select>
                                            <div *ngIf="submitted4 && UserForm4.controls['type2'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="UserForm4.controls['type2'].errors.required">
                                                    Required Field</div>

                                            </div>
                                            <label for="type2">Required Field<span class="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="mb-10 cu-login-input2">
                                            <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                            <input (change)="checkApplictionFile($event,'2nd')" accept="application/pdf"
                                                [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type2File'].errors}"
                                                formControlName="type2File" class="from-control" type="file"
                                                onKeyPress="if(this.value.length==10) return false;" id="name"
                                                name="name" placeholder="Enter your BP Number">
                                            <div *ngIf="submitted4 && UserForm4.controls['type2File'].errors"
                                                class="invalid-feedback">
                                                <div style="margin-left: 50px;"
                                                    *ngIf="UserForm4.controls['type2File'].errors">Proof of
                                                    identity
                                                    required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 ">
                                        <button (click)="uploadDoc()" class="greenbtn2">Upload <i
                                                class="demo-icon uil-upload-alt"></i></button>
                                    </div>

                                    <div *ngIf="alertType == 'LastSubmit'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="upload-doc-emiment" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="upload-doc-emimentModalLabel">Submit Documents</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i class="demo-icon uil-multiply"></i></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="UserFormemi">

                    <p class="mb-15"><b><i>Proof of eligibility: (select any one)</i></b></p>
                    <div class="mb-25">
                         <ol class="number-list">
                                        <li> Allotment letter of SRA Authority / SRA Society</li>
                                        <li> BPL (Saffron Colored) Ration Card</li>
                                        <li> Self Declaration - I declare that my Annual Income is up to Rs. 3 Lakhs</li>
                                        
                                    </ol>
                    </div>
                    <p class="mb-15"><b><i>
                                                    Note- Please upload PDF file and file size should be below 2MB.
                                                </i></b></p>
                    <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">

                                        <div class="customer-login-page">

                                            <div class="row">

                                              
                                                 <div class="col-lg-6 col-md-6 col-sm-12">

                                                    <div class="form-floating mb-3">
                                                        <select formControlName="emitype1"
                                                            [ngClass]="{'is-invalid':submittedEmi && UserFormemi.controls['emitype1'].errors}"
                                                            class="form-select" (change)="checkEmiproof()">
                                                            <option value="" selected>Select Proof of eligibility for emi scheme</option>
                                                            <option value="Allotment letter of SRA Authority / SRA Society">Allotment letter of SRA Authority / SRA Society</option>
                                                            <option value="BPL (Saffron Colored) Ration Card">BPL (Saffron Colored) Ration Card</option>
                                                            <option value="Self Declaration - I declare that my Annual Income is up to Rs. 3 Lakhs">Self Declaration - I declare that my Annual Income is up to Rs. 3 Lakhs</option>
                                                            
                                                        </select>
                                                        <div *ngIf="submittedEmi && UserFormemi.controls['emitype1'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserFormemi.controls['emitype1'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="emitype1">Select Proof of eligibility for emi scheme<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="mb-10 cu-login-input2" *ngIf="proofofEmiFlag == false">
                                                       
                                                        <input formControlName="emitype1File"
                                                            (change)="checkApplictionEMIFile($event,'1st')"
                                                            [ngClass]="{'is-invalid':submittedEmi && UserFormemi.controls['emitype1File'].errors}"
                                                            class="from-control" type="file" id="name" name="name"
                                                            accept="application/pdf"
                                                            placeholder="Select ownership proof"
                                                            accept="image/jpeg, image/png, application/pdf">
                                                        <div *ngIf="submittedEmi && UserFormemi.controls['emitype1File'].errors"
                                                            class="invalid-feedback">
                                                            <div style="margin-left: 50px;"
                                                                *ngIf="UserFormemi.controls['emitype1File'].errors">Required
                                                                Field
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                               
                                                <div class="mt-10 ">
                                                    <button (click)="submitEmi()" class="greenbtn2">Submit <i
                                                            class="demo-icon uil-upload-alt"></i></button>
                                                </div>

                                               <!--  <div *ngIf="alertType == 'emiLastSubmit'"
                                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                    class="card card-inverse-info" id="context-menu-simple">
                                                    <div class="card-body">
                                                        <p class="card-text">
                                                            {{alertMsg}}
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                </form>
            </div>
        </div>
    </div>
</div>




<div class="modal fade" id="confirm-pay" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="upload-doc-mentModalLabel">Please Confirm your Payment Type</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i class="demo-icon uil-multiply"></i></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="confirmForm">

                    
                   
					
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="customer-login-page">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">


                                        <div class="form-floating mb-3">
                                            <select (change)="checkPayment()" formControlName="paymentType"
                                                [ngClass]="{'is-invalid':submitted3 && confirmForm.controls['paymentType'].errors}"
                                                class="form-select">
                                                <!-- <option value="" selected>Select Options</option> -->
                                               <!--  <option value="Full payment">Full payment – Rs 6135</option> -->
                                                
                                                            <option value="Full payment">Full payment – Rs 6385</option>
                                            s<option *ngIf="checkPNG==true"  value="PNG Mahotsav">PNG Mahotsav – Rs 6885</option>
                                                <option value="FDC Scheme">FDC Scheme – Rs 885</option>
                                                <option value="EMI Scheme">EMI Scheme- Rs 885</option>



                                            </select>
                                            <div *ngIf="submitted3 && confirmForm.controls['paymentType'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="confirmForm.controls['paymentType'].errors.required">
                                                    Required Field</div>

                                            </div>
                                            <label for="paymentType">Payment Type<span
                                                    class="text-danger">*</span></label>

                                        </div>
                                      <!--   <div *ngIf="fullPayFlag==true">
                                            <p class="mb-1"><b><i>₹ 750/- Non-Refundable Application
                                                        Charges.</i></b></p>
                                            <p class="mb-1"><b><i>₹ 135/- GST @18% of Application
                                                        Charges</i></b></p>
                                            <p class="mb-1"><b><i>₹ 4500/- Interest Free Refundable Security
                                                        Deposit for the Connection.</i></b></p>
                                            <p class="mb-4"><b><i>₹ 750/- Interest Free Refundable Security
                                                        Deposit for the Gas Consumption.</i></b></p>
                                        </div> -->
                                         <div *ngIf="fullPayFlag==true">
                                                        <p  class="mb-1"><b><i>₹ 750/- Non-Refundable Application
                                                                    Charges.</i></b></p>
                                                        <p  class="mb-1"><b><i>₹ 135/- GST @18% of Application
                                                                    Charges</i></b></p>
                                                        <p  class="mb-1"><b><i>₹ 4500/- Interest Free Refundable Security
                                                                    Deposit for the Connection.</i></b></p>
                                                                      <p  class="mb-1"><b><i>₹ 1000/- Interest Free Refundable Security
                                                                    Deposit for the Gas Consumption.</i></b></p>
                                                                </div>
                                                      
                                                     <div *ngIf="pngkamahostavPayFlag==true">
                                                      <!--  <p  class="mb-1"><b><i>₹ 885/- Non-Refundable Application
                                                                    Charges.</i></b></p> -->
                                                                     <p  class="mb-1"><b><i>₹ 750/- Non-Refundable Application
                                                                    Charges.</i></b></p>
                                                        <p  class="mb-1"><b><i>₹ 135/- GST @18% of Application
                                                                    Charges</i></b></p>
                                                      <p  class="mb-1"><b><i>₹ 5000/- Interest Free Refundable Security
                                                                    Deposit for the Connection.</i></b></p>
                                                     <p  class="mb-1"><b><i>₹ 1000/- Interest Free Refundable Security
                                                                    Deposit for the Gas Consumption.</i></b></p>
                                                    </div>
                                                    <div *ngIf="fdcPayFlag==true">
                                                        <p class="mb-1"><b><i>₹ 750/- Non-Refundable Application
                                                                    Charges.</i></b></p>
                                                        <p class="mb-1"><b><i>₹ 135/- GST @18% of Application
                                                                    Charges.</i></b></p>
                                                       <!--  <p class="mb-1"><b><i>₹ 750/- Interest Free Refundable Security
                                                                    Deposit for the Gas Consumption*.</i></b></p> -->
                                                        <p class="mb-1"><b><i>Note: Rs.30/- per month (Non-Refundable)
                                                                    would be charged on every bill.</i></b></p>
                                                    </div>
                                                    <div *ngIf="emiPayFlag==true">
                                                        <p class="mb-1"><b><i>₹ 750/- Non-Refundable Application
                                                                    Charges.</i></b></p>
                                                        <p class="mb-1"><b><i>₹ 135/- GST @18% of Application
                                                                    Charges.</i></b></p>
                                                       <!--  <p class="mb-1"><b><i>Note : 1. The Security deposit comprising
                                                                    of gas connection and gas consumption charges
                                                                    amounting to 5750/- can be paid in 12 gas bills (in
                                                                    bimonthly billing) 518.50 each.<br>
                                                                    2. In case the consumer has opted EMI Scheme and
                                                                    wants to foreclose his account, he needs to pay the
                                                                    balance principal amount and his account would be
                                                                    closed as full and final settled.</i></b></p> -->

                                                    </div>

                                    </div>

                                 

                                
                                   
                                    <div class="mt-10">
                                    <button (click)="confirmPay()" class="greenbtn2">Confirm</button>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>