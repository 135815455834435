import { Component } from '@angular/core';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pay-upaymenterror',
  templateUrl: './pay-upaymenterror.component.html',
  styleUrls: ['./pay-upaymenterror.component.css']
})
export class PayUpaymenterrorComponent {
  id: any
  err: any
  constructor(public datePipe: DatePipe, public UserService: UserRegistrationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
   
    this.id = this.route.snapshot.paramMap.get('id');
    this.err = this.route.snapshot.paramMap.get('err');

  }


}
