<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CSR Initiatives</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a>CSR</a></li>
                <li class="active" aria-current="page">CSR Initiatives</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <div class="customer-slider mt-30 mb-30">
                    <app-tips></app-tips>
                </div>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">

                    <!-- <p>MGL Corporate <span>Social Responsibility </span></p>
                    <p>The Company has CSR Policy, which is reviewed on time-to-time basis, based on current happenings on CSR front. Last review was done in 2019 which was to align it with the guidelines formulated by the Companies (Corporate Social Responsibility Policy) Rules, 2014 and subsequent amendments thereof.</p>
                    <div class="mt-30 mb-30">
                        <div class="blue-text mb-20">
                            Vision
                        </div>
                        <p>In accordance with vision of the company, its CSR initiatives will supplement its contribution in environment protection, development of healthy and enlightened citizens, social upliftment and sustainable community development through its service, conduct and social initiatives.</p>
                    </div>

                    <div class="mt-30 mb-30">
                        <div class="blue-text mb-20">
                            CSR Committe
                        </div>
                        <p>MGL has constituted the CSR Committee as per mandate of the relevant rules. The CSRC has adequate representation of Independent Directors. The link to the current membership of CSR Committee is appended herein under:<a [routerLink]="['/CorporateGovernance']">Corporate Governance</a></p>
                    </div> -->
                    <!-- <div class="mt-30 mb-30">
                        <div class="blue-text mb-20">
                            Objectives
                        </div>
                        <p>Integrate business function, goals and strategy with planned community development agenda. Play a role in transforming the society through education, health, empowerment and environment awareness.</p>
                    </div> -->

                   <!--  <div class="mt-30 mb-30">
                        <div class="blue-text mb-20">
                            Financial outlay
                        </div>
                        <p>For achieving the Company's CSR objectives through implementation of strategic and sustainable CSR programs, MGL will allocate 2% of its average net profit made during the three immediately preceeding financial years as its Annual CSR Budget. With the activities focused on the thrust areas specified in the Schedule VII of The Companies Act.</p>
                        <p>The policy outlines the implementation, monitoring and evaluation of the CSR activities to be undertaken.</p>
                    </div> -->

                    <div class="mb-30">
                       <!--  <div class="blue-text mb-20">
                            CSR Initiatives
                        </div> -->
                        <p>With deep rooted tradition, constant participation and belief, MGL through its community outreach programmes and initiatives continues to contribute and focus on Education, Environment and Empowerment thereby fostering the philosophy of taking care of the community for a sustainable tomorrow. Entrenched with a vision for working towards the betterment of the society, MGL's sustained efforts have touched and enhanced lives reflecting its commitment of serving the community.</p>

                        <p>Under the banner of <b>'MGL We Care',</b> various sustainable social activities are conducted throughout the year reaching out with a helping hand because we care. Following are the various thematic areas are covered under <b>“WE CARE”.</b> </p>
                    </div>

                     <div class="light-blue-bg mt-30 csr-initiatives">
                     <div id="rs-services" class="rs-services style4">
                
                    <div class="row">
                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-aarogya-icon.png" alt="MGL Connect">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL AAROGYA for Health Care Program</h3>
                                    <p class="margin-0">MGL Provides a comprehensive range of services to benefit the community and healthcare. MGL has enhanced lives of children of sex workers and provided financial support for upgradation of various charitable hospitals, Medical Day Care Centres etc.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-vikas-icon.png" alt="MGL Social Media">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL VIKAS for Rural Development Program </h3>
                                    <p class="margin-0"> An integrated model village development program being implemented in 6 villages of Sudhagad Taluka in Raigad District. Upgradation of school infrastructure, support classes, health and sanitation, environment protection etc. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-saksham-icon.png" alt="Distribution Company">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL SAKSHAM for Empowerment Program</h3>
                                    <p class="margin-0">Aimed at empowering the community, students in their adolescence are being empowered through counselling and scholarships. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-vidya-icon.png" alt="Customer Care">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL VIDYA for Education Program</h3>
                                    <p class="margin-0">The initiative for enhancement and promotion of education, MGL provides infrastructure support to various schools. </p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-hariyali-icon.png" alt="MGL Social Media">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL HARIYALI for Environment Protection Program</h3>
                                    <p class="margin-0"> Promoting the national agenda of Swachh Bharat for a clean India. Installed litter bins and solar streetlights have also been installed.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-hunar-icon.png" alt="MGL Connect">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL HUNAR for Skill Development Program</h3>
                                    <p class="margin-0">Focuses on skill development of youth for a better India. Though this project training has been provided to youth invocational trades like Gas Plumbing, Welding, Electrician, AC repairing, and as Customer Care Executive.  </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 mb-20 md-mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <img class="dance_hover" src="assets/images/careers/mgl-saathi-icon.png" alt="MGL Connect">
                                </div>
                                <div class="services-content">
                                    <h3 class="title">MGL SAATHI </h3>
                                    <p class="margin-0">Protection of national heritage, art, & culture, benefit for armed forces veterans, war widows & their dependents. Training to promote rural sports, Paralympic & Olympic sports.</p>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                
            </div>
            </div>
            <div class="mt-30 mb-30">
                <div class="row y-middle">
                <div class="col-lg-6 col-md-6 col-sm-12">
                 <h3>CSR Thematic Programs Undertaken in Financial Year 2022 - 2023</h3>   
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                <img class="dance_hover" src="assets/images/CSR-Thematic-Programs-2021-2022.png" alt="CSR Thematic Programs Undertaken in Financial Year 2021 - 2022">
                </div>
                </div>
            </div>

             <div class="mt-30 mb-30">
                <h3>Corporate Social Responsibility </h3>
                <p>Mahanagar Gas Limited (MGL) is a responsible corporate citizen conscious of its social responsibility and has continuously contributed to the national developmental agenda. MGL’s CSR initiatives contribute to environmental protection, the development of healthy and enlightened citizens, social upliftment, and sustainable community development through its service, conduct, and social enterprises. </p>

                
                <p><b>MGL Vidya:</b> - MGL’s CSR initiative for fostering the cause of education. Through this initiative, MGL has identified more than 1500 out of school children and is aiding their mainstreaming by bridging their learning gaps. The company has also facilitated the enhancement of early childhood education by providing training and support to 240 Anganwadi workers and Anganwadi sevika.</p>

                  <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/mgl-vidya.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/mgl-vidya2.jpg" class="w-100">
                           </div>
                       </div>         

             </div>

             <div class="mt-30 mb-30">
                
                <p><b>MGL Saksham:</b> - MGL’s CSR intervention for the empowerment of deprived and underserved sections of society. Under the initiative, MGL has supported nearly 1720 handicapped individuals with assistive aids and appliances, besides facilitating therapeutic interventions for 70 individuals (mostly children) diagnosed with multiple disabilities.</p>
                <p>Fifty children who are either orphaned or abandoned are being groomed in a home in a loving and caring environment to facilitate a typical growth trajectory. MGL has also supported the holistic development of families of 450 orphan children in Alibaug Taluka of Raigad District. More than 15000 individuals spread across 12 slum pockets of Mumbai benefit from Project Disha with interventions like support classes, computer education, formation and strengthening of Self-Help Groups (SHGs), support for entrepreneurship development, health camps, financial literacy, etc. Under a similar initiative, 400 children and women from the slums of Mumbra and Bhiwandi are being supported through balwadis, support classes, an adult literacy center, and a vocational skilling center.</p>

                  <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Saksham.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Saksham2.jpg" class="w-100">
                           </div>
                       </div>         

             </div>

             <div class="mt-30 mb-30">
                
                <p><b>MGL Hunar:</b> - MGL Hunar includes intervention for empowering women. Under the National Skill Development Corporation (NSDC), MGL under the MGL Hunar is implementing an employment-oriented skills development initiative for 450 women in the Raigad District of Maharashtra over a period of 1 year. The program is executed in 5 broader steps: Mobilization of Candidates, Counselling, Training and Assessment, Placement, Post Placement Support. </p>


                  <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Hunar.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Hunar2.jpg" class="w-100">
                           </div>
                       </div>         
             </div>

             <div class="mt-30 mb-30">
                
                <p><b>MGL Aarogya:</b>  - MGL’s CSR intervention for promoting health care. MGL has supported Nair Dental Hospital for the upgradation of Dental Equipment's has been donated to the Dental Departments of Nair Hospital Dental College for the welfare of the people of Mumbai & Suburban area. In addition, this equipment is used for educational purposes for Dental Students. “MGL Dental Implantology Centre” under the Department of Implantology was upgraded under ‘MGL Aarogya.’ Shanmukhanand Foundation pops up with Eye check-up health camps organized under MGL Aarogya. </p>

                <p>MGL supports pediatric cancer patients by providing them safe and hygienic stays, nutritious meals, etc., during treatment. Nearly 90 patients with families benefitted from the intervention during the year. We have   also taken care of the hassle-free traveling of cancer patients by donating a bus.</p>

                <p>MGL supports the 1,50,000 Meals serving program for Vulnerable Families of Patients admitted at KEM Hospital, Mumbai, for 2022-23 under the MGL Aarogya. MGL has supported to Mid-Day Meal program for 15,000 underprivileged children from 124 Municipal and Government aided Schools in Mumbai Region for the entire academic year of 2022-23</p>

                <p>MGL Aarogya - Lifeline Express Hospital Train” project, designed to address the lack of access to medical and surgical facilities, lack of affordability for treatment, and poor health awareness experienced by rural communities of Raigad district. Total Patients served – 9850 through the hospital train was stationed at Goregaon Road Railway Station in Mangaon block, where poor and needy patients came for treatment. </p>

                  <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Aarogya.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Aarogya2.jpg" class="w-100">
                           </div>
                       </div>         
             </div>


               

            <div class="mt-30 mb-30">
                
                <p><b>MGL Vikas:</b>  - Under this initiative, the integrated development of 17 villages of Raigad District is being facilitated, benefitting nearly 12148 individuals through activities like infrastructure upgradation, individual and community level hygiene and sanitation, environmental protection, enhancing the quality of education, awareness generation, rights realization, health, etc.</p>


                  <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Vikas.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Vikas2.jpg" class="w-100">
                           </div>
                             <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Vikas3.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Vikas4.jpg" class="w-100">
                           </div>
                            
                       </div>         
             </div>

<div class="mt-30 mb-30">
                
                <p><b>MGL Unnati:</b>  - Under this project, CSRL provides a work readiness program by giving skill education and providing placement opportunities. We address the lack of opportunities for underprivileged but talented 60 students yearly by trained and mentored to face competitive entrance examinations for engineering colleges. The parents of these students cannot afford the financial support needed to shape their careers. </p>


                  <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Unnati.jpg" class="w-100">
                           </div>
                           <div class="col-lg-6 col-md-6">
                                    <img src="assets/images/csr/MGL-Unnati2.jpg" class="w-100">
                           </div>
                         
                            
                       </div>         
             </div>

             <!-- <div class="mt-30 mb-30 rs-inner-blog">
             <div class="row">
                <div *ngFor="let i of AllData.slice(0, 6)" class="col-lg-6 mb-50">
                    <div class="blog-item csr-initiatives-blogs">
                        <div class="blog-img">
                            <a href="https://mahanagargas.com/blog/{{i._id}}"><img src="https://mahanagargas.com:3000/{{i.Img}}" alt=""></a>
                        </div>
                        <div class="blog-content">
                            <h3 class="blog-title"><a href="https://mahanagargas.com/blog/{{i._id}}">{{i.Title}}</a></h3>
                            <div class="blog-desc">
                               <span [innerHTML]="i.Description"></span>
                            </div>
                          
                        </div>
                    </div>
                        </div>
                        </div>
                        </div> -->



                    <!-- <div class="mt-30 mb-30">
                        <p> With deep rooted tradition, constant participation and belief, MGL through its community
                            outreach programmes and initiatives continues to contribute and focus on Education,
                            Environment and Empowerment thereby fostering the philosophy of taking care of the community
                            for a sustainable tomorrow. Entrenched with a vision for working towards the betterment of
                            the society, MGL’s sustained efforts have touched and enhanced lives reflecting its
                            commitment of serving for the community.</p>

                        <p>Under the banner of 'MGL We Care' various sustainable social activities are conducted
                            throughout the year reaching out to many with a helping hand because we care.</p>
                        <p>Mahanagar Gas Limited being a responsible Corporate has been contributing in social
                            investments primarily in the areas of:</p>
                    </div>
                    <div class="light-blue-bg mt-30 csr-policies">
                        <div id="rs-services" class="rs-services style4">

                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div [routerLink]="['/csrinitiativeedu']" class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/education-icon.png"
                                                alt="Customer Care">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Education</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div [routerLink]="['/csrinitiativeenv']" class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/environment-icon.png"
                                                alt="Distribution Company">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Environment</h3>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div [routerLink]="['/csrinitiativeemp']" class="services-item">
                                        <div class="services-icon">
                                            <img class="dance_hover" src="assets/images/careers/empowerment-icon.png"
                                                alt="MGL Connect">
                                        </div>
                                        <div class="services-content">
                                            <h3 class="title">Empowerment</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->

                    

                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    