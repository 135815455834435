import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { WalkinItem } from 'src/app/models/walkincenter';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-generateyourbill',
  templateUrl: './generateyourbill.component.html',
  styleUrls: ['./generateyourbill.component.css']
})
export class GenerateyourbillComponent {
 alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'
  BillingData:any

  options:any[]=[]
  data:any
  UserForm!:FormGroup
  submitted:boolean = false
  isShow: boolean = false;
   isformShow: boolean = false;
    meter_img:any;
    meter_img_name:any;
    custData:any;

      //pdf parameter start

    title = 'ng-chart';
    chart: any = [];
     chart1: any = [];
   data1: any[] = []
  
    billData: any = []
    billData1: any = []
    public qrdata_tc: any = null;
    public qrdata_upi: any = null;
    public qrdata_paytm: any = null;
    date!: Date;
    mobile = '';
    email = '';
    dueAmt = '';
    openingCycle = '';
    closeCycle = '';
    dueDate = '';
    str5000 = '';
    important1 = '';
    previoseReading = '';
    assessedActual = '';
    previousDate = '';
    closingReading = '';
    closingActual = '';
    closingDate = '';
    billPeriod = '';
    details = '';
    flat = '';
    landmark = '';
    city = '';
    meterImgTxt = '';
    meterImg = '';
    historyDate1 = '';
    historyDate2 = '';
    historyDate3 = '';
    historyDate4 = '';
    historyDate5 = '';
    historyDate6 = '';
    historyIMG1 = true;
    historyIMG2 = true;
    historyIMG3 = true;
    historyIMG4 = true;
    historyIMG5 = true;
    historyIMG6 = true;
    walkinCenter: WalkinItem[] = [];
    walkincenterData = '';
    isLoad = false;
    str_arrears_emi = 0;
    mtrImg = '';
    mtrImgTxt = '';
    imageSrcString: any;
    totalPartA = 0;
    otherCharges = 0;
    v_creditbalance = 0;
    v_SGST = 0;
    v_CGST = 0;
    vPartB = 0;
    abTotal = 0;
  
    firstMessageFlag=false;
    secondMessageFlag=false;
     importantNew = '';
      importantNew1 = '';
  
      mychart='';
  
      uptoTotal = 0;
      uptodueAmt: any;

      //pdf parameter end

      pdfOpenFlag=false;
  constructor(private Service :DashboardServiceService,private pageLoaderService: PageLoaderService, private FormBuilder:FormBuilder,private router: Router,
    private UserService: UserRegistrationService) { }

  ngOnInit(): void {
this.UserService.checkLogin()
this.getSessionData();
    this.options=['Assesed reading','Bill not recieved since commencement of gas','Bill recieved on actual consumption but reading differs',
    'Flat generally not occupied','Not available at home during the office hours']

    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    
    this.UserForm=this.FormBuilder.group({
      Email:['',[Validators.required,Validators.email]],
      name:['',[Validators.required]],
      PreMeterReading:[''],
      MeterReadingWindow:[''],
      PreMeterReadingDate:[''],
      MeterFrom:['',[Validators.required]],
      MeterTo:['',[Validators.required]],
      Reading:[''],
      BPno:[''],
      CAno:[''],
      Reading1:['',[Validators.required]],
      Reading2:['',[Validators.required]],
      Reading3:['',[Validators.required]],
      Reading4:['',[Validators.required]],
      Reading5:['',[Validators.required]],
      Reading6:['',[Validators.required]],
      Reading7:['',[Validators.required]],
      Reading8:['',[Validators.required]],
    Phone: [''],
      Reason:['',[Validators.required]],
      Datess:['',[Validators.required]],
    RegName: [''],
      meter_img: ['', Validators.required],
    })
    
//setTimeout(() => {
      this.Service.getCurrentBill({CAno:JSON.parse(data).CaNo}).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response))
    if(data.Response){

        this.BillingData = data.details[0]
        this.UserForm.get('PreMeterReading')?.setValue(this.BillingData.closing)
        //this.UserForm.get('MeterReadingWindow')?.setValue(this.BillingData.n_mr_date1 +' to' + this.BillingData.n_mr_date2)
        this.UserForm.get('PreMeterReadingDate')?.setValue(this.BillingData.desp_date)
       // console.log(data)
    const temp1 = this.BillingData.n_mr_date1.split('/');
    const temp2 = this.BillingData.n_mr_date2.split('/');
    var date1 = new Date(temp1[2]+"-"+temp1[1]+"-"+temp1[0]);
    var date2 = new Date(temp2[2]+"-"+temp2[1]+"-"+temp2[0]);
    var date3 = new Date();
    date2.setDate(date2.getDate() + 1);
    this.isShow = date1.getTime() <= date3.getTime() && date2.getTime() >= date3.getTime();
    
    this.UserForm.get('MeterFrom')?.setValue(temp1[0]+"-"+temp1[1]+"-"+temp1[2])
    this.UserForm.get('MeterTo')?.setValue(temp2[0]+"-"+temp2[1]+"-"+temp2[2])
    this.isformShow = true;
    }else{
    this.isformShow = false;
    }
       


      })
   // }, 200);

    let data1 = sessionStorage.getItem('UserCredential')
    if (data1)
      this.data = JSON.parse(data1)

    //console.log('assldjsldj jlfjs jfjlk',this.data)

    this.UserForm.get('BPno')?.setValue(this.data.BpNo)
    this.UserForm.get('CAno')?.setValue(this.data.CaNo)
  this.UserForm.get('Phone')?.setValue(this.data.Mobile)
  this.UserForm.get('RegName')?.setValue(this.data.FirstName)

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

   getSessionData() {

    let data = sessionStorage.getItem('UserCredential')
    if (data)
      this.data = JSON.parse(data)
     this.UserService.getUserAddress({BPnoCAno:this.data.CaNo,BPno:''}).subscribe(response=>{
        let data2 = JSON.parse(JSON.stringify(response)).details[0]
        this.custData=data2

       
       
      })
   
  }

  reset(){
    this.options=[]
    this.UserForm.reset()
    this.submitted=false
    setTimeout(() => {
      this.options=['Assesed reading','Bill not recieved since commencement of gas','Bill recieved on actual consumption but reading differs',
    'Flat generally not occupied','Not available at home during the office hours']
      
    }, 200);
  }

   filechange(val: any,name:any) {
   
    if (name=='meterimg') {
       if(val.target.files[0].size/1024/1024>2){
          this.UserForm.get('meter_img')?.setValue('');
        return alert('Please select Image less than 2 MB ')
      }else{
         this.meter_img = val.target.files;
         this.meter_img_name = val.target.files[0];

      }
     
      
    }
 
  }

  submit(){

    this.submitted=true
    
    var GivenDate = this.UserForm.get('Datess')?.value;
    var CurrentDate = new Date();
    
    if(new Date(GivenDate) > CurrentDate){
      this.UserForm.get('Datess')?.reset()
    }

    this.UserForm.get('Reading')?.setValue(""+this.UserForm.get('Reading1')?.value + this.UserForm.get('Reading2')?.value +
    this.UserForm.get('Reading3')?.value + this.UserForm.get('Reading4')?.value + this.UserForm.get('Reading5')?.value +
    this.UserForm.get('Reading6')?.value + this.UserForm.get('Reading7')?.value + this.UserForm.get('Reading8')?.value)

    //console.log(this.UserForm.get('Reading')?.value)
    if(this.UserForm.invalid){
      return
    }

    let data = sessionStorage.getItem('UserCredential')
    if (data)
      this.data = JSON.parse(data)

    this.UserForm.get('BPno')?.setValue(this.data.BpNo)
    this.UserForm.get('CAno')?.setValue(this.data.CaNo)
  this.UserForm.get('Phone')?.setValue(this.data.Mobile)
  this.UserForm.get('RegName')?.setValue(this.data.FirstName)

  

      const date = new Date();
      // const start = new Date(this.UserForm.get('MeterFrom')?.value)
      // const end = new Date(this.UserForm.get('MeterTo')?.value);

      //  if (date > start && date < end) {
         var startDate = this.UserForm.get('MeterFrom')?.value.split("-");
     var toDate = this.UserForm.get('MeterTo')?.value.split("-");
   // console.log(startDate)
    var startDate1 =startDate[2]+"-"+startDate[1]+"-"+startDate[0]
    var toDate1 =toDate[2]+"-"+toDate[1]+"-"+toDate[0]

const start = new Date(startDate1)
const end = new Date(toDate1);

 // console.log(date)
 //  console.log(start)
 //   console.log(end)

   const form = new FormData()

    //console.log(' this.custData', this.custData.nextbilldate)
      let str = this.custData.nextbilldate;
str = str.substring(0, str.length - 5);
//console.log(str);

    if (this.meter_img_name != null) {
      let meterImg = this.meter_img[0].name.split('.').pop()
    let meterImg1  =this.meter_img[0].name.split('.').slice(0, -1).join('.') + this.data.BpNo + new Date() +'.'+ meterImg;
      form.append("MeterPhoto", this.meter_img[0], meterImg1)
    }



      form.append('Email', this.UserForm.get('Email')?.value)
      form.append('name', this.UserForm.get('name')?.value)
      form.append('PreMeterReading', this.UserForm.get('PreMeterReading')?.value)
      form.append('PreMeterReadingDate', this.UserForm.get('PreMeterReadingDate')?.value)
      form.append('MeterFrom', this.UserForm.get('MeterFrom')?.value)
      form.append('MeterTo', this.UserForm.get('MeterTo')?.value)
      form.append('Reading', this.UserForm.get('Reading')?.value)
      form.append('BPno', this.UserForm.get('BPno')?.value)
      form.append('CAno', this.UserForm.get('CAno')?.value)
      form.append('Phone', this.UserForm.get('Phone')?.value)
      form.append('Reason', this.UserForm.get('Reason')?.value)
      form.append('Datess', this.UserForm.get('Datess')?.value)
      form.append('RegName', this.UserForm.get('RegName')?.value)
      form.append('Meterreadingnote', this.BillingData.MR_Note)
      form.append('Mrdateforbilling', this.custData.Mrreason)
      form.append('Mridnumber', '000000000000'+this.custData.mrdocno)
      form.append('Mrreason', this.custData.Mrreason)
      form.append('Mrtimeforbilling', this.custData.Mrreason)
      form.append('SmrDate', str)

      

    

    // if (date >= start && date <= end) {
       this.Service.generateSelfbill(form).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      if(data.Response){
         this.billData1 = data.details;
          this.pdfOpenFlag=true;
        //this.pdfCreate();
        this.alertType='submit'
        this.alertMsg = data.message
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {
          this.alertType=''
          //this.UserForm.reset()
          this.submitted=false
        }, 1000);
      }
    })
     // }else{

     //    this.alertType='windowsubmit'
     //    this.alertMsg = "PLEASE CHECK METER READING WINDOW"
     //    this.alertColor = 'rgba(4, 183, 107, 0.2)'
     //    this.alertTextColor = '#038b51'
     //    this.alertBorder = '1px solid #04a862;'

     //    setTimeout(() => {
     //     this.alertType='';
     //    }, 5000);


     //   }

   
  }

 pdfCreate(){

   this.qrdata_tc = "https://chart.googleapis.com/chart?chs=180x180&cht=qr&chl=" + encodeURIComponent("https://www.mahanagargas.com/assets/images/pdf/_regitration-form-terms-and-condition_0d27e650ee.pdf") + "&choe=UTF-8";
    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))

    this.Service.getWalkinCenter().subscribe(response => {
      this.walkinCenter = JSON.parse(JSON.stringify(response)).details
    })

    if (data) {
      this.data = JSON.parse(data).CaNo
     // console.log({ CAno: JSON.parse(data).CaNo })
      var txtMobile = JSON.parse(data).Mobile;
      var txtEmail = JSON.parse(data).Email;
      this.mobile = Array(6).join("*") + + txtMobile.slice(-4);
      this.email = txtEmail.slice(0, 2) + Array(8).join("*") + txtEmail.slice(-9);
        this.billData =  this.billData1
      // this.service.getCurrentBill({ CAno: JSON.parse(data).CaNo }).subscribe(response => {
      //   let data = JSON.parse(JSON.stringify(response)).details
      //   //console.log(response)
      //   if (this.DashboardService.billno == undefined) {
      //     this.billData = data[0]
      //   } else {
      //     this.billData = data.filter((x: any) => {
      //       return x.bill_no == this.DashboardService.billno
      //     })
      //     this.billData = this.billData[0]
      //   }
      //   if (this.billData.MR_Note == "99") {
      //     this.service.getBillImage({ date: this.billData.MR_Path }).subscribe(response => {
      //       let data = JSON.parse(JSON.stringify(response))
      //       if (data.message) {
      //         this.meterImg = 'https://www.mahanagargas.com:3000' + data.message;
      //         console.log('meter img',this.meterImg);
      //         this.isLoad = true;
      //          this.mtrImg = `<td width="51%" align="center" valign="top" style="font-size:9px;" >Meter Image : <br><img src="${this.meterImg}" width="120px" height="70px"></td>`
      //         // this.mtrImg = `<img src="${this.meterImg}" width="100">`
      //       }
      //     })
      //   }else {
      //     this.isLoad = true;
      //   }


        //**********New Change********//

        if(this.billData.EBILL=="Y" && this.billData.cnt_bill > "1"){
           this.importantNew = "Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill";
   
          //console.log("For existing customers (who have opted for paperless billing earlier ):“Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill”")
          this.firstMessageFlag=true;
           this.secondMessageFlag=false;
        }else if (this.billData.EBILL=="Y" && this.billData.cnt_bill == "1"){
           this.importantNew1 = "We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill";
          //console.log("We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill")
          this.firstMessageFlag=false;
           this.secondMessageFlag=true;
        }
         

        //********New Change END*******//

        //this.billData = data[0]
       // console.log(this.billData)
        //this.walkincenterData = "Thane (West): 101 & 102, Landmark Arcade, Eastern Express Highway, Louiswadi, Thane-400604. Landmark: Near Nitin Company Junction Nearest Rail Head: Thane (Central Railway)"
        for (var i = 0; i < this.walkinCenter.length; i++) {
          if (this.walkinCenter[i].Portion == (this.billData.group).substring(0, 4)) {
            this.walkincenterData = this.walkinCenter[i].WalkinCenters
          }
        }
        if (this.billData.bill_date_history2) {
          this.historyIMG1 = true;
          this.historyDate1 = this.getBillInfo(this.billData.bill_date_history2) + " To " + this.getBillInfo(this.billData.bill_date_history1)+" "+this.getBillInfoYear(this.billData.bill_date_history1);
        } else {
          this.historyIMG1 = false;
        }
        if (this.billData.bill_date_history3) {
          this.historyIMG2 = true;
          this.historyDate2 = this.getBillInfo(this.billData.bill_date_history3) + " To " + this.getBillInfo(this.billData.bill_date_history2)+" "+this.getBillInfoYear(this.billData.bill_date_history2);
        } else {
          this.historyIMG2 = false;
        }
        if (this.billData.bill_date_history4) {
          this.historyIMG3 = true;
          this.historyDate3 = this.getBillInfo(this.billData.bill_date_history4) + " To " + this.getBillInfo(this.billData.bill_date_history3)+" "+this.getBillInfoYear(this.billData.bill_date_history3);
        } else {
          this.historyIMG3 = false;
        }
        if (this.billData.bill_date_history5) {
          this.historyIMG4 = true;
          this.historyDate4 = this.getBillInfo(this.billData.bill_date_history5) + " To " + this.getBillInfo(this.billData.bill_date_history4)+" "+this.getBillInfoYear(this.billData.bill_date_history4);
        } else {
          this.historyIMG4 = false;
        }
        if (this.billData.bill_date_history6) {
          this.historyIMG5 = true;
          this.historyDate5 = this.getBillInfo(this.billData.bill_date_history6) + " To " + this.getBillInfo(this.billData.bill_date_history5)+" "+this.getBillInfoYear(this.billData.bill_date_history5);
        } else {
          this.historyIMG5 = false;
        }
        if (this.billData.bill_date_history7) {
          this.historyIMG6 = true;
          this.historyDate6 = this.getBillInfo(this.billData.bill_date_history7) + " To " + this.getBillInfo(this.billData.bill_date_history6)+" "+this.getBillInfoYear(this.billData.bill_date_history6);
        } else {
          this.historyIMG6 = false;
        }

        // console.log(this.historyDate1)
        // console.log(this.historyDate2)
        // console.log(this.historyDate3)
        // console.log(this.historyDate4)
        // console.log(this.historyDate5)
        // console.log(this.historyDate6)

        if (this.billData.PLOT_NO != null && this.billData.PLOT_NO != "") {
          this.details += "Plot : " + this.billData.PLOT_NO + ", ";
        }

        if (this.billData.flat_no != null && this.billData.flat_no != "") {
          this.details += "Flat No : " + this.billData.flat_no + ", ";
        }

        if (this.billData.floor != null && this.billData.floor != "") {
          this.details += "Floor No : " + this.billData.floor + ", ";
        }

        if (this.billData.wing != null && this.billData.wing != "") {
          this.details += "Wing : " + this.billData.wing;
        }

        if (this.billData.bldg_name != null && this.billData.bldg_name != "") {
          this.flat += this.billData.bldg_name + ", ";
        }
        if (this.billData.city2 != null && this.billData.city2 != "") {
          this.flat += this.billData.city2 + ", ";
        }
        if (this.billData.street != null && this.billData.street != "") {
          this.flat += this.billData.street + ", ";
        }
        if (this.billData.colony != null && this.billData.colony != "") {
          this.flat += this.billData.colony + ", ";
        }
        if (this.billData.road_name != null && this.billData.road_name != "") {
          this.flat += this.billData.road_name + ", ";
        }

        if (this.billData.land_mark != null && this.billData.land_mark != "") {
          this.landmark = "LandMark : " + this.billData.land_mark + ", ";
        }

        if (this.billData.location != null && this.billData.location != "") {
          this.city += this.billData.location + ", ";
        }
        if (this.billData.city != null && this.billData.city != "") {
          this.city += this.billData.city + ", ";
        }
        if (this.billData.pin != null && this.billData.pin != "") {
          this.city += " - " + this.billData.pin;
        }

        if (!isNaN(this.billData.amt_pybl)) {
          this.dueAmt = Number(this.billData.amt_pybl) + 100 + ".00";
          this.dueDate = this.billData.due_date;
        }
        if (this.billData.ASSET_SD) {
          // this.str5000 = 'YOUR INTEREST FREE, REFUNDABLE SECURITY DEPOSIT TOWARDS LAST MILE CONNECTIVITY WITH US AS IMG IS ₹ ' + this.billData.ASSET_SD
          this.str5000 = 'Your interest free, refundable security deposit towards last mile connectivity wiht us as img is ₹ ' + this.billData.ASSET_SD
        } else {
          this.str5000 = '';
        }

        if (this.billData.bill_date_history1) {
          this.billPeriod = this.getBillInfo(this.billData.bill_date_history1) + " TO " + this.billData.bill_date;
        }
        else {
          this.billPeriod = this.billData.metdtf + " TO " + this.billData.bill_date;
        }


         const strdy_msg5 = this.billData.dy_msg5;
        const credit = this.billData.credit;
        const arrtot = this.billData.arrtot;
 
          if (strdy_msg5) {
          if (strdy_msg5 != "0" && Number(arrtot) > 0) {
            this.important1 = this.important1 + "<p>We thank you for paying ₹" + strdy_msg5.replace("Thank you for your payment of Rs.", "") + "/- against the PNG bill dated " + this.billData.bill_date_history1 + " and request you to make balance payment of ₹ " + arrtot + "/- for settling the full bill amount and avoid imposition of delayed payment charges and disconnection.</p>";
          }
        }

        if (strdy_msg5 && credit) {
          if (strdy_msg5 != "0" && Number(arrtot) == 0 && Number(credit) == 0) {
            this.important1 = this.important1 + "<p>We thank you for making full payment of ₹" + strdy_msg5.replace("Thank you for your payment of Rs.", "") + "/- against the PNG bill dated " + this.billData.bill_date_history1 + "</p>";
          }
        }



      


        if (this.billData.EBILL=="") {
            this.important1 = this.important1 + "<p>Opt for paperless Invoice by sending SMS to 922555557  “No Hard Copy”</p>";
          }

          if (!txtMobile && !txtEmail) {
          this.important1 = this.important1 + "<p>For registering your Mobile number or E-mail id, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>";
        }
        // else if (txtMobile && !txtEmail) {
        //   this.important1 = this.important1 + "<p>For registering your E-mail id, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>";
        // }

        this.important1 = this.important1 + "<p>We value your Privacy. If you wish to avoid visit of Meter reader inside your premises, please provide us your meter reading or generate your bill by "  + this.billData.n_mr_date4 +", for this purpose you will receive an SMS from MGL with a link between "  + this.billData.n_mr_date3 +" to "+ this.billData.n_mr_date4 +".  </p>";

 // else if (!txtMobile && txtEmail) {
        //   this.important1 = this.important1 + "<p>For registering your Mobile number, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>";
        // }

        const billDate = new Date(this.billData.bill_date);
        const dtNewBillDisply = new Date("27/11/2021");
        const dtNewBillDisply_01042022 = new Date("01/04/2022");

        if (billDate >= dtNewBillDisply)  //new format after 26/11/2021 
        {
          if (billDate >= dtNewBillDisply_01042022) //new format after 31/03/2022
          {
            //strInfo = strInfo + "* w.e.f. 1st April 2022 Vat is applicable @3%";
          }
          else {
            this.important1 = this.important1 + "<p>* w.e.f. 27th November 2021 Single Slab tariff is applicable</p>";
          }
        }
        const strClosing = this.billData.closing;
        if (strClosing) {
          if (strClosing.length >= 3) {
            if (strClosing.substring(strClosing.length - 3) == "(A)") {
              this.important1 = this.important1 + "<p>We have raised the current bill based on the Assessment of the consumption derived from the last six bills</p>";
            }
            else if (strClosing.substring(strClosing.length - 3) == "(E)") {
              this.important1 = this.important1 + "<p>We have raised the current bill based on the Assessment of the consumption derived from the last six bills. Please note that we could not obtain the reading when our Meter Reader visited your premises on " + this.billData.MR_Date + "</p>";
            }

            if (strClosing.substring(strClosing.length - 3) != "(E)" && strClosing.substring(strClosing.length - 3) != "(A)" && (this.billData.MR_Note == "99" || this.billData.MR_Note == "M8" || this.billData.MR_Note == "M15" || this.billData.MR_Note == "M12" || this.billData.MR_Note == "M2" || this.billData.MR_Note == "M3" || this.billData.MR_Note == "M10")) {
              this.important1 = this.important1 + "<p>We have raised the current bill based on the Reading obtained by us through visit by ";
              this.important1 = this.important1 + (this.billData.MR_Note == "99" ? "<b><u>Meter Reader</u></b>" : "Meter Reader");
              this.important1 = this.important1 + "/" + ((this.billData.MR_Note == "M8" || this.billData.MR_Note == "M15") ? "<b><u>provided by you via SMS</u></b>" : "provided by you via SMS");
              this.important1 = this.important1 + "/" + (this.billData.MR_Note == "M10" ? "<b><u>MGL connect</u></b>" : "MGL connect");
              this.important1 = this.important1 + "/" + ((this.billData.MR_Note == "M12" || this.billData.MR_Note == "M2" || this.billData.MR_Note == "M3") ? "<b><u>Mailed to customer Support ID of MGL.</u></b>" : "Mailed to customer Support ID of MGL." + "</p>");
            }
          }
        }

        const strdiscount = this.billData.discount;
        const stramt_pybl = this.billData.amt_pybl;
        if (strdiscount) {
          if (stramt_pybl == "ACH Operative" && Number(strdiscount) == -50) {
            this.important1 = this.important1 + "<p>We have received your request for NACH and the same has been activated. As per the current scheme, a sum of ₹ " + (Number(strdiscount) * -1) + "/- is credited and is shown as part of this item.</p>";
          }

          //if (stramt_pybl == "ACH Operative" && (Number(strdiscount) < 0 && Number(strdiscount) > -50))
          if (stramt_pybl != "" && (Number(strdiscount) < 0 && Number(strdiscount) > -50)) {
            if(this.billData.cnt_bill == " " || this.billData.cnt_bill == null){
               this.important1 = this.important1 + "<p>1% discount Rs ₹ " + (Number(strdiscount) * -1) + "/- against your previous bill settled through NACH has been credited to your account and is shown here.</p>";
            }
           
          }
        }



        var str_EMI_ARR = 0;
        var v_adjustaddarrears = 0;
        var v_adjustaddcredit = 0;
        this.v_creditbalance = 0;
        if (this.billData.EMI_ARR.length > 0) {
          str_EMI_ARR = Number(this.billData.EMI_ARR);
        }
        if (this.billData.adjust.length > 0 && Number(this.billData.adjust) > 0) {
          v_adjustaddarrears = Number(this.billData.adjust);
        }
        else {
          v_adjustaddcredit = Number(this.billData.adjust)
        }
        var v_consump_based_sd = this.billData.consump_based_sd ? Number(this.billData.consump_based_sd) : 0;
        this.str_arrears_emi = Number(this.billData.arrtot) + Number(this.billData.EMI_OPEN) + Number(v_consump_based_sd);
        //this.str_arrears_emi = Number(this.billData.EMI_OPEN);
        this.v_creditbalance = Number(this.billData.credit) + v_adjustaddcredit + Number(this.billData.discount);
        var v_emiopen = this.billData.EMI_OPEN ? Number(this.billData.EMI_OPEN) : 0;
        var v11 = this.billData.cons ? Number(this.billData.cons) : 0;
       // console.log('cons',v11)
        var v12 = this.billData.vat ? Number(this.billData.vat) : 0;
        // console.log('vat',v12)
        var v13 = this.billData.arrtot ? Number(this.billData.arrtot) + str_EMI_ARR + v_adjustaddarrears + v_emiopen : 0;
        //console.log('v13',v13)
        var v_cf = this.billData.cf ? Number(this.billData.cf) : 0;
        var v_bf = this.billData.bf ? Number(this.billData.bf) : 0;

        this.totalPartA = v11 + v12 + v13 + this.v_creditbalance;




        var v_Asset_500 = this.billData.ASSET_500 ? Number(this.billData.ASSET_500) : 0;;
        var v_othercharges = Number(this.billData.CU_Charges) + v_Asset_500;

        //console.log('v_othercharges',v_othercharges)

        var v_asset_based_sd = this.billData.asset_based_sd ? Number(this.billData.asset_based_sd) : 0;

        var v_int_inst = this.billData.int_inst ? Number(this.billData.int_inst) : 0;
        var v_NAME_CHRG = this.billData.NAME_CHRG ? Number(this.billData.NAME_CHRG) : 0;
        var v_FIXCED_CHARGE = this.billData.FIXCED_CHARGE ? Number(this.billData.FIXCED_CHARGE) : 0;
        var v_ebill_amt = this.billData.ebill_amt ? Number(this.billData.ebill_amt) : 0;

        var dec_OtherCharges = Number(v_othercharges) + Number(v_asset_based_sd) + Number(v_consump_based_sd) + Number(v_int_inst) + Number(v_NAME_CHRG);
        this.otherCharges = dec_OtherCharges + Number(v_FIXCED_CHARGE) + Number(v_ebill_amt);
       
       // console.log('this.otherCharges,10',this.otherCharges,10)

        var str_sgst = 0;
        var str_cgst = 0;
        var dec_sgst = 0;
        var dec_cgst = 0;

        str_sgst = this.billData.SGST ? Number(this.billData.SGST) : 0;
        str_cgst = this.billData.CGST ? Number(this.billData.CGST) : 0;
        var v_int_inst_sgst = this.billData.int_inst_sgst ? (this.billData.int_inst_sgst) : 0;
        var v_int_inst_cgst = this.billData.int_inst_cgst ? (this.billData.int_inst_cgst) : 0;

        var v_NAME_SGST = this.billData.NAME_SGST ? Number(this.billData.NAME_SGST) : 0;
        var v_NAME_CGST = this.billData.NAME_CGST ? Number(this.billData.NAME_CGST) : 0;

        var v_ZFSGST = this.billData.ZFSGST ? Number(this.billData.ZFSGST) : 0;
        var v_ZFCGST = this.billData.ZFCGST ? Number(this.billData.ZFCGST) : 0;

        var v_ebill_amt_cgst = this.billData.ebill_amt_cgst ? Number(this.billData.ebill_amt_cgst) : 0;
        var v_ebill_amt_sgst = this.billData.ebill_amt_sgst ? Number(this.billData.ebill_amt_sgst) : 0;

        // console.log("int_inst_sgst", v_int_inst_sgst);
        // console.log("NAME_SGST", v_NAME_SGST);
        // console.log("ZFSGST", v_ZFSGST);
        // console.log("ebill_amt_cgst", v_ebill_amt_cgst);

        dec_sgst = Number(str_sgst) + Number(v_int_inst_sgst) + Number(v_NAME_SGST) + Number(v_ZFSGST) + Number(v_ebill_amt_cgst);
        dec_cgst = Number(str_cgst) + Number(v_int_inst_cgst) + Number(v_NAME_CGST) + Number(v_ZFCGST) + Number(v_ebill_amt_sgst);
        this.v_SGST = Number(dec_sgst);
        this.v_CGST = Number(dec_cgst);
        var v_min_charges = this.billData.min_chg ? Number(this.billData.min_chg) : 0;
        // console.log("this.otherCharges", this.otherCharges)
        // console.log("v_min_charges", v_min_charges)
        // console.log("dec_sgst", dec_sgst)
        // console.log("dec_cgst", dec_cgst)
        this.vPartB = Number(this.otherCharges) + Number(v_min_charges) + Number(dec_sgst) + Number(dec_cgst);
        this.abTotal = this.totalPartA + this.vPartB;
        const strecslimit = this.billData.ecslimit;

        //   console.log(" this.vPartB",  this.vPartB)
        // console.log("this.totalPartA", this.totalPartA)
        //  console.log("this.abTotal", this.abTotal)



        if (v_FIXCED_CHARGE > 0) {
          this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>Fixed Charge of ₹" + v_FIXCED_CHARGE.toFixed(2) + "/-    is included in Other Charges.</b></p>";
        }

        // if (v_ebill_amt > 0) {
        //   this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>Convenience charges of ₹" + v_ebill_amt.toFixed(2) + "/- plus GST is included in Other Charges w.e.f. 01.08.2023.</b></p>";
        // }

        if (v_Asset_500 > 0) {
          this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>Other Charges includes Balance payment on account of Last Mile Connectivity Deposit.</b></p>";
        }

        //if (this.billData.EBILL != 'Y') {
        //  this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>We invite you to join our \"Go Green Movement\" by opting for paperless PNG bill by sending an SMS \"No Hard Copy\" to 9223 55 5557. If you wish to receive paper Bill in future, the same shall be provided at ₹10/bill.</b></p>";
        //}
        if (strecslimit) {
          //if (stramt_pybl.toUpperCase() == "ACH OPERATIVE" && (vPartA + vPartB) > Number(strecslimit) && EMI_ARR == "0")
          if (this.billData.ACH_Flg == "N" && (this.totalPartA + this.vPartB) > Number(strecslimit)) {
            var nachAmt= this.totalPartA + this.vPartB;
           // console.log(nachAmt)
             var nachAmt1=((this.totalPartA + this.vPartB) - Number(strecslimit))
            this.important1 = this.important1 + "<p>Your NACH mandate is for ₹ " + strecslimit + " and your current bill value is Amount ₹ " + nachAmt.toFixed(2) + "/- You are requested to make balance payment of ₹ " + nachAmt1.toFixed(2) + "/- by any of the prescribed modes within the due date.</p>";
          }
        }

        if (this.billData.arrdpc) {
          if (Number(this.billData.arrdpc) > 0) {
            this.important1 = this.important1 + "<p>Delayed Payment Charges and Late Payment Charges are levied due to delayed payment against your past bills and total of the same amounting to ₹ " + this.billData.arrdpc + "/- has been included under the Arrears amount.</p>";
          }
        }

        if (this.billData.intnetpay.toLowerCase() == "shubh labh") {
          this.important1 = this.important1 + "<p>Discount eligible under the Shub Labh Scheme is shown and adjusted against your gas dues in this bill.";
        }

        if (this.billData.CU_Charges) {
          if (Number(this.billData.CU_Charges) > 0) {
            this.important1 = this.important1 + "<p>Other charges in the current bill represent charges levied for Extra Copper Pipe/ Rubber Hose used in your residence as per MGL's Policy.</p>";
          }
        }

       


        //const billDate = new Date(this.billData.bill_date);
        const dtEMI_OPEN_From = new Date('01/05/2022');
        const dtEMI_OPEN_To = new Date('30/06/2022');
        if (billDate >= dtEMI_OPEN_From && billDate <= dtEMI_OPEN_To) {
          if (this.billData.EMI_OPEN.trim()) {
            if (Number(this.billData.EMI_OPEN) > 0) {
              //strInfo = "Additional Gas Consumption Security Deposit based on your consumption pattern in multiples of 100/- subject to a minimum of 750/- is charged & included as Arrears.";
              this.important1 = this.important1 + "<p>Additional Gas Consumption Security Deposit of ₹ " + this.billData.EMI_OPEN + " is charged & included as Arrears.</p>";
            }
          }
        }

        if (Number(this.billData.EMI_OPEN) > 0) {
          //strInfo = "Additional Gas Consumption Security Deposit based on your consumption pattern in multiples of 100/- subject to a minimum of 750/- is charged & included as Arrears.";
          this.important1 = this.important1 + "<p>Additional Gas Consumption Security Deposit of ₹ " + this.billData.EMI_OPEN + " is charged & included as Arrears.</p>";
        }

        if (this.billData.NAME_CHRG.trim()) {
          if (Number(this.billData.NAME_CHRG) > 0) {
            this.important1 = this.important1 + "<p>If you have requested for name transfer @ Rs. 350 /- is charged & included in Other Charges.</p>";
          }
        }

        

        var opening = this.billData.opening;
        if (opening) {
          if (opening.length >= 3) {

            if (opening.substring(opening.length - 3) == "(E)" || opening.substring(opening.length - 3) == "(A)") {
              this.previoseReading = opening.substring(0, opening.length - 3); //ds.Tables[0].Rows[0]["opening"].ToString().trim();
              this.assessedActual = "Assessed";
            }
            else {
              this.previoseReading = opening;
              this.assessedActual = "Actual";
            }
          }
          else {
            this.previoseReading = opening;
            this.assessedActual = "Actual";
          }
        }

        if (this.billData.p_mrdate != "00/00/0000" && this.billData.p_mrdate.trim() != "") {
          this.previousDate = this.billData.p_mrdate.trim();
        }
        else {
          this.previousDate = this.billData.metdtf.trim();
        }

        var closing = this.billData.closing.trim();
        if (closing) {
          if (closing.length >= 3) {
            if (closing.substring(closing.length - 3) == "(E)" || closing.substring(closing.length - 3) == "(A)") {
              this.closingReading = closing.substring(0, closing.length - 3); //ds.Tables[0].Rows[0]["closing"].ToString().trim();
              this.closingActual = "Assessed";
            }
            else {
              this.closingReading = closing;
              this.closingActual = "Actual";
            }
          }
          else {
            this.closingReading = closing;
            this.closingActual = "Actual";
          }
        }

        if (this.billData.MR_Note.trim() == "99") {
          this.closingDate = this.billData.MR_Date;
        }
        else {
          this.closingDate = this.billData.bill_date;
        }

        if (!this.billData.MR_Note) {
          this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"><tbody><tr>";
          this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"left\" valign=\"top\" style=\"font-size:9px;\">";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">As per the company policy, Bills are based on assessed basis. Hence, No photo image Printed</p>";
          this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "<td align=\"center\" valign=\"middle\" style=\"font-size:9px\">Tentative next meter reading date <br/> " + this.billData.n_mr_date + "</td>";
          this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }
        else if (this.billData.MR_Note == "0" || this.billData.MR_Note == "00" || this.billData.MR_Note == "17" || this.billData.MR_Note == "55" || this.billData.MR_Note == "66") {
          this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"><tbody><tr>";
          this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"center\" valign=\"top\" style=\"font-size:9px;\">";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">READER VISIT DATE <br/>" + this.billData.MR_Date + "</p>";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">TIME <br/> " + this.billData.MRTIME + "</p>";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">REMARK <br/> " + this.billData.MR_TEXT + "</p>";
          this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "<td align=\"center\" valign=\"middle\" style=\"font-size:9px\">Tentative next meter reading date <br/> " + this.billData.n_mr_date + "</td>";
          this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }
        else if (this.billData.MR_Note.trim() == "99") {
          const strMrDate = this.billData.MR_Date.replace("/", "_");
          const strPartFileName = this.billData.MR_DOC + "_" + this.billData.partner_no;
          const invoice = this.billData.TAX_INVNO.split('/');

          //this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\" style=\"border: none;\"><tbody><tr>";
          //this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"left\" valign=\"top\" style=\"font-size:9px;\">";

          //this.meterImgTxt = this.meterImgTxt + GetBillingMrText(this.billData.MR_Note);

          //this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "Tentative next meter reading date <br/> " + this.billData.n_mr_date;
          //this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }
        else {
          this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"><tbody><tr>";
          this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"left\" valign=\"top\" style=\"font-size:9px;\">";

          //this.meterImgTxt = this.meterImgTxt + GetBillingMrText(this.billData.MR_Note);

          this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "<td align=\"center\" valign=\"middle\" style=\"font-size:9px\">Tentative next meter reading date <br/> " + this.billData.n_mr_date + "</td>";
          this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }

        if (this.meterImgTxt) {
          this.mtrImgTxt = `<td width="50%" align="center" valign="top" style="font-size:9px;" [innerHTML]="meterImgTxt"></td>`
        }
        //} else {
        //  this.billData = data.filter((x: any) => {
        //    return x.bill_no == this.DashboardService.billno
        //  })
        //  this.billData = this.billData[0]
        //}
        // var pyblAmt = this.billData.amt_pybl;
         var pyblAmt = this.uptoTotal;
        if (!isNaN(this.billData.amt_pybl)) {
          var temp = this.billData.due_date.split("/")

          var date1 = new Date(temp[2] + "-" + temp[1] + "-" + temp[0]).getTime();
          var date2 = new Date().getTime();
          //alert(date2+ "   " + date1)
          if (date2 > date1) {

            // pyblAmt = this.dueAmt;
            pyblAmt = this.uptodueAmt;
          }
        }

        const upi = "upi://pay?pa=mahagasupi" + this.billData.contract_acc + "@hsbc&pn=MahanagarGasLtd&mc=4900&tid=&tr=" + this.billData.bill_no + "&tn=" + this.billData.bill_no + "&am=" + pyblAmt + "&mam=null&cu=INR";
        this.qrdata_upi = "https://chart.googleapis.com/chart?chs=180x180&cht=qr&chl=" + encodeURIComponent(upi) + "&choe=UTF-8";
        const paytm = "paytmmp://utility?url=https://catalog.paytm.com/v1/mobile/getproductlist/78640?group=brand$title=gas$board=Mahanagar Gas- Mumbai$recharge_number=" + this.billData.contract_acc;
        this.qrdata_paytm = "https://chart.googleapis.com/chart?chs=180x180&cht=qr&chl=" + encodeURIComponent(paytm) + "&choe=UTF-8";


      //  console.log(this.billData.bill_date_history2)
        //var mydate = this.billData.bill_date_history2
        //var dateParts = mydate.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        //var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 


        //console.log(this.billData)

         if (stramt_pybl.toUpperCase() == "CREDIT BILL" || stramt_pybl.toUpperCase() == "CREDIT BIL") {
          // console.log('this.totalPartA',this.totalPartA)
          //   console.log('this.vPartB',this.vPartB)
          var addtionImp = Number(this.totalPartA) + Number(this.vPartB);
            var addtionImp1 = addtionImp * -1;
         // console.log('addtionImp1',addtionImp1)
          this.important1 = this.important1 + "<p>This is a credit bill as past payment / credit is ₹ " + addtionImp1.toFixed(2) + "/- more than the amount due and the same shall be adjusted in future bills.</p>";
        }
    



       if (!isNaN(this.billData.amt_pybl)) {
          this.uptoTotal = Number(this.billData.amt_pybl) + Number(this.billData.EMI_ARR);
         // console.log('this.uptoTotal',this.uptoTotal)
        }else{
          this.uptoTotal = this.billData.amt_pybl
        }
       
       

        if (!isNaN(this.billData.amt_pybl)) {
          this.uptodueAmt = this.uptoTotal + 100;
        }

        

       // this.loadChart()

        // this.billData.bill_date_history2 = dateObject
      //})
    }

 }

 printPdf(){
  
 }

  getBillInfo(strDate: any) {
    if (strDate) {
      //console.log(strDate)
      const temp: string[] = strDate.split("/")
      // console.log('temp',temp)
      const date = new Date();
      date.setDate(Number(temp[0]));
      date.setMonth(Number(temp[1]) - 1);
      date.setFullYear(Number(temp[2]));

      const date1 = new Date(Number(temp[2]), Number(temp[1]) - 1, Number(temp[0]));  // 2009-11-10
const month = date1.toLocaleString('default', { month: 'short' });
//console.log(month);

      // return this.datepipe.transform(new Date(date.setDate(date.getDate() + 1)), 'dd/MM/yyyy')
       return month
    } else {
      return ''
    }

  }

  getBillInfoYear(strDate: any) {
    if (strDate) {
     // console.log(strDate)
      const tempYear: string[] = strDate.split("/")
       return Number(tempYear[2])
    } else {
      return ''
    }

  }

}

