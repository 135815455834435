import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { TenderService } from 'src/app/Service/tenders/tender.service';
import { TenderResp } from 'src/app/models/tender';

@Component({
  selector: 'app-tender',
  templateUrl: './tender.component.html',
  styleUrls: ['./tender.component.css']
})
export class TenderComponent {
  tenderDate: TenderResp[] = []

	  constructor(private pageLoaderService: PageLoaderService, private service: TenderService,) { }

	  ngOnInit(): void {
		this.pageLoaderService.show()
		setTimeout(() => {
		  this.pageLoaderService.hide()
		}, 3000);
		this.getTender();
	  }

	  ngAfterViewInit(): void {
		(<any>window).twttr.widgets.load();
	  }

	  getTender() {
		this.service.getOldTenders().subscribe((res: any) => {
		  this.tenderDate = res
		  //console.log(this.tenderDate)
		})
	  }
	}



