<!--Full width header End-->
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>CNG Kit Supplier </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Customer Zone </a></li>
                <li class="active" aria-current="page">CNG Kit Supplier</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <app-recent-post></app-recent-post>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <h5 class="mb-30">
                    <span>List of RTO Approved CNG Kit Distributors in Mumbai, Navi Mumbai & Thane</span>
                </h5>
                <div class="light-blue-bg mt-20 cng-kit-suppiler">
                    <div class="rs-faq applications-natural-gas">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link" href="#collapseOne" data-toggle="collapse"
                                            aria-expanded="true"><i class="demo-icon uil-location-pin-alt"></i> GRANT
                                            ROAD TO DADAR</a>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <div class="blue-tick-list">
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>CNG RETROFITMENT WORKSHOP</th>
                                                                        <th>CONTACT PERSON</th>
                                                                        <th>CONTACT DETAILS/EMAIL ID</th>
                                                                        <th>VEHICLE TYPE</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>PRATHMESH AUTO GAS
                                                                            Sewree (W)</td>
                                                                        <td>Mr. Loke
                                                                        </td>
                                                                        <td>9820370756</td>
                                                                        <td>CAR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>KUSAR AUTO SERVICE
                                                                            Mumbai Central</td>
                                                                        <td>Mr. Ibriham
                                                                        </td>
                                                                        <td>9920490671</td>
                                                                        <td>CAR</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapseTwo" data-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> MAHIM TO ANDHERI
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>BINDRA TRADING COMPANY
                                                                    Andheri (E)</td>
                                                                <td>Mr. Dharampalsingh Bindra
                                                                </td>
                                                                <td>9892294083
                                                                    sales@bindracng.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SAGA AUTO COMPANY
                                                                    Andheri</td>
                                                                <td>Mr. Anil
                                                                </td>
                                                                <td>9820044689
                                                                    shagaautoco@yahoo.com
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RAJU AUTO GARAGE
                                                                    Vile Parle (E)</td>
                                                                <td>Mr. Raju
                                                                </td>
                                                                <td>9322283357
                                                                    rajuautolpg@gmail.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>GURU NANAK GAS AGENCY
                                                                    Andheri (E)</td>
                                                                <td>Mr. Puneet
                                                                </td>
                                                                <td>9820092593</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>CHEMOTECH FABCON PVT LTD
                                                                    Andheri (E)</td>
                                                                <td>Mr. Amit
                                                                </td>
                                                                <td>9892546979</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SURAJ AUTO
                                                                    Bandra (E)</td>
                                                                <td>Mr. Arun Jain
                                                                </td>
                                                                <td>975062 50019
                                                                    cng.lpg@surajautogas.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>XPRESS PIT STOP
                                                                    Andheri (W)</td>
                                                                <td>Mr. Rahul Saraf
                                                                </td>
                                                                <td>9819995114</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">

                                        <a class="card-link collapsed" href="#collapseThree" data-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> JOGESWARI TO MALAD
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>BALAJI AUTO GARAGE
                                                                    Goregaon</td>
                                                                <td>Mr. Arif
                                                                </td>
                                                                <td>97695323483
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SAGA AUTO COMPANY
                                                                    Andheri</td>
                                                                <td>Mr. Chandrashekharan

                                                                </td>
                                                                <td>9820044689
                                                                    shagaautoco@yahoo.com
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SUMAN ENTERPRISE
                                                                    Goregaon (W)</td>
                                                                <td>Mr. Gupta

                                                                </td>
                                                                <td>9324647440
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ECO GAS SYSTEM
                                                                    Jogeswari</td>
                                                                <td>Mr. Puneet</td>
                                                                <td>9768012300</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SUPER GAS POINT
                                                                    Jogeswari)</td>
                                                                <td>Mr. Ashraf Bhai
                                                                </td>
                                                                <td>9892546979</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>MANIX MOTORS
                                                                    Malad (E) </td>
                                                                <td>Mr. Manish Pandey
                                                                </td>
                                                                <td>9892309024
                                                                    manixmotorgas@yahoo.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ALARAM MOTORS
                                                                    Malad (W)</td>
                                                                <td>Mr. Mitesh
                                                                </td>
                                                                <td>9867711330</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsefour" data-toggle="collapse"
                                            data-bs-target="#collapsefour" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> KANDIVALI TO DAHISAR


                                        </a>
                                    </div>
                                    <div id="collapsefour" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>GREEN GLOBE FUEL SOLUTIONS,
                                                                    Kandivalin</td>
                                                                <td>Mr. Uday
                                                                </td>
                                                                <td>7718801128
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>AMOGSIDHA AUTO
                                                                    Kandivali</td>
                                                                <td>Mr. Yadav

                                                                </td>
                                                                <td>9869634689
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>XPRESS PIT STOP
                                                                    Kandivali</td>
                                                                <td>Mr. Rahul Saraf

                                                                </td>
                                                                <td>9819995114
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>AUTO CONCEPT
                                                                    Borivali</td>
                                                                <td>Mr. Vinay shah</td>
                                                                <td>98200 67826</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ECO GREEN
                                                                    Kandivali (W)</td>
                                                                <td>Mr. Shailesh Ghelani
                                                                </td>
                                                                <td>9324261284
                                                                    ecogreen007@gmail.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SHANTI GREEN WHEEL
                                                                    Borivali (W) </td>
                                                                <td>Mr. Ravi Jaiswal
                                                                </td>
                                                                <td>9820222611
                                                                    shanticng@gmail.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ASAMOHA FUEL TECH
                                                                    Borivali (W)</td>
                                                                <td>Mr. Rajesh Nair
                                                                </td>
                                                                <td>9702806938
                                                                    samohafuels@gmail.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ZERO POLLUTION
                                                                    Kandivali (W)</td>
                                                                <td>Mr. Yogesh Panchal
                                                                </td>
                                                                <td>9892414080
                                                                    helpdesk@zeropollutions.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SHREE SAINATH AUTO GAS SYSTEM
                                                                    Borivali</td>
                                                                <td>Mr. Amit
                                                                </td>
                                                                <td>9004090161
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SANDEEP CNG KIT FITTING CENTER
                                                                    Kandivali (E)</td>
                                                                <td>Mr. Sandeep Shivgan
                                                                </td>
                                                                <td>9833090270
                                                                    sandeep_cng@yahoo.co.in</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SHREEJI ENTERPRISES,
                                                                    Kandivali (W)</td>
                                                                <td>Mr. Devesh Bhatia
                                                                </td>
                                                                <td>9867936398</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsefive" data-toggle="collapse"
                                            data-bs-target="#collapsefive" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> MIRA ROAD </a>
                                    </div>
                                    <div id="collapsefive" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>MARS FUEL IGNITION
                                                                    Mira Road</td>
                                                                <td>Mr. Amit
                                                                </td>
                                                                <td>9324370433</td>
                                                                <td>CAR+LCV</td>
                                                            </tr>
                                                            <tr>
                                                                <td>MS MOTORS
                                                                    Mira Road</td>
                                                                <td>Mr. Mossni
                                                                </td>
                                                                <td>9768584319</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ECO GREEN
                                                                    Mira-Bhayender</td>
                                                                <td>Mr. Mossni
                                                                </td>
                                                                <td>Mrs. Vaishali Ghelani</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsesix" data-toggle="collapse"
                                            data-bs-target="#collapsesix" aria-expanded="false"> <i
                                                class="demo-icon uil-location-pin-alt"></i> WADALA-SION-KURLA
                                        </a>
                                    </div>
                                    <div id="collapsesix" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>DASHMESH CNG INPEX PVT. LTD.
                                                                    Wadala</td>
                                                                <td>Mr. Tarlok
                                                                </td>
                                                                <td>8976017060
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>SA.K.MOTORS
                                                                    Wadala</td>
                                                                <td>Mr. Chotu bhai

                                                                </td>
                                                                <td>9821236661

                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RELIABLE AUTO GAS AGENCY
                                                                    Kurla (W)</td>
                                                                <td>Mr. Prakash

                                                                </td>
                                                                <td>9821574454
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>KHATRI AUTO CNG WORKS
                                                                    SION</td>
                                                                <td>MR. YASHWANT</td>
                                                                <td>9820900806</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>MUMBAI AUTO GAS
                                                                    Sion</td>
                                                                <td>Mr. Gurjit
                                                                </td>
                                                                <td>9821198414</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>MKWEALITY GAS CENTRE
                                                                    Sion </td>
                                                                <td>Mr. Micky
                                                                </td>
                                                                <td>9819960350
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>KBN AUTO GAS
                                                                    Wadala</td>
                                                                <td>Mr. Mitesh
                                                                </td>
                                                                <td>Mr. Arif</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapseseven" data-toggle="collapse"
                                            data-bs-target="#collapseseven" aria-expanded="false"> <i
                                                class="demo-icon uil-location-pin-alt"></i> GHATKOPAR TO MULUND
                                        </a>
                                    </div>
                                    <div id="collapseseven" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>BLUE DRIVE TRADERS
                                                                    Ghatkopar (W)</td>
                                                                <td>Mr. Harshal Shah
                                                                </td>
                                                                <td>25140065/9867160345
                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>GREENGLOBE FUEL SOLUTIONS
                                                                    Mulund (W)</td>
                                                                <td>Mr. Ashish Chheda

                                                                </td>
                                                                <td>25677775
                                                                    9819749360/ 370

                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>CHUNILAL AUTO WORKS
                                                                    Bhandup)</td>
                                                                <td>Mr. Jeetu Bhai

                                                                </td>
                                                                <td>9322809968

                                                                </td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>KHATRI AUTO CNG WORKS
                                                                    SION</td>
                                                                <td>MR. YASHWANT</td>
                                                                <td>9820900806</td>
                                                                <td>LCV</td>
                                                            </tr>
                                                            <tr>
                                                                <td>OM CAR AUTO SERVICE
                                                                    Ghatkopar</td>
                                                                <td>Mr. Uttam
                                                                </td>
                                                                <td>9322228368</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>LOTUS AUTO GAS
                                                                    Powai </td>
                                                                <td>Mr. Solanki
                                                                </td>
                                                                <td>9321805050
                                                                </td>
                                                                <td>LCV</td>
                                                            </tr>
                                                            <tr>
                                                                <td>JAI AMBE ENTERPRISES
                                                                    Mulund </td>
                                                                <td>Mr. Nilesh
                                                                </td>
                                                                <td>9892184007</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>GOLDEN AUTO WORKS
                                                                    Mulund </td>
                                                                <td>Mr. Baawaa
                                                                </td>
                                                                <td>9769252727</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>GREEN INDIA FUEL
                                                                    Ghatkopar </td>
                                                                <td>Mr. Salaudin
                                                                </td>
                                                                <td>9702972062</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>INDIA AUTO ENGG
                                                                    Vikhroli </td>
                                                                <td>Mr. Akhil
                                                                </td>
                                                                <td>9819379870</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>GREEN PLANET
                                                                    Powai </td>
                                                                <td>Sachin Pawar
                                                                </td>
                                                                <td>9769343044 / 0702949894
                                                                    solbizserve@gmail.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapseeight" data-toggle="collapse"
                                            data-bs-target="#collapseeight" aria-expanded="false"> <i
                                                class="demo-icon uil-location-pin-alt"></i> THANE
                                        </a>
                                    </div>
                                    <div id="collapseeight" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>CNG RETROFITMENT WORKSHOP</th>
                                                            <th>CONTACT PERSON</th>
                                                            <th>CONTACT DETAILS/EMAIL ID</th>
                                                            <th>VEHICLE TYPE</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>ECO FUEL SYSTEM
                                                                Thane</td>
                                                            <td>Mr. Harshal
                                                            </td>
                                                            <td>9867160345
                                                                lovatomumbai@yahoo.com</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>BALAJI ENTERPRISES
                                                                Thane</td>
                                                            <td>Mr. S Y Reddy
                                                            </td>
                                                            <td>9323456120</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>MANIX MOTORS
                                                                Thane</td>
                                                            <td>Mr. Manish Pandey
                                                            </td>
                                                            <td>9892309024</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AUTO MAX
                                                                Thane</td>
                                                            <td>Mr. Alinaqi
                                                            </td>
                                                            <td>9768112786</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>THANE AUTO SERVICE
                                                                Thane</td>
                                                            <td>Mr. Sumit Londhe
                                                            </td>
                                                            <td>9773212777</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>9773212777</td>
                                                            <td>Customer Care
                                                            </td>
                                                            <td>7506264848,
                                                                022-65243253</td>
                                                            <td>LCV/CAR
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwenty" data-toggle="collapse"
                                            data-bs-target="#collapsetwenty" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> NAVI MUMBAI
                                        </a>
                                    </div>
                                    <div id="collapsetwenty" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>CNG RETROFITMENT WORKSHOP</th>
                                                            <th>CONTACT PERSON</th>
                                                            <th>CONTACT DETAILS/EMAIL ID</th>
                                                            <th>VEHICLE TYPE</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>SHIVAM CAR SOLUTIONS
                                                                Koparkhairne</td>
                                                            <td>Mr. Kamal Panchal
                                                            </td>
                                                            <td>98216 28308</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>GREEN FUEL SOLUTION
                                                                Turbhe</td>
                                                            <td>Mr. Dhingra
                                                            </td>
                                                            <td>Mr. Dhingra</td>
                                                            <td>CAR+LCV</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FORTUNE DISTRIBUTORS
                                                                Koparkhairne</td>
                                                            <td>Mr. Pankaj
                                                            </td>
                                                            <td>9326015255</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>KOHLI MOTORS
                                                                Vashi</td>
                                                            <td>KOHLI MOTORS
                                                                Vashi
                                                            </td>
                                                            <td>9867672382</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TRINITY AUTO
                                                                Nerul</td>
                                                            <td>Mr. Poojari/Rajesh
                                                            </td>
                                                            <td>9892530115</td>
                                                            <td>CAR</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetewntyone" data-toggle="collapse"
                                            data-bs-target="#collapsetewntyone" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> KHARGHAR PANVEL-TALOJA

                                        </a>
                                    </div>
                                    <div id="collapsetewntyone" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>CNG RETROFITMENT WORKSHOP</th>
                                                                <th>CONTACT PERSON</th>
                                                                <th>CONTACT DETAILS/EMAIL ID</th>
                                                                <th>VEHICLE TYPE</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>PATIL CNG
                                                                </td>
                                                                <td>Mr. Pritam Patil

                                                                </td>
                                                                <td>98673 96782 / 72085 79505</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ECO TECH
                                                                    Kharghar</td>
                                                                <td>Mr. Mitt Mehta
                                                                </td>
                                                                <td>98191 11067</td>
                                                                <td>CAR+LCV</td>
                                                            </tr>
                                                            <tr>
                                                                <td>FORTUNE DISTRIBUTORS
                                                                    Koparkhairne</td>
                                                                <td>Mr. Pankaj
                                                                </td>
                                                                <td>9326015255</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IDEAL CNG
                                                                    Panvel </td>
                                                                <td>Mr. Anoop Alreja
                                                                </td>
                                                                <td>9321188999
                                                                    cngfortunekharghar@gmail.com</td>
                                                                <td>CAR</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ECO DRIVE CNG WORKS
                                                                    Kharghar</td>
                                                                <td>Mr. Santosh Thakkar
                                                                </td>
                                                                <td>9833161076</td>
                                                                <td>CAR</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwentytwo" data-toggle="collapse"
                                            data-bs-target="#collapsetwentytwo" aria-expanded="false"><i
                                                class="demo-icon uil-location-pin-alt"></i> KALYAN TO AMBERNATH
                                        </a>
                                    </div>
                                    <div id="collapsetwentytwo" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>CNG RETROFITMENT WORKSHOP</th>
                                                            <th>CONTACT PERSON</th>
                                                            <th>CONTACT DETAILS/EMAIL ID</th>
                                                            <th>VEHICLE TYPE</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>JAI BHOLE NATH
                                                                Sahad Station, Sahad, Ulhasnagar</td>
                                                            <td>Mr. Hitesh Talreja
                                                            </td>
                                                            <td>9890313616/ 9322176500/ 9867607066</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ICON FUEL SOLUTION
                                                                Ram Patil Compound, Pimpleshwar Point, Rameshwar CNG
                                                                Pump, Kalyan Shil Road, Dombivili East</td>
                                                            <td>Mr. Kamlesh Wagh
                                                            </td>
                                                            <td>9967712330</td>
                                                            <td>CAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td>BAJRANG AUTO
                                                                Pimpleshwar Point, Rameshwar CNG Pump, Kalyan Shil Rd,
                                                                Dombivili East
                                                            </td>
                                                            <td>Mr. Narendra Bhai Lovato

                                                            </td>
                                                            <td>9324504298
                                                            </td>
                                                            <td>CAR</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Inner Page Content Section End -->





<!-- Main content End -->