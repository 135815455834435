import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-listofmglcngstations',
  templateUrl: './listofmglcngstations.component.html',
  styleUrls: ['./listofmglcngstations.component.css']
})
export class ListofmglcngstationsComponent {
  listofArray:any;
 constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    
      this.listofArray = [
{'id':'1','Name_of_CNG_RO':'Shree Kedarnath Petroleum','address':'Dahisar - Waklan Rd, Dahisar, Adivali,','area':'ADIVALI'},
{'id':'2','Name_of_CNG_RO':'City Gate Station, Ambernath','address':'CNG Pump, Kalyan - Badlapur Rd, Ladinaka, Dattanagar, Ambernath, Maharashtra 421505','area':'AMBERNATH'},
{'id':'3','Name_of_CNG_RO':'MGL Agent -Green Energy Station','address':'S. No. 41 (p), Plot No. C-2, Village Morivali, Ambernath Badlapur road, Taluka Ambernath, Dist - Thane','area':'AMBERNATH'},
{'id':'4','Name_of_CNG_RO':'Royal Service Station, Andheri (E)','address':'M/s Royal Service Station ,Near Hotel Leela,Andheri (E),Mumbai – 400 059','area':'ANDHERI'},
{'id':'5','Name_of_CNG_RO':'Gurunanak Auto, Andheri (E)','address':'M/s Guru Nanak Auto ,82 – Andheri Kurla Road,Andheri (E)Mumbai – 400 093','area':'ANDHERI'},
{'id':'6','Name_of_CNG_RO':'Servo Corner (IOCL)/(COCO Sahar)','address':'Ccoc Sahra CTS NO 145 ,Servo corner ,Cargo complex ,Andheri  ( e ) 400069','area':'ANDHERI'},
{'id':'7','Name_of_CNG_RO':'Trimurti Pump & Ser. Stn, OMC (HPCL)','address':'X547+C2W, NH 48, Ariwali, Maharashtra 410221','area':'ARIWALI'},
{'id':'8','Name_of_CNG_RO':'MGL Agent - Jai Shreeram Ent','address':'Neral - Badlapur Rd, Maharashtra Industrial Development Corporation, Badlapur, Maharashtra 421503','area':'BADLAPUR'},
{'id':'9','Name_of_CNG_RO':'MGL-Badlapur','address':'Neral - Badlapur Rd, Maharashtra Industrial Development Corporation, Badlapur, Maharashtra 421503','area':'BADLAPUR'},
{'id':'10','Name_of_CNG_RO':'Mahaveer Auto, Bhandup (W)','address':'S-86, PLOT NO, 3, Maruti Mandir Rd, Subhash Nagar, Bhandup West, Mumbai,  400078','area':'BHANDUP W'},
{'id':'11','Name_of_CNG_RO':'Inspire Engg, Bhayander','address':'Plot Num 100/8, Uttan Gorai Road, Rai Village, Bhayander West, Thane 401101','area':'BHAYANDER'},
{'id':'12','Name_of_CNG_RO':'MGL Agent - Hind Auto, Bhiwandi','address':'S. No. 144, Plot No. 2 & 3, Bhiwandi Wada Highway, Village Shelar, Taluka- Bhiwandi, Dist – Thane ','area':'BHIWANDI'},
{'id':'13','Name_of_CNG_RO':'MGL Agent - Jay Hind Filling Station','address':'S. No. 9/1, 10/2, 11/1, Village Purna, Old Agra Road, Taluka Bhiwandi, Dist. Thane','area':'BHIWANDI'},
{'id':'14','Name_of_CNG_RO':'Kanhaiya Traders','address':'124/4, NEAR RITU HYUNDAI,GOVE, VILLAGE, Bhiwandi, Maharashtra 421311','area':'BHIWANDI'},
{'id':'15','Name_of_CNG_RO':'RR Fuels','address':'Ranjnoli Kalyan Road, Dandekarwadi, Bhiwandi, Maharashtra 421302','area':'BHIWANDI'},
{'id':'16','Name_of_CNG_RO':'Anjur Service Station. OMC (IOCL)','address':'SURVEY NO 102, VILLAGE DAPODA, Mumbai - Nashik Expy, Mankoli, Bhiwandi, Maharashtra 421302','area':'BHIWANDI'},
{'id':'17','Name_of_CNG_RO':'Aadarsh Petroleum, Yewai, OMC (HPCL)','address':'At Yewai Naka, Mumbai Nashik Highway, Bypass, Bhiwandi, Maharashtra 421302','area':'BHIWANDI'},
{'id':'18','Name_of_CNG_RO':'ROYAL AUTO SERVICE, GOVE','address':'Lock, 11031, Kalyan - Bhiwandi Rd, Saravali, Kalyan, Bhiwandi, Maharashtra 421311','area':'BHIWANDI'},
{'id':'19','Name_of_CNG_RO':'Gujrat Service Center, Arthur Road','address':'Next to Kasturba Hospital, Arthur Road, Sane Guruji Marg, Mumbai- 400 011','area':'BYCULLA'},
{'id':'20','Name_of_CNG_RO':'Diamond Auto Service, Chembur','address':'Sion Trombay Road, Near Akbarallys, Chembur Naka, Mumbai','area':'CHEMBUR'},
{'id':'21','Name_of_CNG_RO':'Joaquim Petroleum, Deonar','address':'Sion - Trombay Rd, opposite Telecom Factory Road, Deonar, Chembur, Mumbai, Maharashtra ','area':'CHEMBUR'},
{'id':'22','Name_of_CNG_RO':'Ketki Enterprise, Dahiser (E)','address':'C.T.S No. 296/A, Near Shree Krishana industrial Estate,Ketkipada Road, Near Dahisar Toll naka,Dahisar €','area':'DAHISAR'},
{'id':'23','Name_of_CNG_RO':'Rameshwar Engineering','address':'Plot No. F-20, MIDC Phase-2, KalyanShil Road, MIDC, Dombivali (E), Dist – Thane','area':'DOMBIVLI'},
{'id':'24','Name_of_CNG_RO':'Kwality Chemicals','address':'Plot No. 174, MIDC, Phase – I, Khambalpada Road, Dombivali (E), Thane','area':'DOMBIVLI'},
{'id':'25','Name_of_CNG_RO':'Om Vedant Dongri','address':'3335, IR Road, Bhendi Bazaar, Bhuleshwar, Mumbai, Maharashtra 400009','area':'DONGRI'},
{'id':'26','Name_of_CNG_RO':'Amul Chemicals Industries Pvt. Ltd Rabale','address':'Reliance Corporate Park, MIDC Industrial Area, Ghansoli','area':'GHANSOLI'},
{'id':'27','Name_of_CNG_RO':'Niru Engineering & Services Pvt Ltd','address':'Niru Filling Station, Opp Ghansoli Dmart, Ghansoli, Navi Mumbai','area':'GHANSOLI'},
{'id':'28','Name_of_CNG_RO':'Highway Automobiles, Ghatkopar (W)','address':'LBS Marg, Nityanad Marg, Ghatkopar West','area':'GHATKOPAR'},
{'id':'29','Name_of_CNG_RO':'GoM Ghatkopar','address':'Off Eastern Express Highway, Near Rajput House, Kamraj Nagar, Ghatkopar W','area':'GHATKOPAR E'},
{'id':'30','Name_of_CNG_RO':'MGL TEZ Ghatkopar RO','address':'Ghatkopar Bus depot,Mankur, Ghatkopar ','area':'GHATKOPAR E'},
{'id':'31','Name_of_CNG_RO':'Bharat Coaltar, Goregaon (E)','address':' 1-A, Cama Muncipal Industrial Estate, I. B. PaPh Road, Off. Western Exp. Highway, Goregaon (E),Mumbai–400 063.','area':'GOREGAON'},
{'id':'32','Name_of_CNG_RO':'MGL-Dindoshi Depot - MGL, Goregaon (E)','address':'MGL -Dindoshi ,Dindoshi out side pump  Mulund link road, Goregaon (E )Mumbai 400065','area':'GOREGAON'},
{'id':'33','Name_of_CNG_RO':'MGL TEZ Goregaon RO','address':'Goregaon BEST Depot, Near Goregaon W Metro Station, Goregaon west','area':'GOREGAON'},
{'id':'34','Name_of_CNG_RO':'Fort Motor','address':'Fort Motors  ,CNG CTS 589/A Village Pahadi off W.E .Highway near Shivdham ,Goregaon East','area':'GOREGAON'},
{'id':'35','Name_of_CNG_RO':'Radha Auto Care, Dronagiri','address':'Plot No 103A, Shikhar, Dronagiri, Node, Uran, Near JNPT NH 4B Jaskhar Sector 1, Raigad 400707','area':'JNPT'},
{'id':'36','Name_of_CNG_RO':'S K CNG station','address':'Gajanan Buliders, SNO 41(PT),CTS-410(PT)CNG STATION, OPP.CEMETARY,BESIDE ASIYANA CHS, RELIEF ROAD,OSHIWARA, JOGESHWARI','area':'JOGESHWARI'},
{'id':'37','Name_of_CNG_RO':'Pushpak Auto, Jogeshwari (E)','address':'M/s Pushpak Auto &  Gas station,Jogeshwari –Vikhroli link Road, Jogeshwari (E)Mumbai – 400 060','area':'JOGESHWARI'},
{'id':'38','Name_of_CNG_RO':'Venus Auto Centre (IOCL)','address':'Indian Oil Dealers, National Highway No-4,Old Mumbai Pune Road, Sector - 2, Kalamboli, Navi Mumbai, Maharashtra 410206','area':'KALAMBOLI'},
{'id':'39','Name_of_CNG_RO':'Hind Cng Filling Station','address':'Kalyan Bhiwandi Bypass Naka, Kalyan Road, Near TATA Showroom, Pimpalgaon, Kalyan, Thane – 400601','area':'KALYAN'},
{'id':'40','Name_of_CNG_RO':'SGR GROUP','address':'Parking Plaza, Durga mata Chowk, Kalyan West','area':'KALYAN'},
{'id':'41','Name_of_CNG_RO':'Ashirwad Enterprises, Kamba','address':'S.16/1 , Kamba Goan , kalyan murbad road ,kamba','area':'Kamba'},
{'id':'42','Name_of_CNG_RO':'Panvel Fastner Kamothe','address':'Panvel Ind. Fast. Pvt. Ltd Plot No. 33 , Kamothe, Panvel','area':'KAMOTHE'},
{'id':'43','Name_of_CNG_RO':'Charkop Petroleum, Charkop','address':'M/s Charkop Petroleum ,Plot  no. 186, 90’ Road,Behind Vasant Complex,Kandivili (W),Mumbai – 400067','area':'KANDIVLI'},
{'id':'44','Name_of_CNG_RO':'Vighnaharta Petroleum (IOCL)','address':' Kawad (Kh.), Maharashtra 421302','area':'Kawad'},
{'id':'45','Name_of_CNG_RO':'VICKY AND TEJAS AUTOMOBILE','address':'Survey No.4, Hissa No. 1&@, Village lodhivali,Old Mumbai Pune Highway,Taluka Khalapur,Dist. Raigad.','area':'KHALAPUR'},
{'id':'46','Name_of_CNG_RO':'Mauli enterprises','address':'77/4, Hal Budruk Next to Reliance Petrol Pump,Mumbai -Pune Old Expressway,Khopoli,Maharashtra -410203','area':'KHOPOLI'},
{'id':'47','Name_of_CNG_RO':'Samrudh Auto, Kurla (W)','address':'No 9, Nest to NEXA Showroom, Lal Bahadur Shastri Marg, Kurla West,','area':'KURLA'},
{'id':'48','Name_of_CNG_RO':'MGL MSRTC Mahad','address':'Survey No 71, Kakartale, Mahad, Raigad District 402301','area':'MAHAD'},
{'id':'49','Name_of_CNG_RO':'Saikripa Gas Station','address':'CTS NO -452,Survey no 241,Hissa no - 7,Village marve road,Malavni next to HPCL Petrol pump,Malad (W)Mumbai 4000098','area':'MALAD'},
{'id':'50','Name_of_CNG_RO':'GOM Malad,Malad (w )','address':'LOAD 02, CTS no 1188, Village malad, Opp jain sabkuch food plaza,Malad ( w )400064','area':'MALAD'},
{'id':'51','Name_of_CNG_RO':'Kapadia Brother, Malad (W)','address':'M/s Kapadia Brothers ,S.V.Road,Malad (W)Mumbai-400 064','area':'MALAD'},
{'id':'52','Name_of_CNG_RO':'Dattu Pawar, Mangaon','address':'Survey No. 178/1/2, Village- Koshte Khurd, Tehsil- Mangaon, District-Raigad, State – Maharashtra','area':'MANGAON'},
{'id':'53','Name_of_CNG_RO':'Kamerkar Cng Station, Rudravali','address':'Survey no 11/2, House No. 176, Koste BK, Pune Road, Mangaon, Raigad, Maharashtra, 402120, ','area':'MANGAON'},
{'id':'54','Name_of_CNG_RO':'MGL Mankhurd','address':'Jeejabai Bhosale Marg, Mankhurd West, Dr.Babsaheb Ambedker Nagar, Govandi East, Mumbai, ','area':'MANKHURD'},
{'id':'55','Name_of_CNG_RO':'Amar Automobiles, Wadi Bunder','address':'46 Wadi Bunder, P D Mello Road, next to Honda workshop, Opp commisioner of Police Railways, Mumbai 400010','area':'Mazgaon'},
{'id':'56','Name_of_CNG_RO':'BP Mahape - BPCL','address':'Plot no 5, 4, Mahape Midc Rd, Reliance Corporate Park, MIDC Industrial Area, Mahape, Navi Mumbai, Maharashtra 400701','area':'MHAPE'},
{'id':'57','Name_of_CNG_RO':'KG Rathod CNG Station, Mira-Bhayander ','address':'S NO70H NO 25A, CNG station ,mira bhayender road,Near Reliance petrol pump ,Bhayander (E )400006','area':'MIRA ROAD'},
{'id':'58','Name_of_CNG_RO':'Chris Enterprises Mira Road','address':'MAHANAGAR GAS LTD,OLD S NO 444 2 NEW S NO 128 2A,OPP CHRISTINE HIGHTS,NAVGHAR VILLAGE ,MARI GOLD ROAD ,MIRA ROAD (E),THANE,401107','area':'MIRA ROAD'},
{'id':'59','Name_of_CNG_RO':'Jaya CNG, Mira Road (Private)','address':'Bhagtivedanta swami marg, New Shree Krishna Garden,Opp Delta Garden, Miar Road east','area':'MIRA ROAD'},
{'id':'60','Name_of_CNG_RO':'Haji Liyakat CNG Station, Neral','address':'Shop No 1 Karjat Kalyan Road, West, near Pahunchar Hotel, Neral, Maharashtra 410101','area':'NERAL'},
{'id':'61','Name_of_CNG_RO':'Shree Omkar Gas Filling Station','address':'Survey no. 188/1, Village- Shirdhon, Taluka - Panvel, Dist. – Raigad-410221 ','area':'PANVEL'},
{'id':'62','Name_of_CNG_RO':'Raj Fleet, HPCL Petrol Pump','address':'National Highway 4,Old Mumbai Pune Road, Peth, Kolkhe Village, Palaspe Phata, Panvel, Navi Mumbai, Maharashtra 410206','area':'PANVEL'},
{'id':'63','Name_of_CNG_RO':'Rameshwar Cng Centre','address':'Survey No. 43A/2 , sawarsai, Post Tal, Pen, Raigad.','area':'PEN'},
{'id':'64','Name_of_CNG_RO':'Avdhut Enterprises','address':'Survey No. 38/18/B, Village- Jite, Taluka- Pen, District-Raigad','area':'PEN'},
{'id':'65','Name_of_CNG_RO':'MGL (MSRTC) Pen Pvt','address':'Survey No. 194B, 212/1/B, 194 A/1, Ramwadi, Pen, Raigad, Maharashtra 402107 ','area':'PEN'},
{'id':'66','Name_of_CNG_RO':'Shree Agency Poldapur','address':'Prop.Rohan Ramesh Talathi,Shop No.1,1972,M.G.Road,Mahad -Raigad -402301','area':'POLADPUR'},
{'id':'67','Name_of_CNG_RO':'Interstate Auto','address':'123, Reay Road west, Indian Oil Petrol Pump, Opp Reay Road Station West, P D Mello Road, Mumbai','area':'REAY ROAD'},
{'id':'68','Name_of_CNG_RO':'R V CNG Station, Roha','address':'Survey No. 146/3, Village- Chikani, Taluka- Roha','area':'ROHA'},
{'id':'69','Name_of_CNG_RO':'CGS Savroli','address':'R72M+J3J, MH SH 104, Niphan, Maharashtra 410203','area':'SAVROLI'},
{'id':'70','Name_of_CNG_RO':'Manhas Auto, Sewree','address':'A D Marg Sewri west opp municipal market. Near Sewri Bus Depot','area':'SEWRI'},
{'id':'71','Name_of_CNG_RO':'Indrajeet Waghmare, Shahad (Private)','address':'65W4+PP8, Shahad Brg, Shahad, Ulhasnagar, Maharashtra 421103','area':'SHAHAD'},
{'id':'72','Name_of_CNG_RO':'Shree Samarth Krupa, Padale (Kailash Bhoir)','address':'AT CNG , Near Sunanda circle , At.Padlegoan , post padlegoan , Kalyan Shilphata Road , Tal - Kalyan , Dist - Thane ','area':'SHILPHATA'},
{'id':'73','Name_of_CNG_RO':'Subhash Shinde, Kalya Shil Road. Samarthkrupa Daighar','address':'1, Kalyan - Shilphata Rd, Shilphata, Mumbai, Thane, Maharashtra 421204','area':'SHILPHATA'},
{'id':'74','Name_of_CNG_RO':'City Gate Station, Sion','address':'Sewri - Chembur Rd, Opp Anik Bus Depot,Municipal Kamgar Vasahat, Trombay Indusrial Area, Sion','area':'SION'},
{'id':'75','Name_of_CNG_RO':'MGL-Taloja','address':'J-93/2 Taloja MIDC Area Navi Mumbai','area':'TALOJA'},
{'id':'76','Name_of_CNG_RO':'Apoorva Hospitality & Engineering Private Limited, Taloja MIDC','address':'PAP 168 Opposite Aditya Birla, Navda, Taloja, Panvel','area':'TALOJA'},
{'id':'77','Name_of_CNG_RO':'Noble Automobiles khoni (BPCL)','address':'Taloja Bypass Phata, Khoni Pagadyacha Pada, Maharashtra 421204','area':'TALOJA'},
{'id':'78','Name_of_CNG_RO':'Twincity Glass Pvt Ltd','address':'TWINCITY GLASS MIDC, A-2, MIDC Industrial Area, Taloja, Navi Mumbai, Maharashtra 410208','area':'TALOJA'},
{'id':'79','Name_of_CNG_RO':'MGL - Owala CNG Filling Sation','address':'1, Ghodbunder Road, Owale, Thane West','area':'THANE'},
{'id':'80','Name_of_CNG_RO':'MGL AGENT-Dhanlaxmi Enterprises','address':'H no 22, Gaimukh Gaon, Godhbunder Road, Thane W','area':'THANE'},
{'id':'81','Name_of_CNG_RO':'Sun Automobile, Thane','address':'Plot No. A, 120, Rd Number 22, Hanuman Nagar, Wagle Industrial Estate, MIDC, Thane,','area':'THANE'},
{'id':'82','Name_of_CNG_RO':'MGL Khopat','address':'Khopat Road, Next to MSRCT Bus Stand, Khopat, Thane W','area':'THANE'},
{'id':'83','Name_of_CNG_RO':'MGL-(Agent NMMT- Bus Depot)','address':'Plot No. 1 Turbhe Depot Sector 20 Vashi','area':'TURBHE'},
{'id':'84','Name_of_CNG_RO':'Raj Auto Service Centre, Turbhe','address':'Raj Auto Service Centre, Turbhe Plot No-d-28/17 TTC Industrial Area MIDC, Turbhe','area':'TURBHE'},
{'id':'85','Name_of_CNG_RO':'Green Gas Services','address':'Plot no.30, Industrial Area, Ulhasnagar - 421004','area':'ULHASNAGAR'},
{'id':'86','Name_of_CNG_RO':'Laxmi Jyot Cng Station','address':'Padmashree Mohammad Rafi Marg, Baba Namandas Nagar, Kailash Colony, Ulhasnagar, Maharashtra 421004','area':'ULHASNAGAR'},
{'id':'87','Name_of_CNG_RO':'Pranaya Gas ( Diraj Jethani)','address':'Behind Sai baba mandir , dolphin club road , shantinagar, Ulhanagar-421003','area':'ULHASNAGAR'},
{'id':'88','Name_of_CNG_RO':'Pranaya Gas (Dhiraj Jethani) ','address':'Behind Sai baba mandir , dolphin club road , shantinagar, Ulhanagar-421003','area':'ULHASNAGAR'},
{'id':'89','Name_of_CNG_RO':'P.P. Kharpatil Cng Station','address':'Survey no. 28/2/1/A, Village - Bhom, Taluka - Uran, Dist. – Raigad','area':'URAN'},
{'id':'90','Name_of_CNG_RO':'Vashi Petroleum, Vashi','address':'Lock No 21016 Plot No 42, Sector 18, Sector 26, Vashi,','area':'VASHI'},
{'id':'91','Name_of_CNG_RO':'I Carport, Sahar','address':'M/s Intel Carport ,Mumbai International Airport, Terminal 2, Sahar,Mumbai – 400 009','area':'VILE PARLE'},
{'id':'92','Name_of_CNG_RO':'Excel Service Station - 1, Vile Parle (W)','address':'M/s Excel Service Station,V.M Road,Juhu Vile Parle Scheme,Vile Parle (W),Mumbai – 400 056','area':'VILE PARLE'},
{'id':'93','Name_of_CNG_RO':'MGL - Vitthalwadi ','address':'Vitthalwadi station, Ulhasnagar, Maharashtra 421306','area':'VITTHALWADI'},
]


  }




}
