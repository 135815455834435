import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AwardsResp } from '../models/award';

@Injectable({
  providedIn: 'root'
})
export class AwardsService {
  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  get(Year:string) {
    return this.http.post<AwardsResp>(`${this.baseUrl}awards/year_awards`, {Year:Year})
      .pipe(map(user => {
        return user; 
      }))
  } 

  
  getAll():Observable<AwardsResp[]> {
    return this.http.get<AwardsResp[]>(this.baseUrl+'awards/get_Year');
  }
   

}
