import { Injectable } from '@angular/core';
import { map, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PayuService {
 baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

         getPayU_From_wallet_data(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/getPayU_From_wallet_data',data)
        }

        getPayUdata(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/getPayUdata',data)
        }

        payUencrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/payUencrypt',data)
        }

        payUcreateCard(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/payUcreateCard',data)
        }

        payUdecrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/payUdecrypt',data)
        }

        payUcreatewalletdata(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/payUcreatewalletdata',data)
        }

        findpayUcreatewalletdata(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/findpayUcreatewalletdata',data)
        }


        get_wallet_balance_encrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/get_wallet_balance_encrypt',data)
        }

        get_retrieveCustRecord(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/get_retrieveCustRecord',data)
        }

        get_wallet_balance_decrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/get_wallet_balance_decrypt',data)
        }

         get_statementInquiry_encrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/get_statementInquiry_encrypt',data)
        }

         get_statementInquiry(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/get_statementInquiry',data)
        }

          get_statementInquiry_decrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/get_statementInquiry_decrypt',data)
        }

         load_wallet_money_encrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/load_wallet_money_encrypt',data)
        }

         wallet_money_loadAccount(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_money_loadAccount',data)
        }

          load_wallet_money__decrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/load_wallet_money__decrypt',data)
        }


          wallet_card_block_encrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_card_block_encrypt',data)
        }

          wallet_card_block(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_card_block',data)
        }

          wallet_card_block__decrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_card_block__decrypt',data)
        }


         wallet_card_Unblock_encrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_card_Unblock_encrypt',data)
        }

          wallet_Uncard_block(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_Uncard_block',data)
        }

          wallet_card_Unblock__decrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_card_Unblock__decrypt',data)
        }


         wallet_amt_Unload_encrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_amt_Unload_encrypt',data)
        }

          wallet_amt_Unload(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_amt_Unload',data)
        }

          wallet_amt_Unload__decrypt(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/wallet_amt_Unload__decrypt',data)
        }


        //***********New Api start********//


        checkwallet(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/checkwallet',data)
        }


          createWallet(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/createWallet',data)
        }

        walletStatement(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/walletStatement',data)
        }

         initpaymentorder_wallet(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/initpaymentorder_wallet',data)
        }

          payupaymentcomplete_wallet(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/payupaymentcomplete_wallet',data)
        }

          getLatestPayadddata(data:any){
        return this.http.post<any>(this.baseUrl+'payutransaction/getLatestPayadddata',data)
        }

          //***********New Api end********//


}
