import { Component } from '@angular/core';

@Component({
  selector: 'app-steelpipelinenetworklayout',
  templateUrl: './steelpipelinenetworklayout.component.html',
  styleUrls: ['./steelpipelinenetworklayout.component.css']
})
export class SteelpipelinenetworklayoutComponent {

}
