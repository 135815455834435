  import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { vehiclepromotionResp } from '../models/vehiclepromotion';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Oempromotion20Service {
 baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }


    insert_Vehicle_OEM(data:any){
    return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/insert_Vehicle_OEM',data)
    }

     search_Vehicle_OEM_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/search_Vehicle_OEM_Application',data)
    }


     update_Vehicle_OEM(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/update_Vehicle_OEM',data)
    }

     edit_Vehicle_OEM(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/edit_Vehicle_OEM',data)
    }

     search_Vehicle_OEM_level_two_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/search_Vehicle_OEM_level_two_Application',data)
    }

     update_Vehicle_levle_two_OEM(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/update_Vehicle_levle_two_OEM',data)
    }

    get_oem_20_levle_one_list(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/get_oem_20_levle_one_list',data)
    }

    //****************Nawagati api star**********//

    get_all_oem_delear(data:any){
    return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/get_all_oem_delear',data)
    }

     get_oem_delear_carnumber(data:any){
    return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_2_0_promotion_oem/get_oem_delear_carnumber',data)
    }


    //****************Nawagati api end**********//

}
