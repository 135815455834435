import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { ApplyforoutletService } from 'src/app/Service/applyforoutlet.service';

@Component({
  selector: 'app-applyforcngouletnewm',
  templateUrl: './applyforcngouletnewm.component.html',
  styleUrls: ['./applyforcngouletnewm.component.css']
})
export class ApplyforcngouletnewmComponent {
 applyoutletForm!: FormGroup
  submitted = false
   captcha:any
   alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center';

  previouspageData:any;
  previouspageData1:any;

  plot:any;
  district:any;

  applicantFlag = false;
  applicantIndividualFlag = false;
  applicantFirmFlag = false;

  firstFalg = false;
  secondFalg  = false;
  thirdFalg = false;
  fourthFalg  = false;
  fiveFalg = false;
  sixFalg  = false;

  othertypeFlag = false;

  under_taking_img:any;
under_taking_name:any;
architect_layout_img:any;
architect_layout_name:any;
id_proof_img:any;
id_proof_name:any;
demand_draft_img:any;
demand_draft_name:any;
incorporation_img:any;
incorporation_name:any;

  constructor(private pageLoaderService: PageLoaderService, 
    private activatedRoute: ActivatedRoute,public router: Router,
    private FormBuilder: FormBuilder, private service: ApplyforoutletService) { 
      this.activatedRoute.queryParams.subscribe((data: any) => {
     //   console.log(data);
        this.previouspageData = JSON.parse(data.data);
        this.previouspageData1 = this.previouspageData.details;
        console.log('a',this.previouspageData1);
        // if (data && data.id) {
        //   this.mobileNumber = data.mobile_no;
        // }
      });
      
    }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);



    this.captchaGenerate()
    this.getPlot();
    this.getdist();
    this.applyoutletForm = this.FormBuilder.group({
      ApplicationNo:[''],
      typeofapplicant: ['', [Validators.required]],
      numberofapplicant: ['', [Validators.required]],
      firstapplicant: ['', [Validators.required]],
      secondapplicant: ['', [Validators.required]],
      thirdapplicant: ['', [Validators.required]],
      fourthapplicant: ['', [Validators.required]],
      fifthapplicant: ['', [Validators.required]],
      sixapplicant: ['', [Validators.required]],
      nameoffirm: ['', [Validators.required]],
      typeoffirm:  ['', [Validators.required]],
      otherfirm:  ['', [Validators.required]],
      Name: ['', [Validators.required]],
      ContactNumber: ['', [Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
      // Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
       Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      NameofPlotOwner: ['', [Validators.required]],
    //  RelationshipofAppOwner: ['', [Validators.required]],
      // Addressofplot: ['', [Validators.required]],
       Addressofplot: [''],
      SurveyNo: ['', [Validators.required]],
      District: ['', [Validators.required]],
      // PinCode: ['', [Validators.required,Validators.pattern(/^[0-9]{6}$/)]],
      AreaofthePlot: ['', Validators.required],
     // Frontageoftheplot: ['', Validators.required],
     // Depth: ['', Validators.required],
      // Latitude: ['', Validators.required],
      // Longitude: ['', Validators.required],
       Latitude: [''],
      Longitude: [''],
     // NameofRoad: ['', Validators.required],
   //   Plot: ['', Validators.required],
      Additionalremarks: [''],
      lease_tenure_years: ['', Validators.required],
      orderNo: ['', Validators.required],
      orderNodate: ['', Validators.required],
      bank_name: ['', Validators.required],
      under_taking_doc: ['', Validators.required],
      architect_layout_doc: ['', Validators.required],
      id_proof_doc: ['', Validators.required],
      demand_draft_doc: ['', Validators.required],
      incorporation_doc: ['', Validators.required],
      Captcha: ['',[Validators.required]],
      Ownership_of_Land: ['', Validators.required],
       Correspondence_Address: ['', [Validators.required]],
       accept_term: ['', [Validators.required]],

      // Doc1_Propertycard: ['', Validators.required],
      // Doc2_subleases: ['', Validators.required],
      // Doc3_Photographsofland: ['', Validators.required],
      // Doc4_AgeProof: ['', Validators.required],
      // Doc5_IDproof: ['', Validators.required],

    });

      this.applyoutletForm.get('ApplicationNo')?.setValue(this.previouspageData1[0].ApplicationNo);
      this.applyoutletForm.get('Name')?.setValue(this.previouspageData1[0].Name);
      this.applyoutletForm.get('ContactNumber')?.setValue(this.previouspageData1[0].ContactNumber);
      this.applyoutletForm.get('Email')?.setValue(this.previouspageData1[0].Email);
      this.applyoutletForm.get('NameofPlotOwner')?.setValue(this.previouspageData1[0].NameofPlotOwner);
    //  this.applyoutletForm.get('RelationshipofAppOwner')?.setValue(this.previouspageData1[0].RelationshipofAppOwner);
      this.applyoutletForm.get('Addressofplot')?.setValue(this.previouspageData1[0].Addressofplot );
      this.applyoutletForm.get('SurveyNo')?.setValue(this.previouspageData1[0].SurveyNo);
      this.applyoutletForm.get('District')?.setValue(this.previouspageData1[0].District);
    //  this.applyoutletForm.get('PinCode')?.setValue(this.previouspageData1[0].PinCode);
      this.applyoutletForm.get('AreaofthePlot')?.setValue(this.previouspageData1[0].AreaofthePlot);
     // this.applyoutletForm.get('Frontageoftheplot')?.setValue(this.previouspageData1[0].Frontageoftheplot);
    //  this.applyoutletForm.get('Depth')?.setValue(this.previouspageData1[0].Depth);
      this.applyoutletForm.get('Latitude')?.setValue(this.previouspageData1[0].Latitude);
      this.applyoutletForm.get('Longitude')?.setValue(this.previouspageData1[0].Longitude);
     // this.applyoutletForm.get('NameofRoad')?.setValue(this.previouspageData1[0].NameofRoad);
     // this.applyoutletForm.get('Plot')?.setValue(this.previouspageData1[0].Plot);
      this.applyoutletForm.get('Additionalremarks')?.setValue(this.previouspageData1[0].Additionalremarks);
      this.applyoutletForm.get('Ownership_of_Land')?.setValue(this.previouspageData1[0].Ownership_of_Land);
      this.applyoutletForm.get('Correspondence_Address')?.setValue(this.previouspageData1[0].Correspondence_Address);

  }

   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }


   captchaGenerate() {
    let length = 5
    let result = '';
    // const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
     const characters = '0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

   getdist() {
    this.service.getdistrict().subscribe((res: any) => {
      // this.district = res
       this.district = [
      {"District":"Mumbai" },
      {"District":"Thane" },
      {"District":"Raigad" }
      ]
      // console.log(res)
      // console.log('this.District', this.district);
    })
  }

   getPlot() {
    this.service.getplot().subscribe((res: any) => {
      this.plot = res
      // console.log(res)
      // console.log('this.plot', this.plot);
    })
  }

  getApplicantNumber(){
    console.log(this.applyoutletForm.get('numberofapplicant')?.value=="1")
    if(this.applyoutletForm.get('numberofapplicant')?.value=="1"){
        this.firstFalg = true;
        this.secondFalg  = false;
        this.thirdFalg = false;
        this.fourthFalg  = false;
        this.fiveFalg = false;
        this.sixFalg  = false;

         this.applyoutletForm.get('firstapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.clearValidators()
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.clearValidators()
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.clearValidators()
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.clearValidators()
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()

         this.applyoutletForm.get('firstapplicant')?.setValue(this.previouspageData1[0].Name);

        this.applyoutletForm.get('secondapplicant')?.setValue("");
        this.applyoutletForm.get('thirdapplicant')?.setValue("");
        this.applyoutletForm.get('fourthapplicant')?.setValue("");
        this.applyoutletForm.get('fifthapplicant')?.setValue("");
        this.applyoutletForm.get('sixapplicant')?.setValue("");

    }else if(this.applyoutletForm.get('numberofapplicant')?.value=="2"){
        this.firstFalg = true;
        this.secondFalg  = true;
        this.thirdFalg = false;
        this.fourthFalg  = false;
        this.fiveFalg = false;
        this.sixFalg  = false;

         this.applyoutletForm.get('firstapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.clearValidators()
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.clearValidators()
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.clearValidators()
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()

        this.applyoutletForm.get('firstapplicant')?.setValue(this.previouspageData1[0].Name);
        this.applyoutletForm.get('thirdapplicant')?.setValue("");
        this.applyoutletForm.get('fourthapplicant')?.setValue("");
        this.applyoutletForm.get('fifthapplicant')?.setValue("");
        this.applyoutletForm.get('sixapplicant')?.setValue("");

    }else if(this.applyoutletForm.get('numberofapplicant')?.value=="3"){
        this.firstFalg = true;
        this.secondFalg  = true;
        this.thirdFalg = true;
        this.fourthFalg  = false;
        this.fiveFalg = false;
        this.sixFalg  = false;

           this.applyoutletForm.get('firstapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.clearValidators()
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.clearValidators()
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()

        this.applyoutletForm.get('firstapplicant')?.setValue(this.previouspageData1[0].Name);
        this.applyoutletForm.get('fourthapplicant')?.setValue("");
        this.applyoutletForm.get('fifthapplicant')?.setValue("");
        this.applyoutletForm.get('sixapplicant')?.setValue("");

    }else if(this.applyoutletForm.get('numberofapplicant')?.value=="4"){
        this.firstFalg = true;
        this.secondFalg  = true;
        this.thirdFalg = true;
        this.fourthFalg  = true;
        this.fiveFalg = false;
        this.sixFalg  = false;

         this.applyoutletForm.get('firstapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.clearValidators()
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()

       this.applyoutletForm.get('firstapplicant')?.setValue(this.previouspageData1[0].Name);
        this.applyoutletForm.get('fifthapplicant')?.setValue("");
        this.applyoutletForm.get('sixapplicant')?.setValue("");

    }else if(this.applyoutletForm.get('numberofapplicant')?.value=="5"){
        this.firstFalg = true;
        this.secondFalg  = true;
        this.thirdFalg = true;
        this.fourthFalg  = true;
        this.fiveFalg = true;
        this.sixFalg  = false;

         this.applyoutletForm.get('firstapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()
      
        this.applyoutletForm.get('firstapplicant')?.setValue(this.previouspageData1[0].Name);

        this.applyoutletForm.get('sixapplicant')?.setValue("");

    }else if(this.applyoutletForm.get('numberofapplicant')?.value=="6"){
        this.firstFalg = true;
        this.secondFalg  = true;
        this.thirdFalg = true;
        this.fourthFalg  = true;
        this.fiveFalg = true;
        this.sixFalg  = true;

         this.applyoutletForm.get('firstapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()



    }else{
        this.firstFalg = false;
        this.secondFalg  = false;
        this.thirdFalg = false;
        this.fourthFalg  = false;
        this.fiveFalg = false;
        this.sixFalg  = false;

         this.applyoutletForm.get('firstapplicant')?.clearValidators()
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.clearValidators()
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.clearValidators()
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.clearValidators()
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.clearValidators()
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()

         this.applyoutletForm.get('firstapplicant')?.setValue("");
        this.applyoutletForm.get('secondapplicant')?.setValue("");
        this.applyoutletForm.get('thirdapplicant')?.setValue("");
        this.applyoutletForm.get('fourthapplicant')?.setValue("");
        this.applyoutletForm.get('fifthapplicant')?.setValue("");
        this.applyoutletForm.get('sixapplicant')?.setValue("");

    }

  }


  getApplicanttype(){
    if(this.applyoutletForm.get('typeofapplicant')?.value=="वैयक्तिक"){
        this.applicantFlag = true;
        this.applicantIndividualFlag = true;
        this.applicantFirmFlag = false;

         this.applyoutletForm.get('numberofapplicant')?.setValidators([Validators.required])
        this.applyoutletForm.get('numberofapplicant')?.updateValueAndValidity()

         this.applyoutletForm.get('nameoffirm')?.clearValidators()
        this.applyoutletForm.get('nameoffirm')?.updateValueAndValidity()
         this.applyoutletForm.get('typeoffirm')?.clearValidators()
        this.applyoutletForm.get('typeoffirm')?.updateValueAndValidity()
         this.applyoutletForm.get('otherfirm')?.clearValidators()
        this.applyoutletForm.get('otherfirm')?.updateValueAndValidity()


          this.applyoutletForm.get('incorporation_doc')?.clearValidators()
        this.applyoutletForm.get('incorporation_doc')?.updateValueAndValidity()

         this.applyoutletForm.get('nameoffirm')?.setValue("");
         this.applyoutletForm.get('typeoffirm')?.setValue("");
         this.applyoutletForm.get('otherfirm')?.setValue("");

          this.othertypeFlag = false;

    }else if(this.applyoutletForm.get('typeofapplicant')?.value=="नोंदणीकृत संस्था"){
         this.applicantFlag = true;
        this.applicantIndividualFlag = false;
        this.applicantFirmFlag = true;

        this.applyoutletForm.get('numberofapplicant')?.clearValidators()
        this.applyoutletForm.get('numberofapplicant')?.updateValueAndValidity()

         this.applyoutletForm.get('nameoffirm')?.setValidators([Validators.required])
        this.applyoutletForm.get('nameoffirm')?.updateValueAndValidity()
         this.applyoutletForm.get('typeoffirm')?.setValidators([Validators.required])
        this.applyoutletForm.get('typeoffirm')?.updateValueAndValidity()
        //  this.applyoutletForm.get('otherfirm')?.setValidators([Validators.required])
        // this.applyoutletForm.get('otherfirm')?.updateValueAndValidity()


          this.applyoutletForm.get('incorporation_doc')?.setValidators([Validators.required])
        this.applyoutletForm.get('incorporation_doc')?.updateValueAndValidity()

         this.applyoutletForm.get('otherfirm')?.clearValidators()
        this.applyoutletForm.get('otherfirm')?.updateValueAndValidity()

        this.firstFalg = false;
        this.secondFalg  = false;
        this.thirdFalg = false;
        this.fourthFalg  = false;
        this.fiveFalg = false;
        this.sixFalg  = false;

         this.applyoutletForm.get('firstapplicant')?.clearValidators()
        this.applyoutletForm.get('firstapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('secondapplicant')?.clearValidators()
        this.applyoutletForm.get('secondapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('thirdapplicant')?.clearValidators()
        this.applyoutletForm.get('thirdapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fourthapplicant')?.clearValidators()
        this.applyoutletForm.get('fourthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('fifthapplicant')?.clearValidators()
        this.applyoutletForm.get('fifthapplicant')?.updateValueAndValidity()
         this.applyoutletForm.get('sixapplicant')?.clearValidators()
        this.applyoutletForm.get('sixapplicant')?.updateValueAndValidity()
        
          this.applyoutletForm.get('numberofapplicant')?.setValue("");

        this.applyoutletForm.get('firstapplicant')?.setValue("");
        this.applyoutletForm.get('secondapplicant')?.setValue("");
        this.applyoutletForm.get('thirdapplicant')?.setValue("");
        this.applyoutletForm.get('fourthapplicant')?.setValue("");
        this.applyoutletForm.get('fifthapplicant')?.setValue("");
        this.applyoutletForm.get('sixapplicant')?.setValue("");


    }else{
        this.applicantFlag = false;
        this.applicantIndividualFlag = false;
        this.applicantFirmFlag = false;
    }
    // console.log(this.applyoutletForm.get('typeofapplicant')?.value)
  }


  getfirmtype(){

    if(this.applyoutletForm.get('typeoffirm')?.value=="इतर"){
      this.applyoutletForm.get('otherfirm')?.setValidators([Validators.required])
        this.applyoutletForm.get('otherfirm')?.updateValueAndValidity()

        this.othertypeFlag = true;
    }else{

       this.applyoutletForm.get('otherfirm')?.clearValidators()
        this.applyoutletForm.get('otherfirm')?.updateValueAndValidity()
        this.othertypeFlag = false;
        this.applyoutletForm.get('otherfirm')?.setValue("");

    }

  }



   fileCheckForSlip(val: any,filename:any) {
    //console.log(val.target.files[0].type)

    if(filename == "Undertaking"){
       if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
        this.under_taking_img = val.target.files;
         this.under_taking_name = val.target.files[0];
    } else {
       this.applyoutletForm.get('under_taking_doc')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }
  }else if(filename == "architect"){
     if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
        this.architect_layout_img = val.target.files;
         this.architect_layout_name = val.target.files[0];
    } else {
       this.applyoutletForm.get('architect_layout_doc')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }

  }else if(filename == "idproof"){
     if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
        this.id_proof_img = val.target.files;
         this.id_proof_name = val.target.files[0];
    } else {
       this.applyoutletForm.get('id_proof_doc')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }

  }else if(filename == "demanddraft"){
     if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
        this.demand_draft_img = val.target.files;
         this.demand_draft_name = val.target.files[0];
    } else {
       this.applyoutletForm.get('demand_draft_doc')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }

  }else if(filename == "incorporation"){
     if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
        this.incorporation_img = val.target.files;
         this.incorporation_name = val.target.files[0];
    } else {
       this.applyoutletForm.get('incorporation_doc')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }

  }
   
  }

   
  onSubmit(){

    console.log('sdkjkfsdfjdsfjsdfjsdkkjjfj')

     this.submitted = true
    if(this.applyoutletForm.get('Captcha')?.value != this.captcha){
        this.applyoutletForm.get('Captcha')?.setValue('')
      }
    if (this.applyoutletForm.invalid) {
      return;
    }

    //alert("all good")

    const form = new FormData()

      if (this.under_taking_name != null) {
       let under_takingImg = this.under_taking_img[0].name.split('.').pop()
    let under_takingImg1  =this.under_taking_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ under_takingImg;
      form.append("under_taking_doc", this.under_taking_img[0], under_takingImg1)
      }

      if (this.architect_layout_name != null) {
       let architect_layoutImg = this.architect_layout_img[0].name.split('.').pop()
    let architect_layoutImg1  =this.architect_layout_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ architect_layoutImg;
      form.append("architect_layout_doc", this.architect_layout_img[0], architect_layoutImg1)
      
    }

    if (this.id_proof_name != null) {
       let id_proofImg = this.id_proof_img[0].name.split('.').pop()
    let id_proofImg1  =this.id_proof_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ id_proofImg;
      form.append("id_proof_doc", this.id_proof_img[0], id_proofImg1)
      
    }

    if (this.demand_draft_name != null) {
       let demand_draftImg = this.demand_draft_img[0].name.split('.').pop()
    let demand_draftImg1  =this.demand_draft_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ demand_draftImg;
      form.append("demand_draft_doc", this.demand_draft_img[0], demand_draftImg1)
      
    }

    if (this.incorporation_name != null) {
       let incorporationImg = this.incorporation_img[0].name.split('.').pop()
    let incorporationImg1  =this.incorporation_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ incorporationImg;
      form.append("incorporation_doc", this.incorporation_img[0], incorporationImg1)
      
    }




     form.append("ApplicationNo", this.applyoutletForm.get('ApplicationNo')?.value)
    form.append("Name", this.applyoutletForm.get('Name')?.value)
    form.append("ContactNumber", this.applyoutletForm.get('ContactNumber')?.value)
    form.append("Email", this.applyoutletForm.get('Email')?.value)
    form.append("NameofPlotOwner", this.applyoutletForm.get('NameofPlotOwner')?.value)
   // form.append("RelationshipofAppOwner", this.applyoutletForm.get('RelationshipofAppOwner')?.value)
    form.append("Addressofplot", this.applyoutletForm.get('Addressofplot')?.value)
    form.append("SurveyNo", this.applyoutletForm.get('SurveyNo')?.value)
    form.append("District", this.applyoutletForm.get('District')?.value)
   // form.append("PinCode", this.applyoutletForm.get('PinCode')?.value)
    form.append("AreaofthePlot", this.applyoutletForm.get('AreaofthePlot')?.value)
    //form.append("Frontageoftheplot", this.applyoutletForm.get('Frontageoftheplot')?.value)
   // form.append("Depth", this.applyoutletForm.get('Depth')?.value)
    form.append("Latitude", this.applyoutletForm.get('Latitude')?.value)
    form.append("Longitude", this.applyoutletForm.get('Longitude')?.value)
   // form.append("NameofRoad", this.applyoutletForm.get('NameofRoad')?.value)
   // form.append("Plot", this.applyoutletForm.get('Plot')?.value)
    form.append("Additionalremarks", this.applyoutletForm.get('Additionalremarks')?.value)
    form.append("typeofapplicant", this.applyoutletForm.get('typeofapplicant')?.value)
    form.append("numberofapplicant", this.applyoutletForm.get('numberofapplicant')?.value)
    form.append("firstapplicant", this.applyoutletForm.get('firstapplicant')?.value)
    form.append("secondapplicant", this.applyoutletForm.get('secondapplicant')?.value)
    form.append("thirdapplicant", this.applyoutletForm.get('thirdapplicant')?.value)
    form.append("fourthapplicant", this.applyoutletForm.get('fourthapplicant')?.value)
    form.append("fifthapplicant", this.applyoutletForm.get('fifthapplicant')?.value)
    form.append("sixapplicant", this.applyoutletForm.get('sixapplicant')?.value)
    form.append("nameoffirm", this.applyoutletForm.get('nameoffirm')?.value)
    form.append("typeoffirm", this.applyoutletForm.get('typeoffirm')?.value)
    form.append("otherfirm", this.applyoutletForm.get('otherfirm')?.value)
    form.append("lease_tenure_years", this.applyoutletForm.get('lease_tenure_years')?.value)
    form.append("orderNo", this.applyoutletForm.get('orderNo')?.value)
    form.append("orderNodate", this.applyoutletForm.get('orderNodate')?.value)
    form.append("bank_name", this.applyoutletForm.get('bank_name')?.value)
    form.append("form_status", "Application Submitted")

      this.service.insert_applyoulet_level_two_marathi(form).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {

        this.alertType='submit'
        this.alertMsg =  res.message
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
         this.clearAlert()
          this.applyoutletForm.reset()
          this.submitted = false
          this.router.navigate(['/marathi-thank-you-apply-outlet/'+this.previouspageData1[0].ApplicationNo]) 
        }, 1500);
          

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'submit'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      //console.log(err)
    })


     


  }



  onSubmit1(){

    console.log('sdkjkfsdfjdsfjsdfjsdkkjjfj')

     this.submitted = true
    if(this.applyoutletForm.get('Captcha')?.value != this.captcha){
        this.applyoutletForm.get('Captcha')?.setValue('')
      }
    if (this.applyoutletForm.invalid) {
      return;
    }

    //alert("all good")

    const form = new FormData()

      if (this.under_taking_name != null) {
       let under_takingImg = this.under_taking_img[0].name.split('.').pop()
    let under_takingImg1  =this.under_taking_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ under_takingImg;
      form.append("under_taking_doc", this.under_taking_img[0], under_takingImg1)
      }

      if (this.architect_layout_name != null) {
       let architect_layoutImg = this.architect_layout_img[0].name.split('.').pop()
    let architect_layoutImg1  =this.architect_layout_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ architect_layoutImg;
      form.append("architect_layout_doc", this.architect_layout_img[0], architect_layoutImg1)
      
    }

    if (this.id_proof_name != null) {
       let id_proofImg = this.id_proof_img[0].name.split('.').pop()
    let id_proofImg1  =this.id_proof_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ id_proofImg;
      form.append("id_proof_doc", this.id_proof_img[0], id_proofImg1)
      
    }

    if (this.demand_draft_name != null) {
       let demand_draftImg = this.demand_draft_img[0].name.split('.').pop()
    let demand_draftImg1  =this.demand_draft_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ demand_draftImg;
      form.append("demand_draft_doc", this.demand_draft_img[0], demand_draftImg1)
      
    }

    if (this.incorporation_name != null) {
       let incorporationImg = this.incorporation_img[0].name.split('.').pop()
    let incorporationImg1  =this.incorporation_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ incorporationImg;
      form.append("incorporation_doc", this.incorporation_img[0], incorporationImg1)
      
    }




     form.append("ApplicationNo", this.applyoutletForm.get('ApplicationNo')?.value)
    form.append("Name", this.applyoutletForm.get('Name')?.value)
    form.append("ContactNumber", this.applyoutletForm.get('ContactNumber')?.value)
    form.append("Email", this.applyoutletForm.get('Email')?.value)
    form.append("NameofPlotOwner", this.applyoutletForm.get('NameofPlotOwner')?.value)
   // form.append("RelationshipofAppOwner", this.applyoutletForm.get('RelationshipofAppOwner')?.value)
    form.append("Addressofplot", this.applyoutletForm.get('Addressofplot')?.value)
    form.append("SurveyNo", this.applyoutletForm.get('SurveyNo')?.value)
    form.append("District", this.applyoutletForm.get('District')?.value)
   // form.append("PinCode", this.applyoutletForm.get('PinCode')?.value)
    form.append("AreaofthePlot", this.applyoutletForm.get('AreaofthePlot')?.value)
    //form.append("Frontageoftheplot", this.applyoutletForm.get('Frontageoftheplot')?.value)
   // form.append("Depth", this.applyoutletForm.get('Depth')?.value)
    form.append("Latitude", this.applyoutletForm.get('Latitude')?.value)
    form.append("Longitude", this.applyoutletForm.get('Longitude')?.value)
   // form.append("NameofRoad", this.applyoutletForm.get('NameofRoad')?.value)
   // form.append("Plot", this.applyoutletForm.get('Plot')?.value)
    form.append("Additionalremarks", this.applyoutletForm.get('Additionalremarks')?.value)
    form.append("typeofapplicant", this.applyoutletForm.get('typeofapplicant')?.value)
    form.append("numberofapplicant", this.applyoutletForm.get('numberofapplicant')?.value)
    form.append("firstapplicant", this.applyoutletForm.get('firstapplicant')?.value)
    form.append("secondapplicant", this.applyoutletForm.get('secondapplicant')?.value)
    form.append("thirdapplicant", this.applyoutletForm.get('thirdapplicant')?.value)
    form.append("fourthapplicant", this.applyoutletForm.get('fourthapplicant')?.value)
    form.append("fifthapplicant", this.applyoutletForm.get('fifthapplicant')?.value)
    form.append("sixapplicant", this.applyoutletForm.get('sixapplicant')?.value)
    form.append("nameoffirm", this.applyoutletForm.get('nameoffirm')?.value)
    form.append("typeoffirm", this.applyoutletForm.get('typeoffirm')?.value)
    form.append("otherfirm", this.applyoutletForm.get('otherfirm')?.value)
    form.append("lease_tenure_years", this.applyoutletForm.get('lease_tenure_years')?.value)
    form.append("orderNo", this.applyoutletForm.get('orderNo')?.value)
    form.append("orderNodate", this.applyoutletForm.get('orderNodate')?.value)
    form.append("bank_name", this.applyoutletForm.get('bank_name')?.value)
    form.append("form_status", "Draft")

      this.service.insert_applyoulet_level_two_marathi(form).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {

        this.alertType='submit'
        this.alertMsg =  res.message
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
         this.clearAlert()
          this.applyoutletForm.reset()
          this.submitted = false
          this.router.navigate(['/marathi-thank-you-apply-outlet/'+this.previouspageData1[0].ApplicationNo]) 
        }, 1500);
          

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'submit'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      //console.log(err)
    })


     


  }


   clearAlert() {
    this.alertType = ''
  }


}
