import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';
@Component({
  selector: 'app-dashboard-info-card',
  templateUrl: './dashboard-info-card.component.html',
  styleUrls: ['./dashboard-info-card.component.css']
})
export class DashboardInfoCardComponent {
  address:any[]=[]
  data: any;
   address1:any

  constructor(private pageLoaderService: PageLoaderService, private UserService:UserRegistrationService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.getSessionData()
  }



  // getSessionData() {
  //   let data = sessionStorage.getItem('UserCredential')
  //   if (data)
  //     this.data = JSON.parse(data)
  //   //getUserAddress
  //     this.UserService.getUserAddress({BPnoCAno:this.data.CaNo,BPno:''}).subscribe(response=>{
  //       let data = JSON.parse(JSON.stringify(response)).details
  //       this.address=data
  //       console.log(data)
  //     })
  // }


  //  getSessionData() {
  //   let data = sessionStorage.getItem('UserCredential')
  //   if (data)
  //     this.data = JSON.parse(data)
  //    this.UserService.getUserAddress({BPnoCAno:this.data.CaNo,BPno:''}).subscribe(response=>{
  //       let data2 = JSON.parse(JSON.stringify(response)).details
  //       this.address=data2
  //       console.log(data2)
  //     })
  //     this.UserService.getCaNo({CAno:this.data.CaNo,BPno:''}).subscribe(response=>{
  //       let data1 = JSON.parse(JSON.stringify(response)).details

  //       // this.address=data1
  //       this.address1 = data1;
  //       console.log('this.address',this.address)
  //     })
  // }



   getSessionData() {
    let data = sessionStorage.getItem('UserCredential')
    if (data)
      this.data = JSON.parse(data)
     var ciphertext = crypto.AES.encrypt(this.data.CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     this.UserService.getUserAddressNew({BPnoCAno:ciphertext,BPno:''}).subscribe(response=>{
        let data2 = JSON.parse(JSON.stringify(response)).details
        this.address=data2
        //console.log(data2)
      })
      this.UserService.getCaNoNew({CAno:ciphertext,BPno:''}).subscribe(response=>{
        let data1 = JSON.parse(JSON.stringify(response)).details

        // this.address=data1
        this.address1 = data1;
        //console.log('this.address',this.address)
      })
  }

}
