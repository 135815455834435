<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Reset Password</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page">Reset Password</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->

<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-sm-12">
                <div class="light-blue-bg">
                    <div class="customer-login-page log-meter-reading">
                        <div class="row">
                            <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                
                                <form [formGroup]="UserForm">
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-floating mb-3">
                                                <input type="password" class="form-control"
                                                    formControlName="NewPass" placeholder="NewPass"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['NewPass'].errors }"
                                                    id="NewPass" />
                                                <div *ngIf="submitted && UserForm.controls['NewPass'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['NewPass'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="NewPass">New Password<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-floating mb-3">
                                                <input type="password" class="form-control"
                                                    formControlName="ConfPass" placeholder="ConfPass"
                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['ConfPass'].errors }"
                                                    id="ConfPass" />
                                                <div *ngIf="submitted && UserForm.controls['ConfPass'].errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="UserForm.controls['ConfPass'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="ConfPass">Confirm Password<span style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submit()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="this.UserForm.reset()"
                                                    (click)="this.UserForm.controls.Captcha.reset()"
                                                    class="bluebtn2">Reset <i class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="alertMsg != ''"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder}"
                                        class="card card-inverse-info" id="context-menu-simple">
                                        <div class="card-body">
                                            <p class="card-text">
                                                {{alertMsg}}
                                        </div>
                                    </div>

                                       <p class="text-right mt-20"><b><i>Password has contains At least 8 characters long At least 1 capital letter At least 1 lowercase letter At least 1 special character At least 1 numeric character marked 
                                            are compulsory.</i></b></p>

                                    <p class="text-right mt-20"><b><i>Please note all fields marked <span class="text-danger">*</span>
                                            are compulsory.</i></b></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>