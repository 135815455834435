import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ntzd',
  templateUrl: './ntzd.component.html',
  styleUrls: ['./ntzd.component.css']
})
export class NtzdComponent {
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
	  //https://mgl.ebillz.in/Notice/ZeroConsumptionNotice-Domestic.php?billKey=MHmKfH
      window.location.href = 'https://mgl.ebillz.in/Notice/ZeroConsumptionNotice-Domestic.php?billKey=' + id;
      console.log('Url Id: ', id);
    })
  }
}
