<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
     <h1>Award-Details</h1>   
     <div class="breadcrumbs">
           <ul class="">
            <li class=""><a [routerLink]="['/']">Home</a></li>
            <li class=""><a [routerLink]="['/']">MGL-corporate</a></li>
            <li class="active" aria-current="page"><a >
                Awards</a></li>
             <li class="active" aria-current="page"><a href="#">Award-Details</a></li>
           </ul>
     </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
     <!-- Services Section End -->

 <!-- Inner Page Content Section Start -->
 <div class="rs-inner-blog innerpage-content-section">
 <div class="container custom">
       <div class="row ">
 <div class="col-lg-3 col-md-3 col-sm-12">
     <!-- <div class="widget-area">
     <div class="search-widget mb-50">
                         <div class="search-wrap">
                             <input type="search" placeholder="Searching..." name="s" class="search-input" value="">
                             <button type="submit" value="Search"><i class="flaticon-search"></i></button>
                         </div>
                     </div>
                     </div> -->

 <app-recent-post></app-recent-post>

                     <!-- <div class="blog-cat widget-area">
                     <div class="categories mt-30">
                             <h3 class="">Categories</h3>
                             <a href="#">CNG</a>
                             <a href="#">PNG</a>
                             <a href="#"> Commercial </a>
                             <a href="#">Environment</a>
                             <a href="#">CNG</a>
                             <a href="#">PNG</a>
                             <a href="#"> Commercial </a>
                             <a href="#">Environment</a>
                             <a href="#">CNG</a>
                             <a href="#">PNG</a>
                             <a href="#"> Commercial </a>
                             <a href="#">Environment</a>
                     </div>
                 </div> -->
 </div>

 <div class="col-lg-9 col-md-9 col-sm-12">
   <div class="row">
                     <div class="col-lg-12">
                        <div class="blog-details">
                            <div class="bs-img mb-35">
                                <a><img class="w-100" src="{{baseUrl}}{{Data[0].Image}}" alt=""></a>
                            </div>
                            <div class="blog-full">
                                <ul class="single-post-meta">
                                    <li>
                                        <span class="p-date"><i class="fa fa-calendar-check-o"></i>{{Data[0].Year}}</span>
                                    </li>
                                    <!-- li><span class="p-date"> <i class="fa fa-user-o"></i> admin </span></li>
                                    <li class="Post-cate">
                                        <div class="tag-line">
                                            <i class="fa fa-book"></i>
                                            <a href="#">Strategy</a>
                                        </div>
                                    </li>
                                    <li class="post-comment"> <i class="fa fa-comments-o"></i> 1</li> -->
                                </ul>
                               
                                <h3> {{Data[0].Title}}</h3>
                                <p [innerHTML]="Data[0].Discription"></p>
                               
                               

                               
                                <!-- <div class="comment-note">
                                    <div id="form-messages"></div>
                                    <form id="contact-form" method="post"
                                        action="https://reactheme.com/products/html/bizup/mailer.php">
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-lg-6 mb-35 col-md-6 col-sm-6">
                                                    <input class="from-control" type="text" id="name" name="name"
                                                        placeholder="Name*" required="">
                                                </div>
                                                <div class="col-lg-6 mb-35 col-md-6 col-sm-6">
                                                    <input class="from-control" type="text" id="email" name="email"
                                                        placeholder="E-Mail*" required="">
                                                </div>
                                                <div class="col-lg-12 mb-30">
                                                    <textarea class="from-control" id="message" name="message"
                                                        placeholder="Your message Here" required=""></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <input class="readon post" type="submit" value="Post Comment">
                                            </div>
                                        </fieldset>
                                    </form>
                                </div> -->
                            </div>
                        </div>
                     </div>
                 </div>

 </div>
 </div>
</div>
 <!-- Inner Page Content Section End -->





</div>
<!-- Main content End -->