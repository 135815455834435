import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Oempromotion20Service } from 'src/app/Service/oempromotion20.service';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-oemsnewvehicles20leveltwostatus',
  templateUrl: './oemsnewvehicles20leveltwostatus.component.html',
  styleUrls: ['./oemsnewvehicles20leveltwostatus.component.css']
})
export class Oemsnewvehicles20leveltwostatusComponent {
searchpromotionForm!: FormGroup;
  checkOtpForm!: FormGroup;
  submitted = false;
  OtpSubmitted = false;
  captcha: any;
  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  submitStatus:boolean=false;
  display:string='none';
  Popup:any='';
  stopTimer:boolean=false;
  OtpButtonActive: boolean = false;
  Minute: any = 1;
  Sec: any = 10;
  Otp: any;
  nextPageres:any;
  oemLoginRole:any;
    oemEmail:any;
  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: Oempromotion20Service,
    public router: Router,public UserService:UserRegistrationService,
    private http: HttpClient) { }

  ngOnInit(): void {
     this.UserService.checkLogin2()
     
       let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential2')))
    
     if (data) {
      let oemLogindata = JSON.parse(data);

      let oemData = oemLogindata.details
     this.oemLoginRole = oemData.Role;
     this.oemEmail = oemData.Email;
     //console.log('details data',this.oemLoginRole)
    }
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    
    this.captchaGenerate();

   

    this.searchpromotionForm = this.FormBuilder.group({
      reference_number: ['', [Validators.required]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      otp: [''],
      Captcha: ['', [Validators.required]],

    });

    this.checkOtpForm = this.FormBuilder.group({
      Otp: ['', Validators.required]
    })
  }




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result



  }

  submit(){
    this.submitted = true;
    if (this.searchpromotionForm.get('Captcha')?.value != this.captcha) {
      this.searchpromotionForm.get('Captcha')?.setValue('')
    }
    if (this.searchpromotionForm.invalid) {
      return;
    }
    let obj = {'ContactNumber':this.searchpromotionForm.get('ContactNumber')?.value,
    'refranceNumber':this.searchpromotionForm.get('reference_number')?.value,
    'otp':'','role':this.oemLoginRole}

    this.service.search_Vehicle_OEM_level_two_Application(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         // this.otpTimer()
          //this.OtpButtonActive = true
          this.submitStatus=false
           this.nextPageres = res;
      //  console.log(this.nextPageres);
          this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-level-two-details'],
        {queryParams:{data:JSON.stringify(this.nextPageres)}})
           //this.openPopup();

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'searchPro'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      //console.log(err)
    })

  }


 

  otpTimer() {

    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {
     sec--;
   if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0

        clearInterval(count)
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);

    var test = () => {
      if(this.stopTimer){
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp=''
        this.submitStatus=true
        clearInterval(count)
      }
      this.Minute = minute
      this.Sec = sec
    }
  }

  openPopup(){
    this.Popup='show';
    this.display='block';
    }

  closeModel(){
    this.Popup='';
    this.display='none';
    this.stopTimer=true;
    
  }

  clearAlert() {
    this.alertType = ''
  }

  verifyOtp() {
    this.OtpSubmitted = true
    if (this.checkOtpForm.invalid) {
      return
    }
    this.alertType = 'secSubmit';
     let obj = {'ContactNumber':this.searchpromotionForm.get('ContactNumber')?.value,
    'refranceNumber':this.searchpromotionForm.get('reference_number')?.value,
    'otp':this.checkOtpForm.get('Otp')?.value,'role':this.oemLoginRole}

    this.service.search_Vehicle_OEM_level_two_Application(obj).subscribe(response => {
      //console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.nextPageres = res;
        //console.log(this.nextPageres);

      this.alertMsg = "Otp Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
      // this.ShowGo = true
      setTimeout(() => {
        this.clearAlert()
        // this.ShowOtp = false
        this.closeModel()
         this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-level-two-details'],
        {queryParams:{data:JSON.stringify(this.nextPageres)}})
       //  if(res.details.status=='Rejected'){
       //     this.searchpromotionForm.reset();
       //    this.checkOtpForm.reset();
       //    return alert('Your Application is Rejected')
       // }else{
       //  this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-maruti-suzuki-application-details'],
       //  {queryParams:{data:JSON.stringify(this.nextPageres)}})
       // }
        
       
      }, 1000);
        

      } else {

      this.alertMsg = "Otp Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
       
      }



    }, err => {
      //console.log(err)
    })

  }



  // addPromotion(){
  //   this.router.navigate(['/cng/cng-offers-schemes/savings-ki-shubh-shuruaat'])
  // }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

}


