import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { vehiclepromotionResp } from '../models/vehiclepromotion';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http';
import { AreaResp } from '../models/area';

@Injectable({
  providedIn: 'root'
})
export class VehiclepromotionService {
  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

    // insert_Vehicle_pro_data(data:any) {
    // this.http.post<vehiclepromotionResp>(`${this.baseUrl}applyforoutlet/insert_applyoulet`,data).subscribe((res)=>{
    //  return alert("inserted  successfully")
    //   console.log(res);
      
    // })
      
    //   }

    insert_Vehicle_pro_data(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle-promotion/insertVehicle',data)
    }

    search_Vehicle_pro_data(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle-promotion/searchApplication',data)
    }

    update_Vehicle_pro_data(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle-promotion/updateVehicle',data)
    }

     insert_Vehicle_pro_data1(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_promotionnew/insertVehicle1',data)
    }

      searchnewPromotionApplication(data:any){

      return this.http.post<any>(this.baseUrl+'vehicle_promotionnew/searchnewPromotionApplication',data)
    }

     updateCngpromotion(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_promotionnew/updateCngpromotion',data)
    }

      editmarutiVehicle(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_promotionnew/editmarutiVehicle',data)
    }


     getpromotionReferenceNo(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_promotionnew/getpromotionReferenceNo',data)
    }

    

    insertRetrofitters(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/insertRetrofitters',data)
    }


      search_Retrofitters_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/search_Retrofitters_Application',data)
    }

     search_Level_one_Retrofitters_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/search_Level_one_Retrofitters_Application',data)
    }

      search_Level_two_Retrofitters_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/search_Level_two_Retrofitters_Application',data)
    }

     edit_Retrofitters(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/edit_Retrofitters',data)
    }


      insert_Retrofitters_Level_one(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/insert_Retrofitters_Level_one',data)
    }

     insert_Retrofitters_Level_one_Edit(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/insert_Retrofitters_Level_one_Edit',data)
    }

    insert_Retrofitters_Level_two(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'retrofitters/insert_Retrofitters_Level_two',data)
    }

  
    insert_Vehicle_OEM(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_OEMs_promotion/insert_Vehicle_OEM',data)
    }

    search_Vehicle_OEM_Application(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_OEMs_promotion/search_Vehicle_OEM_Application',data)
    }


     update_Vehicle_OEM(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_OEMs_promotion/update_Vehicle_OEM',data)
    }

     edit_Vehicle_OEM(data:any){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'vehicle_OEMs_promotion/edit_Vehicle_OEM',data)
    }


}


