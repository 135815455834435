<div class="innerpage-banner">
	<img src="assets/images/innerpage-banner/about-banner.jpg">
	<div class="banner-heading">
		<h1>Sitemap</h1>
		<div class="breadcrumbs">
			<ul class="">
				<li class=""><a href="#">Home</a></li>
				<li class="active" aria-current="page">Sitemap</li>
			</ul>
		</div>
	</div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<div class="innerpage-content-section">
	<div class="innerpage-content-section">
		<div class="container custom">

			<div class="sitemap-section">
				<h5>Residential PNG</h5>
				<div class="row">
					<div class="col-md-4">
						<div class="my-20">
							<h6><a href="javascript:void(0)">Customer Zone</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a
											[routerLink]="['/residential-png/customer-care/customer-care-overview']">Overview</a>
									</li>
									<li><a [routerLink]="['/residential-png/customer-zone/customer-login']">Customer
											Login</a></li>
									<li><a
											[routerLink]="['/residential-png/customer-zone/customer-login/edit-profile']">Update
											Your Contact Details </a></li>
									<li><a>Name Transfer</a>
										<!--Sub Sub Sub Menu Start-->
										<ul>
											<li><a [routerLink]="['/residential-png/customer-zone/name-transfer']">Apply
													for Name Transfer </a></li>
											<li><a [routerLink]="['/residential-png/customer-zone/name-transfer/name-transfer-status']">Know your Application Status </a></li>
										</ul>
										<!--Sub Sub Sub Menu End-->
									</li>
									<li><a [routerLink]="['/residential-png/customer-zone/consumer-safty-checklist']">Consumer
											Safety Checklists </a></li>
									<li><a href="https://www.youtube.com/watch?v=BX8OZLb4k18">Conservation tips for
											Domestic usage </a></li>
								</ul>
								<!--Sub Sub Menu End-->
							</div>
							<!--Sub Menu End-->
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<h6><a href="javascript:void(0)">Domestic Connection Request</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a href="#">Domestic PNG Registration</a>
										<!--Sub Sub Sub Menu Start -->
										<ul>
											<li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']">Apply For PNG Connection</a></li>
											<li><a [routerLink]="['/residential-png/customer-zone/domestic-connection']">New PNG Connection Request </a></li>
											<li><a >Balance Payment Option </a></li>
											<li><a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check']">Connection Application Status </a></li>
											
										</ul>
										<!--Sub Sub Sub Menu End-->
									</li>
									<li><a
											[routerLink]="['/residential-png/domestic-connection-tequest/png-rate-card']">PNG
											Rate card</a></li>
									<li><a
											[routerLink]="['/residential-png/domestic-connection-request/tech-guide-for-png-installation']">Technical
											Guidelines for PNG Installation</a></li>
									<li><a href="https://mahanagargas.com:3000/regitration-form-terms-and-condition_0d27e650ee.pdf">Terms & Condition of Domestic Users</a></li>
									<li><a
										[routerLink]="['/residential-png/domestic-connection-request/png-network']">PNG Network
									</a></li>
								</ul>
								<!--Sub Sub Menu End-->
							</div>
							<!--Sub Menu End-->
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<h6><a [routerLink]="['/residential-png/view-authorized-personnel']">View Authorized Personnel</a></h6>

							<!--Sub Menu Start-->
							

							<!--Sub Menu End-->

							<!--Sub Menu End-->

						</div>
					</div>
					<div class="col-md-4">
						<div class="my-20">
							<h6><a href="#">Gas Geysers</a></h6>
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a target="_blank" href="{{baseUrl}}Gas-geyser-Guidelines-Annexure-I.pdf">Guidelines</a> </li>
									<li><a [routerLink]="['/residential-png/gas-geysers/rate-card']">Rate card</a></li>
								</ul>
								<!--Sub Sub Menu End-->
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="my-20">
							<h6><a href="#">Policies & Procedures</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a [routerLink]="['/residential-png/policies-and-procedures/important-policy']">Important
											Policies</a> </li>
									<li><a href="#">Aftersales Services</a>
										<ul>
											<li><a
													[routerLink]="['/residential-png/policies-and-procedures/aftersales-services/png-service-charges']">Rate
													Card</a> </li>
											<li><a
													[routerLink]="['/residential-png/policies-and-procedures/aftersales-services/service-provider']">Service
													provider's list</a></li>
											<!-- <li><a
													[routerLink]="['/residential-png/policies-and-procedures/aftersales-services/service-provider']">Service
													Refund Policy</a></li> -->
										</ul>

									</li>
								</ul>
								<!--Sub Sub Menu End-->
							</div>
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<h6><a [routerLink]="['/residential-png/if-you-smell-gas']">If You Smell Gas</a></h6>
						</div>
					</div>
					<div class="col-md-4">
						<div class="my-20">
							<h6><a href="javascript:void(0)">Customer Support</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a [routerLink]="['/residential-png/customer-care/walk-in-center']">Walk in
											Centers</a> </li>
									<!--<li><a [routerLink]="['/residential-png/customer-care/customer-support']">Customer
											Support</a></li>-->
									<li><a
											[routerLink]="['/residential-png/customer-care/feedback-inquiry-form']">Feedback/Enquiry</a>
									</li>
									<li><a [routerLink]="['/residential-png/customer-care/dropbox']">Dropbox</a></li>
								</ul>
								<!--Sub Sub Menu End-->
							</div>
							<!--Sub Menu End-->
						</div>
					</div>
					<div class="col-md-4">
						<div class="my-20">
							<h6><a [routerLink]="['/faq-list']">FAQ</a></h6>
							

						</div>
					</div>
					<div class="col-md-4">
						<div class="my-20">
					<h6><a href="#">Download Forms</a></h6>
					<div class="sitemap-sublinks">
					<ul>
						<li><a
								href="https://www.mahanagargas.com/assets/images/pdf/Registeration%20Form-%20Normal%20(1).pdf">Registration Form</a> </li>
						<li><a
								href="https://www.mahanagargas.com/assets/images/pdf/Transfer%20Form%20(1).pdf">Name Transfer Form</a></li>
						<!-- <li><a
								[routerLink]="['/residential-png/policies-and-procedures/aftersales-services/service-provider']">Service
								Refund Policy</a></li> -->
					</ul>
				</div>
					</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
					<h6><a [routerLink]="['/view-your-bill']">View Your Bill</a></h6>
					
					</div>
					</div>
				</div>
			</div>

			<div class="sitemap-section">
				<h5>CNG</h5>
				<div class="row">

					<div class="col-md-4">
						<div class="my-20">
							<h6><a [routerLink]="['/cng/overview/overview-enviro-benifits']">Overview</a></h6>
							<h6><a href="javascript:void(0)">Customer Zone</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>


									<!--Sub Sub Sub Menu Start-->
									<ul>
										<li><a [routerLink]="['/cng/customer-zone/cng-rate-card']">CNG Rate Card</a>
										</li>
										<li><a [routerLink]="['/cng/customer-zone/saving-calculator']">Saving
												Calculator </a></li>
										<li><a href="#">CNG Locator </a></li>
										<li><a [routerLink]="['/cng/customer-zone/car-care']">Car Maintenance </a>
										</li>
										<li><a [routerLink]="['/cng/customer-zone/cng-testing-center']">Cylinders
												Testing Station </a></li>
										<li><a [routerLink]="['/cng/customer-zone/cng-kit']">CNG Kit Supplier </a>
										</li>
										<li><a [routerLink]="['/faq-list']">FAQs </a></li>
									</ul>
									<!--Sub Sub Sub Menu End-->


								</ul>
								<!--Sub Sub Menu End-->
							</div>
							<!--Sub Menu End-->
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<h6><a [routerLink]="['/cng/cng-offers-schemes/cng-up-report']">CNG Offers/Schemes</a></h6>
							<h6><a href="javascript:void(0)">Dealer Zone</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a href="#">Login For Existing Dealers</a>
										<ul>
											<li><a>Feedback</a>
													<ul>
														<li><a [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/give-feedback']">Give Feedback</a></li>
														<li><a [routerLink]="['/cng/dealer-zone/login-for-existing-dealers/cng-view-complaint-status']">Complaint Status</a></li>
													</ul>
												</li>
										</ul>
									</li>
									<li><a [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">Apply for New CNG
											Retail Outlets </a></li>
									<li><a [routerLink]="['/cng/dealer-zone/mobile-unit']">Composite Dispensing Unit
										</a></li>
									<li><a target="_blank" href="{{baseUrl}}retail-policy.pdf">Retail Policy</a></li>
									<li><a [routerLink]="['/cng/dealer-zone/reqmt-open-slot']">Requirement of open plot
											- GA3</a></li>
								</ul>
							</div>

						</div>
					</div>
					<div class="col-md-4">
						<div class="my-20">
							<h6><a [routerLink]="['/cng/customer-zone/cng-conversion-enquiry']">Conversion Enquiry Form</a></h6>
						</div>
					</div>
				</div>
			</div>

			<div class="sitemap-section">
				<h5>INDUSTRIAL & COMMERCIAL</h5>
				<div class="row">
					<div class="col-md-4">
						<div class="my-20">
							<h6><a href="javascript:void(0)">Commercial PNG</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a [routerLink]="['/cng/overview/overview-enviro-benifits']">Overview</a>
										<ul>
											<li><a [routerLink]="['/commercial-png-application']">Applications</a>
											</li>
											<li><a
													[routerLink]="['/industrial-and-commercial/commercial-png/overview/benefits']">Benefits</a>
											</li>

										</ul>

									</li>
									<li><a href="#">Apply For Commercial Connection</a>
										<!--Sub Sub Sub Menu Start-->
										<ul>
											<li><a
													[routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply
													for New Connection</a>
											</li>
											<li><a
													[routerLink]="['/industrial-and-commercial/Commercial-png/apply-for-commercial-connection/how-to-apply']">How
													to apply</a></li>
											<li><a
													[routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/document']">Documents
													Required</a></li>
											<li><a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a></li>
										</ul>
										<!--Sub Sub Sub Menu End-->
									</li>

									<li><a
											[routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply
											for Commercial</a>
									</li>

									<li><a [routerLink]="['/faq-list']">FAQs</a></li>

								</ul>
								<!--Sub Sub Menu End-->
							</div>
							<!--Sub Menu End-->
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<!-- <h6><a [routerLink]="['/cng/cng-offers-schemes/cng-up-report']">CNG Offers/Schemes</a></h6> -->
							<h6><a href="javascript:void(0)">Industrial PNG</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a href="#">Overview</a>
										<ul>
											<li><a
													[routerLink]="['/industrial-and-commercial/industrial-png/overview/types-of-industries']">Type
													of Industries</a></li>
										</ul>
									</li>
									<li><a href="#">Apply For Industrial Connection</a>
										<ul>
											<li><a
													[routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/apply-for-new-connection']">Apply
													For New Connection</a></li>
											<li><a
													[routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/how-to-apply-ind']">How
													to apply</a></li>
											<li><a
													[routerLink]="['/industrial-and-commercial/industrial-png/apply-for-commercial-connection/ind-document']">Documents
													Required</a></li>
											<li><a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a>
									</li>
										</ul>
									</li>
									<li><a href="assets/images/pdf/_web-host-tariff-card-i-c_2dcbdf35e4.pdf" target="_Blank">Tariff Card
										</a></li>
									<li><a
											[routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment</a>
									</li>
									<li><a href="https://www.youtube.com/watch?v=GhPW-HEVcGQ">Conservation Tips For
											Industrial Usage</a></li>
									<li><a [routerLink]="['/faq-list']">FAQs</a></li>
								</ul>
							</div>
							<!-- <h6><a [routerLink]="['/faq-list']">FAQs</a></h6> -->
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<h6><a
									[routerLink]="['/industrial-and-commercial/conservation-tips-for-industrial-usage/clien-tele']">Clientele</a>
							</h6>
							<h6><a [routerLink]="['/user-dashboard']">View Your Bill</a></h6>
							<h6><a href="">Contact Us</a></h6>
						</div>
					</div>
				</div>
			</div>

			<div class="sitemap-section">
				<h5>MGL CORPORATE</h5>
				<div class="row">
					<div class="col-md-4">
						<div class="my-20">
							<h6> <a [routerLink]="['/MGL-corporate/about-MGL']">About MGL</a></h6>

							<h6> <a [routerLink]="['/MGL-corporate/leadership']">Leadership</a></h6>

							<h6><a href="javascript:void(0)">Contracts & Procurements</a></h6>
							<!--Sub Menu Start-->
							<div class="sitemap-sublinks">
								<!--Sub Sub Menu Start-->
								<ul>
									<li><a>Tenders</a>
										<ul>
											<li><a
													[routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/active-tender']">Active-tender</a>
											</li>
											<li><a>E-Tender (SAP-SRM)</a></li>
											<li><a
													[routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/empanelment-notice']">Empanelment
													Notice</a></li>
											<li><a>Archives</a>
												<ul>
													<li><a>Tenders</a></li>
													<li><a
															[routerLink]="['/MGL-corporate/contracts-and-procurements/tenders/archives/empanelment']">Empanelment</a>
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li><a href="#">MGL Statutory Details</a>
										<!--Sub Sub Sub Menu Start-->
										<ul>
											<li><a target="_blank" href="assets/images/pdf/MGL GST REGISTRTAION CERTIFICATE_.pdf">GST</a>
											</li>
											<li><a target="_blank" href="assets/images/pdf/MGL PAN CARD SCANNED COPY.pdf">PAN</a></li>
											<li><a target="_blank" href="assets/images/pdf/MGL Bank AC Details.pdf">Bank A/C Details</a></li>
										</ul>
										<!--Sub Sub Sub Menu End-->
									</li>

									<li><a href="#">Important T&C Of Tenders</a>
										<!--Sub Sub Sub Menu Start-->
										<ul>
											<li><a>General Conditions Of Contract</a>
												<ul>
													<li><a target="_blank" href="assets/images/pdf/GCC  for Works.pdf">Works</a>
													</li>
													<li><a target="_blank" href="assets/images/pdf/GCC FOR CONSUTANCY SERVICES.pdf">Goods</a></li>
													<li><a target="_blank" href="assets/images/pdf/GCC for Materials.pdf">Consultancy</a></li>

												</ul>
											</li>
											<li><a
													[routerLink]="['/MGL-corporate/contracts-and-procurements/important-T&C-of-tenders/instruction-to-bidders']">Instruction
													to Bidders</a></li>
											<li><a target="_blank" href="assets/images/pdf/Code of Ethics.pdf">Code of Ethics</a></li>

										</ul>
										<!--Sub Sub Sub Menu End-->
									</li>

									<li><a>5 Years Annual Procurements Plan- Materials</a>
									</li>

									<li><a>Downloads (Forms/Formats)</a>
										<ul>
											<li><a>EMD Format</a></li>
											<li><a
													[routerLink]="['/MGL-corporate/contracts-and-procurements/downloads/security-deposit']">Security
													Deposit Format</a>
												<ul>
													<li><a>Works</a>
													</li>
													<li><a>Materials</a></li>
												</ul>
											</li>
											<li><a>Vender Registration Form</a></li>
											<li><a>Online Payment Form</a></li>

											<li><a>Mobiliztion Advance Format</a></li>
											<li><a>Non Disclosure Agreement</a></li>
											<li><a>Bid Security Declaration Form</a></li>

										</ul>
									</li>

									<li><a>Inventory Management</a>
										<ul>
											<li><a>Scrap Disposal</a>
											</li>
											<li><a>List Of Surplus Items Available For Sale CGD/OEMs</a></li>
										</ul>
									</li>
									<li><a
											[routerLink]="['/MGL-corporate/contracts-and-procurements/bidders-of-holiday']">Bidders
											Of Holiday</a></li>
									<li><a>Others</a></li>
								</ul>
								<!--Sub Sub Menu End-->
							</div>
							<!--Sub Menu End-->
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<!-- <h6><a [routerLink]="['/cng/cng-offers-schemes/cng-up-report']">CNG Offers/Schemes</a></h6> -->
							<h6><a href="javascript:void(0)">Investors</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a href="#">Financial Results</a>
										<ul>
											<li><a
													[routerLink]="['/MGL-corporate/investors/financial-results/quarterly-result']">Quarterly
													Results</a></li>
											<li><a
													[routerLink]="['/MGL-corporate/investors/financial-results/audited-result']">Audited
													Report</a></li>
										</ul>
									</li>
									<li><a [routerLink]="['/MGL-corporate/investors/annual-report']">Annual Report</a>
									</li>
									<li><a [routerLink]="['/MGL-corporate/investors/annual-general-meeting']">Annual
											General Meeting
										</a></li>
									<li><a [routerLink]="['/MGL-corporate/investors/corporate-governance']">Corporate
											Governance</a></li>
									<li><a [routerLink]="['/MGL-corporate/investors/policies']">Policies</a></li>
									<li><a [routerLink]="['/MGL-corporate/investors/investor-guide']">Investor Guide</a>
									</li>

									<li><a>Dividend</a>
										<ul>
											<li><a
													[routerLink]="['/MGL-corporate/investors/dividend/unclaimed-dividend']">Unclaimed
													Dividend</a></li>
											<li><a [routerLink]="['/MGL-corporate/investors/dividend/dividend-policy']">Dividend
													Policy</a></li>
											<!--<li><a>Dividend Declaration</a></li>-->
										</ul>
									</li>

									<li><a [routerLink]="['/MGL-corporate/investors/investor-update-and-presentation']">Investor
											Updates & Presentation</a></li>
									<li><a [routerLink]="['/MGL-corporate/investors/shareholding-pattern']">Shareholding
											Pattern</a></li>
									<li><a [routerLink]="['/MGL-corporate/investors/other-filings-with-stock-exchanges']">Other Filings With Stock Exchanges</a></li>
								</ul>
							</div>

							<h6><a href="javascript:void(0)">CSR</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a [routerLink]="['/MGL-corporate/csr/csr-policy']">CSR Policies</a>
									<li><a [routerLink]="['/MGL-corporate/csr/csr-initiative']">CSR
											Initiatives</a>
								</ul>
							</div>
							<!-- <div class="col-md-4">
								<div class="my-20">
							<h6 href="javascript:void(0)">CSR</h6>
							
							</div>
							</div> -->


							<h6> <a href="javascript:void(0)"> Safety, Health And Environment</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a>Policies</a>
										<ul>
											<li><a
													[routerLink]="['/MGL-corporate/safety-health-and-environment/policies/hse-policy']">MGL
													HSE Policy</a>
											<li><a
													[routerLink]="['/MGL-corporate/safety-health-and-environment/policies/mgl-quality-policy']">MGL
													Quality Policy</a>
										</ul>
									</li>
									<li><a
											[routerLink]="['/MGL-corporate/safety-health-and-environment/environmental-compliance']">Environmental
											Compliance</a></li>
									<li><a
											[routerLink]="['/MGL-corporate/safety-health-and-environment/environmental-compliance']">Safety
											& Sustainability</a>
										<ul>
											<li><a
													[routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/our-approach-to-sustainability']">Our
													Approach to Sustainability</a>
											<li><a
													[routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/safety-as-core-value']">Safety
													As Core Value</a>
											<li><a
													[routerLink]="['/MGL-corporate/safety-health-and-environment/safety-and-sustainability/mgl-spirit']">MGL
													Spirit statement</a>
										</ul>
									</li>
									<li><a [routerLink]="['/MGL-corporate/safety-health-and-environment/stc-training']">Safety
											and Technical Competency (STC) Training</a>
									<li><a
											[routerLink]="['/MGL-corporate/safety-health-and-environment/safety-feature']">Safety
											Features</a>
									<li><a
											[routerLink]="['/MGL-corporate/safety-health-and-environment/mgl-life-savers']">MGL
											Life Saving Rules</a>
									<li><a
											[routerLink]="['/MGL-corporate/safety-health-and-environment/natural-gas-data-sheet']">Natural
											Gas: Material Safety Data Sheet</a>
								</ul>
							</div>
							<h6><a [routerLink]="['/MGL-corporate/asset-integrity']">Asset Integrity</a></h6>
						</div>
					</div>

					<div class="col-md-4">
						<div class="my-20">
							<!-- <h6><a [routerLink]="['/cng/cng-offers-schemes/cng-up-report']">CNG Offers/Schemes</a></h6> -->
							<h6><a href="javascript:void(0)">Careers</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a [routerLink]="['/MGL-corporate/career/life-Mgl']">Life @ MGL</a>
									</li>
									<li><a [routerLink]="['/MGL-corporate/career/current-opp']">Current
											Opportunities</a>
									</li>
								</ul>
							</div>
							<h6><a [routerLink]="['/MGL-corporate/awards']">Awards</a></h6>

							<h6><a href="javascript:void(0)">Media</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a [routerLink]="['/MGL-corporate/media/news']">News</a>
									</li>
									<li><a>Gallery</a>
										<ul>
											<li><a [routerLink]="['/MGL-corporate/media/gallery/images']">Images</a>
											</li>
											<li><a [routerLink]="['/MGL-corporate/media/gallery/video-gallary']">Videos</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>

							<h6><a href="javascript:void(0)">Contact Us</a></h6>
							<div class="sitemap-sublinks">
								<ul>
									<li><a [routerLink]="['/MGL-corporate/Contact-Us/registered-and-corporate-office-address']">Registered
											& Corporate Office Address</a>
									</li>
								</ul>
							</div>

							<div class="col-md-20">
								<div class="my-20">
									<h6> <a [routerLink]="['/MGL-corporate/MGL-sahayogi']">MGL Sahayogi- Dial before
											Dig</a></h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>