import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-types-of-industries',
  templateUrl: './types-of-industries.component.html',
  styleUrls: ['./types-of-industries.component.css']
})
export class TypesOfIndustriesComponent implements OnInit {

  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
