import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validator, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.css']
})
export class CustomerLoginComponent implements OnInit {

  dialog: any = false

  status: any = 'collapse'

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'
  loading = false;

  UserForm !: FormGroup

  OtpForm = new FormGroup({
    originalOtp: new FormControl(''),
    InputOtp: new FormControl('')
  })

  allFaq: any[] = []

  otp: any

  submitted: boolean = false

  captcha:any

  constructor(public translate: TranslateService, private FormBuilder: FormBuilder, public FaqService: FaqServiceService, private service: UserRegistrationService, private router: Router, private pageLoaderService: PageLoaderService) {
    translate.addLangs(['en', 'hi']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.captchaGenerate()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.checkLogin()

    this.UserForm = this.FormBuilder.group({
      BPnoCAno: ['', Validators.required],
      Captcha:['',Validators.required],
      Otp: [''],
      Password: ['', Validators.required],
    });


    this.getAllFaq()
  }

  captchaGenerate() {
    let length = 4
    let result = '';
    // const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

  

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  checkLogin() {
    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    if (data) {
		this.router.navigateByUrl('/residential-png/customer-zone/customer-login', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/user-dashboard']);
		}); 
    }
  }

  getAllFaq() {
    this.FaqService.getAllFaq().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
     // console.log(data)
      this.allFaq = data
    })
  }

  openDialog() {
    if (this.dialog === false) {
      this.dialog = true
    } else {
      this.dialog = false
    }


  }

  expireOtp() {
    setTimeout(() => {
      this.otp = ''
     // console.log("expired")
    }, 10000)
  }

  verify() {

    this.UserForm.get('Otp')?.setValue(this.OtpForm.value.InputOtp)



    this.service.checkOtp(this.UserForm.value).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))

      if (data === 'Sucessfully Logged In') {
        this.router.navigate(['/user-dashboard'])
        setTimeout(() => {
          this.dialog = false
          this.OtpForm.reset()
        }, 500)
        return alert(data)
      }
      setTimeout(() => {
        this.dialog = false
        this.OtpForm.reset()
      }, 500)
      return alert(data)
    })

  }

  

  clearAlert() {
    this.alertType = ''
  }



  submit() {
    
    if(this.UserForm.get('Captcha')?.value != this.captcha){
      this.UserForm.get('Captcha')?.setValue('')
    }
    this.captchaGenerate()

    this.submitted = true
    this.loading = true;
    if (this.UserForm.invalid) {
	this.loading = false;
      return;
    }
    
    // this.openDialog()
    // this.captchaGenerate()
    // this.UserForm.get('Otp')?.setValue(this.otp)
    //console.log(this.UserForm.value)

    let BPnoCAnostring = this.UserForm.get('BPnoCAno')?.value;
    let BPnoCAnostringnum = BPnoCAnostring.toString()

     var  convertBPnoCAno = crypto.AES.encrypt(BPnoCAnostringnum, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
     var  convertPassword = crypto.AES.encrypt(this.UserForm.get('Password')?.value, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();


    let obj = {"BPnoCAno":convertBPnoCAno,"Password":convertPassword}
  //  console.log(obj)
    this.service.login(obj).subscribe(response => {
     // console.log(response)
      let res = JSON.parse(JSON.stringify(response)).message
      if (res === 'Invalid Crediential' || res === 'Your Account Is Blocked') {
        this.alertType='login'
		if (res === 'Invalid Crediential') {
			this.alertMsg = "BP Number OR CA Number OR Password is invalid.";
		} else if (res === 'Your Account Is Blocked') {
			this.alertMsg = "Your Account Is Blocked/Inactive. Please contact MGL";
		} else {
			this.alertMsg = "You are not register on our website. Please Sign Up";
		}
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        setTimeout(() => {
          this.clearAlert()
          this.loading = false;
        }, 5000);

      } else {
        sessionStorage.setItem('UserCredential', JSON.stringify(response))
        
        this.service.loginStatus = true
        this.alertType='login'
        this.alertMsg = "Welcome To MGL"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {//lumiq
		  this.loading = false;
          this.clearAlert()
          this.router.navigate(['/user-dashboard'])
          this.service.loggedIn = true
        }, 1000);


      }

    }, err => {
	this.loading = false;
      //console.log(err)
    })

  }



  checkppLogin(){
    //const { Name, BpNo, CaNo, MobileNo, Password, Email,Otp } = req.body

        let obj = {"Name":"adsadlja","BpNo":1100274654,"CaNo":210000007924,"MobileNo":9657136379,"Password":"123456","Email":"chetan.hoh@gmail.com"}
  //  console.log(obj)
    this.service.app_user_reg(obj).subscribe(response => {
     // console.log(response)
  

    }, err => {
 
     // console.log(err)
    })

  }

}
