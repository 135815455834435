import { Component } from '@angular/core';
import { BlogarticleService } from 'src/app/Service/blogarticle.service';
import { ActivatedRoute } from '@angular/router';
import { BlogItem } from 'src/app/models/blogs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bloglists',
  templateUrl: './bloglists.component.html',
  styleUrls: ['./bloglists.component.css']
})
export class BloglistsComponent {
  public base = environment.baseUrl

  blogdata:BlogItem []=[]
  id: any;

  constructor(private route:ActivatedRoute,private service : BlogarticleService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.id=id
    this.get();  
  }

  get() {
    this.service.getAll().subscribe((response) => {
      var data = JSON.parse(JSON.stringify(response));
      this.blogdata = data
      //console.log(this.blogdata)
      })
     
    }
  }
 





