<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>List of MGL CNG Stations accepting MGL Fuel Card</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav 2.0</a></li>
                <li class="active" aria-current="page"><a>List of MGL CNG Stations accepting MGL Fuel Card</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
               <app-insta-post></app-insta-post>
             
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <p class="mb-10">List of MGL CNG Stations accepting MGL Fuel Card is as follows:</p>
                    

                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>Name of CNG RO</th>
                                    <th>Address</th>
                                    <th>Area</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listofArray">
                                    <td>{{item.id}}</td>
                                    <td>{{item.Name_of_CNG_RO}}</td>
                                    <td>{{item.address}}</td>
                                    <td>{{item.area}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>