import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {

  constructor() { }

  private loading$: Subject<boolean> = new BehaviorSubject(false);

  show(){
    this.loading$.next(true);
  }

  hide(){
    this.loading$.next(false);
  }

  get state(){
    return this.loading$.asObservable();
  }
}
