import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-serviceprovider',
  templateUrl: './serviceprovider.component.html',
  styleUrls: ['./serviceprovider.component.css']
})
export class ServiceproviderComponent {
    UserForm!: FormGroup;
    UserForm1!: FormGroup;
    MaxNaturalActive: boolean = false;
    FIXOFITActive: boolean = false;
    VVPRASADActive: boolean = false;
    SAHEDULActive: boolean = false;
    AARTIENTERPRISESActive: boolean = false;
    RISHIINFRAActive: boolean = false;
    SAMRUDDHIActive: boolean = false;
    SHRIENGINEERSActive: boolean = false;
    RATHODBROTHERSActive: boolean = false;

     NAMEOFSERVICEActive: boolean = false;
    NAMEOFOWNERActive: boolean = false;
    CONTACTOFOWNERActive: boolean = false;
    DRSAREAActive: boolean = false;
    DRSNOSActive: boolean = false;
    serviceProArr: any[] = [];
     keyword = "name";
     userLocation:any;
     userLocationdata: boolean = false;
     serviceProArr1: any[] = [];
       keyword1 = "name";
     userLocation1:any;
     userLocationdata1: boolean = false;


     firstActive: boolean = false;
     secActive: boolean = false;
     thirdActive: boolean = false;
     fourthActive: boolean = false;
     fifthActive: boolean = false;
     sixActive: boolean = false;
     sevenActive: boolean = false;
     eightActive: boolean = false;
     nineActive: boolean = false;
     tenActive: boolean = false;
     elevenActive: boolean = false;
     twelveActive: boolean = false;
     thirteenActive: boolean = false;
     fourteenActive: boolean = false;
     fifteenActive: boolean = false;
     sixteenActive: boolean = false;
     seventeenActive: boolean = false;
     eighteenActive: boolean = false;
     nineteenActive: boolean = false;
     twentyActive: boolean = false;
     twentyoneActive: boolean = false;
     twentytwoActive: boolean = false;
     twentythreeActive: boolean = false;
      twentyfourActive: boolean = false;

  constructor(private pageLoaderService: PageLoaderService,private FormBuilder: FormBuilder,) { }

  ngOnInit(): void {
     this.UserForm = this.FormBuilder.group({
     tpye_of_request: ['', Validators.required],
     })
       this.UserForm1 = this.FormBuilder.group({
     tpye_of_request1: ['', Validators.required],
     })
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    // this.UserForm.get('tpye_of_request')?.setValue('MAX NATURAL GAS.PVT.LTD')
    // this.MaxNaturalActive = true;
     // this.UserForm1.get('tpye_of_request1')?.setValue('NAME OF SERVICE PROVIDER')
     // this.NAMEOFSERVICEActive = true;

     this.serviceProArr=[
     {name:"MAX NATURAL GAS.PVT.LTD"},
     {name:"FIXOFIT-WELL"},
     {name:"V.V.PRASAD"},
     {name:"SAHEDUL SHEIKH"},
     {name:"AARTI ENTERPRISES"},
     {name:"RISHI INFRA"},
      {name:"SAMRUDDHI EXPRESS"},
      // {name:"SHRI ENGINEERS"},
      {name:"RATHOD BROTHERS"}
     ];

     // this.serviceProArr=[
     // {name:"Chembur,Chunabhatti"},
     // {name:"Chembur, Deonar,"},
     //  {name:"Ghatkopar (E), Tilak Nagar, Pestom Sagar"},
     // {name:"Kurla (W)"},
     // {name:"Ghatkopar (W)"},
     // {name:"Vikhroli (E) & (W)"},
     // {name:"Mulund (W)"},
     //  {name:"Mulund (E)"},
     //  {name:"Bhandup (W), Kanjur Marg (W"},
     //  {name:"Bhandup (E), Kanjur Marg (E)"}
     // ];

     // this.serviceProArr1=[{name:"NAME OF SERVICE PROVIDER"},
     // {name:"NAME OF OWNER"},
     // {name:"CONTACT OF OWNER"},
     // {name:"DRS AREA"},
     // {name:"DRS NOS"}
     // ]


     //  this.serviceProArr1=[
     //  {name:"015B Goregaon (W)"},
     // {name:"015A Goregaon (E)"},
     // {name:"024A Borivali (W) & Part of Dahisar (W), 024B Dahisar (W)"},
     // {name:"014 Juhu, Andheri (W), 027 Santacruz (W)"},
     // {name:"0032 Maratha Mandir, 0034 Parel, 025A Mahim to Dadar (W), 025B Mahim to Dadar (W), 030 Worli, 031 Tardeo, 033 Mantralaya"},
     // {name:"011 Andheri (E)"},
     // {name:"Bhandup, Mulund (E ), Mulund (W)"},
     // {name:"013 Andheri (W)"},
     // {name:"Kamani, Postal Colony, Suman Nagar,Taxi-men Colony, Kurla(E & W),Pratiksha Nagar, Sion (E) to Dadar (E ),Sion west,Wadala,Sindhi society,Chunabhatti,Some parts of Chembur Naka"},
     // {name:"Deonar, Govandi, Mahul, Collector colony, Some parts of Chembur naka"},
     // {name:"0119 Sanpada , 0122 Rabale, 0124 Mahape , 0125 Turbhe, 023A Borlivali(W) , 023B Borlivali (W) , 105 Thane (W) , 106 Thane (W) , 107 Thane (W) , 108 Thane (W) , 109 Thane (W) , 116A&B  Airoli ,117A &B Koparkhairane, 118A&B Vashi, 135A Ambernath (E) , 136A Ambernath (E) Additional MIDC , 137A Ambernath(W) CGS , 139A Shahad , 140A Kalyan (W) , 141A Kalyan (W) , 142A Badlapur (W) , 143A Badlapur (E) , 146A Kalyan (E) , 147A MIDC Dombivali , 148A Dombivali (E) , 148B Dombivali (E) , 149A MIDC Dombivali , 151A Dombivali (W),114A Juinagar (W), 120A Nerul , 120B Nerul, 115A Seawoods (W), 121A&B Belapur, 123A Kharghar, 123B Kharghar, 131A Kharghar, 129A Kalamboli , 130A Kamothe (Mansarovar) , 132A MIDC, Taloja, 126A Taloja MIDC, 127A New Panvel , 128A Old Panvel, Ulwe"},
     // {name:"016C Kandivali (W), 016B Malad (W)"},
     // {name:"100A Kashigaon, Miraroad (E), 101 Mira Road (E), 102 Mira Road, 103 Bhayandar (E), 104 Bhayandar (W)"},
     // {name:"022A Borlivali (E), 022B Borlivali (E),016A Malad (E&W)"},
     // {name:"008 Sher-e-Punjab, Andheri(E)"},
     // {name:"03 Pestom Sagar,Tilak nagar,mankhurd"},
     // {name:"017 Kandivali (E), 037A -Charkop, Mumbai"},
     // {name:"012 Andheri-(W),Jogeshwari west"},
     // {name:"009 Kala Nagar, Bandra (E),018A Bandra (W),018B Bandra & Khar (W ),026 Santacruz (E),028 Bandra Kurla Complex"},
     // {name:"007 Pant Nagar, Ghatkopar , 010 Kanjur Marg(W),021 Godrej"},
     // {name:"005 Sakinaka (Inside Metro Hotel), 006 Hotel Leela"},
     // {name:"301A Uran"},
     // {name:"310A Fortune city Panvel,401A India Bulls,308A Pen"},
     // ]


        this.serviceProArr1=[
      {name:"016B Malad (W),016C Kandivali (W)"},
     {name:"014 Juhu, Andheri (W),027 Santacruz (W)"},
     {name:"002 Postal Colony, Chembur"},
     {name:"030 Worli,031 Tardeo,0034 Parel,025A Mahim to Dadar (W),025B Mahim to Dadar (W)"},
     {name:"013 Andheri (W)"},
     {name:"016A Malad (E&W),022A Borlivali (E),022B Borlivali (E )"},
     {name:"003 Pestom Sagar, Chembur"},
     {name:"017 Kandivali (E),037A -Charkop, Mumbai"},
     {name:"029 Sion (E) to Dadar (E),0032 Maratha Mandir,033 Mantralaya"},
     {name:"008 Sher-e-Punjab, Andheri(E)"},
     {name:"024A Borivali (W) & Part of Dahisar (W),024B Dahisar (W)"},
     {name:"019 Mulund(W),020 Bhandup,019A Mulund (E)"},
     {name:"007 Pant Nagar, Ghatkopar,010 Kanjur Marg(W),021 Godrej"},
     {name:"011 Andheri (E)"},
     {name:"100A Kashigaon, Miraroad (E), 101 Mira Road (E), 102 Mira Road, 103 Bhayandar (E), 104 Bhayandar (W)"},
     {name:"005 Sakinaka (Inside Metro Hotel),006 Hotel Leela"},
     {name:"023A&B Borlivali(W),105 Thane (W),106 Thane (W),107 Thane (W),108 Thane (W),109 Thane (W)"},
     {name:"015A Goregaon (E)"},
     // {name:"301A Raigadh"},
     {name:"012 Andheri-(W)"},
     {name:"009 Kala Nagar, Bandra (E),018A Bandra (W),018B Bandra & Khar (W ),026 Santacruz (E),028 Bandra Kurla Complex"},
     {name:"001 Suman Nagar, Chembur,004 Kamani, Kurla,0040 Navi Nagar,0042 Taxi-men Colony, Kurla(W),035A Bhakti Park, Wadala,036A - Pratiksha Nagar, Mumbai"},
     {name:"015B Goregaon (W)"},
     // {name:"116A  Airoli, Navi Mumbai,116B  Airoli, Navi Mumbai,117B  Koparkhairane, Navi Mumbai,117A  Koparkhairane, Navi Mumbai,118A Vashi, Navi Mumbai,0119 Sanpada, Navi Mumbai,0122 Rabale, Navi Mumbai,0124 Mahape, Navi Mumbai,0125 Turbhe, Navi Mumbai,114A Juinagar (W), Nerul (W),115A Seawoods (W),118B Navi Mumbai (Sanpada),120A Nerul, Navi Mumbai,120B Nerul, Navi Mumbai,121A Belapur, Navi Mumbai,121B Belapur, Navi Mumbai,123A Kharghar, Navi Mumbai,123B Kharghar, Navi Mumbai,126A Taloja MIDC, Navi Mumbai,127A New Panvel,128A Old Panvel,129A Kalamboli,130A Kamothe (Mansarovar),131A KHARGHAR,132A MIDC,Taloja, Navi Mumbai,135A Ambernath (E),136A Ambernath (E) Additional MIDC,137A Ambernath(W) CGS,139A Shahad,140A Kalyan (W),141A Kalyan (W),142A Badlapur (W),143A Badlapur (E),146A Kalyan (E),147A MIDC Dombivali,148A Dombivali (E),148B Dombivali (E),149A MIDC Dombivali,151A Dombivali (W),302B Ulwe,302A Ulwe,128B KARANJADE,133A Taloja,144A BHIWANDI,150A DOMBIVALI,152A-LODHA CASA RIO,152B-LODHA CASA BELA,153A-RUNWAL,153B-LODHA PREMIER,310A Fortune city Panvel, Rees Village Khalapur,308A India Bulls,401A Pen,303A-JNPT,311A- RASAYANI MIDC,313A- SAVROLI,314A-DHEKU,320A - ROHA,402A - NERAL,403A- KARJAT,405A-KHOPOLI"},
      {name:"116A  Airoli, Navi Mumbai,116B  Airoli, Navi Mumbai,117B  Koparkhairane, Navi Mumbai,117A  Koparkhairane, Navi Mumbai,118A Vashi, Navi Mumbai,0119 Sanpada, Navi Mumbai,0122 Rabale, Navi Mumbai,0124 Mahape, Navi Mumbai,0125 Turbhe, Navi Mumbai,114A Juinagar (W), Nerul (W),115A Seawoods (W),118B Navi Mumbai (Sanpada),120A Nerul, Navi Mumbai,120B Nerul, Navi Mumbai,121A Belapur, Navi Mumbai,121B Belapur, Navi Mumbai,123A Kharghar, Navi Mumbai,123B Kharghar, Navi Mumbai,126A Taloja MIDC, Navi Mumbai,127A New Panvel,128A Old Panvel,129A Kalamboli,130A Kamothe (Mansarovar),131A KHARGHAR,132A MIDC,Taloja, Navi Mumbai,135A Ambernath (E),136A Ambernath (E) Additional MIDC,137A Ambernath(W) CGS,139A Shahad,140A Kalyan (W),141A Kalyan (W),142A Badlapur (W),143A Badlapur (E),146A Kalyan (E),147A MIDC Dombivali,148A Dombivali (E),148B Dombivali (E),149A MIDC Dombivali,151A Dombivali (W),302B Ulwe,302A Ulwe,128B KARANJADE,133A Taloja,144A BHIWANDI,150A DOMBIVALI,152A-LODHA CASA RIO,152B-LODHA CASA BELA,153A-RUNWAL,153B-LODHA PREMIER,310A Fortune city Panvel, Rees Village Khalapur,308A India Bulls,401A Pen,303A-JNPT,311A- RASAYANI MIDC,313A- SAVROLI,314A-DHEKU,320A - ROHA,402A - NERAL,403A- KARJAT,405A-KHOPOLI,301A Uran"},
     ]
  }

  selectEvent(item:any) {
    //console.log(item);
    // this.userLocation = item.Bldg_name+','+
    // item.Colony_name +','+item.Road_name+','+item.Locatitemon+','+item.Pincode;
    // // do something with selected item
    // this.submit23(item._id)
    this.checkData(item.name);
  }

  onChangeSearch(search: string) {
   // console.log(search);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e:any) {
   // console.log(e);
    // do something
  }





  checkData(val:any){
  //console.log(val)
  this.userLocationdata = true;
  // console.log( this.UserForm.get('tpye_of_request')?.value)
  if(val=='RATHOD BROTHERS'){
     this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = true;

  }else if(val=='MAX NATURAL GAS.PVT.LTD'){
    this.MaxNaturalActive = true;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = false;

   
  }else if(val=='FIXOFIT-WELL'){
    this.MaxNaturalActive = false;
     this.FIXOFITActive =true;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive =false;

   
  }else if(val=='V.V.PRASAD'){
    this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = true;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = false;

   
  }else if(val=='SAHEDUL SHEIKH'){
    this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive =true;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = false;

   
  }else if(val=='AARTI ENTERPRISES'){
     this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = true;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = false;
   
  }else if(val=='RISHI INFRA'){
     this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = true;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = false;

   
  }else if(val=='SAMRUDDHI EXPRESS'){
    this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = true;
     this.SHRIENGINEERSActive = false;
     this.RATHODBROTHERSActive = false;

   
  }else if(val=='SHRI ENGINEERS'){
    this.MaxNaturalActive = false;
     this.FIXOFITActive = false;
     this.VVPRASADActive = false;
     this.SAHEDULActive = false;
     this.AARTIENTERPRISESActive = false;
     this.RISHIINFRAActive = false;
     this.SAMRUDDHIActive = false;
     this.SHRIENGINEERSActive = true;
     this.RATHODBROTHERSActive = false;

   
  }
  


    
  }


  selectEvent1(item:any) {
    //console.log(item);
    // this.userLocation = item.Bldg_name+','+
    // item.Colony_name +','+item.Road_name+','+item.Locatitemon+','+item.Pincode;
    // // do something with selected item
    // this.submit23(item._id)
    this.checkData1(item.name);
  }

  onChangeSearch1(search: string) {
   // console.log(search);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused1(e:any) {
   // console.log(e);
    // do something
  }

   checkData1(val:any){
this.userLocationdata1 = true;
     if(val=='016B Malad (W),016C Kandivali (W)'){
     this.firstActive = true;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
     this.twentyfourActive = false;

  }else if(val=='014 Juhu, Andheri (W),027 Santacruz (W)'){
      this.firstActive = false;
     this.secActive = true;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='002 Postal Colony, Chembur'){
      this.firstActive = false;
     this.secActive = false;
     this.thirdActive = true;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='030 Worli,031 Tardeo,0034 Parel,025A Mahim to Dadar (W),025B Mahim to Dadar (W)'){
      this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = true;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='013 Andheri (W)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = true;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='016A Malad (E&W),022A Borlivali (E),022B Borlivali (E )'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = true;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='003 Pestom Sagar, Chembur'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = true;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='017 Kandivali (E),037A -Charkop, Mumbai'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = true;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='029 Sion (E) to Dadar (E),0032 Maratha Mandir,033 Mantralaya'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = true;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='008 Sher-e-Punjab, Andheri(E)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = true;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='024A Borivali (W) & Part of Dahisar (W),024B Dahisar (W)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = true;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='019 Mulund(W),020 Bhandup,019A Mulund (E)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = true;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='007 Pant Nagar, Ghatkopar,010 Kanjur Marg(W),021 Godrej'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = true;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='011 Andheri (E)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = true;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='100A Kashigaon, Miraroad (E), 101 Mira Road (E), 102 Mira Road, 103 Bhayandar (E), 104 Bhayandar (W)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = true;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;

   
  }else if(val=='005 Sakinaka (Inside Metro Hotel),006 Hotel Leela'){
    this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = true;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='023A&B Borlivali(W),105 Thane (W),106 Thane (W),107 Thane (W),108 Thane (W),109 Thane (W)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = true;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='015A Goregaon (E)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = true;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='301A Raigadh'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = true;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='012 Andheri-(W)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = true;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='009 Kala Nagar, Bandra (E),018A Bandra (W),018B Bandra & Khar (W ),026 Santacruz (E),028 Bandra Kurla Complex'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = true;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='001 Suman Nagar, Chembur,004 Kamani, Kurla,0040 Navi Nagar,0042 Taxi-men Colony, Kurla(W),035A Bhakti Park, Wadala,036A - Pratiksha Nagar, Mumbai'){
    this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = true;
     this.twentythreeActive = false;
      this.twentyfourActive = false;


   
  }else if(val=='015B Goregaon (W)'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = true;
      this.twentyfourActive = false;


   
  }else if(val=='116A  Airoli, Navi Mumbai,116B  Airoli, Navi Mumbai,117B  Koparkhairane, Navi Mumbai,117A  Koparkhairane, Navi Mumbai,118A Vashi, Navi Mumbai,0119 Sanpada, Navi Mumbai,0122 Rabale, Navi Mumbai,0124 Mahape, Navi Mumbai,0125 Turbhe, Navi Mumbai,114A Juinagar (W), Nerul (W),115A Seawoods (W),118B Navi Mumbai (Sanpada),120A Nerul, Navi Mumbai,120B Nerul, Navi Mumbai,121A Belapur, Navi Mumbai,121B Belapur, Navi Mumbai,123A Kharghar, Navi Mumbai,123B Kharghar, Navi Mumbai,126A Taloja MIDC, Navi Mumbai,127A New Panvel,128A Old Panvel,129A Kalamboli,130A Kamothe (Mansarovar),131A KHARGHAR,132A MIDC,Taloja, Navi Mumbai,135A Ambernath (E),136A Ambernath (E) Additional MIDC,137A Ambernath(W) CGS,139A Shahad,140A Kalyan (W),141A Kalyan (W),142A Badlapur (W),143A Badlapur (E),146A Kalyan (E),147A MIDC Dombivali,148A Dombivali (E),148B Dombivali (E),149A MIDC Dombivali,151A Dombivali (W),302B Ulwe,302A Ulwe,128B KARANJADE,133A Taloja,144A BHIWANDI,150A DOMBIVALI,152A-LODHA CASA RIO,152B-LODHA CASA BELA,153A-RUNWAL,153B-LODHA PREMIER,310A Fortune city Panvel, Rees Village Khalapur,308A India Bulls,401A Pen,303A-JNPT,311A- RASAYANI MIDC,313A- SAVROLI,314A-DHEKU,320A - ROHA,402A - NERAL,403A- KARJAT,405A-KHOPOLI,301A Uran'){
     this.firstActive = false;
     this.secActive = false;
     this.thirdActive = false;
     this.fourthActive = false;
     this.fifthActive = false;
     this.sixActive = false;
     this.sevenActive = false;
     this.eightActive = false;
     this.nineActive = false;
     this.tenActive = false;
     this.elevenActive = false;
     this.twelveActive = false;
     this.thirteenActive = false;
     this.fourteenActive = false;
     this.fifteenActive = false;
     this.sixteenActive = false;
     this.seventeenActive = false;
     this.eighteenActive = false;
     this.nineteenActive = false;
     this.twentyActive = false;
     this.twentyoneActive = false;
     this.twentytwoActive = false;
     this.twentythreeActive = false;
      this.twentyfourActive = true;


   
  } 

   }

//    checkData1(val:any){
// this.userLocationdata1 = true;
//      if(val=='NAME OF SERVICE PROVIDER'){
//      this.NAMEOFSERVICEActive = true;
//      this.NAMEOFOWNERActive = false;
//      this.CONTACTOFOWNERActive = false;
//      this.DRSAREAActive = false;
//      this.DRSNOSActive = false;

//   }else if(val=='NAME OF OWNER'){
//      this.NAMEOFSERVICEActive = false;
//      this.NAMEOFOWNERActive = true;
//      this.CONTACTOFOWNERActive = true;
//      this.DRSAREAActive = false;
//      this.DRSNOSActive = false;

   
//   }else if(val=='CONTACT OF OWNER'){
//     this.NAMEOFSERVICEActive = false;
//      this.NAMEOFOWNERActive = true;
//      this.CONTACTOFOWNERActive = true;
//      this.DRSAREAActive = false;
//      this.DRSNOSActive = false;

   
//   }else if(val=='DRS AREA'){
//     this.NAMEOFSERVICEActive = false;
//      this.NAMEOFOWNERActive = false;
//      this.CONTACTOFOWNERActive = false;
//      this.DRSAREAActive = true;
//      this.DRSNOSActive = false;

   
//   }else if(val=='DRS NOS'){
//    this.NAMEOFSERVICEActive = false;
//      this.NAMEOFOWNERActive = false;
//      this.CONTACTOFOWNERActive = false;
//      this.DRSAREAActive = false;
//      this.DRSNOSActive = true;

   
//   }

//    }


}
