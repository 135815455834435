	<div class="container">
       <div class="register-top-section">
        	<a href="#" class="mgl-logo"><img src="assets/images/payulog/logo.png"></a>
       </div>
       <div class="register-content">
       		<!--PayU Left Start-->
	        <div class="left-number-section">
	        		<a class="payu-logo">
	        			<img src="assets/images/payulog/payu-white.png">
	        		</a>
		           <div class="wallet-graphic">
		           		<img src="assets/images/payulog/wallet-graphic.png">
		           </div> 
	           <div class="balance-payment">
		            <div class="balance-amount">
		            		<p>Balance </p>   
		            		<h2><span>INR.</span>{{availableBalance}}</h2>
		            </div>
	            	<i class="wallet-img"><img src="assets/images/payulog/wallet-icon.png"></i>
	           </div>   
	    	</div>
	    	<!--PayU Left End-->

	    	<!--PayU Right Start-->
		    <div class="right-form-section wallet-page-right">
		         <h2>Welcome <span>{{firstName}}</span></h2>   
		         <h6 class="select-option">Select Option</h6>
			         <ul class="wallet-option-select">
			            <li  [class.active]="isActive" (click)="checkActive('View Transaction')">
			                <a (click)="gotoTransaction()">
			                    <i><img src="assets/images/payulog/view-transaction.png"></i> View Transaction
			                </a>
			                <span class="tick"><img src="assets/images/payulog/tick-arrow.png"></span>
			            </li>
				            <li [class.active]="isActive1" (click)="checkActive('Add Balance')">
				                <a>
				                    <i><img src="assets/images/payulog/add-balance.png"></i> Add Balance
				                </a>
				                <span class="tick"><img src="assets/images/payulog/tick-arrow.png"></span>
				            </li> 
					            <li [class.active]="isActive2" (click)="checkActive('Card Limit')">
					                <a>
					                    <i><img src="assets/images/payulog/card-limit.png"></i> Card Limit</a>
					                    <span class="tick"><img src="assets/images/payulog/tick-arrow.png"></span>
					                </li>
				            <li [class.active]="isActive3" (click)="checkActive('Block Card')">
				                <a>
				                    <i><img src="assets/images/payulog/block-card.png"></i> Block Card
				                </a>
				                <span class="tick"><img src="assets/images/payulog/tick-arrow.png"></span>
				        	</li> 
                              <li [class.active]="isActive4" (click)="checkActive('Pay Wallet')">
                                <a>
                                    <i><img src="assets/images/payulog/block-card.png"></i> Pay From Wallet
                                </a>
                                <span class="tick"><img src="assets/images/payulog/tick-arrow.png"></span>
                            </li> 
                             <li [class.active]="isActive5" (click)="checkActive('UnBlock Card')">
                                <a>
                                    <i><img src="assets/images/payulog/block-card.png"></i> UnBlock Card
                                </a>
                                <span class="tick"><img src="assets/images/payulog/tick-arrow.png"></span>
                            </li> 
			         </ul>
		          </div>
		          <!--PayU Right End-->
       </div>
    </div>

    <div class="modal fade" id="exampleModal-Block" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Block Your Card</h5>
        <button type="button" (click)=" closeBlockModel()" class="close"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
         
          <div class="col-12 ">
          	<form [formGroup]="bloackcardForm">
             <div class="col-sm-12 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="blockType"
                                                [ngClass]="{'is-invalid':blocksubmitted && bloackcardForm.controls['blockType'].errors}">
                                                <option value="" selected>Select Block Type </option>
                                                <option value="Custom">Custom</option>
                                                <option value="Temporary">Temporary</option>
                                                <option value="Permanent">Permanent</option>
                                                <option value="Debit">Debit</option>
                                                <option value="Credit">Credit</option>
                                                <option value="CreditDebit">CreditDebit</option>

                                            </select>
                                            <div *ngIf="blocksubmitted && bloackcardForm.controls['blockType'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="bloackcardForm.controls['blockType'].errors">Block Type
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Block Type<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                </form>
          </div>
        </div>
      </div>
       <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="blockCard()">Block</button>
        <button type="button" class="btn btn-light" (click)="closeBlockModel()">Cancel</button>
      </div>
     
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal-UnBlock" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">UnBlock Your Card</h5>
        <button type="button" (click)=" closeUnBlockModel()" class="close"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
         
          <div class="col-12 ">
            Are your sure UnBlock Your Card?
          </div>
        </div>
      </div>
       <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="UnblockCard()">UnBlock</button>
        <button type="button" class="btn btn-light" (click)="closeUnBlockModel()">Cancel</button>
      </div>
     
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal-Deduct" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Pay From Wallet</h5>
        <button type="button" (click)=" closeDeductModel()" class="close"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <form [formGroup]="deductmoneyForm">
                            <div class="row">



                                <div class="col-sm-12 form-group">
                                    <div class="form-floating">
                                        <input type="text" class="form-control"  placeholder="Enter Amount" formControlName="deduct_amount" [ngClass]="{ 'is-invalid': deductsubmitted && deductmoneyForm.controls['deduct_amount'].errors }"/>
                                        <div *ngIf="deductsubmitted && deductmoneyForm.controls['deduct_amount'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="deductmoneyForm.controls['deduct_amount'].errors.required">Required Field
                                            </div>
                                           
                                        </div>
                                        <label for="Captcha">Enter Amount<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                           

                       
                        </form>
      </div>
       <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="deductFromwallet()">Pay</button>
        <button type="button" class="btn btn-light" (click)="closeDeductModel()">Cancel</button>
      </div>
     
    </div>
  </div>
</div>


    <div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                            Add Money
                        </div>
                        <form [formGroup]="addmoneyForm">
                            <div class="row">



                                <div class="col-sm-12 form-group">
                                    <div class="form-floating">
                                        <input type="text" class="form-control"  placeholder="Enter Amount" formControlName="amount" [ngClass]="{ 'is-invalid': submitted && addmoneyForm.controls['amount'].errors }"/>
                                        <div *ngIf="submitted && addmoneyForm.controls['amount'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="addmoneyForm.controls['amount'].errors.required">Required Field
                                            </div>
                                           
                                        </div>
                                        <label for="Captcha">Enter Amount<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                    <button  class="greenbtn2" (click)="addMoney()">Submit</button>
                                </div>

                                <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                    <button 
                                        class="greybtn" (click)="closeModel()">Cancel</button>
                                </div>
                            </div>


                         <!--    <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>
