import { Component, Input, OnInit } from '@angular/core';
import { FaqServiceService } from '../Service/FAQ/faq-service.service';
import { MenuService } from '../Service/menu.service';
import { UserRegistrationService } from '../Service/user-registration.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CngSavingCalculatorService } from '../Service/cng-saving-calculator.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {


  langauge:any[]=[{lang:'English',url:''},{lang:'हिंदी',url:'https://hindi.mahanagargas.com/'},{lang:'मराठी',url:'https://marathi.mahanagargas.com/'}]
  selected:any
  userForm! :FormGroup
  routerUrl: any[] = []

  ResponsiveSeach:boolean=false
  mobileMenuActive:boolean=false

  txt = false;
  isLoading = true;
  dataLoaded = false;
  Popup:any=''
  safeSrc: SafeResourceUrl = '';
  display:string='none';
  CngPrice = '';
  

  dropdownName: any[] = ['Residential PNG', 'CNG', 'MGL Corporate', 'Industrial & Commercial']
  config = {
    paddingAtStart: true,
    classname: 'my-custom-class',
    listBackgroundColor: 'rgb(208, 241, 239)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(208, 241, 239)',
    selectedListFontColor: 'red',
  };
  constructor(public router: Router, private CngService : CngSavingCalculatorService, private FormBuilder : FormBuilder,private Service: MenuService, public FaqService: FaqServiceService, public sanitizer:DomSanitizer,
     public UserService : UserRegistrationService) { }

  ngOnInit(): void {

    this.userForm = this.FormBuilder.group({
      inputs:['']
    })

    this.getCngPrice(); 

    this.routerUrl = this.router.config.filter((x: any) => {
      return x.data !== undefined
    })


    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))
    if (!data) {
      this.txt = false;
    } else {
      this.txt = true;
    }
  }

    gotoNach(){
    this.router.navigate(['/residential-png/nach-online'],
    {queryParams:{data:'Nach'}})
  }

  gotoENach(){
    this.router.navigate(['/residential-png/nach-online'],
    {queryParams:{data:'ENach'}})
  }

  navigateTo(val:any){
    
    let filtered = this.routerUrl.filter((x:any)=>{
      return x.data.name == val
    })
    this.router.navigate(['/'+filtered[0].path])
    this.openSearch()
    this.userForm.get('inputs')?.setValue('')
    //console.log(filtered)
  }

  open(){
    document.querySelector("#test")!.className='defult-home nav-expanded'
    this.mobileMenuActive=true
  }

  openSearch(){
    if(this.ResponsiveSeach==true){
      this.ResponsiveSeach=false
    }else{
      this.ResponsiveSeach=true
    }
  }

  close(){
    document.querySelector("#test")!.className='defult-home'
    this.mobileMenuActive=false
  }
  // getData(node: string) {
  //   console.log(node)
  //   if (!this.dataLoaded) {
  //     // this.isLoading = true;
  //     this.Service.getChildren(node).subscribe((d) => {
  //       this.data = d?.slice() || [];
  //       // this.isLoading = false;
  //       // this.dataLoaded = true;
  //     });
  //   }
  // }

  isExpandable(node: string) {
    return this.Service.isExpandable(node);
  }

  openPopup(videoID: any) {
    this.Popup = 'show'
    this.display = 'block'
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + videoID);
  }

  closeModel() {
    this.Popup = ''
    this.display = 'none'
    //console.log(this.Popup)
  }

  getCngPrice(){
    this.CngService.getCngPrice().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details

      this.CngPrice=data.CurrentPrice
    })
  }

  redirecturl(val:any){
    if(val != ''){
      window.open(val,'_blank');
    }
    
  }
}
