
	<!--Customer Form Start-->
	<div class="container">
       <div class="register-top-section">
        <a href="#" class="mgl-logo"><img src="assets/images/payulog/logo.png"></a>
       </div>
       <div class="register-content">
        <div class="left-number-section">
        <a class="payu-logo"><img src="assets/images/payulog/payu-white.png"></a>
        <div class="signup-text-box active">
            <div class="number-circle">1</div>
            <div class="sign-sub-box">
                <h3>Sign Up</h3>
                <p>Signup for Payu account</p>
            </div>
        </div>
        <div class="mid-blank-sect"></div>
        <div class="signup-text-box in-active">
            <div class="number-circle">2</div>
            <div class="sign-sub-box">
                <h3>Billing info</h3>
                <p>Choose a payment option below and fill out the appropriate in formation</p>
            </div>
        </div>
        <p class="already-ac">Already have an account? <a>Login here</a></p>
    </div>
    <div class="right-form-section">
         <form [formGroup]="walletForm">
        <div class="form-control1 mb-10">
            <div class="input-sect">
            <label>First Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="FName" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && walletForm.controls['FName'].errors }" id="FName" />
             <div *ngIf="submitted && walletForm.controls['FName'].errors" class="invalid-feedback">
             <div *ngIf="walletForm.controls['FName'].errors.required">Required Field
             </div>
             </div>
            </div>
            </div>	
            
            <div class="form-control1 mb-10">
            <div class="input-sect">
            <label>Last Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="LName" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && walletForm.controls['LName'].errors }">
             <div *ngIf="submitted && walletForm.controls['LName'].errors" class="invalid-feedback">
             <div *ngIf="walletForm.controls['LName'].errors.required">Required Field
             </div>
             </div>	
            </div>
            </div>

            <div class="form-control1 mb-10">
                <div class="input-sect">
                <label>Mobile Number <span class="text-danger">*</span></label>
                <input readonly type="text" formControlName="ContactNumber" placeholder="Mobile Number"
                [ngClass]="{ 'is-invalid': submitted && walletForm.controls['ContactNumber'].errors }"
                id="ContactNumber">	
                 <div *ngIf="submitted && walletForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="walletForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="walletForm.controls['ContactNumber'].errors.pattern">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="walletForm.controls.ContactNumber.hasError('maxlength') && walletForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="walletForm.controls.ContactNumber.hasError('minlength') && walletForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                            </div>
                </div>
                </div>

                <div class="form-control1 mb-10">
                    <div class="input-sect">
                    <label>Email Id <span class="text-danger">*</span></label>
                    <input  type="email" formControlName="Email" placeholder="Email Id"
                    [ngClass]="{ 'is-invalid': submitted && walletForm.controls['Email'].errors }"
                                                id="Email">	
                                                 <div *ngIf="submitted && walletForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                 <div *ngIf="walletForm.controls['Email'].errors.required">Required
                                                Field</div>
                                               
                                                <div *ngIf="walletForm.controls['Email'].errors.pattern">
                                                    Invalid Email </div>
                                              
                                            </div>
                    </div>
                    </div>  

                    <div class="form-control1 mb-20">
                        <div class="input-sect">
                        <label>Date of Birth <span class="text-danger">*</span></label>
                        <input type="date" formControlName="dob" 
                        [ngClass]="{ 'is-invalid': submitted && walletForm.controls['dob'].errors }" (change)="checkDob()">	
                        <div *ngIf="submitted && walletForm.controls['dob'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="walletForm.controls['dob'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                        </div>
                        </div> 
                        <button type="submit" (click)="onSubmit()">
                         <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                        </form>      

    </div>
       </div>
    </div>
