import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-pledgethankyou',
  templateUrl: './pledgethankyou.component.html',
  styleUrls: ['./pledgethankyou.component.css']
})
export class PledgethankyouComponent {
   name: any;
    name1: any;

constructor(private route: ActivatedRoute) { }

 ngOnInit(): void {
    this.name = this.route.snapshot.paramMap.get('name');
   // console.log(this.name)
// this.name1 = this.route.snapshot.paramMap.get('name');
//    var bytes  = crypto.AES.decrypt(this.name1, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr');
// this.name = bytes.toString(crypto.enc.Utf8);


 }


 
    printForm() {
  
    const Application: any = `<!doctype html>
    <html>
    
    <head>
      <meta charset="utf-8">
      <title>PLEDGE</title>
      <style></style>
    </head>
    
    <body>
 <div style="width:100%; margin: 0 auto; background:#efeef4;-webkit-print-color-adjust: exact; font-family:Montserrat, sans-serif;">
  <div style="padding:4%">
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="20%"><img src="../../assets/images/pledge/left-butterfly-img.png" style="width: 100%"></td>
      <td width="60%" align="center"><img src="../../assets/images/pledge/pledge-text.jpg" style="width: 50%;"></td>
      <td width="19%" align="center"><img src="../../assets/images/pledge/logo.png" style="width:65%"></td>
    </tr>
  </tbody>
</table>

    </div>
    <div style="font-family: Montserrat,Verdana; padding:4%; font-size:3.5vw; font-weight: 500;line-height:5vw;">
      <div style="color:#12472b">
      <table width="100%" border="0" cellspacing="0" cellpadding="2" style="font-family: Montserrat,Verdana;font-size:3.5vw; font-weight: 500;line-height:5vw;">
        <tbody>
        <tr>
          <td width="3%">I</td>
          <td width="62%"><div style="height: 30px; width: 96%; position: relative; padding-bottom:10px; font-weight: 800;text-align:center;">
        ${this.name}
        <div style="position: absolute; left: 0; bottom:0px; width: 100%; height:2px; background:#12472b;-webkit-print-color-adjust: exact;"></div>
        </div></td>
          <td width="35%">solemnly pledge</td>
        </tr>
        </tbody>
      </table>

      </div>
      <div style="color:#12472b; text-align: justify;">that I will shoulder the responsibility of a good citizen by using eco-friendly fuel for household and vehicular consumption and endeavour to educate
my immediate family members and friends to use cleaner fuels for environment protection.</div>
      <div style="font-weight: 600; color:#509141;margin-top: 30px;text-align: justify;">MGL endeavours to protect and restore the planet through your support for generations to come.</div>
    </div>
    <div><img src="../../assets/images/pledge/footer-graphic-img.png" style="width: 100%"></div>
  </div>
</body>
    
    </html>`

    var popupWin = window.open('', '_blank', 'width=1000,height=600');
    popupWin!.document.open();
    popupWin!.document.write(Application);

    setTimeout(function() {
      popupWin?.print()
      popupWin!.document.close();
  }, 500);
  }

}
