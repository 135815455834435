import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { logcomplaint } from 'src/app/Service/logcomplaint.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-cng-complaint-status',
  templateUrl: './cng-complaint-status.component.html',
  styleUrls: ['./cng-complaint-status.component.css']
})
export class CngComplaintStatusComponent {


  OutletName: any[] = []
  feedback:any[]=[]

  Feedbackdetail:any[]=[]

  FinalFeedBack:any={}

  fuelType: string[] = []
  UserForm!: FormGroup
  submitted: boolean = false;

  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder,private Service : logcomplaint) { }

  ngOnInit(): void {
    this.getAllOutlet()
    this.getAllFeedback()
    this.fuelType = ['Cat1', 'Cat2', 'Cat3', 'Cat4']

    this.UserForm = this.FormBuilder.group({
      RetailOutlet: ['', Validators.required],
      ComplaintNo: ['', Validators.required],
      ComplaintDate: ['', Validators.required],
      ComplaintType: ['', [Validators.required]],
      ComplaintCat: ['', Validators.required],
      Remark: ['', Validators.required],
      Feedback: ['', Validators.required],
      Status: ['', Validators.required],
      })


    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  getAllFeedback(){

    this.Service.getAllCng_DealerFeedBack().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.feedback = data
      //console.log(this.feedback)
    })

  }

  getFeedbackComplaintIdByOutlet(val:any){
    this.Feedbackdetail = this.feedback.filter((x:any)=>{
      return x.RetailOutlet == val
    })

    
  }

  getFeedBackDetailById(Id:any){

    this.FinalFeedBack = this.feedback.filter((x:any)=>{
      return x.Complaint_id == Id
    })
    var date = new Date(this.FinalFeedBack[0].create_date);
    
    this.UserForm.get('ComplaintDate')?.setValue(date.toISOString().substring(0, 10))
    this.UserForm.get('ComplaintType')?.setValue(this.FinalFeedBack[0].Matter)
    this.UserForm.get('ComplaintCat')?.setValue(this.FinalFeedBack[0].Category)
    this.UserForm.get('Remark')?.setValue(this.FinalFeedBack[0].Remark)
    this.UserForm.get('Feedback')?.setValue(this.FinalFeedBack[0].Feedback)
    this.UserForm.get('Status')?.setValue(this.FinalFeedBack[0].Status)
    


    //console.log(this.UserForm.value)

  }


  getAllOutlet() {
    this.Service.getAllOutlet().subscribe(response => {
      let data = JSON.parse(JSON.stringify(response)).details
      this.OutletName = data
     // console.log(this.OutletName)
      this.OutletName.sort(function(a,b){
        if(a.OutletName< b.OutletName) return -1;
        if(a.OutletName >b.OutletName) return 1;
        if(a.OutletName< b.OutletName) return -1;
        if(a.OutletName >b.OutletName) return 1;
        return 0;
      });
    })
  }


  reset() {
    this.fuelType = []
    this.UserForm.reset()
    this.submitted = false
    setTimeout(() => {
      this.fuelType = ['Cat1', 'Cat2', 'Cat3', 'Cat4']
    }, 200);
  }

  submit() {
    this.submitted = true

    if (this.UserForm.invalid) {
      return
    }
  }

}
