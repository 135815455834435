<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Connection Application Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG </a></li>
                <li class=""><a>Domestic Connection Request</a></li>
                <!--  <li class=""><a>Domestic PNG Registration</a></li> -->
                <li class="active" aria-current="page">Connection Application Status</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>

<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <!--  <div class="white-box mt-30 mb-30 dom-con-req">
                                  <i class="demo-icon uil-notes"></i>    
                                 <p>If you wish to avail a new Domestic PNG connection from a Gasified building, please <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/Reg_Form']">Click here</a> </p>
                                </div>-->
                        <div class=" customer-care-overview-icon">

                            <div id="rs-services" class="rs-services style4">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 md-mb-30" >
                                        <a [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_check']">
                                        <div class="services-item text-center">
                                            <div class="services-icon">
                                                <img class="dance_hover"
                                                    src="assets/images/domestic-connection-form-icons/Network-is-Available.png"
                                                    alt="Customer Care">
                                            </div>
                                            <div class="services-content">
                                                <!-- <h3 class="title">Customer Care</h3> -->
                                                <p class="margin-0"><strong>Check Domestic PNG Application Status</strong></p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                     
                              <div class="col-lg-6 col-md-6 md-mb-30">
                                        <a
                                            [routerLink]="['/residential-png/domestic-connection-request/domestic-png-registration/domestic-connection-request/application_institution_check']">
                                            <div class="services-item text-center">
                                                <div class="services-icon">
                                                    <img class="dance_hover"
                                                        src="assets/images/domestic-connection-form-icons/No-Network-is-Available.png"
                                                        alt="Distribution Company">
                                                </div>
                                                <div class="services-content">
                                                    <p class="margin-0"><strong>Check Domestic PNG Connection Government / Defence/ Institutional colonies Application Status</strong></p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                     

                                </div>

                            </div>

                           
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>

