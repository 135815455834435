<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Other Initiatives </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a [routerLink]="['/csrinitiative']">CSR Initiatives</a></li>
                <li class="active" aria-current="page">Other Initiatives</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>
                 <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
                <!-- <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2">View All <i class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <h3>More CSR Initiatives</h3>
                    <div class="blue-tick-list">
                        <ul class="">
                            <li>As rehabilitation initiative operations of MGL’s CNG outlets were allotted to families
                                of the martyrs in the recent Mumbai terror attack.</li>
                            <li>Financial support extended for the education and welfare of visually impaired students.
                            </li>
                            <li>As a national responsibility, MGL has contributed for the relief of victims of National
                                Disasters such as floods, earthquake, etc.</li>
                            <li>To provide a source of livelihood to needy women sewing machines were distributed to
                                them.</li>
                            <li>Supporting Art and Culture for the welfare of community and for propagating Indian Art
                                and Culture.</li>
                            <li>As a concern for the health and welfare of auto and taxi drivers Free Spirometry (Lung
                                Function Test) and Awareness Camps, Eye Check-up Camps are conducted for auto and taxi
                                drivers.</li>
                            <li>Published booklets for creating awareness about harmful effects of tobacco.</li>
                            <li>Garden benches provided for the public gardens of Navi Mumbai and Thane for the
                                convenience of the public.</li>
                            <li>Road Dividers provided for easing the traffic on the roads of Mumbai.</li>
                            <li>Infrastructural support provided for the Police Department’s Special Cell for welfare of
                                women and children.</li>
                            <li>'Mahasuraksha Yojana', MGL's unique social initiative provides Group Personal Accident
                                cover for the drivers of CNG vehicles (rickshaws and taxis) was launched by the
                                Honourable Prime Minister of India.</li>
                            <li>Traffic Booths and Police shelter cabin for welfare of traffic police.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    