<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>MGL Hattrick Yojana
            <span> </span>
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class="active" aria-current="page">MGL Hattrick Yojana
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <!-- <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    MGL Hattrick Yojana - Advertisement in Hindi
                                </div>
                                <a href="assets/images/pdf/_mgl-hattrick-yojana-hindi-ad_d665b5898e.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    MGL Hattrick Yojana - Advertisement in Marathi
                                </div>
                                <a href="assets/images/pdf/_mgl-hattrick-yojana-marathi-ad_081fbb1124.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div> -->

                        <div class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    Frequently Asked Questions (FAQ's)
                                </div>
                                <a href="assets/images/pdf/_mgl-hattrick-yojana-faq_9e25c376db.pdf" target="_Blank"
                                    class="bluebtn2 d-block">Download <i class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Inner Page Content Section End -->