import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { FormServiceService } from 'src/app/Service/AllFormService/form-service.service';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { DatePipe } from '@angular/common';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment'; 

@Component({
  selector: 'app-applyfornametsteptwoedit',
  templateUrl: './applyfornametsteptwoedit.component.html',
  styleUrls: ['./applyfornametsteptwoedit.component.css']
})
export class ApplyfornametsteptwoeditComponent {

   public base = environment.baseUrl
   allPost: any[] = [];
   //captcha: any;
   CustomerInfoForm!: FormGroup
   submitted = false
   finalSubmit : boolean = false
   loading = false;

  alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center'

  //  meter_img:any;
  // meter_img_name:any;
   LpgSurrSlip_img:any;
  LpgSurrSlip_img_name:any;
   DocName1File_img:any;
   DocName1File_img_name:any;
     DocName2File_img:any;
   DocName2File_img_name:any;
  LpgConn : boolean = false

   HaveConn: any[] = ['Yes', 'No'];
   AllReason: any[] = [];

  Data1:any=['Sale Deed / Letter from Builder or Society','Allotment Letter']
  Data2:any=['Succession certificate / Letter from Society','Gift deed/ Succession Agreement']
  Data3:any=['Death Certificate']
  Data4:any=['Allotment Letter','Lease Agreement','If any other']
  Data5:any=['Succession certificate','Letter from Society']
  Data6:any=['Letter from Society']
  Data7:any=['Gift Deed']
  finalData:any=[]
   finalData1:any=[]
  ShowDoc1:boolean = false;
  Doc1Text:any='';

   ShowDoc2:boolean = false;
  Doc2Text:any='';

  ApplicationNoID:any;

  previouspageData:any;
 constructor(private PostService: SliderService,private el: ElementRef,private datePipe: DatePipe, private FormBuilder: FormBuilder, private PngService: FormServiceService, public UserService: UserRegistrationService,
   public router: Router, private pageLoaderService: PageLoaderService,
   private activatedRoute: ActivatedRoute,) {

      this.activatedRoute.queryParams.subscribe((data: any) => {
        this.ApplicationNoID = JSON.parse(data.data);
         //console.log('this.ApplicationNoID',this.ApplicationNoID);

          let obj = {'ApplicationNo': this.ApplicationNoID}
     this.PngService.getUserinfoforPrint(obj).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response))
         this.previouspageData = data.details[0]
          //console.log(this.previouspageData)

          this.CustomerInfoForm.get('MeterNo')?.setValue(this.previouspageData.MeterNo)
   this.CustomerInfoForm.get('MeterReading')?.setValue(this.previouspageData.MeterReading)
   this.CustomerInfoForm.get('LpgGasConn')?.setValue(this.previouspageData.LpgGasConn)
   this.CustomerInfoForm.get('GasSuppli')?.setValue(this.previouspageData.GasSuppli)
   this.CustomerInfoForm.get('GasDistri')?.setValue(this.previouspageData.GasDistri)
   this.CustomerInfoForm.get('LpgConnNo')?.setValue(this.previouspageData.LpgConnNo)
   this.CustomerInfoForm.get('LpgSurrSlip')?.setValue(this.previouspageData.LpgSurrSlip)
    this.CustomerInfoForm.get('ResForTrans')?.setValue(this.previouspageData.ResForTrans)
   this.CustomerInfoForm.get('DocName1')?.setValue(this.previouspageData.Doc1Type)
   this.CustomerInfoForm.get('DocName1File')?.setValue(this.previouspageData.Doc1File)
   this.CustomerInfoForm.get('DocName2')?.setValue(this.previouspageData.Doc2Type)
   this.CustomerInfoForm.get('DocName2File')?.setValue(this.previouspageData.Doc2File)

   this.HaveConnection(this.previouspageData.LpgGasConn)
   this.ReasonForTransferEdit(this.previouspageData.ResForTrans)
   

  })
        
      
      });

    }


 ngOnInit(): void {
 // this.captchaGenerate()
  this.getAllHighlight()

  this.CustomerInfoForm = this.FormBuilder.group({
      MeterNo: [''],
      MeterReading: [''],
      // MeterPhoto: [''],
      LpgGasConn: ['', [Validators.required]],
      GasSuppli: [''],
      GasDistri: [''],
      LpgConnNo: [''],
      LpgSurrSlip: [''],
      ResForTrans: ['', [Validators.required]],
      DocName1: ['', [Validators.required]],
      DocName1File: ['', [Validators.required]],
        DocName2: [''],
      DocName2File: [''],
     //Captcha: ['', [Validators.required]],
     })

   this.AllReason =[
      {"ReasonText":"Purchase of Property"},
      {"ReasonText":"Inheritance of property / succession"},
      {"ReasonText":"Demise of Registered Customer"},
      {"ReasonText":"Succession"},
       {"ReasonText":"Gift Deed"},
      {"ReasonText":"Other"}
  ]

   this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

 }

 ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

 getAllHighlight(){
    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
     this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
     })
    }

 //  captchaGenerate() {
 //    let length = 7
 //    let result = '';
 //    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
 //    const charactersLength = characters.length;
 //    let counter = 0;
 //    while (counter < length) {
 //      result += characters.charAt(Math.floor(Math.random() * charactersLength));
 //      counter += 1;
 //    }
 //   this.captcha = result
 // }


  // fileCheckForMeter(val: any) {
  //   console.log(val.files)
  //    if(val.target.files[0].size/1024/1024>2){
  //        this.CustomerInfoForm.get('MeterPhoto')?.setValue('');
  //       return alert('Please select image less than 2 MB ')
  //     }else{
  //        this.meter_img = val.target.files;
  //        this.meter_img_name = val.target.files[0];
  //     }
  // }

   HaveConnection(val: any) {

   // console.log(val)

    if (val == 'Yes') {
      this.CustomerInfoForm.get('GasSuppli')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('GasSuppli')?.updateValueAndValidity()
      this.CustomerInfoForm.get('GasDistri')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('GasDistri')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgConnNo')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('LpgConnNo')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgSurrSlip')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('LpgSurrSlip')?.updateValueAndValidity()
      this.LpgConn = true
    } else if (val == 'No') {

      this.CustomerInfoForm.get('GasSuppli')?.clearValidators()
      this.CustomerInfoForm.get('GasDistri')?.clearValidators()
      this.CustomerInfoForm.get('LpgConnNo')?.clearValidators()
      this.CustomerInfoForm.get('GasSuppli')?.updateValueAndValidity()
      this.CustomerInfoForm.get('GasDistri')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgConnNo')?.updateValueAndValidity()
      this.CustomerInfoForm.get('LpgSurrSlip')?.clearValidators()
      this.CustomerInfoForm.get('LpgSurrSlip')?.updateValueAndValidity()
      this.LpgConn = false
    }

  }

  fileCheckForSlip(val: any,name:any) {
      if (name=='LpgSurrSlip') {
         if(val.target.files[0].type == "application/pdf"){
       if(val.target.files[0].size/1024/1024>2){
          this.CustomerInfoForm.get('LpgSurrSlip')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
         this.LpgSurrSlip_img = val.target.files;
         this.LpgSurrSlip_img_name = val.target.files[0];
      }

    }else{
        this.CustomerInfoForm.get('LpgSurrSlip')?.setValue('');
      return alert('Please select only PDF less than 2 MB ')
    }
     
      
    }else if(name=='DocName1File'){
       if(val.target.files[0].type == "application/pdf"){
      if(val.target.files[0].size/1024/1024>2){
          this.CustomerInfoForm.get('DocName1File')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
        this.DocName1File_img = val.target.files;
      this.DocName1File_img_name = val.target.files[0];
      this.CustomerInfoForm.get('DocName1File')?.setValue(val.target.files);
      }
    }else{
       this.CustomerInfoForm.get('DocName1File')?.setValue('');
        return alert('Please select only PDF less than 2 MB ')
    }
      

    }else if(name=='DocName2File'){
       if(val.target.files[0].type == "application/pdf"){
      if(val.target.files[0].size/1024/1024>2){
          this.CustomerInfoForm.get('DocName2File')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
        this.DocName2File_img = val.target.files;
      this.DocName2File_img_name = val.target.files[0];
      }
    }else{
       this.CustomerInfoForm.get('DocName2File')?.setValue('');
         return alert('Please select only PDF less than 2 MB ')
     }
    }

  }

   // ReasonForTransfer(val:any){
   //       this.ShowDoc1 = false
   //       this.Doc1Text = ""
   //     if(val == "Purchase of Property"){
   //      this.ShowDoc1 = true
   //      this.CustomerInfoForm.get('DocName1')?.setValue('');
   //      this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //      this.finalData = this.Data1;
   //     }else if(val == "Inheritance of property / succession"){
   //      this.ShowDoc1 = true
   //      this.CustomerInfoForm.get('DocName1')?.setValue('');
   //      this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //      this.finalData = this.Data2;

   //   }else if(val == "Demise of Registered Customer"){
   //      this.ShowDoc1 = true
   //       this.CustomerInfoForm.get('DocName1')?.setValue('');
   //        this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //       this.finalData = this.Data3;
      
   //   }else if(val == "Succession"){
   //      this.ShowDoc1 = true
   //       this.CustomerInfoForm.get('DocName1')?.setValue('');
   //        this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //       this.finalData = this.Data5;
      
   //   }else if(val == "Other"){
   //      this.ShowDoc1 = true
   //       this.CustomerInfoForm.get('DocName1')?.setValue('');
   //        this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //       this.finalData = this.Data4;
      
   //   }

   // }

   ReasonForTransfer(val:any){
         this.ShowDoc1 = false
         this.Doc1Text = ""
          this.ShowDoc2 = false
         this.Doc2Text = ""
          this.CustomerInfoForm.get('DocName2')?.clearValidators()
          this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
           this.CustomerInfoForm.get('DocName2File')?.clearValidators()
          this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
       if(val == "Purchase of Property"){
        this.ShowDoc1 = true
        this.CustomerInfoForm.get('DocName1')?.setValue('');
        this.CustomerInfoForm.get('DocName1File')?.setValue('');
        this.finalData = this.Data1;
         this.CustomerInfoForm.get('DocName2')?.setValue('');
          this.CustomerInfoForm.get('DocName2File')?.setValue('');
       }else if(val == "Inheritance of property / succession"){
        this.ShowDoc1 = true
        this.CustomerInfoForm.get('DocName1')?.setValue('');
        this.CustomerInfoForm.get('DocName1File')?.setValue('');
        this.finalData = this.Data2;
        this.CustomerInfoForm.get('DocName2')?.setValue('');
          this.CustomerInfoForm.get('DocName2File')?.setValue('');

     }else if(val == "Demise of Registered Customer"){
        this.ShowDoc1 = true
          this.ShowDoc2 = true
         this.CustomerInfoForm.get('DocName1')?.setValue('');
          this.CustomerInfoForm.get('DocName1File')?.setValue('');
         this.finalData = this.Data3;
          this.finalData1 = this.Data6;
         this.CustomerInfoForm.get('DocName2')?.setValue('');
          this.CustomerInfoForm.get('DocName2File')?.setValue('');
          this.CustomerInfoForm.get('DocName2')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2')?.updateValueAndValidity()
      this.CustomerInfoForm.get('DocName2File')?.setValidators([Validators.required])
      this.CustomerInfoForm.get('DocName2File')?.updateValueAndValidity()
      
     }else if(val == "Succession"){
        this.ShowDoc1 = true
         this.CustomerInfoForm.get('DocName1')?.setValue('');
          this.CustomerInfoForm.get('DocName1File')?.setValue('');
         this.finalData = this.Data5;
         this.CustomerInfoForm.get('DocName2')?.setValue('');
          this.CustomerInfoForm.get('DocName2File')?.setValue('');
      
     }else if(val == "Gift Deed"){
        this.ShowDoc1 = true
         this.CustomerInfoForm.get('DocName1')?.setValue('');
          this.CustomerInfoForm.get('DocName1File')?.setValue('');
         this.finalData = this.Data7;
         this.CustomerInfoForm.get('DocName2')?.setValue('');
          this.CustomerInfoForm.get('DocName2File')?.setValue('');
      
     }else if(val == "Other"){
        this.ShowDoc1 = true
         this.CustomerInfoForm.get('DocName1')?.setValue('');
          this.CustomerInfoForm.get('DocName1File')?.setValue('');
         this.finalData = this.Data4;
         this.CustomerInfoForm.get('DocName2')?.setValue('');
          this.CustomerInfoForm.get('DocName2File')?.setValue('');
      
     }

   }

   DocnameForTransfer(val:any){
    this.Doc1Text = "Upload "+val
    this.CustomerInfoForm.get('DocName1File')?.setValue('');
   }

   DocnameForTransfer2(val:any){
    this.Doc2Text = "Upload "+val
    this.CustomerInfoForm.get('DocName2File')?.setValue('');
   }


   //   ReasonForTransferEdit(val:any){
   //       this.ShowDoc1 = false
   //       this.Doc1Text = ""
   //     if(val == "Purchase of Property"){
   //      this.ShowDoc1 = true
   //      // this.CustomerInfoForm.get('DocName1')?.setValue('');
   //      // this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //      this.finalData = this.Data1;
   //     }else if(val == "Inheritance of property / succession"){
   //      this.ShowDoc1 = true
   //      // this.CustomerInfoForm.get('DocName1')?.setValue('');
   //      // this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //      this.finalData = this.Data2;

   //   }else if(val == "Demise of Registered Customer"){
   //      this.ShowDoc1 = true
   //       // this.CustomerInfoForm.get('DocName1')?.setValue('');
   //       //  this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //       this.finalData = this.Data3;
      
   //   }else if(val == "Succession"){
   //      this.ShowDoc1 = true
   //       // this.CustomerInfoForm.get('DocName1')?.setValue('');
   //       //  this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //       this.finalData = this.Data5;
      
   //   }else if(val == "Other"){
   //      this.ShowDoc1 = true
   //       // this.CustomerInfoForm.get('DocName1')?.setValue('');
   //       //  this.CustomerInfoForm.get('DocName1File')?.setValue('');
   //       this.finalData = this.Data4;
      
   //   }

   // }

    ReasonForTransferEdit(val:any){
         this.ShowDoc1 = false
         this.Doc1Text = ""
          this.ShowDoc2 = false
         this.Doc2Text = ""
       
       if(val == "Purchase of Property"){
        this.ShowDoc1 = true
        this.finalData = this.Data1;
       }else if(val == "Inheritance of property / succession"){
        this.ShowDoc1 = true
        this.finalData = this.Data2;
       }else if(val == "Demise of Registered Customer"){
        this.ShowDoc1 = true
        this.ShowDoc2 = true
        this.finalData = this.Data3;
        this.finalData1 = this.Data6;
       }else if(val == "Succession"){
        this.ShowDoc1 = true
        this.finalData = this.Data5;
       }else if(val == "Gift Deed"){
        this.ShowDoc1 = true
        this.finalData = this.Data7;
        }else if(val == "Other"){
        this.ShowDoc1 = true
        this.finalData = this.Data4;
        
      
     }

   }


 submitCustomerForm(){
  // console.log("ssklfj",this.DocName1File_img)
  //  console.log("ssklfj",this.CustomerInfoForm.get('DocName1File')?.value)
   // this.DocName1File_img = val.target.files;
   //    this.DocName1File_img_name = val.target.files[0];
    // this.previouspageData.ApplicationId ="TF002"
    // console.log('this.previouspageData',this.previouspageData)
    // this.router.navigate(['/residential-png/customer-zone/name-transfer/apply-for-name-step-two'],
    //     {queryParams:{data:JSON.stringify(this.previouspageData)}})

   this.submitted = true
   this.loading = true;
    // if (this.CustomerInfoForm.get('Captcha')?.value != this.captcha) {
    //   this.CustomerInfoForm.get('Captcha')?.setValue('')
    // }
    if (this.CustomerInfoForm.invalid) {
       this.loading = false;
      return;
    }
//console.log("ssklfj  sffsff")

   const form = new FormData()

   form.append('ApplicationNo',this.ApplicationNoID)

    if (this.LpgSurrSlip_img_name != null) {
       let LpgSurrSlipImg = this.LpgSurrSlip_img[0].name.split('.').pop()
    let LpgSurrSlipImg1  =this.LpgSurrSlip_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData.BPno + new Date() +'.'+ LpgSurrSlipImg;
      form.append("LpgSurrSlip", this.LpgSurrSlip_img[0], LpgSurrSlipImg1)
      //form.append("LpgSurrSlip", this.LpgSurrSlip_img[0], this.LpgSurrSlip_img[0].name)
    }

    if (this.DocName1File_img_name != null) {
      form.append('DocName1Name',this.CustomerInfoForm.get('DocName1')?.value)
        let DocName1Img = this.DocName1File_img[0].name.split('.').pop()
    let DocName1Img1  =this.DocName1File_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData.BPno + new Date() +'.'+ DocName1Img;
      form.append("DocName1File", this.DocName1File_img[0], DocName1Img1)
      //form.append("DocName1File", this.DocName1File_img[0], this.DocName1File_img[0].name)
    }

     if (this.DocName2File_img_name != null) {
      form.append('DocName2Name',this.CustomerInfoForm.get('DocName2')?.value)
        let DocName2Img = this.DocName2File_img[0].name.split('.').pop()
    let DocName2Img1  =this.DocName2File_img[0].name.split('.').slice(0, -1).join('.') + this.previouspageData.Partner + new Date() +'.'+ DocName2Img;
      form.append("DocName2File", this.DocName2File_img[0], DocName2Img1)
      //form.append("DocName1File", this.DocName1File_img[0], this.DocName1File_img[0].name)
    }
   
    form.append('MeterNo', this.CustomerInfoForm.get('MeterNo')?.value)
    form.append('MeterReading', this.CustomerInfoForm.get('MeterReading')?.value)
    form.append('LpgGasConn', this.CustomerInfoForm.get('LpgGasConn')?.value)
    form.append('GasSuppli', this.CustomerInfoForm.get('GasSuppli')?.value)
    form.append('GasDistri', this.CustomerInfoForm.get('GasDistri')?.value)
    form.append('LpgConnNo', this.CustomerInfoForm.get('LpgConnNo')?.value)
    form.append('ResForTrans', this.CustomerInfoForm.get('ResForTrans')?.value)
   
    this.PngService.updatepostNameTransferForm(form).subscribe(response => {
      let data = JSON.parse(JSON.stringify(response))
      
    //  console.log(response)
      this.alertType = '3rdSubmit'
      if (data.Response) {
        // this.CustomerInfoForm.get('ApplicationId')?.setValue(data.details.ApplicationId)
       
        // this.UserService.ApplicationNo=data.details.ApplicationId
        // this.CustomerInfoForm.get('BPnoCAno')?.setValue(this.ValidationForm.get('BPnoCAno')?.value)

        // this.UserService.printNameTransfer = this.CustomerInfoForm.value
        this.alertMsg = data.message+"| Application No." + data.details.ApplicationId
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
           this.loading = false;
           this.router.navigate(['/residential-png/customer-zone/name-transfer/apply-for-name-step-three-edit'],
        {queryParams:{data:JSON.stringify(this.ApplicationNoID)}})
          // this.printForm()
          // this.router.navigate(['/thank-you'])
        }, 1000);
      } else {
         this.loading = false;
        this.alertMsg = data.message
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
      }
    })
    
     

 }

}

