import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AreaResp } from 'src/app/models/area';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IcUsersService {
  baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getCANum(data: any) {
    return this.http.post<AreaResp>(this.baseUrl + 'ic-users/checkICUsers', {canum: data})
    .pipe(map(user => {
      return user;
    }));
  }

  getBill(data: any) {
    return this.http.post<AreaResp>(this.baseUrl + 'ic-users/getBill', {canum: data})
    .pipe(map(user => {
      return user;
    }));
  }
}
