import { Component, OnInit } from '@angular/core';
import { FaqServiceService } from 'src/app/Service/FAQ/faq-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-dealerzonefaqs',
  templateUrl: './dealerzonefaqs.component.html',
  styleUrls: ['./dealerzonefaqs.component.css']
})
export class DealerzonefaqsComponent {
 allFaq:any[]=[]
 
 

  constructor(public FaqService : FaqServiceService, private pageLoaderService: PageLoaderService) { 

  

  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
   
   
  
  }

 

 
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
