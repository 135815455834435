import { Component } from '@angular/core';

@Component({
  selector: 'app-apppayment-error',
  templateUrl: './apppayment-error.component.html',
  styleUrls: ['./apppayment-error.component.css']
})
export class ApppaymentErrorComponent {

}
