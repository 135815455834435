import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { PayuService } from 'src/app/Service/payu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payupaymentsuccess',
  templateUrl: './payupaymentsuccess.component.html',
  styleUrls: ['./payupaymentsuccess.component.css']
})
export class PayupaymentsuccessComponent {
  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  data:any;

  backendData:any;
  constructor(public UserService: UserRegistrationService, private pageLoaderService: PageLoaderService,
    public payuService: PayuService,public router: Router) { }

  ngOnInit(): void {
       
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   
  }

  payUwallet(){
    this.UserService.checkLogin()
     let data = sessionStorage.getItem('UserCredential')
    if(data){
       this.data=JSON.parse(data)
       this.findpayUcreatewalletdata();
       
    }
   
  }




  findpayUcreatewalletdata() {
    let obj={"MobileNo":this.data.Mobile}
     this.payuService.checkwallet(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
     // console.log(res)
   
      if (res.response == true) {
        this.router.navigate(['/view-wallet']);
      } else {
        this.router.navigate(['/create-wallet']);
      }
     }, err => {
     // console.log(err)
    })

  }

}

