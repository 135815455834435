import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-mglcngmahotsav',
  templateUrl: './mglcngmahotsav.component.html',
  styleUrls: ['./mglcngmahotsav.component.css']
})
export class MglcngmahotsavComponent {
 constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

  }  

}
