import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { PayuService } from 'src/app/Service/payu.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payuwallet',
  templateUrl: './payuwallet.component.html',
  styleUrls: ['./payuwallet.component.css']
})
export class PayuwalletComponent {
  walletForm!: FormGroup
   submitted = false;

   data : any;
   postFinalData:any;
   loading = false;

   constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder,
    public payuService: PayuService,public UserService: UserRegistrationService,public router: Router,) { 



   }

   ngOnInit(): void {
     //this.UserService.checkLogin()
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

 
   
    this.walletForm = this.FormBuilder.group({
      FName: ['', [Validators.required]],
      LName: ['', [Validators.required]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      Email: ['', [Validators.email,Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      dob: ['', Validators.required],
     });

     let data = sessionStorage.getItem('UserCredential')
     //console.log(data)
    if(data){
       this.data=JSON.parse(data)
       this.walletForm.get('ContactNumber')?.setValue(this.data.Mobile);
       this.walletForm.get('Email')?.setValue(this.data.Email);
    }
   
   



  }


   checkDob(){

      var d1 = new Date(this.walletForm.get('dob')?.value);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    var daydiff = (diff / 31536000000).toFixed(0);

    if (parseInt(daydiff) < 18) {
      this.walletForm.get('dob')?.setValue('')
       alert("Should be Above 18")
     
    }

  }


//   onSubmit() {
//      console.log(this.walletForm.value)
//      this.submitted = true
//      var mydate = new Date();
//      var curr_date = mydate.getDate();
//      var curr_month = mydate.getMonth();
//      var curr_year = mydate.getFullYear();
//      console.log(mydate,curr_date,curr_month,curr_year)
//  var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
// var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
//   year: "numeric",
//   month: "2-digit",
//   day: "2-digit",
// });

// console.log(result)
// var myresultsplit = result.split('/');
// console.log(myresultsplit)
// var time = mydate.toLocaleTimeString();
// console.log(time)
// var timesplit = time.split(':');
// console.log(timesplit)
//  let text3 = timesplit[2].slice(0, 2);
//  console.log(text3)
//  console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
// var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;
   
//     if (this.walletForm.invalid) {
//       return;
//     }

//      this.loading = true;

//     var mydob = this.walletForm.get('dob')?.value;
//     var mydobsplit = mydob.split("-");
//     console.log(mydobsplit)
//     var finalDOB=mydobsplit[1]+"-"+mydobsplit[2]+"-"+mydobsplit[0];
    
//     let obj={"FName":this.walletForm.get('FName')?.value,
//          "LName":this.walletForm.get('LName')?.value,
//          "ContactNumber":this.walletForm.get('ContactNumber')?.value,
//          "Email":this.walletForm.get('Email')?.value,
//          "dob":finalDOB,
//           "datetime":savetime
//        }

//        console.log(obj)

//       this.payuService.payUencrypt(obj).subscribe(response => {
//       console.log(response);
//       let res = JSON.parse(JSON.stringify(response))
//       console.log(res)
//       this.payUcreateCard(res.details.token)
//       // if (res.response == true) {
       
//       //   this.alertColor = 'rgba(4, 183, 107, 0.2)'
//       //   this.alertTextColor = '#038b51'
//       //   this.alertBorder = '1px solid #04a862;'
//       //   this.alertType = 'vehiclepromotion'
//       //   this.alertMsg = res.message;
//       //   this.backendData = res.details;
      
//       //   setTimeout(() => {
          

//       //   }, 5000);

//       // } else {
//       //   this.alertColor = 'rgba(255, 94, 109, 0.2)'
//       //   this.alertTextColor = '#c24753'
//       //   this.alertBorder = '1px solid #eb5664;'
//       //   this.alertType = 'vehiclepromotion'
//       //   this.alertMsg = res.message;
//       //   //  this.promotionForm.reset();
//       //   setTimeout(() => {
        

//       //   }, 5000);
//       // }



//     }, err => {
//       console.log(err)
//     })




  
//   }



  onSubmit() {
    // console.log(this.walletForm.value)
     this.submitted = true
     var mydate = new Date();
     var curr_date = mydate.getDate();
     var curr_month = mydate.getMonth();
     var curr_year = mydate.getFullYear();
    // console.log(mydate,curr_date,curr_month,curr_year)
 var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)
var myresultsplit = result.split('/');
//console.log(myresultsplit)
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)
 let text3 = timesplit[2].slice(0, 2);
// console.log(text3)
 //console.log( myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3)
var savetime = myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+text3;
   
    if (this.walletForm.invalid) {
      return;
    }

     this.loading = true;

    var mydob = this.walletForm.get('dob')?.value;
    var mydobsplit = mydob.split("-");
   // console.log(mydobsplit)
    var finalDOB=mydobsplit[1]+"-"+mydobsplit[2]+"-"+mydobsplit[0];
    
    let obj={"FName":this.walletForm.get('FName')?.value,
         "LName":this.walletForm.get('LName')?.value,
         "ContactNumber":this.walletForm.get('ContactNumber')?.value,
         "Email":this.walletForm.get('Email')?.value,
         "dob":finalDOB,
          "datetime":savetime
       }

       //console.log(obj)

      this.payuService.createWallet(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
     // console.log(res)
     
     if (res.response == true) {
       this.loading = false;
       //res.details.LstErrorResponse[0].ErrorStatus=="-2"
       if(res.details.LstWallet_Response !=null){
        if(res.details.LstWallet_Response[0].responseMessage=="CARD_CREATED_SUCCESSFULLY"){
         
         alert('CARD_CREATED_SUCCESSFULLY');
         this.router.navigate(['/wallet-thank-you'])
       }else{
        alert('Error occurred! Please try later.');
       }
       }else{
         alert(res.details.LstErrorResponse[0].ErrorMsg);
       }
     
      
      } else {
        alert(res.message);
         this.loading = false;
      }

    }, err => {
     // console.log(err)
    })




  
  }


  payUcreateCard(data:any) {

   

    let obj={"token":data}
   // console.log(obj)


    this.payuService.payUcreateCard(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
     // console.log(res)
      this.payUdecrypt(res.details.token)
      // if (res.response == true) {
       
      //   this.alertColor = 'rgba(4, 183, 107, 0.2)'
      //   this.alertTextColor = '#038b51'
      //   this.alertBorder = '1px solid #04a862;'
      //   this.alertType = 'vehiclepromotion'
      //   this.alertMsg = res.message;
      //   this.backendData = res.details;
      
      //   setTimeout(() => {
          

      //   }, 5000);

      // } else {
      //   this.alertColor = 'rgba(255, 94, 109, 0.2)'
      //   this.alertTextColor = '#c24753'
      //   this.alertBorder = '1px solid #eb5664;'
      //   this.alertType = 'vehiclepromotion'
      //   this.alertMsg = res.message;
      //   //  this.promotionForm.reset();
      //   setTimeout(() => {
        

      //   }, 5000);
      // }



    }, err => {
      //console.log(err)
    })

  }


  payUdecrypt(data:any) {

   

    let obj={"token":data}
   // console.log(obj)


    this.payuService.payUdecrypt(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      //console.log('d res',res.details.responseCode)
      if (res.response == true) {
         this.postFinalData = res.details;
        // console.log(this.postFinalData)
        if(this.postFinalData.responseCode=='10'){
          alert('CARD ALREADY CREATED');
           this.loading = false;

        }else if(this.postFinalData.responseCode=='1003'){
          alert('ERROR CARD CREATION');
          this.loading = false;

        }else if(this.postFinalData.responseCode=='1237'){
          alert('DUPLICATE MOBILE NUMBER WITH MULTIPLE ACCOUNT');
          this.loading = false;

        }else if(this.postFinalData.responseCode=='1500'){
          alert('SYSTEM ERROR');
          this.loading = false;

        }else if(this.postFinalData.responseCode=='1056'){
          alert('INVALID MESSAGE CODE');
          this.loading = false;

        }else if(this.postFinalData.responseMessage=='UNKNOWN ERROR'){
          alert('UNKNOWN ERROR');
          this.loading = false;

        }else if(this.postFinalData.responseCode=='201'){
           let obj={"BPNo":this.data.BpNo,"CANo":this.data.CaNo,
          "FName":this.walletForm.get('FName')?.value,
          "LName":this.walletForm.get('LName')?.value,
          "Email":this.walletForm.get('Email')?.value,
          "MobileNo":this.walletForm.get('ContactNumber')?.value,
          "DOB":this.walletForm.get('dob')?.value,
          "urn":this.postFinalData.urn.toString(),"customerId":this.postFinalData.customerId,
          "description":this.postFinalData.description,"responseCode":this.postFinalData.responseCode,
          "messageCode":this.postFinalData.messageCode.toString(),"clientTxnId":this.postFinalData.clientTxnId,
          "clientId":this.postFinalData.clientId,"responseDateTime":this.postFinalData.responseDateTime,
          "accosaTransactionId":this.postFinalData.accosaTransactionId,"responseMessage":this.postFinalData.responseMessage,
          "bankId":this.postFinalData.bankId,"accosaRefNo":this.postFinalData.accosaRefNo,
          "cardNumber":this.postFinalData.cardNumber,"cardExpiry":this.postFinalData.cardExpiry,
          "cardCVV2":this.postFinalData.cardCVV2,"cardProfileId":this.postFinalData.cardProfileId,
          "loadAmount":this.postFinalData.loadAmount,"availableBalance":this.postFinalData.availableBalance,
          "availableCashLimit":this.postFinalData.availableCashLimit,"loginProfId":this.postFinalData.loginProfId,
          "subwallet_id":this.postFinalData.subwalletListDetails[0].subwallet_id,
          "default_wallet":this.postFinalData.subwalletListDetails[0].default_wallet,
          "available_balance":this.postFinalData.subwalletListDetails[0].available_balance,
          "account_number":this.postFinalData.subwalletListDetails[0].account_number,
          "account_status":this.postFinalData.subwalletListDetails[0].account_status,
          "subwallet_name":this.postFinalData.subwalletListDetails[0].subwallet_name,
          "ledger_balance":this.postFinalData.subwalletListDetails[0].ledger_balance

          }

          this.payUcreatewalletdata(obj);

        }
       
       

      } else {
       alert('Some error Occure')
      }



    }, err => {
      //console.log(err)
    })

  }



  payUcreatewalletdata(obj:any) {
     this.payuService.payUcreatewalletdata(obj).subscribe(response => {
    //  console.log(response);
      let res = JSON.parse(JSON.stringify(response))
     // console.log(res)
     
      if (res.Response == true) {
        this.loading = false;
       alert('CARD_CREATED_SUCCESSFULLY"');
       this.router.navigate(['/wallet-thank-you'])
       

      } else {
       alert('Not CARD_CREATED_SUCCESSFULLY"');
       this.loading = false;
      }



    }, err => {
      //console.log(err)
    })

  }






}
