<!-- <div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Apply for Name Transfer
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a>Home</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Apply for Name Transfer
                </li>
            </ul>
        </div>
    </div>
</div> -->


<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
              </div>




            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
              <div  class="customer-login-page log-meter-reading">

                    <form [formGroup]="CustomerInfoForm">
                        <div class="light-blue-bg mt-10">
                            <div class="row">
                                <p><b><i>New Customer Information </i></b></p>
                                 <div class="col-lg-2 col-md-2 col-sm-12 mb-3">
                                    <div class="form-floating">
                                        <select #initial
                                            [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['NameInitial'].errors}"
                                            class="form-select"
                                            required formControlName="NameInitial">
                                            <option>Initial</option>
                                            <option *ngFor="let i of NameInitial">{{i}}</option>
                                             </select>
                                        <div *ngIf="submitted && CustomerInfoForm.controls['NameInitial'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['NameInitial'].errors.required">
                                                Required Field</div>
                                           </div>
                                        <label for="Captcha">Initial<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12 mb-3">
                                     <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Fname"
                                            placeholder="Fname"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Fname'].errors }"
                                            id="Fname" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['Fname'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Fname'].errors.required">Required
                                                Field</div>
                                           </div>
                                        <label for="Captcha">First Name<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                               <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                     <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="Mname"
                                            placeholder="Mname"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Mname'].errors }"
                                            id="Mname" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['Mname'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Mname'].errors.required">Required
                                                Field</div>
                                            </div>
                                        <label for="Mname">Middle Name</label>
                                    </div>
                                </div>
                                 <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                      <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="lname"
                                            placeholder="lname"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['lname'].errors }"
                                            id="lname" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['lname'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['lname'].errors.required">Required
                                                Field</div>
                                          </div>
                                        <label for="lname">Last Name<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                  <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="FHusbandName"
                                            placeholder="FHusbandName"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['FHusbandName'].errors }"
                                            id="FHusbandName" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['FHusbandName'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['FHusbandName'].errors.required">
                                                Required Field</div>
                                          </div>
                                        <label for="FHusbandName">Father/Husband's Name</label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                     <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="text"
                                            class="form-control panupper" formControlName="Pan" placeholder="Pan"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Pan'].errors }"
                                            id="Pan" />
                                             <div *ngIf="submitted && CustomerInfoForm.controls['Pan'].errors" class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Pan'].errors.required">Required Field</div>
                                            <div *ngIf="CustomerInfoForm.controls['Pan'].errors?.['pattern']">PAN No Invalid</div>
                                          </div>
                                        <label for="Pan">Pan No<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating">
                                        <input readonly onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="MobileNo" placeholder="MobileNo"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['MobileNo'].errors }"
                                            id="MobileNo" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['MobileNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['MobileNo'].errors.required">Required
                                                Field</div>
                                            
                                        </div>
                                        <label for="MobileNo">Mobile No<span style="color: crimson;">*</span></label>
                                    </div>
                                </div>

                             

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" formControlName="Email"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Email'].errors }"
                                            id="Email" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['Email'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['Email'].errors.required">Required
                                                Field</div>
                                           
                                        </div>
                                        <label for="Email">Email<span style="color: crimson;">*</span></label>
                                    </div>


                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">

                                    <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="AltMobileNo" placeholder="AltMobileNo"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['AltMobileNo'].errors }"
                                            id="AltMobileNo" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['AltMobileNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['AltMobileNo'].errors.required">
                                                Required
                                                Field</div>
                                           
                                        </div>
                                        <label for="AltMobileNo">Alternate Mobile No</label>
                                    </div>


                                </div>
                             </div>
                              <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                              
                                                <div class="mt-20">
                                                
                                                    <div (copy)="false" class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-7 col-sm-12">


                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted && CustomerInfoForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="CustomerInfoForm.controls['Captcha'].errors.required">
                                                            Required Field</div>
                                                     
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                 <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="submitCustomerForm()"
                                            class="greenbtn2">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Next<i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>

                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                        <button
                                            (click)="this.router.navigate(['/residential-png/customer-zone/name-transfer'])"
                                            class="greybtn">Cancel<i class="demo-icon uil-times-circle"></i></button>
                                    </div>
                                </div>


                                <div *ngIf="alertType == '3rdSubmit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                        </div>
                      
                      
                    </form>
                </div>






            </div>
        </div>
    </div>
</div>


