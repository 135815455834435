import { Component } from '@angular/core';
import { BlogarticleService } from 'src/app/Service/blogarticle.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-csr-initiative',
  templateUrl: './csr-initiative.component.html',
  styleUrls: ['./csr-initiative.component.css']
})
export class CsrInitiativeComponent {

  baseUrl = environment.baseUrl
  AllData:any[]=[]

  constructor(private pageLoaderService: PageLoaderService,private service : BlogarticleService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

    this.getAllBlog()
  }


  getAllBlog(){
    this.service.getAll().subscribe(response=>{
      this.AllData = response
    })
  }
}
