import { Component } from '@angular/core';

@Component({
  selector: 'app-subsidiary-financials',
  templateUrl: './subsidiary-financials.component.html',
  styleUrls: ['./subsidiary-financials.component.css']
})
export class SubsidiaryFinancialsComponent {

}
