import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ManageClientService {

  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }

  getAllClient(){
    return this.http.get(this.baseUrl+'getAllClient')
  }
}
