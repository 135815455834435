import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-apply-for-new-connection',
  templateUrl: './apply-for-new-connection.component.html',
  styleUrls: ['./apply-for-new-connection.component.css']
})
export class ApplyForNewConnectionComponent {
  constructor(private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}
