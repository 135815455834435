import { Component } from '@angular/core';

@Component({
  selector: 'app-mgl-journey',
  templateUrl: './mgl-journey.component.html',
  styleUrls: ['./mgl-journey.component.css']
})
export class MglJourneyComponent {

}
