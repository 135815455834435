import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-nexgenproject',
  templateUrl: './nexgenproject.component.html',
  styleUrls: ['./nexgenproject.component.css']
})
export class NexgenprojectComponent {
  constructor(private activatedRoute: ActivatedRoute,
    private pageLoaderService: PageLoaderService) { 
      this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 50000);
   }

  ngOnInit(): void {
   
   this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
    //https://www.skrm.me/m/bill.php?code={billkey}
      window.location.href = 'https://www.skrm.me/m/bill.php?code=' + id;
      console.log('Url Id: ', id);
    })
  }

}
