
import { Component, OnInit } from '@angular/core';
import { GalleryServiceService } from 'src/app/Service/gallery-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  allAlbum:any[]=[]
  Albums:any[]=[]
  allPhoto:any[]=[]
  AlbumYear:any[]=[]

  currentYear:any

  PhotoList:any[]=[]
  backupnews:any[]=[]
  filteredAlbum:any[]=[]

  selectedImage:any
  selectedTitle:any
  currentImgIndex : any = 0


  display:string='none'

  networkOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      450: {
        items: 2
      }
    }
  }
  

  Popup:any=''
  range:any = 0

  closed = false

  baseUrl: string = environment.baseUrl

  constructor(private GalleryService : GalleryServiceService, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getAllAlbum()
    this.getAllPhoto()
  }



  openCity(evt :any, cityName:any) {
    var i, tabcontent, tablinks;

    this.allAlbum = this.backupnews.filter((x:any)=>{
      return new Date(x.CreatedOn).getFullYear() == cityName
    })

    this.currentYear = cityName
    //console.log(this.filteredAlbum)
    

    tabcontent = document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName)!.style.display = "block";
    evt.currentTarget.className += " active";
  }

  PrevImage(){
    this.closed=true
    if(this.currentImgIndex > 0){
      this.currentImgIndex = this.currentImgIndex - 1
    }
  }

  NextImage(){

    

    this.closed=true

    if(this.currentImgIndex < this.PhotoList.length - 1){
      this.currentImgIndex = this.currentImgIndex + 1
    }

  }

  increaseRange(){

    this.range = this.range + 5

    this.allAlbum = this.Albums.slice(0, this.range)

  }

  getPhotoByAlbum(id:any){
    this.PhotoList = this.allPhoto.filter((x:any)=>{
      return x.AlbumID == id._id
    })

    this.selectedTitle = id.Album

    this.Popup='show'
    this.display='block'

    const loop = setInterval((()=>{
      this.currentImgIndex = this.currentImgIndex + 1
      if(this.currentImgIndex == this.PhotoList.length - 1){
        this.currentImgIndex = 0
      }

      if(this.closed){
        this.currentImgIndex = 0
        clearInterval(loop)
      }
    }),2000)

  //  console.log(this.PhotoList)
  }

  openPopup(val:any){
   // console.log(val)
    this.Popup='show'
    this.display='block'
    // this.selected=val
    

  }

  openModel(){
    
  }

  closeModel(){
    this.Popup=''
    this.display='none'
    //console.log(this.Popup)
  }
  


  getAllAlbum(){
    this.GalleryService.getAllAlbum().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      this.Albums=data.filter((x:any)=>{
        return x.IsDeleted != true && x.ThumbnailImage != undefined
      })

      this.increaseRange()

      this.Albums.sort((a, b) => Date.parse(b.CreatedOn) - Date.parse(a.CreatedOn))

      const uniqueAges = [...new Set( this.Albums.map(obj => new Date(obj.CreatedOn).getFullYear())) ];
      //console.log(uniqueAges)

      
      this.AlbumYear = uniqueAges

      this.currentYear = this.AlbumYear[0]
      this.backupnews = this.Albums

      this.openCity('',this.AlbumYear[0])
    })
  }


  getAllPhoto(){
    this.GalleryService.getAllGallery().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      this.allPhoto=data

      //console.log(this.allPhoto)
    })
  }
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }
}


// import { Component, OnInit } from '@angular/core';
// import { GalleryServiceService } from 'src/app/Service/gallery-service.service';
// import { PageLoaderService } from 'src/app/Service/page-loader.service';
// import { environment } from 'src/environments/environment';
// @Component({
//   selector: 'app-gallery',
//   templateUrl: './gallery.component.html',
//   styleUrls: ['./gallery.component.css']
// })
// export class GalleryComponent implements OnInit {

//   allAlbum:any[]=[]
//   Albums:any[]=[]
//   allPhoto:any[]=[]

//   PhotoList:any[]=[]

//   selectedImage:any
//   selectedTitle:any
//   currentImgIndex : any = 0


//   display:string='none'

//   networkOptions: any = {
//     loop: true,
//     margin: 30,
//     autoplay: true,
//     responsiveClass: true,
//     dots: false,
//     nav:false,
//     responsive: {
//       0: {
//         items: 1
//       },
//       450: {
//         items: 2
//       }
//     }
//   }
  

//   Popup:any=''
//   range:any = 0

//   closed = false

//   baseUrl: string = environment.baseUrl

//   constructor(private GalleryService : GalleryServiceService, private pageLoaderService: PageLoaderService) { }

//   ngOnInit(): void {
//     this.pageLoaderService.show()
//     setTimeout(() => {
//       this.pageLoaderService.hide()
//     }, 3000);
  
//     this.getAllAlbum()
//     this.getAllPhoto()
//   }

//   PrevImage(){
//     this.closed=true
//     if(this.currentImgIndex > 0){
//       this.currentImgIndex = this.currentImgIndex - 1
//     }
//   }

//   NextImage(){

    

//     this.closed=true

//     if(this.currentImgIndex < this.PhotoList.length - 1){
//       this.currentImgIndex = this.currentImgIndex + 1
//     }

//   }

//   increaseRange(){

//     this.range = this.range + 5

//     this.allAlbum = this.Albums.slice(0, this.range)

//   }

//   getPhotoByAlbum(id:any){
//     this.PhotoList = this.allPhoto.filter((x:any)=>{
//       return x.AlbumID == id._id
//     })

//     this.selectedTitle = id.Album

//     this.Popup='show'
//     this.display='block'

//     const loop = setInterval((()=>{
//       this.currentImgIndex = this.currentImgIndex + 1
//       if(this.currentImgIndex == this.PhotoList.length - 1){
//         this.currentImgIndex = 0
//       }

//       if(this.closed){
//         this.currentImgIndex = 0
//         clearInterval(loop)
//       }
//     }),2000)

//     console.log(this.PhotoList)
//   }

//   openPopup(val:any){
//     console.log(val)
//     this.Popup='show'
//     this.display='block'
//     // this.selected=val
    

//   }

//   openModel(){
    
//   }

//   closeModel(){
//     this.Popup=''
//     this.display='none'
//     console.log(this.Popup)
//   }
  


//   getAllAlbum(){
//     this.GalleryService.getAllAlbum().subscribe(response=>{
//       let data = JSON.parse(JSON.stringify(response))

//       this.Albums=data.filter((x:any)=>{
//         return x.IsDeleted != true && x.ThumbnailImage != undefined
//       })

//       this.increaseRange()

//       console.log(this.Albums)
//     })
//   }


//   getAllPhoto(){
//     this.GalleryService.getAllGallery().subscribe(response=>{
//       let data = JSON.parse(JSON.stringify(response))

//       this.allPhoto=data

//       console.log(this.allPhoto)
//     })
//   }
//   ngAfterViewInit(): void {
//     (<any>window).twttr.widgets.load();
//   }
// }



