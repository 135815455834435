<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Industrial Tariff Card</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Industrial PNG</a></li>
                <li class="active" aria-current="page">Tariff Card</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-industrial-service-section></app-industrial-service-section>

<!-- Services Section End -->

<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
        	<div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                	<div class="mt-20">
                	<h5>{{myTitle}} </h5>
                	<div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Segments </th>
                                    <th>Basic Price (Rs/SCM)</th>
                                    <th>Basic Price (Rs.MMBTU)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of industArray">
                                    <td>{{item.segments}}</td>
                                    <td>{{item.basic_price_scm}}</td>
                                    <td> {{item.basic_price_mmbtu}}</td>
                                </tr>
                               <!--  <tr>
                                    <td> Industrial B </td>
                                    <td> 40.55</td>
                                    <td> 1,202.1900</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                    </div>
				</div>
				</div>                	
        </div>
           </div>
         		</div>