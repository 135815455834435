import { Component } from '@angular/core';

@Component({
  selector: 'app-retrofitment',
  templateUrl: './retrofitment.component.html',
  styleUrls: ['./retrofitment.component.css']
})
export class RetrofitmentComponent {

}
