import { Component } from '@angular/core';

@Component({
  selector: 'app-facebook-post',
  templateUrl: './facebook-post.component.html',
  styleUrls: ['./facebook-post.component.css']
})
export class FacebookPostComponent {

}
