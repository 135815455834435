<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>View Complaint</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
                <li class="active" aria-current="page">View Complaint</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12">
                <div>
                    <div class="row">
                        <div class="customer-login-page log-meter-reading">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr.no</th>
                                            <th>Docket No.</th>
                                            <th>Type Of Request</th>
                                            <th>Remark</th>
											<th>Complaint Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
										<tr *ngIf="complaintdata.length == 0" >
                                            <td colspan="6" style="text-align: center;">No Data Found</td>
                                        </tr>
                                        <tr *ngFor="let ComplaintItem of complaintdata  let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{ComplaintItem.DocketNo}}</td>
                                            <td>{{ComplaintItem.Complaint}}</td>
                                            <td>{{ComplaintItem.Remark}}</td>
                                            <td>{{ComplaintItem.Compdt}}</td>
											<td>{{ComplaintItem.Status}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>