<div class="latestnews-section">
    <div class="latest-posts">
        <div class="recent-posts">
            <div class="widget-title">
                <h3 class="title">Posts</h3>
            </div>
            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                <div class="post-img">
                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                </div>
                <div class="post-desc">
                    <a [innerHTML]="i.Description"></a>
                    <!-- <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM dd, yyyy'}}</span> -->
                </div>
            </div>
           <!--  <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a>  -->
        </div>
    </div>
</div>