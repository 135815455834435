import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-domestic-connection',
  templateUrl: './domestic-connection.component.html',
  styleUrls: ['./domestic-connection.component.css']
})
export class DomesticConnectionComponent {

  public base = environment.baseUrl
  allPost: any[] = [];

  status:boolean =false
  submitted = false
  UserForm!:FormGroup

  constructor(private PostService: SliderService,private pageLoaderService: PageLoaderService,private FormBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getAllHighlight()
    this.UserForm = this.FormBuilder.group({
      pincode:['',[Validators.required]]
    })

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  }


  getAllHighlight(){

    this.PostService.getAllHighlight().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response)).details
    
      this.allPost=data.filter((x:any)=>{
        return x.Type == 'PNG'
      })
      

    })
    
  }


  submit(){
    this.submitted=true

    if(this.UserForm.invalid){
      return
    }
  }
}
