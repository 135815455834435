import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-iand-cnach-cancellation',
  templateUrl: './iand-cnach-cancellation.component.html',
  styleUrls: ['./iand-cnach-cancellation.component.css']
})
export class IandCNachCancellationComponent {

  confirmationStatus:boolean = false

  confirmedStatus : boolean = false

  alertType:any=''
  alertMsg:any=''
  alertColor : string=''
  alertTextColor : string=''
  alertBorder : string=''
  alertTextAlign:string='center'

  data: any = ''

  UserForm !: FormGroup
  checkOtpForm !: FormGroup
  Minute : any =2
  Sec : any=59
  
  captcha:any
  Otp:any
  OtpSent:boolean=false
  PopUp:boolean = false
  submitted: boolean = false;

  OOtp: any
  OtpMinute: any = 1
  OtpSec: any = 10
  OtpButtonActive: boolean = false
  stopTimer:any
  submitStatus:boolean=false
  OtpSubmitted=false
  Cadata:any[]=[]

  ShowOtp: boolean = false

  Popup:any=''
  display:string='none'

  constructor(private UserService : UserRegistrationService,private FormBuilder: FormBuilder, public service: DashboardServiceService, private pageLoaderService: PageLoaderService, public sanitizer:DomSanitizer) { 
    
  }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

     this.UserForm = this.FormBuilder.group({
      CAno: ['', [Validators.required]],
      Captcha: ['', [Validators.required]],
      BPNo: ['', [Validators.required]],
      Otp:[''],
      Contact:['', [Validators.required]],
      Email:['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      Name:['', [Validators.required]],
      MandateId:['', [Validators.required]],
    })


    this.checkOtpForm = this.FormBuilder.group({
      Otp: ['', Validators.required]
    })



    this.captchaGenerate()
  }

  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result
  }


  OtpGenerate() {
    let length = 6
    let result = '';
    const characters = '0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    
    this.captcha=result
   // console.log(result)
    this.UserService.sendOtp(result,this.UserForm.get('Mobile')?.value)
    this.otpTimer()
    this.OtpButtonActive = true
    this.submitStatus=false


  }

  clearAlert() {
    this.alertType = ''
  }


  verifyOtp() {

    this.OtpSubmitted = true

  //  console.log(this.captcha)
    

    if (this.checkOtpForm.invalid) {
      return
    }
    this.alertType = 'secSubmit'
    if (this.captcha != this.checkOtpForm.get('Otp')?.value) {

      this.alertMsg = "Otp Did Not Match"
      this.alertColor = 'rgba(255, 94, 109, 0.2)'
      this.alertTextColor = '#c24753'
      this.alertBorder = '1px solid #eb5664;'
      setTimeout(() => {
        this.clearAlert()
      }, 1000);
    } else {
      this.alertMsg = "Otp Verified"
      this.alertColor = 'rgba(4, 183, 107, 0.2)'
      this.alertTextColor = '#038b51'
      this.alertBorder = '1px solid #04a862;'
      
      setTimeout(() => {
        this.clearAlert()
        this.ShowOtp = false
        this.closeModel()
        this.confirmationStatus=false
        this.confirmedStatus=true
        
      }, 1000);
    }
  }

  openPopup(){
    
    this.Popup='show'
    this.display='block'
    
    

  }

  closeModel(){
    this.Popup=''
    this.display='none'
    this.stopTimer=true
    
  }


  otpTimer() {

    var minute = 1;
    var sec = 1;
    const count = setInterval(function () {

      sec--;

      

      if (sec == 0 && minute == 0) {
        minute = 0
        sec = 0
        clearInterval(count)
      }

      if (0 == sec && minute != 0) {
        minute--;
        sec = 60;
      }
      test()
    }, 1000);

    var test = () => {
      if(this.stopTimer){
        clearInterval(count)
      }

      if (sec == 0 && minute == 0) {
        this.OtpButtonActive = false
        this.Otp=''
        this.submitStatus=true
        clearInterval(count)
      }
      this.OtpMinute = minute
      this.OtpSec = sec
    }
  }


  confirm(){

    this.UserForm.get('Mobile')?.setValue(this.Cadata[0].Contact)

    this.OtpGenerate()
    this.openPopup()

  }

  clearStatus(){
    this.alertType=''
  }


  submit() {

    this.submitted = true
    //console.log(this.UserForm.invalid)
    if (this.UserForm.invalid) {
      return;
    }

    this.UserService.registerNachCancel(this.UserForm.value).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
     // console.log(data)
      this.alertType='CA'
      if(data.Response){
        this.alertMsg = data.message
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'

        setTimeout(() => {
          this.clearStatus()
          this.UserForm.reset()
          this.submitted = false
        }, 1000)
        
      }else{
        this.alertMsg = data.message
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'

        setTimeout(() => {
          this.clearStatus()
          
          
        }, 1000)
      }
    })

    // this.UserService.getCaNo(this.UserForm.value).subscribe(response=>{
    //   let data = JSON.parse(JSON.stringify(response))
    //   console.log(data)
    //   if(data.Response){
    //     this.Cadata = data.details
    //     this.confirmationStatus=true
        
    //   }
    // })
  }

}
