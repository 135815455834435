import { Component, OnInit } from '@angular/core';
import { SliderService } from 'src/app/Service/slider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
export class TipsComponent implements OnInit {

  networkOptions: any = {
    loop: true,
    margin: 30,
    autoplay: true,
    responsiveClass: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      450: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
    }
  }

  allTips:any[]=[]
  baseUrl : string = environment.baseUrl

  constructor( private Service : SliderService ) { }

  ngOnInit(): void {

    this.getAllTips()
  }

  getAllTips(){
    this.Service.getAllTips().subscribe(response=>{
      var data = JSON.parse(JSON.stringify(response)).details;
      let filtered = data.filter((x:any)=>{
        return x.IsDeleted !== true
      })
      this.allTips=filtered
      //console.log(this.allTips)
    })
  }

}
