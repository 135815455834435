import { Injectable } from '@angular/core';
  import { map, Observable} from 'rxjs';
  import { vehiclepromotionResp } from '../models/vehiclepromotion';
  import { environment } from 'src/environments/environment';
  import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class IndustrialCommercialTariffService {
 baseUrl: any = "";
  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

   getIndustrialtariff(){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'getIndustrialtariff',{})
    }

     getCommercialtariff(){

      return this.http.post<vehiclepromotionResp>(this.baseUrl+'getCommercialtariff',{})
    }

}
