import { Component } from '@angular/core';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-mglcngmaostav20oemdashboard',
  templateUrl: './mglcngmaostav20oemdashboard.component.html',
  styleUrls: ['./mglcngmaostav20oemdashboard.component.css']
})
export class Mglcngmaostav20oemdashboardComponent {

  constructor(private pageLoaderService: PageLoaderService,
    private UserService : UserRegistrationService) { }

   ngOnInit(): void {
    this.UserService.checkLogin2()

   

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   
   

     

  }

   ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

}
