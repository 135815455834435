import { Component } from '@angular/core';

@Component({
  selector: 'app-expression-of-interest',
  templateUrl: './expression-of-interest.component.html',
  styleUrls: ['./expression-of-interest.component.css']
})
export class ExpressionOfInterestComponent {

}
