import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Photo } from '../models/ManageGallery';
@Injectable({
  providedIn: 'root'
})
export class GalleryServiceService {

  baseUrl:string=environment.baseUrl

  constructor(private http : HttpClient) { }

  getAllGallery(){
    return this.http.get(this.baseUrl+'getPhoto')
  }

  getallVideo():Observable<Photo>{
    return this.http.get<Photo>(this.baseUrl+'getAllVideo')
  }

  getAllAlbum(){
    return this.http.get(this.baseUrl+'getAllAlbum')
  }
}
