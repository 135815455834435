<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Expression of Interest</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL-Corporate</a></li>
                <li class="active" aria-current="page">Expression of Interest</li>


            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <!-- <app-tips></app-tips> -->

                
            </div>

             <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="light-blue-bg mb-30">
                        <div  class="white-box10 mb-20">
                            <div class="empanelment">
                             Data not Available
                                <!-- <div class="empanelment-name">
                                   Addendum to EOI for Participating in Setting up CBG Plant <span class="new">New</span>
                                </div> -->
                               <!--  <a   href="assets/images/pdf/Addendum to EOI for Participating in Setting up CBG Plant.pdf" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a> -->
                            </div>
                        </div>
						<!-- <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   Addendum to EOI for Participating in Setting up CBG Plant <span class="new">New</span>
                                </div>
                                <a   href="assets/images/pdf/Addendum to EOI for Participating in Setting up CBG Plant.pdf" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                        <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                   EOI for Participating in Setting up CBG Plant & Annexures
                                </div>
                                <a   href="assets/images/pdf/EOI for Participating in Setting up CBG plant.zip" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div> -->
                        <!--30-11-2023 <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    EOI- Supply of Compressed Biogas (CBG) to MGL under SATAT and CBG-CGD synchronization scheme of MoP&NG – in English
                                </div>
                                <a   href="assets/images/pdf/English - Invite for EOI_28 - Copy.pdf" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                        <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    EOI- Supply of Compressed Biogas (CBG) to MGL under SATAT and CBG-CGD synchronization scheme of MoP&NG – in Hindi
                                </div>
                                <a   href="assets/images/pdf/Hindi - Invite for EOI_28 - Copy.pdf" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>-->

                       <!--  <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    EOI- Supply of Compressed Biogas (CBG) to MGL under SATAT and CBG-CGD synchronization scheme of MoP&NG – in English
                                </div>
                                <a   href="assets/images/pdf/EOI- Supply of Compressed Biogas (CBG) to MGL under SATAT and CBG-CGD synchronization scheme of MoP&NG – in English (Valid Up to 31.03.24).pdf" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div>
                        <div  class="white-box10 mb-20">
                            <div class="empanelment">
                                <div class="empanelment-name">
                                    EOI- Supply of Compressed Biogas (CBG) to MGL under SATAT and CBG-CGD synchronization scheme of MoP&NG – in Hindi
                                </div>
                                <a   href="assets/images/pdf/EOI- Supply of Compressed Biogas (CBG) to MGL under SATAT and CBG-CGD synchronization scheme of MoP&NG – in Hindi (Valid Up to 31.03.24).pdf" class="bluebtn2 d-block" target="_blank">Download <i
                                        class="demo-icon uil-download-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

           <!--  <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">   
                                  <div class="light-blue-bg mb-30"> 
                                <div class=" customer-care-overview-icon">
                        <div id="rs-services" class="rs-services style4">
                            <div class="row">
                                 <div class="col-lg-6 col-md-6 md-mb-30">
                                    <a target="_blank"
                                   href="https://www.mahanagargas.com:3000/English - Invite for EOI_28.pdf">                               
                                      <div class="services-item text-center">
                                        <div class="services-icon">
                                            <img class="dance_hover"  src="assets/images/careers/EOI-cbg-english.png"
                                                alt="Distribution Company">
                                        </div>
                                        <div class="services-content">
                                          <p class="margin-0"><strong>CBG-English</strong></p>
                                        </div>
                                    </div>
                                </a>
                                </div>
                                <div class="col-lg-6 col-md-6 md-mb-30">
                                    <a target="_blank"
                                   href="https://www.mahanagargas.com:3000/Hindi - Invite for EOI_28.pdf">
                                    <div class="services-item text-center">
                                        <div class="services-icon">
                                            <img class="dance_hover"
                                                src="assets/images/careers/EOI-cbg-hindi.png"
                                                alt="Customer Care">
                                        </div>
                                        <div class="services-content">
                                           
                                            <p class="margin-0"><strong>CBG-Hindi</strong></p>
                                        </div>
                                    </div>
                                </a>
                                </div>
                               </div>
                          
                        </div>
                        </div>

                </div>
            </div>
        </div> -->
    </div>
</div>    
