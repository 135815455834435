<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Asset Integrity</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>MGL Corporate</a></li>
                <li class="active" aria-current="page">Asset Integrity</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">

                    
                    <div class="light-blue-bg mb-30">
                        <p>"Asset Integrity" is a unique Management System initiative adopted by MGL aimed at ensuring robust health of our Assets &amp; installations.</p>

                    <h6>MGL's Approach</h6>
                    <p>We at Mahanagar Gas Limited are committed to ensure that our Gas Supply Assets are designed, constructed, operated &amp; maintained to deliver optimum performance,
                     reliability and safety throughout their working life.</p>

                        <h6>This will be achieved by</h6>
                        <div class="blue-tick-list">
                            <ul class="unordered-list">
                                <li>Application of the national and International codes &amp; standards.</li>
                                <li>The use of industry best practices.</li>
                                <li>Utilizing effective techniques and systems to prevent failures by identifying
                                    hazards, potential consequential failures and undertaking necessary mitigation
                                    actions.</li>
                                <li>Ensuring all personnel required to work on the network are trained and
                                    competent.</li>
                                <li>Monitoring performance, review and amend as required to achieve this goal.</li>
                                <li>Involvement of all stakeholders.</li>
                                <li>This endeavour shall be supported by the provision of all the necessary
                                    resources.</li>
                            </ul>
                            <p>This endeavour shall be supported by the provision of all the necessary resources.</p>
                        </div>
                        <div class="asset-integrity-pic"><img src="assets/images/asset-integrity.png"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>