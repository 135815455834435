import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropboxServiceService } from 'src/app/Service/dropbox-service.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';

@Component({
  selector: 'app-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrls: ['./dropbox.component.css']
})
export class DropboxComponent {

  status:boolean=false
  
  submitted:boolean=false

  dropboxForm!:FormGroup

  AllData:any[]=[]
  AllCity:any[]=[]
  AllBank:any[]=[]
  BankList:any[]=[]

  selectedCity:any
  selectedArea:any
  selectedBank:any

  constructor(private service : DropboxServiceService, private FormBuilder:FormBuilder, private pageLoaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.dropboxForm = this.FormBuilder.group({
      City:[''],
      Area:[''],
      Bank:['']
    })

    this.getCity()
  }

  reset(){
    this.dropboxForm.reset()
    this.status=false
    this.submitted=false

    this.AllData=[]
    this.AllCity=[]
    this.AllBank=[]
    this.getCity()
    
    
  }

  getAllDropbox(){

    this.service.getAllDroplistss().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      this.BankList=data
      //console.log(this.BankList)

      
    })

  }

  submit(){

    this.submitted=true
    this.status=true
   // console.log(this.dropboxForm.value)
    if(this.dropboxForm.get('City')?.value == '' && this.dropboxForm.get('Area')?.value == '' && this.dropboxForm.get('Bank')?.value == ''){
      this.getAllDropbox()
    }else{
      this.service.getBankDetails(this.dropboxForm.value).subscribe(response=>{
        let data = JSON.parse(JSON.stringify(response))
  
        this.BankList=data.details
        //console.log(this.BankList)
        
      })

    }

    

    

    

  }

  getBankList(val:any){

    this.service.getBankByCityArea({city:this.selectedCity,Area:val}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      this.AllBank=data.details

      
    })

  }

  getAreaByCity(val:any){
    //console.log(val)
    this.selectedCity=val
    this.service.getAreaByCity({city:val}).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      this.AllCity=data.details

      
    })
  

  }

  getCity(){
    this.service.getAllDroplist().subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))

      this.AllData=data.details

      
    })
  }

}
