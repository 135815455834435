<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1><span>Status Of Open Requests</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >Dealer Zone</a></li>
                <li class=""><a >Login For Existing Dealers</a></li>
                <li class=""><a >Feedback</a></li>
                <li class="active" aria-current="page">Status Of Open Requests </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <div class="customer-login-page log-meter-reading">

                                <form [formGroup]="UserForm">
                                    <div class="row">

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            
                                            

                                            <div class="form-floating mb-3">
                                                <select (change)="getFeedbackComplaintIdByOutlet(type.value)"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['DocketNo'].errors}" #type
                                                    (change)="this.UserForm.get('DocketNo')?.setValue(type.value)"
                                                     class="form-select" 
                                                     >
                                                     <option value="" selected>Select Option</option>
                                                     <option *ngFor="let i of feedback" [value]="i.DocketNo">{{i.DocketNo}}</option>
                                                    
                                                </select>
                                                <label for="floatingPassword">Docket No</label>
                                                <div *ngIf="submitted && UserForm.controls['DocketNo'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['DocketNo'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <select (change)="getFeedBackDetailById(Id.value)"
                                                    [ngClass]="{'is-invalid':submitted && UserForm.controls['tpye_of_request'].errors}" #Id
                                                    (change)="this.UserForm.get('tpye_of_request')?.setValue(type.value)"
                                                     class="form-select" 
                                                     >
                                                     <option value="" selected>Select Option</option>
                                                    <option *ngFor="let i of Feedbackdetail">{{i.Complaint}}</option>
                                                    
                                                </select>
                                                <label for="floatingPassword">Type Of Request</label>
                                                <div *ngIf="submitted && UserForm.controls['tpye_of_request'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['tpye_of_request'].errors">Required Field</div>
                                                  </div>
                                              </div>

                                              
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['Remark'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="Remark">
                                                <label for="floatingPassword">Remark </label>
                                                <div *ngIf="submitted && UserForm.controls['Remark'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['Remark'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                       
                                        
                                        
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['ComplaintDate'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="ComplaintDate">
                                                <label for="floatingPassword">Probable Date Of Closer </label>
                                                <div *ngIf="submitted && UserForm.controls['ComplaintDate'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['ComplaintDate'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>
                                         <div class="col-lg-6 col-md-6 col-sm-12">
                                            

                                            <div class="form-floating mb-3">
                                                <input readonly [ngClass]="{'is-invalid':submitted && UserForm.controls['Feedback'].errors}" type="text" class="form-control" id="floatingPassword" placeholder="Password" formControlName="Status">
                                                <label for="floatingPassword">Status   </label>
                                                <div *ngIf="submitted && UserForm.controls['Status'].errors"
                                                      class="invalid-feedback">
                                                      <div 
                                                          *ngIf="UserForm.controls['Status'].errors">Required Field</div>
                                                  </div>
                                              </div>
                                        </div>

                                       
                                        

                                        

                                        
                                    </div>

                                </form>
                              
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>